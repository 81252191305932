import { useUnit } from 'effector-react';
import { KonvaEventObject } from 'konva/lib/Node';
import { Stage as TStage } from 'konva/lib/Stage';

import {
  $currentTool,
  $dragSelection,
  $selectedObjectIds,
  $stageState,
  startedDragSelection,
  stoppedDragSelection,
  updatedDragSelection,
} from '@pages/StudioPage/model';
import { $isShiftPressed } from '@pages/StudioPage/shift-key-tracking';
import { selectBlocksInSelection } from '@pages/StudioPage/utils';

import { useStudioDesign } from '../use-studio-design';

export const useDragSelection = (
  stageRef: React.MutableRefObject<TStage | null>,
  designId: string,
) => {
  const [stage, currentTool, dragSelection, selectedObjectIds] = useUnit([
    $stageState,
    $currentTool,
    $dragSelection,
    $selectedObjectIds,
  ]);
  const isShiftPressed = useUnit($isShiftPressed);

  const { getGroupObjectsByObjectId } = useStudioDesign(designId);

  const startDragSelection = (e: KonvaEventObject<MouseEvent>) => {
    // If cursor over anything do not start selection
    if (e.target.name() !== 'Stage') return;
    // If select tool start drawing box
    if (currentTool === 'select') {
      startedDragSelection({
        x: (e.evt.offsetX - stage.x) / stage.scale,
        y: (e.evt.offsetY - stage.y) / stage.scale,
      });
    }
  };

  const updateDragSelection = (e: KonvaEventObject<MouseEvent>) => {
    if (dragSelection) {
      // If a drag is in action continue drawing box
      updatedDragSelection({
        x: (e.evt.offsetX - stage.x) / stage.scale,
        y: (e.evt.offsetY - stage.y) / stage.scale,
      });
    }
  };

  const endDragSelection = () => {
    if (dragSelection) {
      if (stageRef.current) {
        selectBlocksInSelection({
          stage: stageRef.current,
          x1: dragSelection.x1,
          y1: dragSelection.y1,
          x2: dragSelection.x2,
          y2: dragSelection.y2,
          selectedObjectIds,
          getGroupObjectsByObjectId,
          isShiftPressed,
        });
      }
      stoppedDragSelection();
    }
  };

  return {
    startDragSelection,
    updateDragSelection,
    endDragSelection,
  };
};

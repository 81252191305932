import {
  Children,
  MouseEventHandler,
  PropsWithChildren,
  ReactNode,
  useState,
} from 'react';

import cn from 'classnames';

import { Icon } from '@visualist/icons';

import TypographyItalic from '../Styles/Typography/TypographyItalic';
import { TypographyPoppins } from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

const INDENT_SPACING = 16;

export const NavItemList = ({
  leadingIcon,
  trailingIcon,
  iconAfterText,
  name,
  indent,
  children,
  onClick,
  onContextMenu,
  isSelected,
  isHoveredOverride = false,
  isArchived,
  breadcrumbs,
  className,
  classNameContent,
  date,
  user,
  menu,
}: PropsWithChildren<{
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  iconAfterText?: ReactNode;
  name: ReactNode | string;
  indent: number;
  onClick: () => void;
  onContextMenu?: MouseEventHandler<HTMLButtonElement>;
  isSelected: boolean;
  isHoveredOverride?: boolean;
  isArchived?: boolean;
  breadcrumbs?: ReactNode;
  className?: string;
  classNameContent?: string;
  date?: ReactNode;
  user?: ReactNode;
  menu?: ReactNode;
}>) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const hasChildren = !!Children.toArray(children).length;

  return (
    <>
      <button
        className={cn(styles.container, className)}
        onClick={onClick}
        onContextMenu={onContextMenu}
        data-active={isSelected}
        data-force-hover={isHoveredOverride}
        style={{
          paddingLeft: `${indent * INDENT_SPACING}px`,
        }}
      >
        <div className={cn(styles.left, classNameContent)}>
          <button
            onClick={(e) => {
              e.stopPropagation();
              if (!hasChildren) return;

              setIsExpanded((e) => !e);
            }}
            style={{
              visibility: hasChildren || isArchived ? 'visible' : 'hidden',
            }}
            className={styles.caretContainer}
          >
            {isArchived ? (
              <Icon
                name="sprite/archive"
                className={styles.archive}
                size={16}
              />
            ) : (
              <Icon
                name="sprite/caret-down"
                style={{
                  rotate: isExpanded ? '0deg' : '-90deg',
                }}
                className={styles.caretIcon}
              />
            )}
          </button>
          {leadingIcon ? (
            <div className={styles.caretContainer}>{leadingIcon}</div>
          ) : null}
          {isArchived ? (
            <TypographyItalic
              type="label"
              formattingLabel="small italic"
              customStyle={cn(styles.text, styles.archive)}
            >
              {name}
            </TypographyItalic>
          ) : (
            <TypographyPoppins type="label" size="M" className={styles.text}>
              {name}
            </TypographyPoppins>
          )}

          <span>{iconAfterText}</span>
          {menu && indent === 1 && menu}
        </div>
        {trailingIcon ? (
          <div className={styles.right}>{trailingIcon}</div>
        ) : null}
        {breadcrumbs && indent === 1 && breadcrumbs}
        {date && indent === 1 && date}
        {user && indent === 1 && user}
      </button>
      {isExpanded ? children : null}
    </>
  );
};

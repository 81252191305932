import { useAppData } from '@src/AppContext';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getBoards } from '../../../../../api/boards';

export function useAllBoards(
  options?: Omit<
    UseQueryOptions<Awaited<ReturnType<typeof getBoards>>>,
    'queryKey' | 'queryFn'
  >,
) {
  const { user } = useAppData();

  return useQuery({
    ...options,
    queryKey: ['all_boards', user.show_archived],
    queryFn: () =>
      getBoards({ ordering: '-created_at', is_archived: user.show_archived }),
  });
}

import { api, File } from '@api/index';

type BlockTag = {
  id: string;
  tag: string;
  tags: string[];
  file: File;
  height: number;
  width: number;
};

export type BlockTags = Array<BlockTag>;

export const fetchTags = async (): Promise<BlockTag[]> => {
  const { data } = (await api.get('/blocks/tags/label_next')) as {
    data: BlockTag[];
  };

  // Fetch all images in parallel
  await Promise.all(
    data.map((blockTag) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = resolve;
        img.onerror = resolve;
        img.src = blockTag.file.thumbnail_640;
      });
    }),
  );

  return data;
};

import { useState } from 'react';

import {
  Avatar,
  Checkbox,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { useAppData, User } from '@src/AppContext';

import { SearchModal } from '../ui/modal';

import styles from './styles.module.css';

export const formatName = (user: User) => {
  const { first_name, last_name, email } = user;

  if (first_name || last_name) {
    let name = '';
    if (first_name) name += first_name;
    if (last_name) name += ` ${last_name}`;
    return name;
  }

  return email;
};

export const UserCheckSelectSearch = ({
  children,
  onResultClick,
  isLoading,
  users = [],
  selectedAssignees,
}: {
  children:
    | React.ReactElement<{ onClick?: (e: React.MouseEvent) => void }>
    | Array<React.ReactElement<{ onClick?: (e: React.MouseEvent) => void }>>;
  onResultClick: (user: User) => void;
  isLoading: boolean;
  users: Array<User> | undefined;
  selectedAssignees: Array<User> | null;
}) => {
  const { user } = useAppData();
  const [username, setUsername] = useState('');

  return (
    <SearchModal
      placeholder="Search for someone"
      searchTerm={username}
      updateSearchTerm={setUsername}
      onClose={() => setUsername('')}
      isLoading={isLoading}
      noResultsPlaceholder="No matches... Try another name"
      iconDescription="Work with others in Visualist"
      results={[...users.filter((u) => u.id !== user.id), user].map((user) => ({
        ...user,
        id: user.id,
        name: formatName(user) ?? '',
        onClick: () => onResultClick(user),
      }))}
      renderItem={(row) => (
        <SearchModal.EmptyRow key={row.id} onClick={() => {}}>
          <div className={styles.row}>
            <TooltipRadix
              key={row.id}
              classNameTrigger={styles.tooltip}
              type="rich"
              title={
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    marginBottom: '12px',
                  }}
                >
                  <Avatar
                    key={row.id}
                    image={row?.photo?.full_size}
                    initials={`${row.first_name ?? ''} ${row.last_name ?? ''}`}
                    size={40}
                  />
                  {row ? `${row.first_name} ${row.last_name}` : undefined}
                </span>
              }
              description={
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    marginBottom: '8px',
                  }}
                >
                  <Icon
                    name="sprite/at-sign"
                    style={{ paddingLeft: '8px' }}
                    color="var(--color-neutral-variant-30)"
                  />
                  {row.email}
                </span>
              }
              descriptionSize="S"
              side="bottom"
            >
              <div className={styles.left}>
                <Avatar
                  size={24}
                  initials={row.name}
                  image={row.photo?.medium_square_crop}
                />
                <TypographyPoppins type="body" size="L" className={styles.text}>
                  {row.name}
                </TypographyPoppins>
              </div>
            </TooltipRadix>
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                onResultClick(row);
              }}
              icon={
                <Icon
                  name="sprite/checkbox-filled"
                  color="var(--color-primary-40)"
                  size={30}
                />
              }
              isSelected={
                !selectedAssignees
                  ? false
                  : selectedAssignees.map((u) => u.id).includes(row.id)
              }
            />
          </div>
        </SearchModal.EmptyRow>
      )}
    >
      {children}
    </SearchModal>
  );
};

import {
  AssistChip,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const ExtraSeats = () => (
  <div className={styles.extraSeats}>
    <TooltipRadix description="Coming soon: Pay for extra seats">
      <AssistChip style="outlined" onClick={() => {}} disabled>
        Get extra seats
      </AssistChip>
    </TooltipRadix>
  </div>
);

import cn from 'classnames';

import {
  Design,
  NavItemList,
  Palette,
  TypographyItalic,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { BlockItem, DocItem } from '@api/bin';
import { BlockType } from '@api/index';
import { useUndoDeleteBlock } from '@pages/FileCard/model/queries/use-undo-delete-block';
import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { deletePermanentlyModalOpened } from '@src/entities/delete-modals/model';
import { useDeleteDoc } from '@src/entities/doc/queries/useDeleteDoc';

import { Menu } from '../menu';
import { Person } from '../person';
import { Time } from '../time';

import styles from '../styles.module.css';

type Props = {
  item: DocItem | BlockItem;
  indent?: number;
  isNestedItem?: boolean;
};

export const File = ({ item, isNestedItem = false, indent = 1 }: Props) => {
  const { putBlockBack } = useUndoDeleteBlock();
  const { putDocBackMutation } = useDeleteDoc();

  const restore = () => {
    if (item.item_type === 'Block') {
      putBlockBack({
        id: item.block?.id,
        location: item.block?.boards.length
          ? item.block?.boards[0].id
          : undefined,
      });
    }

    if (item.item_type === 'Doc') {
      putDocBackMutation.mutate({ docId: item.doc.id });
    }
  };

  const deletePermanently = () => {
    deletePermanentlyModalOpened({
      file: item.item_type,
      id: item.item_type === 'Doc' ? item.doc.id : item.block.id,
      type: 'single',
    });
  };

  const getName = () => {
    const getTitle = (title: string | null) =>
      title || (
        <TypographyItalic type="body" formattingBody="small italic">
          Untitled
        </TypographyItalic>
      );

    return item.item_type === 'Doc'
      ? getTitle(item.doc?.title)
      : getTitle(item.block?.name);
  };

  const getIcon = () => {
    if (item.item_type === 'Doc') return <Icon name="sprite/doc" />;

    const iconsMap = {
      Image: <Icon name="sprite/image" />,
      Set: <Design fill="none" height={24} width={24} />,
      'Colour palette': <Palette fill="none" height={24} width={24} />,
    };

    return iconsMap[item.block?.block_type as BlockType];
  };

  const getBreadcrumbs = () => {
    if (isNestedItem) return null;

    return item.item_type === 'Doc' && item.doc.board ? (
      <Breadcrumbs
        className={styles.breadcrumbs}
        board={item.doc.board}
        isClickable
      />
    ) : item.item_type === 'Block' && item.block.boards ? (
      <Breadcrumbs
        className={styles.breadcrumbs}
        board={item.block.boards[0]}
        isClickable
      />
    ) : null;
  };

  const isIndent = () => {
    return item.item_type === 'Doc'
      ? !item.doc.board
      : !item.block.boards.length;
  };

  return (
    <li className={cn(styles.row, { [styles.nestedItem]: isNestedItem })}>
      <NavItemList
        key={item.id}
        className={styles.item}
        classNameContent={styles.align}
        indent={indent}
        breadcrumbs={getBreadcrumbs()}
        date={
          isNestedItem ? null : (
            <Time date={item.deleted_at} isIndent={isIndent()} />
          )
        }
        user={
          isNestedItem ? null : (
            <Person user={item.deleted_by} isIndent={isIndent()} />
          )
        }
        menu={
          isNestedItem ? null : (
            <Menu
              item={item}
              restore={restore}
              deletePermanently={deletePermanently}
            />
          )
        }
        name={getName()}
        leadingIcon={getIcon()}
        onClick={() => {}}
        isSelected={false}
      />
    </li>
  );
};

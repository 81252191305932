import { GlobalError, GlobalErrorHomeButton } from '..';

export const IncorrectPermissions = () => {
  return (
    <GlobalError
      icon="sprite/lock-colored"
      text={`
      Uh-oh, it looks like you don’t have
			permission to view this page.

      Check with your admin and try again.
      `}
      button={<GlobalErrorHomeButton />}
    />
  );
};

import { AxiosResponse } from 'axios';
import cn from 'classnames';
import { useUnit } from 'effector-react';

import {
  IconButton,
  SegmentedButton,
  Switch,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { ColorTheme, Role } from '@api/index';
import {
  openedColourPaletteSearch,
  searchedColours,
  toggleSearch,
  updatedColours,
} from '@src/entities/search/model';
import { UseMutateFunction } from '@tanstack/react-query';

import { $openPanel, toggledPanel } from '../model';
import { $palette } from '../palette/model';
import { AdjustPalette } from './adjust-palette';
import { ColorCodes } from './color-codes';
import {
  $isAdjustPalette,
  $isColorCodes,
  adjustPaletteSelected,
  colorCodesSelected,
} from './model/options-managment';

import styles from './styles.module.css';

type Props = {
  fileId: string;
  editColor: UseMutateFunction<AxiosResponse<any>, Error, ColorTheme, unknown>;
  role: Role | null;
  isArchived?: boolean;
};

export const PaletteOptions = ({
  fileId,
  editColor,
  role,
  isArchived,
}: Props) => {
  const isAdjustPalette = useUnit($isAdjustPalette);
  const isColorCodes = useUnit($isColorCodes);
  const openPanel = useUnit($openPanel);
  const palette = useUnit($palette);

  const isExtended =
    openPanel === 'palette' && (role === 'Editor' || role === null);

  const search = () => {
    toggleSearch();

    const selectedColors = palette.swatches.map((item) => ({
      hex: item.hex_color,
      id: item.id,
    }));

    updatedColours(selectedColors);
    openedColourPaletteSearch();
    searchedColours();
  };

  return (
    <div
      className={cn(styles.options, {
        [styles.extended]: isExtended,
      })}
    >
      <div
        className={cn(styles.header, {
          [styles.archived]: isArchived,
        })}
        onClick={() => {
          if (!isArchived) {
            toggledPanel('palette');
          }
        }}
      >
        <TypographyPoppins type="title" size="M">
          <span className={styles.title}>Palette options</span>
        </TypographyPoppins>
        <div className={styles.actions}>
          <Switch
            value={isExtended}
            onValueChange={() => toggledPanel('palette')}
            disable={role === 'Commenter' || role === 'Viewer' || isArchived}
          />
          <IconButton
            className={styles.iconButton}
            type="unfilled"
            icon={
              <Icon
                name="sprite/chevron-down"
                className={styles.icon}
                style={{
                  transform: `rotate(${isExtended ? '180deg' : '0deg'})`,
                }}
              />
            }
            onClick={() => {}}
            isDisabled={role !== 'Editor' || isArchived}
          />
        </div>
      </div>
      <div className={styles.segmentedButtons}>
        <TooltipRadix description="Adjust palette">
          <SegmentedButton
            buttonStyle={cn(styles.button, {
              [styles.archived]: isArchived,
            })}
            isSelected={isAdjustPalette && role === 'Editor' && !isArchived}
            icon={<Icon name="sprite/toggle" />}
            onClick={() => adjustPaletteSelected()}
            isDisabled={!isExtended || isArchived}
            start
          />
        </TooltipRadix>
        <TooltipRadix description="Color codes">
          <SegmentedButton
            buttonStyle={cn(styles.button, {
              [styles.archived]: isArchived,
            })}
            isSelected={isColorCodes}
            icon={<Icon name="sprite/hashtag" />}
            onClick={() => colorCodesSelected()}
            isDisabled={!isExtended || isArchived}
          />
        </TooltipRadix>
        <TooltipRadix description="Search with this palette">
          <SegmentedButton
            buttonStyle={cn(styles.button, {
              [styles.archived]: isArchived,
            })}
            icon={<Icon name="sprite/magnifier" />}
            onClick={search}
            isDisabled={!isExtended || isArchived}
            end
          />
        </TooltipRadix>
      </div>

      {isAdjustPalette && isExtended && (
        <AdjustPalette
          fileId={fileId}
          editColor={editColor}
          isArchived={isArchived}
        />
      )}
      {isColorCodes && isExtended && <ColorCodes fileId={fileId} />}
    </div>
  );
};

import { combine, createEvent, createStore } from 'effector';
import { hotkey } from 'effector-hotkey';

import {
  $isShowConfirmationDialog,
  confirmationDialogClosed,
} from './show-confirmation-dialog';
import {
  $isShowMoveBoardDialog,
  moveBoardDialogClosed,
} from './show-move-dialog';

export const searchOpened = createEvent<boolean>();
export const searchClosed = createEvent();

export const queryEntered = createEvent<string>();
export const queryCleared = createEvent();

export const $isSearchOpen = createStore(false);
export const $query = createStore('');

$isSearchOpen.on(searchOpened, (_, state) => state).reset(searchClosed);

$query
  .on(queryEntered, (_, query) => query)
  .reset([queryCleared, searchClosed]);

const $canClose = combine(
  $isShowMoveBoardDialog,
  $isShowConfirmationDialog,
  $isSearchOpen,
  (isShowMoveBoardDialog, isShowConfirmationDialog, isSearchOpen) =>
    isShowMoveBoardDialog && !isShowConfirmationDialog && !isSearchOpen,
);

hotkey({
  key: 'Escape',
  filter: $canClose,
  target: moveBoardDialogClosed,
});

hotkey({
  key: 'Escape',
  filter: $isSearchOpen && $query.map((query) => query.length === 0),
  target: searchClosed,
});

hotkey({
  key: 'Escape',
  filter: $isSearchOpen && $query.map((query) => query.length > 0),
  target: queryCleared,
});

hotkey({
  key: 'Escape',
  filter: $isShowConfirmationDialog,
  target: confirmationDialogClosed,
});

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { Invitation, inviteToBoard } from '@api/boards';
import { MutateOptions, useMutation } from '@tanstack/react-query';

import { queryClient } from '../../../../../queryClient';
import {
  MEMBERS_INVITATION_QUERY,
  MEMBERS_SUGGESTIONS_QUERY,
} from '../../../../../shared/constants/query-names';

export const useInviteToBoard = (
  options?: MutateOptions<Invitation, Error, Invitation>,
) => {
  const { mutate: sendInvitation } = useMutation({
    mutationFn: ({
      id,
      users,
      message,
      permissions,
      nested_board,
    }: Invitation) =>
      inviteToBoard({ id, users, message, permissions, nested_board }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_INVITATION_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [MEMBERS_SUGGESTIONS_QUERY],
      });

      // startedSnack({
      //   label: `Invited them to ${variables.boardName}`,
      //   close: true,
      // });

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      if (error.message.includes('406') && variables.setOn) {
        startedSnack({
          label: "You don't have guest seats left",
          close: true,
        });
        variables.setOn(false);
      } else {
        // startedSnack({
        //   label: `Couldn't invite them to ${variables.boardName}`,
        //   action: {
        //     label: 'Try again',
        //     action: () => {
        //       sendInvitation({
        //         id: variables.id,
        //         users: variables.users,
        //         boardName: variables.boardName,
        //         nested_board: variables.nested_board,
        //         permissions: variables.permissions,
        //       });
        //     },
        //   },
        //   close: true,
        // });
      }

      if (options?.onError) options?.onError(error, variables, context);
    },
  });

  return { sendInvitation };
};

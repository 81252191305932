import { useEffect, useRef, useState } from 'react';

import { useOnClickOutside } from '@visualist/hooks';

import { InvitationDoc } from '@api/docs';
import { InvitationHub } from '@api/hubs';
import { Permissions, ResponseSuggestion } from '@api/users';
import { UseMutateFunction } from '@tanstack/react-query';

import { User } from '..';

type Invitation = InvitationDoc | InvitationHub;

type ExistingUserProps = {
  user: ResponseSuggestion;
  id: string | null;
  sendInvitation: UseMutateFunction<Invitation, any, Invitation, unknown>;
  currentPermissions?: Permissions[];
  type?: 'doc';
};
export const ExistingUser = ({
  user,
  id,
  sendInvitation,
  type,
  currentPermissions,
}: ExistingUserProps) => {
  const [on, setOn] = useState(false);
  const [isShowMenu, setShowMenu] = useState(false);
  const [permissions, setPermissions] = useState<any[]>(['edit', 'invite']);

  const permissionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentPermissions) {
      setPermissions(currentPermissions);
    }
  }, [currentPermissions]);

  useOnClickOutside(permissionsRef, () => {
    if (isShowMenu) {
      setShowMenu(false);
    }
  });

  const invite = () => {
    if (!on && id) {
      sendInvitation({
        id,
        users: [{ email: user.email }],
        permissions,
      });
    }
    setOn(true);
  };

  const changePermissions = (permissions: any) => {
    if (id) {
      sendInvitation({
        id,
        users: [{ email: user.email }],
        permissions,
      });
    }
    setPermissions(permissions);
    setOn(true);
  };

  const getPermissions = () => {
    if (permissions.includes('edit')) {
      return 'Editor';
    } else if (permissions.includes('comment')) {
      return 'Commenter';
    } else {
      return 'Viewer';
    }
  };

  return (
    <User
      // @ts-expect-error Error here because the user expected is meant to be a more complete user object. And this component is only shown to render the switch from this component. To fix the type will need to create a new component to render this. Similiar issue in other matching components copied in uhbs share sheet.
      user={user}
      isShowMenu={isShowMenu}
      setShowMenu={setShowMenu}
      invite={invite}
      on={on}
      permissions={permissions}
      permissionsRef={permissionsRef}
      changePermissions={changePermissions}
      getPermissions={getPermissions}
      type={type}
      currentPermissions={currentPermissions}
    />
  );
};

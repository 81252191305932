import React, { useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { flushSync } from 'react-dom';

import {
  Button,
  Modal,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SegmentedButton,
  TextField,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { ResponseDoc } from '@api/docs';
import { SMALL } from '@src/shared/constants/breakpoints';
import { FontFamily } from '@src/shared/types';
import { TextSelection } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';

import {
  $isShowElementsPanel,
  $isShowFilesPanel,
  elementPanelClosed,
  filesPanelClosed,
} from '../../../../pages/DocPage/model/sidebar-opening';
import { TextStyleButton } from '../text-style-segmented-button';
import { FontDropdown } from './ui/FontDropDown';
import { StyleDropdown } from './ui/StyleDropdown';
import { TableCreation } from './ui/TablePopover';
import { canAppendHTTPS, getFontSize, getNumberFromPx, isURL } from './utils';

import styles from './styles.module.css';

export const Toolbar = ({
  editor,
  showToolbar,
  doc,
}: {
  editor: Editor;
  showToolbar: boolean;
  doc?: ResponseDoc;
}) => {
  const isShowElementsPanel = useUnit($isShowElementsPanel);
  const isShowFilesPanel = useUnit($isShowFilesPanel);

  const colour: string = editor.getAttributes('textStyle').color ?? '#000000';

  const { width } = useWindowSize();

  useEffect(() => {
    if (width <= SMALL) {
      filesPanelClosed();
      elementPanelClosed();
    }
  }, [width]);

  if (!editor) {
    console.error('Needs to be child of EditorWrapper');
    return null;
  }

  if (!showToolbar) {
    return null;
  }

  return (
    <div
      className={cn(styles.scrollable, {
        [styles.sideBarOpen]: isShowElementsPanel || isShowFilesPanel,
      })}
    >
      <div className={styles.container}>
        <FontSizeButton editor={editor} doc={doc} />
        <FontTypeButton editor={editor} doc={doc} />
        <ColourButton editor={editor} doc={doc} />
        <StyleButton editor={editor} doc={doc} />
        <div className={styles.divider}></div>
        <TextStyleButton
          isActive={doc?.is_archived ? false : editor.isActive('bold')}
          toggle={() => {
            // @ts-ignore TODO fix types for bold
            editor.chain().focus().toggleBold().run();
          }}
          textIcon="B"
          textIconStyle={{
            fontSize: 20,
            fontFamily: 'Roboto Serif',
            fontWeight: 'bold',
          }}
          tooltipText="Bold"
          doc={doc}
        />
        <TextStyleButton
          isActive={doc?.is_archived ? false : editor.isActive('italic')}
          toggle={() => {
            // @ts-ignore TODO fix types for bold
            editor.chain().focus().toggleItalic().run();
          }}
          textIcon="I"
          textIconStyle={{
            fontSize: 20,
            fontFamily: 'Roboto Serif',
            fontStyle: 'italic',
          }}
          tooltipText="Italic"
          doc={doc}
        />
        <TextStyleButton
          isActive={doc?.is_archived ? false : editor.isActive('underline')}
          toggle={() => {
            // @ts-ignore TODO fix types for bold
            editor.chain().focus().toggleUnderline().run();
          }}
          textIcon="U"
          textIconStyle={{
            fontSize: 20,
            fontFamily: 'Roboto Serif',
            textDecoration: 'underline',
          }}
          tooltipText="Underline"
          doc={doc}
        />
        <TextStyleButton
          isActive={doc?.is_archived ? false : editor.isActive('strike')}
          toggle={() => {
            // @ts-ignore TODO fix types for bold
            editor.chain().focus().toggleStrike().run();
          }}
          textIcon="S"
          textIconStyle={{
            fontSize: 20,
            fontFamily: 'Roboto Serif',
            textDecoration: 'line-through',
          }}
          tooltipText="Strike"
          doc={doc}
        />
        <LinkButton
          editor={editor}
          key={
            editor.getAttributes('link').href +
            editor.state.doc.textBetween(
              editor.state.selection.from,
              editor.state.selection.to,
              ' ',
            )
          }
          doc={doc}
        />
        <div className={styles.divider}></div>
        <TextStyleButton
          isActive={doc?.is_archived ? false : editor.isActive('bulletList')}
          toggle={() => {
            // @ts-ignore TODO fix types for bold
            editor.chain().focus().toggleBulletList().run();
          }}
          textIcon={
            <Icon name="sprite/text-bullet-list" height={20} width={20} />
          }
          textIconStyle={{
            fontSize: 20,
          }}
          tooltipText="Bulleted list"
          doc={doc}
        />
        <TextStyleButton
          isActive={doc?.is_archived ? false : editor.isActive('orderedList')}
          toggle={() => {
            // @ts-ignore TODO fix types for bold
            editor.chain().focus().toggleOrderedList().run();
          }}
          textIcon={
            <Icon name="sprite/text-numbered-list" height={20} width={20} />
          }
          textIconStyle={{
            fontSize: 20,
          }}
          tooltipText="Numbered list"
          doc={doc}
        />
        <TextStyleButton
          isActive={doc?.is_archived ? false : editor.isActive('taskList')}
          toggle={() => {
            // @ts-ignore TODO fix types for bold
            editor.chain().focus().toggleTaskList().run();
          }}
          textIcon={
            <Icon name="sprite/text-task-list" height={20} width={20} />
          }
          textIconStyle={{
            fontSize: 20,
          }}
          tooltipText="Checklist"
          doc={doc}
        />
        <div className={styles.divider}></div>
        <TextStyleButton
          isActive={
            doc?.is_archived ? false : editor.isActive({ textAlign: 'left' })
          }
          toggle={() => {
            editor.chain().focus().setTextAlign('left').setColor(colour).run();
          }}
          textIcon={
            <Icon name="sprite/text-align-left" height={20} width={20} />
          }
          textIconStyle={{
            fontSize: 20,
          }}
          tooltipText="Align left"
          doc={doc}
        />
        <TextStyleButton
          isActive={
            doc?.is_archived ? false : editor.isActive({ textAlign: 'center' })
          }
          toggle={() => {
            editor
              .chain()
              .focus()
              .setTextAlign('center')
              .setColor(colour)
              .run();
          }}
          textIcon={
            <Icon name="sprite/text-align-centre" height={20} width={20} />
          }
          textIconStyle={{
            fontSize: 20,
          }}
          tooltipText="Align center"
          doc={doc}
        />
        <TextStyleButton
          isActive={
            doc?.is_archived ? false : editor.isActive({ textAlign: 'right' })
          }
          toggle={() => {
            editor.chain().focus().setTextAlign('right').setColor(colour).run();
          }}
          textIcon={
            <Icon name="sprite/text-align-right" height={20} width={20} />
          }
          textIconStyle={{
            fontSize: 20,
          }}
          tooltipText="Align right"
          doc={doc}
        />
        <div className={styles.divider}></div>
        <TableButton editor={editor} doc={doc} />
      </div>
    </div>
  );
};

type PassedEditorProps = {
  editor: Editor;
  doc?: ResponseDoc;
};

const LinkButton = ({ editor, doc }: PassedEditorProps) => {
  const [showModal, setShowModal] = useState(false);
  const [linkText, setLinkText] = useState(
    editor.getAttributes('link').href ?? '',
  );
  const hasLink = editor.isActive('link');

  const { from, to } = editor.view.state.selection;
  const [selectedText, setSelectedText] = useState(
    editor.state.doc.textBetween(from, to, ' '),
  );
  const fieldToFocus = editor.state.doc.textBetween(from, to, ' ').length
    ? 'link'
    : 'text';

  const reset = () => {
    setShowModal(false);
    setLinkText('');
    setSelectedText('');
  };

  const applyLink = () => {
    let link = linkText;

    if (!isURL(link) || canAppendHTTPS(link)) {
      // Not a valid url
      // Check if can be converted to url
      const url = new URL(`https://${link}`);
      link = url.href;
    }
    // Check if text is changed from editor selection
    if (selectedText !== editor.state.doc.textBetween(from, to, ' ')) {
      // Update editor selection
      const textNode = editor.state.schema.text(selectedText);

      const tr = editor.state.tr;
      tr.replaceWith(from, to, textNode);
      const selection = TextSelection.create(
        tr.doc,
        from,
        from + selectedText.length,
      );
      tr.setSelection(selection);
      editor.view.dispatch(tr);
    }

    editor
      .chain()
      .focus()
      .setLink({
        href: link,
        target: '_blank',
      })
      .run();
    reset();
  };

  return (
    <TooltipRadix description="Link" side="bottom">
      <SegmentedButton
        isSelected={hasLink}
        buttonStyle={cn(styles.button, {
          [styles.archived]: doc?.is_archived,
        })}
        icon={<Icon name="sprite/link" height={20} width={20} />}
        onClick={() => {
          if (linkText) {
            editor.chain().focus().unsetLink().run();
          } else {
            setShowModal((p) => !p);
          }
        }}
        isDisabled={doc?.is_archived}
      />
      <Modal
        showModal={showModal}
        handleClose={reset}
        className={styles.linkModal}
      >
        <div className={styles.linkModalTextFields}>
          <TextField
            value={selectedText}
            hideClear
            clear={() => {}}
            label="Text"
            onChange={(e) => {
              setSelectedText(e.target.value);
            }}
            isFocused={fieldToFocus === 'text'}
          />
          <TextField
            value={linkText}
            hideClear
            onChange={(e) => {
              setLinkText(e.target.value);
            }}
            clear={() => {}}
            label="Paste a link"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                applyLink();
              }
            }}
            isFocused={fieldToFocus === 'link'}
          />
        </div>
        <div>
          <Button
            isDisabled={!(selectedText && linkText)}
            label="Apply"
            type="outlined"
            onClick={applyLink}
          />
        </div>
      </Modal>
    </TooltipRadix>
  );
};

const StyleButton = ({ editor, doc }: PassedEditorProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const headingLevel: 1 | 2 | 3 | undefined =
    editor.getAttributes('heading').level;

  const getTextIcon = () => {
    const styleBasic = {
      fontSize: 20,
      fontFamily: 'Roboto Serif',
    } as React.CSSProperties;

    const styleHeading = {
      ...styleBasic,
      position: 'relative',
    } as React.CSSProperties;

    const styleNumber = {
      position: 'absolute',
      fontSize: 8,
      fontWeight: 'bolder',
      bottom: -1,
      right: -4,
    } as React.CSSProperties;

    switch (headingLevel) {
      case 1:
        return (
          <span style={styleHeading}>
            H<span style={styleNumber}>1</span>
          </span>
        );
      case 2:
        return (
          <span style={styleHeading}>
            H<span style={styleNumber}>2</span>
          </span>
        );
      case 3:
        return (
          <span style={styleHeading}>
            H<span style={styleNumber}>3</span>
          </span>
        );
      default:
        return <span style={styleBasic}>T</span>;
    }
  };

  return (
    <>
      <TooltipRadix description="Style" side="bottom">
        <SegmentedButton
          buttonStyle={cn(styles.button, {
            [styles.archived]: doc?.is_archived,
          })}
          icon={getTextIcon()}
          onClick={() => setShowDropdown((p) => !p)}
          isDisabled={doc?.is_archived}
        />
      </TooltipRadix>
      <StyleDropdown
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        heading={headingLevel}
        editor={editor}
        setHeading={(heading: 1 | 2 | 3 | undefined) => {
          // TODO not sure why TS is dying here
          // @ts-ignore
          editor.chain().focus().toggleHeading({ level: heading }).run();
        }}
      />
    </>
  );
};

const ColourButton = ({ editor, doc }: PassedEditorProps) => {
  const colour: string = editor.getAttributes('textStyle').color ?? '#000000';
  const ref = useRef<HTMLInputElement>(null);

  return (
    <TooltipRadix description="Color" side="bottom">
      <SegmentedButton
        buttonStyle={cn(styles.button, {
          [styles.archived]: doc?.is_archived,
        })}
        icon={
          <>
            {doc?.is_archived ? (
              <Icon
                name="sprite/text-color"
                color="var(--color-neutral-variant-90)"
              />
            ) : (
              <Icon name="sprite/text-color-colored" color={colour} />
            )}

            <div className={styles.colorContainer}>
              <input
                style={{
                  visibility: 'hidden',
                }}
                value={colour}
                ref={ref}
                type="color"
                className={styles.colorWheelInput}
                onChange={(e) => {
                  editor.chain().setColor(e.target.value).run();
                }}
              />
            </div>
          </>
        }
        onClick={() => {
          ref.current?.click();
        }}
        isDisabled={doc?.is_archived}
      />
    </TooltipRadix>
  );
};

const FontTypeButton = ({ editor, doc }: PassedEditorProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const fontFamily: FontFamily =
    editor.getAttributes('textStyle').fontFamily ?? 'Poppins';
  const hasMultipleFonts = useMemo(() => {
    const fonts: Set<FontFamily> = new Set();
    editor.state.doc.nodesBetween(
      editor.state.selection.from,
      editor.state.selection.to,
      (node) => {
        node.marks.forEach((mark) => {
          if (mark.attrs.fontFamily) fonts.add(mark.attrs.fontFamily);
        });
        return true;
      },
    );

    return fonts.size > 1;
  }, [editor.state.selection]);

  return (
    <>
      <TooltipRadix description="Font" side="bottom">
        <SegmentedButton
          buttonStyle={cn(styles.button, {
            [styles.archived]: doc?.is_archived,
          })}
          icon={
            <span
              style={{
                fontFamily: fontFamily ?? 'Poppins',
              }}
            >
              Aa
            </span>
          }
          onClick={() => setShowDropdown((p) => !p)}
          isDisabled={doc?.is_archived}
        />
      </TooltipRadix>
      <FontDropdown
        editor={editor}
        selectedFontFamily={fontFamily}
        hideTick={hasMultipleFonts}
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
      />
    </>
  );
};

const FontSizeButton = ({ editor, doc }: PassedEditorProps) => {
  const parsedSize = getNumberFromPx(getFontSize(editor), 12);

  const { width } = useWindowSize();

  const [fontSize, setFontSize] = useState(parsedSize);

  useEffect(() => {
    setFontSize(parsedSize);
  }, [parsedSize]);

  const headingLevel: 1 | 2 | 3 | undefined =
    editor.getAttributes('heading').level;

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      editor.chain().focus().setFontSize(fontSize.toString()).run();

      if (headingLevel === 1 || headingLevel === 2 || headingLevel === 3) {
        editor
          .chain()
          .focus()
          //@ts-ignore
          .setParagraph()
          .selectParentNode()
          .setFontSize(fontSize.toString())
          .toggleBold()
          .run();
      }

      return;
    }
    if (e.key === 'ArrowUp') {
      flushSync(() => {
        const newSize = Math.min(400, fontSize + 1);
        if (newSize >= 400) {
          e.preventDefault();
          return;
        }
        editor.chain().setFontSize(newSize.toString()).run();

        if (headingLevel === 1 || headingLevel === 2 || headingLevel === 3) {
          editor
            .chain()
            //@ts-ignore
            .setParagraph()
            .selectParentNode()
            .setFontSize(newSize.toString())
            .toggleBold()
            .run();
        }

        e.target.focus();
      });
      return;
    }
    if (e.key === 'ArrowDown') {
      flushSync(() => {
        const newSize = Math.max(1, fontSize - 1);
        if (newSize <= 1) {
          e.preventDefault();
          return;
        }
        editor.chain().setFontSize(newSize.toString()).run();

        if (headingLevel === 1 || headingLevel === 2 || headingLevel === 3) {
          editor
            .chain()
            //@ts-ignore
            .setParagraph()
            .selectParentNode()
            .setFontSize(newSize.toString())
            .toggleBold()
            .run();
        }

        e.target.focus();
      });
    }
  };

  return (
    <TooltipRadix description="Size" side="bottom">
      <SegmentedButton
        start
        buttonStyle={cn(styles.button, {
          [styles.archived]: doc?.is_archived,
        })}
        icon={
          <input
            onBlur={(e) => {
              if (width < 500) {
                // For mobile change font size on blur
                const size = parseInt(e.target.value);
                setFontSize(size);
              }
            }}
            value={fontSize}
            className={cn(styles.input, {
              [styles.archived]: doc?.is_archived,
            })}
            onChange={(e) => {
              const size = parseInt(e.target.value);
              setFontSize(size);
            }}
            onKeyDown={onKeyDown}
            type="number"
            disabled={doc?.is_archived}
          />
        }
        onClick={() => {}}
        isSelected={false}
        isDisabled={doc?.is_archived}
      />
    </TooltipRadix>
  );
};

const TableButton = ({ editor, doc }: PassedEditorProps) => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <Popover
      open={showDropdown}
      defaultOpen
      onOpenChange={(v) => {
        if (!v) setShowDropdown(false);
      }}
    >
      <TooltipRadix description="Table" side="bottom">
        <PopoverTrigger asChild>
          <SegmentedButton
            buttonStyle={cn(styles.button, {
              [styles.archived]: doc?.is_archived,
            })}
            icon={<Icon name="sprite/doc-table" />}
            onClick={() => setShowDropdown((p) => !p)}
            isDisabled={doc?.is_archived}
          />
        </PopoverTrigger>
      </TooltipRadix>
      <PopoverContent>
        <TableCreation editor={editor} />
      </PopoverContent>
    </Popover>
  );
};

import { RemoveFile, removeFileFromBoard } from '@api/blocks';
import {
  ALL_BOARD_IMAGES_QUERY,
  ALL_FILE_COPIES,
  LIBRARY_QUERY,
} from '@src/shared/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useRemoveFile = () => {
  const queryClient = useQueryClient();

  const removeFileMutation = useMutation({
    mutationFn: ({ blocks }: RemoveFile) => removeFileFromBoard({ blocks }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [ALL_FILE_COPIES, variables.fileId],
      });
      queryClient.invalidateQueries({
        queryKey: [LIBRARY_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARD_IMAGES_QUERY],
      });
    },
  });

  return { removeFileMutation };
};

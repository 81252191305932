import { createEvent, createStore } from 'effector';

export const archiveBoardIdSelected = createEvent<string>();
export const archiveBoardIdCleared = createEvent();

export const $archiveBoardId = createStore<string | null>(null);

$archiveBoardId
  .on(archiveBoardIdSelected, (_, id) => id)
  .reset(archiveBoardIdCleared);

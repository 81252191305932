import cn from 'classnames';
import Markdown from 'react-markdown';
import { useHistory, useLocation } from 'react-router';

import {
  ChatLeft,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { SimpleAction } from '@api/actions';
import { Conversation } from '@api/vai';
import { ErrorBoundary } from '@sentry/react';
import { ActionItemDetails } from '@src/entities/sheet/action-item-details';

import { openedVaiModalWithHub } from '../../model';

import styles from './styles.module.css';

export const LatestConversation = ({
  messages,
  actionItems,
  basePath,
  isArchived,
}: {
  messages: Array<Conversation>;
  actionItems: Array<SimpleAction>;
  basePath: string;
  isArchived?: boolean;
}) => {
  const lastVaiMessage = messages.at(-1);

  const lastActionItem = actionItems && actionItems[actionItems.length - 1];

  return (
    <div className={styles.messages}>
      {lastVaiMessage ? (
        <div
          className={cn(styles.messageContainer, {
            [styles.archived]: isArchived,
          })}
        >
          <ChatLeft
            fill="none"
            style={{
              flexShrink: 0,
            }}
          />
          <div>
            <TypographyPoppins
              type="body"
              size="M"
              className={styles.marginBottom}
            >
              Continue your chat with Vai
            </TypographyPoppins>
            <button
              className={cn(styles.message, {
                [styles.archived]: isArchived,
              })}
              onClick={() => {
                openedVaiModalWithHub({
                  id: lastVaiMessage.hub.id,
                  name: lastVaiMessage.hub.name,
                });
              }}
              disabled={isArchived}
            >
              <TypographyPoppins type="body" size="M">
                <Markdown className={styles.leftAlign}>
                  {'text' in lastVaiMessage ? lastVaiMessage.text.text : ''}
                </Markdown>
              </TypographyPoppins>
            </button>
          </div>
        </div>
      ) : null}

      {lastActionItem ? (
        <ErrorBoundary>
          <LastActionItem basePath={basePath} actionItem={lastActionItem} />
        </ErrorBoundary>
      ) : null}
    </div>
  );
};

const LastActionItem = ({
  actionItem,
  basePath,
}: {
  actionItem: SimpleAction;
  basePath: string;
}) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const actionId = queryParams.get('actionId');

  const showActionItemDetails = () => {
    history.push(`${basePath}?actionId=${actionItem.id}`);
  };

  return (
    <>
      <ActionItemDetails
        isOpen={!!actionId}
        closeAction={() => {
          history.push(basePath);
        }}
      />
      <div className={styles.messageContainer}>
        <Icon name="sprite/check-circle" className={styles.actionIcon} />
        <span onClick={showActionItemDetails}>
          <TypographyPoppins type="body" size="M" className={styles.text}>
            Vai recently created this action item: {actionItem.name}
          </TypographyPoppins>
        </span>
      </div>
    </>
  );
};

import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import {
  Button,
  Divider,
  EmptyMailboxColored,
  IconButton,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { HubResponse } from '@api/hubs';
import { MessagesResponse } from '@api/messaging';
import { revealedHubEmailDialog } from '@pages/messages/model';
import { CopyHubEmailModal } from '@pages/messages/ui/side-bar/email-hub-button';
import { EmailListItem } from '@pages/messages/ui/side-bar/email-list';

import styles from './styles.module.css';

type Props = {
  messages?: MessagesResponse;
  hub: HubResponse;
  isArchived: boolean;
};

export const HubMessageCenter = ({ messages, hub, isArchived }: Props) => {
  const history = useHistory();

  return (
    <>
      <li className={styles.item} id="onboarding-hub-todos">
        <div className={cn(styles.card, styles.messageCenterCard)}>
          <div className={styles.header}>
            <TypographyPoppins className={styles.color} type="title" size="M">
              Messages ({messages?.count ? `${messages?.unread_count} new` : 0})
            </TypographyPoppins>
            {messages && messages.count > 0 && (
              <div className={styles.actions}>
                <TooltipRadix description="Get unique email address for this hub">
                  <IconButton
                    type="unfilled"
                    icon={
                      <Icon
                        className={styles.expand}
                        name="sprite/paper-plane"
                      />
                    }
                    onClick={() => {
                      revealedHubEmailDialog();
                    }}
                    isDisabled={isArchived}
                  />
                </TooltipRadix>
              </div>
            )}
          </div>
          <div className={styles.horizontal}>
            <Divider className={styles.divider} type="long-line" />
          </div>
          <div className={styles.list}>
            <ul className={styles.messages}>
              {!messages || messages.count === 0 ? (
                <div className={styles.emptyMessagesContainer}>
                  <div className={styles.emptyContent}>
                    <EmptyMailboxColored fill="none" size={80} />
                    <TypographyPoppins
                      type="body"
                      size="S"
                      style={{ color: 'var(--color-secondary-40)' }}
                    >
                      Get Vai’s help to turn
                      <br />
                      messages into actions
                    </TypographyPoppins>
                  </div>
                  <Button
                    label="Email to hub"
                    onClick={() => {
                      revealedHubEmailDialog();
                    }}
                    type="outlined"
                    isDisabled={isArchived}
                  />
                </div>
              ) : (
                messages?.results?.map((email, index) => (
                  <EmailListItem
                    key={email.id}
                    message={email}
                    isSelected={false}
                    onClick={() => {
                      if (!isArchived) {
                        history.push(`/messages/${hub.id}/${email.id}`);
                      }
                    }}
                    displayDate={false}
                    hideDivider={index == 2 ? true : false}
                    dividerReducedWidth={true}
                    isArchived={isArchived}
                  />
                ))
              )}
            </ul>
          </div>
          {messages && messages.count > 0 && (
            <Button
              type="text"
              label="See all"
              style={{ color: 'var(--color-secondary-40)' }}
              fontType="label"
              size="S"
              onClick={() => {
                history.push(`/messages/${hub.id}`);
              }}
              isDisabled={isArchived}
            />
          )}
        </div>
      </li>
      <CopyHubEmailModal hubId={hub.id} />
    </>
  );
};

import { createEvent, createStore } from 'effector';

import { BoardLvl1, BoardLvl2, BoardLvl3, Hub } from '@api/designs';

import { boardDialogClosed } from '../../../dialogs/board/create/model/create';

export type Location = {
  id?: string;
  level?: number;
  name?: string;
  parent?: BoardLvl1 | BoardLvl2 | BoardLvl3 | Hub | null;
  hub?: { id: string };
  type: 'board' | 'hub';
};

export const locationSelected = createEvent<Location>();
export const locationCleared = createEvent();

export const $location = createStore<Location | null>(null);

$location
  .on(locationSelected, (_, location) => location)
  .reset([locationCleared, boardDialogClosed]);

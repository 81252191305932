import { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useUnit } from 'effector-react';
import { isMobileOnly } from 'react-device-detect';
import Masonry from 'react-masonry-css';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router';

import { BrandLoader } from '@visualist/design-system/src/components/v2';
import { useLoadingDelay } from '@visualist/design-system/src/components/v2/brand-loader/useLoadingDelay';

import { getBoards } from '@api/boards';
import { LibraryActions } from '@components/LibraryActions';
import { ArchiveBanner } from '@src/entities/banner/archive';
import { $isShowBoardDialog } from '@src/entities/dialogs/board/create/model/create';
import { MissingContent } from '@src/entities/global-error/premade/missing-content';
import { locationSelected } from '@src/entities/search-modal/location/model';
import { RECENT_HUBS_KEY } from '@src/shared/constants/variables-local-storage';
import { useRecent } from '@src/shared/hooks/useRecent';
import { useBilling } from '@src/shared/queries/useBilling';
import { useQuery } from '@tanstack/react-query';

import { getBoardsOfHub, getHub, getInviteesHub } from '../../api/hubs';
import { getShare } from '../../api/share';
import { useAppData } from '../../AppContext';
import { DocumentHead } from '../../components/DocumentHead';
import {
  BOARDS_OF_HUB_QUERY,
  BOARDS_TREE_QUERY,
  HUB_QUERY,
  MEMBERS_INVITATION_HUB_QUERY,
} from '../../shared/constants';
import { FileCard } from '..';
import { HubActionItems } from './components/ActionItems';
import { Boards } from './components/Boards';
import { Container } from './components/Container';
import { Details } from './components/Details';
import { Header } from './components/Header';
import {
  backgroundSelected,
  colorPicked,
  currentHeaderNameSetted,
  thumbnailHexSetted,
  thumbnailSetted,
} from './components/Header/model';
import { LockedView } from './components/LockedView';
import { HubMessageCenter } from './components/MessageCenter';
import { Settings } from './components/Settings';
import { SettingSheet } from './components/SettingsSheet';
import { VaiCard } from './components/VaiCard';
import { Welcome } from './components/Welcome';
import { useHubActions } from './model/queries/useHubActions';
import { useHubMessages } from './model/queries/useHubMessages';
import { useHubs } from './model/queries/useHubs';

import styles from './styles.module.css';

const cardsBreakpoints = {
  default: 3,
  1300: 3,
  1279: 2,
  767: 1,
};

export type HubShare = {
  hub: string;
  id: string;
  link: string;
  allow_comments: boolean;
};

export const HubPage = () => {
  const { hub_id } = useParams<{ hub_id: string }>();

  return <HubPageComponent key={hub_id} hub_id={hub_id} />;
};

const HubPageComponent = ({ hub_id }: { hub_id: string }) => {
  const [name, setName] = useState('');
  const [hubShare, setHubShare] = useState<HubShare | null>(null);

  const isShowBoardDialog = useUnit($isShowBoardDialog);

  const { user } = useAppData();
  const history = useHistory();

  const isMedium = useMediaQuery({
    query: `(min-width: 767px) and (max-width: 1279px)`,
  });

  const {
    data: hub,
    isLoading: isLoadingHub,
    isError: isHubError,
    error: hubError,
  } = useQuery({
    queryKey: [HUB_QUERY, { hubId: hub_id }, user.show_archived],
    queryFn: () => getHub({ hubId: hub_id, is_archived: user.show_archived }),
    refetchInterval: 180000,
  });
  const { updateHubCustomColorsMutation } = useHubs();
  const [customColors, setCustomColors] = useState<string[]>([]);

  const isLoadingDelay = useLoadingDelay({
    enableLoadingDelay: typeof hub === 'undefined',
  });

  const { query: billingQuery, isWithinThreeDays } = useBilling();

  const getDateOfBilling = (isSettingsSheet?: boolean) => {
    if (billingQuery.data && billingQuery.data.current_membership_period_end) {
      const date = format(
        billingQuery.data.current_membership_period_end * 1000,
        'MMMM d, yyyy',
      );
      const month = date.split(' ')[0];
      const number = date.split(' ')[1];
      const year = date.split(' ')[2];

      return isSettingsSheet
        ? `Your next bill is on ${month} ${number} ${year}.`
        : `Your next bill is on ${month} ${number} ${year}`;
    }

    return null;
  };

  const { data: boards, isLoading: isLoadingBoards } = useQuery({
    queryKey: [BOARDS_OF_HUB_QUERY, { hub: hub_id }, user.show_archived],
    queryFn: () =>
      getBoardsOfHub({
        hubId: hub_id,
        ordering: '-created_at',
        is_archived: user.show_archived,
      }),
    refetchInterval: 180000,
  });

  useQuery({
    queryKey: [BOARDS_TREE_QUERY, user.show_archived],
    queryFn: () =>
      getBoards({ view_type: 'tree', is_archived: user.show_archived }),
  });

  const boardsWithRightAccess = boards?.filter(
    (board) => board.permissions.length > 0,
  );

  const { data: invitees } = useQuery({
    queryKey: [MEMBERS_INVITATION_HUB_QUERY, { hub_id }],
    queryFn: () => getInviteesHub(hub_id),
  });

  const isOwner = invitees?.members?.find(
    (member) => member.user?.id === user.id,
  )?.is_owner;

  const { items } = useHubActions({ id: hub_id });
  const { messagesQuery } = useHubMessages({ id: hub_id });

  useEffect(() => {
    if (hub) {
      currentHeaderNameSetted(hub.name);

      if (hub.thumbnail) {
        thumbnailSetted(hub.thumbnail);
        backgroundSelected(hub.thumbnail);
      } else if (hub.thumbnail_hex) {
        thumbnailHexSetted(hub.thumbnail_hex);
        backgroundSelected(hub.thumbnail_hex);
      }
    }
  }, [hub]);

  useEffect(() => {
    const getShareFromId = async (shareId: string) => {
      const hubShare = await getShare(shareId);
      setHubShare(hubShare);
    };
    if (hub && hub?.share?.id) {
      getShareFromId(hub.share.id);
    } else {
      setHubShare(null);
    }
  }, [hub]);

  useEffect(() => {
    if (hub) {
      setName(hub.name);
      colorPicked(hub.thumbnail_hex);
    }
  }, [hub]);

  useRecent({
    key: RECENT_HUBS_KEY,
    item: {
      id: hub_id,
      name: hub?.name ?? '',
    },
  });

  useEffect(() => {
    if (isShowBoardDialog && hub) {
      locationSelected({
        id: hub.id,
        name: hub.name,
        type: 'hub',
      });
    }
  }, [hub, isShowBoardDialog]);

  useEffect(() => {
    if (hub?.custom_colors) {
      setCustomColors(hub.custom_colors);
    }
  }, [hub?.custom_colors]);

  const updateCustomColors = (customColor: string) => {
    if (!customColors.includes(customColor)) {
      setCustomColors((prevColors) => {
        const updatedColors = [...prevColors, customColor];
        updateHubCustomColorsMutation({
          hubId: hub?.id,
          custom_colors: updatedColors,
        });
        return updatedColors;
      });
    }
  };

  if (isHubError && hubError.message.includes('404')) {
    history.push('/error');
  }

  if (
    isLoadingDelay ||
    isLoadingHub ||
    isLoadingBoards ||
    billingQuery.isLoading
  )
    return (
      <div className={styles.hubPage}>
        <BrandLoader.CenterContainer>
          <BrandLoader
            animatedIcon="hub"
            subText="Freshening up your workspace..."
          />
        </BrandLoader.CenterContainer>
      </div>
    );

  if (!hub) return <MissingContent />;

  return (
    <>
      <FileCard />
      <div className={styles.hubPage}>
        <Container>
          {hub.is_archived && (
            <ArchiveBanner
              className={isMobileOnly ? styles.archiveBanner : undefined}
              id={hub.id}
              entity="Hub"
            />
          )}
          <DocumentHead name={name} />
          <Header
            thumbnail={hub.thumbnail && hub.thumbnail.full_size}
            thumbnailHex={hub.thumbnail_hex}
            name={name}
            hubId={hub.id}
            thumbnailBrightness={hub.thumbnail_brightness}
            customColors={customColors}
            updateCustomColors={updateCustomColors}
            isArchived={hub.is_archived}
          />
          <div className={styles.innerContainer}>
            {isMedium ? (
              <Masonry
                breakpointCols={cardsBreakpoints}
                className={styles.cards}
                columnClassName={styles.cardsColumn}
              >
                <Welcome
                  title={hub.welcome_title}
                  body={hub.welcome_message}
                  isArchived={hub.is_archived}
                />
                <Details
                  description={hub.description}
                  location={hub.project_details?.location}
                  isArchived={hub.is_archived}
                />
                <VaiCard
                  hubId={hub.id}
                  hubName={hub.name}
                  isArchived={hub.is_archived}
                />
                <HubActionItems
                  items={items.data}
                  hub={hub}
                  isArchived={hub.is_archived}
                />
                <HubMessageCenter
                  messages={messagesQuery.data}
                  hub={hub}
                  isArchived={hub.is_archived}
                />
                <Settings
                  hubShare={hubShare}
                  getDateOfBilling={getDateOfBilling}
                  isOwner={isOwner}
                  isArchived={hub.is_archived}
                />

                {/* <LatestItems /> */}
              </Masonry>
            ) : (
              <Masonry
                breakpointCols={cardsBreakpoints}
                className={styles.cards}
                columnClassName={styles.cardsColumn}
              >
                <Welcome
                  title={hub.welcome_title}
                  body={hub.welcome_message}
                  isArchived={hub.is_archived}
                />
                <Details
                  description={hub.description}
                  location={hub.project_details?.location}
                  isArchived={hub.is_archived}
                />
                <Settings
                  hubShare={hubShare}
                  getDateOfBilling={getDateOfBilling}
                  isOwner={isOwner}
                  isArchived={hub.is_archived}
                />
                <VaiCard
                  hubId={hub.id}
                  hubName={hub.name}
                  isArchived={hub.is_archived}
                />
                <HubActionItems
                  items={items.data}
                  hub={hub}
                  isArchived={hub.is_archived}
                />
                <HubMessageCenter
                  messages={messagesQuery.data}
                  hub={hub}
                  isArchived={hub.is_archived}
                />
                {/* <LatestItems /> */}
              </Masonry>
            )}

            <Boards
              boards={boardsWithRightAccess}
              isArchived={hub.is_archived}
            />
            {hub.is_locked && billingQuery.data ? (
              <LockedView
                isLocked={hub.is_locked}
                currentPlan={billingQuery.data.membershipTierInfo.plan}
                isOwner={typeof isOwner === 'undefined' ? false : isOwner}
              />
            ) : null}
            {hub.is_locked && billingQuery.data ? (
              <LockedView
                isLocked={hub.is_locked}
                currentPlan={billingQuery.data.membershipTierInfo.plan}
                isOwner={typeof isOwner === 'undefined' ? false : isOwner}
              />
            ) : null}
          </div>
        </Container>
        <SettingSheet
          hubShare={hubShare}
          setHubShare={setHubShare}
          hubId={hub_id}
          hubName={hub.name}
          isWithinThreeDays={isWithinThreeDays}
          getDateOfBilling={getDateOfBilling}
          isOwner={isOwner}
        />
        <LibraryActions
          isHubLocked={hub.is_locked}
          isArchived={hub.is_archived}
        />
      </div>
    </>
  );
};

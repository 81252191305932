import { createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';

import { entityTypeCleared } from '@src/shared/utils/get-entity-type';

export const fileIdSelected = createEvent<string[]>();
export const fileIdCleared = createEvent();

export const $fileId = createStore<string[] | null>(null);

export const fileDeleteDialogOpened = createEvent();
export const fileDeleteDialogClosed = createEvent();

export const $isFileDeleteDialogOpen = createStore(false);

$fileId.on(fileIdSelected, (_, id) => id).reset(fileIdCleared);

$isFileDeleteDialogOpen
  .on(fileDeleteDialogOpened, () => true)
  .reset(fileDeleteDialogClosed);

sample({
  clock: fileDeleteDialogClosed,
  target: entityTypeCleared,
});

hotkey({
  key: 'Escape',
  filter: $isFileDeleteDialogOpen,
  target: fileDeleteDialogClosed,
});

import { useRef } from 'react';

// import { useUnit } from 'effector-react';
// import { isMobileOnly } from 'react-device-detect';
import { useLocation } from 'react-router';

import { OnboardingScreens } from './onboarding-screens';
import { TrialProOnboarding } from './onboarding-screens/trial-pro-onboarding';
import { useOnboardingState } from './useOnboardingState';

// import {
//   Button,
//   Dialog,
//   Modal,
//   Switch,
//   TooltipRadix,
//   TypographyPoppins,
// } from '@visualist/design-system/src/components/v2';
// import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
// import { resetOnboarding } from '@api/account';
// import { Step, useAppData } from '@src/AppContext';
// import { $isShowHubDialog } from '@src/entities/dialogs/hub/model';
// import {
// OnboardingTooltip,
// onboardingTooltipContentMapper,
// } from '@src/entities/onboarding-tooltip';
// import { disabledLoadingDemoMode, enabledLoadingDemoMode } from '@src/model';
// import { useMutation, useQueryClient } from '@tanstack/react-query';
// import { hasUserCompletedAllPaths } from './helpers';
import // $hideTooltipForSession,
// $showDemoWarning,
// $showTooltip,
// hideDemoWarning,
// hidingTooltipForSession,
'./model';
// import { useUserOnboarding } from './useUserOnboarding';

// import styles from './styles.module.css';

export const Onboarding = () => {
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  // const first = urlSearchParams.get('first');
  const usedPromo = urlSearchParams.get('usedPromo');
  // const isOnWelcomeScreen = first === 'true';
  const skipOfferCode = usedPromo === 'true';
  const tooltipRef = useRef<HTMLDivElement>(null);
  const {
    // goToNextStep,
    // currentStep,
    // onboardingState,
    // endDemoMode,
    updateOnboardingPath,
    // getNextStepLabel,
    // getNextPath,
  } = useOnboardingState({
    tooltipRef,
  });

  return (
    <>
      <OnboardingScreens
        updateOnboardingPath={updateOnboardingPath}
        initialScreen={skipOfferCode ? 'welcome' : 'promoScreen'}
      />
      <TrialProOnboarding />
      {/* {onboardingState.demoMode ? (
        <>
          <DemoWarning />
          <Banner endDemoMode={endDemoMode} />
          {isOnWelcomeScreen ? null : (
            <TooltipContainer
              key={currentStep.title}
              ref={tooltipRef}
              goToNextStep={goToNextStep}
              currentStep={currentStep}
              getNextStepLabel={getNextStepLabel}
              getNextPath={getNextPath}
            />
          )}
        </>
      ) : null} */}
    </>
  );
};

// const TooltipContainer = forwardRef<
//   HTMLDivElement,
//   {
//     currentStep: Step;
//     goToNextStep: () => Promise<void>;
//     getNextStepLabel: () => string | false;
//     getNextPath: () => string | false;
//   }
// >(({ currentStep, goToNextStep, getNextStepLabel, getNextPath }, ref) => {
//   const [isShowingTooltip, hideTooltipForSession, isShowHubDialog] = useUnit([
//     $showTooltip,
//     $hideTooltipForSession,
//     $isShowHubDialog,
//   ]);
//   const { user } = useAppData();
//   const tooltipRef = useRef<HTMLDivElement>(null);

//   const url = window.location.href;

//   // For if the tooltip is only for a specific page and is of page type
//   const showTooltipPageFilterCheck = currentStep.urlFilter
//     ? url.includes(currentStep.urlFilter)
//     : true;

//   useImperativeHandle(
//     ref,
//     () => {
//       return tooltipRef.current as HTMLDivElement;
//     },
//     [],
//   );

//   const isLastNextStepLabel = getNextStepLabel();
//   const lastStepLabel =
//     typeof isLastNextStepLabel === 'string'
//       ? isLastNextStepLabel
//       : currentStep.nextActionTitle;

//   const showTooltip =
//     isShowingTooltip &&
//     !hasUserCompletedAllPaths(user) &&
//     showTooltipPageFilterCheck &&
//     hideTooltipForSession !== currentStep.title &&
//     !isShowHubDialog &&
//     !isMobileOnly;

//   return (
//     <div
//       style={{
//         zIndex:
//           currentStep.title === 'Spend your time on what matters'
//             ? 10000
//             : undefined,
//         position: 'absolute',
//         ...(currentStep.type === 'page'
//           ? {
//               top: `${currentStep.position.top}%`,
//               left: `${currentStep.position.left}%`,
//               transform: `translate(-50%, -50%)`,
//             }
//           : {}),
//       }}
//       ref={tooltipRef}
//       className={styles.tooltip}
//     >
//       {showTooltip ? (
//         <OnboardingTooltip
//           title={currentStep.title}
//           onClose={() => hidingTooltipForSession(currentStep.title)}
//           actionForward={{
//             label: lastStepLabel,
//             onClick: goToNextStep,
//           }}
//         >
//           {onboardingTooltipContentMapper(
//             currentStep.content,
//             getNextPath() === false,
//           )}
//         </OnboardingTooltip>
//       ) : null}
//     </div>
//   );
// });

// const Banner = ({ endDemoMode }: { endDemoMode: () => Promise<void> }) => {
//   const history = useHistory();
//   const queryClient = useQueryClient();
//   const { resetOnboardingTooltips } = useUserOnboarding();

//   const mutateResetOnboarding = useMutation({
//     mutationFn: resetOnboarding,
//     onMutate: () => {
//       enabledLoadingDemoMode();
//     },
//     onSuccess: async () => {
//       await queryClient.refetchQueries();
//     },
//     onError: () => {
//       startedSnack({
//         label: 'An error occurred',
//       });
//     },
//     onSettled: () => {
//       disabledLoadingDemoMode();
//     },
//   });

//   return (
//     <div
//       className={styles.banner}
//       data-hide-margin={history.location.pathname.includes('account')}
//     >
//       <TooltipRadix description="Toggle to exit demo">
//         <Switch
//           value={true}
//           onValueChange={async () => {
//             await endDemoMode();
//           }}
//         />
//       </TooltipRadix>
//       <TypographyPoppins
//         type="body"
//         size="M"
//         style={{
//           fontSize: isMobileOnly ? '0.7rem' : undefined,
//         }}
//         className={styles.bannerTitle}
//       >
//         Toggle to exit demo. Want to go through onboarding again?
//       </TypographyPoppins>
//       <Button
//         type="outlined"
//         label="Restart demo"
//         onClick={async () => {
//           await resetOnboardingTooltips();
//           history.push('/home');
//           mutateResetOnboarding.mutate();
//         }}
//       />
//     </div>
//   );
// };

// const DemoWarning = () => {
//   const { endDemoMode } = useUserOnboarding();
//   const [showDemoWarning] = useUnit([$showDemoWarning]);

//   return (
//     <Modal handleClose={() => hideDemoWarning()} showModal={showDemoWarning}>
//       <Dialog
//         closeAction={() => {
//           setTimeout(() => {
//             hideDemoWarning();
//           }, 300);
//         }}
//         action={() => endDemoMode()}
//         actionLabel="Exit demo"
//         headline="There's a problem"
//         description="Sorry, this feature is not available when you’re in demo mode."
//         supportingText="Get out of demo mode and try again."
//       />
//     </Modal>
//   );
// };

import { AxiosResponse } from 'axios';

import { User } from '@src/AppContext';
import { UpdateableStickyData } from '@src/entities/Stickies/useStickies';

import { StickyType, StickyUpdateDataType } from '../entities/Stickies/types';
import { api } from './services';

type Emoji = {
  code: string;
  firstName?: string;
  lastName?: string;
};

type EmojiComment = {
  code: string;
  comment: string;
};

export type Comment = {
  id: string;
  parent: string | null;
  mention_users: string[];
  reactions: [];
  created_by: User;
  text: string;
  created_at: string;
  sticker: string;
  action_sticky: StickyType['action_sticky'] | null;
};

export const addStickerToImage = async (
  id: string,
  stickyData: StickyUpdateDataType &
    Pick<StickyType, 'top_pixel' | 'left_pixel'>,
): Promise<StickyType> => {
  const { data } = await api.post('/stickies/', { ...stickyData, block: id });
  return data;
};

export const addCommentToSticker = async (
  id: string,
  stickyData: StickyUpdateDataType,
): Promise<StickyType> => {
  const { data } = await api.post(`/stickies/${id}/comment/`, {
    ...stickyData,
  });
  return data;
};

export const fetchStickersOfImage = async (
  id: string,
): Promise<AxiosResponse<Array<StickyType>>> => {
  return await api.get('/stickies/', {
    params: {
      block: id,
    },
  });
};

export const undoStickyDelete = async (id: string) => {
  await api.post(`/stickies/${id}/undo_delete/`);
};

export const undoStickyCommentDelete = async (
  stickyId: string,
  commentId: string,
) => {
  await api.post(`/stickies/${stickyId}/comment/${commentId}/undo_delete/`);
};

export const fetchCommentsOfStickersOfImage = async (
  id: string | null,
): Promise<Array<Comment>> => {
  const { data } = await api.get(`/stickies/${id}/comment/`);
  return data;
};

export const deleteCommentsOfStickersOfImage = async (
  stickyId: string,
  commentId: string,
) => {
  const response = await api.delete(
    `/stickies/${stickyId}/comment/${commentId}`,
  );
  return response;
};

export const updateCommentsOfStickersOfImage = async (
  id: string,
  commentId: string,
  payload: { text: string },
) => {
  const { data } = await api.patch(`/stickies/${id}/comment/${commentId}/`, {
    ...payload,
  });
  return data;
};

export const deleteStickersOfImage = async (id: string) => {
  const response = await api.delete(`/stickies/${id}/`);
  return response;
};

export const updateStickersOfImage = async (
  id: string,
  payload: UpdateableStickyData,
) => {
  const { data } = await api.patch(`/stickies/${id}/`, {
    ...payload,
    attached_to_id: payload.attached_to_id || null,
    attached_to_type: payload.attached_to_type || null,
  });
  return data;
};

export const bulkUpdateStickies = async (
  payload: (Pick<
    UpdateableStickyData,
    'attached_to_id' | 'attached_to_type' | 'left_pixel' | 'top_pixel'
  > & { id: string })[],
) => {
  const { data } = await api.post(
    '/stickies/bulk_update/',
    payload.map(
      (
        p,
      ): Pick<
        UpdateableStickyData,
        'attached_to_id' | 'attached_to_type' | 'left_pixel' | 'top_pixel'
      > & { id: string } => ({
        id: p.id,
        left_pixel: p.left_pixel,
        top_pixel: p.top_pixel,
        attached_to_id: p.attached_to_id || undefined,
        attached_to_type: p.attached_to_type || undefined,
      }),
    ),
  );
  return data;
};

export const addEmojiToSticker = async (id: string, data: Emoji) =>
  await api.post(`/stickies/${id}/emoji/`, data);

export const deleteEmojiFromSticker = async (id: string, emojiId: string) =>
  await api.delete(`/stickies/${id}/emoji/${emojiId}`);

export const addEmojiToComment = async (id: string, data: EmojiComment) =>
  await api.post(`/stickies/${id}/emoji/`, data);

import { useState } from 'react';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { OptionKey } from '@src/AppContext';

import { DemoOptions } from '../demoOptions';
import { PlaceholderMedia } from '../placeholderMedia';
import { Progress } from '../progress';

import styles from './styles.module.css';

interface MainScreenProps {
  onNextClick: () => void;
  selectedOption: OptionKey;
  onOptionChange: (option: OptionKey) => void;
  handleCloseModal: () => void;
  skipOnboarding: () => void;
}

export const MainScreen = ({
  onNextClick,
  selectedOption,
  onOptionChange,
  skipOnboarding,
}: MainScreenProps) => {
  const [hoveredOption, setHoveredOption] = useState<OptionKey | null>(null);

  const mediaUrls = {
    createHubs: '/assets/gifs/create-hubs.gif',
    workCreatively: '/assets/gifs/work-creatively.gif',
    manageBusinessAdmin: '/assets/gifs/manage-business-admin.gif',
  };

  const currentMediaUrl =
    hoveredOption !== null
      ? mediaUrls[hoveredOption]
      : mediaUrls[selectedOption];

  return (
    <div className={styles.modalContentWrap}>
      <Progress
        onNextClick={onNextClick}
        currentStep={1}
        onSkip={skipOnboarding}
      />
      <div className={styles.headlineWrap}>
        <TypographyPoppins type="headline" size="L" className={styles.headline}>
          Welcome to your new home
        </TypographyPoppins>
      </div>
      <TypographyPoppins type="body" size="M" className={styles.welcomeBody}>
        What are you looking to do in Visualist?
      </TypographyPoppins>
      <div className={styles.placeholderMedia}>
        <PlaceholderMedia src={currentMediaUrl} />
      </div>
      <DemoOptions
        selectedKey={selectedOption}
        onRadioClick={onOptionChange}
        onRadioHover={(key: OptionKey | null) => setHoveredOption(key)}
      />
      <div className={styles.actionButtonWrap}>
        <Button
          type="filled"
          label="Next"
          onClick={onNextClick}
          className={styles.welcomeButton}
        />
        <Button
          type="ghost"
          label="I'm not sure yet"
          onClick={() => {
            onOptionChange('createHubs');
            onNextClick();
          }}
          className={styles.welcomeScreenNotSureButton}
        />
      </div>
    </div>
  );
};

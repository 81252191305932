import { AxiosResponse } from 'axios';

import { getBlocks, ImageGenericBlockResponse } from '@pages/StudioPage/api';
import { useAppData } from '@src/AppContext';
import { GET_BLOCKS_QUERY } from '@src/shared/constants/query-names';
import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query';

// import { getBlocks } from '@pages/NewStudioPage/api';

const filterOutNonImageBlocks = (
  data: InfiniteData<AxiosResponse<ImageGenericBlockResponse>>,
) => {
  // Filter out all blocks that are not images or color palettes
  const filteredPages = data.pages.map((page) => {
    const filteredResults = page.data.results.filter((result) => {
      return (
        result.block_type === 'Image' || result.block_type === 'Colour palette'
      );
    });

    return { ...page, data: { ...page.data, results: filteredResults } };
  });

  return {
    ...data,
    pages: filteredPages,
  };
};

export const useBlocks = () => {
  const { user } = useAppData();
  const blocksQuery = useInfiniteQuery({
    queryKey: [GET_BLOCKS_QUERY, user.show_archived],
    queryFn: ({ pageParam }) =>
      getBlocks({
        ordering: '-created_at',
        offset: parseInt(pageParam),
        is_archived: user.show_archived,
      }),
    select: filterOutNonImageBlocks,
    initialPageParam: '0',
    getNextPageParam: (lastGroup) => {
      return (
        lastGroup.data.next &&
        new URL(lastGroup.data.next).searchParams.get('offset')
      );
    },
  });

  return { blocksQuery };
};

import { useLocation } from 'react-router';

import { getAllCopiesFile } from '@api/blocks';
import { ALL_FILE_COPIES } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

import { useBlock } from '../useBlock';

export const useFileCopies = () => {
  const location = useLocation();
  const fileId = location.hash.replace('#/f/', '');

  const { data: block } = useBlock(fileId);
  const imageName = block?.file.full_size.split('/').slice(5).join('/');

  const {
    data: fileCopies,
    isFetchedAfterMount,
    isFetching,
  } = useQuery({
    queryKey: [ALL_FILE_COPIES, fileId],
    queryFn: () => getAllCopiesFile(imageName),
    enabled: !!block?.id,
  });

  return {
    fileCopies,
    isFetchedAfterMount,
    isFetching,
    fileId,
  };
};

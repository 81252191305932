import { SnackError } from './types';

export const CHECKOUT_SUCCESS = 'checkout_success';
export const CHECKOUT_FAILURE = 'checkout_failure';
export const HUB_CREDIT_CHECKOUT_FAILURE = 'hub_credit_checkout_failure';

export const saveCheckoutFailureToLocalStorage = (value: SnackError) => {
  saveValueTolocalStorage(CHECKOUT_FAILURE, value);
};

export const saveHubCreditCheckoutBundleId = (value: string) => {
  saveValueTolocalStorage(HUB_CREDIT_CHECKOUT_FAILURE, value);
};

export const readHubCreditCheckoutBundleId = () => {
  const s = readValueFromLocalStorageAndDelete(HUB_CREDIT_CHECKOUT_FAILURE);

  if (s) return s.replaceAll('"', '');
  return null;
};

export const generateCurrentPathURL = () => {
  const url = new URL(window.location.href);

  return removeTrailingSlash(`${url.pathname}`);
};

/**
 * Needs the full url passed
 *
 * @param url
 * @param paramsToRemove
 * @returns
 */

export function removeParamFromURL(
  url: string,
  paramsToRemove?: Array<string>,
) {
  try {
    const urlObject = new URL(url);

    if (!paramsToRemove) {
      // Clear all search params when no specific params are provided
      urlObject.search = '';
    } else {
      paramsToRemove.forEach((param) => {
        urlObject.searchParams.delete(param);
      });
    }

    return urlObject;
  } catch (error) {
    console.error("Something went wrong in removing params from url's", error);
    throw new Error('Failed to parse url');
  }
}

const removeTrailingSlash = (url: string) =>
  url.endsWith('/') ? url.slice(0, -1) : url;

export const formatAnnualPrice = (n: number, isAnnual: boolean) => {
  return isAnnual ? n / 100 / 12 : n / 100;
};

export const saveValueTolocalStorage = (key: string, value: string) => {
  // TODO error handling for if local storage cannot be set.
  localStorage.setItem(key, JSON.stringify(value));
};

export const readValueFromLocalStorageAndDelete = (key: string) => {
  const value = localStorage.getItem(key);
  localStorage.removeItem(key);
  return value ? value : null;
};

import { useEffect, useState } from 'react';

import { debounce } from 'lodash';

export const useDebounce = <T>(value: T, delay = 400) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  const debounced = debounce(setDebouncedValue, delay);

  useEffect(() => {
    debounced(value);

    return () => {
      debounced.cancel();
    };
  }, [value, debounced]);

  return debouncedValue;
};

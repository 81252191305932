import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Stage } from 'konva/lib/Stage';
import { Group, Image as KonvaImage, Rect, Transformer } from 'react-konva';

import { useKeyPress } from '@visualist/hooks';

import { GRETNA_40, TERTIARY_40 } from '@src/shared/constants/colours';

type Box = {
  x: number;
  y: number;
  height: number;
  width: number;
};

const CROP_PADDING = 1;

export const CroppingImage = ({
  canvasPosition,
  image,
  rotation,
  onCropComplete,
  onCropCancel,
  initialCrop,
  originalWidth,
  originalHeight,
  stageRef,
}: {
  image?: HTMLImageElement;
  canvasPosition: Box;
  rotation: number;
  onCropComplete: (props: { originalCrop: Box; newPosition: Box }) => void;
  onCropCancel: () => void;
  initialCrop: Box | null;
  originalWidth: number;
  originalHeight: number;
  stageRef: MutableRefObject<Stage | null>;
}) => {
  // Create refs for the crop area and transformer
  const imageRef = useRef<Konva.Image>(null);
  const clipRectRef = useRef<Konva.Rect | null>(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const hasMadeChange = useRef(false);

  const [imagePosition] = useState<{
    x: number;
    y: number;
    height: number;
    width: number;
  }>(() => {
    if (initialCrop) {
      const cropWindowWidthRatio = canvasPosition.width / initialCrop.width;
      const cropWindowHeightRatio = canvasPosition.height / initialCrop.height;

      // Convert coordinates taking rotation into account
      const angleRad = (rotation * Math.PI) / 180;
      const cropWindowLeft = initialCrop.x * cropWindowWidthRatio;
      const cropWindowTop = initialCrop.y * cropWindowHeightRatio;

      // Apply rotation transformation
      const rotatedX =
        cropWindowLeft * Math.cos(angleRad) -
        cropWindowTop * Math.sin(angleRad);
      const rotatedY =
        cropWindowLeft * Math.sin(angleRad) +
        cropWindowTop * Math.cos(angleRad);

      return {
        x: canvasPosition.x - rotatedX,
        y: canvasPosition.y - rotatedY,
        width: originalWidth * cropWindowWidthRatio,
        height: originalHeight * cropWindowHeightRatio,
      };
    }

    // Use provided canvas position as is, because the image has no crop so the x and y will match the original image
    return {
      x: canvasPosition.x,
      y: canvasPosition.y,
      width: canvasPosition.width,
      height: canvasPosition.height,
    };
  });

  const [clipRect, setClipRect] = useState<{
    x: number;
    y: number;
    height: number;
    width: number;
  }>(() => {
    if (initialCrop) {
      // Initial crop is a square window positioned inside the image where the hegiht and width of the image is the originalHeight and originalWidth
      // We dont need rotation as these positions are for local space so rotation in  local space is always 0
      // Get the ratio of the size of the crop window against the images size in the canvas scale
      const cropWindowHeightRatio = canvasPosition.height / initialCrop.height;
      const cropWindowWidthRatio = canvasPosition.width / initialCrop.width;

      // Convert to canvas scale from original image scale
      const cropWindowLeft = initialCrop.x * cropWindowWidthRatio;
      const cropWindowRight = initialCrop.y * cropWindowHeightRatio;

      return {
        x: cropWindowLeft,
        y: cropWindowRight,
        width: initialCrop.width * cropWindowWidthRatio,
        height: initialCrop.height * cropWindowHeightRatio,
      };
    } else {
      return {
        x: 0,
        y: 0,
        width: canvasPosition.width,
        height: canvasPosition.height,
      };
    }
  });

  const confirmedCrop = () => {
    const cropRect = clipRectRef.current;
    if (!hasMadeChange.current || !cropRect) {
      onCropCancel();
      return;
    }

    // Calculate scale factors to convert from display dimensions to original image dimensions
    const scaleX = originalWidth / imagePosition.width;
    const scaleY = originalHeight / imagePosition.height;

    // Get current crop rectangle coordinates
    const currentX = cropRect.x();
    const currentY = cropRect.y();
    const currentWidth = cropRect.width() * cropRect.scaleX();
    const currentHeight = cropRect.height() * cropRect.scaleY();

    // Convert rotation to radians
    const angleRad = (rotation * Math.PI) / 180;

    // Keep originalCrop simple as it works
    const originalX = currentX * scaleX;
    const originalY = currentY * scaleY;

    // Apply rotation only to newPosition
    const rotatedX =
      currentX * Math.cos(angleRad) - currentY * Math.sin(angleRad);
    const rotatedY =
      currentX * Math.sin(angleRad) + currentY * Math.cos(angleRad);

    onCropComplete({
      originalCrop: {
        x: originalX,
        y: originalY,
        width: currentWidth * scaleX,
        height: currentHeight * scaleY,
      },
      newPosition: {
        x: imagePosition.x + rotatedX,
        y: imagePosition.y + rotatedY,
        width: currentWidth,
        height: currentHeight,
      },
    });
  };

  // Handle Escape key
  useKeyPress({
    key: 'Escape',
    onKeyDown: onCropCancel,
  });

  /**
   * The originalCrop x, y, width and height is the crop window of the original image size
   */

  // Handle Enter key
  useKeyPress({
    key: 'Enter',
    onKeyDown: confirmedCrop,
  });

  // Set up initial crop area
  React.useLayoutEffect(() => {
    if (transformerRef.current && clipRectRef.current) {
      if (initialCrop) {
        const cropWindowHeightRatio =
          canvasPosition.height / initialCrop.height;
        const cropWindowWidthRatio = canvasPosition.width / initialCrop.width;

        const cropWindowLeft = initialCrop.x * cropWindowWidthRatio;
        const cropWindowRight = initialCrop.y * cropWindowHeightRatio;

        clipRectRef.current.setAttrs({
          x: cropWindowLeft,
          y: cropWindowRight,
          width: initialCrop.width * cropWindowWidthRatio,
          height: initialCrop.height * cropWindowHeightRatio,
        });
      } else {
        clipRectRef.current.setAttrs({
          x: CROP_PADDING,
          y: CROP_PADDING,
          width: imagePosition.width - CROP_PADDING,
          height: imagePosition.height - CROP_PADDING,
        });
      }

      transformerRef.current.nodes([clipRectRef.current]);
      transformerRef.current.getLayer()?.batchDraw();
    }
  }, []);

  // Creates a listener for off crop area clicks to either dismiss or confirm crop
  useEffect(() => {
    if (!stageRef.current) return;

    const handleStageClick = (e: KonvaEventObject<MouseEvent>) => {
      if (e.target === stageRef.current) {
        confirmedCrop();
      }
    };

    stageRef.current.on('click', handleStageClick);

    return () => {
      if (!stageRef.current) return;
      stageRef.current.off('click', handleStageClick);
    };
  }, []);

  return (
    <Group
      x={imagePosition.x}
      y={imagePosition.y}
      width={imagePosition.width}
      height={imagePosition.height}
      rotation={rotation}
    >
      {/* Original image */}
      <KonvaImage
        opacity={0.5}
        ref={imageRef}
        x={0}
        y={0}
        image={image}
        width={imagePosition.width}
        height={imagePosition.height}
      />

      {/* Crop image overlay */}
      <Group
        clipFunc={(ctx) => {
          ctx.save();
          ctx.beginPath();
          ctx.rect(clipRect?.x, clipRect?.y, clipRect?.width, clipRect?.height);
          ctx.closePath();
          ctx.restore();
        }}
        x={0}
        y={0}
        width={imagePosition.width}
        height={imagePosition.height}
      >
        <KonvaImage
          x={0}
          y={0}
          image={image}
          width={imagePosition.width}
          height={imagePosition.height}
        />
      </Group>

      {/* Crop selection area - now initialized to full image size */}
      <Rect
        ref={clipRectRef}
        stroke={TERTIARY_40}
        strokeWidth={1}
        name="crop-rect"
        draggable
        // Use only relative positions. Avoid dragBoundFunc as it uses absolute positioning and that becomes complex with rotations are involved
        onDragMove={() => {
          if (!clipRectRef.current || !imageRef.current) return;

          hasMadeChange.current = true;

          // These are equivalent:
          // e.target.x(), clipRectRef.current.x()

          // Keep drag within image bounds
          const imageScaleX = imageRef.current.scaleX();
          const imageScaleY = imageRef.current.scaleY();
          const imageW = imageRef.current.width() * imageScaleX;
          const imageH = imageRef.current.height() * imageScaleY;

          const clipRectX = clipRectRef.current.x();
          const clipRectY = clipRectRef.current.y();
          const clipRectScaleX = clipRectRef.current.scaleX();
          const clipRectScaleY = clipRectRef.current.scaleY();
          const clipRectW = clipRectRef.current.width() * clipRectScaleX;
          const clipRectH = clipRectRef.current.height() * clipRectScaleY;

          clipRectRef.current.x(
            ~~Math.min(
              imageW - CROP_PADDING - clipRectW,
              Math.max(CROP_PADDING, clipRectX),
            ),
          );

          clipRectRef.current.y(
            ~~Math.min(
              imageH - CROP_PADDING - clipRectH,
              Math.max(CROP_PADDING, clipRectY),
            ),
          );

          setClipRect({
            x: ~~Math.min(
              imageW - CROP_PADDING - clipRectW,
              Math.max(CROP_PADDING, clipRectX),
            ),
            y: ~~Math.min(
              imageH - CROP_PADDING - clipRectH,
              Math.max(CROP_PADDING, clipRectY),
            ),
            width: ~~clipRectW,
            height: ~~clipRectH,
          });
        }}
      />

      {/* Transformer for crop area */}
      <Transformer
        ref={transformerRef}
        boundBoxFunc={(oldBox, newBox) => {
          if (!imageRef.current || !clipRectRef.current) return oldBox;

          const stageScale = stageRef.current?.getAbsoluteScale();
          if (!stageScale) return newBox;

          // Keep drag within image bounds
          const imageScaleX = imageRef.current.scaleX();
          const imageScaleY = imageRef.current.scaleY();
          const imageW = imageRef.current.width() * imageScaleX;
          const imageH = imageRef.current.height() * imageScaleY;

          const clipRectX = clipRectRef.current.x();
          const clipRectY = clipRectRef.current.y();
          const clipRectScaleX = clipRectRef.current.scaleX();
          const clipRectScaleY = clipRectRef.current.scaleY();
          const clipRectW = clipRectRef.current.width() * clipRectScaleX;
          const clipRectH = clipRectRef.current.height() * clipRectScaleY;

          // Convert absolute position to relative position using transform matrix
          const transform = imageRef.current.getAbsoluteTransform().copy();
          transform.invert();
          const relative = transform.point(newBox);
          const newBoxWidth = newBox.width / stageScale.x;
          const newBoxHeight = newBox.height / stageScale.y;

          // Check if relative position is within bounds
          const isInsideWithRelativeX =
            relative.x >= 0 && ~~(relative.x + newBoxWidth) <= imageW;
          const isInsideWithRelativeY =
            relative.y >= 0 && ~~(relative.y + newBoxHeight) <= imageH;

          // Check if user is shrinking the box
          const isShrinking =
            newBox.width <= oldBox.width && newBox.height <= oldBox.height;

          setClipRect({
            x: ~~Math.min(imageW - clipRectW, Math.max(0, clipRectX)),
            y: ~~Math.min(imageH - clipRectH, Math.max(0, clipRectY)),
            width: ~~clipRectW,
            height: ~~clipRectH,
          });

          // Allow shrinking regardless of bounds, but enforce bounds for expanding
          if (isShrinking || (isInsideWithRelativeX && isInsideWithRelativeY)) {
            hasMadeChange.current = true;
            return newBox;
          }

          return oldBox;
        }}
        rotateEnabled={false}
        borderStroke={GRETNA_40}
        anchorStroke={GRETNA_40}
        keepRatio={false}
        anchorStyleFunc={(anchor) => {
          // @ts-expect-error
          anchor.cornerRadius(10);
          anchor.height(10);
          anchor.width(10);
          anchor.fill(GRETNA_40);
        }}
        flipEnabled={false}
        enabledAnchors={[
          'top-right',
          'top-left',
          'bottom-right',
          'bottom-left',
        ]}
      />
    </Group>
  );
};

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import styles from '../styles.module.css';

export const EmptyBin = () => {
  return (
    <div className={styles.emptyBin}>
      <Icon name="sprite/bin-open-soft-colored" size={80} />
      <TypographyPoppins
        type="body"
        size="S"
        style={{ color: 'var(--color-secondary-40)' }}
      >
        This bin looks nice and empty
      </TypographyPoppins>
    </div>
  );
};

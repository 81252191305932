import { createEvent, createStore, Store } from 'effector';

const createMediaMatcher = (query: string): Store<boolean> => {
  const change = createEvent<MediaQueryListEvent>();
  const init = createEvent<boolean>();
  const mediaQueryList = window.matchMedia(query);

  const $isMatched = createStore(mediaQueryList.matches);
  $isMatched.on(init, (_, current) => current);
  $isMatched.on(change, (_, event) => event.matches);

  mediaQueryList.addEventListener('change', change);
  setTimeout(() => init(mediaQueryList.matches));

  return $isMatched;
};

const breakpoints = {
  small: '(max-width: 767px)',
  medium: '(min-width: 768px) and (max-width: 1279px)',
  large: '(min-width: 1279px)',
};

export const $isSmallBreakpoint = createMediaMatcher(breakpoints.small);
export const $isMediumBreakpoint = createMediaMatcher(breakpoints.medium);
export const $isLargeBreakpoint = createMediaMatcher(breakpoints.large);

import cn from 'classnames';
import { AnimatePresence } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router-dom';

import {
  Dropdown,
  Fab,
  Item,
  Palette,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { Icon } from '@visualist/icons';

import { useCreateDesign } from '@pages/StudioPage/hooks/use-create-design';
import { boardDialogOpened } from '@src/entities/dialogs/board/create/model/create';
import { hubDialogOpened } from '@src/entities/dialogs/hub/model';
import { paletteDialogOpened } from '@src/entities/dialogs/palette/model';
import { useBilling } from '@src/shared/queries/useBilling';

import { Board } from '../../api';
import { useCreateDoc } from '../../entities/doc/queries/useCreateDoc';
import { Plan } from '../../pages/AccountPage/components/types';
import { useBoardContext } from '../../pages/BoardPage';
import { Page } from '../LibraryActions/LibraryActions';

import styles from './ActionButton.module.css';

export type Props = {
  className?: string;
  mobile?: boolean;
  uploadAction: any;
  showFAB?: boolean;
  handleOpen?: any;
  handleClose?: any;
  currentBoard?: Board;
  page?: Page;
  plan?: Plan;
  isArchived?: boolean;
};

const ActionButton = ({
  className,
  uploadAction,
  page,
  plan,
  isArchived,
}: Props) => {
  const { board_id } = useParams<{ board_id: string }>();
  const { hasPermissionEditing } = useBoardContext();
  const { isWithinThreeDays } = useBilling();

  const createDoc = () => {
    board_id ? makeDoc({ board: board_id }) : makeDoc({});
  };

  const uploadImages = () => {
    uploadAction();
  };

  const { createDesignMutation } = useCreateDesign();

  const createDesign = () => {
    if (board_id) {
      createDesignMutation.mutate({ boardId: board_id });
    } else {
      createDesignMutation.mutate({});
    }
  };

  const menuItems: Item<Variant>[] = hasPermissionEditing
    ? [
        {
          leadingIcon: <Icon name="sprite/hub" />,
          content: 'Create hub',
          trailingIcon:
            plan === 'free' || plan === 'starter' ? (
              <TypographyPoppins
                className={styles.badge}
                type="label"
                size="XS"
              >
                Pro users only
              </TypographyPoppins>
            ) : null,
          isDisabled:
            plan === 'free' || plan === 'starter' || isWithinThreeDays,
          onClick: () => hubDialogOpened(),
        },
        {
          leadingIcon: <Icon name="sprite/board" />,
          content: 'Create board',
          onClick: () => boardDialogOpened(),
        },
        {
          leadingIcon: <Icon name="sprite/doc" />,
          content: 'Create doc',
          onClick: createDoc,
        },
        {
          leadingIcon: <Icon name="sprite/studio" />,
          content: 'Create design',
          onClick: createDesign,
        },
        {
          leadingIcon: <Icon name="sprite/upload" />,
          content: 'Upload images',
          onClick: uploadImages,
        },
        {
          leadingIcon: <Palette fill="none" />,
          content: 'Create palette',
          onClick: () => paletteDialogOpened(),
        },
      ]
    : [
        {
          leadingIcon: <Icon name="sprite/hub" />,
          content: 'Create hub',
          onClick: () => hubDialogOpened(),
        },
        {
          leadingIcon: <Icon name="sprite/board" />,
          content: 'Create board',
          onClick: () => boardDialogOpened(),
        },
      ];

  if (isMobile) {
    menuItems.splice(2, 1);
  }

  const { makeDoc } = useCreateDoc();

  return (
    <div
      data-testid="Action Button"
      className={cn(styles.container, className, styles.desktop, {
        [styles.mobile]: isMobile,
        [styles.docPage]: page === 'docPage',
      })}
    >
      {isArchived ? (
        <Fab style="small" icon={<Icon name="sprite/plus" />} disabled />
      ) : (
        <AnimatePresence>
          <Dropdown>
            <Dropdown.Menu
              trigger={<Fab style="small" icon={<Icon name="sprite/plus" />} />}
              side="top"
              sideOffset={15}
              collisionPadding={20}
              density="-2"
            >
              {menuItems.map((item, index) => (
                <Dropdown.MenuItem key={index} item={item} />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </AnimatePresence>
      )}
    </div>
  );
};

export default ActionButton;

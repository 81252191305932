import { useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { useAllBlocks } from '@pages/LibraryPage/model/queries/useAllBlocks';

import { useColourQuery } from '../../hooks/useColourQuery';
import {
  $selectedColours,
  $showColourPaletteSearch,
  closedColourPaletteSearch,
  openedColourPaletteSearch,
  removedColour,
  selectedColour,
} from '../../model';
import { hexToRgb, hslToRgb, rgbToHex, rgbToHsl } from '../../util/colour';

import styles from './styles.module.css';

export const ColourSwatches = () => {
  const [showColourPaletteSearch] = useUnit([$showColourPaletteSearch]);

  const { isMobile } = useWindowSize();

  return (
    <div className={styles.container}>
      <div className={styles.swatchesContainer}>
        {isMobile ? null : (
          <motion.span
            key="label"
            initial={{
              opacity: 0,
              x: -40,
              width: 0,
            }}
            animate={{
              opacity: showColourPaletteSearch ? 1 : 0,
              x: showColourPaletteSearch ? 0 : -40,
              width: showColourPaletteSearch ? 'auto' : 0,
              transition: {
                width: {
                  duration: 0.2,
                },
                opacity: {
                  delay: showColourPaletteSearch ? 0.05 : 0,
                  duration: 0.2,
                },
              },
            }}
            style={{
              pointerEvents: showColourPaletteSearch ? 'auto' : 'none',
            }}
          >
            <TypographyPoppins
              type="label"
              size="L"
              className={styles.swatchLabel}
            >
              Pick up to 5 swatches
            </TypographyPoppins>
          </motion.span>
        )}
        <Swatches />
      </div>

      <AnimatePresence mode="popLayout" initial={false}>
        {showColourPaletteSearch ? (
          <motion.span
            key="1"
            initial={{
              opacity: 0,
              x: 40,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
          >
            <AnimatePresence mode="popLayout">
              <motion.span
                key="1"
                initial={{
                  opacity: 0,
                  x: 40,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                }}
                exit={{
                  opacity: 0,
                  x: -40,
                }}
              >
                <Button
                  label="Simple search"
                  icon={<Icon name="sprite/chevron-left" />}
                  onClick={() => {
                    closedColourPaletteSearch();
                  }}
                  type="ghost"
                />
              </motion.span>
            </AnimatePresence>
          </motion.span>
        ) : (
          <motion.span
            key="2"
            initial={{
              opacity: 0,
              x: 40,
            }}
            animate={{
              opacity: 1,
              x: 0,
            }}
            exit={{
              opacity: 0,
              x: -40,
            }}
          >
            <Button
              label="Search by color"
              onClick={() => {
                openedColourPaletteSearch();
              }}
              type="ghost"
            />
          </motion.span>
        )}
      </AnimatePresence>
    </div>
  );
};

const LoaderColours = [
  { id: '1', hex_color: '#E2C9EB' },
  { id: '2', hex_color: '#B3D4C1' },
  { id: '3', hex_color: '#FFE2B5' },
  { id: '4', hex_color: '#D1A8D6' },
  { id: '5', hex_color: '#A2B89E' },
  { id: '6', hex_color: '#FFD199' },
  { id: '7', hex_color: '#C08BBF' },
  { id: '8', hex_color: '#8FAF8B' },
  { id: '9', hex_color: '#FFBF80' },
  { id: '10', hex_color: '#A96BA8' },
];

const DefaultColours = [
  { id: '1', hex_color: '#FFE2B5' },
  { id: '2', hex_color: '#E2C9EB' },
  { id: '3', hex_color: '#B3D4C1' },
  { id: '4', hex_color: '#FF9D09' },
  { id: '5', hex_color: '#9E4BBB' },
  { id: '6', hex_color: '#016F2F' },
  { id: '7', hex_color: '#D94F70' },
  { id: '8', hex_color: '#40A298' },
  { id: '9', hex_color: '#0F4C82' },
  { id: '10', hex_color: '#F5DF4D' },
];

const Swatches = () => {
  const [selectedColours] = useUnit([$selectedColours]);
  const [showColourPaletteSearch] = useUnit([$showColourPaletteSearch]);
  const [randomPalette, setRandomPalette] = useState({
    palette1: '',
    palette2: '',
  });
  const { blocks, query: paletteQuery } = useAllBlocks({
    ordering: '-created_at',
    typeOfBlock: 'color_palette',
  });

  const { isMobile } = useWindowSize();

  if (
    blocks &&
    blocks.length > 0 &&
    !randomPalette.palette1 &&
    !randomPalette.palette2
  ) {
    setRandomPalette({
      palette1: blocks[Math.floor(Math.random() * blocks.length)].id,
      palette2: blocks[Math.floor(Math.random() * blocks.length)].id,
    });
  }

  const palette1 = useColourQuery(randomPalette.palette1);
  const palette2 = useColourQuery(randomPalette.palette2);

  if (paletteQuery.isLoading || palette1.isLoading || palette2.isLoading) {
    return (
      <div className={styles.swatches}>
        {LoaderColours.map((colour, i) => (
          <div
            key={`${colour.id}-${i}`}
            style={{
              backgroundColor: colour.hex_color,
              animationDelay: `${i * 0.1}s`,
            }}
            className={cn(styles.swatch, styles.swatchLoading)}
          />
        ))}
      </div>
    );
  }

  if (!palette1.data || !palette2.data) {
    return (
      <div className={cn(styles.swatches, styles.swatchesLoaded)}>
        {DefaultColours.map((colour) => (
          <motion.button
            initial={false}
            animate={{
              height: showColourPaletteSearch ? '24px' : '40px',
            }}
            onClick={() => {
              if (!showColourPaletteSearch) {
                openedColourPaletteSearch();
              } else {
                // Choose swatch
              }
            }}
            key={colour.id}
            style={{ backgroundColor: colour.hex_color }}
            className={styles.swatch}
          />
        ))}
      </div>
    );
  }

  const combinedPalette = [...palette1.data, ...palette2.data];

  const swatches = isMobile ? combinedPalette.slice(0, 5) : combinedPalette;

  return (
    <div className={cn(styles.swatches, styles.swatchesLoaded)}>
      {swatches.map((colour, i) => {
        const rgb = hexToRgb(colour.hex_color);
        if (!rgb) return null;
        const hsl = rgbToHsl(rgb.r, rgb.g, rgb.b);
        const hslString = `hsl(${hsl.h}, 100%, ${hsl.l}%)`;
        const rgbConverted = hslToRgb(hsl.h, 100, hsl.l);
        const hexConverted = rgbToHex(
          rgbConverted.r,
          rgbConverted.g,
          rgbConverted.b,
        );
        return (
          <motion.button
            initial={false}
            animate={{
              height: showColourPaletteSearch ? '24px' : '40px',
              transition: {
                duration: 0.2,
              },
            }}
            onClick={() => {
              if (!showColourPaletteSearch) {
                openedColourPaletteSearch();
              } else {
                // Toggle selected swatch
                if (selectedColours.length >= 5) return;

                const isSelected = selectedColours.some(
                  (selectedColour) => selectedColour.id === colour.id,
                );
                if (isSelected) {
                  removedColour(colour.id);
                } else {
                  selectedColour({
                    hex: hexConverted,
                    id: colour.id,
                  });
                }
              }
            }}
            key={`${colour.id}-${i}`}
            style={{ backgroundColor: hslString }}
            className={styles.swatch}
          />
        );
      })}
    </div>
  );
};

import { format, isToday, isYesterday, parseISO } from 'date-fns';

type DateStyle = 'comprehensive';

export const getDate = (d: string, type: DateStyle) => {
  switch (type) {
    case 'comprehensive':
    default:
      return format(new Date(d), `MMM d yyyy 'at' h:mm aaa`);
  }
};

export const getDateCommaSeparatedYear = (d: string, type: DateStyle) => {
  switch (type) {
    case 'comprehensive':
    default:
      return format(new Date(d), `MMM d, yyyy 'at' h:mm aaa`);
  }
};

// Our design system conventions for dates
export const formatDate = (date: string) => {
  const now = new Date();
  let parsedDate;

  try {
    parsedDate = typeof date === 'string' ? parseISO(date) : date;
    if (isNaN(parsedDate.getTime())) {
      throw new Error('Invalid date');
    }
  } catch (error) {
    console.error('Invalid date format:', date);
    return 'Invalid date';
  }

  if (isToday(parsedDate)) {
    return `Today at ${format(parsedDate, 'h:mm aaa')}`;
  }

  if (isYesterday(parsedDate)) {
    return `Yesterday at ${format(parsedDate, 'h:mm aaa')}`;
  }

  const dateFormat =
    now.getFullYear() === parsedDate.getFullYear() ? 'MMM d' : 'MMM d, yyyy';

  return `${format(parsedDate, dateFormat)} at ${format(
    parsedDate,
    'h:mm aaa',
  )}`;
};

import { useDeferredValue, useEffect, useRef, useState } from 'react';

import { useUnit } from 'effector-react';
import { AnimatePresence, motion } from 'framer-motion';
import { createPortal } from 'react-dom';

import { Divider } from '@visualist/design-system/src/components/v2';
import { useFocusTrap, useWindowSize } from '@visualist/hooks';

import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';

import { useSearch } from '../../hooks/useSearch';
import {
  $filters,
  $searchFullScreen,
  $showColourPaletteSearch,
  $showSearch,
  hideSearch,
  revealedSearch,
} from '../../model';
import { ColourBottom } from '../colour-bottom';
import { ColourSwatches } from '../colour-swatches';
import { SearchResults } from '../results';
import { SearchBar } from '../searchBar';
import { SearchContainer, SearchContainerMobile } from '../searchContainer';
import { SearchWithinFilter } from '../searchWithinFilter';
import { Suggestions } from '../suggestions';

import styles from './styles.module.css';

export const SearchDialog = ({
  hideMobileSearch = false,
}: {
  hideMobileSearch?: boolean;
}) => {
  const [showSearch, filters, searchFullScreen] = useUnit([
    $showSearch,
    $filters,
    $searchFullScreen,
  ]);

  const [searchText, setSearchText] = useState('');
  const [, setSearches] = useLocalStorage<string[]>('recent-searches', []);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const captureSearchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const defferedSearchText = useDeferredValue(searchText);

  const { searchQuery } = useSearch({
    query: defferedSearchText,
    type: Array.from(
      filters.allSelected() ? filters.allPossible() : filters.files.values(),
    ),
  });

  const { width } = useWindowSize();
  const isMobile = width < 487 && width !== 0;

  useEffect(() => {
    if (!showSearch) setSearchText('');
  }, [showSearch]);

  useFocusTrap({
    isOpen: showSearch,
    ref: containerRef,
    setInitialFocus: false,
  });

  const onClickSearch = () => {
    revealedSearch();
    searchRef.current?.focus();
  };

  const onSearchInputUpdate = (searched: React.SetStateAction<string>) => {
    if (captureSearchTimeoutRef.current)
      clearTimeout(captureSearchTimeoutRef.current);

    if (searched.length > 0) {
      captureSearchTimeoutRef.current = setTimeout(() => {
        if (searched.length < 3) return;

        setSearches((prev) => {
          // Check if search already in list
          const searchIndex = prev.findIndex((s) => s === searched.toString());

          if (searchIndex === -1) {
            // New element append
            return [searched.toString(), ...prev.slice(0, 4)];
          }

          // Element already in list, move it to the top
          const arr = [...prev];
          arr.splice(searchIndex, 1);
          arr.unshift(searched.toString());

          return arr;
        });
      }, 700);
    }

    setSearchText(searched);
  };

  if (isMobile && !hideMobileSearch) {
    return (
      <div className={styles.mobileSearch} ref={containerRef}>
        {showSearch ? (
          createPortal(
            <div className={styles.container}>
              <SearchContainer>
                <ColourSwatches />
                <SearchBar
                  autoFocus
                  searchText={searchText}
                  setSearchText={onSearchInputUpdate}
                  ref={searchRef}
                  onClick={onClickSearch}
                />
                <SearchArea
                  searchText={searchText}
                  searchQuery={searchQuery}
                  onSearchInputUpdate={onSearchInputUpdate}
                />
              </SearchContainer>
            </div>,
            document.body,
          )
        ) : (
          <SearchContainerMobile>
            <SearchBar
              searchText={searchText}
              setSearchText={setSearchText}
              ref={searchRef}
              onClick={onClickSearch}
            />
          </SearchContainerMobile>
        )}
      </div>
    );
  }

  return createPortal(
    <AnimatePresence>
      {showSearch && (
        <div
          key="search-dialog"
          className={styles.container}
          ref={containerRef}
        >
          <SearchContainer
            shouldAnimate
            variant={searchFullScreen ? 'fullScreen' : 'default'}
          >
            <div className={styles.searchHeader}>
              <ColourSwatches />
              <SearchBar
                autoFocus
                ref={searchRef}
                searchText={searchText}
                setSearchText={onSearchInputUpdate}
                onClick={onClickSearch}
              />
            </div>
            <SearchArea
              searchText={searchText}
              searchQuery={searchQuery}
              onSearchInputUpdate={onSearchInputUpdate}
            />
          </SearchContainer>
          <motion.div
            onClick={() => hideSearch()}
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 0.16,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{
              duration: 0.5,
            }}
            className={styles.scrim}
          ></motion.div>
        </div>
      )}
    </AnimatePresence>,
    document.body,
  );
};

const SearchArea = ({
  searchText,
  searchQuery,
  onSearchInputUpdate,
}: {
  searchText: string;
  searchQuery: any;
  onSearchInputUpdate: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [showColourPaletteSearch] = useUnit([$showColourPaletteSearch]);

  return (
    <AnimatePresence>
      {showColourPaletteSearch ? (
        <motion.div
          key="colour-selector"
          initial={{
            y: 40,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            duration: 0.3,
            type: 'tween',
          }}
          className={styles.colourSelectorContainer}
        >
          <ColourBottom />
        </motion.div>
      ) : (
        <motion.div
          key="text-search-results"
          className={styles.textSearchResults}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            duration: 0.3,
            type: 'tween',
          }}
          exit={{
            y: -40,
            opacity: 0,
          }}
        >
          <SearchWithinFilter />
          <Divider type="long-line" className={styles.divider} />
          {searchText.length ? (
            <SearchResults
              results={searchQuery.data}
              isLoading={searchQuery.isLoading}
              searchText={searchText}
            />
          ) : (
            <Suggestions setSearchText={onSearchInputUpdate} />
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

import React from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { AnimatePresence } from 'framer-motion';

import { Block, BlockResponse } from '@api/services';
import { $isStickyModeOn } from '@pages/FileCard/model';
import { useAppData } from '@src/AppContext';
import { $id } from '@src/entities/share-sheet/board/model/inviting';
import { useInvitees } from '@src/entities/share-sheet/board/model/queries/use-invitees';
import { editedSticky } from '@src/entities/Stickies/model';
import {
  checkCollisions,
  chooseColour,
  isStickyOutOfBounds,
  sortByUpdatedAt,
} from '@src/entities/Stickies/utils';
import useStickies from '@src/shared/queries/useStickies';
import { useIsMutating } from '@tanstack/react-query';

import { PADDING_SIZE } from '../constants';
import { FileCardSticky } from '../ImageSticky';

import styles from './styles.module.css';

type Props = {
  block: BlockResponse | Block;
  containerHeight: number;
  containerWidth: number;
  imageRef: React.RefObject<React.ElementRef<'img'>>;
};

export const MiniStickies = ({
  block,
  containerHeight,
  containerWidth,
  imageRef,
}: Props) => {
  const { user } = useAppData();
  const [isStickyMode] = useUnit([$isStickyModeOn]);
  const id = useUnit($id);

  const ref = React.useRef<React.ElementRef<'div'>>(null);

  const { stickiesQuery, addNewStickyOptimistic } = useStickies(block.id, null);
  const { role } = useInvitees({
    id,
  });

  const currentStickyMutationsRunning = useIsMutating({
    mutationKey: ['ADD_STICKY_MUTATION', { imageId: block.id }],
  });

  const addPlaceholderSticky = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (!imageRef.current || !isStickyMode || role === 'Viewer') return;

    // If there are mutations running do nothing
    if (currentStickyMutationsRunning) return;

    // If not clicking on the container do nothing
    if (e.target !== e.currentTarget) return;

    // Turn off any sticky being edited
    editedSticky(null);

    const { clientX, clientY } = e;
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();
    const x = clientX - left - PADDING_SIZE / 2;
    const y = clientY - top - PADDING_SIZE / 2;

    const { cleanedX, cleanedY } = checkCollisions({
      x,
      y,
      top: 0,
      left: 0,
      right: containerWidth,
      bottom: containerHeight,
    });

    const clientXRatio = cleanedX / width;
    const clientYRatio = cleanedY / height;

    addNewStickyOptimistic({
      id: 'new-sticky',
      created_by: user,
      is_private: true,
      left_pixel: clientXRatio,
      top_pixel: clientYRatio,
      text: '',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      background_hex: chooseColour(
        stickiesQuery.data ? stickiesQuery.data.stickies.length : 0,
      ),
      attached_to_id: undefined,
      attached_to_type: undefined,
      action_sticky: null,
    });
  };

  return (
    <div
      ref={ref}
      className={cn(styles.container, {
        [styles.stickyCursor]:
          isStickyMode && !currentStickyMutationsRunning && role !== 'Viewer',
        [styles.loadingCursor]: currentStickyMutationsRunning,
      })}
      onClick={addPlaceholderSticky}
      style={{
        height: containerHeight,
        width: containerWidth,
      }}
    >
      <AnimatePresence>
        {ref.current &&
          isStickyMode &&
          stickiesQuery.data &&
          stickiesQuery.data.stickies
            .sort(sortByUpdatedAt)
            .filter(isStickyOutOfBounds)
            .map((sticky, index) => (
              <FileCardSticky
                key={sticky.id}
                sticky={sticky}
                imageHeight={block.height}
                imageWidth={block.width}
                containerRef={ref}
                index={index}
                blockId={block.id}
                role={role}
              />
            ))}
      </AnimatePresence>
    </div>
  );
};

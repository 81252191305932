import { isMobile } from 'react-device-detect';

import {
  IconButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Role } from '@api/users';
import { copyFileDialogOpened } from '@src/entities/dialogs/file-card/copy-file-to-board/model/show-copy-dialog';

import styles from './styles.module.css';

export const CopyFileOtherBoard = ({
  role,
  isArchived,
}: {
  role: Role | null;
  isArchived?: boolean;
}) => {
  return role === 'Editor' || role === null ? (
    <div className={styles.board}>
      <TooltipRadix
        description="Copy file to other boards"
        side={isMobile ? 'top' : 'left'}
      >
        <IconButton
          type="unfilled"
          className={styles.iconButton}
          icon={<Icon name="sprite/board" className={styles.icon} />}
          onClick={() => copyFileDialogOpened()}
          isDisabled={isArchived}
        />
      </TooltipRadix>
    </div>
  ) : (
    <div style={{ paddingTop: '82px' }} />
  );
};

import { useHistory, useLocation } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { unarchiveDoc } from '@api/docs';
import {
  ALL_DOCS_QUERY,
  DOC_QUERY,
  DOCS_OF_BOARD_QUERY,
} from '@src/shared/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUnarchiveDoc = () => {
  const { pathname } = useLocation();
  const history = useHistory();

  const queryClient = useQueryClient();

  const unarchiveDocMutation = useMutation({
    mutationFn: ({ id }: { id: string }) => unarchiveDoc({ id }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [DOC_QUERY, { docId: variables.id }],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_BOARD_QUERY, { board: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_DOCS_QUERY],
      });

      startedSnack({
        label: 'Unarchived doc',
        action: {
          label: 'Go to doc',
          action: () => history.push(`/d/${variables.id}`),
        },
        close: true,
      });
    },
    onError: (err, variables) => {
      startedSnack({
        label: 'Couldn’t unarchive doc',
        action: {
          action: () => {
            unarchiveDocMutation.mutate({ id: variables.id });
          },
          label: 'Try again',
        },
        close: true,
      });
    },
  });

  return {
    unarchiveDocMutation,
  };
};

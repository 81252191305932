import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { getSelectedNodes } from '@pages/StudioPage/utils';

import { DistributionType } from './constants';

export const nodeDistribution = (
  items: string[],
  type: DistributionType[number],
  selectionTransformer: Konva.Transformer | null,
  stage: Stage | null,
): void => {
  if (!selectionTransformer || !stage) {
    return;
  }

  const nodes = getSelectedNodes(stage, items);
  if (nodes.length < 2) return;

  const scale = stage.getAbsoluteScale();

  switch (type) {
    case 'HORIZONTAL': {
      const sorted = nodes
        .slice()
        .sort((a, b) => a.getAbsolutePosition().x - b.getAbsolutePosition().x);

      const firstPos = sorted[0].getAbsolutePosition().x;
      const lastPos =
        sorted[sorted.length - 1].getAbsolutePosition().x +
        sorted[sorted.length - 1].width() * scale.x;

      const totalWidth = lastPos - firstPos;
      const totalNodesWidth = sorted.reduce(
        (sum, node) => sum + node.width() * scale.x,
        0,
      );
      const gap = (totalWidth - totalNodesWidth) / (sorted.length - 1);

      let currentX = firstPos;
      sorted.forEach((node) => {
        node.absolutePosition({
          x: currentX,
          y: node.getAbsolutePosition().y,
        });
        currentX += node.width() * scale.x + gap;
      });
      break;
    }

    case 'VERTICAL': {
      const sorted = nodes
        .slice()
        .sort((a, b) => a.getAbsolutePosition().y - b.getAbsolutePosition().y);

      const firstPos = sorted[0].getAbsolutePosition().y;
      const lastPos =
        sorted[sorted.length - 1].getAbsolutePosition().y +
        sorted[sorted.length - 1].height() * scale.y;

      const totalHeight = lastPos - firstPos;
      const totalNodesHeight = sorted.reduce(
        (sum, node) => sum + node.height() * scale.y,
        0,
      );
      const gap = (totalHeight - totalNodesHeight) / (sorted.length - 1);

      let currentY = firstPos;
      sorted.forEach((node) => {
        node.absolutePosition({
          x: node.getAbsolutePosition().x,
          y: currentY,
        });
        currentY += node.height() * scale.y + gap;
      });
      break;
    }

    default:
      console.error(`Distribution error! Unknown type: ${String(type)}`);
      startedSnack({
        label: 'Oops, something went wrong.',
        close: true,
      });
  }

  nodes.forEach((node) => {
    node.fire('dragend', {
      type: 'dragend',
      target: node,
      evt: new MouseEvent('dragend'),
    });
  });
};

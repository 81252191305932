import { createEvent, createStore, sample } from 'effector';

export const archiveFileIdSelected = createEvent<string>();
export const archiveFileIdUnselected = createEvent<string>();
export const archiveFileIdCleared = createEvent();

export const $archiveFileIds = createStore<string[] | null>(null);

sample({
  clock: archiveFileIdSelected,
  source: $archiveFileIds,
  fn: (selectedIds, id) => (selectedIds === null ? [id] : [...selectedIds, id]),
  target: $archiveFileIds,
});

sample({
  clock: archiveFileIdUnselected,
  source: $archiveFileIds,
  fn: (selectedIds, id) => {
    if (selectedIds === null) return null;
    return selectedIds.filter((selectedId) => selectedId !== id);
  },
  target: $archiveFileIds,
});

sample({
  clock: archiveFileIdUnselected,
  source: $archiveFileIds,
  filter: (selectedIds) => selectedIds !== null && selectedIds.length === 0,
  target: archiveFileIdCleared,
});

sample({
  clock: archiveFileIdCleared,
  fn: () => null,
  target: $archiveFileIds,
});

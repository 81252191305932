import { memo } from 'react';

import {
  SegmentedButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { ImageType } from '@pages/StudioPage/api';
import { useImages } from '@pages/StudioPage/hooks/useImages';
import { startedCroppingImage } from '@pages/StudioPage/model';
import { useAppData } from '@src/AppContext';

import styles from './styles.module.css';

type ImageToolbarProps = {
  designId: string;
  imageId: string;
  blockId: string;
  imageType: ImageType;
  canShowOnboardingTip: boolean;
};

export const ImageToolbar = memo(
  ({
    designId,
    imageId,
    blockId,
    imageType,
    canShowOnboardingTip,
  }: ImageToolbarProps) => {
    const { imageDeleteMutation, imageBackgroundRemove } = useImages({
      designId,
    });
    const { user } = useAppData();

    const removeBackground = () => {
      if (imageBackgroundRemove.isPending) return;
      if (!blockId) {
        console.error('blockId is missing');
        return;
      }
      imageBackgroundRemove.mutate({ blockId, imageId });
    };

    const deleteImage = () => imageDeleteMutation.mutate([imageId]);

    const startCroppingImage = () => startedCroppingImage(imageId);

    const showOnboardingDot =
      user.meta?.onboarding?.workCreatively === 'moodboards-that-pop' &&
      canShowOnboardingTip;

    return (
      <>
        <div className={styles.divider}></div>
        {showOnboardingDot ? (
          <div
            className={styles.demoIndicator}
            onClick={removeBackground}
          ></div>
        ) : null}
        <TooltipRadix description="Crop" side="top">
          <SegmentedButton
            start
            buttonStyle={styles.button}
            icon={<Icon name="sprite/crop" />}
            onClick={startCroppingImage}
          />
        </TooltipRadix>
        <TooltipRadix
          description={
            imageType === 'Background remove'
              ? 'Add background back'
              : 'Remove background'
          }
          side="top"
        >
          <SegmentedButton
            buttonStyle={styles.button}
            icon={
              imageType === 'Background remove' ? (
                <Icon name="sprite/add-background" />
              ) : (
                <Icon name="sprite/remove-background" />
              )
            }
            onClick={removeBackground}
          />
        </TooltipRadix>
        <div className={styles.divider}></div>
        <TooltipRadix description="Delete" side="top">
          <SegmentedButton
            end
            buttonStyle={styles.button}
            icon={
              <Icon name="sprite/bin" size={24} color="var(--color-error)" />
            }
            onClick={deleteImage}
          />
        </TooltipRadix>
      </>
    );
  },
);

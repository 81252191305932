import cn from 'classnames';

import { Spinner } from '@components/Spinner';
import { Columns } from '@src/entities/recycle-bin/ui/columns';
import { EmptyBin } from '@src/entities/recycle-bin/ui/empty-bin';
import { Header } from '@src/entities/recycle-bin/ui/header';
import { Items } from '@src/entities/recycle-bin/ui/items';

import { useDeletedItems } from './model/queries/use-deleted-items';

import styles from './styles.module.css';

export const Bin = () => {
  const { itemsQuery } = useDeletedItems();

  if (itemsQuery.isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100dvh',
        }}
      >
        <Spinner />
      </div>
    );

  const isEditor = itemsQuery.data?.results.some((item) => {
    const perms = item.permissions;

    return (
      Array.isArray(perms) &&
      perms.length === 2 &&
      perms.includes('edit') &&
      perms.includes('invite')
    );
  });

  return (
    <>
      <Header isDisabled={itemsQuery.data?.count === 0 || !isEditor} />
      <div
        className={cn(styles.items, {
          [styles.noItems]: itemsQuery.data?.count === 0,
        })}
      >
        {itemsQuery.data?.count === 0 ? (
          <EmptyBin />
        ) : (
          <>
            <Columns />
            <Items items={itemsQuery.data?.results} />
          </>
        )}
      </div>
    </>
  );
};

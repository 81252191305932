import { ComponentPropsWithoutRef, PropsWithChildren } from 'react';

import axios from 'axios';
import cn from 'classnames';
import { useHistory } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import googleLogin from '@api/authentication/googleLogin';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { setCookie } from '@src/shared/utils';

import styles from './styles.module.css';

export const GoogleButton = (props: PropsWithChildren<{ label?: string }>) => {
  return (
    <GoogleOAuthProvider clientId="24648013288-l4ac6ugtnalb29p0acp3s98j6gkm0pos.apps.googleusercontent.com">
      {props.children}
    </GoogleOAuthProvider>
  );
};

GoogleButton.Headless = ({
  label,
  handleAccessToken,
  ...rest
}: {
  label: string;
  handleAccessToken: (token: string) => void;
} & ComponentPropsWithoutRef<'button'>) => {
  const authenticateGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handleAccessToken(tokenResponse.access_token);
    },
  });

  return (
    <button
      {...rest}
      className={cn(styles.googleButton, rest.className)}
      onClick={() => authenticateGoogle()}
    >
      <Icon
        className={styles.icon}
        name="sprite/google-colored"
        width={24}
        height={24}
      />
      <p className={styles.labelGoogle}>{label}</p>
    </button>
  );
};

GoogleButton.Login = ({ label }: { label: string }) => {
  const history = useHistory();

  const handleLogin = async (accessToken: string) => {
    try {
      const googleResponse = await googleLogin({
        access_token: accessToken,
      });

      setCookie('access', googleResponse.access);
      setCookie('refresh', googleResponse.refresh);
      history.push('/home');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.non_field_errors
        ) {
          // Show snack bar for email
          startedSnack({
            label: 'Oops, log in with your email instead',
            close: true,
          });
        } else {
          startedSnack({
            label: "Sorry, there's a problem with the connection",
            close: true,
          });
        }
      }
    }
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => handleLogin(tokenResponse.access_token),
  });

  return (
    <button className={styles.googleButton} onClick={() => login()}>
      <Icon
        className={styles.icon}
        name="sprite/google-colored"
        width={24}
        height={24}
      />
      <p className={styles.labelGoogle}>{label}</p>
    </button>
  );
};

export const AppleButton = (props: { label?: string }) => {
  const { label = 'Continue with Apple' } = props;
  return (
    <button className={styles.appleButton}>
      <Icon name="sprite/apple" width={24} height={24} />
      <p className={styles.labelApple}>{label}</p>
    </button>
  );
};

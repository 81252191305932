// For reference why its done like this read:
// https://tkdodo.eu/blog/effective-react-query-keys

import { SearchType } from '@api/search';
import { ConversationFilters } from '@api/vai';

export const boardsKeys = {
  all: ['boards'] as const,
  noHub: (is_archived?: boolean) =>
    [...boardsKeys.all, 'no-hub', is_archived] as const,
  board: (id: string) => [...boardsKeys.noHub(), id] as const,
};

export const docsKeys = {
  all: ['docs'] as const,
  doc: (id: string) => [...docsKeys.all, id] as const,
};

export const searchKeys = {
  all: ['search'] as const,
  exactMatch: (query: string, type: SearchType[]) =>
    [...searchKeys.all, query, type] as const,
};

export const searchPaletteKeys = {
  all: ['searchPalette'] as const,
  palette: (query: string[]) => [...searchPaletteKeys.all, query] as const,
};

export const messagesKeys = {
  all: ['messages'] as const,
  messages: (hubId: string) => [...messagesKeys.all, hubId] as const,
  hubMessages: (hubId: string) =>
    [...messagesKeys.all, 'hubMessages', hubId] as const,
  message: (hubId: string, threadId: string) =>
    [...messagesKeys.messages(hubId), threadId] as const,
  thread: (hubId: string, threadId: string) =>
    [...messagesKeys.messages(hubId), 'thread', threadId] as const,
};

export const imagesKeys = {
  all: ['image'] as const,
  image: (id?: string) => [...imagesKeys.all, { id }] as const,
};

export const libraryKeys = {
  all: ['library'] as const,
};

export const allBoardImagesKeys = {
  all: ['all_board_images'] as const,
};

export const vaiConversationsKeys = {
  all: ['conversations'] as const,
  filtered: (filters?: ConversationFilters) =>
    [...vaiConversationsKeys.all, filters] as const,
  hubConversation: (id: string) => [...vaiConversationsKeys.all, id],
};

export const vaiGeneratedActionKeys = {
  all: ['vai-generated-actions'] as const,
};

export const vaiPinnedMessageKeys = {
  all: ['pinned_messages'] as const,
};

export const actionAssignees = {
  all: ['action_assignees'] as const,
  action: (id: string) => [...actionAssignees.all, id],
};

// Studio Query Keys
export const studioDesignKeys = {
  all: ['designs'] as const,
  design: (id: string) => [studioDesignKeys.all, id] as const,
  images: (id: string) =>
    [...studioDesignKeys.design(id), 'get-images-studio'] as const,
  text: (id: string) =>
    [...studioDesignKeys.design(id), 'get-text-studio'] as const,
};

export const recycleBin = {
  all: ['recycle_bin'] as const,
};

import { ComponentProps, forwardRef, ReactNode } from 'react';

import cn from 'classnames';
import { Link } from 'react-router-dom';

import { TypographyPoppins } from '../Styles/Typography/TypographyPoppins';
import { TooltipRadix } from '../tooltip-radix';

import styles from './styles.module.css';

type Props = ComponentProps<typeof Link> & {
  to: string;
  name: string;
  description?: string;
  isSelected: boolean;
  isBulletPoint?: boolean;
  isDisabled?: boolean;
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  minimal?: boolean;
  onClick?: () => void;
  isNested?: boolean;
  className?: string | null;
  classNameIconLeft?: string;
  classNameIconRight?: string;
  isExpanded?: boolean;
};

export const NavItem = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const {
    name,
    description,
    to,
    isSelected,
    isBulletPoint = false,
    isDisabled = false,
    leftIcon,
    rightIcon,
    minimal = false,
    onClick,
    isNested = false,
    className,
    classNameIconLeft,
    classNameIconRight,
    isExpanded,
    ...rest
  } = props;
  return isDisabled ? (
    <TooltipRadix description="Coming soon">
      <Link
        {...rest}
        to={to}
        style={{
          pointerEvents: isDisabled ? 'none' : 'auto',
        }}
        className={cn(styles.link, className)}
        data-is-selected={isSelected}
        data-is-bullet={isBulletPoint}
        ref={ref}
        aria-disabled={isDisabled}
        data-is-nested={isNested}
      >
        <div className={styles.left}>
          <TypographyPoppins
            type="label"
            size={isNested ? 'M' : 'L'}
            className={styles.label}
          >
            {name}
          </TypographyPoppins>
        </div>
        <div className={styles.right}>{rightIcon}</div>
      </Link>
    </TooltipRadix>
  ) : (
    <>
      <Link
        {...rest}
        // @ts-expect-error TODO FIX
        to={to ? to : undefined}
        onClick={onClick}
        className={cn(styles.link, className)}
        data-is-selected={isSelected}
        data-is-bullet={isBulletPoint}
        data-is-nested={isNested}
        ref={ref}
        aria-disabled={isDisabled}
      >
        <div className={styles.left}>
          <div className={cn(styles.iconLeft, classNameIconLeft)}>
            {leftIcon}
          </div>
          {minimal ? null : (
            <div>
              <TypographyPoppins
                type="label"
                size={isNested ? 'M' : 'L'}
                className={styles.label}
              >
                {name}
              </TypographyPoppins>
            </div>
          )}
        </div>
        {minimal ? null : (
          <div className={cn(styles.right, classNameIconRight)}>
            {rightIcon}
          </div>
        )}
      </Link>
      {isExpanded && description ? (
        <TypographyPoppins className={styles.description} type="body" size="S">
          {description}
        </TypographyPoppins>
      ) : null}
    </>
  );
});

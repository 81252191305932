import { Helmet } from 'react-helmet-async';

import { MissingContent } from '@src/entities/global-error/premade/missing-content';

export const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 | Visualist</title>
      </Helmet>
      <MissingContent />
    </>
  );
};

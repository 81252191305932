import cn from 'classnames';

import { TypographyPoppins } from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

type FourSizes = 'x-small' | 'small' | 'medium' | 'large';
type ThreeSizes = 'small' | 'medium' | 'large';

type FirstSet = {
  style: 'red';
  fourSizes: FourSizes;
  text: string;
};

type SecondSet = {
  style: 'red-single digit' | 'tertiary-single digit';
  threeSizes: ThreeSizes;
  text: string;
};

type ThirdSet = {
  style: 'multiple digits' | 'text';
  text: string;
};

type FourthSet = {
  style: 'outline';
};

export type BadgeProps = FirstSet | SecondSet | ThirdSet | FourthSet;

//@ts-ignore
export const Badge = ({ style, fourSizes, threeSizes, text }: BadgeProps) => {
  const parseString = (string?: string) => {
    if (!string) return '';

    if (style === 'red-single digit' || style === 'tertiary-single digit') {
      const lastIndex = string.trim().length - 1;
      const lastLetter = string[lastIndex].charAt(0);

      return lastLetter;
    } else return string;
  };

  const displayContent = parseString(text);
  return (
    <div
      className={cn({
        [styles.red]: style === 'red',
        [styles.redSingleDigit]: style === 'red-single digit',
        [styles.tertiarySingleDigit]: style === 'tertiary-single digit',
        [styles.multipleDigits]: style === 'multiple digits',
        [styles.text]: style === 'text',
        [styles.outline]: style === 'outline',

        [styles.large]:
          (style === 'red' && fourSizes === 'large') ||
          (style === 'red-single digit' && threeSizes === 'large') ||
          (style === 'tertiary-single digit' && threeSizes === 'large'),
        [styles.medium]:
          (style === 'red' && fourSizes === 'medium') ||
          (style === 'red-single digit' && threeSizes === 'medium') ||
          (style === 'tertiary-single digit' && threeSizes === 'medium'),
        [styles.small]:
          (style === 'red' && fourSizes === 'small') ||
          (style === 'red-single digit' && threeSizes === 'small') ||
          (style === 'tertiary-single digit' && threeSizes === 'small'),
        [styles.xsmall]: style === 'red' && fourSizes === 'x-small',
      })}
    >
      {(style === 'red-single digit' ||
        style === 'tertiary-single digit' ||
        style === 'multiple digits') && (
        <TypographyPoppins
          className={cn(styles.mainContent, {
            [styles.secondaryContent]: style === 'red-single digit',
          })}
          type="label"
          size="S"
        >
          {displayContent}
        </TypographyPoppins>
      )}

      {style === 'text' && (
        <TypographyPoppins
          className={styles.mainContent}
          type="label"
          size="XS"
        >
          {displayContent}
        </TypographyPoppins>
      )}
    </div>
  );
};

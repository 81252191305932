import { EventCallable } from 'effector';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { getBoards } from '@api/boards';
import { getHubs } from '@api/hubs';
import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { SearchModal } from '@src/entities/search-modal/ui/modal';
import {
  BOARDS_SUGGESTIONS_QUERY,
  HUBS_SUGGESTIONS_QUERY,
} from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

import { Location as LocationType } from './model';

import styles from './styles.module.css';

type Props = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  query: string;
  queryEntered: (query: string) => void;
  location: LocationType | null;
  selectLocation: EventCallable<LocationType>;
  level?: string[] | boolean | null;
  children: React.ReactElement<{ onClick?: (e: React.MouseEvent) => void }>;
};

export const Location = ({
  isOpen,
  open,
  close,
  query,
  queryEntered,
  location,
  selectLocation,
  level = ['0, 1'],
  children,
}: Props) => {
  const { data: hubsSuggestions = [], isLoading } = useQuery({
    queryKey: [HUBS_SUGGESTIONS_QUERY, query.toLowerCase()],
    queryFn: () => getHubs({ name: query.toLowerCase(), action: 'edit' }),
    enabled: !!query,
  });

  const { data: boardsSuggestions = [] } = useQuery({
    queryKey: [BOARDS_SUGGESTIONS_QUERY, query.toLowerCase(), level],
    queryFn: () =>
      getBoards({ name: query.toLowerCase(), level, action: 'edit' }),
    enabled: !!query && !!level,
  });

  const results = [...boardsSuggestions, ...hubsSuggestions]
    .map((item) => {
      if ('hub' in item) {
        return {
          id: item.id,
          name: item.name,
          parent: item.parent,
          level: item.level,
          hub: item.hub,
          type: 'board',
        };
      } else {
        return { id: item.id, name: item.name, type: 'hub' };
      }
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const select = (location: any) => {
    selectLocation(location);
    close();
  };

  return (
    <SearchModal
      placeholder="Search for something"
      searchTerm={query}
      updateSearchTerm={queryEntered}
      onClose={close}
      isLoading={isLoading}
      results={query ? results : []}
      icon={
        results.length === 0 ? (
          <Icon size={80} name="sprite/folder-empty-colored" />
        ) : (
          <Icon size={80} name="sprite/folder-full-colored" />
        )
      }
      iconDescription={
        results.length === 0 ? undefined : 'Tidy files, tidy mind'
      }
      isOpen={isOpen}
      setIsOpen={open}
      renderItem={(row) => (
        <SearchModal.EmptyRow
          key={row.id}
          className={styles.result}
          onClick={() => select(row)}
        >
          <div className={styles.row}>
            <div className={styles.item}>
              {row.type === 'board' && (
                <Breadcrumbs board={row.parent} className={styles.breadcumbs} />
              )}
              <div className={styles.content}>
                {row.type === 'board' ? (
                  <div>
                    <Icon
                      name="sprite/board"
                      color="var(--color-secondary-40)"
                    />
                  </div>
                ) : (
                  <div>
                    <Icon name="sprite/hub" color="var(--color-secondary-40)" />
                  </div>
                )}
                <TypographyPoppins type="body" size="L" className={styles.name}>
                  {row.name}
                </TypographyPoppins>
                {location && row.id === location.id && (
                  <div style={{ paddingRight: '12px' }}>
                    <Icon name="sprite/tick" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </SearchModal.EmptyRow>
      )}
    >
      {children}
    </SearchModal>
  );
};

import {
  getDesign,
  updateDesignCustomColors,
  updateDesignFile,
  updateDesignName,
} from '@api/designs';
import { studioDesignKeys } from '@src/shared/constants/query-keys';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useCreateDesign } from '../use-create-design';

type Props = {
  designId: string;
};

export const useDesign = ({ designId }: Props) => {
  const { createDesignMutation } = useCreateDesign();

  const designQuery = useQuery({
    queryKey: studioDesignKeys.design(designId),
    queryFn: () => getDesign(designId),
    select: (data) => data.data,
  });

  const updateDesignNameMutation = useMutation({
    mutationFn: updateDesignName,
    onSuccess: () => {
      designQuery.refetch();
    },
  });

  const updateDesignFileMutation = useMutation({
    mutationFn: updateDesignFile,
    onSuccess: () => {
      designQuery.refetch();
    },
  });

  const updateDesignCustomColorsMutation = useMutation({
    mutationFn: updateDesignCustomColors,
    onSuccess: () => {
      designQuery.refetch();
    },
  });

  return {
    designQuery,
    createDesignMutation,
    updateDesignNameMutation,
    updateDesignFileMutation,
    updateDesignCustomColorsMutation,
  };
};

import { createEvent, createStore, sample } from 'effector';

export const boardDialogOpened = createEvent();
export const boardDialogClosed = createEvent();

export const stepContinue = createEvent();
export const stepBack = createEvent();

export const boardNameEntered = createEvent<string>();
export const boardNameCleared = createEvent();

export const boardDescriptionEntered = createEvent<string>();
export const boardDescriptionCleared = createEvent();

export const $isShowBoardDialog = createStore(false);
export const $step = createStore<1 | 2>(1);
export const $boardName = createStore('');
export const $boardDescription = createStore('');

$isShowBoardDialog.on(boardDialogOpened, () => true).reset(boardDialogClosed);

sample({
  clock: stepContinue,
  source: { isShowBoardDialog: $isShowBoardDialog, step: $step },
  filter: ({ isShowBoardDialog }) => isShowBoardDialog,
  fn: ({ step }) => toggleStep(step),
  target: $step,
});

sample({
  clock: stepBack,
  source: { isShowBoardDialog: $isShowBoardDialog, step: $step },
  filter: ({ isShowBoardDialog }) => isShowBoardDialog,
  fn: ({ step }) => toggleStep(step),
  target: $step,
});

$step.reset(boardDialogClosed);

$boardName
  .on(boardNameEntered, (_, boardName) => boardName)
  .reset(boardNameCleared);

$boardDescription
  .on(boardDescriptionEntered, (_, boardDescription) => boardDescription)
  .reset(boardDescriptionCleared);

sample({
  clock: boardDialogClosed,
  target: [boardNameCleared, boardDescriptionCleared],
});

const toggleStep = (step: 1 | 2) => (step === 1 ? 2 : 1);

export class LIFOStack<T> {
  private items: Array<T>;

  constructor() {
    this.items = [];
  }

  push(e: T) {
    if (!e) return;

    this.items.push(structuredClone(e));
  }

  pop() {
    if (this.isEmpty()) return null;

    const item = this.items.pop();

    return item ? structuredClone(item) : null;
  }

  peek() {
    if (this.isEmpty()) return null;

    return structuredClone(this.items[this.items.length - 1]);
  }

  isEmpty() {
    return this.items.length === 0;
  }

  size() {
    return this.items.length;
  }

  clear() {
    this.items = [];
    return this;
  }

  private readonly MAX_STACK_SIZE = 50;

  trimIfNeeded() {
    if (this.items.length > this.MAX_STACK_SIZE) {
      this.items = this.items.slice(-this.MAX_STACK_SIZE);
    }
  }
}

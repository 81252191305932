import { Fragment, useRef, useState } from 'react';

import { useHistory, useLocation } from 'react-router';

import {
  Divider,
  NavItem,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useOnClickOutside, useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { useAppData } from '@src/AppContext';

import styles from './styles.module.css';

const routes = [
  {
    link: 'profile',
    name: 'Profile',
    hasTopBorder: false,
    comingSoon: false,
    hideInDemo: false,
  },
  {
    link: 'billing',
    name: 'Plan & credits',
    hasTopBorder: false,
    comingSoon: false,
    hideInDemo: true,
  },
  {
    link: 'bin',
    name: 'Bin',
    hasTopBorder: true,
    comingSoon: false,
    hideInDemo: true,
  },
  // {
  //   link: 'rewards',
  //   name: 'Rewards',
  //   hasTopBorder: false,
  //   comingSoon: true,
  // },
  // {
  //   link: 'notifications',
  //   name: 'Notifications',
  //   hasTopBorder: false,
  //   comingSoon: true,
  // },
  // {
  //   link: 'trash',
  //   name: 'Trash',
  //   hasTopBorder: true,
  //   comingSoon: true,
  // },
] as const;

export const AccountNavigation = () => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useAppData();

  const { isMobile } = useWindowSize();

  const cameFromRef = useRef(
    (history.location.state as { from?: string })?.from || '/',
  );

  const goBack = () => {
    history.push(cameFromRef.current);
  };

  return (
    <div className={styles.container}>
      <div className={styles.backToWorkContainer}>
        <button className={styles.backToWorkButton} onClick={() => goBack()}>
          <Icon name="sprite/chevron-left" />
          <TypographyPoppins type="label" size="L">
            Back to work
          </TypographyPoppins>
        </button>
      </div>
      {isMobile ? (
        <nav className={styles.nav}>
          <MobileDropdown />
        </nav>
      ) : (
        <nav className={styles.nav}>
          {routes
            .filter(
              (route) => !(route.hideInDemo && user.meta?.onboarding?.demoMode),
            )
            .map((route) => {
              return (
                <Fragment key={route.link}>
                  {route.hasTopBorder && (
                    <Divider type="long-line" className={styles.noMargin} />
                  )}
                  <NavItem
                    isDisabled={route.comingSoon}
                    isSelected={location.pathname === `/account/${route.link}`}
                    to={`/account/${route.link}`}
                    key={route.link}
                    name={route.name}
                  />
                </Fragment>
              );
            })}
        </nav>
      )}
    </div>
  );
};

const MobileDropdown = () => {
  const location = useLocation();

  const [showDropdown, setShowDropdown] = useState(false);

  const mobileDropdownRef = useRef<HTMLDivElement>(null);

  const activeRoute = routes.find((r) => location.pathname.includes(r.link));

  useOnClickOutside(mobileDropdownRef, () => setShowDropdown(false));

  return (
    <div className={styles.mobileContainer} ref={mobileDropdownRef}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setShowDropdown((v) => !v);
        }}
        className={styles.mobileNavSelectedItemButton}
        data-isopen={showDropdown}
      >
        <NavItem
          isSelected
          name={activeRoute?.name ?? ''}
          to={location.pathname}
          rightIcon={
            <Icon
              name="sprite/caret-down"
              style={{
                transform: showDropdown ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          }
        />
      </button>
      {showDropdown ? (
        <div className={styles.routeDropdownContainer}>
          {routes
            .filter((r) => r.link !== activeRoute?.link)
            .map((item) => (
              <>
                {item.hasTopBorder && (
                  <Divider type="long-line" className={styles.noMargin} />
                )}
                <NavItem
                  isSelected={location.pathname.includes(item.link)}
                  name={item.name}
                  to={item.link}
                  onClick={() => setShowDropdown(false)}
                />
              </>
            ))}
        </div>
      ) : null}
    </div>
  );
};

import { useEffect, useRef, useState } from 'react';

import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory } from 'react-router';

import {
  Button,
  TextField,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { GoogleButton } from '../buttons';
import { useSignIn } from '../hooks/use-log-in';
import { LoginFormValues, LoginFormWithPasswordValues } from '../types';
import { validateEmail, validatePasswordLogin } from '../utils';

import styles from './styles.module.css';

const initialValues: LoginFormValues = {
  email: '',
};

export const LoginForm = () => {
  const history = useHistory();
  const [emailSuccess, setEmailSuccess] = useState('');
  const [hasTriedSubmitting, setHasTriedSubmitting] = useState(false);

  const formik = useFormik({
    initialValues,
    validateOnBlur: hasTriedSubmitting,
    validateOnChange: hasTriedSubmitting,
    validate: (v) =>
      validateEmail(v, {} as Record<keyof LoginFormValues, string>),
    onSubmit: (values) => {
      setEmailSuccess(values.email);
    },
  });

  return (
    <AnimatePresence mode="popLayout">
      {emailSuccess ? (
        <motion.div
          key={1}
          initial={{ transform: 'translateX(120%)' }}
          animate={{ transform: 'translateX(0%)' }}
          transition={{
            duration: 0.3,
            bounce: 0.2,
            type: 'spring',
          }}
        >
          <EmailLoginForm email={emailSuccess} />
        </motion.div>
      ) : (
        <motion.div
          key={2}
          exit={{
            transform: 'translateX(-120%)',
          }}
          transition={{
            duration: 0.3,
            bounce: 0.2,
            type: 'spring',
          }}
          className={styles.container}
        >
          <TypographyPoppins type="title" size="L" className={styles.title}>
            <motion.div
              transition={{
                duration: 0.3,
                bounce: 0.2,
                type: 'spring',
              }}
              layoutId="log-in-title"
            >
              Log in to Visualist
            </motion.div>
          </TypographyPoppins>
          <div className={styles.mainForm}>
            <div className={styles.buttons}>
              <GoogleButton>
                <GoogleButton.Login label="Continue with Google" />
              </GoogleButton>
              {/* <AppleButton /> */}
            </div>
            <TypographyPoppins type="body" size="L" className={styles.or}>
              or
            </TypographyPoppins>
            <form
              noValidate
              onSubmit={(e) => {
                setHasTriedSubmitting(true);
                formik.handleSubmit(e);
              }}
              className={styles.form}
            >
              <div className={styles.formInput}>
                <TypographyPoppins
                  type="label"
                  size="M"
                  className={styles.emailLabel}
                >
                  <label htmlFor="email">Email</label>
                </TypographyPoppins>
                <TextField
                  autoFocus
                  formNoValidate
                  id="email"
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  errorAndSupportingText={formik.errors.email}
                  clear={(e) => {
                    e.preventDefault();
                    formik.resetForm();
                  }}
                />
              </div>
              <div className={styles.formButtons}>
                <Button
                  type="filled"
                  label="Log in with email"
                  // Empty onclick as it's handled by formik
                  onClick={() => {}}
                />
                <Button
                  type="ghost"
                  label="Reset password"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push('/forgot-password');
                  }}
                />
              </div>
            </form>
          </div>
          <div className={styles.footer}>
            <TypographyPoppins type="body" size="M">
              Don't have an account?
            </TypographyPoppins>
            <Button
              type="ghost"
              label="Sign up now"
              onClick={() => {
                history.push('/signup');
              }}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const EmailLoginForm = ({ email }: { email: string }) => {
  const history = useHistory();
  const [errorCount, setErrorCount] = useState(0);
  const [hasTriedSubmitting, setHasTriedSubmitting] = useState(false);
  const password1Ref = useRef<HTMLInputElement | null>(null);

  const { mutate: signIn, isError } = useSignIn({
    options: {
      onError: (error, variables) => {
        if (errorCount > 4) {
          startedSnack({
            label: 'Too many login attempts. Please try again later',
            close: true,
          });
        }

        if (error.response?.data?.non_field_errors) {
          setErrorCount((e) => e + 1);
        } else {
          startedSnack({
            label: "Sorry, there's a problem with the connection",
            action: {
              label: 'Try again',
              action: () => {
                signIn({
                  email: variables.email,
                  password: variables.password,
                });
              },
            },
            close: true,
          });
        }
      },
    },
  });

  const formik = useFormik<LoginFormWithPasswordValues>({
    initialValues: {
      email,
      password: '',
    },
    validateOnBlur: hasTriedSubmitting,
    validateOnChange: hasTriedSubmitting,
    validate: (v) =>
      validatePasswordLogin(
        v,
        {} as Record<keyof LoginFormWithPasswordValues, string>,
      ),
    onSubmit: (values) => {
      signIn({ email: values.email, password: values.password });
    },
  });

  useEffect(() => {
    // Focus password element after animation is complete otherwise is janks
    const timeout = setTimeout(() => {
      password1Ref.current?.focus();
    }, 300);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={styles.container}>
      <TypographyPoppins type="title" size="L" className={styles.title}>
        <motion.div
          transition={{
            duration: 0.3,
            bounce: 0.2,
            type: 'spring',
          }}
          layoutId="log-in-title"
        >
          Log in to Visualist
        </motion.div>
      </TypographyPoppins>
      <form
        noValidate
        onSubmit={(e) => {
          setHasTriedSubmitting(true);
          formik.handleSubmit(e);
        }}
        className={styles.form}
      >
        <div className={styles.formInput}>
          <TypographyPoppins
            type="label"
            size="M"
            className={styles.emailLabel}
          >
            <label htmlFor="email">Email</label>
          </TypographyPoppins>
          <TextField
            formNoValidate
            id="email2"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            errorAndSupportingText={formik.errors.email}
            clear={(e) => {
              e.preventDefault();
              formik.setFieldValue('email', '');
            }}
          />
        </div>
        <div className={styles.formInput}>
          <TypographyPoppins
            type="label"
            size="M"
            className={styles.emailLabel}
          >
            <label htmlFor="email">Password</label>
          </TypographyPoppins>
          <TextField
            ref={password1Ref}
            formNoValidate
            id="password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            errorAndSupportingText={formik.errors.password}
            clear={(e) => {
              e.preventDefault();
              formik.resetForm();
            }}
          />
        </div>
        {isError ? (
          <TypographyPoppins type="body" size="S" className={styles.error}>
            Check your credentials: your email or password is invalid
          </TypographyPoppins>
        ) : null}
        <div className={styles.formButtons}>
          <Button type="filled" label="Log in with email" onClick={() => {}} />
          <Button
            type="ghost"
            label="Reset password"
            onClick={(e) => {
              e.preventDefault();
              history.push('/forgot-password');
            }}
          />
        </div>
      </form>
      <div className={styles.footer}>
        <TypographyPoppins type="body" size="M">
          Don't have an account?
        </TypographyPoppins>
        <Button
          type="ghost"
          label="Sign up now"
          onClick={() => {
            history.push('/signup');
          }}
        />
      </div>
    </div>
  );
};

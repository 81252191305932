import { jsPDF } from 'jspdf';
import Konva from 'konva';
import { Layer } from 'konva/lib/Layer';
import { Stage } from 'konva/lib/Stage';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  DesignJSONV2,
  DesignObject,
  ImageJSON,
  Page,
  ShapeJSON,
  TextJSON,
} from '@api/designs';

import {
  IMAGE_GROUP,
  MAIN_OBJECTS_LAYER,
  SHAPE,
  SHAPE_GROUP,
  TEXT,
  TEXT_GROUP,
} from './constants';
import { DesignMetadata } from './lib/design';
import { generatePreview } from './utils';

type TempStageContext = {
  container: HTMLDivElement;
  stage: Stage;
  layer: Layer;
};

type PreviewDimensions = {
  maxX: number;
  minX: number;
  maxY: number;
  minY: number;
};

// Creates a temporary stage for rendering
export function createTempStage({
  width,
  height,
  backgroundColor,
  type,
}: {
  width: number;
  height: number;
  backgroundColor: string;
  type: 'page' | 'infinite';
}): TempStageContext {
  const container = document.createElement('div');
  container.style.position = 'absolute';
  container.style.visibility = 'hidden';
  container.style.width = `${width}px`;
  container.style.height = `${height}px`;
  container.style.left = '0';
  container.style.top = '0';
  document.body.appendChild(container);

  const stage = new Konva.Stage({
    container,
    width,
    height,
  });

  const layer = new Konva.Layer({ name: MAIN_OBJECTS_LAYER });
  stage.add(layer);

  if (type === 'infinite') {
    return { container, stage, layer };
  }

  const paper = new Konva.Rect({
    x: 0,
    y: 0,
    height,
    width,
    fill: backgroundColor,
    name: SHAPE,
    id: 'PAGE-CONTAINER-TEMP',
  });

  const paperGroup = new Konva.Group({ name: SHAPE_GROUP });
  paperGroup.add(paper);
  layer.add(paperGroup);

  return { container, stage, layer };
}

// Cleans up temporary stage elements
export function cleanupTempStage({
  container,
  stage,
}: Partial<TempStageContext>): void {
  if (stage) stage.destroy();
  if (container && document.body.contains(container)) {
    document.body.removeChild(container);
  }
}

// Generates a preview for a single page
async function generatePagePreview(
  page: Page,
  quality: Parameters<typeof generatePreview>['1'],
  mimeType: 'image/png' | 'image/jpeg',
): Promise<{ data: string; dimensions: PreviewDimensions } | null> {
  const { container, stage, layer } = createTempStage({
    width: page.metadata.width,
    height: page.metadata.height,
    backgroundColor: page.metadata.backgroundColor,
    type: 'page',
  });

  try {
    await loadPageObjectsToStage(layer, page.objects);
    const result = generatePreview(stage, quality, mimeType, false, page);

    if (!result.data) return null;

    return {
      data: result.data,
      dimensions: {
        maxX: result.maxX,
        minX: result.minX,
        maxY: result.maxY,
        minY: result.minY,
      },
    };
  } finally {
    cleanupTempStage({ container, stage });
  }
}

// Downloads a single image file
function downloadImage(data: string, filename: string): void {
  const link = document.createElement('a');
  link.href = data;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const downloadStudioImages = async (
  stage: Stage,
  designData: { state: DesignJSONV2 | null; metadata: DesignMetadata },
  name?: string,
  format: 'png' | 'jpg' = 'png',
) => {
  const mimeType = format === 'png' ? 'image/png' : 'image/jpeg';
  const fileExtension = format === 'png' ? 'png' : 'jpg';

  try {
    startedSnack({
      label: `Exporting design as ${format.toUpperCase()}...`,
    });

    if (designData.state?.type === 'pages' && designData.state.data) {
      // Handle multi-page design
      for (const [index, page] of designData.state.data.entries()) {
        const result = await generatePagePreview(page, 'export', mimeType);
        if (!result) {
          console.error(`Failed to generate preview for page ${index}`);
          continue;
        }

        downloadImage(
          result.data,
          `${name || 'design'}-page-${index + 1}.${fileExtension}`,
        );
      }
    } else {
      // Handle single page design
      const result = generatePreview(stage, 'export', mimeType);
      if (!result.data) {
        throw new Error("Couldn't generate preview");
      }

      startedSnack({
        label: `Exporting design as ${format.toUpperCase()}...`,
      });
      downloadImage(result.data, `${name || 'design'}.${fileExtension}`);
    }
  } catch (error) {
    console.error('Error exporting images:', error);
    startedSnack({
      label: `Couldn't export design as ${format.toUpperCase()}`,
      action: {
        label: 'Try again',
        action: () => downloadStudioImages(stage, designData, name, format),
      },
      close: true,
    });
  }
};

export const downloadStudioPDF = async (
  stage: Stage,
  designData: { state: DesignJSONV2 | null; metadata: DesignMetadata },
  name?: string,
) => {
  const pdf = new jsPDF('p', 'px', 'a4');
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();

  try {
    startedSnack({ label: 'Exporting design...' });
    if (designData.state?.type === 'pages' && designData.state.data) {
      // Handle multi-page design
      let firstPageAdded = false;

      for (const [index, page] of designData.state.data.entries()) {
        const result = await generatePagePreview(page, 'export', 'image/jpeg');
        if (!result) {
          console.error(`Failed to generate preview for page ${index}`);
          continue;
        }

        const { data, dimensions } = result;
        const { maxX, minX, maxY, minY } = dimensions;

        // Calculate best fit
        const widthRatio = pageWidth / (maxX - minX);
        const heightRatio = pageHeight / (maxY - minY);
        const ratio = Math.min(widthRatio, heightRatio);
        const canvasWidth = (maxX - minX) * ratio;
        const canvasHeight = (maxY - minY) * ratio;

        const orientation =
          page.metadata.orientation === 'landscape' ? 'l' : 'p';
        pdf.addPage([canvasWidth, canvasHeight], orientation);
        firstPageAdded = true;
        pdf.addImage(data, 'PNG', 0, 0, canvasWidth, canvasHeight);
      }

      if (firstPageAdded) {
        pdf.deletePage(1);
      }
    } else {
      // Handle single page design
      const result = generatePreview(stage, 'export', 'image/jpeg');
      if (!result.data) {
        throw new Error("Couldn't generate preview");
      }

      const { maxX, minX, maxY, minY } = result;
      const widthRatio = pageWidth / (maxX - minX);
      const heightRatio = pageHeight / (maxY - minY);
      const ratio = Math.min(widthRatio, heightRatio);
      const canvasWidth = (maxX - minX) * ratio;
      const canvasHeight = (maxY - minY) * ratio;

      pdf.addPage(
        [canvasWidth, canvasHeight],
        canvasWidth > canvasHeight ? 'l' : 'p',
      );
      pdf.addImage(result.data, 'JPEG', 0, 0, canvasWidth, canvasHeight);
      pdf.deletePage(1);
    }

    pdf.save(`${name || 'design'}.pdf`);
    startedSnack({ label: 'Exported pdf' });
  } catch (error) {
    console.error('Error generating PDF:', error);
    startedSnack({
      label: "Couldn't export pdf",
      action: {
        label: 'Try again',
        action: () => downloadStudioPDF(stage, designData, name),
      },
      close: true,
    });
  }
};

// Helper function to load page objects into a stage
export const loadPageObjectsToStage = async (
  layer: Layer,
  objects: DesignObject[],
) => {
  // Process each design object and add it to the layer
  for (const obj of objects) {
    switch (obj.type) {
      case 'image':
        await addImageToLayer(layer, obj);
        break;
      case 'text':
        addTextToLayer(layer, obj);
        break;
      case 'shape':
        addShapeToLayer(layer, obj);
        break;
    }
  }

  // Force layer update
  layer.draw();
};

// Helper function to add an image to the layer
const addImageToLayer = async (layer: Layer, imageJSON: ImageJSON) => {
  // Assuming you have a way to get the image source from the file data
  const imageElement = new Image();

  imageElement.crossOrigin = 'anonymous';

  // Create a promise to wait for the image to load
  const imageLoadPromise = new Promise((resolve, reject) => {
    imageElement.onload = resolve;
    imageElement.onerror = reject;

    // Set the source based on your file structure
    imageElement.src = imageJSON.metadata.file.full_size;
  });

  try {
    await imageLoadPromise;

    // Create the Konva image
    const konvaImage = new Konva.Image({
      x: 0,
      y: 0,
      image: imageElement,
      width: imageJSON.metadata.width,
      height: imageJSON.metadata.height,
    });

    // Apply crop if it exists
    if (imageJSON.metadata.crop) {
      konvaImage.crop({
        x: imageJSON.metadata.crop.x,
        y: imageJSON.metadata.crop.y,
        width: imageJSON.metadata.crop.width,
        height: imageJSON.metadata.crop.height,
      });
    }

    const group = new Konva.Group({
      id: imageJSON.id,
      name: IMAGE_GROUP,
      x: imageJSON.x,
      y: imageJSON.y,
      width: imageJSON.metadata.width,
      height: imageJSON.metadata.height,
      rotation: imageJSON.rotation || 0,
      opacity: imageJSON.opacity !== undefined ? imageJSON.opacity : 1,
    });

    group.add(konvaImage);

    // Add to layer
    layer.add(group);
  } catch (error) {
    console.error('Failed to load image:', error);
  }
};

// Helper function to add text to the layer
const addTextToLayer = (layer: Layer, textJSON: TextJSON) => {
  const konvaText = new Konva.Text({
    name: TEXT,
    id: textJSON.id,
    x: textJSON.x,
    y: textJSON.y,
    text: textJSON.metadata.content,
    fontSize: textJSON.metadata.fontSize,
    fontFamily: textJSON.metadata.fontFamily,
    fill: textJSON.metadata.colour,
    width: textJSON.metadata.width,
    height: textJSON.metadata.height,
    align: textJSON.metadata.alignment,
    fontStyle: textJSON.metadata.italic ? 'italic' : 'normal',
    fontVariant: textJSON.metadata.underline ? 'small-caps' : 'normal',
    textDecoration: textJSON.metadata.underline ? 'underline' : '',
    rotation: textJSON.rotation || 0,
    opacity: textJSON.opacity !== undefined ? textJSON.opacity : 1,
  });

  const group = new Konva.Group({
    name: TEXT_GROUP,
  });

  group.add(konvaText);

  // Apply bold if needed
  if (textJSON.metadata.bold) {
    konvaText.fontStyle(konvaText.fontStyle() + ' bold');
  }

  // Add to layer
  layer.add(group);
};

// Helper function to add shapes to the layer
const addShapeToLayer = (layer: Layer, shapeJSON: ShapeJSON) => {
  let shape;

  switch (shapeJSON.metadata.type) {
    case 'rectangle':
      shape = new Konva.Rect({
        name: SHAPE,
        id: shapeJSON.id,
        x: shapeJSON.x,
        y: shapeJSON.y,
        width: shapeJSON.metadata.width,
        height: shapeJSON.metadata.height,
        fill: shapeJSON.metadata.fill,
        stroke: shapeJSON.metadata.stroke,
        strokeWidth: shapeJSON.metadata.strokeWidth,
        rotation: shapeJSON.rotation || 0,
        opacity: shapeJSON.opacity !== undefined ? shapeJSON.opacity : 1,
      });
      break;

    case 'circle':
      shape = new Konva.Circle({
        name: SHAPE,
        id: shapeJSON.id,
        x: shapeJSON.x,
        y: shapeJSON.y,
        radius: shapeJSON.metadata.radius,
        fill: shapeJSON.metadata.fill,
        stroke: shapeJSON.metadata.stroke,
        strokeWidth: shapeJSON.metadata.strokeWidth,
        rotation: shapeJSON.rotation || 0,
        opacity: shapeJSON.opacity !== undefined ? shapeJSON.opacity : 1,
      });
      break;

    case 'ellipse':
      shape = new Konva.Ellipse({
        name: SHAPE,
        id: shapeJSON.id,
        x: shapeJSON.x,
        y: shapeJSON.y,
        radiusX: shapeJSON.metadata.radiusX ?? 0,
        radiusY: shapeJSON.metadata.radiusY ?? 0,
        fill: shapeJSON.metadata.fill,
        stroke: shapeJSON.metadata.stroke,
        strokeWidth: shapeJSON.metadata.strokeWidth,
        rotation: shapeJSON.rotation || 0,
        opacity: shapeJSON.opacity !== undefined ? shapeJSON.opacity : 1,
      });
      break;

    case 'hexagon':
      shape = new Konva.RegularPolygon({
        name: SHAPE,
        id: shapeJSON.id,
        x: shapeJSON.x,
        y: shapeJSON.y,
        sides: shapeJSON.metadata.sides ?? 0,
        radius: shapeJSON.metadata.radius ?? 0,
        fill: shapeJSON.metadata.fill,
        stroke: shapeJSON.metadata.stroke,
        strokeWidth: shapeJSON.metadata.strokeWidth,
        rotation: shapeJSON.rotation || 0,
        opacity: shapeJSON.opacity !== undefined ? shapeJSON.opacity : 1,
      });
      break;

    case 'wedge':
      shape = new Konva.Wedge({
        name: SHAPE,
        id: shapeJSON.id,
        x: shapeJSON.x,
        y: shapeJSON.y,
        radius: shapeJSON.metadata.radius ?? 0,
        angle: shapeJSON.metadata.angle ?? 0,
        fill: shapeJSON.metadata.fill,
        stroke: shapeJSON.metadata.stroke,
        strokeWidth: shapeJSON.metadata.strokeWidth,
        rotation: shapeJSON.rotation || 0,
        opacity: shapeJSON.opacity !== undefined ? shapeJSON.opacity : 1,
      });
      break;

    case 'star':
      shape = new Konva.Star({
        name: SHAPE,
        id: shapeJSON.id,
        x: shapeJSON.x,
        y: shapeJSON.y,
        numPoints: shapeJSON.metadata.numPoints ?? 0,
        innerRadius: shapeJSON.metadata.innerRadius ?? 0,
        outerRadius: shapeJSON.metadata.outerRadius ?? 0,
        fill: shapeJSON.metadata.fill,
        stroke: shapeJSON.metadata.stroke,
        strokeWidth: shapeJSON.metadata.strokeWidth,
        rotation: shapeJSON.rotation || 0,
        opacity: shapeJSON.opacity !== undefined ? shapeJSON.opacity : 1,
      });
      break;

    case 'ring':
      shape = new Konva.Ring({
        name: SHAPE,
        id: shapeJSON.id,
        x: shapeJSON.x,
        y: shapeJSON.y,
        innerRadius: shapeJSON.metadata.innerRadius ?? 0,
        outerRadius: shapeJSON.metadata.outerRadius ?? 0,
        fill: shapeJSON.metadata.fill,
        stroke: shapeJSON.metadata.stroke,
        strokeWidth: shapeJSON.metadata.strokeWidth,
        rotation: shapeJSON.rotation || 0,
        opacity: shapeJSON.opacity !== undefined ? shapeJSON.opacity : 1,
      });
      break;

    case 'arc':
      shape = new Konva.Arc({
        name: SHAPE,
        id: shapeJSON.id,
        x: shapeJSON.x,
        y: shapeJSON.y,
        innerRadius: shapeJSON.metadata.innerRadius ?? 0,
        outerRadius: shapeJSON.metadata.outerRadius ?? 0,
        angle: shapeJSON.metadata.angle ?? 0,
        fill: shapeJSON.metadata.fill,
        stroke: shapeJSON.metadata.stroke,
        strokeWidth: shapeJSON.metadata.strokeWidth,
        rotation: shapeJSON.rotation || 0,
        opacity: shapeJSON.opacity !== undefined ? shapeJSON.opacity : 1,
      });
      break;

    case 'line':
      shape = new Konva.Line({
        name: SHAPE,
        id: shapeJSON.id,
        x: shapeJSON.x,
        y: shapeJSON.y,
        points: shapeJSON.metadata.points,
        fill: shapeJSON.metadata.fill,
        stroke: shapeJSON.metadata.stroke,
        strokeWidth: shapeJSON.metadata.strokeWidth,
        rotation: shapeJSON.rotation || 0,
        opacity: shapeJSON.opacity !== undefined ? shapeJSON.opacity : 1,
      });
      break;

    case 'arrow':
      shape = new Konva.Arrow({
        name: SHAPE,
        id: shapeJSON.id,
        x: shapeJSON.x,
        y: shapeJSON.y,
        points: shapeJSON.metadata.points ?? [],
        pointerLength: shapeJSON.metadata.pointerLength,
        pointerWidth: shapeJSON.metadata.pointerWidth,
        fill: shapeJSON.metadata.fill,
        stroke: shapeJSON.metadata.stroke,
        strokeWidth: shapeJSON.metadata.strokeWidth,
        rotation: shapeJSON.rotation || 0,
        opacity: shapeJSON.opacity !== undefined ? shapeJSON.opacity : 1,
      });
      break;
  }

  const group = new Konva.Group({
    name: SHAPE_GROUP,
  });

  if (shape) {
    group.add(shape);
    layer.add(group);
  }
};

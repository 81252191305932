import { ImageJSON } from '@api/designs';

export class ImageFile {
  private readonly image: ImageJSON;

  constructor(image: ImageJSON) {
    this.image = image;
  }

  resizeImage({ width, height }: { width: number; height: number }) {
    this.image.metadata.width = width;
    this.image.metadata.height = height;

    return this;
  }

  resizeWidth(width: number) {
    this.image.metadata.width = width; //(maxX - minX) * 1.5;
    this.image.metadata.height =
      this.image.metadata.width /
      (this.image.metadata.originalWidth / this.image.metadata.originalHeight);

    return this;
  }

  resizeImageAspect(size: number) {
    const image = this.image;
    const totalHeight = size;
    const totalWidth = size;

    const aspectRatio = image.metadata.width / image.metadata.height;

    // Get 90& of the height or width of the max bounds of all images
    if (image.metadata.height > image.metadata.width) {
      // Vertical image priority
      image.metadata.height = totalHeight; //(maxY - minY) * 1.5;
      image.metadata.width = image.metadata.height * aspectRatio;
    } else {
      // Horizontal image priority
      image.metadata.width = totalWidth; //(maxX - minX) * 1.5;
      image.metadata.height = image.metadata.width / aspectRatio;
    }

    return this;
  }

  resetRotation() {
    this.image.rotation = 0;
    return this;
  }

  centerImageFromPoint({ x, y }: { x: number; y: number }) {
    this.image.x = x - this.image.metadata.width / 2;
    this.image.y = y - this.image.metadata.height / 2;
    return this;
  }

  getId() {
    return this.image.id;
  }

  getRawImage() {
    return this.image;
  }

  getImageDimensions() {
    return {
      x: this.image.x,
      y: this.image.y,
      width: this.image.metadata.width,
      height: this.image.metadata.height,
    };
  }
}

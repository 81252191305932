import cn from 'classnames';
import { useUnit } from 'effector-react';

import { TooltipRadix } from '@visualist/design-system/src/components/v2';

import { $searchFullScreen } from '../../model';

import styles from './styles.module.css';

export const ExpandButton = ({
  children,
  ...props
}: React.ComponentProps<'button'>) => {
  const [searchFullScreen] = useUnit([$searchFullScreen]);

  return (
    <TooltipRadix
      classNameTrigger={props.className}
      description={searchFullScreen ? 'Exit full screen' : 'Go full screen'}
      side="left"
    >
      <button {...props} className={cn(styles.expandButton)}>
        {children}
      </button>
    </TooltipRadix>
  );
};

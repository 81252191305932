import { useState } from 'react';

import { useUnit } from 'effector-react';
import { Stage } from 'konva/lib/Stage';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';

import {
  SegmentedButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import { $stageState, updatedStage } from '@pages/StudioPage/model';
import { SwitchPagesOrInfiniteDialog } from '@src/entities/dialogs/studio/switch-pages-infinity';

import styles from './styles.module.css';

export const DesignLayout = ({
  designId,
  stage,
}: {
  designId: string;
  stage: Stage | null;
}) => {
  const history = useHistory();
  const stageState = useUnit($stageState);

  const { designData, switchStateType, objects } = useStudioDesign(designId);

  const [
    isOpenSwitchPagesOrInfinityDialog,
    setIsOpenSwitchPagesOrInfinityDialog,
  ] = useState(false);

  const switchToInfinte = () => {
    setIsOpenSwitchPagesOrInfinityDialog(false);
    switchStateType(stage);
    history.push(`/studio/${designId}`);
  };

  const switchToPages = () => {
    setIsOpenSwitchPagesOrInfinityDialog(false);
    switchStateType(stage);
    history.push(`/studio/${designId}/1`);
    updatedStage({
      scale: isMobile ? 0.1 : 0.25,
      x: stageState.x,
      y: stageState.y,
    });
  };

  return (
    <div className={styles.toolbarSection}>
      {designData.state?.type === 'pages' ? (
        <TooltipRadix description="Switch to infinity canvas">
          <SegmentedButton
            end
            buttonStyle={styles.button}
            icon={<Icon name="sprite/page-landscape" />}
            onClick={() => {
              if (objects.length > 0) {
                setIsOpenSwitchPagesOrInfinityDialog(true);
              } else {
                switchToInfinte();
              }
            }}
            isSelected={designData.state?.type === 'pages'}
          />
        </TooltipRadix>
      ) : (
        <TooltipRadix description="Switch to pages">
          <SegmentedButton
            end
            buttonStyle={styles.button}
            icon={<Icon name="sprite/infinite-canvas" />}
            onClick={() => {
              if (objects.length > 0) {
                setIsOpenSwitchPagesOrInfinityDialog(true);
              } else {
                switchToPages();
              }
            }}
            isSelected={designData.state?.type === 'infinite'}
          />
        </TooltipRadix>
      )}
      <SwitchPagesOrInfiniteDialog
        headline={
          designData.state?.type === 'pages'
            ? 'Switch to infinity canvas?'
            : 'Switch to pages?'
        }
        description={
          designData.state?.type === 'pages'
            ? 'Your pages will be removed, and all objects will be placed on an infinity canvas.'
            : 'All objects on the canvas will be placed on a single page. They’ll retain their current arrangement.'
        }
        isOpenSwitchPagesOrInfinityDialog={isOpenSwitchPagesOrInfinityDialog}
        setOpenSwitchPagesOrInfinityDialog={
          setIsOpenSwitchPagesOrInfinityDialog
        }
        switchPagesOrInfinity={
          designData.state?.type === 'pages' ? switchToInfinte : switchToPages
        }
      />
    </div>
  );
};

import { isMobile } from 'react-device-detect';

import {
  IconButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { downloadSelectedBlocksSingle } from '@api/blocks';

import styles from './styles.module.css';

export const Download = ({ block, getFileType }: any) => {
  const download = async () => {
    if (block.file.full_size) {
      try {
        startedSnack({
          label: `Downloading image...`,
          close: true,
        });

        const blob = await downloadSelectedBlocksSingle(block.id);
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = `${block.id}.jpg`;
        link.click();

        startedSnack({
          label: `Downloaded ${getFileType()}`,
          close: true,
        });
      } catch (error) {
        startedSnack({
          label: `Couldn't download ${getFileType()}`,
          action: {
            label: 'Try again',
            action: () => {
              download();
            },
          },
          close: true,
        });
      }
    }
  };

  return (
    <TooltipRadix description="Download" side={isMobile ? 'bottom' : 'right'}>
      <IconButton
        type="unfilled"
        className={styles.iconButton}
        icon={<Icon name="sprite/download" className={styles.icon} />}
        onClick={download}
        isDisabled={block?.is_archived}
      />
    </TooltipRadix>
  );
};

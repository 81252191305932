import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { getDeletedItems, permanentDeleteAllItems } from '@api/bin';
import { recycleBin } from '@src/shared/constants/query-keys';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const useDeletedItems = () => {
  const queryClient = useQueryClient();

  const itemsQuery = useQuery({
    queryKey: recycleBin.all,
    queryFn: () => getDeletedItems({}),
    refetchInterval: 180000,
  });

  const { mutate: permanentDeleteItems } = useMutation({
    mutationFn: () => permanentDeleteAllItems(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: recycleBin.all,
      });

      startedSnack({
        label: 'Emptied bin',
        close: true,
      });
    },
    onError: () => {
      startedSnack({
        label: 'Couldn’t empty bin',
        action: {
          label: 'Try again',
          action: () => permanentDeleteItems(),
        },
        close: true,
      });
    },
  });

  return {
    itemsQuery,
    permanentDeleteItems,
  };
};

import { useEffect } from 'react';

export const useKeyPress = ({
  key,
  onKeyDown,
  onKeyUp,
}: {
  key: string;
  onKeyDown?: (event: KeyboardEvent) => void;
  onKeyUp?: (event: KeyboardEvent) => void;
}): void => {
  useEffect(() => {
    const handleDown = (event: KeyboardEvent) => {
      const element = event.target as Element;
      if (element && element.matches('input')) return;
      if (event.repeat) return;
      const { key: pressedKey } = event;
      if (key === pressedKey && onKeyDown) {
        onKeyDown(event);
      }
    };

    const handleUp = (event: KeyboardEvent) => {
      const element = event.target as Element;
      if (element && element.matches('input')) return;
      const { key: pressedKey } = event;
      if (key === pressedKey && onKeyUp) {
        onKeyUp(event);
      }
    };

    window.addEventListener('keydown', handleDown);
    window.addEventListener('keyup', handleUp);

    return () => {
      window.removeEventListener('keydown', handleDown);
      window.removeEventListener('keyup', handleUp);
    };
  }, [key, onKeyDown, onKeyUp]);
};

import { createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';

import { archiveBoardIdCleared } from '@pages/BoardPage/model/archive';
import { archiveFileIdCleared } from '@pages/FileCard/model/archive';
import { archiveHubIdCleared } from '@pages/HubPage/model/archive';
import { archiveDocIdCleared } from '@src/entities/doc/archive';
import { entityTypeCleared } from '@src/shared/utils/get-entity-type';

export const unarchiveDialogOpened = createEvent();
export const unarchiveDialogClosed = createEvent();

export const $isOpenUnarchiveDialog = createStore(false);

sample({
  clock: unarchiveDialogOpened,
  fn: () => true,
  target: $isOpenUnarchiveDialog,
});

sample({
  clock: unarchiveDialogClosed,
  fn: () => false,
  target: $isOpenUnarchiveDialog,
});

sample({
  clock: unarchiveDialogClosed,
  target: [
    entityTypeCleared,
    archiveHubIdCleared,
    archiveBoardIdCleared,
    archiveDocIdCleared,
    archiveFileIdCleared,
  ],
});

hotkey({
  key: 'Escape',
  filter: $isOpenUnarchiveDialog,
  target: unarchiveDialogClosed,
});

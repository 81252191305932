import cn from 'classnames';
import { useUnit } from 'effector-react';

import {
  Button,
  FilterChip,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { Title } from '@src/entities/dialogs/ui/title';

import { Location } from '../../../../search-modal/location';
import {
  $location,
  locationCleared,
  locationSelected,
} from '../../../../search-modal/location/model';
import { $isBlur } from '../../../../search-modal/location/model/blur';
import { useLocation } from '../../../../search-modal/location/use-location';
import { boardDialogClosed, stepContinue } from '../model/create';
import {
  $isSearchOpen,
  $query,
  queryEntered,
  searchClosed,
  searchOpened,
} from '../model/show-search';

import styles from './styles.module.css';

export const Step1 = () => {
  const isSearchOpen = useUnit($isSearchOpen);
  const query = useUnit($query);
  const location = useUnit($location);
  const isBlur = useUnit($isBlur);

  const { ref, getLeadingIcon, getTrailingIcon, isShowTooltip } = useLocation({
    location,
  });

  const clear = () => locationCleared();

  return (
    <>
      <div className={styles.progress}>
        <button className={styles.current}></button>
        <button
          className={styles.continue}
          onClick={() => stepContinue()}
        ></button>
      </div>
      <Title
        className={styles.title}
        title="Create a board"
        description="Use boards to organize your documents, images, designs and palettes."
      />
      <div className={styles.location}>
        <div className={styles.selection}>
          {location && (
            <TypographyPoppins className={styles.nest} type="body" size="S">
              Nest board in
            </TypographyPoppins>
          )}
          <Location
            isOpen={isSearchOpen}
            open={searchOpened}
            close={searchClosed}
            query={query}
            queryEntered={queryEntered}
            location={location}
            selectLocation={locationSelected}
          >
            {isShowTooltip() ? (
              <TooltipRadix
                description={
                  <Breadcrumbs
                    board={location && location.parent}
                    className={styles.breadcrumbs}
                  />
                }
              >
                <FilterChip
                  ref={ref}
                  className={cn({
                    [styles.filterChip]: location !== null,
                  })}
                  classNameLabel={cn({
                    [styles.blur]: isBlur,
                  })}
                  leadingIcon={getLeadingIcon()}
                  trailingIcon={getTrailingIcon()}
                  trailingAction={location ? clear : undefined}
                  isSelected={location !== null}
                  onClick={() => searchOpened(true)}
                  hideTick
                >
                  {location ? location.name : 'Choose a location (optional)'}
                </FilterChip>
              </TooltipRadix>
            ) : (
              <FilterChip
                ref={ref}
                className={cn({
                  [styles.filterChip]: location !== null,
                })}
                classNameLabel={cn({
                  [styles.blur]: isBlur,
                })}
                leadingIcon={getLeadingIcon()}
                trailingIcon={getTrailingIcon()}
                trailingAction={location ? clear : undefined}
                isSelected={location !== null}
                hideTick
              >
                {location ? location.name : 'Choose a location (optional)'}
              </FilterChip>
            )}
          </Location>
        </div>
        <TypographyPoppins className={styles.tip} type="body" size="S">
          Don’t worry, you can always move it later.
        </TypographyPoppins>
      </div>
      <div className={styles.actions}>
        <Button
          type="outlined"
          label="Cancel"
          onClick={() => boardDialogClosed()}
        />
        <Button type="filled" label="Continue" onClick={() => stepContinue()} />
      </div>
    </>
  );
};

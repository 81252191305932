import { Dialog, Modal } from '@visualist/design-system/src/components/v2';

export const SwitchPagesOrInfiniteDialog = ({
  headline,
  description,
  isOpenSwitchPagesOrInfinityDialog,
  setOpenSwitchPagesOrInfinityDialog,
  switchPagesOrInfinity,
}: {
  headline: string;
  description: string;
  isOpenSwitchPagesOrInfinityDialog: boolean;
  setOpenSwitchPagesOrInfinityDialog: (switchPagesOrInfinity: boolean) => void;
  switchPagesOrInfinity: () => void;
}) => {
  return (
    <Modal
      showModal={isOpenSwitchPagesOrInfinityDialog}
      handleClose={() => {
        setOpenSwitchPagesOrInfinityDialog(false);
      }}
    >
      <Dialog
        headline={headline}
        description={description}
        action={switchPagesOrInfinity}
        actionLabel="Okay"
        closeAction={() => {
          setOpenSwitchPagesOrInfinityDialog(false);
        }}
      />
    </Modal>
  );
};

import { ForwardedRef, MutableRefObject, useEffect } from 'react';

type EventType = MouseEvent | TouchEvent;

function isRefObject<T>(
  ref: MutableRefObject<T> | ForwardedRef<T>,
): ref is MutableRefObject<T> {
  return ref !== null && typeof ref === 'object' && 'current' in ref;
}

export const useOnClickOutside = (
  ref: MutableRefObject<any> | ForwardedRef<any>,
  handler: (e: EventType) => void,
) => {
  useEffect(
    () => {
      const listener = (event: EventType) => {
        if (!ref) return;

        const element = isRefObject(ref) ? ref.current : null;

        // Do nothing if clicking ref's element or descendent elements
        if (!element || element.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);

      // if ('ontouchstart' in document.documentElement) {
      //   document.addEventListener('touchstart', listener);
      // } else {
      //   document.addEventListener('mousedown', listener);
      // }

      return () => {
        document.removeEventListener('mousedown', listener);
        // if ('ontouchstart' in document.documentElement) {
        //   document.removeEventListener('touchstart', listener);
        // } else {
        //   document.removeEventListener('mousedown', listener);
        // }
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  );
};

export const useOnClickOutsideWithCss = (
  cssSelector: any,
  handler: (e: EventType) => void,
) => {
  useEffect(() => {
    const listener = (event: EventType) => {
      const current = document.querySelector(cssSelector);
      // Do nothing if clicking element or descendent elements
      if (!current || current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);

    // if ('ontouchstart' in document.documentElement) {
    //   document.addEventListener('touchstart', listener);
    // } else {
    //   document.addEventListener('mousedown', listener);
    // }

    return () => {
      document.removeEventListener('mousedown', listener);
      // if ('ontouchstart' in document.documentElement) {
      //   document.removeEventListener('touchstart', listener);
      // } else {
      //   document.removeEventListener('mousedown', listener);
      // }
    };
  }, [cssSelector, handler]);
};

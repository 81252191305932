import { DeletedItem } from '@api/bin';

import { Board } from '../board';
import { File } from '../file';
import { Hub } from '../hub';

type Props = {
  items?: DeletedItem[];
};

export const Items = ({ items }: Props) => {
  return (
    <ul
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0px',
        padding: '0px',
        gap: '8px',
      }}
    >
      {items && items.length > 0 ? mapDeletedItems(items) : null}
    </ul>
  );
};

const mapDeletedItems = (items: DeletedItem[]) => {
  return items.map((item) => {
    switch (item.item_type) {
      case 'Hub':
        return <Hub item={item} />;
      case 'Board':
        return <Board item={item} isNestedItem={false} />;
      case 'Doc':
        return <File item={item} />;
      case 'Block':
        return <File item={item} />;
      default:
        throw new Error('There’s no element with the item_type field');
    }
  });
};

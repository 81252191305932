import { createEvent, createStore, sample } from 'effector';

import { PurchasePurpose } from '@api/billing';
import {
  $isHubRestoringDialog,
  hubRestorationCanceled,
  hubRestorationContinued,
} from '@src/entities/dialogs/hub/restoring/model';

import {
  BillingPlanSheetActions,
  Plan,
  UpgradePlanSheetActions,
} from '../components/types';

export type BillingFrequencyModalState = {
  mode: 'billing-frequency';
  isPlanModalOpen: boolean;
  planModalMode: BillingPlanSheetActions;
  planToChange: null;
};

export type HiddenModalState = {
  mode: 'hidden';
  isPlanModalOpen: boolean;
  planModalMode: null;
  planToChange: null;
};

export type UpgradeDowngradeModalState = {
  mode: 'upgrade-downgrade';
  isPlanModalOpen: boolean;
  planModalMode: UpgradePlanSheetActions;
  planToChange: Plan;
};

export type PlanModalState =
  | BillingFrequencyModalState
  | HiddenModalState
  | UpgradeDowngradeModalState;

export const openedPlanModal = createEvent<PlanModalState>();
export const closedPlanModal = createEvent();

export const changedAnnualBilling = createEvent();

export const $planModal = createStore<PlanModalState>({
  mode: 'hidden',
  isPlanModalOpen: false,
  planModalMode: null,
  planToChange: null,
})
  .on(openedPlanModal, (state, payload) => payload)
  .reset(closedPlanModal);

export const $isAnnualToggle = createStore(false).on(
  changedAnnualBilling,
  (state) => !state,
);

// Cancel Plan Modal
export const openedCancelPlanModal = createEvent();
export const closedCancelPlanModal = createEvent();

export const $isCancelPlanModalOpen = createStore(false)
  .on(openedCancelPlanModal, () => true)
  .reset(closedCancelPlanModal);

// Hub Credit Checkout
export const openedHubCreditBundleFromInfo = createEvent<CreditSheetData>();

type CreditSheetData = {
  bundleId: keyof typeof CREDIT_BUNDLES;
  redirectURL?: string;
  purchaseIdentifier?: 'hub-credits';
  purchasePurpose?: PurchasePurpose;
  creditShortfallPurchaseAmount?: {
    // Object to be used for the credit shortfall purchase (currently only happens when creating a new hub)
    deficit: number;
    cost: number;
    totalAmount: number;
    hubName?: string;
    id?: string;
  };
};

export const openedHubCreditSheet = createEvent<CreditSheetData | null>();
export const closedHubCreditSheet = createEvent();

// export const $hubCreditSheetRedirect = createStore<string | null>(null)

export const $isHubCreditSheetOpen = createStore(false);

sample({
  clock: [
    openedHubCreditSheet,
    openedHubCreditBundleFromInfo,
    hubRestorationContinued,
  ],
  source: { isHubRestoringDialog: $isHubRestoringDialog },
  filter: ({ isHubRestoringDialog }) => !isHubRestoringDialog,
  fn: () => true,
  target: $isHubCreditSheetOpen,
});

sample({
  clock: closedHubCreditSheet,
  fn: () => false,
  target: $isHubCreditSheetOpen,
});

export const changedHubBundleCreditId = createEvent<CreditSheetData>();

export const $selectedHubCreditBundleId = createStore<CreditSheetData | null>(
  null,
)
  .on(openedHubCreditSheet, (_, payload) => payload)
  .on(openedHubCreditBundleFromInfo, (_, payload) => payload)
  .on(changedHubBundleCreditId, (_, payload) => payload)
  .reset([closedHubCreditSheet, hubRestorationCanceled]);

export const openedHubInfoSheet = createEvent();
export const closedHubInfoSheet = createEvent();

export const $isHubInfoSheetOpen = createStore<boolean>(false)
  .on(openedHubInfoSheet, (state) => !state)
  .reset(openedHubCreditBundleFromInfo)
  .reset(closedHubInfoSheet);

// Trial Checkout
export const openedTrialSheet = createEvent();
export const closedTrialSheet = createEvent();

export const $isTrialSheetOpen = createStore(false)
  .on(openedTrialSheet, (state) => !state)
  .reset(closedTrialSheet);

export type CreditBundles = typeof CREDIT_BUNDLES;
export type CreditBundle = keyof CreditBundles;

export const CREDIT_BUNDLES = {
  credit_pack_1: {
    amountPerCredit: 0,
    originalTotal: 0,
    discountedTotal: 0,
    discount: 0,
    numberOfCredits: 0,
  },
  credit_pack_10: {
    amountPerCredit: 0,
    originalTotal: 0,
    discountedTotal: 0,
    discount: 0,
    numberOfCredits: 0,
  },
  credit_pack_100: {
    amountPerCredit: 11,
    originalTotal: 120,
    discountedTotal: 110,
    discount: 8,
    numberOfCredits: 100,
  },
  credit_pack_250: {
    amountPerCredit: 10,
    originalTotal: 300,
    discountedTotal: 250,
    discount: 17,
    numberOfCredits: 250,
  },
  credit_pack_500: {
    amountPerCredit: 8,
    originalTotal: 600,
    discountedTotal: 400,
    discount: 33,
    numberOfCredits: 500,
  },
};

import styles from './styles.module.css';

export const HubIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="none">
      {/* Top Left */}
      <g className={styles.box}>
        <rect
          width="23.3333"
          height="26.6667"
          x="15.384"
          y="11.5117"
          fill="#DCD5CB"
          rx=".666667"
        />
        <rect
          width="26.6667"
          height="26.6667"
          x="12.0508"
          y="11.5117"
          fill="#DCD5CB"
          opacity=".5"
          rx=".666667"
        />
        <rect
          width="26.6667"
          height="26.6667"
          x="12.0508"
          y="11.4822"
          stroke="#43424A"
          strokeWidth=".666667"
          rx=".666667"
        />
        <path
          fill="#E2C9EB"
          d="M13.5676 36.6135L21.1155 24.8579L13.595 12.9668L25.4464 20.8423L35.0326 14.8092L28.9633 24.8579L35.3835 34.8092L25.4464 28.2978L13.5676 36.6135Z"
          opacity=".5"
        />
        <path
          fill="#E2C9EB"
          d="M19.5378 32.7656L24.5714 25.2099L21.5115 18.3392L25.3056 20.826L37.1183 13.0229L29.6913 24.8215L37.3062 36.6671L25.3056 28.817L19.5378 32.7656Z"
        />
        <path
          stroke="#43424A"
          strokeWidth=".666667"
          d="M13.3855 36.8152L21.179 24.8737L13.3855 12.8152L25.3934 20.8652L37.3855 12.8152L29.6812 24.8737L37.3855 36.8152L25.3934 28.821L13.3855 36.8152Z"
        />
      </g>
      {/* Top Right */}
      <g className={styles.box}>
        <path
          fill="#FFE2B5"
          d="M45.2603 12.1488C45.2603 11.7807 45.5587 11.4822 45.9269 11.4822H67.9269C68.2951 11.4822 68.5936 11.7807 68.5936 12.1488V37.4822C68.5936 37.8504 68.2951 38.1488 67.9269 38.1488H45.9269C45.5587 38.1488 45.2603 37.8504 45.2603 37.4822V12.1488Z"
        />
        <path
          fill="#FFE2B5"
          d="M41.9268 12.1488C41.9268 11.7807 42.2252 11.4822 42.5934 11.4822H67.9268C68.2949 11.4822 68.5934 11.7807 68.5934 12.1488V37.4822C68.5934 37.8504 68.2949 38.1488 67.9268 38.1488H42.5934C42.2252 38.1488 41.9268 37.8504 41.9268 37.4822V12.1488Z"
          opacity=".5"
        />
        <rect
          width="26.6667"
          height="26.6667"
          x="41.9268"
          y="11.4822"
          stroke="#43424A"
          strokeWidth=".666667"
          rx=".666667"
        />
      </g>
      {/* Bottom Left */}
      <g className={styles.box}>
        <path
          fill="#B3D4C1"
          d="M12.0508 42.2184C12.0508 41.8502 12.3493 41.5518 12.7174 41.5518H38.0508C38.419 41.5518 38.7174 41.8502 38.7174 42.2184V67.5518C38.7174 67.9199 38.419 68.2184 38.0508 68.2184H12.7174C12.3493 68.2184 12.0508 67.9199 12.0508 67.5518V42.2184Z"
          opacity=".5"
        />
        <path
          fill="#B3D4C1"
          d="M15.384 42.2184C15.384 41.8502 15.6825 41.5518 16.0507 41.5518H38.0507C38.4189 41.5518 38.7174 41.8502 38.7174 42.2184V67.5518C38.7174 67.9199 38.4189 68.2184 38.0507 68.2184H16.0507C15.6825 68.2184 15.384 67.9199 15.384 67.5518V42.2184Z"
        />
        <rect
          width="26.6667"
          height="26.6667"
          x="12.0508"
          y="41.5518"
          stroke="#43424A"
          strokeWidth=".666667"
          rx=".666667"
        />
      </g>
      {/* Bottom Right */}
      <g className={styles.box}>
        <rect
          width="26.6667"
          height="26.6667"
          x="41.9268"
          y="41.5518"
          fill="#DCD5CB"
          opacity=".5"
          rx=".666667"
        />
        <rect
          width="23.3333"
          height="26.6667"
          x="45.2603"
          y="41.5518"
          fill="#DCD5CB"
          rx=".666667"
        />
        <rect
          width="26.6667"
          height="26.6667"
          x="41.9268"
          y="41.5518"
          stroke="#43424A"
          strokeWidth=".666667"
          rx=".666667"
        />
        <path
          fill="#DCD5CB"
          d="M49.509 64.5018L45.7068 56.408C45.4991 55.9659 45.8218 55.4579 46.3102 55.4579H46.4034V52.2504C46.4034 51.8822 46.7019 51.5837 47.07 51.5837H47.8857C48.2515 51.5837 48.549 51.8785 48.5523 52.2442L48.582 55.4579H50.3772C50.6145 55.4579 50.834 55.584 50.9534 55.7891L51.282 56.3535C51.4014 56.5585 51.6208 56.6847 51.8581 56.6847H61.9034V51.5837H63.609C63.9772 51.5837 64.2757 51.8822 64.2757 52.2504V56.6847C64.7221 56.6847 65.0079 57.1601 64.7984 57.5544L61.0914 64.5312C60.9757 64.7489 60.7492 64.885 60.5027 64.885H50.1124C49.854 64.885 49.6189 64.7357 49.509 64.5018Z"
          opacity=".5"
        />
        <path
          fill="#DCD5CB"
          d="M50.4262 64.488L46.8469 56.3942C46.652 55.9535 46.9747 55.4579 47.4566 55.4579H47.5087V52.0964C47.5087 51.8133 47.7383 51.5837 48.0215 51.5837C48.3046 51.5837 48.5342 51.8133 48.5342 52.0964V55.4579H50.2601C50.4746 55.4579 50.676 55.5611 50.8013 55.7353L51.2848 56.4073C51.41 56.5815 51.6114 56.6847 51.826 56.6847H62.1001V51.5837H63.6667C64.0349 51.5837 64.3334 51.8822 64.3334 52.2504V56.6847C64.7593 56.6847 65.0362 57.1329 64.8457 57.5138L61.343 64.5166C61.2301 64.7424 60.9993 64.885 60.7468 64.885H51.0359C50.772 64.885 50.5329 64.7293 50.4262 64.488Z"
        />
        <path
          fill="#F3F1EE"
          d="M49.509 64.5018L45.7068 56.408C45.4991 55.9659 45.8218 55.4579 46.3102 55.4579H46.4034V52.2504C46.4034 51.8822 46.7019 51.5837 47.07 51.5837H47.8857C48.2515 51.5837 48.549 51.8785 48.5523 52.2442L48.582 55.4579V45.5517C48.582 45.1835 48.8804 44.885 49.2486 44.885H61.2367C61.6049 44.885 61.9034 45.1835 61.9034 45.5517V51.5837H63.609C63.9772 51.5837 64.2757 51.8822 64.2757 52.2504V56.6847C64.7221 56.6847 65.0079 57.1601 64.7984 57.5544L61.0914 64.5312C60.9757 64.7489 60.7492 64.885 60.5027 64.885H50.1124C49.854 64.885 49.6189 64.7357 49.509 64.5018Z"
          opacity=".5"
        />
        <path
          fill="#F3F1EE"
          d="M52.3179 56.3342L52.0347 55.8084C51.9184 55.5925 51.693 55.4579 51.4478 55.4579H49.8304V45.5517C49.8304 45.1835 50.1289 44.885 50.4971 44.885H61.2255C61.5936 44.885 61.8921 45.1835 61.8921 45.5517V51.5837V56.6847H52.9049C52.6596 56.6847 52.4342 56.5501 52.3179 56.3342Z"
        />
        <path
          stroke="#43424A"
          strokeLinecap="round"
          strokeWidth=".666667"
          d="M46.4034 55.4579H46.3102C45.8218 55.4579 45.4991 55.9659 45.7068 56.408L49.509 64.5018C49.6189 64.7357 49.854 64.885 50.1124 64.885H60.5027C60.7492 64.885 60.9757 64.7489 61.0914 64.5312L64.7984 57.5544C65.0079 57.1601 64.7221 56.6847 64.2757 56.6847V56.6847M46.4034 55.4579V52.2504C46.4034 51.8822 46.7019 51.5837 47.07 51.5837H47.8857C48.2515 51.5837 48.549 51.8785 48.5523 52.2442L48.582 55.4579M46.4034 55.4579H48.582M48.582 55.4579H50.3772C50.6145 55.4579 50.834 55.584 50.9534 55.7891L51.282 56.3535C51.4014 56.5585 51.6208 56.6847 51.8581 56.6847H64.2757M48.582 55.4579V45.5517C48.582 45.1835 48.8804 44.885 49.2486 44.885H61.2367C61.6049 44.885 61.9034 45.1835 61.9034 45.5517V51.5837M61.9034 51.5837H63.609C63.9772 51.5837 64.2757 51.8822 64.2757 52.2504V56.6847M61.9034 51.5837V56.6847"
        />
        <path
          fill="#E2C9EB"
          stroke="#43424A"
          strokeWidth=".666667"
          d="M59.6031 46.1707L59.499 47.7792L58.6711 48.0716L59.0175 48.1581V48.6971C59.0175 49.1426 58.6564 49.5039 58.2108 49.5039L56.2698 49.504L56.3242 47.9837L57.1554 48.0716L56.8603 47.6908V46.9775L57.1554 46.4767L57.733 46.1707H59.6031Z"
        />
        <path
          fill="#B3D4C1"
          fillRule="evenodd"
          stroke="#43424A"
          strokeWidth=".666667"
          d="M52.6718 51.314C52.1178 51.3405 51.6635 51.3623 51.6625 51.3624C51.6614 51.3626 51.654 51.443 51.6461 51.5413C51.6333 51.6997 51.6325 51.7249 51.6391 51.7649C51.6501 51.8314 51.672 51.8897 51.7079 51.9477C51.7385 51.9971 51.7392 51.9993 51.7272 52.0043C51.7203 52.0072 51.6379 52.0388 51.5441 52.0745C51.4503 52.1103 51.3713 52.1415 51.3684 52.1439C51.3656 52.1463 51.3304 52.3008 51.2902 52.4873L51.2172 52.8264L51.2615 52.9438L51.3058 53.0613L51.0801 53.2407L50.8543 53.4201L50.8286 53.6909L50.8029 53.9617L50.9878 54.1359C51.0895 54.2318 51.1766 54.3114 51.1814 54.3129C51.1862 54.3144 51.772 54.3793 52.4832 54.4571L53.7763 54.5985L53.9456 54.4438L54.1148 54.2891L54.1338 54.0882L54.1528 53.8873L53.9857 53.6749C53.8938 53.5581 53.8177 53.4594 53.8166 53.4556C53.8155 53.4519 53.8446 53.3904 53.8812 53.319L53.9477 53.1892L53.9527 52.7186C53.9555 52.4597 53.9567 52.2458 53.9555 52.2433C53.9542 52.2408 53.8691 52.1975 53.7663 52.1472C53.6636 52.0968 53.5809 52.0531 53.5826 52.0499C53.5843 52.0468 53.5964 52.0224 53.6096 51.9956C53.6537 51.9062 53.6563 51.8916 53.6875 51.562L53.7155 51.2658L53.6974 51.2658C53.6874 51.2659 53.2259 51.2876 52.6718 51.314Z"
          clipRule="evenodd"
        />
        <rect
          width="2.66667"
          height="2.66667"
          x="51.4007"
          y="46.504"
          fill="#FFE2B5"
          stroke="#43424A"
          strokeWidth=".666667"
          rx=".333333"
        />
        <rect
          width="2.66667"
          height="2.66667"
          x="56.6031"
          y="51.4989"
          fill="#FFE2B5"
          stroke="#43424A"
          strokeWidth=".666667"
          rx=".333333"
        />
      </g>
    </svg>
  );
};

import { useUnit } from 'effector-react';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  $defaultColor,
  $isShowSnackbar,
  $previousColor,
} from '@pages/HubPage/components/Header/model';
import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  CoverResponse,
  ThumbnailBrightness,
  uploadImage,
} from '../../../../../api/hubs';
import {
  ALL_HUBS_QUERY,
  HUB_QUERY,
} from '../../../../../shared/constants/query-names';

interface Cover {
  thumbnail?: FileList | string;
  thumbnail_hex?: string;
  hubId: string;
  thumbnailBrightness: ThumbnailBrightness;
}

export const useAddCover = (
  options?: MutateOptions<CoverResponse, any, Cover>,
) => {
  const previousColor = useUnit($previousColor);
  const defaultColor = useUnit($defaultColor);
  const isShowSnackbar = useUnit($isShowSnackbar);

  const queryClient = useQueryClient();

  const { mutate: addCover } = useMutation({
    mutationFn: ({ hubId, thumbnail, thumbnail_hex, thumbnailBrightness }) => {
      const formData = new FormData();

      if (thumbnail_hex && thumbnailBrightness) {
        formData.append('thumbnail_hex', thumbnail_hex);
        formData.append('thumbnail_brightness', thumbnailBrightness);
      } else if (thumbnail && thumbnailBrightness) {
        formData.append('thumbnail', thumbnail[0]);
        formData.append('thumbnail_brightness', thumbnailBrightness);
      }

      return uploadImage({
        hubId,
        thumbnail: formData,
      });
    },
    ...(options ?? {}),
    onMutate: () => {
      startedSnack({
        label: 'Updating hub cover...',
        close: true,
      });
    },
    onSuccess: (
      data,
      { hubId, thumbnail, thumbnail_hex, thumbnailBrightness },
      context,
    ) => {
      const label =
        previousColor === defaultColor || isShowSnackbar
          ? 'Added cover'
          : 'Changed cover';

      queryClient.invalidateQueries({
        queryKey: [ALL_HUBS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [HUB_QUERY, { hubId }],
      });

      startedSnack({
        label,
        close: true,
      });

      if (options?.onSuccess)
        options?.onSuccess(
          data,
          { hubId, thumbnail, thumbnail_hex, thumbnailBrightness },
          context,
        );
    },
    onError: (
      error,
      { hubId, thumbnail, thumbnail_hex, thumbnailBrightness },
      context,
    ) => {
      const MAX_SIZE = 15 * 1024 * 1024;

      if (
        thumbnail &&
        thumbnail[0] instanceof File &&
        thumbnail[0].size >= MAX_SIZE
      ) {
        startedSnack({
          label: 'Image is too large. Use an image under 15 MB.',
          close: true,
        });

        return;
      }

      const label =
        previousColor === defaultColor
          ? "Couldn't add cover"
          : "Couldn't change cover";

      startedSnack({
        label,
        action: {
          label: 'Try again',
          action: () => {
            addCover({
              hubId,
              thumbnail: thumbnail ? thumbnail : '',
              thumbnail_hex: thumbnail_hex ? thumbnail_hex : '',
              thumbnailBrightness,
            });
          },
        },
        close: true,
      });

      if (options?.onError)
        options?.onError(
          error,
          { hubId, thumbnail, thumbnail_hex, thumbnailBrightness },
          context,
        );
    },
  });

  return { addCover };
};

import { useUnit } from 'effector-react';

import { useShortcut } from '@visualist/hooks/src/useKeyboardShortcutsV2';

import { $areShortcutsDisabled } from '../model';

/**
 * A wrapper around useShortcut that respects the global shortcut disabled state.
 * This hook will prevent shortcuts from triggering when:
 * 1. The user is editing text
 * 2. A modal is open
 * 3. Any other case where shortcuts should be disabled
 *
 * @param shortcut - The keyboard shortcut string
 * @param callback - Function to execute when shortcut is triggered
 * @param disableTextInputs - If true, shortcut will not trigger inside text inputs (default: true)
 * @param forcedEnable - If true, the shortcut will work even when shortcuts are globally disabled
 */
export const useStudioShortcut = (
  shortcut: string,
  callback: (event: KeyboardEvent) => void,
  disableTextInputs: boolean = true,
  forcedEnable: boolean = false,
) => {
  const areShortcutsDisabled = useUnit($areShortcutsDisabled);

  useShortcut(
    shortcut,
    (event: KeyboardEvent) => {
      // Skip the callback if shortcuts are disabled (unless forcedEnable is true)
      if (!forcedEnable && areShortcutsDisabled) {
        return;
      }

      callback(event);
    },
    { disableTextInputs },
  );
};

import { api } from '../services';

type GoogleLoginResponse = {
  access: string;
  refresh: string;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    photo: Record<string, unknown>;
    creative_field: string | null;
    creative_field_other: string | null;
    active_since: string | null;
    location: string | null;
    team_size: string | null;
    project_number: string | null;
    average_fee: string | null;
    use_case: string | null;
    acquisition_channel: string | null;
    acquisition_channel_other: string | null;
    hub: string | null;
    referral_code: string | null;
    onboard_complete: boolean;
    meta: {
      onboarding: {
        demoMode: boolean;
        createHubs: string;
        selectedPath: string;
        completedPaths: {
          createHubs: boolean;
          workCreatively: boolean;
          manageBusinessAdmin: boolean;
        };
        workCreatively: string;
        manageBusinessAdmin: string;
      };
    };
    last_login: string;
    is_google_linked: boolean;
    valid_password: boolean;
  };
};

/**
 *
 * @param {*} accesstoken This is the accesstoken of the user obtained from Google
 */

type Props = {
  access_token: string;
  invitation_code?: string | string[] | null;
  promo_code?: string | string[] | null;
};
const googleLogin = async ({
  access_token,
  invitation_code,
  promo_code,
}: Props): Promise<GoogleLoginResponse> => {
  const { data } = await api.post('/auth/google/connect/', {
    access_token,
    invitation_code,
    promo_code,
  });
  return data;
};

export default googleLogin;

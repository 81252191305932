import { ImageJSON } from '@api/designs';

import { ImageFile } from '../imageFile';

export type Slot =
  | 'top-right'
  | 'top-center'
  | 'top-left'
  | 'center-right'
  | 'center-left'
  | 'bottom-right'
  | 'bottom-left'
  | 'bottom-center';

export const clusterLayout = ({
  heroImage,
  images,
  center,
  transparentImages,
}: // paletteImages,
{
  heroImage: ImageJSON;
  images: ImageJSON[];
  center: {
    x: number;
    y: number;
  };
  transparentImages: Set<string>;
  paletteImages: Set<string>;
}) => {
  const hero = new ImageFile(heroImage);
  hero.resetRotation().resizeImageAspect(4000).centerImageFromPoint({
    x: center.x,
    y: center.y,
  });

  // Slots to place images. 0 means empty, 1 means filled
  const imageSlots: Record<Slot, 0 | 1> = {
    'top-right': 0,
    'top-center': 0,
    'top-left': 0,
    'center-left': 0,
    'center-right': 0,
    'bottom-center': 0,
    'bottom-left': 0,
    'bottom-right': 0,
  } as const;

  // const transparentSlots: Record<Slot, 0 | 1> = {
  //   'top-right': 0,
  //   'top-center': 0,
  //   'top-left': 0,
  //   'center-left': 0,
  //   'center-right': 0,
  //   'bottom-center': 0,
  //   'bottom-left': 0,
  //   'bottom-right': 0,
  // } as const;

  // const imageSlotKeys = Object.keys(imageSlots);

  // Place remaining photos around main photo
  for (const image of images) {
    // Check if image is transparent
    const isTransparent = transparentImages.has(image.id);
    // Check if image is in palette
    // const isPalette = paletteImages.has(image.id);

    const remainingSlots = Object.entries(imageSlots)
      .filter(([, value]) => value === 0)
      .map(([key]) => {
        // TODO fix type cast
        return key as Slot;
      });

    // if (isPalette) {
    //   const chosenSlot =
    //     remainingSlots[Math.floor(Math.random() * remainingSlots.length)];
    //   imageSlots[chosenSlot] = 1;

    //   updateImagePosition(image, chosenSlot, {
    //     x: hero.x,
    //     y: hero.y,
    //     width: hero.metadata.width,
    //     height: hero.metadata.height,
    //   });
    // }

    const chosenSlot =
      remainingSlots[Math.floor(Math.random() * remainingSlots.length)];
    imageSlots[chosenSlot] = 1;

    moveImageToSlot(image, chosenSlot, hero.getImageDimensions());

    addSomePositionRandomness(image, 0.1, chosenSlot, isTransparent);

    adjustImageSize(image, hero.getRawImage(), isTransparent);

    addSomeRotationalRandomness(image, hero.getRawImage(), 0.1, isTransparent);
  }

  const clusteredImages = [hero.getRawImage(), ...images];

  clusteredImages.sort((a, b) => {
    // Sort layers here
    const isAHero = a.id === hero.getId();
    if (isAHero) return -1;

    // Transparent Images always go on top
    const isATransparent = transparentImages.has(a.id);
    const isBTransparent = transparentImages.has(b.id);

    if (isATransparent) return 1;
    if (isBTransparent) return -1;

    return 0;
  });

  return clusteredImages;
};

export const moveImageToSlot = (
  image: ImageJSON,
  slot: Slot,
  hero: { x: number; y: number; width: number; height: number },
) => {
  const {
    metadata: { height, width },
  } = image;

  const imageHeight = height;
  const imageWidth = width;

  // Note: Y position needs to be added when moving towards the bottom
  switch (slot) {
    case 'top-right':
      image.x = hero.x + (hero.width - imageWidth / 2);
      image.y = hero.y - imageHeight / 2;
      break;
    case 'top-center':
      image.x = hero.x + hero.width / 2 - imageWidth / 2;
      image.y = hero.y - imageHeight / 2;
      break;
    case 'top-left':
      image.x = hero.x - imageWidth / 2;
      image.y = hero.y - imageHeight / 2;
      break;
    case 'center-left':
      image.x = hero.x - imageWidth / 2;
      image.y = hero.y + hero.height / 2 - imageHeight / 2;
      break;
    case 'center-right':
      image.x = hero.x + hero.width - imageWidth / 2;
      image.y = hero.y + hero.height / 2 - imageHeight / 2;
      break;
    case 'bottom-center':
      image.x = hero.x + hero.width / 2 - imageWidth / 2;
      image.y = hero.y + hero.height - imageHeight / 2;
      break;
    case 'bottom-left':
      image.x = hero.x - imageWidth / 2;
      image.y = hero.y + hero.height - imageHeight / 2;
      break;
    case 'bottom-right':
      image.x = hero.x + hero.width - imageWidth / 2;
      image.y = hero.y + hero.height - imageHeight / 2;
      break;
  }
};

export const addSomePositionRandomness = (
  image: ImageJSON,
  chaos: number,
  chosenSlot: Slot,
  isTransparent: boolean,
) => {
  // All transparents images get randomness position, solid images may sometimes not get randomised
  const shouldRandomise = Math.random() > 0.3;
  if (!shouldRandomise && !isTransparent) return;

  const {
    metadata: { width, height },
  } = image;
  const shiftH = height * (0.1 + chaos);
  const shiftW = width * (0.1 + chaos);

  const shiftWToAdd = Math.random() > 0.5 ? +shiftW : -shiftW;
  const shiftHToAdd = Math.random() > 0.5 ? +shiftH : -shiftH;

  // How much to push transparent images to the center
  const TRANSPARENT_GRAVITY = 1.5;

  // Nudge images closer to the hero image or further. Transparent closer and solid blocks further
  switch (chosenSlot) {
    case 'bottom-center':
      image.x += shiftWToAdd;
      image.y += isTransparent ? -shiftH * TRANSPARENT_GRAVITY : shiftHToAdd;
      break;
    case 'bottom-left':
      image.x += isTransparent ? +shiftW * TRANSPARENT_GRAVITY : shiftWToAdd;
      image.y += isTransparent ? -shiftH * TRANSPARENT_GRAVITY : shiftHToAdd;
      break;
    case 'bottom-right':
      image.x += isTransparent ? -shiftW * TRANSPARENT_GRAVITY : shiftWToAdd;
      image.y += isTransparent ? -shiftH * TRANSPARENT_GRAVITY : shiftHToAdd;
      break;
    case 'center-left':
      image.x += isTransparent ? +shiftW * TRANSPARENT_GRAVITY : shiftWToAdd;
      image.y += shiftHToAdd;
      break;
    case 'center-right':
      image.x += isTransparent ? -shiftW * TRANSPARENT_GRAVITY : shiftWToAdd;
      image.y += shiftHToAdd;
      break;
    case 'top-center':
      image.x += shiftWToAdd;
      image.y += isTransparent ? +shiftH * TRANSPARENT_GRAVITY : shiftHToAdd;
      break;
    case 'top-left':
      image.x += isTransparent ? +shiftW * TRANSPARENT_GRAVITY : shiftWToAdd;
      image.y += isTransparent ? +shiftH * TRANSPARENT_GRAVITY : shiftHToAdd;
      break;
    case 'top-right':
      image.x += isTransparent ? -shiftW * TRANSPARENT_GRAVITY : shiftWToAdd;
      image.y += isTransparent ? +shiftH * TRANSPARENT_GRAVITY : shiftHToAdd;
      break;
  }
};

export const addSomeRotationalRandomness = (
  image: ImageJSON,
  hero: ImageJSON,
  chaos: number,
  isTransparent: boolean,
) => {
  // Do not rotate hero image
  if (image.id === hero.id) return;

  // Should we rotate this image? Transparents images are more likely to rotate vs solid images
  const shouldRotate = isTransparent
    ? Math.random() > 0.6
    : Math.random() > 0.3;

  if (!shouldRotate) return;

  const amountToRotate = Math.random() * 20;

  const shift = amountToRotate * (0.2 + chaos);

  image.rotation = Math.random() > 0.5 ? +shift : -shift;
};

export const adjustImageSize = (
  image: ImageJSON,
  hero: ImageJSON,
  isTransparent: boolean,
) => {
  const width = image.metadata.width;
  const height = image.metadata.height;
  const imageAspect = width / height;
  const heroHeight = hero.metadata.height;
  const heroWidth = hero.metadata.width;

  const oldHeight = image.metadata.height ?? 0;
  const oldWidth = image.metadata.width ?? 0;

  // Scale images down based on their larger side
  if (imageAspect > 1) {
    image.metadata.width = heroWidth * 0.5;
    image.metadata.height = image.metadata.width / imageAspect;
  } else {
    image.metadata.height = heroHeight * 0.5;
    image.metadata.width = image.metadata.height * imageAspect;
  }

  // Move positions back to their position
  image.x += (oldWidth - image.metadata.width) / 2;
  image.y += (oldHeight - image.metadata.height) / 2;

  if (Math.random() > 0.7) {
    const scaleFactor = isTransparent ? 1.6 : 1.4;
    image.metadata.width *= scaleFactor;
    image.metadata.height *= scaleFactor;

    image.x = image.x + (width * (1 - scaleFactor)) / 2;
    image.y = image.y + (height * (1 - scaleFactor)) / 2;
  }
};

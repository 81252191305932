import { createEffect, createEvent, createStore } from 'effector';

// Shift Key tracking Studio
export const setupStudioKeyListenerFx = createEffect<void, void, Error>(
  async () => {
    window.addEventListener('keydown', (e) => keyDown(e));
    window.addEventListener('keyup', (e) => keyUp(e));
  },
);

export const keyDown = createEvent<KeyboardEvent>();
export const keyUp = createEvent<KeyboardEvent>();

export const $isShiftPressed = createStore(false)
  .on(keyDown, (_, event) => event.key === 'Shift')
  .reset(keyUp);

setupStudioKeyListenerFx();

import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';
import { Vector2d } from 'konva/lib/types';

import {
  DesignJSONV2,
  DesignObject,
  ImageJSON,
  ShapeJSON,
  TextJSON,
} from '@api/designs';
import { StickyType } from '@src/entities/Stickies/types';

import { getImages, getTextBoxes } from '../api';
import { shapeType } from '../components/Shape';
import { getShapeWidthHeight, isArrowNode, isLineNode } from '../utils';

export const fetchImages = async (id: string) => {
  const imagesResponse = await getImages(id);

  return imagesResponse.data.results;
};

export const fetchTextboxes = async (id: string) => {
  const textResponse = await getTextBoxes(id);

  return textResponse.data.results;
};

export const isBlobOldShapeData = (
  blob: unknown,
): blob is {
  shapes: Array<{
    x: number;
    y: number;
    id: string;
    rotation: number;
    scale: {
      x: number;
      y: number;
    };
    metadata: {
      fill: string;
      radius: number;
      stroke: string;
      strokeWidth: number;
      type: shapeType;
    };
  }>;
} => {
  if (
    typeof blob === 'object' &&
    blob !== null &&
    'shapes' in blob &&
    Array.isArray((blob as any).shapes)
  ) {
    return true;
  }

  return false;
};

// Rotates a point (x, y) around the origin (0,0) by a given angle in radians.
// Uses standard 2D rotation matrix transformation.
export const rotatePoint = ({ x, y }: Vector2d, rad: number) => {
  const rcos = Math.cos(rad);
  const rsin = Math.sin(rad);
  return { x: x * rcos - y * rsin, y: y * rcos + x * rsin };
};

// Rotates around the centre of the object
// Because groups are squares in Konva
// we want to rotate them around the center not top left
export const rotateAroundCenter = (
  object: ImageJSON | TextJSON | ShapeJSON,
  amount: number,
): ImageJSON | TextJSON | ShapeJSON => {
  if (!object.metadata.width || !object.metadata.height) {
    console.error(
      `${object.id} has null width or hight. Shouldn't be possible`,
    );
    return object;
  }
  const topLeft = {
    x: -object.metadata.width / 2,
    y: -object.metadata.height / 2,
  };

  const currentRotation = Konva.getAngle(object.rotation);
  const newRotation = Konva.getAngle(object.rotation + amount);

  const current = rotatePoint(topLeft, currentRotation);
  const rotated = rotatePoint(topLeft, newRotation);

  const dx = rotated.x - current.x;
  const dy = rotated.y - current.y;

  const newObject = {
    ...object,
    x: object.x + dx,
    y: object.y + dy,
    rotation: object.rotation + amount,
  };

  return newObject;
};

/**
 * @description  Type guard to check if a design file is infinite
 * @param state
 * @returns
 */

export const isInfinite = (
  state: DesignJSONV2,
): state is DesignJSONV2 & { type: 'infinite' } => {
  return state.type === 'infinite';
};

/**
 * Returns stickies that are not in the design file
 */

export const getStickiesDiff = ({
  designState,
  stickiesData,
}: {
  designState: DesignJSONV2;
  stickiesData: Array<StickyType>;
}) => {
  // Stickies in the backend but not synched to the design file. To be added to design
  let unsyncedStickies: Set<string>;
  // Stickies removed from the backend but are present in the design file. To be deleted from design
  let deletedStickies: Set<string>;

  const allStickies = new Set(stickiesData.map((s) => s.id));
  if (isInfinite(designState)) {
    // Find unsynched
    const stickiesInDesign = new Set(designState.data.metadata.stickyIds);
    unsyncedStickies = difference(allStickies, stickiesInDesign);

    // Find deleted
    deletedStickies = difference(stickiesInDesign, allStickies);
  } else {
    // Find unsynched
    const stickiesInDesign = new Set(
      designState.data.flatMap((p) => p.metadata.stickyIds ?? []),
    );
    unsyncedStickies = difference(allStickies, stickiesInDesign);

    // Find deleted
    deletedStickies = difference(stickiesInDesign, allStickies);
  }

  return {
    unsyncedStickies,
    deletedStickies,
  };
};

/**
 * Returns the items in set a that are not in set b
 * @param a
 * @param b
 * @returns
 */

const difference = <T extends string>(a: Set<T>, b: Set<T>) => {
  const difference = new Set<T>();
  for (const item of a) {
    if (!b.has(item)) {
      difference.add(item);
    }
  }
  return difference;
};

export const updateElementsPosition = ({
  data,
  containerBounds,
  stage,
}: {
  data: DesignObject[];
  containerBounds: {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
  };
  stage: Stage | null;
}) => {
  if (data.length === 0)
    return {
      objects: data,
      bounds: null,
    };

  const MIN_WIDTH = 100; // Minimum width threshold
  const MIN_HEIGHT = 100; // Minimum height threshold
  const MIN_FONT_SIZE = 20; // Minimum font threshold
  //Get bounding box of all elements
  let minX = Infinity,
    minY = Infinity,
    maxX = -Infinity,
    maxY = -Infinity;

  const elementsWithSize = data.map((elementObject) => {
    let width = 0,
      height = 0;

    if (elementObject.type === 'image' || elementObject.type === 'text') {
      width = elementObject.metadata.width;
      height = elementObject.metadata.height;

      // Update function level bounding box
      minX = Math.min(minX, elementObject.x);
      minY = Math.min(minY, elementObject.y);
      maxX = Math.max(maxX, elementObject.x + width);
      maxY = Math.max(maxY, elementObject.y + height);
    } else if (elementObject.type === 'shape') {
      const shapeSize = getShapeWidthHeight({ shape: elementObject, stage });
      width = shapeSize.width;
      height = shapeSize.height;
      if (
        elementObject.metadata.type === 'line' ||
        elementObject.metadata.type === 'arrow'
      ) {
        if (stage) {
          const node = stage.findOne(`#${elementObject.id}`);
          if (node && (isLineNode(node) || isArrowNode(node))) {
            const x1 = node.x();
            const y1 = node.y();
            const x2 = node.x() + node.points()[2];
            const y2 = node.y() + node.points()[3];
            // Update function level bounding box
            minX = Math.min(minX, x1, x2);
            minY = Math.min(minY, y1, y2);
            maxX = Math.max(maxX, x1, x2);
            maxY = Math.max(maxY, y1, y2);
          }
        }
      } else {
        // Update function level bounding box
        minX = Math.min(minX, elementObject.x);
        minY = Math.min(minY, elementObject.y);
        maxX = Math.max(maxX, elementObject.x + width);
        maxY = Math.max(maxY, elementObject.y + height);
      }
    }

    return { ...elementObject, width, height };
  });

  // Compute scale factor to fit within page bounds
  const elementWidth = maxX - minX;
  const elementHeight = maxY - minY;
  const containerWidth = containerBounds.maxX - containerBounds.minX;
  const containerHeight = containerBounds.maxY - containerBounds.minY;

  // Define Safe Space Based on Page Size
  const safeMargin = Math.min(containerWidth, containerHeight) * 0.07; // 7% margin
  const safeMinX = containerBounds.minX + safeMargin;
  const safeMinY = containerBounds.minY + safeMargin;
  const safeMaxX = containerBounds.maxX - safeMargin;
  const safeMaxY = containerBounds.maxY - safeMargin;

  // Compute available space after applying safe margin
  const availableWidth = safeMaxX - safeMinX;
  const availableHeight = safeMaxY - safeMinY;

  // Compute scale factor considering safe margins
  let scaleFactor = Math.min(
    availableWidth / elementWidth,
    availableHeight / elementHeight,
    1,
  );
  // Ensure scale factor does not reduce elements below their minimum size
  const minScaleFactor = Math.min(
    MIN_WIDTH / elementWidth,
    MIN_HEIGHT / elementHeight,
    1,
  );
  scaleFactor = Math.max(scaleFactor, minScaleFactor);

  // Compute new centered position within safe area
  const offsetX = safeMinX + (availableWidth - elementWidth * scaleFactor) / 2;
  const offsetY =
    safeMinY + (availableHeight - elementHeight * scaleFactor) / 2;

  // Apply scaling and centering with safe margins
  const objects = elementsWithSize.map((elementObject) => {
    const scaledX = (elementObject.x - minX) * scaleFactor + offsetX;
    const scaledY = (elementObject.y - minY) * scaleFactor + offsetY;
    const scaledWidth = Math.max(elementObject.width * scaleFactor, MIN_WIDTH);
    const scaledHeight = Math.max(
      elementObject.height * scaleFactor,
      MIN_HEIGHT,
    );

    if (elementObject.type === 'shape') {
      switch (elementObject.metadata.type) {
        case 'rectangle':
          return {
            ...elementObject,
            x: scaledX,
            y: scaledY,
            metadata: {
              ...elementObject.metadata,
              width: scaledWidth,
              height: scaledHeight,
            },
          };

        case 'circle':
        case 'hexagon':
        case 'wedge':
          return {
            ...elementObject,
            x: scaledX,
            y: scaledY,
            metadata: {
              ...elementObject.metadata,
              radius: Math.max(scaledWidth, scaledHeight) / 2,
            },
          };

        case 'ellipse':
          return {
            ...elementObject,
            x: scaledX,
            y: scaledY,
            metadata: {
              ...elementObject.metadata,
              radiusX: scaledWidth / 2,
              radiusY: scaledHeight / 2,
            },
          };

        case 'star':
        case 'ring':
        case 'arc':
          return {
            ...elementObject,
            x: scaledX,
            y: scaledY,
            metadata: {
              ...elementObject.metadata,
              outerRadius: Math.max(scaledWidth, scaledHeight) / 2,
              innerRadius: (Math.max(scaledWidth, scaledHeight) / 2) * 0.5,
            },
          };

        case 'arrow':
        case 'line':
          if (stage) {
            const node = stage.findOne(`#${elementObject.id}`);
            if (node && elementObject.metadata.points) {
              const originalPoints = elementObject.metadata.points;
              const scaledPoints = [
                0,
                0,
                originalPoints[2] * scaleFactor,
                originalPoints[3] * scaleFactor,
              ];
              return {
                ...elementObject,
                x: scaledX,
                y: scaledY,
                metadata: {
                  ...elementObject.metadata,
                  points: scaledPoints,
                },
              };
            }
          }
          break;

        default:
          console.warn(`Unknown shape type: ${elementObject.metadata.type}`);
          return {
            ...elementObject,
            x: scaledX,
            y: scaledY,
            metadata: {
              ...elementObject.metadata,
              width: scaledWidth,
              height: scaledHeight,
            },
          };
      }
    } else if (elementObject.type === 'text') {
      return {
        ...elementObject,
        x: scaledX,
        y: scaledY,
        metadata: {
          ...elementObject.metadata,
          fontSize: Math.max(
            elementObject.metadata.fontSize * scaleFactor,
            MIN_FONT_SIZE,
          ),
        },
      };
    }

    return {
      ...elementObject,
      x: scaledX,
      y: scaledY,
      metadata: {
        ...elementObject.metadata,
        width: scaledWidth,
        height: scaledHeight,
      },
    };
  }) as DesignObject[];

  return {
    objects,
    bounds: {
      minX,
      maxX,
      minY,
      maxY,
    },
  };
};

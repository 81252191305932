import { ReactNode } from 'react';

import { Dropdown } from '@visualist/design-system/src/components/v2/Dropdown';

import styles from './styles.module.css';

export interface ContextMenuItem {
  key: string;
  content: ReactNode;
  leadingIcon?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
}

export interface ContextMenuGroups {
  pageOptions: ContextMenuItem[];
  editOptions: ContextMenuItem[];
  dangerOptions: ContextMenuItem[];
}

interface ContextMenuProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  menuItems: ContextMenuGroups;
  side?: 'top' | 'right' | 'bottom' | 'left';
  sideOffset?: number;
  alignOffset?: number;
  align?: 'start' | 'end' | 'center';
}

export const ContextMenu = ({
  isOpen,
  onOpenChange,
  menuItems,
  side = 'top',
  sideOffset = 12,
  alignOffset = 0,
  align,
}: ContextMenuProps) => {
  return (
    <Dropdown open={isOpen} onOpenChange={onOpenChange}>
      <Dropdown.Menu
        trigger={<div />}
        side={side}
        density="-2"
        sideOffset={sideOffset}
        alignOffset={alignOffset}
        align={align}
      >
        {/* Page options group */}
        {menuItems.pageOptions.map((item) => (
          <Dropdown.MenuItem
            key={item.key}
            item={{
              content: item.content,
              leadingIcon: item.leadingIcon,
              onClick: item.onClick,
              isDisabled: item.disabled,
            }}
          />
        ))}

        <hr className={styles.menuDivider} />

        {menuItems.editOptions.map((item) => (
          <Dropdown.MenuItem
            key={item.key}
            item={{
              content: item.content,
              leadingIcon: item.leadingIcon,
              onClick: item.onClick,
              isDisabled: item.disabled,
            }}
          />
        ))}

        <hr className={styles.menuDivider} />

        {menuItems.dangerOptions.map((item) => (
          <Dropdown.MenuItem
            style={{ color: 'var(--color-error)' }}
            key={item.key}
            item={{
              content: item.content,
              leadingIcon: item.leadingIcon,
              onClick: item.onClick,
              isDisabled: item.disabled,
            }}
          />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

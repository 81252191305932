import styles from './styles.module.css';

export const PaintBrushIcon = () => {
  return (
    <svg
      className={styles.paint}
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
    >
      {/* Handle */}
      <g className={styles.brush}>
        {/* Top Handle */}
        <path
          fill="#8A6F51"
          d="M39.3601 49.4993L35.002 47.0483L54.046 14.9169C55.634 12.2375 58.7957 6.90303 60.9033 8.08835C63.1537 9.35404 60.0258 14.6315 58.404 17.3679L39.3601 49.4993Z"
          opacity=".5"
        />
        <path
          stroke="#43424A"
          strokeWidth=".67"
          d="M39.3601 49.4997L35.002 47.0487L54.046 14.9172C55.634 12.2379 58.7957 6.9034 60.9033 8.08872C63.1537 9.3544 60.0258 14.6319 58.404 17.3682L39.3601 49.4997Z"
        />

        {/* Bottom handle */}
        <path
          fill="#DCD5CB"
          d="M0 0H5V5H0z"
          transform="matrix(.87161 .4902 -.50986 .86025 34.15 48.48)"
        />
        <path
          stroke="#43424A"
          strokeWidth=".67"
          d="M0 0H5V5H0z"
          transform="matrix(.87161 .4902 -.50986 .86025 34.15 48.48)"
        />

        {/* Paint Head */}
        <path
          fill="#E2C9EB"
          d="M22.881 58.744C26.0844 53.3392 30.8234 54.2383 32.5129 55.2664C37.465 58.0515 36.6487 61.6589 34.5025 65.28C34.2395 65.7238 33.8988 66.1898 33.4956 66.6615C30.6083 70.0383 24.5096 73.703 20.6815 71.55C24.2859 69.1473 23.3858 66.1354 22.9651 64.9378C22.6274 63.9762 22.4119 63.0863 22.3234 62.2582C22.183 60.9443 22.3625 59.786 22.881 58.744Z"
        />
        <path
          fill="#8A6F51"
          fillRule="evenodd"
          d="M32.5129 55.2664C30.8234 54.2383 26.0844 53.3392 22.881 58.744C22.3625 59.786 22.183 60.9443 22.3234 62.2582C23.9827 61.33 27.4969 60.4576 28.2793 64.393C29.0618 68.3284 32.0828 67.5451 33.4956 66.6615C33.8988 66.1898 34.2395 65.7238 34.5025 65.28C36.6487 61.6589 37.465 58.0515 32.5129 55.2664Z"
          clipRule="evenodd"
        />
        <path
          stroke="#43424A"
          strokeWidth=".67"
          d="M22.881 58.744C26.0844 53.3392 30.8234 54.2383 32.5129 55.2664C37.465 58.0515 36.6487 61.6589 34.5025 65.28C32.3563 68.9011 25.0443 74.0037 20.6815 71.55C24.2859 69.1473 23.3858 66.1354 22.9651 64.9378C22.0915 62.4505 22.0357 60.4427 22.881 58.744Z"
        />
        <path
          stroke="#43424A"
          strokeWidth=".67"
          d="M22.22 62.3141C23.7683 61.4294 27.0787 60.3825 27.9337 63.2723C29.0024 66.8847 30.0345 67.9536 33.2044 66.9952"
        />
      </g>

      {/* Stars */}
      <path
        className={styles.star1}
        d="M43.1795 66.0851L42.6526 64.2338L40.8277 63.7359L42.6679 63.1903L43.1834 61.3776L43.7038 63.1826L45.5378 63.7372L43.6961 64.2185L43.1795 66.0851Z"
        fill="#DCD5CB"
        stroke="#43424A"
        strokeWidth="0.666667"
      />
      <path
        className={styles.star2}
        d="M59.6105 30.4748L58.8201 27.6979L56.0828 26.951L58.843 26.1326L59.6163 23.4136L60.3968 26.1211L63.148 26.953L60.3853 27.6749L59.6105 30.4748Z"
        fill="#DCD5CB"
        stroke="#43424A"
        strokeWidth="0.666667"
      />
      <path
        className={styles.star3}
        d="M19.8592 49.3991L19.3323 47.5478L17.5074 47.0499L19.3476 46.5043L19.8631 44.6916L20.3834 46.4966L22.2175 47.0512L20.3758 47.5325L19.8592 49.3991Z"
        fill="#DCD5CB"
        stroke="#43424A"
        strokeWidth="0.666667"
      />
      <path
        className={styles.star4}
        d="M60.787 58.4058L59.7332 54.7032L56.0834 53.7074L59.7637 52.6162L60.7949 48.9908L61.8355 52.6009L65.5037 53.7101L61.8202 54.6726L60.787 58.4058Z"
        fill="#DCD5CB"
        stroke="#43424A"
        strokeWidth="0.666667"
      />
      <path
        className={styles.star5}
        d="M22.9875 21.5658L21.9336 17.8632L18.2838 16.8675L21.9642 15.7762L22.9953 12.1508L24.0359 15.7609L27.7041 16.8701L24.0206 17.8327L22.9875 21.5658Z"
        fill="#DCD5CB"
        stroke="#43424A"
        strokeWidth="0.666667"
      />
      <path
        className={styles.star1}
        d="M33.3473 36.5954L32.5569 33.8185L29.8196 33.0717L32.5798 32.2532L33.3532 29.5342L34.1336 32.2417L36.8848 33.0736L34.1221 33.7955L33.3473 36.5954Z"
        fill="#DCD5CB"
        stroke="#43424A"
        strokeWidth="0.666667"
      />
    </svg>
  );
};

import { useEffect, useRef, useState } from 'react';

import cn from 'classnames';

import {
  Avatar,
  FilterChip,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Switch,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useOnClickOutside } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { Permissions as PermissionsType } from '@api/users';
import {
  renderUserInitials,
  renderUserNameOrEmail,
} from '@src/shared/utils/names';

import { Permissions } from '../../permissions';

import styles from './styles.module.css';

export const RemovedUser = ({
  user,
  currentPermissions,
  sendInvitation,
  id,
}: any) => {
  const [isShowMenu, setShowMenu] = useState(false);
  const [permissions, setPermissions] = useState<PermissionsType[]>([
    'edit',
    'invite',
  ]);
  const [on, setOn] = useState(false);

  const permissionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentPermissions) {
      setPermissions(currentPermissions);
    }
  }, [currentPermissions]);

  useOnClickOutside(permissionsRef, () => {
    if (isShowMenu) {
      setShowMenu(false);
    }
  });

  const invite = () => {
    if (!on && id) {
      sendInvitation({
        id,
        users: [{ email: user.email }],
        permissions,
      });
    }
    setOn(true);
  };

  const changePermissions = (permissions: any) => {
    if (id) {
      sendInvitation({
        id,
        users: [{ email: user.email }],
        permissions,
      });
    }
    setPermissions(permissions);
    setOn(true);
  };

  const getPermissions = () => {
    if (permissions.includes('edit')) {
      return 'Editor';
    } else if (permissions.includes('comment')) {
      return 'Commenter';
    } else {
      return 'Viewer';
    }
  };

  return (
    <li className={styles.user}>
      <div
        className={cn(styles.nameOrEmail, {
          [styles.showMenu]: isShowMenu,
        })}
      >
        <TooltipRadix
          classNameTrigger={styles.tooltip}
          type="rich"
          title={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginBottom: '12px',
              }}
            >
              <Avatar initials={user.email} size={40} />
            </span>
          }
          description={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                marginBottom: '8px',
              }}
            >
              <Icon
                name="sprite/at-sign"
                style={{ paddingLeft: '8px' }}
                color="var(--color-neutral-variant-30)"
              />
              {user.user.email}
            </span>
          }
          descriptionSize="S"
          side="bottom"
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Avatar
              initials={renderUserInitials({
                first_name: null,
                last_name: null,
                email: user.user.email,
              })}
              size={20}
            />
            <TypographyPoppins
              className={styles.nameTitle}
              type="body"
              size="S"
            >
              {renderUserNameOrEmail({
                first_name: null,
                last_name: null,
                email: user.user.email,
              })}
            </TypographyPoppins>
          </span>
        </TooltipRadix>
      </div>
      <div className={styles.actions}>
        <div ref={permissionsRef} className={styles.permissions}>
          <Popover open={isShowMenu}>
            <PopoverTrigger className={styles.popover}>
              <FilterChip
                className={styles.permission}
                leadingIcon={
                  isShowMenu && (
                    <Icon
                      name="sprite/tick"
                      size={16}
                      color="var(--color-neutral-variant-30)"
                    />
                  )
                }
                trailingIcon={<Icon name="sprite/caret-down" size={16} />}
                type="label"
                size="S"
                isSelected={isShowMenu}
                onClick={() => setShowMenu(!isShowMenu)}
              >
                {getPermissions && getPermissions()}
              </FilterChip>
            </PopoverTrigger>
            <PopoverContent side="top" align="end">
              <Permissions
                permissions={permissions}
                permissionsSelected={changePermissions}
                closeMenu={() => setShowMenu(false)}
                currentPermissions={currentPermissions}
              />
            </PopoverContent>
          </Popover>
        </div>
        <TooltipRadix description="Invite">
          <Switch
            onValueChange={() => {
              if (invite) {
                invite();
              }
            }}
            value={on}
          />
        </TooltipRadix>
      </div>
    </li>
  );
};

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { CopyFile, copyFileToBoard } from '@api/boards';
import {
  ALL_BOARD_IMAGES_QUERY,
  ALL_FILE_COPIES,
  LIBRARY_QUERY,
} from '@src/shared/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useCopyFile = () => {
  const queryClient = useQueryClient();

  const copyFileMutation = useMutation({
    mutationFn: ({ blocks, boards }: CopyFile) =>
      copyFileToBoard({ blocks, boards }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [ALL_FILE_COPIES, variables.blocks[0]],
      });
      queryClient.invalidateQueries({
        queryKey: [LIBRARY_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARD_IMAGES_QUERY],
      });

      startedSnack({
        label: 'Added to board',
        close: true,
      });
    },
    onError: (err, variables) => {
      startedSnack({
        label: `Couldn't add to board`,
        action: {
          label: 'Try again',
          action: () => {
            copyFileMutation.mutate({
              blocks: variables.blocks,
              boards: variables.boards,
            });
          },
        },
        close: true,
      });
    },
  });

  return {
    copyFileMutation,
  };
};

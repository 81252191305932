import { createEvent, createStore, sample } from 'effector';

import {
  currentLocationCleared,
  initialLocationIdCleared,
  originalLocationCleared,
} from './move';
import { cleared } from './move-out-of-hub';
import { levelCleared } from './nesting-limit';

export const moveBoardDialogOpened = createEvent();
export const moveBoardDialogClosed = createEvent();

export const $isShowMoveBoardDialog = createStore(false);

$isShowMoveBoardDialog
  .on(moveBoardDialogOpened, () => true)
  .reset(moveBoardDialogClosed);

sample({
  clock: moveBoardDialogClosed,
  target: [
    originalLocationCleared,
    initialLocationIdCleared,
    currentLocationCleared,
    levelCleared,
    cleared,
  ],
});

import { DOC_TABS } from '@src/shared/constants/variables-local-storage';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';

import { Tab } from '../ui/tabs';

export const useDocTabs = () => {
  const [tabs, setTabs] = useLocalStorage<Tab[]>(DOC_TABS, []);

  const deleteTab = (id: string) => {
    setTabs((tabs) => tabs.filter((tab) => tab.id !== id));
  };

  const addTab = (tab: Tab) => {
    setTabs((tabs) => [...tabs, tab]);
  };

  return {
    tabs,
    addTab,
    deleteTab,
  };
};

import { openedVaiTagPopup } from '@src/entities/popups/ui/VaiTagPopup/model';

type Thread =
  | {
      type: 'tagging_prompt';
      payload: null;
    }
  | {
      type: 'search_results_small';
      payload: null;
    }
  | {
      type: 'search_results_large';
      payload: null;
    };

export const handleEvent = (message: Thread) => {
  switch (message.type) {
    case 'tagging_prompt':
      openedVaiTagPopup();
      break;
    case 'search_results_small':
    case 'search_results_large':
      // We used to have a show a popup but that has been removed. Keep this here since messages are still recieved
      // https://app.clickup.com/t/8697mqxkp
      break;
    default:
      return;
  }
};

import cn from 'classnames';

import {
  MenuItem,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Permissions as PermissionsType } from '@api/users';

import styles from './styles.module.css';

type Props = {
  permissions: PermissionsType[];
  permissionsSelected: (permissions: PermissionsType[]) => void;
  currentPermissions?: PermissionsType[];
  closeMenu: () => void;
  type?: 'doc' | 'board' | 'hub';
};

export const Permissions = ({
  permissions,
  permissionsSelected,
  type,
  closeMenu,
  currentPermissions,
}: Props) => {
  const getItems = () => {
    const items = [
      {
        label: 'Editor',
        supportingText: 'Can edit, delete and comment.',
        isSelected: permissions.includes('edit'),
        onClick: () => {
          permissionsSelected(['edit', 'invite']);
          closeMenu();
        },
      },
      {
        label: 'Commenter',
        supportingText: "Can comment but can't edit or delete.",
        isSelected: permissions.includes('comment'),
        onClick: () => {
          permissionsSelected(['comment', 'invite']);
          closeMenu();
        },
        isDisabled: type === 'doc',
      },
      {
        label: 'Viewer',
        supportingText: "Read-only. Can't comment or edit.",
        isSelected: permissions.includes('invite') && permissions.length === 1,
        onClick: () => {
          permissionsSelected(['invite']);
          closeMenu();
        },
        isDisabled: type === 'doc',
      },
    ];

    if (currentPermissions?.includes('edit') || type === 'doc') {
      return items;
    } else if (currentPermissions?.includes('comment')) {
      return items.slice(1);
    } else {
      return items.slice(2);
    }
  };

  return (
    <ul className={styles.menu}>
      {getItems().map((item, index) =>
        type === 'doc' && item.isDisabled ? (
          <TooltipRadix key={index} description="Coming soon">
            <Item {...item} />
          </TooltipRadix>
        ) : (
          <Item key={index} {...item} />
        ),
      )}
    </ul>
  );
};

const Item = ({
  label,
  supportingText,
  isSelected,
  isDisabled,
  onClick,
}: {
  label: string;
  supportingText: string;
  isSelected: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}) => (
  <MenuItem
    classNameLabel={cn({ [styles.nonselected]: !isSelected })}
    type="body"
    size="L"
    supportingText={supportingText}
    trailingIcon={
      isSelected && <Icon name="sprite/tick" className={styles.tickmarkIcon} />
    }
    onClickContent={onClick}
    isDisabled={isDisabled}
  >
    {label}
  </MenuItem>
);

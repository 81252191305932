import { useHistory } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  permanentDelete,
  softDeleteDoc,
  undoSoftDeleteDoc,
  UpdateDoc,
} from '@api/docs';
import { recycleBin } from '@src/shared/constants/query-keys';
import {
  ALL_DOCS_QUERY,
  DOC_QUERY,
  DOCS_OF_BOARD_QUERY,
  DOCS_OF_HUB_QUERY,
} from '@src/shared/constants/query-names';
import { DOC_TABS } from '@src/shared/constants/variables-local-storage';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';
import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { Tab } from '../../ui/tabs';

export const useDeleteDoc = (options?: MutateOptions<any, any, any>) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const [, setTabs] = useLocalStorage<Tab[]>(DOC_TABS, []);

  const removeDocMutation = useMutation({
    mutationFn: ({ docId }: UpdateDoc) => softDeleteDoc(docId),
    onSuccess: (data, { docId, redirect }, context) => {
      queryClient.invalidateQueries({
        queryKey: [ALL_DOCS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [DOC_QUERY, { docId }],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_HUB_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_BOARD_QUERY],
      });

      setTabs((tabs) => tabs.filter((t) => t.id !== data.id));

      if (redirect) {
        history.push(`/library`);
      }

      startedSnack({
        label: 'Moved doc to bin',
        close: true,
      });

      if (options?.onSuccess) options?.onSuccess(data, { docId }, context);
    },
    onError: (_, { docId }) => {
      startedSnack({
        label: "Couldn't move doc to bin",
        action: {
          label: 'Try again',
          action: async () => {
            await removeDocMutation.mutateAsync({ docId });
          },
        },
        close: true,
      });
    },
  });

  const permanentDeleteDocMutation = useMutation({
    mutationFn: (id: string) => permanentDelete(id),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: recycleBin.all,
      });

      startedSnack({
        label: 'Permanently deleted',
        close: true,
      });

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    onError: (err, id) => {
      startedSnack({
        label: 'Couldn’t permanently delete',
        action: {
          label: 'Try again',
          action: () => permanentDeleteDocMutation.mutate(id),
        },
        close: true,
      });
    },
  });

  const putDocBackMutation = useMutation({
    mutationFn: ({ docId }: UpdateDoc) => undoSoftDeleteDoc(docId),
    ...(options ?? {}),
    onSuccess: (_data, { docId }) => {
      queryClient.invalidateQueries({
        queryKey: [ALL_DOCS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [DOC_QUERY, { docId }],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_HUB_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_BOARD_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: recycleBin.all,
      });

      startedSnack({
        label: 'Restored doc',
        action: {
          label: 'Go to doc',
          action: () => history.push(`/d/${docId}`),
        },
        close: true,
      });
    },
    onError: (err, variables) => {
      startedSnack({
        label: 'Couldn’t restore',
        action: {
          label: 'Try again',
          action: () => {
            putDocBackMutation.mutate({ docId: variables.docId });
          },
        },
        close: true,
      });
    },
  });

  return { removeDocMutation, putDocBackMutation, permanentDeleteDocMutation };
};

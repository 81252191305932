import { MouseEvent, useState } from 'react';

import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import {
  BoardMove,
  Dropdown,
  IconButton,
  Item,
  TeamIcon,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { Icon } from '@visualist/icons';

import { File } from '@api/blocks';
import { Board } from '@api/boards';
import { boardIdPassed } from '@pages/BoardPage/model';
import { archiveBoardIdSelected } from '@pages/BoardPage/model/archive';
import { deleteBoardModalOpened } from '@src/entities/delete-modals/model';
import { archiveDialogOpened } from '@src/entities/dialogs/archive/model';
import { locationSelected } from '@src/entities/dialogs/board/move/model/move';
import { moveBoardDialogOpened } from '@src/entities/dialogs/board/move/model/show-move-dialog';
import {
  boardDescriptionEntered,
  boardIdSelected,
  boardNameEntered,
  boardRenamingDialogOpened,
} from '@src/entities/dialogs/board/rename/model';
import { unarchiveDialogOpened } from '@src/entities/dialogs/unarchive/model';
import {
  boardShareSheetOpened,
  idSelected,
} from '@src/entities/share-sheet/board/model/open-share-sheet';
import { useInvitees } from '@src/entities/share-sheet/board/model/queries/use-invitees';
import { entityTypeSelected } from '@src/shared/utils/get-entity-type';

import styles from './styles.module.css';

type Props = {
  board: Board;
  parent?: string;
  onboardingId?: string;
};

export const Thumbnail = ({ board, parent, onboardingId }: Props) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const { isEditor } = useInvitees({
    id: board.hub && isOpenDropdown ? board.id : '',
  });

  const history = useHistory();

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();

    if (e.ctrlKey || e.metaKey) {
      window.open(`${import.meta.env.VITE_BASE_URL}/b/${board.id}`, '_blank');
    } else {
      history.push(`/b/${board.id}`);
    }
  };

  const onContextMenu = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setOpenDropdown(true);
  };

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${import.meta.env.VITE_BASE_URL}/b/${board.id}`,
      );

      startedSnack({
        label: 'Copied link to board',
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link to board",
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const rename = () => {
    boardRenamingDialogOpened();
    boardNameEntered(board.name);
    boardIdSelected(board.id);

    if (board.description) {
      boardDescriptionEntered(board.description);
    }
  };

  const invite = () => {
    boardShareSheetOpened();
    idSelected(board.id);
  };

  const archive = () => {
    entityTypeSelected('Board');
    archiveBoardIdSelected(board.id);
    archiveDialogOpened();
  };

  const deleteBoard = () => {
    deleteBoardModalOpened({
      boardId: board.id,
      parentBoardId: parent,
      hubId: parent,
      level: board.level,
    });
    boardIdPassed(board.id);
  };

  const unarchive = () => {
    entityTypeSelected('Board');
    archiveBoardIdSelected(board.id);
    unarchiveDialogOpened();
  };

  const menuItems: Item<Variant>[] = [
    {
      leadingIcon: <Icon name="sprite/link" size={21} />,
      content: 'Copy link',
      onClick: () => copyLink(),
    },
    ...(isEditor() && !board.is_archived
      ? [
          {
            leadingIcon: <Icon name="sprite/pen" />,
            content: 'Rename',
            onClick: rename,
          },
        ]
      : []),
    ...(isEditor() && !board.is_archived
      ? [
          {
            leadingIcon: <BoardMove />,
            content: 'Move board',
            onClick: () => {
              moveBoardDialogOpened();
              locationSelected(board);
            },
          },
        ]
      : []),
    ...(board.hub && !board.is_archived
      ? [
          {
            leadingIcon: <TeamIcon />,
            content: 'Invite',
            onClick: invite,
          },
        ]
      : []),
    {
      leadingIcon: <Icon name="sprite/archive" />,
      content: board.is_archived ? 'Unarchive' : 'Archive',
      isDivider: true,
      onClick: board.is_archived ? unarchive : archive,
    },
    ...(isEditor()
      ? [
          {
            leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
            content: 'Move to bin',
            onClick: () => deleteBoard(),
            classNameContent: styles.delete,
          },
        ]
      : []),
  ];

  return (
    <div style={{ position: 'relative' }}>
      <div
        id={onboardingId}
        className={styles.thumbnail}
        onClick={onClick}
        onContextMenu={onContextMenu}
      >
        <div className={styles.media}>
          {board.recent_images.length > 0 ? (
            <Images images={board.recent_images} />
          ) : (
            <Icon name="sprite/folder-empty-colored" size={80} />
          )}
        </div>
        <header className={styles.header}>
          <div className={styles.content}>
            <Icon name="sprite/board" size={16} />
            <TypographyPoppins type="label" size="S" className={styles.label}>
              {board.name}
            </TypographyPoppins>
          </div>
          <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
            <Dropdown.Menu
              trigger={
                <IconButton
                  className={styles.iconButton}
                  type="unfilled"
                  icon={<Icon name="sprite/3-dot-menu" size={16} />}
                  onClick={() => {}}
                  stopPropagation
                  isSelected={isOpenDropdown}
                />
              }
              side="top"
              density="-2"
            >
              {menuItems.map((item, index) => (
                <Dropdown.MenuItem key={index} item={item} />
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </header>
      </div>
      {board.is_archived && (
        <IconButton
          type="on secondary container"
          className={styles.archived}
          icon={<Icon name="sprite/archive" />}
          onClick={(e) => {
            e.stopPropagation();
            unarchive();
          }}
        />
      )}
    </div>
  );
};

type ImagesProps = {
  images: { id: string; file: File }[];
};

const Images = ({ images }: ImagesProps) => (
  <>
    {images.slice(0, 3).map(({ id, file }) => (
      <div
        className={cn(styles.image, {
          [styles.one]: images.length === 1,
          [styles.two]: images.length === 2,
          [styles.three]: images.length >= 3,
        })}
        key={id}
      >
        <img
          style={{ width: '100%', objectFit: 'cover' }}
          src={file.full_size}
          alt="image"
        />
      </div>
    ))}
  </>
);

import React from 'react';

import { ImageJSON } from '@api/designs';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import { calculateCenter } from '@pages/StudioPage/utils/calculate-center';
import {
  chooseHeroImage,
  performLayoutCalculation,
} from '@pages/StudioPage/utils/layoutAlgorithms';
import { isTransparent } from '@pages/StudioPage/utils/transparency';

export const useShuffler = ({ designId }: { designId: string }) => {
  const { objects, images, updateImage, applyPassedLayers } =
    useStudioDesign(designId);

  const [currentHeroImage, setCurrentHeroImage] = React.useState<string>('');
  const [hasUserSelectedHero, setHasUserSelectedHero] = React.useState(false);

  const [shuffledMap, setShuffledMap] = React.useState<
    Map<number, { images: ImageJSON[] }>
  >(new Map());
  const [currentMapKey, setCurrentMapKey] = React.useState<
    number | undefined
  >();

  const [hasLoadedTransparentImages, setHasLoadedTransparentImages] =
    React.useState(false);
  const [transparentImages, setTransparentImages] = React.useState<Set<string>>(
    new Set(),
  );
  const [paletteImages, setPaletteImages] = React.useState<Set<string>>(
    new Set(),
  );

  const selectHero = (id: string) => {
    // Toggle hero image selection
    if (id === currentHeroImage) {
      setCurrentHeroImage('');
      setHasUserSelectedHero(false);
      return;
    }
    setCurrentHeroImage(id);
    setHasUserSelectedHero(true);
  };

  const centerPoint = React.useMemo(() => {
    if (!objects.length)
      return {
        x: 0,
        y: 0,
      };

    // TODO add shapes
    const blocks = objects
      .filter((b) => b.type !== 'shape')
      .map((b) => {
        return {
          x: b.x,
          y: b.y,
          width: b.metadata.width,
          height: b.metadata.height,
        };
      });

    return calculateCenter({ blocks });
  }, [
    objects
      .map((o) => `${o.x}-${o.y}-${o.metadata.width}-${o.metadata.height}`)
      .join(','),
  ]);

  React.useEffect(() => {
    const checkTransparency = async () => {
      if (objects.length === 0) return;

      // Cache previous results to avoid unnecessary updates
      const tempTransparentImages = new Set<string>();

      const uncheckedImages = images.filter(
        (img) => !transparentImages.has(img.id),
      );

      const promises = uncheckedImages.map((image) => {
        return isTransparent({ url: image.metadata.file.full_size }).then(
          (isTransparent) => {
            if (isTransparent) {
              tempTransparentImages.add(image.id);
            }
          },
        );
      });

      await Promise.all(promises);

      // Only update if there are new transparent images
      if (tempTransparentImages.size > 0) {
        setTransparentImages(
          (prev) => new Set([...prev, ...tempTransparentImages]),
        );
      }
      setHasLoadedTransparentImages(true);
    };

    checkTransparency();
  }, [images, transparentImages]);

  React.useEffect(() => {
    const checkIfPalette = () => {
      if (!images.length) return;

      const newPaletteSet = new Set<string>();
      let hasChanges = false;

      for (const image of images) {
        const isPalette =
          image.metadata.height === 1000 && image.metadata.width === 1000;
        if (isPalette && !paletteImages.has(image.id)) {
          newPaletteSet.add(image.id);
          hasChanges = true;
        }
      }

      // Only update if there are new palette images
      if (hasChanges) {
        setPaletteImages((prev) => new Set([...prev, ...newPaletteSet]));
      }
    };

    checkIfPalette();
  }, [images, paletteImages]);

  const handleShuffle = async () => {
    if (!objects.length) return;

    const nextHeroImageBlock = await chooseHeroImage(images, transparentImages);
    let currentHeroImageBlock = images.find(
      (image) => image.id === currentHeroImage,
    );
    currentHeroImageBlock =
      typeof currentHeroImageBlock !== 'undefined'
        ? currentHeroImageBlock
        : nextHeroImageBlock;

    const heroImage = hasUserSelectedHero
      ? currentHeroImageBlock
      : nextHeroImageBlock;

    const shuffledImages = await performLayoutCalculation({
      heroImage,
      images: images.filter((image) => image.id !== heroImage.id),
      center: centerPoint,
      transparentImages,
      paletteImages,
    });

    const newMap = new Map<number, { images: ImageJSON[] }>(shuffledMap);
    const newKey = newMap.size + 1;
    newMap.set(newKey, { images: shuffledImages });

    setCurrentMapKey(newKey);
    setShuffledMap(newMap);
  };

  return {
    images,
    updateImage,
    applyPassedLayers,
    selectHero,
    centerPoint,
    shuffledMap,
    currentHeroImage,
    currentMapKey,
    handleShuffle,
    hasLoadedTransparentImages,
    setCurrentMapKey,
  };
};

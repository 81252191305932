import { createEvent, createStore } from 'effector';
import { hotkey } from 'effector-hotkey';

import { DeletedItemType } from '@api/bin';

type HubOpenDeleteEvent = {
  hubId: string;
  name: string;
};

type BoardOpenDeleteEvent = {
  boardId: string;
  hubId?: string;
  parentBoardId?: string;
  level: number;
};

// Hub (soft delete)
export const deleteHubModalOpened = createEvent<HubOpenDeleteEvent>();
export const deleteHubModalClosed = createEvent();

export const $hubDeleteModalState = createStore<HubOpenDeleteEvent | null>(
  null,
);

$hubDeleteModalState
  .on(deleteHubModalOpened, (_, payload) => payload)
  .reset(deleteHubModalClosed);

// Board (soft delete)
export const deleteBoardModalOpened = createEvent<BoardOpenDeleteEvent>();
export const deleteBoardModalClosed = createEvent();

export const $boardDeleteModalState = createStore<BoardOpenDeleteEvent | null>(
  null,
);

$boardDeleteModalState
  .on(deleteBoardModalOpened, (_, payload) => payload)
  .reset(deleteBoardModalClosed);

// Doc (soft delete)
export const deleteDocModalOpened = createEvent();
export const deleteDocModalClosed = createEvent();
export const docIdSelected = createEvent<string>();

export const $isOpenDocDeleteModal = createStore(false);
export const $docId = createStore<string | null>(null);

$isOpenDocDeleteModal
  .on(deleteDocModalOpened, () => true)
  .reset(deleteDocModalClosed);

$docId.on(docIdSelected, (_, docId) => docId).reset(deleteDocModalClosed);

// Bin (permanently deletion of all items)
export const binDeletionDialogOpened = createEvent();
export const binDeletionDialogClosed = createEvent();

export const $isOpenBinDeletionDialog = createStore(false);

$isOpenBinDeletionDialog
  .on(binDeletionDialogOpened, () => true)
  .reset(binDeletionDialogClosed);

// Bin (permanently deletion)
export const deletePermanentlyModalOpened = createEvent<DeletedItemType>();
export const deletePermanentlyModalClosed = createEvent();

export const $permanentlyDeleteModal = createStore<DeletedItemType | null>(
  null,
);

$permanentlyDeleteModal
  .on(deletePermanentlyModalOpened, (_, item) => item)
  .reset(deletePermanentlyModalClosed);

// Hotkeys
hotkey({
  key: 'Escape',
  filter: $hubDeleteModalState.map(Boolean),
  target: [deleteHubModalClosed],
});

hotkey({
  key: 'Escape',
  filter: $boardDeleteModalState.map(Boolean),
  target: [deleteBoardModalClosed],
});

hotkey({
  key: 'Escape',
  filter: $isOpenDocDeleteModal,
  target: deleteDocModalClosed,
});

hotkey({
  key: 'Escape',
  filter: $isOpenBinDeletionDialog,
  target: binDeletionDialogClosed,
});

hotkey({
  key: 'Escape',
  filter: $permanentlyDeleteModal.map(Boolean),
  target: deletePermanentlyModalClosed,
});

import { NavItemList } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { BlockItem, DeletedItem, DocItem } from '@api/bin';

import { File } from '../file';

import styles from '../styles.module.css';

type ChildFilesProps = {
  children: DeletedItem[];
  indent: number;
};

type ChildBoardsProps = {
  children: DeletedItem[];
  indent: number;
};

export const renderChildFiles = ({ children, indent }: ChildFilesProps) => {
  const isDocOrBlockItem = (item: DeletedItem): item is DocItem | BlockItem => {
    return item.item_type === 'Doc' || item.item_type === 'Block';
  };

  return children
    .filter(isDocOrBlockItem)
    .map((item) => (
      <File key={item.id} item={item} indent={indent} isNestedItem />
    ));
};

export const renderChildBoards = ({ children, indent }: ChildBoardsProps) => {
  return children
    .filter((item) => item.item_type === 'Board')
    .map((boardItem) => (
      <NavItemList
        key={boardItem.board.id}
        className={styles.item}
        classNameContent={styles.align}
        leadingIcon={<Icon name="sprite/board" />}
        name={boardItem.board.name}
        indent={indent}
        onClick={() => {}}
        isSelected={false}
      >
        {boardItem.child &&
          renderChildFiles({ children: boardItem.child, indent: indent + 1 })}
        {boardItem.child &&
          renderChildBoards({ children: boardItem.child, indent: indent + 2 })}
      </NavItemList>
    ));
};

import { useEffect } from 'react';

import { disableShortcuts, enableShortcuts } from '../model';

/**
 * Hook to disable shortcuts when a modal is open
 * @param isOpen Whether the modal is open
 */
export const useModalShortcutDisable = (isOpen: boolean) => {
  useEffect(() => {
    if (isOpen) {
      disableShortcuts();
    } else {
      enableShortcuts();
    }

    return () => {
      enableShortcuts();
    };
  }, [isOpen]);
};

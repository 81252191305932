// Based from https://www.taniarascia.com/keyboard-shortcut-hook-react/

import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

type ShortcutOptions = {
  disableTextInputs: boolean;
};

type ModifierMap = {
  Control: boolean;
  Alt: boolean;
  Command: boolean;
  Shift: boolean;
};

/**
 * A keyboard shortcut hook that handles key combinations
 *
 * @param shortcut - The keyboard shortcut string in format:
 *                   - Single key: 'a', 'b', etc
 *                   - With modifiers: 'Control+a', 'Command+Shift+z'
 *                   - Supported modifiers: Control, Alt, Command, Shift
 * @param callback - Function to execute when shortcut is triggered
 * @param options - Configuration options:
 *                 - disableTextInputs: Disable shortcuts in text inputs
 */

export const useShortcut = (
  shortcut: string,
  callback: (event: KeyboardEvent) => void,
  options: ShortcutOptions = { disableTextInputs: true },
) => {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    const isTextInput =
      event.target instanceof HTMLTextAreaElement ||
      (event.target instanceof HTMLInputElement &&
        (!event.target.type || event.target.type === 'text')) ||
      (event.target as HTMLElement).isContentEditable;

    const modifierMap: ModifierMap = {
      Control: event.ctrlKey,
      Alt: event.altKey,
      Command: event.metaKey,
      Shift: event.shiftKey,
    };

    // Cancel shortcut if key is being held down
    if (event.repeat) {
      return null;
    }

    // Don't enable shortcuts in inputs unless explicitly declared
    if (options.disableTextInputs && isTextInput) {
      return event.stopPropagation();
    }

    // Handle combined modifier key shortcuts (e.g. pressing Control + D)
    if (shortcut.includes('+')) {
      const keyArray = shortcut.split('+');

      // If the first key is a modifier, handle combinations
      if (Object.keys(modifierMap).includes(keyArray[0])) {
        const finalKey = keyArray.pop();

        // Check that exactly the right modifiers are pressed
        const requiredModifiers = new Set(keyArray);
        const activeModifiers = new Set(
          Object.entries(modifierMap)
            .filter(([, isPressed]) => isPressed)
            .map(([key]) => key),
        );

        const hasExactModifiers =
          requiredModifiers.size === activeModifiers.size &&
          [...requiredModifiers].every((mod) => activeModifiers.has(mod));

        // Run handler if the exact modifier(s) + key have been pressed
        if (hasExactModifiers && finalKey === event.key) {
          // Blocks default event on shortcut match
          event.preventDefault();
          return callbackRef.current(event);
        }
      }
    }

    // Single key shortcuts (e.g. pressing D)
    if (shortcut === event.key) {
      return callbackRef.current(event);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
};

import { Icon } from '@visualist/icons';

import styles from './styles.module.css';

export const SearchIcon = () => {
  return (
    <div className={styles.container}>
      <Icon
        className={styles.search}
        size={80}
        name="sprite/magnifying-glass-colored"
      />
      <div className={styles.emptyContainer}></div>
    </div>
  );
};

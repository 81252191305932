import cn from 'classnames';

import {
  Avatar,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { DeletedBy } from '@api/bin';

import styles from '../styles.module.css';

type Props = {
  user: DeletedBy;
  isIndent: boolean;
};

export const Person = ({ user, isIndent }: Props) => {
  return (
    <div
      className={cn(styles.person, {
        [styles.personIndent]: isIndent,
      })}
    >
      <TooltipRadix
        type="rich"
        title={
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              marginBottom: '12px',
            }}
          >
            <Avatar
              image={user.photo?.full_size}
              initials={`${user.first_name} ${user.last_name}`}
              email={user.email}
              size={40}
            />
          </span>
        }
        description={
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '16px',
              marginBottom: '8px',
            }}
          >
            <Icon
              name="sprite/at-sign"
              style={{ paddingLeft: '8px' }}
              color="var(--color-neutral-variant-30)"
            />
            {user.email}
          </span>
        }
        descriptionSize="S"
        side="bottom"
      >
        <span style={{ display: 'flex' }}>
          <Avatar
            image={user.photo?.full_size}
            initials={`${user.first_name} ${user.last_name}`}
            email={user.email}
            size={20}
          />
        </span>
      </TooltipRadix>
    </div>
  );
};

import {
  LoginFormValues,
  LoginFormWithPasswordValues,
  ResetConfirmFormValues,
  SignupFormWithPasswordValues,
} from './types';

export const validateEmail = <T extends LoginFormValues>(
  values: T,
  errors: Record<keyof T, string>,
) => {
  const { email } = values;

  if (!email) {
    errors.email = 'Enter a valid email';
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    errors.email = 'Enter a valid email';
  }

  if (email.length > 320) {
    errors.email = 'Use an email below 320 characters';
  }

  return errors;
};

export const validatePasswordLogin = <T extends LoginFormWithPasswordValues>(
  values: T,
  errors: Record<keyof T, string>,
) => {
  const { password } = values;

  errors = validateEmail(values, errors);

  if (!password) {
    errors.password = 'Enter your password';
  }

  return errors;
};

export const validateSignupPasswordLogin = <
  T extends SignupFormWithPasswordValues,
>(
  values: T,
  errors: Record<keyof T, string>,
) => {
  const { confirmPassword, password } = values;

  errors = validatePasswordLogin(values, errors);

  if (!confirmPassword) {
    errors.confirmPassword = 'Enter your password';
  }

  if (!/^(?=.*[a-zA-Z])(?=.*[0-9\W]).{12,}$/.test(password)) {
    errors.password =
      'At least 12 characters, including 1 letter and 1 symbol or number';
  }

  if (confirmPassword !== password) {
    errors.confirmPassword = 'Passwords do not match';
  }

  return errors;
};

export const resolvePasswordErrorMessages = (errMessage: string) => {
  switch (errMessage) {
    case 'This password is too short. It must contain at least 12 characters.':
    case 'This password must contain at least: one English lower or uppercase character, one digit or special character.':
    case 'This password is entirely numeric.':
      return 'At least 12 characters, including 1 letter and 1 symbol or number';
    case 'The password is too similar to the email.':
    case 'The password is too similar to the first_name.':
    case 'The password is too similar to the last_name.':
    case 'This password is too common.':
      return 'Use a more secure password—don’t use any part of your name or email';
    case "The two password fields didn't match":
      return 'Passwords don’t match';
    default:
      return 'An error occurred';
  }
};

export const resolveEmailErrorMessages = (errMessage: string) => {
  switch (errMessage) {
    case 'A user is already registered with this e-mail address.':
      return 'Looks like this email is already registered';
    default:
      return 'An error occurred';
  }
};

export const validateResetConfirmedPasswordLogin = <
  T extends ResetConfirmFormValues,
>(
  values: T,
  errors: Record<keyof T, string>,
) => {
  const { password, confirmPassword } = values;

  if (!password) {
    errors.password = 'Enter your password';
  }

  if (!confirmPassword) {
    errors.confirmPassword = 'Enter your password';
  }

  if (!/^(?=.*[a-zA-Z])(?=.*[0-9\W]).{12,}$/.test(password)) {
    errors.password =
      'At least 12 characters, including 1 letter and 1 symbol or number';
  }

  if (confirmPassword !== values.password) {
    errors.confirmPassword = 'Passwords do not match';
  }

  if (!/^(?=.*[a-zA-Z])(?=.*[0-9\W]).{12,}$/.test(password)) {
    errors.password =
      'At least 12 characters, including 1 letter and 1 symbol or number';
  }

  return errors;
};

// This function is largely the same as resolvePasswordErrorMessages except for a few strings.
export const resolveResetPasswordErrorMessages = (errMessage: string) => {
  switch (errMessage) {
    case 'This password is too short. It must contain at least 12 characters.':
    case 'This password must contain at least: one English lower or uppercase character, one digit or special character.':
    case 'This password is entirely numeric.':
      return 'At least 12 characters, including 1 letter and 1 symbol or number';
    case 'The password is too similar to the email.':
    case 'The password is too similar to the first name.':
    case 'The password is too similar to the last name.':
    case 'This password is too common.':
      return 'Use a more secure password—don’t use any part of your name or email';
    case "The two password fields didn't match":
      return 'Passwords don’t match';
    default:
      return 'An error occurred';
  }
};

import { createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';

import { archiveBoardIdCleared } from '@pages/BoardPage/model/archive';
import { archiveFileIdCleared } from '@pages/FileCard/model/archive';
import { archiveHubIdCleared } from '@pages/HubPage/model/archive';
import { archiveDocIdCleared } from '@src/entities/doc/archive';
import { entityTypeCleared } from '@src/shared/utils/get-entity-type';

export const archiveDialogOpened = createEvent();
export const archiveDialogClosed = createEvent();

export const $isOpenArchiveDialog = createStore(false);

sample({
  clock: archiveDialogOpened,
  fn: () => true,
  target: $isOpenArchiveDialog,
});

sample({
  clock: archiveDialogClosed,
  fn: () => false,
  target: $isOpenArchiveDialog,
});

sample({
  clock: archiveDialogClosed,
  target: [
    entityTypeCleared,
    archiveHubIdCleared,
    archiveBoardIdCleared,
    archiveDocIdCleared,
    archiveFileIdCleared,
  ],
});

hotkey({
  key: 'Escape',
  filter: $isOpenArchiveDialog,
  target: archiveDialogClosed,
});

import { useHistory } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { AddedBlockToSetResponse } from '@api/blocks';
import { createDesign, updateDesignFile } from '@api/designs';
import { Design } from '@pages/StudioPage/lib/design';
import { getDefaultPagedContentsBlob } from '@pages/StudioPage/utils';
import { studioDesignKeys } from '@src/shared/constants/query-keys';
import {
  DefaultError,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

type CreateDesignResponse = Awaited<ReturnType<typeof createDesign>>;
type CreateDesignVariables = Parameters<typeof createDesign>[0];

export const useCreateDesign = (
  mutationProps?: Omit<
    UseMutationOptions<
      CreateDesignResponse,
      DefaultError,
      CreateDesignVariables
    >,
    'mutationFn'
  > & {
    addBlocks?: (designId: string) => Promise<AddedBlockToSetResponse>;
  },
) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const createDesignMutation = useMutation({
    ...mutationProps,
    mutationFn: createDesign,
    onSuccess: async (res, vars, ctx) => {
      if (mutationProps?.onSuccess) {
        await mutationProps.onSuccess(res, vars, ctx);
      }

      let addedBlocks: AddedBlockToSetResponse = [];

      if (mutationProps && mutationProps.addBlocks) {
        addedBlocks = await mutationProps.addBlocks(res.data.id);
      }

      const id = res.data.id;

      await updateDesignFileMutation.mutateAsync({
        id,
        contents_blob: {
          id,
          version: 1,
          type: 'pages',
          data: [
            {
              objects: Design.convertAddedBlocksToImageJson(addedBlocks),
              metadata: getDefaultPagedContentsBlob().metadata,
            },
          ],
        },
      });

      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.all,
      });

      history.replace(`/studio/${res.data.id}`);
    },
    onError: (err, vars, ctx) => {
      if (mutationProps?.onError) mutationProps.onError(err, vars, ctx);

      startedSnack({
        label: "Couldn't create design",
        action: {
          label: 'Try again',
          action: () => {
            if (vars.boardId) {
              createDesignMutation.mutate({ boardId: vars.boardId });
            } else {
              createDesignMutation.mutate({});
            }
          },
        },
        close: true,
      });
    },
  });

  // Copy of update from useDesigns but only for private use
  const updateDesignFileMutation = useMutation({
    mutationFn: updateDesignFile,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.design(data.data.id),
      });
    },
  });

  return {
    createDesignMutation,
  };
};

import { Icon, IconProps } from './Icon';

export const BoardMove = (props: IconProps) => {
  return (
    <Icon {...props} fill="none">
      <path
        d="M10.1789 3.85777L10.2739 3.81027L10.3328 3.72188L11.2141 2.4H12.7859L13.6672 3.72188L13.7261 3.81027L13.8211 3.85777L14.6 4.24721V5.1H9.4V4.24721L10.1789 3.85777Z"
        stroke="currentColor"
        strokeWidth="0.8"
      />
      <mask
        id="mask0_3152_4188"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="3"
        width="14"
        height="19"
      >
        <path
          d="M19 22L19 16.3472H17.7205L17.6366 13.2639H19L19 3.5H5V22H19Z"
          fill="currentColor"
        />
      </mask>
      <g mask="url(#mask0_3152_4188)">
        <mask
          id="mask1_3152_4188"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="4"
          y="2"
          width="15"
          height="22"
        >
          <path
            d="M15.9994 5.04161H7.99939V3.49995L7.69171 2.47217H4.46094V23.0277L18.9994 22.8497V2.47217L15.9994 2.98606V5.04161Z"
            fill="currentColor"
          />
        </mask>
        <g mask="url(#mask1_3152_4188)">
          <path
            d="M5.53711 21.405V4.09509H17.4602C18.0125 4.09509 18.4602 4.54281 18.4602 5.09509V21.405H5.53711Z"
            stroke="currentColor"
          />
        </g>
      </g>
      <path d="M7.5 15H18.0001" stroke="currentColor" strokeWidth="0.8" />
      <path
        d="M15.5 13.5L18 15L15.5 16.5"
        stroke="currentColor"
        strokeWidth="0.8"
      />
    </Icon>
  );
};

import { PropsWithChildren } from 'react';

import { BrandLoader } from '@visualist/design-system/src/components/v2';

import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';

import styles from './styles.module.css';

// Stickies are stored outside the design file. As such pages and objects maintain id's to stickies in studio.
// These ids are mapped together. Stickies are the only element mapped from outside the design file
// the useStickies hook is also called inside the useStudioDesign hook so stickies are always accessible

export const FileLoader = ({
  children,
  designId,
}: PropsWithChildren<{
  designId: string;
}>) => {
  const { isLoading } = useStudioDesign(designId);

  const loader = (
    <div className={styles.loaderContainer}>
      <BrandLoader
        animatedIcon="paint-brush"
        subText="Crafting your canvas, just a sec!"
      />
    </div>
  );

  // If any loading of blob file show loader
  if (isLoading) return loader;

  return <>{children}</>;
};

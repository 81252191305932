import { KonvaEventObject } from 'konva/lib/Node';

import { MAX_ZOOM, MIN_ZOOM } from '../constants';
import { updatedStage } from '../model';

const BASE_SCALE_SENSITIVITY = 0.015;

export const onWheelChange = (e: KonvaEventObject<WheelEvent>) => {
  e.evt.preventDefault();

  const stage = e.target.getStage();
  if (!stage?.getPointerPosition()) return;

  if (!e.evt.ctrlKey) {
    updatedStage({
      scale: stage.scaleX(),
      x: stage.x() - e.evt.deltaX,
      y: stage.y() - e.evt.deltaY,
    });
    return;
  }

  // Handle zooming
  const oldScale = stage.scaleX();
  const pointerPosition = stage.getPointerPosition();

  if (!pointerPosition) return;
  const mousePointTo = {
    x: pointerPosition.x / oldScale - stage.x() / oldScale,
    y: pointerPosition.y / oldScale - stage.y() / oldScale,
  };

  // Calculate new scale based on delta
  const scaleDelta = -e.evt.deltaY * BASE_SCALE_SENSITIVITY;
  // Allows scaling based on zoom. Closer zoom creates larger change in zoom and farther zoom creates smaller changes
  const newScale = oldScale * Math.exp(scaleDelta);

  // Clamp scale between MIN and MAX zoom levels
  const clampedScale = Math.min(Math.max(newScale, MAX_ZOOM), MIN_ZOOM);
  stage.scale({
    x: clampedScale,
    y: clampedScale,
  });

  updatedStage({
    scale: clampedScale,
    x: (pointerPosition.x / clampedScale - mousePointTo.x) * clampedScale,
    y: (pointerPosition.y / clampedScale - mousePointTo.y) * clampedScale,
  });
};

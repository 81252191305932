import { combine, createEvent, createStore } from 'effector';

import { $selectedLocation } from './move';
import { $moveOutOfHub } from './move-out-of-hub';

export const confirmationDialogOpened = createEvent();
export const confirmationDialogClosed = createEvent();

export const $isShowConfirmationDialog = createStore(false);

$isShowConfirmationDialog
  .on(confirmationDialogOpened, () => true)
  .reset(confirmationDialogClosed);

export const $shouldMoveOutOfHub = combine(
  $selectedLocation,
  $moveOutOfHub,
  (selectedLocation, moveOutOfHub) =>
    !selectedLocation && Boolean(moveOutOfHub),
);

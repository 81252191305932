import { useFormik } from 'formik';

import {
  Button,
  TextField,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { saveProfile } from '@api/account';
import { useAppData } from '@src/AppContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { Progress } from '../progress';

import styles from './styles.module.css';

interface WelcomeNameRequestScreenProps {
  onNext: () => void;
  onPreviousClick: () => void;
}

export const WelcomeNameRequestScreen = ({
  onNext,
  onPreviousClick,
}: WelcomeNameRequestScreenProps) => {
  const { user } = useAppData();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: saveProfile,
    onSuccess: (response) => {
      queryClient.setQueryData(['my-user'], response);
      localStorage.setItem('v_user', JSON.stringify(response));
    },
    onError: () => {},
  });

  const formik = useFormik({
    initialValues: {
      first_name: user.first_name ?? '',
      last_name: user.last_name ?? '',
      email: user.email,
    },
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      return mutate({
        first_name: values.first_name,
        last_name: values.last_name,
      });
    },
  });

  return (
    <div className={styles.container}>
      <Progress onPreviousClick={onPreviousClick} currentStep={2} />
      <TypographyPoppins type="headline" size="L" className={styles.headline}>
        Let’s get you started right away
      </TypographyPoppins>
      <TypographyPoppins type="body" size="M" className={styles.welcomeBody}>
        Just one more question: what’s your name?
        <br />
        <br />
        Your name will show on invitations you send to collaborators and files
        you create, edit, or comment on.
      </TypographyPoppins>
      <form className={styles.form} onSubmit={formik.handleSubmit}>
        <div className={styles.row}>
          <div className={styles.formInput}>
            <TypographyPoppins type="label" size="M" className={styles.label}>
              <label htmlFor="first_name">First name</label>
            </TypographyPoppins>
            <TextField
              hideClear
              formNoValidate
              onBlur={() => {
                if (user.first_name !== formik.values.first_name)
                  formik.submitForm();
              }}
              id="first_name"
              name="first_name"
              type="text"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              errorAndSupportingText={formik.errors.first_name}
              clear={(e) => {
                e.preventDefault();
              }}
            />
          </div>
          <div className={styles.formInput}>
            <TypographyPoppins type="label" size="M" className={styles.label}>
              <label htmlFor="last_name">Last name</label>
            </TypographyPoppins>
            <TextField
              hideClear
              formNoValidate
              onBlur={() => {
                if (user.last_name !== formik.values.last_name)
                  formik.submitForm();
              }}
              id="last_name"
              name="last_name"
              type="text"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              errorAndSupportingText={formik.errors.last_name}
              clear={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        </div>
      </form>
      <Button
        type="filled"
        label="Take me to my dashboard"
        onClick={onNext}
        className={styles.welcomeButton}
      />
    </div>
  );
};

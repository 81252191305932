import { combine, createEvent, createStore } from 'effector';
import { hotkey } from 'effector-hotkey';

import {
  $isShowBoardDialog,
  $step,
  boardDialogClosed,
  stepContinue,
} from './create';

export const searchOpened = createEvent<boolean>();
export const searchClosed = createEvent();

export const queryEntered = createEvent<string>();
export const queryCleared = createEvent();

export const $isSearchOpen = createStore(false);
export const $query = createStore('');

$isSearchOpen.on(searchOpened, (_, state) => state).reset(searchClosed);

$query
  .on(queryEntered, (_, query) => query)
  .reset([queryCleared, searchClosed]);

const $goNext = combine(
  $step,
  $isSearchOpen,
  (step, isSearchOpen) => step === 1 && !isSearchOpen,
);

hotkey({
  key: 'Enter',
  filter: $goNext,
  target: stepContinue,
});

hotkey({
  key: 'Escape',
  filter: $isShowBoardDialog && $isSearchOpen.map((open) => !open),
  target: boardDialogClosed,
});

hotkey({
  key: 'Escape',
  filter: $isSearchOpen && $query.map((query) => query.length === 0),
  target: searchClosed,
});

hotkey({
  key: 'Escape',
  filter: $isSearchOpen && $query.map((query) => query.length > 0),
  target: queryCleared,
});

import chroma from 'chroma-js';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';

import { ColorSwatch } from '@api/services';
import { $palette, paletteCleared } from '@pages/FileCard/palette/model';
import { generateId } from '@src/shared/utils/id';

// The maximum value that can be encoded with a six-digit hexadecimal number
const MAX_VALUE = 16777215;

export const fileNameEntered = createEvent<string>();
export const fileNameCleared = createEvent();

export const paletteDialogOpened = createEvent();
export const paletteDialogClosed = createEvent();

export const $isShowPaletteDialog = createStore(false);

$isShowPaletteDialog
  .on(paletteDialogOpened, () => true)
  .reset(paletteDialogClosed);

// Random palette generation
export const randomPaletteGenerated = createEvent();

// An effect for generating random colors
const generateRandomColorsFx = createEffect((count: number) => {
  return Array.from({ length: count }, (_, index) => {
    const randomColor = Math.floor(Math.random() * MAX_VALUE).toString(16);
    const hex_color = `#${randomColor.padStart(6, '0')}`;

    const color = chroma(hex_color);
    const rgb_color = color.rgb().join(', ');
    const hsl = color.hsl();
    const hsv = color.hsv();

    const hsl_color = `${Math.round(hsl[0])}, ${Math.round(
      hsl[1] * 100,
    )}%, ${Math.round(hsl[2] * 100)}%`;

    const hsv_color = `${Math.round(hsv[0])}, ${Math.round(
      hsv[1] * 100,
    )}%, ${Math.round(hsv[2] * 100)}%`;

    return {
      hex_color,
      rgb_color,
      hsl_color,
      hsv_color,
      order: index + 1,
    };
  });
});

// Filling the palette with randomly generated colors
sample({
  clock: generateRandomColorsFx.doneData,
  fn: (swatches) => ({
    theme: '',
    swatches: swatches.map(
      (s) =>
        ({
          ...s,
          // Random id for random palettes
          id: generateId('palette'),
          theme: '',
        } satisfies ColorSwatch),
    ),
  }),
  target: $palette,
});

// Generate 5 random colors when opening the dialog
sample({
  clock: paletteDialogOpened,
  fn: () => 5,
  target: generateRandomColorsFx,
});

// Generating random colors for the current number of swatches when pressing a button or spacebar
sample({
  clock: randomPaletteGenerated,
  source: $palette,
  fn: (palette) => palette.swatches.length,
  target: generateRandomColorsFx,
});

sample({
  clock: paletteDialogClosed,
  target: [paletteCleared, fileNameCleared],
});

// File name
export const $fileName = createStore('');

$fileName.on(fileNameEntered, (_, fileName) => fileName).reset(fileNameCleared);

hotkey({
  key: 'Escape',
  filter: $isShowPaletteDialog,
  target: paletteDialogClosed,
});

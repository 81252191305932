import { forwardRef, MouseEvent, MouseEventHandler, ReactNode } from 'react';

import cn from 'classnames';

import { TypographyPoppins } from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

type Style = 'outlined' | 'elevated';

interface AssistChipProps {
  leadingIcon?: boolean;
  disabled?: boolean;
  style?: Style;
  icon?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  children: ReactNode;
  className?: string;
}

export const AssistChip = forwardRef<HTMLButtonElement, AssistChipProps>(
  (
    { leadingIcon, disabled, style, icon, onClick, children, className },
    ref,
  ) => {
    const handleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (onClick) onClick(e);
    };

    return (
      <button
        ref={ref}
        className={cn(styles.outlined, className, {
          [styles.disabledOutlined]: style === 'outlined' && disabled,
          [styles.elevated]: style === 'elevated',
          [styles.disabledElevated]: style === 'elevated' && disabled,
          [styles.icon]: leadingIcon,
          // [styles.elevationLight2]:
          //   style === 'elevated' && (state === 'pressed' || state === 'dragged'),
        })}
        onClick={handleOnClick}
        disabled={disabled}
      >
        {leadingIcon && (
          <span
            className={cn(styles.plusIcon, {
              [styles.plusIconOutlined]: style === 'outlined' && disabled,
              [styles.plusIconElevated]: style === 'elevated' && disabled,
            })}
          >
            {icon}
          </span>
        )}
        <TypographyPoppins type="label" size="L">
          <span
            className={cn({
              [styles.disabledOutlinedText]: style === 'outlined' && disabled,
              [styles.disabledElevatedText]: style === 'elevated' && disabled,
            })}
          >
            {children}
          </span>
        </TypographyPoppins>
      </button>
    );
  },
);

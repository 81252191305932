import { useEffect } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';

import {
  IconButton,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import {
  $isShowElementsPanel,
  $isShowFilesPanel,
  elementPanelClosed,
  elementsPanelToggled,
  filesPanelClosed,
  filesPanelToggled,
} from '../../../../pages/DocPage/model/sidebar-opening';

import styles from './styles.module.css';

export const NavPanel = ({ isArchived }: { isArchived?: boolean }) => {
  const isShowElementsPanel = useUnit($isShowElementsPanel);
  const isShowFilesPanel = useUnit($isShowFilesPanel);

  useEffect(() => {
    if (isArchived) {
      filesPanelClosed();
      elementPanelClosed();
    }
  }, [isArchived]);

  return (
    <ul className={styles.navPanel}>
      <li className={styles.item}>
        <IconButton
          className={cn(styles.iconButton, {
            [styles.archived]: isArchived,
          })}
          type="unfilled"
          icon={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {isShowElementsPanel ? (
                <Icon
                  name="sprite/elements-filled"
                  color="var(--color-neutral-variant-30)"
                />
              ) : (
                <Icon
                  name="sprite/elements"
                  color={
                    isArchived
                      ? 'var(--color-neutral-variant-90)'
                      : 'var(--color-neutral-variant-30)'
                  }
                />
              )}
            </div>
          }
          isSelected={isShowElementsPanel}
          onClick={() => elementsPanelToggled()}
          isDisabled={isArchived}
        />
        <TypographyPoppins className={styles.label} type="label" size="M">
          Elements
        </TypographyPoppins>
      </li>
      <li className={styles.item}>
        <IconButton
          className={cn(styles.iconButton, {
            [styles.archived]: isArchived,
          })}
          type="unfilled"
          icon={
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {isShowFilesPanel ? (
                <Icon
                  name="sprite/library-filled"
                  color="var(--color-neutral-variant-30)"
                />
              ) : (
                <Icon
                  name="sprite/library"
                  color={
                    isArchived
                      ? 'var(--color-neutral-variant-90)'
                      : 'var(--color-neutral-variant-30)'
                  }
                />
              )}
            </div>
          }
          isSelected={isShowFilesPanel}
          onClick={() => filesPanelToggled()}
          isDisabled={isArchived}
        />
        <TypographyPoppins className={styles.label} type="label" size="M">
          Files
        </TypographyPoppins>
      </li>
    </ul>
  );
};

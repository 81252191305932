import { motion } from 'framer-motion';

import { UserIcon, UserType } from '@visualist/design-system';
import {
  Avatar,
  Badge,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Size } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { Icon } from '@visualist/icons';

import { getDateCommaSeparatedYear } from '@src/shared/utils/data';

import styles from './styles.module.css';

type Props = {
  createdBy: UserType;
  createdAt: string;
  expanded?: boolean;
  size?: Size['label'];
  isActionable?: boolean;
  showActionableBadge?: boolean;
};

export const StickyHeader = ({
  createdBy,
  createdAt,
  expanded = false,
  size = 'XS',
  isActionable = false,
  showActionableBadge = false,
}: Props) => {
  const date = getDateCommaSeparatedYear(createdAt, 'comprehensive');

  return (
    <div className={styles.header}>
      <div className={styles.avatarContainer}>
        <TooltipRadix
          key={createdBy.id}
          type="rich"
          title={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                marginBottom: '12px',
              }}
            >
              <Avatar
                key={createdBy.id}
                image={createdBy?.photo?.full_size}
                initials={`${createdBy.first_name ?? ''} ${
                  createdBy.last_name ?? ''
                }`}
                size={40}
              />
              {createdBy
                ? `${createdBy.first_name} ${createdBy.last_name}`
                : undefined}
            </span>
          }
          description={
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                marginBottom: '8px',
              }}
            >
              <Icon
                name="sprite/at-sign"
                style={{ paddingLeft: '8px' }}
                color="var(--color-neutral-variant-30)"
              />
              {createdBy.email}
            </span>
          }
          descriptionSize="S"
          side="bottom"
        >
          <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            {createdBy.photo && createdBy.photo.small_square_crop ? (
              <img className={styles.avatar} src={createdBy.photo.full_size} />
            ) : (
              <UserIcon user={createdBy} size={20} fontSize={8} />
            )}
            <TypographyPoppins type="label" size={size} className={styles.name}>
              {createdBy.first_name}
            </TypographyPoppins>
          </span>
        </TooltipRadix>
      </div>
      <div className={styles.rightSide}>
        {expanded ? (
          <TypographyPoppins size="XS" type="label" className={styles.date}>
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.5,
              }}
            >
              {date}
            </motion.span>
          </TypographyPoppins>
        ) : (
          isActionable &&
          showActionableBadge && (
            <div
              style={{
                position: 'absolute',
                top: -16,
                right: -16,
              }}
            >
              <TooltipRadix description="Marked for action">
                <Badge text="" threeSizes="large" style="red-single digit" />
              </TooltipRadix>
            </div>
          )
        )}
      </div>
    </div>
  );
};

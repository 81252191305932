import React, { useCallback, useEffect, useRef, useState } from 'react';

import axios from 'axios';
import cn from 'classnames';
import { useUnit } from 'effector-react';
import { AnimatePresence, motion } from 'framer-motion';

import MagicEraserIcon from '@visualist/design-system/src/components/Icons/MagicEraserIcon';
import {
  SegmentedButton,
  TypographyPoppins,
  VaiColored,
} from '@visualist/design-system/src/components/v2';
import { Slider } from '@visualist/design-system/src/components/v2/Slider';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { ImageResponse, magicStudioApi } from '@pages/StudioPage/api';
import { useImageHistory } from '@pages/StudioPage/components/magic-studio/use-image-history';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import { $showMagicStudio, closedMagicStudio } from '@pages/StudioPage/model';
import { VaiButton, VaiButtonText } from '@src/entities/vai/ui/button';
import { VaiModal, VaiModalProvider } from '@src/entities/vai/ui/modal';

import { generateConfigCssVariables, MASK_CONFIG } from './config';

import styles from './styles.module.css';

export const MagicStudio = ({ designId }: { designId: string }) => {
  const magicStudioState = useUnit($showMagicStudio);
  const showMagicStudio = magicStudioState.show;
  const selectedObject = magicStudioState.selectedObject;
  const { updateImage } = useStudioDesign(designId);

  // Image history management
  const {
    currentImage,
    addNewImage,
    undo: undoHistory,
    redo: redoHistory,
    canUndo,
    canRedo,
    reset,
    resetAndAddImage,
  } = useImageHistory({
    initialImage: null,
  });

  // Simple way to toggle between drawing and erasing
  const [maskMode, setMaskMode] = useState('add');
  const [brushSize, setBrushSize] = useState(MASK_CONFIG.DEFAULT_BRUSH_SIZE);

  // We need to disable the buttons when we have not drawn anything
  // This is a simple way to do it
  // Alternatively, we could use a ref to track the canvas changes and loop through the pixels
  const [hasDrawn, setHasDrawn] = useState<boolean>(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [canvasSize, setCanvasSize] = useState({ width: 0, height: 0 });
  const [showActionsToolbar, setShowActionsToolbar] = useState(false);
  const [promptText, setPromptText] = useState('');
  const disableBrush = promptText !== '';
  const [isLoading, setIsLoading] = useState(false);
  const slideDirectionRef = useRef<'left' | 'right'>('right');

  // Refs
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Load and setup image dimensions
  useEffect(() => {
    if (!selectedObject || !showMagicStudio) return;

    const calculateDimensions = () => {
      // Get actual modal width if container is available
      // Otherwise use a fallback
      let modalWidth = window.innerWidth;
      const containerHeight =
        containerRef.current?.parentElement?.clientHeight || window.innerHeight;

      // If container reference is available, use its parent's width
      if (containerRef.current?.parentElement) {
        modalWidth = containerRef.current.parentElement.clientWidth;
      }

      // From Figma spec
      const MAX_WIDTH = Math.min(modalWidth, 526);
      // We need to account for other elements in the modal when setting max height
      // Approximately: total height - (header + toolbar + chat section + actions)
      const availableHeight = Math.max(containerHeight, 100);
      const MAX_HEIGHT = Math.min(availableHeight, 404);

      let newWidth = selectedObject.metadata.originalWidth;
      let newHeight = selectedObject.metadata.originalHeight;
      const aspectRatio = newWidth / newHeight;

      if (newWidth > MAX_WIDTH) {
        newWidth = MAX_WIDTH;
        newHeight = newWidth / aspectRatio;
      }

      if (newHeight > MAX_HEIGHT) {
        newHeight = MAX_HEIGHT;
        newWidth = newHeight * aspectRatio;
      }

      return {
        width: Math.round(newWidth),
        height: Math.round(newHeight),
      };
    };

    // Initial dimensions
    const initialDimensions = calculateDimensions();
    setCanvasSize(initialDimensions);

    // Setup canvas
    if (canvasRef.current) {
      canvasRef.current.width = initialDimensions.width;
      canvasRef.current.height = initialDimensions.height;

      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, initialDimensions.width, initialDimensions.height);
      }
    }

    // Add resize listener that updates canvasSize state
    const handleResize = () => {
      const newDimensions = calculateDimensions();
      setCanvasSize(newDimensions);

      if (canvasRef.current) {
        canvasRef.current.width = newDimensions.width;
        canvasRef.current.height = newDimensions.height;

        // Redraw anything that was on the canvas
        const ctx = canvasRef.current.getContext('2d');
        if (ctx) {
          ctx.clearRect(0, 0, newDimensions.width, newDimensions.height);
        }
      }
    };

    window.addEventListener('resize', handleResize);

    if (!currentImage) {
      if (selectedObject?.metadata?.file) {
        const originalImage: ImageResponse = {
          id: selectedObject.metadata.blockId,
          file: selectedObject.metadata.file,
          tags: [],
          colors: [],
          boards: [],
          hubs: [],
          created_at: new Date(),
          position_x: selectedObject.x,
          position_y: selectedObject.y,
          block_type: selectedObject?.metadata.imageType,
        };
        addNewImage(originalImage);
      }
    }

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [selectedObject, showMagicStudio]);

  const startDrawing = (
    e:
      | React.MouseEvent<HTMLCanvasElement>
      | React.TouchEvent<HTMLCanvasElement>,
  ) => {
    setIsDrawing(true);
    draw(e);
  };

  const stopDrawing = () => {
    setIsDrawing(false);
  };

  // Update cursor size when brushSize changes directly in the slider onChange
  const updateBrushSize = useCallback((values: number[]) => {
    const newSize = values[0];
    setBrushSize(newSize);
  }, []);

  const extractTouchCoordinates = useCallback(
    (e: React.TouchEvent<HTMLCanvasElement>) => {
      const touch = e.touches[0];

      return {
        clientX: touch.clientX,
        clientY: touch.clientY,
        pageX: touch.clientX + (window.scrollX || 0),
        pageY: touch.clientY + (window.scrollY || 0),
      };
    },
    [],
  );

  const draw = (
    e:
      | React.MouseEvent<HTMLCanvasElement>
      | React.TouchEvent<HTMLCanvasElement>,
  ) => {
    if (!canvasRef.current) return;
    setHasDrawn(true);

    // Handle mouse vs touch events differently
    let pageX: number, pageY: number;
    const rect = canvasRef.current.getBoundingClientRect();

    if ('touches' in e) {
      // It's a touch event
      const coords = extractTouchCoordinates(e);
      pageX = coords.pageX;
      pageY = coords.pageY;
    } else {
      pageX = e.pageX;
      pageY = e.pageY;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    const x = (pageX - window.scrollX - rect.left) * scaleX;
    const y = (pageY - window.scrollY - rect.top) * scaleY;

    const scaledBrushSize = brushSize * scaleX;

    if (maskMode === 'add') {
      ctx.fillStyle = MASK_CONFIG.FILL_COLOR;
      ctx.globalAlpha = MASK_CONFIG.FILL_OPACITY;
      ctx.beginPath();
      ctx.arc(x, y, scaledBrushSize, 0, Math.PI * 2);
      ctx.fill();
      ctx.globalAlpha = 1.0;
    } else {
      ctx.globalCompositeOperation = 'destination-out';
      ctx.fillStyle = MASK_CONFIG.ERASE_COLOR;
      ctx.beginPath();
      ctx.arc(x, y, scaledBrushSize, 0, Math.PI * 2);
      ctx.fill();
      ctx.globalCompositeOperation = 'source-over';
    }
  };

  const clearMask = () => {
    if (!canvasRef.current) return;
    setHasDrawn(false);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const createMaskJpeg = async (): Promise<string | null> => {
    if (!canvasRef.current || !selectedObject) return null;

    const image = new Image();

    image.src = currentImage
      ? currentImage.file.full_size
      : selectedObject.metadata.file.full_size;

    return new Promise((resolve) => {
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          resolve(null);
          return;
        }

        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;

        ctx.drawImage(
          canvasRef.current!,
          0,
          0,
          canvasRef.current!.width,
          canvasRef.current!.height,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
        );

        const dataUrl = canvas.toDataURL('image/png');
        const base64Data = dataUrl.split(',')[1];
        resolve(base64Data);
      };
    });
  };

  const handleMouseLeave = useCallback(() => {
    stopDrawing();
  }, []);

  const resetState = () => {
    setHasDrawn(false);
    setShowActionsToolbar(false);
    setPromptText('');

    setMaskMode('add');
    setBrushSize(MASK_CONFIG.DEFAULT_BRUSH_SIZE);
    setIsDrawing(false);
    clearMask();

    if (selectedObject) {
      const originalImage: ImageResponse = {
        id: selectedObject.metadata.blockId,
        file: selectedObject.metadata.file,
        tags: [],
        colors: [],
        boards: [],
        hubs: [],
        created_at: new Date(),
        position_x: selectedObject.x,
        position_y: selectedObject.y,
        block_type: selectedObject?.metadata.imageType,
      };
      resetAndAddImage(originalImage);
    }
  };

  const handleFixWithVai = () => {
    if (isLoading) return;
    if (!promptText.trim()) {
      startedSnack({
        label: 'Tell Vai what you want to fix first',
        close: true,
      });
      return;
    }

    handelMagicStudioFixerApi(promptText.trim());
  };

  const handleMagicEraserApi = async () => {
    if (isLoading) return;
    if (!hasDrawn) {
      startedSnack({
        label: 'Oops, use the brush on the image first then try again',
        close: true,
      });
      return;
    }

    const maskImageData = await createMaskJpeg();
    if (!maskImageData || !selectedObject) return;

    try {
      setIsLoading(true);
      startedSnack({
        label: 'Magic in progress...',
        close: true,
      });

      const blockResponse = await magicStudioApi({
        blockId: currentImage
          ? currentImage.id
          : selectedObject.metadata.blockId,
        designId: designId,
        dataURL: maskImageData,
      });

      addNewImage(blockResponse.data);
      setShowActionsToolbar(true);
      setHasDrawn(false);
      clearMask();
    } catch (error) {
      startedSnack({
        label: 'Oops, something went wrong',
        close: true,
        action: {
          label: 'Try again',
          action: () => {
            handleMagicEraserApi();
          },
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handelMagicStudioFixerApi = async (prompt: string) => {
    try {
      setIsLoading(true);
      startedSnack({
        label: 'Magic in progress...',
        close: true,
      });

      if (!selectedObject) return;

      const blockResponse = await magicStudioApi({
        blockId: currentImage
          ? currentImage.id
          : selectedObject.metadata.blockId,
        designId: designId,
        prompt: prompt,
      });

      addNewImage(blockResponse.data);
      setShowActionsToolbar(true);
      setHasDrawn(true);
      clearMask();
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data?.state?.status == 'bad_prompt'
      ) {
        startedSnack({
          label:
            'Try being more descriptive with what you want Vai to fix or change',
          close: true,
        });
      } else {
        startedSnack({
          label: 'Oops, something went wrong',
          close: true,
          action: {
            label: 'Try again',
            action: () => {
              handelMagicStudioFixerApi(prompt);
            },
          },
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const ensureRestCloseMagicStudio = () => {
    resetState();
    reset();
    closedMagicStudio();
  };

  const cancelChanges = () => resetState();

  const applyChanges = () => {
    if (!selectedObject || !currentImage) return;

    updateImage({
      id: selectedObject.id,
      metadata: {
        ...selectedObject.metadata,
        blockId: currentImage.id,
        file: currentImage.file,
        width: selectedObject.metadata.width,
        height: selectedObject.metadata.height,
      },
    });

    resetState();
    closedMagicStudio();
  };

  const undo = () => {
    slideDirectionRef.current = 'left';
    undoHistory();
  };

  const redo = () => {
    slideDirectionRef.current = 'right';
    redoHistory();
  };

  return (
    <VaiModalProvider
      showModal={showMagicStudio}
      closeModal={ensureRestCloseMagicStudio}
    >
      <VaiModal
        style={
          {
            ...generateConfigCssVariables,
          } as React.CSSProperties
        }
      >
        <div className={styles.header}>
          <div className={styles.headerLeft}>
            <VaiColored />
            <TypographyPoppins
              type="title"
              size="M"
              className={styles.headerText}
            >
              Vai in Studio
            </TypographyPoppins>
          </div>
          <button
            tabIndex={0}
            className={styles.closeButton}
            onClick={() => ensureRestCloseMagicStudio()}
          >
            <Icon name="sprite/plus" className={styles.iconRotate45} />
          </button>
        </div>

        <div className={styles.contentWrapper}>
          <div className={styles.toolbar}>
            <div className={styles.toolbarInner}>
              <div className={styles.brushSizeLabel}>
                <TypographyPoppins type="body" size="M">
                  Brush size
                </TypographyPoppins>
              </div>
              <Slider
                defaultValue={[brushSize]}
                minValue={10}
                maxValue={MASK_CONFIG.MAX_BRUSH_SIZE}
                step={1}
                onChange={updateBrushSize}
                disabled={disableBrush}
              />
              <div className={styles.divider}></div>
              <SegmentedButton
                buttonStyle={styles.segmentedButtonStyle}
                onClick={() => {
                  const newMode = maskMode === 'add' ? 'erase' : 'add';
                  setMaskMode(newMode);
                }}
                isSelected={maskMode !== 'add'}
                label="Erase"
                isDisabled={!hasDrawn || disableBrush}
              />
              <div className={styles.divider}></div>
              <SegmentedButton
                buttonStyle={styles.segmentedButtonStyle}
                onClick={clearMask}
                end
                isDisabled={!hasDrawn || disableBrush}
                label="Clear all"
              />
            </div>
          </div>

          <div className={styles.magicEraserButton}>
            <VaiButton
              variant="primary"
              onClick={() => handleMagicEraserApi()}
              disabled={isLoading || disableBrush}
              style={{ padding: '8px 16px' }}
            >
              <div className={styles.magicEraserIcon}>
                <MagicEraserIcon width={24} height={24} />
              </div>
              <VaiButtonText className={styles.eraserText}>
                Magic eraser
              </VaiButtonText>
            </VaiButton>
          </div>

          <div className={styles.canvasContainer}>
            <div ref={containerRef} className={styles.canvasWrapper}>
              <AnimatePresence initial={false}>
                <motion.img
                  className={styles.imageContainer}
                  style={{
                    width: canvasSize.width,
                    height: canvasSize.height,
                  }}
                  key={
                    currentImage?.file.full_size ||
                    selectedObject?.metadata.file.full_size
                  }
                  src={
                    currentImage?.file.full_size ||
                    selectedObject?.metadata.file.full_size
                  }
                  initial={{
                    x: slideDirectionRef.current === 'right' ? '150%' : '-150%',
                    opacity: 0,
                    scale: 0.8,
                  }}
                  animate={{ x: 0, opacity: 1, scale: 1 }}
                  exit={{
                    x: slideDirectionRef.current === 'right' ? '-150%' : '150%',
                    opacity: 0,
                    scale: 0.8,
                  }}
                  transition={{ duration: 0.3, bounce: 0.15, type: 'spring' }}
                />
              </AnimatePresence>
              <canvas
                data-brush-size={brushSize}
                style={
                  {
                    pointerEvents: disableBrush ? 'none' : 'auto',
                  } as React.CSSProperties
                }
                ref={canvasRef}
                className={cn(styles.canvas, styles.canvasHover)}
                width={canvasSize.width}
                height={canvasSize.height}
                onMouseLeave={handleMouseLeave}
                onMouseDown={startDrawing}
                onMouseMove={(e) => {
                  if (isDrawing) draw(e);
                }}
                onMouseUp={stopDrawing}
                onTouchStart={(e) => {
                  e.preventDefault();
                  setIsDrawing(true);
                  draw(e);
                }}
                onTouchMove={(e) => {
                  if (isDrawing) draw(e);
                }}
                onTouchEnd={() => stopDrawing()}
              />
              {isLoading && (
                <div className={styles.loadingOverlay}>
                  <div
                    className={styles.loadingInner}
                    style={{
                      width: canvasSize.width,
                      height: canvasSize.height,
                    }}
                  >
                    <div className={styles.customSpinner} />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.chatContainer}>
            <div className={styles.chatContainerFirstRow}>
              <TypographyPoppins
                className={styles.vaiText}
                type={'body'}
                size={'L'}
              >
                Tell Vai what to fix:
              </TypographyPoppins>
            </div>
            <div className={styles.chatInputContainer}>
              <textarea
                className={styles.chatInput}
                placeholder="For example, you can add, remove, or change the objects in the image."
                value={promptText}
                onChange={(e) => {
                  clearMask();
                  setPromptText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    handleFixWithVai();
                  }
                }}
                disabled={isLoading}
              />
            </div>
            <div className={styles.chatContainerLastRow}>
              <VaiButton
                variant="primary"
                onClick={() => handleFixWithVai()}
                disabled={isLoading}
              >
                <VaiButtonText>Fix with Vai</VaiButtonText>
              </VaiButton>
            </div>
          </div>
          <div className={styles.actionsContainer}>
            {showActionsToolbar && (
              <div className={styles.actionsToolbar}>
                <div className={styles.toolbarInner}>
                  <SegmentedButton
                    buttonStyle={styles.segmentedButtonStyle}
                    onClick={cancelChanges}
                    label="Reset"
                    isDisabled={isLoading}
                  />
                  <div className={styles.divider}></div>
                  <SegmentedButton
                    buttonStyle={styles.segmentedButtonStyle}
                    onClick={undo}
                    icon={<Icon name="sprite/chevron-left" />}
                    isDisabled={!canUndo() || isLoading}
                  />
                  <SegmentedButton
                    buttonStyle={styles.segmentedButtonStyle}
                    onClick={redo}
                    icon={<Icon name="sprite/chevron-right" />}
                    isDisabled={!canRedo() || isLoading}
                  />
                  <div className={styles.divider}></div>
                  <SegmentedButton
                    buttonStyle={styles.segmentedButtonStyle}
                    icon={<Icon name="sprite/tick" />}
                    onClick={applyChanges}
                    end
                    label="Apply this"
                    isDisabled={isLoading}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </VaiModal>
    </VaiModalProvider>
  );
};

import { useEffect, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';

import {
  Button,
  ColorMenu,
  Divider,
  FilterChip,
  Modal,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Page } from '@api/designs';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import { useDesign } from '@pages/StudioPage/hooks/useDesign';
import { hexToRgb, isLightColor } from '@src/shared/constants/colours';

import {
  $showPageBackgroundColorSetupModal,
  closedPageBackgroundColorSetupModal,
} from '../model';
import {
  $background,
  $colors,
  $isCustomColor,
  $isOpenColorMenu,
  backgroundSelected,
  colorMenuClosed,
  colorMenuToggled,
} from '../model';

import styles from './styles.module.css';

export const PageBackgroundColorSetupWrapper = ({
  designId,
  pageId,
}: {
  designId: string;
  pageId: string;
}) => {
  const [showPageBackgroundColorSetupModal] = useUnit([
    $showPageBackgroundColorSetupModal,
  ]);
  const { designData } = useStudioDesign(designId);
  const { designQuery, updateDesignCustomColorsMutation } = useDesign({
    designId,
  });
  const [customColors, setCustomColors] = useState<string[]>([]);

  useEffect(() => {
    if (designQuery.data?.custom_colors) {
      setCustomColors(designQuery.data.custom_colors);
    }
  }, [designQuery.data?.custom_colors]);

  const updateCustomColors = (customColor: string) => {
    if (!customColors.includes(customColor)) {
      setCustomColors((prevColors) => {
        const updatedColors = [...prevColors, customColor];
        updateDesignCustomColorsMutation.mutate({
          id: designId,
          custom_colors: updatedColors,
        });
        return updatedColors;
      });
    }
  };

  if (
    !designData ||
    !designData.state?.data ||
    !pageId ||
    designData.state?.type === 'infinite'
  )
    return null;

  const pageData = designData.state?.data.find(
    (page) => page.metadata.id === pageId,
  );

  if (!pageData) return null;

  return (
    <PageBackgroundColorSetup
      key={showPageBackgroundColorSetupModal.toString()}
      page={pageData}
      showPageSetup={showPageBackgroundColorSetupModal}
      designId={designId}
      customColors={customColors}
      updateCustomColors={updateCustomColors}
      pageId={pageId}
    />
  );
};

const PageBackgroundColorSetup = ({
  page,
  showPageSetup,
  designId,
  customColors,
  updateCustomColors,
  pageId,
}: {
  page: Page;
  showPageSetup: boolean;
  designId: string;
  customColors: string[];
  updateCustomColors: (customColors: string) => void;
  pageId: string;
}) => {
  const background = useUnit($background);
  const [updateAllPages, setUpdateAllPages] = useState(false);
  const { updateBackgroundColor } = useStudioDesign(designId);

  useEffect(() => {
    if (background !== page.metadata.backgroundColor) {
      backgroundSelected(page.metadata.backgroundColor);
    }
  }, [page.metadata.backgroundColor]);

  useEffect(() => {
    if (!background) {
      backgroundSelected(page.metadata.backgroundColor);
    }
  }, [background]);

  const saveChanges = async () => {
    updateBackgroundColor(background, updateAllPages, pageId);
    closedPageBackgroundColorSetupModal();
  };

  return (
    <Modal
      handleClose={() => closedPageBackgroundColorSetupModal()}
      showModal={showPageSetup}
    >
      <div className={styles.container}>
        <TypographyPoppins type="headline" size="S" className={styles.title}>
          Background color
        </TypographyPoppins>
        <section className={styles.pageSetupContainer}>
          <Background
            background={page.metadata.backgroundColor}
            customColors={customColors}
            updateCustomColors={updateCustomColors}
          />
        </section>
        <section className={styles.applyToContainer}>
          <Divider className={styles.divider} type="short-line" />
          <div style={{ marginLeft: '12px' }}>
            <TypographyPoppins type="body" size="M" className={styles.labels}>
              Apply to
            </TypographyPoppins>
            <div className={styles.applyToRow}>
              <label htmlFor="this-page" className={styles.radioRow}>
                <input
                  checked={!updateAllPages}
                  onChange={() => setUpdateAllPages(false)}
                  type="radio"
                  name="apply-to"
                  id="this-page"
                  value="this-page"
                  className={styles.radio}
                />
                <TypographyPoppins type="body" size="M" className={styles.text}>
                  this page only
                </TypographyPoppins>
              </label>
              <label htmlFor="all-pages" className={styles.radioRow}>
                <input
                  checked={updateAllPages}
                  onChange={() => setUpdateAllPages(true)}
                  type="radio"
                  name="apply-to"
                  id="all-pages"
                  value="all-pages"
                  className={styles.radio}
                />
                <TypographyPoppins type="body" size="M" className={styles.text}>
                  all pages
                </TypographyPoppins>
              </label>
            </div>
          </div>
        </section>
        <section className={styles.footer}>
          <div className={styles.row}>
            <Button
              label="Cancel"
              type="outlined"
              onClick={() => closedPageBackgroundColorSetupModal()}
            />
            <Button label="Save" type="filled" onClick={saveChanges} />
          </div>
        </section>
      </div>
    </Modal>
  );
};

const Background = ({
  background,
  customColors,
  updateCustomColors,
}: {
  background: string;
  customColors: string[];
  updateCustomColors: (customColors: string) => void;
}) => {
  const isOpenColorMenu = useUnit($isOpenColorMenu);
  const colors = useUnit($colors);
  const selectedBackground = useUnit($background);
  const isCustomColor = useUnit($isCustomColor);

  const getLightColor = () => {
    if (selectedBackground && selectedBackground.includes('#')) {
      return isLightColor(hexToRgb(selectedBackground));
    } else if (!selectedBackground && background.includes('#')) {
      return isLightColor(hexToRgb(background));
    }
  };

  const isGradient =
    (selectedBackground && !selectedBackground.includes('#')) ||
    !background.includes('#');

  return (
    <div className={styles.colorSelector}>
      <TypographyPoppins type="body" size="M" className={styles.labels}>
        Background color
      </TypographyPoppins>
      <Popover open={isOpenColorMenu} onOpenChange={() => colorMenuToggled()}>
        <PopoverTrigger asChild>
          <FilterChip
            trailingIcon={<Icon name="sprite/caret-down" />}
            type="label"
            size="S"
          >
            <div
              style={{
                background: selectedBackground
                  ? selectedBackground
                  : background,
              }}
              className={cn(styles.swatch, {
                [styles.light]: !isGradient && getLightColor(),
                [styles.dark]: !isGradient && !getLightColor(),
              })}
            />
          </FilterChip>
        </PopoverTrigger>
        <PopoverContent align="start">
          <ColorMenu
            colors={colors}
            customColors={customColors}
            updateCustomColors={updateCustomColors}
            background={background}
            selectedBackground={selectedBackground}
            isCustomColor={isCustomColor}
            backgroundSelected={backgroundSelected}
            colorMenuClosed={colorMenuClosed}
            isDebounce
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

import { useHistory, useLocation } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { boardsKeys } from '@src/shared/constants/query-keys';
import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { MakeRootBoard, turnIntoRootBoard } from '../../../../../api/boards';
import {
  BOARD_QUERY,
  BOARDS_OF_HUB_QUERY,
  BOARDS_TREE_QUERY,
} from '../../../../../shared/constants/query-names';

export const useConvertBoardIntoTopLevel = (
  options?: MutateOptions<MakeRootBoard, any, MakeRootBoard>,
) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { pathname } = useLocation();

  const convertBoardMutation = useMutation({
    mutationFn: ({ boardId, parentBoardId }: MakeRootBoard) =>
      turnIntoRootBoard({ boardId, parentBoardId }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_OF_HUB_QUERY, { hub: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: boardsKeys.all,
      });

      if (pathname.includes(variables.boardId)) {
        history.push(`/b/${variables.boardId}`);
      }

      startedSnack({
        label: 'Moved board',
        action: {
          label: 'Go to new location',
          action: () => {
            history.push(`/b/${variables.boardId}`);
          },
        },
        close: true,
      });

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    onError: (err, variables) => {
      startedSnack({
        label: 'Couldn’t move board',
        action: {
          label: 'Try again',
          action: () => {
            convertBoardMutation.mutate({
              boardId: variables.boardId,
            });
          },
        },
        close: true,
      });
    },
  });

  return { convertBoardMutation };
};

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useUnit } from 'effector-react';
import { debounce } from 'lodash';

import {
  ColorMenu,
  Popover,
  PopoverContent,
  PopoverTrigger,
  SegmentedButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { ShapeJSON } from '@api/designs';
import { DesignType } from '@pages/StudioPage/lib/design';

import {
  $background,
  $colors,
  $isCustomColor,
  $isOpenColorMenu,
  backgroundSelected,
  colorMenuClosed,
  colorMenuToggled,
} from './model';

import styles from './styles.module.css';

type ShapeToolbarProps = {
  shape: ShapeJSON;
  updateShape: (updates: Parameters<DesignType['updateShape']>['0']) => void;
  deleteShape: (updates: Parameters<DesignType['deleteObject']>['0']) => void;
  customColors: string[];
  updateCustomColors: (customColors: string) => void;
};

export const ShapeToolbar = ({
  shape,
  updateShape,
  deleteShape,
  customColors,
  updateCustomColors,
}: ShapeToolbarProps) => {
  const {
    id,
    metadata: { fill, stroke, type },
  } = shape;

  const [showColorWheel, setShowColorWheel] = useState(false);
  const colorInputRef = useRef<HTMLInputElement>(null);
  const isOpenColorMenu = useUnit($isOpenColorMenu);
  const colors = useUnit($colors);
  const selectedBackground = useUnit($background);
  const isCustomColor = useUnit($isCustomColor);

  const toggleColorWheel = () => setShowColorWheel((s) => !s);

  useEffect(() => {
    if (showColorWheel) {
      colorInputRef.current?.click();
    }
  }, [showColorWheel]);

  useEffect(() => {
    backgroundSelected(stroke);
  }, [stroke]);

  const selectColor = (color: string) => {
    backgroundSelected(color);
    updateShape({
      id,
      metadata: {
        stroke: color,
      },
    });
  };

  const debouncedUpdateShape = useCallback(
    debounce((newColor: string) => {
      updateShape({
        id,
        metadata: {
          fill: newColor,
        },
      });
    }, 200),
    [id, updateShape],
  );

  const fillShape = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;

    if (!newColor) return;

    debouncedUpdateShape(newColor);
  };

  const onClickDeleteShape = () => {
    deleteShape(id);
  };

  return (
    <>
      <div className={styles.divider}></div>
      {type !== 'line' && type !== 'arrow' && (
        <TooltipRadix description="Fill" side="top">
          <SegmentedButton
            buttonStyle={styles.button}
            icon={
              <>
                <Icon name="sprite/paintbucket" />
                <div className={styles.colorContainer}>
                  <input
                    style={{
                      visibility: showColorWheel ? 'visible' : 'hidden',
                    }}
                    value={fill}
                    ref={colorInputRef}
                    type="color"
                    className={styles.colorWheelInput}
                    onChange={fillShape}
                  />
                </div>
              </>
            }
            onClick={toggleColorWheel}
          />
        </TooltipRadix>
      )}
      <Popover open={isOpenColorMenu} onOpenChange={() => colorMenuToggled()}>
        <PopoverTrigger asChild>
          <div>
            <TooltipRadix description="Stroke" side="top">
              <SegmentedButton
                buttonStyle={styles.button}
                icon={<Icon name="sprite/paintbrush" />}
                onClick={() => isOpenColorMenu}
              />
            </TooltipRadix>
          </div>
        </PopoverTrigger>
        <PopoverContent align="start" side="top">
          <ColorMenu
            colors={colors}
            background={stroke}
            customColors={customColors}
            updateCustomColors={updateCustomColors}
            selectedBackground={selectedBackground}
            isCustomColor={isCustomColor}
            backgroundSelected={selectColor}
            colorMenuClosed={colorMenuClosed}
            isDebounce
          />
        </PopoverContent>
      </Popover>
      <div className={styles.divider}></div>
      <TooltipRadix description="Delete" side="top">
        <SegmentedButton
          end
          buttonStyle={styles.button}
          icon={
            <Icon name="sprite/bin" size={24} color="var(--color-error-40)" />
          }
          onClick={onClickDeleteShape}
        />
      </TooltipRadix>
    </>
  );
};

import React, { useEffect, useState } from 'react';

import { useUnit } from 'effector-react';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import {
  CenteredSpinner,
  Dialog,
  Divider,
  Dropdown,
  IconButton,
  Item,
  Modal,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { useFocusTrap, useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { Block, BlockType } from '@api/services';
import { useAppData } from '@src/AppContext';
import {
  $id,
  boardIdCleared,
  boardIdSelected,
} from '@src/entities/share-sheet/board/model/inviting';
import { useInvitees } from '@src/entities/share-sheet/board/model/queries/use-invitees';
import { SMALL } from '@src/shared/constants/breakpoints';
import { Timeout } from '@src/shared/types';

import { SelectedSticky } from '../../entities/Stickies/SelectedSticky';
import { ArrowLeft } from './ArrowLeft';
import { ArrowRight } from './ArrowRight';
import { Board, BoardSkeleton } from './Board';
import { Close } from './Close';
import { ColourPanelSkeleton, ColourThemes } from './color-themes';
import { themeUnselected } from './color-themes/model/active-theme';
import {
  themeCreationCompleted,
  themePlaceholderCleared,
} from './color-themes/model/create-theme';
import { Copy } from './Copy';
import { CopyFileOtherBoard } from './CopyFileOtherBoard';
import { Download } from './Download';
import { MainImage } from './MainImage';
import {
  $isStickyModeOn,
  $openPanel,
  disabledStickyMode,
  fileCardIdUnselected,
  toggledPanel,
} from './model';
import { useEditColor } from './model/queries/use-edit-swatches';
import { useFileCopies } from './model/queries/use-file-copies';
import { useBlock } from './model/queries/useBlock';
import { $palette, paletteReseted, paletteSelected } from './palette/model';
import { useCreatePaletteCopy } from './palette/model/queries/useCreatePaletteCopy';
import { PaletteOptions } from './palette-options';
import {
  hexUpdated,
  hslUpdated,
  hsvUpdated,
  rgbUpdated,
} from './palette-options/color-codes/model/codes-managment';
import {
  $isOpenPromptDialog,
  $isPaletteModified,
  paletteSaved,
  promptDialogClosed,
  promptDialogOpened,
} from './palette-options/model/options-managment';
import { Source } from './Source';
import { StickiesPanel } from './Stickies/StickiesPanel';

import styles from './styles.module.css';

type File = {
  indexList: Record<string, number>;
  idList: Record<number, string>;
  lastFileIndex: number;
};

// To get accurate types, filelist has an added field called blocks which is an array of blocks, ts doesnt support conditionals on maps so use an interface to extend it
interface FileList extends Map<BlockType | 'blocks', File | Array<Block>> {
  get(key: 'blocks'): Record<string, Block>;
  get(key: BlockType): File;
}

interface FileCardProps {
  fileList?: FileList;
  page?: 'Library' | 'Board';
  blocks?: string[];
}

const FILE_CARD_ANIMATION_DURATION = 0.2;

export const FileCard = ({ page, fileList }: FileCardProps) => {
  // Image ref for the image in the file card
  const imageRef = React.useRef<React.ElementRef<'img'>>(null);
  const fileRef = React.useRef<HTMLDivElement>(null);
  const history = useHistory();
  const location = useLocation();
  const id = location.hash.replace('#/f/', '');
  const { board_id } = useParams<{ board_id?: string }>();

  const { user } = useAppData();

  const [currentFileIndex, setCurrentFileIndex] = useState<
    number | undefined
  >();
  const [lastFileIndex, setLastFileIndex] = useState<number | undefined>();
  const [hasAnimatedInitialLayout, setHasAnimatedInitialLayout] =
    useState(false);
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const [isStickyModeOn, openPanel] = useUnit([$isStickyModeOn, $openPanel]);
  const isOpenPromptDialog = useUnit($isOpenPromptDialog);
  const isPaletteModified = useUnit($isPaletteModified);
  const palette = useUnit($palette);
  const boardId = useUnit($id);

  const { data: block, isLoading } = useBlock(id);
  const { editColor, isPending } = useEditColor(block?.id);
  const { createPaletteCopy } = useCreatePaletteCopy();
  const { role } = useInvitees({
    id: boardId,
  });

  const { width } = useWindowSize();

  const initialBlockData =
    typeof fileList !== 'undefined' ? fileList.get('blocks')[id] : undefined;

  const { fileCopies, isFetchedAfterMount, isFetching } = useFileCopies();

  useEffect(() => {
    let timeout: Timeout | null = null;
    // When the file card is closed, we want to reset the the stick mode state
    if (!block) {
      setHasAnimatedInitialLayout(false);
      disabledStickyMode();
    }

    if (block && !hasAnimatedInitialLayout) {
      // If file card selected set the hasAnimatedInitialLayout to true after 0.2s
      timeout = setTimeout(() => {
        setHasAnimatedInitialLayout(true);
      }, (FILE_CARD_ANIMATION_DURATION + 0.1) * 1000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [block]);

  useEffect(() => {
    if (block && fileList && fileList.get(block.block_type)) {
      const currentIndex = fileList.get(block.block_type).indexList[id];
      const lastIndex = fileList.get(block.block_type).lastFileIndex;
      setCurrentFileIndex(currentIndex);
      setLastFileIndex(lastIndex);
    }
  }, [block, fileList]);

  useEffect(() => {
    if (width <= SMALL) {
      setOpenDropdown(false);
    }
  }, [width, SMALL]);

  useEffect(() => {
    if (block && block.display_color_codes) {
      hexUpdated(block.display_color_codes.hex);
      rgbUpdated(block.display_color_codes.rgb);
      hslUpdated(block.display_color_codes.hsl);
      hsvUpdated(block.display_color_codes.hsv);
    }
  }, [block]);

  useEffect(() => {
    if (block && !isPending && !isPaletteModified) {
      if (block.block_type === 'Colour palette') {
        paletteSelected(block?.custom_palettes[0]);
      }
    }
  }, [block, isPending, isPaletteModified]);

  useEffect(() => {
    if (block && block.boards.length > 0) {
      boardIdSelected(block.boards[0].id);
    }

    return () => boardIdCleared();
  }, [block]);

  const getFileType = () => {
    if (block?.block_type === 'Image') {
      return 'image';
    } else if (block?.block_type === 'Set') {
      return 'design';
    } else {
      return 'palette';
    }
  };
  useFocusTrap({
    key: block ? block.id : '',
    isOpen: block !== undefined,
    ref: fileRef,
  });

  const onHandleClose = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    // HACK there is a weird glitch that the backdrop gets the click event even though the modal is open
    if (isStickyModeOn) return;
    if (event.target === event.currentTarget) {
      fileCardIdUnselected();
      themeUnselected();
      themePlaceholderCleared();
      themeCreationCompleted();
      history.replace(location.pathname);
    }
  };

  const goPrevFile = () => {
    if (isPaletteModified) {
      promptDialogOpened();
      return;
    }

    if (block && fileList && fileList.get(block.block_type)) {
      const files = fileList.get(block.block_type);

      if (currentFileIndex) {
        const prevFile = files?.idList[currentFileIndex - 1];
        disabledStickyMode();
        history.push(`#/f/${prevFile}`);
      } else return;
    }

    themeUnselected();
    themePlaceholderCleared();
    themeCreationCompleted();
  };

  const goNextFile = () => {
    if (isPaletteModified) {
      promptDialogOpened();
      return;
    }

    if (block && fileList && fileList.get(block.block_type)) {
      const files = fileList.get(block.block_type);

      if (
        typeof currentFileIndex !== 'undefined' &&
        currentFileIndex !== lastFileIndex
      ) {
        const nextFile = files?.idList[currentFileIndex + 1];
        disabledStickyMode();
        history.push(`#/f/${nextFile}`);
      } else return;
    }

    themeUnselected();
    themePlaceholderCleared();
    themeCreationCompleted();
  };

  const goToStudio = async () => {
    history.push(`/studio/${block?.id}`);
  };

  const copyLink = async () => {
    const url = new URL(document.location.href);

    try {
      await navigator.clipboard.writeText(url.href);

      startedSnack({
        label: `Copied link to ${getFileType()}`,
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: `Couldn't copy link to ${getFileType()}`,
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const saveChanges = () => {
    if (isPaletteModified && block) {
      editColor({
        block: block.id,
        swatches: palette.swatches,
        theme: palette.theme,
      });
      paletteSaved();
      promptDialogClosed();
    }
  };

  const menuItems: Item<Variant>[] =
    block?.block_type === 'Set'
      ? [
          ...(role === 'Editor' || role === null
            ? [
                {
                  leadingIcon: <Icon name="sprite/studio" />,
                  content: 'Edit in Studio',
                  onClick: goToStudio,
                },
              ]
            : []),
          {
            leadingIcon: <Icon name="sprite/link" size={21} />,
            content: 'Copy link',
            onClick: copyLink,
          },
        ]
      : [
          {
            leadingIcon: <Icon name="sprite/link" size={21} />,
            content: 'Copy link',
            onClick: copyLink,
          },
        ];

  const dialogMenuItems: Item<Variant>[] = [
    {
      content: 'Save as a new copy',
      onClick: () => {
        if (block) {
          createPaletteCopy({
            id: block.id,
            swatches: palette.swatches,
            board: board_id,
          });
        }

        promptDialogClosed();
        paletteReseted();
      },
    },
  ];

  if (!id) return null;

  if (isLoading && !initialBlockData) {
    return (
      <div className={styles.backdrop}>
        <motion.div
          ref={fileRef}
          // layoutId="card-container"
          className={styles.content}
          style={{ width: '100%' }}
        >
          <div className={styles.leftRail} />
          <div style={{ flex: 1, flexDirection: 'row', flexGrow: 1 }}>
            <CenteredSpinner />
          </div>
          <div className={styles.rightRail} />
        </motion.div>
      </div>
    );
  }

  const showDemoHighlight =
    user.meta?.onboarding?.demoMode &&
    user.meta?.onboarding?.workCreatively === 'creative-with-colour' &&
    user.meta?.onboarding?.selectedPath === 'workCreatively' &&
    block?.block_type !== 'Colour palette' &&
    openPanel !== 'colour';

  return (
    <AnimatePresence>
      <motion.div
        ref={fileRef}
        // layoutRoot
        // If initial layout animation has happened don't animate the opacity
        // initial={{ opacity: 0 }}
        aria-modal
        role="dialog"
        animate={{ opacity: 1 }}
        onClick={onHandleClose}
        className={styles.backdrop}
        transition={{
          type: 'tween',
          duration: 0.2,
        }}
      >
        <motion.div
          // Hack: key is used to force the component to re-render when the file card is closed. This allows the layout animation to not play on file card close, since it will animate to the first component that was opened on click.
          // key={hasAnimatedInitialLayout ? '1' : '2'}
          // layoutId={hasAnimatedInitialLayout ? undefined : `file-card-${id}`}
          // layout={hasAnimatedInitialLayout ? true : undefined}
          // layout
          // layoutId="card-container"
          className={styles.content}
          // initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            type: 'tween',
            duration: 0.25,
          }}
          // transition={{
          //   type: 'tween',
          //   duration: FILE_CARD_ANIMATION_DURATION,
          // }}
        >
          {page !== 'Board' &&
            typeof currentFileIndex !== 'undefined' &&
            currentFileIndex > 0 && <ArrowLeft goPrevFile={goPrevFile} />}
          <div className={styles.leftRail}>
            <div className={styles.top}>
              <Copy block={block} getFileType={getFileType} />
              <Download block={block} getFileType={getFileType} />
              <Dropdown
                open={isOpenDropdown}
                onOpenChange={() => {
                  if (!block?.is_archived) {
                    setOpenDropdown(!isOpenDropdown);
                  }
                }}
              >
                <Dropdown.Menu
                  trigger={
                    <IconButton
                      type="unfilled"
                      className={styles.iconButton}
                      icon={<Icon name="sprite/3-dot-menu" />}
                      onClick={() => {}}
                      isSelected={isOpenDropdown}
                      isDisabled={block?.is_archived}
                    />
                  }
                  side="right"
                  density="-2"
                >
                  {menuItems.map((item, index) => (
                    <Dropdown.MenuItem key={index} item={item} />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className={styles.bottom}>
              <Source block={block} />
            </div>
          </div>

          <div
            className={styles.centerContent}
            id={
              block?.block_type === 'Colour palette'
                ? undefined
                : 'file-card-right-rail'
            }
          >
            <MainImage
              key={block?.id}
              imageRef={imageRef}
              block={initialBlockData ?? block}
              role={role}
            />
            {showDemoHighlight ? (
              <div
                className={styles.demoIndicator}
                onClick={() => toggledPanel('colour')}
              ></div>
            ) : null}
            <div className={styles.rightCards}>
              {block ? (
                <ColourThemes block={block} role={role} />
              ) : (
                <ColourPanelSkeleton />
              )}
              {block?.block_type === 'Colour palette' && (
                <PaletteOptions
                  fileId={block.id}
                  editColor={editColor}
                  role={role}
                  isArchived={block?.is_archived}
                />
              )}
              <StickiesPanel
                imageRef={imageRef}
                blockId={id}
                role={role}
                isArchived={block?.is_archived}
              />
            </div>
          </div>

          <div className={styles.rightRail}>
            <Close />
            <CopyFileOtherBoard role={role} isArchived={block?.is_archived} />
            <Divider type="long-line" className={styles.divider} />
            <ul className={styles.boardList}>
              {!block || isFetching ? (
                <>
                  <BoardSkeleton />
                  <BoardSkeleton />
                  <BoardSkeleton />
                </>
              ) : (
                isFetchedAfterMount &&
                fileCopies &&
                fileCopies.boards.length > 0 &&
                fileCopies.boards.map((board) => (
                  <Board
                    key={board.board_id}
                    id={board.board_id}
                    name={board.board_name}
                    blockId={board.block_id}
                    boardIdAddedCopy={block.boards[0]?.id}
                    isArchvied={block.is_archived}
                  />
                ))
              )}
            </ul>
          </div>
          {page !== 'Board' && currentFileIndex !== lastFileIndex && (
            <ArrowRight goNextFile={goNextFile} />
          )}
        </motion.div>
        <SelectedSticky blockId={id} role={role} />
        <Modal handleClose={promptDialogClosed} showModal={isOpenPromptDialog}>
          <Dialog
            className={styles.dialog}
            headline="Save changes?"
            description="You’ve made changes to these palette files. Want to save these edits?"
            action={saveChanges}
            cancelLabel="Discard"
            actionLabel="Save changes to this file"
            closeAction={promptDialogClosed}
            menuItems={dialogMenuItems}
          />
        </Modal>
      </motion.div>
    </AnimatePresence>
  );
};

import cn from 'classnames';
import { matchPath } from 'react-router';

import { AccountNavigation } from './components/navigation';
import { AccountRouter } from './components/router';

import styles from './style.module.css';

export const AccountPage = () => {
  const match = matchPath(location.pathname, {
    path: '/account/bin',
    exact: true,
  });

  return (
    <div className={styles.container}>
      <AccountNavigation />
      <div
        className={cn(styles.routerContainer, {
          [styles.binPage]: match?.isExact,
        })}
      >
        <AccountRouter />
      </div>
    </div>
  );
};

import cn from 'classnames';

import { NavItemList } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { BoardItem } from '@api/bin';
import { useBoards } from '@pages/BoardPage/model/queries/useBoards';
import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { deletePermanentlyModalOpened } from '@src/entities/delete-modals/model';

import { Menu } from '../menu';
import { Person } from '../person';
import { Time } from '../time';
import { renderChildBoards, renderChildFiles } from './utils';

import styles from '../styles.module.css';

type Props = {
  item: BoardItem;
  indent?: number;
  isNestedItem?: boolean;
};

export const Board = ({ item, indent = 1, isNestedItem = true }: Props) => {
  const { putBoardBack } = useBoards({});

  const restore = () => {
    putBoardBack({
      item:
        item.child.length > 0
          ? { file: item.item_type, id: item.board.id, type: 'group' }
          : { file: item.item_type, id: item.board.id, type: 'single' },
    });
  };

  const deletePermanently = () => {
    deletePermanentlyModalOpened(
      item.child.length > 0
        ? { file: item.item_type, id: item.board.id, type: 'group' }
        : { file: item.item_type, id: item.board.id, type: 'single' },
    );
  };

  return (
    <li
      className={cn(styles.row, {
        [styles.nestedItem]: isNestedItem,
      })}
    >
      <NavItemList
        className={styles.item}
        classNameContent={styles.align}
        leadingIcon={<Icon name="sprite/board" />}
        name={item.board.name}
        breadcrumbs={
          item.board.parent && (
            <Breadcrumbs
              className={styles.breadcrumbs}
              board={item.board.parent}
              isClickable
            />
          )
        }
        date={<Time date={item.deleted_at} isIndent={!item.board.parent} />}
        user={<Person user={item.deleted_by} isIndent={!item.board.parent} />}
        menu={
          <Menu
            item={item}
            restore={restore}
            deletePermanently={deletePermanently}
          />
        }
        indent={indent}
        onClick={() => {}}
        isSelected={false}
      >
        {item.child &&
          renderChildFiles({ children: item.child, indent: indent + 1 })}
        {item.child &&
          renderChildBoards({ children: item.child, indent: indent + 2 })}
      </NavItemList>
    </li>
  );
};

import { createEvent, createStore, sample } from 'effector';

import { backgroundCleared } from './ui/page-setup/model';

export const $showPageSetupModal = createStore(false);

export const openedPageSetupModal = createEvent();
export const closedPageSetupModal = createEvent();

$showPageSetupModal
  .on(openedPageSetupModal, () => true)
  .on(closedPageSetupModal, () => false);

// Clear background when page setup modal is closed
sample({
  clock: closedPageSetupModal,
  source: {
    showPageSetupModal: $showPageSetupModal,
  },
  filter: ({ showPageSetupModal }) => !showPageSetupModal,
  target: backgroundCleared,
});

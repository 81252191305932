import { useState } from 'react';

import { Icon } from '@visualist/icons';

import { Page } from '@api/designs';
import { WHITE_ONE_BY_ONE_BASE64_IMAGE } from '@pages/StudioPage/constants';

import { ContextMenu, ContextMenuGroups } from './context-menu';

import styles from './styles.module.css';

interface FrameProps {
  frameId: string;
  index: number;
  pageMetaData: Page;
  onClick: () => void;
  handleAddPage: (index: number, id: string) => void;
  handleCopyPage: (pageId: string) => void;
  handleCutPage: (pageId: string) => void;
  handleDeletePage: (pageId: string) => void;
  handlePastePage: (index: number) => void;
  handleDuplicatePage: (pageId: string) => void;
  handleUpdatePageSize: (pageId: string) => void;
  handleUpdatePageBackground: (pageId: string) => void;
  image?: string;
  className?: string;
}

export const Frame = ({
  frameId,
  className,
  onClick,
  image,
  index,
  pageMetaData,
  handleAddPage,
  handleCopyPage,
  handleCutPage,
  handleDeletePage,
  handlePastePage,
  handleDuplicatePage,
  handleUpdatePageSize,
  handleUpdatePageBackground,
}: FrameProps) => {
  const [isOpenContextMenu, setOpenContextMenu] = useState(false);

  // const longPressBind = useLongPress((e) => {
  //   e.preventDefault();

  //   setOpenContextMenu(true);
  // });

  // The draggable object is consuming the clicks this is a work around
  // However the long press doesn't work well.
  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (e.button === 0) {
      const startX = e.clientX;
      const startY = e.clientY;
      const threshold = 5;

      const handleMouseUp = (upEvent: any) => {
        const diffX = Math.abs(upEvent.clientX - startX);
        const diffY = Math.abs(upEvent.clientY - startY);

        // If the mouse barely moved, consider it a click rather than a drag
        if (diffX < threshold && diffY < threshold) {
          onClick();
        }

        window.removeEventListener('mouseup', handleMouseUp);
      };

      window.addEventListener('mouseup', handleMouseUp);
    }
  };

  const handleContextMenu = (e: any) => {
    e.preventDefault();
    setOpenContextMenu(true);
  };

  const menuItems: ContextMenuGroups = {
    pageOptions: [
      {
        key: 'change-background',
        content: 'Change background',
        leadingIcon: <Icon name="sprite/color-drop" />,
        onClick: () => handleUpdatePageBackground(frameId),
      },
      {
        key: 'page-size',
        content: 'Page size',
        leadingIcon: <Icon name="sprite/page-landscape" />,
        onClick: () => handleUpdatePageSize(frameId),
      },
      {
        key: 'switch-to-infinity',
        content: 'Switch to infinity canvas',
        leadingIcon: <Icon name="sprite/infinite-canvas" />,
      },
    ],
    editOptions: [
      {
        key: 'new-page',
        content: 'New page',
        leadingIcon: <Icon name="sprite/plus" />,
        onClick: () => handleAddPage(index + 1, frameId),
      },
      {
        key: 'cut',
        content: 'Cut',
        leadingIcon: <Icon name="sprite/cut" />,
        onClick: () => handleCutPage(frameId),
      },
      {
        key: 'copy',
        content: 'Copy',
        leadingIcon: <Icon name="sprite/copy-studio" />,
        onClick: () => handleCopyPage(frameId),
      },
      {
        key: 'paste',
        content: 'Paste',
        leadingIcon: <Icon name="sprite/paste" />,
        onClick: () => handlePastePage(index + 1),
      },
      {
        key: 'duplicate',
        content: 'Duplicate',
        leadingIcon: <Icon name="sprite/duplicate" />,
        onClick: () => handleDuplicatePage(frameId),
      },
    ],
    dangerOptions: [
      {
        key: 'delete',
        content: (
          <span
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              wordBreak: 'break-word',
              color: 'var(--color-error)',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '24px',
              letterSpacing: '0.08px',
              fontFamily: 'var(--font-poppins)',
            }}
          >
            Delete
          </span>
        ),
        leadingIcon: <Icon color="var(--color-error)" name="sprite/bin" />,
        onClick: () => handleDeletePage(frameId),
      },
    ],
  };
  return (
    <>
      <div
        className={`${styles.frameWrapper} ${className || ''}`}
        onMouseDown={handleMouseDown}
        onContextMenu={handleContextMenu}
      >
        <div className={styles.overlay}></div>
        <div>
          <img
            style={
              {
                '--img-width': pageMetaData.metadata.width,
                '--img-height': pageMetaData.metadata.height,
              } as React.CSSProperties
            }
            src={image || WHITE_ONE_BY_ONE_BASE64_IMAGE}
            alt="Preview"
          />
        </div>
        <div className={styles.contextMenuContainerFrame}>
          <ContextMenu
            isOpen={isOpenContextMenu}
            onOpenChange={setOpenContextMenu}
            menuItems={menuItems}
            side="top"
            sideOffset={28}
            align="start"
            alignOffset={4}
          />
        </div>
      </div>
    </>
  );
};

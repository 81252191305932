import { useUnit } from 'effector-react';
import { createPortal } from 'react-dom';

import { SideSheet } from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';

import { Comment } from '@api/stickies';
import { Role } from '@api/users';
import {
  $selectedStickyId,
  selectedSticky,
} from '@src/entities/Stickies/model';
import useStickies from '@src/shared/queries/useStickies';

import { DeleteReplyModal } from '../DeleteReplyModal';
import { DeleteStickyModal } from '../DeleteStickyModal';
import { ReplyInput } from '../ReplyInput';
import { StickyCardWithReplies } from '../StickyCardWithReplies';
import { StickyType } from '../types';

import styles from './styles.module.css';

type Props = {
  blockId: string;
  role?: Role | null;
};

export const SelectedSticky = ({ blockId, role }: Props) => {
  const [selectedStickyId] = useUnit([$selectedStickyId]);
  const { selectedSticky, replies, repliesQuery } = useStickies(
    blockId,
    selectedStickyId,
  );

  const { isMobile } = useWindowSize();

  return (
    <>
      {isMobile ? (
        <ModalStickyView
          sticky={selectedSticky}
          loadingReplies={repliesQuery.isLoading}
          replies={replies}
          blockId={blockId}
          role={role}
        />
      ) : (
        <SideSheetStickyView
          sticky={selectedSticky}
          replies={replies}
          loadingReplies={repliesQuery.isLoading}
          blockId={blockId}
          role={role}
        />
      )}
      <DeleteStickyModal blockId={blockId} />
      {selectedStickyId ? (
        <DeleteReplyModal stickyId={selectedStickyId} blockId={blockId} />
      ) : null}
    </>
  );
};

type StickyViewProps = {
  sticky?: StickyType;
  replies?: Comment[];
  loadingReplies: boolean;
  blockId: string;
  role?: Role | null;
};

const ModalStickyView = ({
  sticky,
  replies,
  loadingReplies,
  blockId,
  role,
}: StickyViewProps) => {
  if (!sticky) return null;

  return createPortal(
    <div className={styles.modalRoot}>
      <div className={styles.backdrop} />
      <div
        className={styles.modalContainer}
        onClick={(e) => {
          if (e.target !== e.currentTarget) return;
          selectedSticky(null);
        }}
      >
        <StickyCardWithReplies
          sticky={sticky}
          replies={replies}
          loadingReplies={loadingReplies}
          blockId={blockId}
          role={role}
        />
      </div>
      {sticky ? (
        <div className={styles.modalThreadInputContainer}>
          <ReplyInput blockId={blockId} sticky={sticky} role={role} />
        </div>
      ) : undefined}
    </div>,
    document.body,
  );
};

const SideSheetStickyView = ({
  sticky,
  replies,
  loadingReplies,
  blockId,
  role,
}: StickyViewProps) => {
  const closeSheet = () => selectedSticky(null);

  return (
    <SideSheet
      handleBackdropClick={closeSheet}
      showSheet={!!sticky}
      className={styles.sideSheetContainer}
      type="large"
      backdropVisible={false}
      closeSideSheet={closeSheet}
    >
      <SideSheet.Heading closeSideSheet={closeSheet}>{null}</SideSheet.Heading>
      <div className={styles.stickiesSideSheetContainer}>
        {sticky ? (
          <StickyCardWithReplies
            sticky={sticky}
            replies={replies}
            loadingReplies={loadingReplies}
            blockId={blockId}
            role={role}
          />
        ) : null}
      </div>
      {sticky ? (
        <ReplyInput blockId={blockId} sticky={sticky} role={role} />
      ) : undefined}
    </SideSheet>
  );
};

import { useUnit } from 'effector-react';

import { Dialog, Modal } from '@visualist/design-system/src/components/v2';

import { getCredits } from '@api/hubs';
import { useDeletedItems } from '@pages/AccountPage/components/bin/model/queries/use-deleted-items';
import { Plan } from '@pages/AccountPage/components/types';
import { useBoards } from '@pages/BoardPage/model/queries/useBoards';
import { useDeleteBDC } from '@pages/FileCard/model/queries/useDeleteBDC';
import { settingsSheetClosed } from '@pages/HubPage/components/SettingsSheet/model';
import { useHubs } from '@pages/HubPage/model/queries/useHubs';
import { REFUNDED_HUB_CREDITS_QUERY } from '@src/shared/constants';
import { useBilling } from '@src/shared/queries/useBilling';
import { useQuery } from '@tanstack/react-query';

import { useDeleteDoc } from '../doc/queries/useDeleteDoc';
import {
  $boardDeleteModalState,
  $docId,
  $hubDeleteModalState,
  $isOpenBinDeletionDialog,
  $isOpenDocDeleteModal,
  $permanentlyDeleteModal,
  binDeletionDialogClosed,
  deleteBoardModalClosed,
  deleteDocModalClosed,
  deleteHubModalClosed,
  deletePermanentlyModalClosed,
} from './model';

type Period = '7 days' | '30 days' | '6 months';

export const DeleteModals = () => {
  const [
    hubDeleteModalState,
    boardDeleteModalState,
    isOpenDocDeleteModal,
    isOpenBinDeletionDialog,
    permanentlyDeleteModal,
  ] = useUnit([
    $hubDeleteModalState,
    $boardDeleteModalState,
    $isOpenDocDeleteModal,
    $isOpenBinDeletionDialog,
    $permanentlyDeleteModal,
  ]);

  const {
    query: { data },
  } = useBilling();

  const period = getPeriod(data?.membershipTierInfo.plan);

  return (
    <>
      {/* Hub Soft Delete */}
      <Modal
        handleClose={deleteHubModalClosed}
        showModal={hubDeleteModalState !== null}
      >
        {hubDeleteModalState !== null ? (
          <HubDeleteModal hubToDelete={hubDeleteModalState} period={period} />
        ) : null}
      </Modal>
      {/* Board Soft delete */}
      <Modal
        handleClose={deleteBoardModalClosed}
        showModal={boardDeleteModalState !== null}
      >
        {boardDeleteModalState !== null ? (
          <BoardDeleteModal
            boardToDelete={boardDeleteModalState}
            period={period}
          />
        ) : null}
      </Modal>
      {/* Doc Soft Delete */}
      <Modal
        handleClose={deleteDocModalClosed}
        showModal={isOpenDocDeleteModal}
      >
        <DocDeleteModal period={period} />
      </Modal>
      {/* Bin (permanently deletion of all items) */}
      <Modal
        handleClose={binDeletionDialogClosed}
        showModal={isOpenBinDeletionDialog}
      >
        <BinDeleteModal />
      </Modal>
      {/* Bin (permanently deletion) */}
      <Modal
        handleClose={deletePermanentlyModalClosed}
        showModal={permanentlyDeleteModal !== null}
      >
        <PermanentlyDeleteModal />
      </Modal>
    </>
  );
};

const BoardDeleteModal = ({
  boardToDelete,
  period,
}: {
  boardToDelete: {
    hubId?: string;
    boardId: string;
    parentBoardId?: string;
    level: number;
  };
  period?: Period;
}) => {
  const { removeBoard } = useBoards({
    hub_id: boardToDelete.hubId,
    handleSuccessRemoveBoard: () => {
      deleteBoardModalClosed();
    },
  });

  const handleRemoveBoard = () =>
    removeBoard({
      id: boardToDelete.boardId,
      parentId: boardToDelete.parentBoardId,
      hubId: boardToDelete.hubId,
      level: boardToDelete.level,
    });

  return (
    <Dialog
      headline={`Move this board 
      to bin?`}
      description={`We'll move this board and any of its contents to the bin, but you have ${period} to restore them.`}
      action={handleRemoveBoard}
      actionLabel="Confirm"
      closeAction={deleteBoardModalClosed}
    />
  );
};

const HubDeleteModal = ({
  hubToDelete,
  period,
}: {
  hubToDelete: {
    hubId: string;
    name: string;
  };
  period?: Period;
}) => {
  const { hubId } = hubToDelete;

  const { data: creditsToRefund } = useQuery({
    queryKey: [REFUNDED_HUB_CREDITS_QUERY, { hubId }],
    queryFn: () => getCredits({ hubId }),
  });

  const getSupportingText = () => {
    if (creditsToRefund && Math.abs(creditsToRefund.credits_refunded) > 0) {
      return `You’ll be refunded ${Math.abs(
        creditsToRefund.credits_refunded,
      )} ${
        Math.abs(creditsToRefund.credits_refunded) === 1 ? 'credit' : 'credits'
      }.`;
    }
  };

  const { removeHub } = useHubs({
    onSuccess: () => {
      // Closes setting sheet if its open
      settingsSheetClosed();
      deleteHubModalClosed();
    },
  });

  const handleRemove = () => removeHub(hubId);

  return (
    <Dialog
      headline={`Move this hub 
      to bin?`}
      description={`We'll move this hub and any of its contents to the bin, but you have ${period} to restore them.`}
      supportingText={getSupportingText()}
      action={handleRemove}
      actionLabel="Confirm"
      closeAction={deleteHubModalClosed}
    />
  );
};

const DocDeleteModal = ({ period }: { period?: Period }) => {
  const docId = useUnit($docId);

  const { removeDocMutation } = useDeleteDoc();

  const handleRemoveDoc = () => {
    if (docId) {
      removeDocMutation.mutate({
        docId,
      });
    }

    deleteDocModalClosed();
  };

  return (
    <Dialog
      headline={`Move this doc 
      to bin?`}
      description={`You have ${period} to restore it.`}
      action={handleRemoveDoc}
      actionLabel="Delete"
      closeAction={deleteDocModalClosed}
    />
  );
};

const BinDeleteModal = () => {
  const { permanentDeleteItems } = useDeletedItems();

  const action = () => {
    permanentDeleteItems();
    binDeletionDialogClosed();
  };

  return (
    <Dialog
      headline="Empty bin now"
      description={`This will immediately delete all items in the bin permanently. You’ll no longer be able to access them.

Are you sure you want to proceed?`}
      action={action}
      actionLabel="I'm sure"
      closeAction={binDeletionDialogClosed}
    />
  );
};

const PermanentlyDeleteModal = () => {
  const permanentlyDeleteModal = useUnit($permanentlyDeleteModal);

  const { permanentDeleteHub } = useHubs();
  const { permanentDeleteBoard } = useBoards({});
  const { permanentDeleteDocMutation } = useDeleteDoc();
  const { permanentDeleteBlock } = useDeleteBDC();

  const description =
    permanentlyDeleteModal?.type === 'single'
      ? `This will immediately delete the item permanently. You’ll no longer be able to access it.

Are you sure you want to proceed?`
      : `This will immediately delete all of these items permanently. You’ll no longer be able to access them.

      Are you sure you want to proceed?`;

  const action = () => {
    if (permanentlyDeleteModal?.file === 'Hub' && permanentlyDeleteModal.id) {
      permanentDeleteHub(permanentlyDeleteModal.id);
    } else if (
      permanentlyDeleteModal?.file === 'Board' &&
      permanentlyDeleteModal.id
    ) {
      permanentDeleteBoard(permanentlyDeleteModal.id);
    } else if (
      permanentlyDeleteModal?.file === 'Doc' &&
      permanentlyDeleteModal.id
    ) {
      permanentDeleteDocMutation.mutate(permanentlyDeleteModal.id);
    } else if (
      permanentlyDeleteModal?.file === 'Block' &&
      permanentlyDeleteModal.id
    ) {
      permanentDeleteBlock(permanentlyDeleteModal.id);
    }

    deletePermanentlyModalClosed();
  };

  return (
    <Dialog
      headline="Delete permanently"
      description={description}
      action={action}
      actionLabel="I'm sure"
      closeAction={deletePermanentlyModalClosed}
    />
  );
};

export const getPeriod = (plan?: Plan) => {
  if (plan === 'free') return '7 days';
  if (plan === 'starter') return '30 days';
  if (plan === 'pro') return '6 months';
};

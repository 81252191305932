import React from 'react';

import { useUnit } from 'effector-react';
import { Stage } from 'konva/lib/Stage';
import { isMobile } from 'react-device-detect';

import {
  SegmentedButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { MAX_ZOOM } from '@pages/StudioPage/constants';
import { $stageState, updatedStage } from '@pages/StudioPage/model';
import { calculateNewStagePosition } from '@pages/StudioPage/utils';

import styles from './styles.module.css';

const ZOOM_SCALE_BY = 0.1;

export const ZoomAndUndoRedoControls = ({
  stage,
  undo,
  redo,
  canRedo,
  canUndo,
}: {
  stage: Stage | null;
  undo: () => void;
  redo: () => void;
  canUndo: boolean;
  canRedo: boolean;
}) => {
  const [stageState] = useUnit([$stageState]);

  const zoomIn = React.useCallback(() => {
    if (!stage?.getSize()) return;

    const { scale, x, y } = calculateNewStagePosition({
      origin: {
        x: stage.getSize().width / 2,
        y: stage.getSize().height / 2,
      },
      previousScale: stageState.scale,
      newScale: stageState.scale + ZOOM_SCALE_BY,
      x: stageState.x,
      y: stageState.y,
    });

    updatedStage({
      scale,
      x,
      y,
    });
  }, [stage, stageState]);

  const zoomOut = React.useCallback(() => {
    if (!stage?.getSize()) return;

    const { scale, x, y } = calculateNewStagePosition({
      origin: {
        x: stage.getSize().width / 2,
        y: stage.getSize().height / 2,
      },
      previousScale: stageState.scale,
      newScale: stageState.scale - ZOOM_SCALE_BY,
      x: stageState.x,
      y: stageState.y,
    });

    if (scale <= MAX_ZOOM) return;

    updatedStage({
      scale,
      x,
      y,
    });
  }, [stage, stageState]);

  if (isMobile) return null;

  return (
    <div className={styles.toolbarSection}>
      <TooltipRadix description="Undo">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={
            <Icon name="sprite/arrow-swipe-left" className={styles.leftTilt} />
          }
          onClick={undo}
          isDisabled={!canUndo}
        />
      </TooltipRadix>
      <TooltipRadix description="Redo">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={
            <Icon
              name="sprite/arrow-swipe-right"
              className={styles.rightTilt}
            />
          }
          onClick={redo}
          isDisabled={!canRedo}
        />
      </TooltipRadix>
      {isMobile ? null : (
        <>
          <TooltipRadix description="Zoom out">
            <SegmentedButton
              buttonStyle={styles.button}
              icon={<Icon name="sprite/zoom-out" />}
              onClick={zoomOut}
              isDisabled={stageState.scale <= 0.1}
            />
          </TooltipRadix>

          <TooltipRadix description="Zoom in">
            <SegmentedButton
              buttonStyle={styles.button}
              icon={<Icon name="sprite/zoom-in" />}
              onClick={zoomIn}
              isDisabled={stageState.scale >= 10}
            />
          </TooltipRadix>
        </>
      )}
    </div>
  );
};

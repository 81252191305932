import { useHistory } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { saveOnboarding } from '@api/account';
import { api } from '@api/services';
import { useAppData, User, UserOnboarding } from '@src/AppContext';
import { enabledLoadingDemoMode } from '@src/model';
import { clearAllTabData } from '@src/shared/utils/clear-all-local-data';
import { hideDemoWarning } from '@src/widgets/onboarding/model';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { showingProTrial } from './model';

export const InitalOnboardingState: UserOnboarding = {
  demoMode: false,
  selectedPath: 'createHubs' as const,
  createHubs: 'hub-tooltip-getting-started' as const,
  workCreatively: 'creative-with-colour' as const,
  manageBusinessAdmin: 'doc-getting-started' as const,
  completedPaths: {
    createHubs: false,
    workCreatively: false,
    manageBusinessAdmin: false,
  },
};

export const useUserOnboarding = () => {
  const { user } = useAppData();
  const history = useHistory();
  const queryClient = useQueryClient();

  const onboarding = user?.meta?.onboarding ?? {};

  const onboardingState =
    Object.keys(onboarding).length > 0
      ? user?.meta?.onboarding ?? InitalOnboardingState
      : InitalOnboardingState;

  const mutateOnboarding = useMutation({
    mutationKey: ['user-onboarding-mutation'],
    mutationFn: saveOnboarding,
    scope: {
      id: 'user-onboarding-mutation',
    },
    onMutate: (data) => {
      queryClient.cancelQueries({
        queryKey: ['my-user'],
      });

      const userData = queryClient.getQueryData<User>(['my-user']);
      if (!userData) return;

      const clonedUserData = structuredClone(userData);
      let userOnboardingData = clonedUserData?.meta?.onboarding;
      if (
        !userOnboardingData ||
        typeof userOnboardingData.selectedPath !== 'string'
      ) {
        userOnboardingData = InitalOnboardingState;
      }

      queryClient.setQueryData(['my-user'], {
        ...clonedUserData,
        meta: {
          ...clonedUserData.meta,
          onboarding: {
            ...userOnboardingData,
            ...data?.meta?.onboarding,
          },
        },
      });

      return {
        userData,
      };
    },
    onSuccess: (response) => {
      localStorage.setItem('v_user', JSON.stringify(response));
    },
    onError: () => {
      // TODO undo optimistic update for user
      startedSnack({
        label: 'An error occurred',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['my-user'],
      });
    },
  });

  const resetOnboardingTooltips = async () => {
    await mutateOnboarding.mutateAsync({
      meta: {
        ...user.meta,
        onboarding: {
          ...InitalOnboardingState,
          demoMode: true,
        },
      },
    });
  };

  const endDemoMode = async () => {
    // Eagerly start loading screen for demo mode. This happens later anyway.
    enabledLoadingDemoMode();
    hideDemoWarning();

    clearTabData();

    await mutateOnboarding.mutateAsync({
      meta: {
        ...user.meta,
        onboarding: {
          ...(user.meta?.onboarding ?? InitalOnboardingState),
          demoMode: false,
        },
      },
    });

    if (user.meta?.onboarding?.completedPaths.createHubs) {
      showingProTrial();
    }

    // Redirect to home if not in library. As hubs, boards, docs, studio, etc all will be different between demo and not demo
    if (
      !(
        window.location.pathname.includes('/library') ||
        window.location.pathname.includes('/account')
      )
    ) {
      history.push('/home');
    }
  };

  const startDemoMode = async ({
    redirectTo,
    ignoreAutoRedirect,
  }: {
    redirectTo?: string;
    ignoreAutoRedirect?: boolean;
  }) => {
    // Eagerly start loading screen for demo mode. This happens later anyway.
    enabledLoadingDemoMode();
    const checkDemoCreation = await api.get('/start-demo/', {
      baseURL: `${import.meta.env.VITE_API_BASE_URL}/demo`,
    });
    clearTabData();
    if (checkDemoCreation?.data?.demo_status == 2) {
      await mutateOnboarding.mutateAsync({
        meta: {
          ...user.meta,
          onboarding: {
            ...(user.meta?.onboarding ?? InitalOnboardingState),
            demoMode: true,
          },
        },
      });

      if (redirectTo) {
        history.push(redirectTo);
      } else if (
        !(
          window.location.pathname.includes('/library') ||
          window.location.pathname.includes('/account')
        )
      ) {
        if (ignoreAutoRedirect) return;
        history.push('/home');
      }
    } else {
      await mutateOnboarding.mutateAsync({
        meta: {
          ...user.meta,
          onboarding: InitalOnboardingState,
        },
      });
      history.push('/home?first=true');
      window.location.reload();
    }
  };

  const markProTrialAsCompleted = async () => {
    await mutateOnboarding.mutateAsync({
      meta: {
        ...user.meta,
        proTrialDialogCompleted: true,
      },
    });
  };

  const clearTabData = () => {
    clearAllTabData();
  };

  return {
    resetOnboardingTooltips,
    user,
    isDemoModeOn:
      typeof user.meta?.onboarding?.demoMode === 'boolean'
        ? user.meta?.onboarding?.demoMode
        : false,
    markProTrialAsCompleted,
    startDemoMode,
    endDemoMode,
    onboardingState,
    queryClient,
    mutateOnboarding,
  };
};

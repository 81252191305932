import { useLocation } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { archiveBoard } from '@api/boards';
import {
  ALL_BOARD_IMAGES_QUERY,
  BOARD_QUERY,
  BOARDS_OF_HUB_QUERY,
  BOARDS_TREE_QUERY,
  DOCS_OF_BOARD_QUERY,
} from '@src/shared/constants';
import { boardsKeys } from '@src/shared/constants/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useArchiveBoard = () => {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const archiveBoardMutation = useMutation({
    mutationFn: ({ id }: { id: string }) => archiveBoard({ id }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: variables.id }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_BOARD_QUERY, { board: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_OF_HUB_QUERY, { hub: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARD_IMAGES_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: boardsKeys.all,
      });

      startedSnack({ label: 'Archived board', close: true });
    },
    onError: (err, variables) => {
      startedSnack({
        label: 'Couldn’t archive board',
        action: {
          action: () => {
            archiveBoardMutation.mutate({ id: variables.id });
          },
          label: 'Try again',
        },
        close: true,
      });
    },
  });

  return {
    archiveBoardMutation,
  };
};

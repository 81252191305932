import { useEffect, useState } from 'react';

import {
  Spinner,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

export const UsedDocumentBanner = ({ isFetching }: { isFetching: boolean }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [spinnerTimer, setSpinnerTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (isFetching) {
      setShowSpinner(true);
      const timer = setTimeout(() => {
        setShowSpinner(false);
      }, 2000);
      setSpinnerTimer(timer);
    } else {
      if (spinnerTimer) {
        // If isFetching becomes false before 2s, don't clear the spinner immediately
        // Let the existing timer complete
      } else {
        setShowSpinner(false);
      }
    }

    return () => {
      if (spinnerTimer) {
        clearTimeout(spinnerTimer);
      }
    };
  }, [isFetching]);

  useEffect(() => {
    return () => {
      if (spinnerTimer) {
        clearTimeout(spinnerTimer);
      }
    };
  }, [spinnerTimer]);

  return (
    <div className={styles.container}>
      <div>
        <TypographyPoppins type="body" size="M" className={styles.text}>
          Someone else is working on this doc right now. Wait for them to finish
          to edit the doc.
        </TypographyPoppins>
      </div>
      {showSpinner && <Spinner />}
    </div>
  );
};

import { createEvent, createStore } from 'effector';

export const archiveHubIdSelected = createEvent<string>();
export const archiveHubIdCleared = createEvent();

export const $archiveHubId = createStore<string | null>(null);

$archiveHubId
  .on(archiveHubIdSelected, (_, id) => id)
  .reset(archiveHubIdCleared);

import { REDIRECT_TO_HUB_OR_BOARD_NEW_USER } from '@src/shared/constants/variables-local-storage';
import { setCookie } from '@src/shared/utils';
import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import {
  fetchSignUp,
  FetchSignUpResponse,
  FormSignUp,
} from '../../../../api/account';

export const useCheckRegistrationCode = ({
  onSuccess,
  ...options
}: MutateOptions<FetchSignUpResponse, any, FormSignUp>) => {
  const queryClient = useQueryClient();

  const { mutate: registerUser, ...mutateStates } = useMutation({
    mutationFn: (values: FormSignUp) => fetchSignUp(values),
    ...options,
    onSuccess: (res, vars, ctx) => {
      const { access, refresh, checkout } = res;
      setCookie('access', access);
      setCookie('refresh', refresh);
      queryClient.invalidateQueries({
        queryKey: ['my-user'],
      });

      if (checkout) {
        window.location.href = checkout.checkout_url;
        return;
      }

      const redirect = localStorage.getItem(REDIRECT_TO_HUB_OR_BOARD_NEW_USER);

      if (redirect) {
        localStorage.removeItem(REDIRECT_TO_HUB_OR_BOARD_NEW_USER);
        window.location.href = redirect;
      } else {
        window.location.href = vars.redirect_url;
      }

      if (onSuccess) onSuccess(res, vars, ctx);
    },
  });
  return { registerUser, ...mutateStates };
};

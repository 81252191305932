export function getCookie(name: string): string | null {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

export function setCookie(name: string, value: string, days?: number): void {
  try {
    const d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * (days ?? 3));
    document.cookie = name + '=' + value + ';path=/;expires=' + d.toUTCString();
  } catch (e) {
    console.error('Error setting cookie', e);
  }
}

export function deleteCookie(name: string): void {
  setCookie(name, '', -1);
}

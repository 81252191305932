import { useHistory } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { unarchiveFile } from '@api/blocks';
import { ALL_BOARD_IMAGES_QUERY, LIBRARY_QUERY } from '@src/shared/constants';
import { OutputType } from '@src/shared/utils/get-entity-type';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUnarchiveFile = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const unarchiveFileMutation = useMutation({
    mutationFn: ({ ids }: { ids: string[] | null; entityType?: OutputType }) =>
      unarchiveFile({ ids }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [LIBRARY_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARD_IMAGES_QUERY],
      });

      startedSnack({
        label: `Unarchived ${variables.entityType}`,
        action: {
          label: `Go to ${variables.entityType}`,
          action: () => {
            if (variables.entityType === 'design') {
              history.push(`/studio/${variables.ids}`);
            } else {
              history.push(`#/f/${variables.ids}`);
            }
          },
        },
        close: true,
      });
    },
    onError: (err, variables) => {
      startedSnack({
        label: `Couldn’t unarchive ${variables.entityType}`,
        action: {
          action: () => {
            unarchiveFileMutation.mutate({ ids: variables.ids });
          },
          label: 'Try again',
        },
        close: true,
      });
    },
  });

  return {
    unarchiveFileMutation,
  };
};

import { createEvent, createStore, sample } from 'effector';

import { locationSelected } from './move';

export const levelCleared = createEvent();

export const $level = createStore<string[] | boolean | null>(null);

sample({
  clock: locationSelected,
  source: $level,
  fn: (prevLevel, originalLocation) => {
    if (!originalLocation) return prevLevel;

    const { level, children } = originalLocation;

    if (level === 0 && children && children[0].children !== null) return false;
    if (level === 0) return children === null ? ['0, 1'] : ['0'];
    if (level === 1) return children === null ? ['0, 1'] : ['0'];

    return ['0, 1'];
  },
  target: $level,
});

sample({
  clock: levelCleared,
  fn: () => null,
  target: $level,
});

import React from 'react';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';

import styles from './styles.module.css';

export const RecentSearches = (props: {
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const [searches] = useLocalStorage<string[]>('recent-searches', []);

  return (
    <div className={styles.container}>
      <TypographyPoppins type="body" size="M" className={styles.title}>
        Recent searches
      </TypographyPoppins>
      <div className={styles.searchesContainer}>
        {searches.map((search, index) => (
          <button
            key={index}
            className={styles.searchButton}
            onClick={() => props.setSearchText(search)}
          >
            <Icon name="sprite/clock" height={24} width={24} />
            <TypographyPoppins
              type="body"
              size="L"
              className={styles.searchText}
            >
              {search}
            </TypographyPoppins>
          </button>
        ))}
      </div>
    </div>
  );
};

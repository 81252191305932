import { GlobalError, GlobalErrorHomeButton } from '..';

export const MissingContent = ({
  beforeHomeClick,
}: {
  beforeHomeClick?: () => void;
}) => {
  return (
    <GlobalError
      icon="sprite/reminder-colored"
      text={`
      Oops! We couldn’t find that page.

      It might be lost or just never existed—
			let’s get you back on track!
      `}
      button={<GlobalErrorHomeButton beforeHomeClick={beforeHomeClick} />}
    />
  );
};

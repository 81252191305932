import { GlobalError, GlobalErrorGetHelpButton } from '..';

export const SomethingWentWrong = ({
  crashSideEffect,
}: {
  crashSideEffect?: () => void;
}) => {
  return (
    <GlobalError
      icon="sprite/caution-colored"
      text={`
      Whoops, something went wrong.

      Don’t worry. It’s not you, it’s us. Please try again!
      `}
      button={<GlobalErrorGetHelpButton onClick={crashSideEffect} />}
    />
  );
};

import { memo } from 'react';

import { ArchiveDialog } from './archive';
import { BoardCreationDialog } from './board/create';
import { MoveBoardDialog } from './board/move';
import { ConfirmationDialog } from './board/move/confirmation-dialog';
import { BoardRenamingDialog } from './board/rename';
import { ColorCodesDialog } from './file-card/color-codes';
import { CopyFile } from './file-card/copy-file-to-board';
import { HubCreationDialog } from './hub/creation';
import { HubRenamingDialog } from './hub/renaming';
import { HubRestoringDialog } from './hub/restoring';
import { PaletteCreationDialog } from './palette';
import { UnarchiveDialog } from './unarchive';

export const Dialogs = memo(() => (
  <>
    <HubCreationDialog />
    <HubRenamingDialog />
    <HubRestoringDialog />
    <BoardCreationDialog />
    <BoardRenamingDialog />
    <MoveBoardDialog />
    <ConfirmationDialog />
    <PaletteCreationDialog />
    <ColorCodesDialog />
    <ArchiveDialog />
    <UnarchiveDialog />
    <CopyFile />
  </>
));

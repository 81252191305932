import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

type Props = {
  membeSeatsAvailable?: number;
  numberGuests: number;
};

export const Description = ({ numberGuests, membeSeatsAvailable }: Props) => {
  if (membeSeatsAvailable === undefined) return null;

  const getSeatLabel = () => {
    return membeSeatsAvailable === 1 ? 'seat' : 'seats';
  };

  const getGuestLabel = () => {
    return numberGuests === 1 ? 'guest' : 'guests';
  };

  const getNumberRemainingSeats = () => {
    return membeSeatsAvailable - numberGuests;
  };

  const getIsNotEnoughSeats = () => {
    return membeSeatsAvailable < numberGuests;
  };

  const getRemainingSeats = () => {
    return membeSeatsAvailable - numberGuests === 1 ? 'seat' : 'seats';
  };

  return numberGuests > 0 ? (
    <div style={{ marginBottom: '8px' }}>
      <TypographyPoppins className={styles.description} type="body" size="S">
        {getIsNotEnoughSeats()
          ? `You’re trying to add ${numberGuests} ${getGuestLabel()}, but you've only got ${membeSeatsAvailable} ${getSeatLabel()} left in this hub`
          : `You’re adding ${numberGuests} ${getGuestLabel()}, and you’ll have ${
              getNumberRemainingSeats() > 0 ? getNumberRemainingSeats() : 'no'
            } ${getRemainingSeats()} left in this
      hub after this`}
      </TypographyPoppins>
    </div>
  ) : (
    <div style={{ marginBottom: '8px', marginTop: '20px' }}>
      <TypographyPoppins className={styles.description} type="body" size="S">
        {membeSeatsAvailable === 0
          ? "You've got no seats left in this hub"
          : `You’ve got ${membeSeatsAvailable} ${getSeatLabel()} left in this hub`}
      </TypographyPoppins>
    </div>
  );
};

import { Icon } from '@visualist/icons';

import styles from './styles.module.css';

export const PlaneIcon = () => {
  return (
    <div className={styles.container}>
      <div className={styles.plane}>
        <div className={styles.planeLeftRight}>
          <div className={styles.planeUpDown}>
            <Icon
              size={80}
              className={styles.planeRotation}
              name="sprite/paper-plane-curly-colored"
            />
          </div>
        </div>
      </div>
      <div className={styles.emptyContainer}></div>
    </div>
  );
};

import { useState } from 'react';

import { useHistory, useLocation } from 'react-router';

import { Modal } from '@visualist/design-system/src/components/v2';

// import { checkDemoCreation } from '@api/account';
import { OptionKey } from '@src/AppContext';

// import { enabledLoadingDemoMode } from '@src/model';
// import { useQuery } from '@tanstack/react-query';
import { WelcomeNameConfirmationScreen } from './confirmationScreen';
import { MainScreen } from './mainScreen';
import { WelcomeNameRequestScreen } from './nameRequestScreen';
import { PromocodeScreen } from './promocodeScreen';

import styles from './styles.module.css';

type Screens = 'promoScreen' | 'welcome' | 'nameRequest' | 'confirmation';

export const OnboardingScreens = ({
  initialScreen,
  updateOnboardingPath,
}: {
  initialScreen?: Screens;
  updateOnboardingPath: ({
    path,
    startDemo,
    isInitialPath,
  }: {
    path: OptionKey;
    startDemo?: boolean;
    isInitialPath?: boolean;
  }) => Promise<void>;
}) => {
  const { search } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(() => {
    const urlSearchParams = new URLSearchParams(search);
    const first = urlSearchParams.get('first');
    return first === 'true';
  });

  const dismissWelcomeScreen = () => setIsModalOpen(false);

  if (!isModalOpen) return null;

  return (
    <ScreenManager
      dismissWelcomeScreen={dismissWelcomeScreen}
      updateOnboardingPath={updateOnboardingPath}
      initialScreen={initialScreen}
    />
  );
};

const ScreenManager = ({
  // updateOnboardingPath,
  dismissWelcomeScreen,
  initialScreen = 'promoScreen',
}: {
  dismissWelcomeScreen: () => void;
  updateOnboardingPath: ({
    path,
    startDemo,
    isInitialPath,
  }: {
    path: OptionKey;
    startDemo?: boolean;
    isInitialPath?: boolean;
  }) => Promise<void>;
  initialScreen?: Screens;
}) => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(initialScreen === 'welcome');
  const [currentOnboardingPath, setCurrentOnboardingPath] =
    useState<OptionKey>('createHubs');
  const [currentScreen, setCurrentScreen] = useState<Screens>(initialScreen);
  // const [demoWorldCreated, setDemoWorldCreated] = useState(false);
  // const [waitingForDemo, setWaitingForDemo] = useState(false);

  // const { data } = useQuery({
  //   queryKey: ['check-demo-mode-start'],
  //   queryFn: checkDemoCreation,
  //   enabled: !demoWorldCreated,
  //   refetchInterval: 2000,
  // });

  // useEffect(() => {
  //   // If user completed welcomescreen flow and demo is still not created, wait for demo to be created
  //   if (waitingForDemo && data?.data.demo_status === 2) {
  //     setDemoWorldCreated(true);
  //     dismissWelcomeScreen();
  //     updateOnboardingPath({
  //       path: currentOnboardingPath,
  //       startDemo: true,
  //       isInitialPath: true,
  //     });
  //   }
  // }, [
  //   waitingForDemo,
  //   data,
  //   dismissWelcomeScreen,
  //   updateOnboardingPath,
  //   currentOnboardingPath,
  // ]);

  // if (data?.data.demo_status === 2 && !demoWorldCreated) {
  //   setDemoWorldCreated(true);
  // }

  const closePromoScreen = () => {
    setIsModalOpen(true);
    setCurrentScreen('welcome');
  };

  const handleNextClick = () => {
    setCurrentScreen('nameRequest');
  };

  const handlePreviousClick = () => {
    setCurrentScreen('welcome');
  };

  const handleSetClick = async () => {
    dismissWelcomeScreen();
    // Enter demo mode here
    // setCurrentScreen('confirmation');
    history.push('/home');
  };

  const handleCompleteClick = async () => {
    // Eagerly start loading screen for demo mode. This happens later anyway.
    // enabledLoadingDemoMode();
    dismissWelcomeScreen();
    // if (demoWorldCreated) {
    //   await updateOnboardingPath({
    //     path: currentOnboardingPath,
    //     startDemo: true,
    //     isInitialPath: true,
    //   });
    // } else {
    //   // setWaitingForDemo(true);
    // }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    dismissWelcomeScreen();
  };

  const skipOnboarding = async () => {
    dismissWelcomeScreen();
    // Redirect to remove search params
    history.push('/home');
  };

  return (
    <>
      {currentScreen === 'promoScreen' ? (
        <PromocodeScreen closePromoScreen={closePromoScreen} />
      ) : (
        <Modal
          showModal={isModalOpen}
          handleClose={closeModal}
          className={styles.modal}
          preventBackdropClick={true}
        >
          {currentScreen === 'welcome' && (
            <MainScreen
              handleCloseModal={closeModal}
              onNextClick={handleNextClick}
              selectedOption={currentOnboardingPath}
              skipOnboarding={skipOnboarding}
              onOptionChange={(path) => setCurrentOnboardingPath(path)}
            />
          )}
          {currentScreen === 'nameRequest' && (
            <WelcomeNameRequestScreen
              onPreviousClick={handlePreviousClick}
              onNext={handleSetClick}
            />
          )}
          {currentScreen === 'confirmation' && (
            <WelcomeNameConfirmationScreen
              selectedOption={currentOnboardingPath}
              onConfirm={handleCompleteClick}
            />
          )}
        </Modal>
      )}
    </>
  );
};

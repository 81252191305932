import { useState } from 'react';

import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory } from 'react-router';

import {
  Button,
  TextField,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { fetchSendEmail } from '@api/account';
import { useMutation } from '@tanstack/react-query';

import { LoginFormValues } from '../types';
import { validateEmail } from '../utils';

import styles from './styles.module.css';

const initialValues: LoginFormValues = {
  email: '',
};

export const ForgotForm = () => {
  const history = useHistory();
  const [sentEmail, setSentEmail] = useState(false);
  const [hasTriedSubmitting, setHasTriedSubmitting] = useState(false);

  const { mutate } = useMutation({
    mutationFn: fetchSendEmail,
    onSuccess: () => {
      setSentEmail(true);
    },
  });

  const formik = useFormik({
    initialValues,
    validateOnBlur: hasTriedSubmitting,
    validateOnChange: hasTriedSubmitting,
    validate: (v) =>
      validateEmail(v, {} as Record<keyof LoginFormValues, string>),
    onSubmit: (values) => {
      mutate({
        email: values.email,
      });
      setSentEmail(true);
    },
  });

  return (
    <AnimatePresence mode="popLayout">
      {sentEmail ? (
        <motion.div
          key={1}
          initial={{ transform: 'translateX(120%)' }}
          animate={{ transform: 'translateX(0%)' }}
          transition={{
            duration: 0.4,
            bounce: 0,
            type: 'spring',
          }}
        >
          <EmailSignupForm />
        </motion.div>
      ) : (
        <motion.div
          key={2}
          exit={{
            transform: 'translateX(-120%)',
          }}
          transition={{
            duration: 0.3,
            bounce: 0,
            type: 'spring',
          }}
          className={styles.container}
        >
          <TypographyPoppins type="title" size="L" className={styles.title}>
            <div>Reset your password</div>
          </TypographyPoppins>
          <div className={styles.mainForm}>
            <form
              noValidate
              onSubmit={(e) => {
                setHasTriedSubmitting(true);
                formik.handleSubmit(e);
              }}
              className={styles.form}
            >
              <div className={styles.formInput}>
                <TypographyPoppins
                  type="label"
                  size="M"
                  className={styles.label}
                >
                  <label htmlFor="email">Email</label>
                </TypographyPoppins>
                <TextField
                  autoFocus
                  formNoValidate
                  id="email"
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  errorAndSupportingText={formik.errors.email}
                  clear={(e) => {
                    e.preventDefault();
                    formik.resetForm();
                  }}
                />
              </div>
              <div className={styles.formButtons}>
                <Button
                  type="outlined"
                  label="Reset password"
                  // Empty onclick as it's handled by formik
                  onClick={() => {}}
                />
              </div>
            </form>
          </div>
          <div className={styles.footer}>
            <TypographyPoppins type="body" size="M">
              Don't have an account?
            </TypographyPoppins>
            <Button
              type="ghost"
              label="Sign up now"
              onClick={() => {
                history.push('/signup');
              }}
            />
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const EmailSignupForm = () => {
  return (
    <div className={styles.confirmContainer}>
      <TypographyPoppins type="title" size="L" className={styles.emailTitle}>
        <Icon name="sprite/paper-plane" />
        <div>Check your email</div>
      </TypographyPoppins>
      <TypographyPoppins type="body" size="M" className={styles.checkEmailText}>
        If your email is registered, we’ve sent you an email to reset your
        password
      </TypographyPoppins>
    </div>
  );
};

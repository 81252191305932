import { LIFOStack } from './lifo-stack';

export class UndoRedoManager<T> {
  private readonly undoStack: LIFOStack<T>;
  private readonly redoStack: LIFOStack<T>;

  constructor() {
    this.undoStack = new LIFOStack();
    this.redoStack = new LIFOStack();
  }

  performAction(state: T) {
    this.undoStack.push(state);
    this.redoStack.clear();
  }

  undo(currentState: T | null) {
    const newState = this.undoStack.pop();

    if (newState === null) return null;

    if (currentState) this.redoStack.push(currentState);

    return newState;
  }

  redo(currentState: T | null) {
    const newState = this.redoStack.pop();

    if (newState === null) return null;

    if (currentState) this.undoStack.push(currentState);

    return newState;
  }

  canRedo() {
    return !this.redoStack.isEmpty();
  }

  canUndo() {
    return !this.undoStack.isEmpty();
  }
}

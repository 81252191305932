import React from 'react';

import { BlockType } from '@visualist/design-system';

import { Block, BlockResponse } from '@api/services';
import { Role } from '@api/users';

import { Palette } from '../palette';
import { MiniStickies } from '../Stickies/MiniStickies';

import styles from './styles.module.css';

type Props = {
  block?: Block | BlockResponse;
  imageRef: React.RefObject<React.ElementRef<'img'>>;
  role?: Role | null;
};

/**
 * MainImage component
 * @param block
 * @param imageRef
 * @constructor
 * @return JSX.Element
 *
 * @description
 * This component is used to display the main image of the file card. When given initial block data, it will
 * display a placeholder image until the actual image is loaded.
 *
 * @example
 * <MainImage
 *  block={block}
 *  imageRef={imageRef}
 * />
 */

export const MainImage = ({ imageRef, block, role }: Props) => {
  const [imageSize, setImageSize] = React.useState({ width: 0, height: 0 });
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const canvasRef = React.useRef<React.ElementRef<'canvas'>>(null);

  React.useEffect(() => {
    if (!imageRef.current || !hasLoaded) return;
    const img = imageRef.current;

    // Create a new ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      // Loop over the entries
      entries.forEach((entry) => {
        // Log the new size
        setImageSize({
          width: entry.contentRect.width + 16,
          height: entry.contentRect.height + 16,
        });
      });
    });

    // Start observing the image
    resizeObserver.observe(img);

    // Clean up the observer when the component unmounts
    return () => resizeObserver.unobserve(img);
  }, [hasLoaded, block]);

  React.useEffect(() => {
    if (hasLoaded) return;
    setHasLoaded(false);

    // Load image and set hasLoaded to true when image is loaded
    const loadImage = async () => {
      if (!block || !block.file || !block.file.full_size) return;
      const img = new Image();
      img.src = block.file.full_size;

      setHasLoaded(true);
    };

    loadImage();
  }, [block]);

  // Parent is in loading state if not block so we can show nothing
  if (!block) return null;

  return (
    <>
      {block.block_type !== BlockType.palette && (
        <div
          className={styles.mediaContainer}
          key={block.id}
          style={{
            aspectRatio: block.width / block.height,
            minWidth: '20%',
            minHeight: '20%',
          }}
        >
          {hasLoaded ? (
            <img
              key={block.id}
              ref={imageRef}
              src={block.file.full_size}
              className={styles.image}
              alt="card"
            />
          ) : (
            <div
              style={{
                height: block.height,
                width: block.width,
                maxHeight: '100%',
                maxWidth: '100%',
                // backgroundColor: '#F9D4C880',
              }}
            ></div>
          )}
          {imageRef.current && block && hasLoaded ? (
            <MiniStickies
              imageRef={imageRef}
              block={block}
              containerHeight={imageSize.height - 16}
              containerWidth={imageSize.width - 16}
            />
          ) : null}
        </div>
      )}

      {block.block_type === BlockType.palette && (
        <Palette role={role} isArchived={block.is_archived} />
      )}
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </>
  );
};

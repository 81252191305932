import { useCallback, useRef, useState } from 'react';

import { ImageResponse } from '@pages/StudioPage/api';
import { UndoRedoManager } from '@pages/StudioPage/lib/undo-redo-manager';

interface UseImageHistoryProps {
  initialImage: ImageResponse | null;
}

export const useImageHistory = (
  { initialImage }: UseImageHistoryProps = {
    initialImage: null,
  },
) => {
  const imageHistoryManager = useRef(new UndoRedoManager<ImageResponse>());
  const [currentImage, setCurrentImage] = useState<ImageResponse | null>(
    initialImage,
  );

  const addNewImage = useCallback(
    (newImage: ImageResponse) => {
      if (currentImage) {
        imageHistoryManager.current.performAction(currentImage);
      }
      setCurrentImage(newImage);
    },
    [currentImage],
  );

  const undo = useCallback(() => {
    if (!currentImage) return;
    const previousImage = imageHistoryManager.current.undo(currentImage);
    if (previousImage) {
      setCurrentImage(previousImage);
    }
  }, [currentImage]);

  const redo = useCallback(() => {
    if (!currentImage) return;
    const nextImage = imageHistoryManager.current.redo(currentImage);
    if (nextImage) {
      setCurrentImage(nextImage);
    }
  }, [currentImage]);

  const canUndo = useCallback(() => {
    return imageHistoryManager.current.canUndo();
  }, []);

  const canRedo = useCallback(() => {
    return imageHistoryManager.current.canRedo();
  }, []);

  const reset = useCallback(() => {
    imageHistoryManager.current = new UndoRedoManager<ImageResponse>();
    setCurrentImage(null);
  }, []);

  const resetAndAddImage = useCallback((newImage: ImageResponse) => {
    imageHistoryManager.current = new UndoRedoManager<ImageResponse>();
    setCurrentImage(newImage);
  }, []);

  return {
    currentImage,
    addNewImage,
    undo,
    redo,
    canUndo,
    canRedo,
    reset,
    resetAndAddImage,
  };
};

import { CSSProperties, ReactNode } from 'react';

import cn from 'classnames';

import styles from './styles.module.css';

interface CheckboxProps {
  className?: string;
  classNameOuterRadio?: string;
  style?: CSSProperties;
  icon?: ReactNode;
  onClick: (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
  itemID?: string;
  isSelected?: boolean;
  isDisabled?: boolean;
}

export const Checkbox = ({
  className,
  classNameOuterRadio,
  style,
  icon,
  onClick,
  itemID,
  isSelected,
  isDisabled,
}: CheckboxProps) => (
  <div
    className={cn(styles.enabled, {
      [styles.disabled]: isDisabled,
    })}
    style={style}
  >
    <div
      className={cn(styles.container, className, {
        [styles.noBorder]: isSelected,
      })}
    >
      <div
        className={cn(styles.outerRadio, classNameOuterRadio, {
          [styles.outerRadioSelected]: isSelected,
          [styles.outerRadioSelectedDisabled]: isDisabled,
          [styles.noBorder]: isSelected,
        })}
        data-item={itemID}
        onClick={(e) => {
          if (!isDisabled) {
            onClick(e);
          }
        }}
        tabIndex={0}
        role="checkbox"
        onKeyDown={(e) => {
          if (e.key === 'Enter') onClick(e);
        }}
      >
        <div
          className={cn(styles.innerRadio, {
            [styles.innerRadioSelected]: isSelected,
          })}
          data-item={itemID}
        >
          {icon && <span className={styles.icon}>{icon}</span>}
        </div>
      </div>
    </div>
  </div>
);

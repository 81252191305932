import styles from './styles.module.css';

export const WindowIcon = () => {
  return (
    <svg
      className={styles.window}
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="65"
      fill="none"
    >
      <defs>
        <clipPath id="windowClip">
          <rect x="6.66" y="16.66" width="67" height="47" />
        </clipPath>
      </defs>

      {/* Add clipPath to the main content group */}
      <g clipPath="url(#windowClip)">
        <path
          fill="#E2C9EB"
          fillRule="evenodd"
          d="M6.66675 17.3333V56.6255H66.6078L73.3334 63.3333V17.3333C73.3334 16.9651 73.0349 16.6666 72.6667 16.6666H66.6078H7.33342C6.96523 16.6666 6.66675 16.9651 6.66675 17.3333Z"
          clipRule="evenodd"
          opacity=".25"
        />
        {/* Cloud right */}
        <g className={styles.cloudFast}>
          <path
            fill="#E2C9EB"
            d="M63.9181 38.1461C63.8243 36.0021 63.7619 34.5755 65.9402 34.347C66.3081 34.3084 66.6104 34.6159 66.6079 34.9859L66.527 46.9217C66.5245 47.2881 66.2267 47.5838 65.8603 47.5838H56.0337H52.3063C51.3122 47.5838 50.539 44.7478 51.9726 43.4681C52.9873 42.5623 54.581 42.2147 56.3763 42.376C56.9089 42.4239 57.3473 41.8862 57.2734 41.3566C57.1405 40.4044 57.4655 39.9157 57.6189 39.6657C58.9025 37.5749 61.0844 37.8652 62.7335 38.7847C63.24 39.0671 63.9437 38.7307 63.9184 38.1514L63.9181 38.1461Z"
            opacity=".5"
          />
          <path
            stroke="#43424A"
            strokeWidth=".67"
            d="M63.9181 38.1461C63.8243 36.0021 63.7619 34.5755 65.9402 34.347C66.3081 34.3084 66.6104 34.6159 66.6079 34.9859L66.527 46.9217C66.5245 47.2881 66.2267 47.5838 65.8603 47.5838H56.0337H52.3063C51.3122 47.5838 50.539 44.7478 51.9726 43.4681C52.9873 42.5623 54.581 42.2147 56.3763 42.376C56.9089 42.4239 57.3473 41.8862 57.2734 41.3566C57.1405 40.4044 57.4655 39.9157 57.6189 39.6657C58.9025 37.5749 61.0844 37.8652 62.7335 38.7847C63.24 39.0671 63.9437 38.7307 63.9184 38.1514L63.9181 38.1461Z"
          />
        </g>
        <path
          fill="#DCD5CB"
          d="M40.4991 56.6366H34.3623C34.0329 56.6366 33.7528 56.396 33.7032 56.0704L32.6883 49.4092L31.9466 47.5503C31.7719 47.1125 32.0944 46.6366 32.5658 46.6366H42.2043C42.6915 46.6366 43.0141 47.142 42.8091 47.5839L41.9619 49.4092L41.1609 56.0498C41.1205 56.3847 40.8364 56.6366 40.4991 56.6366Z"
          opacity=".5"
        />
        <path
          fill="#DCD5CB"
          d="M40.499 56.6366H36.3136C35.9683 56.6366 35.6802 56.373 35.6496 56.029L35.0605 49.4092L34.4435 47.5092C34.3036 47.0785 34.6247 46.6366 35.0775 46.6366H42.2043C42.6914 46.6366 43.0141 47.142 42.809 47.5839L41.9619 49.4092L41.1609 56.0498C41.1205 56.3847 40.8363 56.6366 40.499 56.6366Z"
        />
        <path
          stroke="#43424A"
          strokeWidth=".67"
          d="M32.6883 49.4092L31.9466 47.5503C31.7719 47.1125 32.0944 46.6366 32.5658 46.6366H42.2043C42.6915 46.6366 43.0141 47.142 42.8091 47.5839L41.9619 49.4092M32.6883 49.4092L33.7032 56.0704C33.7528 56.396 34.0329 56.6366 34.3623 56.6366H40.4991C40.8364 56.6366 41.1205 56.3847 41.1609 56.0498L41.9619 49.4092M32.6883 49.4092H41.9619"
        />
        {/* Cloud Left */}
        <g className={styles.cloudSlow}>
          <path
            fill="#E2C9EB"
            d="M13.9126 29.3044C13.8328 26.9393 13.3547 25.4041 10.4889 25.4041C8.72055 25.404 7.56165 25.9463 6.81222 26.7933C6.71348 26.9048 6.66511 27.0509 6.66511 27.1999V38.0707C6.66511 38.4389 6.96359 38.7374 7.33178 38.7374H21.8559H25.5833C26.5775 38.7374 27.3507 35.9013 25.9171 34.6216C24.9023 33.7159 23.3086 33.3682 21.5134 33.5296C20.9807 33.5775 20.5423 33.0397 20.6162 32.5101C20.7491 31.558 20.4242 31.0693 20.2707 30.8193C18.9872 28.7284 16.8052 29.0188 15.1561 29.9383C14.6496 30.2207 13.9322 29.884 13.9126 29.3044Z"
            opacity=".5"
          />
          <path
            stroke="#43424A"
            strokeWidth=".67"
            d="M13.9126 29.3044C13.8328 26.9393 13.3547 25.4041 10.4889 25.4041C8.72055 25.404 7.56165 25.9463 6.81222 26.7933C6.71348 26.9048 6.66511 27.0509 6.66511 27.1999V38.0707C6.66511 38.4389 6.96359 38.7374 7.33178 38.7374H21.8559H25.5833C26.5775 38.7374 27.3507 35.9013 25.9171 34.6216C24.9023 33.7159 23.3086 33.3682 21.5134 33.5296C20.9807 33.5775 20.5423 33.0397 20.6162 32.5101C20.7491 31.558 20.4242 31.0693 20.2707 30.8193C18.9872 28.7284 16.8052 29.0188 15.1561 29.9383C14.6496 30.2207 13.9322 29.884 13.9126 29.3044Z"
          />
        </g>
        <path
          fill="#B3D4C1"
          fillRule="evenodd"
          d="M73.3339 63.3334H7.3339C6.96571 63.3334 6.66724 63.0349 6.66724 62.6668V56.6257H66.6083L73.3339 63.3334Z"
          clipRule="evenodd"
          opacity=".5"
        />
        <path
          fill="#B3D4C1"
          fillRule="evenodd"
          d="M73.3339 17.3334V63.3334L66.6083 56.6257V16.6667H72.6672C73.0354 16.6667 73.3339 16.9652 73.3339 17.3334Z"
          clipRule="evenodd"
        />
        <path
          stroke="#43424A"
          strokeWidth=".67"
          d="M73.3339 63.3334H7.3339C6.96571 63.3334 6.66724 63.0349 6.66724 62.6667V56.6257M73.3339 63.3334V17.3334C73.3339 16.9652 73.0354 16.6667 72.6672 16.6667H66.6083M73.3339 63.3334L66.6083 56.6257M66.6083 16.6667H7.3339C6.96571 16.6667 6.66724 16.9652 6.66724 17.3334V56.6257M66.6083 16.6667V56.6257M66.6083 56.6257H6.66724"
        />
        {/* Plant */}
        <g className={styles.plant}>
          <path
            fill="#B3D4C1"
            d="M38.3289 35.7647C38.9165 35.0945 41.9333 31.7474 42.0909 32.4433C42.1577 32.7384 40.3624 35.7647 38.3289 35.7647C37.9894 35.7375 37.593 37.6978 37.1183 39.5005C36.8071 40.6821 36.7193 42.0604 36.7573 43.3855C38.1403 42.8857 41.1674 42.0032 41.1674 42.6358C41.1674 43.3855 38.3144 43.3855 36.7573 43.3855C36.7664 43.702 36.7826 44.0155 36.8047 44.3226C35.3854 44.1792 32.2783 44.3226 32.2781 43.3855C32.2779 42.7001 35.864 43.8413 36.8047 44.3226C36.7826 44.0155 36.7664 43.702 36.7573 43.3855C36.7193 42.0604 36.8071 40.6821 37.1183 39.5005C35.3113 38.6607 32.5842 37.2304 33.2647 36.7297C33.9453 36.2291 36.3562 38.4931 37.1183 39.5005C37.593 37.6978 37.9894 35.7375 38.3289 35.7647Z"
          />
          <path
            stroke="#43424A"
            strokeWidth=".67"
            d="M37.1183 46.7825C36.9857 46.0767 36.8698 45.2276 36.8047 44.3225M38.3628 35.7647C38.9504 35.0946 41.1674 32.172 42.2603 32.5112C42.5493 32.6008 40.0235 35.7647 38.3628 35.7647ZM38.3628 35.7647C38.1126 36.05 37.593 37.6976 37.1183 39.5003M37.1183 39.5003C36.3562 38.493 33.9453 36.2289 33.2647 36.7296C32.5842 37.2303 35.3113 38.6605 37.1183 39.5003ZM37.1183 39.5003C36.8071 40.682 36.7193 42.0603 36.7573 43.3853M36.7573 43.3853C38.1403 42.8855 41.1674 42.0031 41.1674 42.6356C41.1674 43.3853 38.3144 43.3853 36.7573 43.3853ZM36.7573 43.3853C36.7664 43.7019 36.7826 44.0154 36.8047 44.3225M36.8047 44.3225C35.864 43.8411 32.2779 42.6999 32.2781 43.3853C32.2783 44.3225 35.3854 44.1791 36.8047 44.3225Z"
          />
        </g>
        <path
          fill="#43424A"
          stroke="#43424A"
          strokeWidth=".67"
          d="M38.5242 63.0132L34.0748 57.7326C33.7095 57.299 34.0177 56.6364 34.5846 56.6364H41.2048C41.3814 56.6364 41.5508 56.7064 41.6757 56.8311L46.9678 62.1117C47.3884 62.5315 47.0912 63.2503 46.4969 63.2503H39.034C38.8374 63.2503 38.6508 63.1636 38.5242 63.0132Z"
        />
      </g>
    </svg>
  );
};

import { ChangeEvent, useEffect, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import { ThumbnailBrightness } from '@api/hubs';
import { useHubs } from '@pages/HubPage/model/queries/useHubs';
import { KeyCodes } from '@src/shared/utils';

import { $defaultColor, $headerName, headerNameChanged } from './model';
import { SelectCover } from './SelectCover';

import styles from './styles.module.css';

interface HeaderProps {
  thumbnail: string;
  thumbnailHex: string;
  name: string;
  hubId: string;
  thumbnailBrightness: ThumbnailBrightness;
  customColors: string[];
  updateCustomColors: (customColors: string) => void;
  isArchived: boolean;
}

export const Header = ({
  thumbnail,
  thumbnailHex,
  name,
  hubId,
  thumbnailBrightness,
  customColors,
  updateCustomColors,
  isArchived,
}: HeaderProps) => {
  const defaultColor = useUnit($defaultColor);

  return (
    <>
      {thumbnail ? (
        <header
          style={{
            backgroundImage: `url(${thumbnail})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
          className={styles.header}
        >
          <Name
            name={name}
            hubId={hubId}
            thumbnailBrightness={thumbnailBrightness}
            isArchived={isArchived}
          />
          <SelectCover
            thumbnail={thumbnail}
            thumbnailHex={thumbnailHex}
            isArchived={isArchived}
            customColors={customColors}
            updateCustomColors={updateCustomColors}
          />
        </header>
      ) : (
        <header
          style={{ background: thumbnailHex ? thumbnailHex : defaultColor }}
          className={styles.header}
        >
          <Name
            name={name}
            hubId={hubId}
            thumbnailBrightness={thumbnailBrightness}
            isArchived={isArchived}
          />
          <SelectCover
            thumbnail={thumbnail}
            thumbnailHex={thumbnailHex ? thumbnailHex : defaultColor}
            customColors={customColors}
            updateCustomColors={updateCustomColors}
            isArchived={isArchived}
          />
        </header>
      )}
    </>
  );
};

const Name = ({
  name,
  hubId,
  thumbnailBrightness,
  isArchived,
}: {
  name: string;
  hubId: string;
  thumbnailBrightness: ThumbnailBrightness;
  isArchived: boolean;
}) => {
  const [isEditing, setEditing] = useState(false);

  const headerName = useUnit($headerName);

  const { editHub } = useHubs({});

  useEffect(() => {
    headerNameChanged(name);
  }, [name]);

  const onDoubleClick = () => {
    if (isArchived) return;
    setEditing(true);
  };

  const rename = () => {
    if (name !== headerName) {
      editHub({ name: headerName, hubId });
    }
    setEditing(false);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    headerNameChanged(e.target.value);
  };

  return isEditing && !isArchived ? (
    <div className={styles.title}>
      <input
        className={cn(styles.input, {
          [styles.dark]: thumbnailBrightness === 'dark',
        })}
        type="text"
        value={headerName}
        onChange={onChange}
        onBlur={rename}
        onKeyDown={(e) => {
          if (e.key === KeyCodes.ENTER) {
            rename();
          }
        }}
        autoFocus
      />
    </div>
  ) : (
    <div
      onDoubleClick={onDoubleClick}
      className={cn(styles.title, {
        [styles.dark]: thumbnailBrightness === 'dark',
        [styles.archived]: isArchived,
      })}
    >
      <TypographyPoppins className={styles.name} type="display" size="M">
        {headerName}
      </TypographyPoppins>
    </div>
  );
};

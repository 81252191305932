import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import styles from '../styles.module.css';

export const Columns = () => {
  return (
    <div className={styles.columns}>
      <TypographyPoppins type="body" size="S" className={styles.name}>
        Name
      </TypographyPoppins>

      <TypographyPoppins type="body" size="S" className={styles.location}>
        Original location
      </TypographyPoppins>

      <TypographyPoppins type="body" size="S" className={styles.date}>
        Deleted on
      </TypographyPoppins>

      <TypographyPoppins type="body" size="S" className={styles.user}>
        By
      </TypographyPoppins>
    </div>
  );
};

import { createEvent, createStore, sample } from 'effector';
import { hotkey } from 'effector-hotkey';

export const boardRenamingDialogOpened = createEvent();
export const boardRenamingDialogClosed = createEvent();

export const boardIdSelected = createEvent<string>();
export const boardIdCleared = createEvent();

export const boardNameEntered = createEvent<string>();
export const boardNameCleared = createEvent();

export const boardDescriptionEntered = createEvent<string>();
export const boardDescriptionCleared = createEvent();

export const $isShowBoardRenamingDialog = createStore(false);
export const $boardId = createStore<string | null>(null);
export const $boardName = createStore('');
export const $boardDescription = createStore('');

$isShowBoardRenamingDialog
  .on(boardRenamingDialogOpened, () => true)
  .reset(boardRenamingDialogClosed);

$boardId.on(boardIdSelected, (_, id) => id).reset(boardIdCleared);

$boardName
  .on(boardNameEntered, (_, boardName) => boardName)
  .reset(boardNameCleared);

$boardDescription
  .on(boardDescriptionEntered, (_, boardDescription) => boardDescription)
  .reset(boardDescriptionCleared);

sample({
  clock: boardRenamingDialogClosed,
  target: [boardIdCleared, boardNameCleared, boardDescriptionCleared],
});

hotkey({
  key: 'Escape',
  filter: $isShowBoardRenamingDialog,
  target: boardRenamingDialogClosed,
});

import { useState } from 'react';

import {
  BoardMove,
  Dropdown,
  IconButton,
  Item,
  TeamIcon,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { Icon } from '@visualist/icons';

import { BoardResponse } from '@api/boards';
import { BoardShare } from '@pages/BoardPage';
import { boardIdPassed } from '@pages/BoardPage/model';
import { archiveBoardIdSelected } from '@pages/BoardPage/model/archive';
import { deleteBoardModalOpened } from '@src/entities/delete-modals/model';
import { archiveDialogOpened } from '@src/entities/dialogs/archive/model';
import { locationSelected } from '@src/entities/dialogs/board/move/model/move';
import { moveBoardDialogOpened } from '@src/entities/dialogs/board/move/model/show-move-dialog';
import {
  boardDescriptionEntered,
  boardIdSelected,
  boardNameEntered,
  boardRenamingDialogOpened,
} from '@src/entities/dialogs/board/rename/model';
import { unarchiveDialogOpened } from '@src/entities/dialogs/unarchive/model';
import {
  boardShareSheetOpened,
  idSelected,
} from '@src/entities/share-sheet/board/model/open-share-sheet';
import { useInvitees } from '@src/entities/share-sheet/board/model/queries/use-invitees';
import { entityTypeSelected } from '@src/shared/utils/get-entity-type';

import styles from './styles.module.css';

type Props = {
  board: BoardResponse;
  shareToWeb: (published: boolean, allow_comments?: boolean) => Promise<void>;
  boardShare: BoardShare | null;
};

export const Title = ({ board }: Props) => {
  const [isOpenDropdown, setOpenDropdown] = useState(false);

  const { isEditor } = useInvitees({
    id: board.hub && isOpenDropdown ? board.id : '',
  });

  const copyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${import.meta.env.VITE_BASE_URL}/b/${board.id}`,
      );

      startedSnack({
        label: 'Copied link to board',
        close: true,
      });
    } catch (error) {
      startedSnack({
        label: "Couldn't copy link to board",
        action: {
          label: 'Try again',
          action: () => {
            copyLink();
          },
        },
        close: true,
      });
    }
  };

  const rename = () => {
    boardRenamingDialogOpened();
    boardNameEntered(board.name);
    boardIdSelected(board.id);

    if (board.description) {
      boardDescriptionEntered(board.description);
    }
  };

  const invite = () => {
    boardShareSheetOpened();
    idSelected(board.id);
  };

  const archive = () => {
    entityTypeSelected('Board');
    archiveBoardIdSelected(board.id);
    archiveDialogOpened();
  };

  const unarchive = () => {
    entityTypeSelected('Board');
    archiveBoardIdSelected(board.id);
    unarchiveDialogOpened();
  };

  const deleteBoard = () => {
    deleteBoardModalOpened({
      boardId: board.id,
      parentBoardId: board.parent?.id,
      hubId: board.hub?.id,
      level: board.level,
    });
    boardIdPassed(board.id);
  };

  const menuItems: Item<Variant>[] = [
    {
      leadingIcon: <Icon name="sprite/link" size={21} />,
      content: 'Copy link',
      onClick: () => copyLink(),
    },
    ...(isEditor() && !board.is_archived
      ? [
          {
            leadingIcon: <Icon name="sprite/pen" />,
            content: 'Rename',
            onClick: rename,
          },
        ]
      : []),
    ...(isEditor() && !board.is_archived
      ? [
          {
            leadingIcon: <BoardMove />,
            content: 'Move board',
            onClick: () => {
              moveBoardDialogOpened();
              locationSelected(board);
            },
          },
        ]
      : []),
    ...(board.hub && !board.is_archived
      ? [
          {
            leadingIcon: <TeamIcon />,
            content: 'Invite',
            onClick: invite,
          },
        ]
      : []),
    {
      leadingIcon: <Icon name="sprite/archive" />,
      content: board.is_archived ? 'Unarchive' : 'Archive',
      isDivider: true,
      onClick: board.is_archived ? unarchive : archive,
    },
    ...(isEditor()
      ? [
          {
            leadingIcon: <Icon name="sprite/bin" className={styles.delete} />,
            content: 'Move to bin',
            onClick: () => deleteBoard(),
            classNameContent: styles.delete,
          },
        ]
      : []),
  ];

  return (
    <div className={styles.title}>
      <TypographyPoppins type="display" size="M" className={styles.name}>
        {board.name}
      </TypographyPoppins>
      <Dropdown open={isOpenDropdown} onOpenChange={setOpenDropdown}>
        <Dropdown.Menu
          trigger={
            <IconButton
              type="unfilled"
              icon={<Icon name="sprite/caret-down" />}
              isSelected={isOpenDropdown}
              onClick={() => {}}
            />
          }
          side="bottom"
          align="end"
          density="-2"
        >
          {menuItems.map((item, index) => (
            <Dropdown.MenuItem key={index} item={item} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

import { AxiosResponse } from 'axios';

import { Plan } from '@pages/AccountPage/components/types';

import { api } from './services';

// Agreed with BE on the types it could be
const MEMBERSHIP_TIER = [
  'free',
  'free_forever',
  'hobby_yearly',
  'hobby_monthly',
  'pro_yearly',
  'pro_monthly',
  'pro_monthly_trial',
];

type MembershipTier = (typeof MEMBERSHIP_TIER)[number];

export type UserTierInfo = {
  billing: number;
  user: number;
  hub_limit: number;
  hubs_billing: {
    hubs_number: number;
    hubs_number_unit_price: number;
  };
  docs_limit: number;
  ai_request_limit: number;
  current_membership_tier: string;
  current_membership_period_end: number;
  currently_applied_promotion: {
    active: boolean;
    promotion_code: string;
    date_valid_until: Date;
  };
  next_membership_tier?: string;
  trial: TrialInfoResponse;
};

type TrialInfoResponse = {
  is_on_trial: boolean;
  has_had_trial: boolean;
};

export type ProductsInfoResponse = ProductInfoResponse[];

type ProductInfoResponse = {
  id: number;
  stripe_product_id: string;
  stripe_price_id: string;
  product_name: string;
  description: string;
  unit_price: number;
  created: string;
  type: string;
  lookup_key: string;
};

export type MappedMembershipProduct = {
  id: number;
  stripeProductId: string;
  stripePriceId: string;
  productName: string;
  description: string;
  unitPrice: number;
  created: string;
  type: string;
  lookupKey: string;
};

export type MembershipTierInfo = {
  plan: Plan;
  isAnnual: boolean;
  planEndDate: Date;
};

export type CheckoutResponse = {
  data: {
    upcoming_membership_tier: MembershipTier | null;
    current_membership_tier: MembershipTier;
    checkout_url: string;
  };
  state: {
    status: 'modify_success';
    message: string;
  };
};

export const parseMembershipTier = (s: string) => {
  if (MEMBERSHIP_TIER.includes(s)) {
    const membershipTier = s;

    if (s.includes('trial')) {
      return {
        plan: 'trial' as Plan,
        isAnnual: false,
      };
    }

    const isAnnual = membershipTier.split('_')[1] === 'yearly';
    if (s.includes('hobby')) {
      return {
        plan: 'starter' as Plan,
        isAnnual,
      };
    }

    const plan = membershipTier.split('_')[0] as Plan;
    return { plan, isAnnual };
  }

  throw new Error('Invalid membership tier');
};

export type LookupKey = (typeof MEMBERSHIP_TIER)[number];

export const generateLookupKey = (plan: Plan, isAnnual: boolean) => {
  if (plan === 'free') return 'free_forever';
  if (plan === 'trial') return 'pro_monthly_trial';
  if (plan === 'starter') plan = 'hobby' as Plan;
  const key = `${plan}_${isAnnual ? 'yearly' : 'monthly'}`;

  if (MEMBERSHIP_TIER.includes(key)) {
    return key as LookupKey;
  }

  throw new Error('Invalid lookup key');
};

export const getUserBillingInfo = async (): Promise<
  AxiosResponse<UserTierInfo>
> => api.get('/billing/membership/info');

export const getProductInfo = async (): Promise<
  AxiosResponse<ProductsInfoResponse>
> => api.get('/billing/membership/products');

export const postCheckoutPlan = async (
  stripePriceId: string,
  redirectURL: string,
): Promise<AxiosResponse<CheckoutResponse>> =>
  api.post(`/billing/membership/modify/`, {
    stripe_price_id: stripePriceId,
    redirect_url: redirectURL,
  });

// Hub credits
type HubCreditBalanceResponse = {
  hub_credits_available: number;
};

type HubProductsResponse = HubCreditProduct[];

export type HubCreditProduct = {
  id: number;
  stripe_product_id: string;
  stripe_price_id: string;
  product_name: string;
  description: string;
  unit_price: number;
  created: string;
  type: string;
  lookup_key: LookupKeyHubCredit;
};

export type LookupKeyHubCredit =
  | 'credit_pack_500'
  | 'credit_pack_250'
  | 'credit_pack_100'
  | 'credit_pack_10'
  | 'credit_pack_1';

export const getHubCreditsBalance =
  async (): Promise<HubCreditBalanceResponse> => {
    const { data } = await api.get('/billing/hubs/credit_balance/');
    return data;
  };

export const getHubProducts = async (): Promise<HubProductsResponse> => {
  const { data } = await api.get('/billing/hubs/products/');
  return data;
};

type HubCreditResponse = {
  data: {
    checkout_url: string;
    credits_added: number;
  };
  state: {
    status: 'checkout_created';
    message: string;
  };
};

export type PurchasePurpose =
  | 'hub_unlock'
  | 'hub_shortfall_create'
  | 'hub_shortfall_restore'
  | 'hub_shortfall_undo_archive'
  | 'credit_pack';

/**
 * @name postHubCreditBundle
 * @description
 * This function is used to purchase hub credits
 *
 * @param {string} stripePriceId - The stripe price id
 * @param {string} redirectURL - The redirect URL
 * @param {PurchasePurpose} purchasePurpose - The purchase purpose
 * @param {number} quantity - The quantity
 * @param {number} purchaseCharge - The purchase total
 * @param {string} purchaseName - Hub name when purchasing credit shortfall
 */

export const postHubCreditBundle = async ({
  stripePriceId,
  redirectURL,
  purchasePurpose,
  quantity,
  purchaseCharge,
  purchaseName,
  purchaseId,
}: {
  stripePriceId: string;
  redirectURL: string;
  purchasePurpose?: PurchasePurpose;
  quantity: number;
  purchaseCharge: number;
  purchaseName?: string;
  purchaseId?: string;
}): Promise<AxiosResponse<HubCreditResponse>> =>
  api.post(`/billing/hubs/checkout/`, {
    stripe_price_id: stripePriceId,
    redirect_url: redirectURL,
    quantity,
    purchase_purpose: purchasePurpose,
    purchase_charge: purchaseCharge,
    purchase_name: purchaseName,
    purchase_id: purchaseId,
  });

type CustomerPortalResponse = {
  customer_session_url: string;
};

// Manage billing info
export const manageBillingInfo = async (): Promise<
  AxiosResponse<CustomerPortalResponse>
> => {
  const data = await api.post(`/billing/customer_portal/`);
  return data;
};

// Remove promotion
export const removePromoCode = async (
  promoCode: string,
): Promise<AxiosResponse> => {
  const { data } = await api.delete(
    `/billing/promotions?promotion_code=${promoCode}`,
  );
  return data;
};

// Apply promotion
type PromoCodeResponse = {
  state: {
    status: string;
  };
};

export const applyPromoCode = async (
  promoCode: string,
): Promise<PromoCodeResponse> => {
  const { data } = await api.post('/billing/promotions', {
    promotion_code: promoCode,
  });
  return data;
};

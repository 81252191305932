import { ChangeEvent, useRef, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import useOnclickOutsideMultiple from 'react-cool-onclickoutside';
import { isMobile } from 'react-device-detect';
import { useParams } from 'react-router';

import {
  AssistChip,
  ColorWheelRingColored,
  Dropdown,
  IconButton,
  Item,
  Palette,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { Icon } from '@visualist/icons';

import { ColorSwatch, ColorTheme } from '@api/services';
import { Role } from '@api/users';
import { useAddColorPalette } from '@pages/FileCard/model/queries/useAddColorPalette';
import { hexToRgb, isLightColor } from '@src/shared/constants/colours';

import {
  $activeTheme,
  $openDropdown,
  dropdownClosed,
  dropdownOpened,
  themeSelected,
  themeUnselected,
} from '../model/active-theme';
import {
  $filledSwatches,
  $isRenamingTheme,
  $isThemeCreation,
  $themeName,
  swatchAdded,
  swatchRemoved,
  themeCreationCompleted,
  themeNameChanged,
  themeNameCleared,
  themePlaceholderCleared,
  themeRenamingActivated,
  themeRenamingDeactivated,
} from '../model/create-theme';
import {
  $currentSwatches,
  $existingThemePlaceholder,
  $isExistingThemeUpdating,
  colorCodesDialogOpened,
  exisgingThemeUpdatingEnabled,
  existingThemeUpdatingCompleted,
  swatchCleared,
  swatchUpdated,
  themeUpdatingStarted,
} from '../model/edit-theme';
import { useCreateColor } from '../model/queries/use-create-color';
import { useEditColor } from '../model/queries/use-edit-color';
import { useRemoveColorTheme } from '../model/queries/use-remove-color-theme';
import { useRenameColorTheme } from '../model/queries/use-rename-color-theme';

import styles from './styles.module.css';

type Props = {
  palette: ColorTheme;
  fileId: string;
  themeIndex: number;
  role: Role | null;
  isArchived: boolean;
};

export const Theme = ({
  palette,
  fileId,
  themeIndex,
  role,
  isArchived,
}: Props) => {
  return (
    <>
      <Name palette={palette} themeIndex={themeIndex} />
      <Swatches
        palette={palette}
        themeIndex={themeIndex}
        fileId={fileId}
        role={role}
        isArchived={isArchived}
      />
    </>
  );
};

const Name = ({
  palette,
  themeIndex,
}: {
  palette: ColorTheme;
  themeIndex: number;
}) => {
  const activeTheme = useUnit($activeTheme);
  const isRenamingTheme = useUnit($isRenamingTheme);

  const themeNameRef = useRef<HTMLDivElement>(null);

  const onBlur = () => {
    themeRenamingDeactivated();

    if (themeNameRef.current) {
      themeNameRef.current.scrollLeft = 0;
    }
  };

  return (
    <div className={styles.name}>
      {palette.type === 'custom_palette' ? (
        <div className={styles.customTheme}>
          <div
            style={{
              overflowX: 'hidden',
              whiteSpace: 'nowrap',
            }}
            ref={themeNameRef}
            className={styles.themeName}
            contentEditable={isRenamingTheme}
            onBlur={onBlur}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onBlur();
              }
            }}
            onInput={(e) => {
              if (e.currentTarget.textContent) {
                themeNameChanged(e.currentTarget.textContent);
              }
            }}
          >
            <TypographyPoppins type="label" size="M" className={styles.label}>
              {palette.theme}
            </TypographyPoppins>
          </div>

          {activeTheme === themeIndex && !isRenamingTheme && (
            <IconButton
              className={styles.pen}
              type="unfilled"
              icon={<Icon name="sprite/pen" size={16} />}
              onClick={(e) => {
                e.stopPropagation();
                themeRenamingActivated();

                setTimeout(() => {
                  if (themeNameRef.current) {
                    themeNameRef.current.focus();
                    const range = document.createRange();
                    const selection = window.getSelection();
                    range.selectNodeContents(themeNameRef.current);
                    range.collapse(false);
                    selection?.removeAllRanges();
                    selection?.addRange(range);
                  }
                }, 0);
              }}
            />
          )}
        </div>
      ) : (
        <>
          <TypographyPoppins type="label" size="M" className={styles.label}>
            {palette.theme}
          </TypographyPoppins>
          <TooltipRadix
            description={getDescription(palette.theme)}
            side="right"
          >
            <span style={{ display: 'flex' }}>
              <Icon name="sprite/information" size={16} />
            </span>
          </TooltipRadix>
        </>
      )}
    </div>
  );
};

const Swatches = ({
  palette,
  themeIndex,
  fileId,
  role,
  isArchived,
}: {
  palette: ColorTheme;
  themeIndex: number;
  fileId: string;
  role: Role | null;
  isArchived: boolean;
}) => {
  const [swatchId, setSwatchId] = useState<string | null>(null);
  const [localHex, setLocalHex] = useState('');
  const [isOpenColorPicker, setOpenColorPicker] = useState(false);

  const openDropdown = useUnit($openDropdown);
  const activeTheme = useUnit($activeTheme);
  const isThemeCreation = useUnit($isThemeCreation);
  const themeName = useUnit($themeName);
  const filledSwatches = useUnit($filledSwatches);
  const currentSwatches = useUnit($currentSwatches);

  const existingThemePlaceholder = useUnit($existingThemePlaceholder);
  const isExistingThemeUpdating = useUnit($isExistingThemeUpdating);

  const { board_id } = useParams<{ board_id: string }>();

  const ref = useOnclickOutsideMultiple(() => setSwatchId(null), {
    eventTypes: ['mousedown'],
  });

  const { addColorPalette } = useAddColorPalette();

  const savePalette = (theme: string) => {
    if (board_id) {
      addColorPalette({ id: fileId, theme, board: board_id });
    } else {
      addColorPalette({ id: fileId, theme });
    }
  };

  const { createColor } = useCreateColor(fileId);
  const { editColor } = useEditColor(fileId);
  const { removeColorTheme } = useRemoveColorTheme(fileId);
  const { renameTheme } = useRenameColorTheme(fileId);

  const done = () => {
    const theme = activeTheme === themeIndex && palette.swatches;

    if (
      JSON.stringify(theme) === JSON.stringify(currentSwatches[0].swatches) &&
      !themeName
    ) {
      themeUnselected();
      themePlaceholderCleared();
      themeNameCleared();
      themeCreationCompleted();
      existingThemeUpdatingCompleted();

      return;
    }

    if (isThemeCreation && filledSwatches[0].swatches.length > 0) {
      createColor({
        block: fileId,
        swatches: filledSwatches[0].swatches,
        theme: themeName ? themeName : 'Custom',
        theme_create: true,
      });
    } else if (
      isExistingThemeUpdating &&
      !themeName &&
      currentSwatches[0].swatches.length > 0
    ) {
      editColor({
        block: fileId,
        swatches: currentSwatches[0].swatches,
        theme: themeName ? themeName : currentSwatches[0].theme,
      });
    } else if (
      isExistingThemeUpdating &&
      themeName &&
      currentSwatches[0].swatches.length > 0
    ) {
      renameTheme({
        blockId: fileId,
        current_name: currentSwatches[0].theme,
        new_name: themeName,
        isEditColor:
          JSON.stringify(theme) !== JSON.stringify(currentSwatches[0].swatches),
      });
    } else if (
      isExistingThemeUpdating &&
      currentSwatches[0].swatches.length === 0
    ) {
      removeColorTheme({
        blockId: fileId,
        theme: currentSwatches[0].theme,
      });
    }

    themeUnselected();
    themePlaceholderCleared();
    themeNameCleared();
    themeCreationCompleted();
    existingThemeUpdatingCompleted();
  };

  const setColor = (e: ChangeEvent<HTMLInputElement>, swatch: ColorSwatch) => {
    let rgb_color = null;
    let hexColor = null;

    if (isMobile) {
      rgb_color = hexToRgb(e.target.value);
      hexColor = e.target.value;
    } else if (localHex) {
      rgb_color = hexToRgb(localHex);
      hexColor = localHex;
    }

    if (isThemeCreation && rgb_color) {
      swatchAdded({
        rgb_color,
        hex_color: hexColor || swatch.hex_color,
        order: swatch.order,
      });
    } else if (isExistingThemeUpdating && rgb_color) {
      swatchUpdated({
        rgb_color,
        hex_color: hexColor || swatch.hex_color,
        order: swatch.order,
      });
    }
  };

  const menuItems: Item<Variant>[] = [
    ...(role === 'Editor' || role === null
      ? [
          {
            leadingIcon: <Palette fill="none" />,
            content: 'Save theme as palette',
            onClick: () => savePalette(palette.theme),
          },
        ]
      : []),
    ...(role === 'Editor' || role === null
      ? [
          {
            leadingIcon: (
              <ColorWheelRingColored
                className={styles.colorWheelRing}
                size={30}
              />
            ),
            content: 'Adjust colors',
            onClick: () => {
              themeSelected(themeIndex);
              themeUpdatingStarted(palette);
              exisgingThemeUpdatingEnabled();
            },
            classNameContent: styles.adjustColors,
          },
        ]
      : []),
    {
      leadingIcon: <Icon name="sprite/hashtag" />,
      content: 'See color codes',
      onClick: () => {
        themeUpdatingStarted(palette);
        colorCodesDialogOpened();
      },
      classNameleadingIcon: styles.hashtag,
    },
  ];

  return (
    <div className={styles.colors}>
      <div
        className={cn(styles.swatches, {
          [styles.inactive]:
            typeof activeTheme === 'number' && activeTheme !== themeIndex,
        })}
      >
        {palette.swatches
          ? getSwatches({
              swatches:
                activeTheme === themeIndex &&
                existingThemePlaceholder[0].swatches.length > 0 &&
                isExistingThemeUpdating
                  ? existingThemePlaceholder[0].swatches
                  : palette.swatches,
              isActiveTheme: activeTheme === themeIndex,
            }).map((swatch, swatchIndex) => {
              return (
                <div
                  key={swatch.id}
                  style={{
                    backgroundColor:
                      swatch.id === swatchId && localHex
                        ? localHex
                        : swatch.hex_color,
                    boxShadow: swatch.rgb_color
                      ? `0px 1px 2px 1px rgba(${swatch.rgb_color}, 0.15), 0px 1px 2px rgba(${swatch.rgb_color}, 0.30)`
                      : undefined,
                    position: 'relative',
                    left: swatchIndex === 0 ? '0px' : `-${swatchIndex * 10}px`,
                    zIndex: swatchId === swatch.id ? 1 : 0,
                  }}
                  className={cn(styles.color, {
                    [styles.light]: isLightColor(swatch.rgb_color),
                    [styles.emptyColor]: !swatch.hex_color,
                    [styles.activeTheme]: activeTheme === themeIndex,
                  })}
                  onMouseEnter={() => {
                    if (activeTheme === themeIndex && !isOpenColorPicker) {
                      setSwatchId(swatch.id);
                    }
                  }}
                  onMouseLeave={() => {
                    if (!isOpenColorPicker) {
                      setSwatchId(null);
                    }
                  }}
                  onClick={() => {
                    if (activeTheme === themeIndex) {
                      setOpenColorPicker(true);
                      setSwatchId(swatch.id);
                    }
                  }}
                >
                  {swatch.hex_color === '' && (
                    <Icon name="sprite/plus" className={styles.plus} />
                  )}
                  {swatchId === swatch.id && swatch.hex_color && (
                    <>
                      <ColorWheelRingColored />
                      {
                        <IconButton
                          ref={ref}
                          className={styles.removeColor}
                          type="primary"
                          icon={
                            <TooltipRadix description="Remove color">
                              <Icon
                                name="sprite/x"
                                size={12}
                                className={styles.x}
                              />
                            </TooltipRadix>
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            swatchCleared(swatch.order);
                            swatchRemoved(swatch.order);
                            setOpenColorPicker(false);
                          }}
                        />
                      }
                    </>
                  )}

                  {activeTheme === themeIndex && (
                    <input
                      className={styles.colorWheelInput}
                      ref={ref}
                      type="color"
                      value={swatch.hex_color}
                      onBlur={(e) => {
                        if (isMobile) {
                          return;
                        } else {
                          setColor(e, swatch);
                          setLocalHex('');
                          setOpenColorPicker(false);
                        }
                      }}
                      onChange={(e) => {
                        if (isMobile) {
                          setColor(e, swatch);
                        } else {
                          setLocalHex(e.target.value);
                        }
                      }}
                    />
                  )}
                </div>
              );
            })
          : null}
      </div>
      <div className={styles.dropdown}>
        {activeTheme === themeIndex ? (
          <AssistChip
            className={styles.done}
            leadingIcon
            onClick={done}
            style="outlined"
          >
            Done
          </AssistChip>
        ) : (
          <>
            <IconButton
              className={cn(styles.iconButton, {
                [styles.hidden]:
                  typeof activeTheme === 'number' && activeTheme !== themeIndex,
              })}
              type="outlined"
              icon={
                openDropdown === themeIndex ? (
                  <Icon
                    name="sprite/chevron-up"
                    color="var(--color-neutral-variant-30)"
                    size={12}
                  />
                ) : (
                  <Icon
                    name="sprite/chevron-down"
                    color={
                      isArchived
                        ? 'var(--color-neutral-variant-90)'
                        : 'var(--color-neutral-variant-30)'
                    }
                    size={12}
                  />
                )
              }
              isSelected={openDropdown === themeIndex}
              onClick={() => dropdownOpened(themeIndex)}
              isDisabled={isArchived}
            />
            <Dropdown
              open={openDropdown === themeIndex}
              onOpenChange={dropdownClosed}
            >
              <Dropdown.Menu
                side="bottom"
                sideOffset={8}
                align="end"
                density="-2"
              >
                {menuItems.map((item, index) => (
                  <Dropdown.MenuItem key={index} item={item} />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </div>
    </div>
  );
};

function getDescription(theme: string) {
  switch (theme) {
    case 'Colorful':
      return 'A variety of colors';
    case 'Scientific':
      return 'Based on our proprietary algorithm that uses K-means';
    case 'Light':
      return 'Light colors are desaturated and bright';
    case 'Dark':
      return 'Dark colors are less bright with a sense of depth';
    case 'Warm':
      return 'Warm colors are based on variations of red, orange and yellow';
    case 'Cool':
      return 'Cool colors are based on variations of green, blue and purple';
    case 'Neutral':
      return 'Neutral colors are muted warm and cool hues';
    case 'Monochromatic':
      return 'Monochromatic colors are variations of a frequent color';
  }
}

function getSwatches({
  swatches,
  isActiveTheme,
}: {
  swatches: ColorSwatch[];
  isActiveTheme: boolean;
}) {
  const filledSwatches = swatches.map((swatch) => swatch.order);

  const emptySwatches = Array.from({ length: 5 }, (_, i) => i + 1)
    .filter((order) => !filledSwatches.includes(order))
    .map((order) => ({
      hex_color: '',
      hsl_color: '',
      hsv_color: '',
      id: `empty-${order}`,
      order,
      rgb_color: '',
      theme: '',
    }));

  const allSwatches = [...swatches, ...emptySwatches];

  if (isActiveTheme) {
    return allSwatches.sort((a, b) => a.order - b.order);
  } else {
    return allSwatches;
  }
}

import { createEvent, createStore, sample } from 'effector';

import { Swatches } from '@visualist/design-system/src/components/v2';

export const colorMenuToggled = createEvent();
export const colorMenuClosed = createEvent();

export const backgroundSelected = createEvent<string>();
export const backgroundCleared = createEvent();

export const $isOpenColorMenu = createStore(false);

$isOpenColorMenu
  .on(colorMenuToggled, (isOpen) => !isOpen)
  .reset(colorMenuClosed);

export const $colors = createStore<Swatches>({
  swatches: [
    { name: 'Transparent', color: 'transparent' },
    { name: '#CC6A00', color: '#CC6A00' },
    { name: '#6B1888', color: '#6B1888' },
    { name: '#003C00', color: '#003C00' },
    { name: '#68402E', color: '#68402E' },
    { name: '#43424A', color: '#43424A' },
    { name: '#1F1F1F', color: '#1F1F1F' },
  ],
});

export const $background = createStore('');

$background
  .on(backgroundSelected, (_, background) => background)
  .reset(backgroundCleared);

export const $isCustomColor = createStore<boolean | null>(false);

// Determine whether custom colour is selected or not
sample({
  clock: $background,
  source: {
    background: $background,
    colors: $colors,
  },
  fn: ({ colors, background }) => {
    if (colors.swatches && background) {
      const isColorSwatch = colors.swatches.some((swatch) =>
        swatch.color.includes(background),
      );

      return !isColorSwatch;
    } else {
      return null;
    }
  },
  target: $isCustomColor,
});

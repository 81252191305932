import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { createdTempNewSticky } from '@pages/StudioPage/model';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  addCommentToSticker,
  addStickerToImage,
  bulkUpdateStickies,
  Comment,
  deleteCommentsOfStickersOfImage,
  deleteStickersOfImage,
  fetchCommentsOfStickersOfImage,
  fetchStickersOfImage,
  undoStickyCommentDelete,
  undoStickyDelete,
  updateStickersOfImage,
} from '../../api';
import {
  actions,
  NOTES_QUERY,
  NOTES_REPLIES_QUERY,
} from '../../shared/constants';
import { StickyType } from './types';
import { useStickyReplyAction } from './useStickyReplyAction';

export type EmptySticky = Omit<
  StickyType,
  | 'project'
  | 'reactions'
  | 'set'
  | 'parent'
  | 'sticker'
  | 'block'
  | 'number'
  | 'mention_users'
>;

export type StickyPostData = Omit<
  StickyType,
  | 'comment_count'
  | 'project'
  | 'reactions'
  | 'set'
  | 'parent'
  | 'sticker'
  | 'block'
  | 'number'
  | 'mention_users'
  | 'created_at'
  | 'id'
  | 'created_at'
  | 'created_by'
  | 'is_private'
>;

export type UpdateableStickyData = Pick<
  StickyType,
  | 'left_pixel'
  | 'top_pixel'
  | 'text'
  | 'background_hex'
  | 'updated_at'
  | 'attached_to_id'
  | 'attached_to_type'
>;

type HasCreatedAt = {
  created_at: string; // or Date, or number, depending on what `created_at` actually is
};

const createdAtSort = (a: HasCreatedAt, b: HasCreatedAt) =>
  new Date(a.created_at).getTime() - new Date(b.created_at).getTime();

const transformStickyData = (data: StickyType[]) => {
  return {
    stickies: data,
  };
};

export const useStickies = (imageId: string, activeSticky: string | null) => {
  const queryClient = useQueryClient();

  const { createStickyReplyAction } = useStickyReplyAction(activeSticky ?? '');

  const stickiesQuery = useQuery({
    queryKey: [NOTES_QUERY, { imageId }],
    queryFn: async () => {
      const { data } = await fetchStickersOfImage(imageId);
      return data;
    },
    select: transformStickyData,
    // refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    gcTime: 0,
  });

  const optimisticallyUpdateStickyUpdateAt = (stickyId: string) => {
    const stickies = queryClient.getQueryData<StickyType[]>([
      NOTES_QUERY,
      { imageId },
    ]);

    if (!stickies) return;

    const newStickies = [
      ...stickies.map((s) => ({
        ...s,
      })),
    ];

    const index = newStickies.findIndex((s) => s.id === stickyId);

    if (index === -1) return;
    newStickies[index] = {
      ...newStickies[index],
      updated_at: new Date().toISOString(),
    };
    queryClient.setQueryData([NOTES_QUERY, { imageId }], newStickies);
  };

  const removeNewStickies = () => {
    const stickies = queryClient.getQueryData<StickyType[]>([
      NOTES_QUERY,
      { imageId },
    ]);

    if (!stickies) return;

    const newStickies = stickies.filter((s) => {
      return s.id !== 'new-sticky';
    });

    queryClient.setQueryData([NOTES_QUERY, { imageId }], newStickies);

    return newStickies;
  };

  const addStickyMutation = useMutation({
    mutationFn: ({ data }: { data: StickyPostData }) =>
      addStickerToImage(imageId, { ...data, is_private: true }),
    onMutate: async () => {
      await queryClient.cancelQueries();
    },
    mutationKey: ['ADD_STICKY_MUTATION', { imageId }],
    onSuccess: (data) => {
      // Remove from model in studio
      createdTempNewSticky(null);

      // Optimistic update
      const stickies = queryClient.getQueryData<StickyType[]>([
        NOTES_QUERY,
        { imageId },
      ]);

      if (!stickies || !data.id) return;

      const clonedStickies = [
        ...stickies.map((s) => ({
          ...s,
        })),
      ];

      const index = clonedStickies.findIndex((s) => s.id === 'new-sticky');

      // Do nothing because no new sticky so append the new sticky
      if (index === -1) return;

      clonedStickies.splice(index, 1, {
        ...data,
      });

      queryClient.setQueryData([NOTES_QUERY, { imageId }], clonedStickies);

      return {
        data,
      };
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY, { imageId }],
      });
    },
    onError: (err, variables, context) => {
      // TODO Get auto inference here https://tkdodo.eu/blog/react-query-and-type-script#optimistic-updates
      const ctx = context as unknown as { stickies: StickyType[] };
      queryClient.setQueryData([NOTES_QUERY, { imageId }], ctx.stickies);
    },
  });

  // In studio the process of an optimistic sticky is controlled from a custom effector var. This is only used for file cards.
  const addNewStickyOptimistic = (unsavedSticky: EmptySticky) => {
    const stickies = queryClient.getQueryData<StickyType[]>([
      NOTES_QUERY,
      { imageId },
    ]);

    if (!stickies) return;

    // Check if placeholder sticky exists
    // update its position if now text, otherwise do nothing
    const index = stickies.findIndex((s) => s.id === 'new-sticky');
    if (index !== -1) {
      const newStickies = [
        ...stickies.map((s) => ({
          ...s,
        })),
      ];

      // if (newStickies[index].text) return;

      newStickies[index] = {
        ...newStickies[index],
        left_pixel: unsavedSticky.left_pixel,
        top_pixel: unsavedSticky.top_pixel,
      };

      queryClient.setQueryData([NOTES_QUERY, { imageId }], newStickies);
      return;
    }

    const newStickies = [
      ...stickies,
      {
        ...unsavedSticky,
      },
    ];

    queryClient.setQueryData([NOTES_QUERY, { imageId }], newStickies);
  };

  const repliesQuery = useQuery({
    queryKey: [NOTES_REPLIES_QUERY, { stickyId: activeSticky }],
    queryFn: () => fetchCommentsOfStickersOfImage(activeSticky),
    enabled: activeSticky !== null,
  });

  const replies =
    (repliesQuery.data &&
      repliesQuery.data.sort(createdAtSort).map((item: Comment) => {
        if (!stickiesQuery.data)
          return {
            // Default to is_private
            ...item,
            is_private: true,
          };
        const active = stickiesQuery.data.stickies.filter(
          ({ id }: StickyType) => id === activeSticky,
        )[0];

        return {
          ...item,
          is_private: active?.is_private,
        };
      })) ||
    [];

  const mutateSticky = useMutation({
    // Update stickers actually updates everything of a sticky
    mutationFn: ({
      stickyId,
      data,
    }: {
      stickyId: string;
      data: UpdateableStickyData;
    }) => updateStickersOfImage(stickyId, data),
    onMutate: async (variables) => {
      await queryClient.cancelQueries();
      const stickies = queryClient.getQueryData<StickyType[]>([
        NOTES_QUERY,
        { imageId },
      ]);

      if (!stickies) return;

      const newStickies = [
        ...stickies.map((s) => ({
          ...s,
        })),
      ];

      const index = newStickies.findIndex((s) => s.id === variables.stickyId);
      if (index === -1) return;

      newStickies[index] = {
        ...newStickies[index],
        ...variables.data,
      };

      queryClient.setQueryData([NOTES_QUERY, { imageId }], newStickies);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY, { imageId }],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const mutateReply = useMutation({
    mutationFn: ({
      stickyId,
      text,
    }: {
      stickyId: string;
      text: string;
      makeAction?: boolean;
    }) => addCommentToSticker(stickyId, { text: text, is_private: false }),
    onMutate: async () => {
      await queryClient.cancelQueries();
    },
    onSuccess: async (data, variables) => {
      if (variables.makeAction) {
        createStickyReplyAction.mutate({
          stickyId: data.id,
          status: 'open',
          isReply: true,
        });
        queryClient.invalidateQueries({
          queryKey: actions.all,
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: [NOTES_REPLIES_QUERY, { stickyId: activeSticky }],
        });
        queryClient.invalidateQueries({
          queryKey: [NOTES_QUERY, { imageId }],
        });
      }
    },
    onError: (_, variables) => {
      startedSnack({
        label: "Couldn't send reply",
        action: {
          action: () => {
            mutateReply.mutate(variables);
          },
          label: 'Try again',
        },
        close: true,
      });
    },
  });

  const mutateCommentDelete = useMutation({
    mutationFn: ({
      stickyId,
      commentId,
    }: {
      stickyId: string;
      commentId: string;
    }) => deleteCommentsOfStickersOfImage(stickyId, commentId),
    onMutate: async () => {
      await queryClient.cancelQueries();
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_REPLIES_QUERY, { stickyId: activeSticky }],
      });
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY, { imageId }],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
      startedSnack({
        label: 'Deleted reply',
        action: {
          label: 'Undo',
          action: () => {
            mutateCommentUndoDelete.mutate({
              stickyId: variables.stickyId,
              commentId: variables.commentId,
            });
          },
        },
        close: true,
      });
    },
    onError: (_, variables) => {
      startedSnack({
        label: "Couldn't delete reply",
        action: {
          label: 'Try again',
          action: () => mutateCommentDelete.mutate(variables),
        },
        close: true,
      });
    },
  });

  const mutateStickyDelete = useMutation({
    mutationFn: ({
      id,
    }: {
      id: StickyType['id'];
      replies: StickyType['comment_count'];
    }) => deleteStickersOfImage(id),
    onMutate: async () => {
      await queryClient.cancelQueries();
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
      startedSnack({
        label: 'Deleted sticky note',
        action: {
          label: 'Undo',
          action: () => {
            mutateStickyUndoDelete.mutate({ stickyId: variables.id });
          },
        },
        close: true,
      });
    },
    onError: (_, variables) => {
      startedSnack({
        label: "Couldn't delete sticky note",
        action: {
          label: 'Try again',
          action: () => mutateStickyDelete.mutate(variables),
        },
        close: true,
      });
    },
  });

  const mutateStickyUndoDelete = useMutation({
    // Update stickers actually updates everything of a sticky
    mutationFn: ({ stickyId }: { stickyId: string }) =>
      undoStickyDelete(stickyId),
    onMutate: async () => {
      await queryClient.cancelQueries();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const mutateCommentUndoDelete = useMutation({
    // Update stickers actually updates everything of a sticky
    mutationFn: ({
      stickyId,
      commentId,
    }: {
      stickyId: string;
      commentId: string;
    }) => undoStickyCommentDelete(stickyId, commentId),
    onMutate: async () => {
      await queryClient.cancelQueries();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [NOTES_REPLIES_QUERY, { stickyId: activeSticky }],
      });
      queryClient.invalidateQueries({
        queryKey: actions.all,
      });
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const updateStickyPosition = (props: {
    stickyId: string;
    ratioX: number;
    ratioY: number;
  }) => {
    // Update sticky position
    const stickies = queryClient.getQueryData<StickyType[]>([
      NOTES_QUERY,
      { imageId },
    ]);

    if (!stickies) return;

    const newStickies = [
      ...stickies.map((s) => ({
        ...s,
      })),
    ];

    const index = newStickies.findIndex((s) => s.id === props.stickyId);
    if (index === -1) return;

    newStickies[index] = {
      ...newStickies[index],
      left_pixel: props.ratioX,
      top_pixel: props.ratioY,
    };

    queryClient.setQueryData([NOTES_QUERY, { imageId }], newStickies);
  };

  const bulkMutateStickies = useMutation({
    // Update stickers actually updates everything of a sticky
    mutationFn: ({
      data,
    }: {
      data: (UpdateableStickyData & { id: string })[];
    }) => bulkUpdateStickies(data),
    onMutate: async (variables) => {
      const previousStickies = queryClient.getQueryData<StickyType[]>([
        NOTES_QUERY,
        { imageId },
      ]);

      if (!previousStickies) return;

      const allStickies: Record<string, StickyType> = {};
      previousStickies.forEach((s) => {
        allStickies[s.id] = s;
      });

      variables.data.forEach((s) => {
        allStickies[s.id] = {
          ...allStickies[s.id],
          ...s,
        };
      });

      queryClient.setQueryData(
        [NOTES_QUERY, { imageId }],
        Object.values(allStickies),
      );

      return { previousStickies };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTES_QUERY, { imageId }],
      });
    },
    onError: (err, variables, context) => {
      console.error(err);
      if (context) {
        queryClient.setQueryData(
          [NOTES_QUERY, { imageId }],
          context.previousStickies,
        );
      }
    },
  });

  return {
    selectedSticky: stickiesQuery.data?.stickies.find(
      (s) => s.id === activeSticky,
    ),
    replies,
    repliesQuery,
    addNewStickyOptimistic,
    addStickyMutation,
    mutateSticky,
    mutateStickyDelete,
    mutateCommentDelete,
    stickiesQuery,
    removeNewStickies,
    optimisticallyUpdateStickyUpdateAt,
    mutateReply,
    updateStickyPosition,
    bulkMutateStickies,
  };
};

export default useStickies;

import { useLocation } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { archiveDoc } from '@api/docs';
import {
  ALL_DOCS_QUERY,
  DOC_QUERY,
  DOCS_OF_BOARD_QUERY,
} from '@src/shared/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useArchiveDoc = () => {
  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  const archiveDocMutation = useMutation({
    mutationFn: ({ id }: { id: string }) => archiveDoc({ id }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [DOC_QUERY, { docId: variables.id }],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_BOARD_QUERY, { board: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_DOCS_QUERY],
      });

      startedSnack({ label: 'Archived doc', close: true });
    },
    onError: (err, variables) => {
      startedSnack({
        label: 'Couldn’t archive doc',
        action: {
          action: () => {
            archiveDocMutation.mutate({ id: variables.id });
          },
          label: 'Try again',
        },
        close: true,
      });
    },
  });

  return {
    archiveDocMutation,
  };
};

import { useRef } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';

import {
  Checkbox,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { ColorCodes as Codes } from '@api/index';

import {
  $hex,
  $hsl,
  $hsv,
  $rgb,
  hexToggled,
  hslToggled,
  hsvToggled,
  rgbToggled,
} from './model/codes-managment';
import { useUpdateColorPalette } from './model/queries/use-update-color-palette';

import styles from './styles.module.css';

type Props = {
  fileId: string;
};

export const ColorCodes = ({ fileId }: Props) => {
  const hex = useUnit($hex);
  const rgb = useUnit($rgb);
  const hsl = useUnit($hsl);
  const hsv = useUnit($hsv);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { updatePalette } = useUpdateColorPalette(fileId);

  const updateCodes = (codes: Codes) => {
    updatePalette({
      codes,
    });
  };

  const selectCode = (
    type: 'hex' | 'rgb' | 'hsl' | 'hsv',
    currentValue: boolean,
  ) => {
    switch (type) {
      case 'hex':
        hexToggled();
        break;
      case 'rgb':
        rgbToggled();
        break;
      case 'hsl':
        hslToggled();
        break;
      case 'hsv':
        hsvToggled();
        break;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      updateCodes({
        hex: type === 'hex' ? !currentValue : hex,
        rgb: type === 'rgb' ? !currentValue : rgb,
        hsl: type === 'hsl' ? !currentValue : hsl,
        hsv: type === 'hsv' ? !currentValue : hsv,
      });
    }, 800);
  };

  return (
    <>
      <TypographyPoppins type="body" size="M" className={styles.label}>
        Choose which color codes to display below each swatch.
      </TypographyPoppins>
      <ul className={styles.codes}>
        <li className={styles.code}>
          <TypographyPoppins type="body" size="L" className={styles.label}>
            Hex
          </TypographyPoppins>
          <Checkbox
            style={{ padding: '0px', width: '40px', height: '40px' }}
            className={styles.checkbox}
            onClick={() => selectCode('hex', hex)}
            icon={
              <Icon name="sprite/checkbox-filled" color="#99461C" size={30} />
            }
            isSelected={hex}
          />
        </li>
        <li className={styles.code}>
          <TypographyPoppins type="body" size="L" className={styles.label}>
            RGB
          </TypographyPoppins>
          <Checkbox
            style={{ padding: '0px', width: '40px', height: '40px' }}
            className={styles.checkbox}
            onClick={() => selectCode('rgb', rgb)}
            icon={
              <Icon name="sprite/checkbox-filled" color="#99461C" size={30} />
            }
            isSelected={rgb}
          />
        </li>
        <li className={styles.code}>
          <TypographyPoppins type="body" size="L" className={styles.label}>
            HSL
          </TypographyPoppins>
          <Checkbox
            style={{ padding: '0px', width: '40px', height: '40px' }}
            className={styles.checkbox}
            onClick={() => selectCode('hsl', hsl)}
            icon={
              <Icon name="sprite/checkbox-filled" color="#99461C" size={30} />
            }
            isSelected={hsl}
          />
        </li>
        <li className={styles.code}>
          <TypographyPoppins type="body" size="L" className={styles.label}>
            HSV
          </TypographyPoppins>
          <Checkbox
            style={{ padding: '0px', width: '40px', height: '40px' }}
            className={styles.checkbox}
            onClick={() => selectCode('hsv', hsv)}
            icon={
              <Icon name="sprite/checkbox-filled" color="#99461C" size={30} />
            }
            isSelected={hsv}
          />
        </li>
        <li className={styles.code}>
          <TypographyPoppins
            type="body"
            size="L"
            className={cn(styles.label, styles.disabled)}
          >
            Pantone
          </TypographyPoppins>
          <Checkbox
            style={{ padding: '0px', width: '40px', height: '40px' }}
            className={styles.checkbox}
            isDisabled={true}
            onClick={() => {}}
          />
        </li>
      </ul>
    </>
  );
};

import { useRef, useState } from 'react';

import { useOnClickOutside } from '@visualist/hooks';

import { Member } from '@api/docs';
import { Member as MemberHub } from '@api/hubs';
import { Permissions } from '@api/users';

import { User } from '..';

type OwnerProps = {
  user: Member | MemberHub;
  permissions: Permissions[];
  currentPermissions?: Permissions[];
  handleChangePermissions?: ({
    permissions,
    member,
  }: {
    permissions: Permissions[];
    member: Member | MemberHub;
  }) => void;
  removeMember: (invitee: Member | MemberHub) => void;
  error: any;
  type?: 'doc';
  isLastMember?: boolean;
  lastEditorId?: string | number | null;
};

export const Owner = ({
  removeMember,
  user,
  type,
  currentPermissions,
  handleChangePermissions,
  error,
  isLastMember,
  lastEditorId,
}: OwnerProps) => {
  const [on, setOn] = useState(true);
  const [isShowMenu, setShowMenu] = useState(false);
  const [permissions, setPermissions] = useState<Permissions[]>(() => {
    if (user || error) {
      return user.permissions;
    } else return ['edit', 'invite'];
  });

  const permissionsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(permissionsRef, () => {
    if (isShowMenu) {
      setShowMenu(false);
    }
  });

  const getPermissions = () => {
    if (permissions.includes('edit')) {
      return 'Editor';
    } else if (permissions.includes('comment')) {
      return 'Commenter';
    } else {
      return 'Viewer';
    }
  };
  const change = (permissions: Permissions[]) => {
    if (handleChangePermissions) {
      handleChangePermissions({
        member: user,
        permissions,
      });
    }

    setPermissions(permissions);
  };

  const remove = () => {
    if (on) {
      removeMember(user);
    }
    setOn(false);
  };

  return (
    <User
      user={user}
      isShowMenu={isShowMenu}
      setShowMenu={setShowMenu}
      on={on}
      permissionsRef={permissionsRef}
      permissions={permissions}
      getPermissions={getPermissions}
      changePermissions={change}
      remove={remove}
      type={type}
      currentPermissions={currentPermissions}
      isLastMember={isLastMember}
      lastEditorId={lastEditorId}
    />
  );
};

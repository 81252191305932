import { createEvent, createStore, sample } from 'effector';

import { emailsCleared, queryCleared } from './inviting';

export const boardShareSheetOpened = createEvent();
export const boardShareSheetClosed = createEvent();

export const idSelected = createEvent<string>();
export const idCleared = createEvent();

export const $isBoardShareSheetOpen = createStore(false);
export const $id = createStore<string | null>(null);

$isBoardShareSheetOpen
  .on(boardShareSheetOpened, () => true)
  .reset(boardShareSheetClosed);

$id.on(idSelected, (_, id) => id).reset(idCleared);

sample({
  clock: boardShareSheetClosed,
  target: [idCleared, queryCleared, emailsCleared],
});

import { User } from '@src/AppContext';

import { api, BoardResponse, Permissions, RawBlockResponse } from '.';
import { ResponseDoc } from './docs';
import { HubResponse } from './hubs';

export type DeletedItemType = {
  file: 'Hub' | 'Board' | 'Doc' | 'Block';
  type: 'single' | 'group';
  id: string;
};

export type DeletedBy = Partial<
  Pick<User, 'email' | 'first_name' | 'id' | 'last_name' | 'photo'>
>;

type Item<T extends DeletedItemType['file']> = {
  deleted_at: string;
  deleted_by: DeletedBy;
  group_id: string;
  id: string;
  item_id: string;
  item_type: T;
  permissions: Permissions[];
};

type ItemWithChild<T extends DeletedItemType['file']> = T extends
  | 'Doc'
  | 'Block'
  ? Item<T>
  : Item<T> & {
      child: T extends 'Hub'
        ? HubItem[]
        : T extends 'Board'
        ? BoardItem[]
        : never;
    };

export type HubItem = ItemWithChild<'Hub'> & {
  hub: HubResponse;
};

export type BoardItem = ItemWithChild<'Board'> & {
  board: BoardResponse;
};

export type DocItem = ItemWithChild<'Doc'> & {
  doc: ResponseDoc;
};

export type BlockItem = ItemWithChild<'Block'> & {
  block: RawBlockResponse;
};

export type DeletedItem = HubItem | BoardItem | DocItem | BlockItem;

export type RecycleBinResponse = {
  count: number;
  next: string | null;
  previous: string | null;
  results: DeletedItem[];
};

export const getDeletedItems = async ({
  limit,
  offset,
}: {
  limit?: string;
  offset?: string;
}): Promise<RecycleBinResponse> => {
  const response = await api.get(`/recycle-bin/`, {
    params: {
      limit,
      offset,
    },
  });
  return response.data;
};

export const permanentDeleteAllItems = async () => {
  const { data } = await api.post(`/recycle-bin/empty/`);
  return data;
};

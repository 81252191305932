import { getInvitees, Member } from '@api/boards';
import { getInviteesHub, Member as MemberHub, NestedMember } from '@api/hubs';
import {
  MEMBERS_INVITATION_HUB_QUERY,
  MEMBERS_INVITATION_QUERY,
} from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

import { Location } from '../../../../../search-modal/location/model';

type Props = {
  originalLocationId: string | null;
  destinationLocation: Location | null;
  originalHubId: string | null;
};

export const useMembers = ({
  originalLocationId,
  destinationLocation,
  originalHubId,
}: Props) => {
  const hubId = getHubId(destinationLocation);
  const boardId = getBoardId(destinationLocation);

  const originalMembersQuery = useQuery({
    queryKey: [MEMBERS_INVITATION_QUERY, originalLocationId],
    queryFn: () => {
      if (originalLocationId) {
        return getInvitees(originalLocationId);
      }
    },
    enabled: Boolean(originalLocationId),
  });

  const hubMembersQuery = useQuery({
    queryKey: [MEMBERS_INVITATION_HUB_QUERY, hubId],
    queryFn: () => {
      if (hubId) {
        return getInviteesHub(hubId);
      }
    },
    enabled: Boolean(hubId),
  });

  const boardMembersQuery = useQuery({
    queryKey: [MEMBERS_INVITATION_QUERY, boardId],
    queryFn: () => {
      if (boardId) {
        return getInvitees(boardId);
      }
    },
    enabled: Boolean(boardId),
  });

  const original = originalMembersQuery.data?.members ?? [];

  const destination = [
    ...(destinationLocation?.type === 'hub'
      ? hubMembersQuery.data?.members ?? []
      : []),
    ...(destinationLocation?.type === 'board'
      ? boardMembersQuery.data?.members ?? []
      : []),
  ];

  const nested = hubMembersQuery.data?.nested_members ?? [];

  const memberCounts = count({ original, destination, nested });
  const members = move({ original, destination, memberCounts });

  const [originalSet, destinationSet] = getMemberSets({
    original,
    destination,
    nested,
  });

  const existing = [...originalSet].filter((email) =>
    destinationSet.has(email),
  ).length;

  const neededSeats = originalSet.size - existing;

  const hubSeats =
    hubMembersQuery.data?.member_seats_available ?? Number.MAX_SAFE_INTEGER;

  const boardSeats =
    boardMembersQuery.data?.member_seats_available ?? Number.MAX_SAFE_INTEGER;

  const noSeats =
    (destinationLocation?.hub?.id || destinationLocation?.id) !==
      originalHubId && Math.min(hubSeats, boardSeats) < neededSeats;

  return {
    noSeats,
    destination: {
      members,
      member_seats_available: 0,
      removed_members: [],
    },
  };
};

const getHubId = (location: Location | null) => {
  if (location?.type === 'hub') return location.id;
  if (location?.type === 'board') return location.hub?.id;
  return null;
};

const getBoardId = (location: Location | null) => {
  if (location?.type === 'board') return location.id;
  return null;
};

export const getMemberEmail = ({
  member,
}: {
  member: Member | MemberHub | NestedMember;
}) => {
  if (!member) return;

  return member.email;
};

export const count = ({
  original,
  destination,
  nested,
}: {
  original: Member[];
  destination: (Member | MemberHub)[];
  nested?: NestedMember[];
}) => {
  const members = new Map<string, number>();
  const emails = new Set<string>();

  original.forEach((member) => {
    const email = getMemberEmail({ member });

    if (email) {
      members.set(email, (members.get(email) || 0) + 1);
      emails.add(email);
    }
  });

  destination.forEach((member) => {
    const email = getMemberEmail({ member });

    if (email) {
      members.set(email, (members.get(email) || 0) + 1);
    }
  });

  nested?.forEach((member) => {
    const email = getMemberEmail({ member });

    if (email && emails.has(email)) {
      members.set(email, (members.get(email) || 0) + 1);
    }
  });

  return members;
};

export const move = ({
  original,
  destination,
  memberCounts,
}: {
  original: Member[];
  destination: (Member | MemberHub)[];
  memberCounts: Map<string, number>;
}) => {
  const members = [...original, ...destination];
  const uniqueMembers = new Map<string, Member | MemberHub>();

  for (const member of members) {
    const email = getMemberEmail({ member });
    if (!email) continue;

    uniqueMembers.set(email, {
      ...member,
      is_disabled: memberCounts.get(email)! > 1,
    });
  }

  return Array.from(uniqueMembers.values());
};

export const getMemberSets = ({
  original,
  destination,
  nested,
}: {
  original: Member[];
  destination: (Member | MemberHub)[];
  nested?: NestedMember[];
}) => {
  const originalSet = new Set<string>();
  const destinationSet = new Set<string>();
  const emails = new Set<string>();

  for (const member of [...original, ...destination]) {
    const email = getMemberEmail({ member });

    if (email) {
      emails.add(email);
    }
  }

  for (const member of original) {
    const email = getMemberEmail({ member });

    if (email) {
      originalSet.add(email);
    }
  }

  for (const member of destination) {
    const email = getMemberEmail({ member });

    if (email) {
      destinationSet.add(email);
    }
  }

  if (nested) {
    for (const member of nested) {
      const email = getMemberEmail({ member });

      if (email && emails.has(email)) {
        destinationSet.add(email);
      }
    }
  }

  return [originalSet, destinationSet];
};

// import { useUnit } from 'effector-react';
import {
  FilterChip,
  // Popover,
  // PopoverTrigger,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

// import { capitalise } from '@pages/AccountPage/utils';
// import { PopoverContent } from '@radix-ui/react-popover';
// import { $filters } from '../../model';
// import { Contents, ItemsAndFiles } from '../filterChecks';
import styles from './styles.module.css';

export const SearchWithinFilter = () => {
  // const [filters] = useUnit([$filters]);

  // const filterItemsLabel = () => {
  //   if (filters.allFilesSelected()) {
  //     return 'Items and files';
  //   } else if (filters.files.size === 1) {
  //     return capitalise(filters.files.values().next().value ?? '');
  //   }
  //   return 'Some items and files';
  // };

  // const contentsLabel = () => {
  //   if (filters.allContentSelected()) {
  //     return 'Contents';
  //   } else if (filters.content.size === 2) {
  //     const iterator = filters.content.values();
  //     return `${capitalise(
  //       iterator.next().value.replace('-', ' ') ?? '',
  //     )} and ${capitalise(iterator.next().value.replace('-', ' ') ?? '')}`;
  //   } else if (filters.content.size === 1) {
  //     return capitalise(
  //       filters.content.values().next().value.replace('-', ' ') ?? '',
  //     );
  //   }

  //   return '';
  // };

  return (
    <div className={styles.container}>
      {/* <div className={styles.row}>
        <TypographyPoppins type="label" labelSize="L" className={styles.label}>
          Search for
        </TypographyPoppins>
        <FilterChip
          type="label"
          leadingIcon={
            filters.allSelected() ? (
              <Icon name="sprite/tick" height={24} width={24} />
            ) : (
              <Icon
                name="sprite/tick"
                height={23}
                width={23}
                style={{
                  visibility: 'hidden',
                }}
              />
            )
          }
          className={styles.filterChip}
          isSelected={filters.allSelected()}
          onClick={() => resetFilters()}
        >
          All
        </FilterChip>
        <Popover>
          <PopoverTrigger asChild>
            <FilterChip
              type="label"
              leadingIcon={
                filters.allFilesSelected() ? (
                  <Icon
                    name="sprite/tick"
                    height={23}
                    width={23}
                    style={{
                      visibility: 'hidden',
                    }}
                  />
                ) : (
                  <Icon name="sprite/tick" height={24} width={24} />
                )
              }
              trailingIcon={
                <Icon name="sprite/caret-down" height={24} width={24} />
              }
              className={styles.filterChip}
              onClick={undefined}
              isSelected={!filters.allSelected()}
            >
              {filterItemsLabel()}
            </FilterChip>
          </PopoverTrigger>
          <PopoverContent sideOffset={8} align="start">
            <ItemsAndFiles />
          </PopoverContent>
        </Popover>
        <Popover>
          <PopoverTrigger asChild>
            <FilterChip
              type="label"
              leadingIcon={
                filters.allFilesSelected() ? (
                  <Icon
                    name="sprite/tick"
                    height={23}
                    width={23}
                    style={{
                      visibility: 'hidden',
                    }}
                  />
                ) : (
                  <Icon name="sprite/tick" height={24} width={24} />
                )
              }
              trailingIcon={
                <Icon name="sprite/caret-down" height={24} width={24} />
              }
              className={styles.filterChip}
              onClick={undefined}
              isSelected={!filters.allSelected()}
            >
              {contentsLabel()}
            </FilterChip>
          </PopoverTrigger>
          <PopoverContent sideOffset={8} align="start">
            <Contents />
          </PopoverContent>
        </Popover>
      </div> */}
      <div className={styles.row}>
        <TypographyPoppins type="label" size="L" className={styles.label}>
          Look in
        </TypographyPoppins>
        <FilterChip
          leadingIcon={<Icon name="sprite/tick" height={24} width={24} />}
          className={styles.filterChip}
          isSelected
        >
          All of my Visualist Library
        </FilterChip>
        <FilterChip className={styles.filterChip} isDisabled>
          Google
        </FilterChip>
      </div>
    </div>
  );
};

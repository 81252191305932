import { useState } from 'react';

import axios from 'axios';
import { useFormik } from 'formik';

import { UserPersonalisationType, UserType } from '@visualist/design-system';
import {
  Button,
  Modal,
  TextField,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  linkGogleAccount,
  saveProfile,
  unlinkGogleAccount,
} from '@api/account';
import { useAppData } from '@src/AppContext';
import { queryClient } from '@src/queryClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { GoogleButton } from '../auth/buttons';
import { AccountAvatar } from './components/avatar';
import { ChangePasswordForm } from './components/change-password';
import { PersonalizationFields } from './components/personalization-fields';
import { SetPasswordForm } from './components/set-password';

import styles from './styles.module.css';

export const Profile = () => {
  const { user } = useAppData();
  const queryClient = useQueryClient();
  // Set password is when google is but no password is set
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState<
    'change-password' | 'set-password' | ''
  >('');

  const { mutate } = useMutation({
    mutationFn: saveProfile,
    onSuccess: (response, variables) => {
      queryClient.setQueryData(['my-user'], response);
      localStorage.setItem('v_user', JSON.stringify(response));
      if (variables.first_name || variables.last_name) {
        startedSnack({
          label: 'Profile updated',
          close: true,
        });
      }
    },
    onError: () => {
      startedSnack({
        label: 'An error occurred',
        close: true,
      });
    },
  });

  const { mutate: unlinkGoogleMutation } = useMutation({
    mutationFn: unlinkGogleAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['my-user'],
      });
      startedSnack({
        label: 'Unlinked Google account',
        close: true,
      });
    },
    onError: () => {
      startedSnack({
        label: 'An error occurred',
        close: true,
      });
    },
  });

  const updatePersonalizeFields = (user: UserPersonalisationType) => {
    mutate({
      active_since: user.active_since,
      location: user.location,
      creative_field: user.creative_field,
      creative_field_other: user.creative_field_other,
      team_size: user.team_size,
      average_fee: user.average_fee,
      project_number: user.project_number,
      use_case: user.use_case,
      acquisition_channel: user.acquisition_channel,
      acquisition_channel_other: user.acquisition_channel_other,
    });
  };

  const formik = useFormik({
    initialValues: {
      first_name: user.first_name ?? '',
      last_name: user.last_name ?? '',
      email: user.email,
    },
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: (values) => {
      return mutate({
        first_name: values.first_name,
        last_name: values.last_name,
      });
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.profileHeaderWrapper}>
        <AccountAvatar />

        {/* Manage Google */}
        <div className={styles.googleContainer}>
          <GoogleLink user={user} />
          {user.valid_password && user.is_google_linked ? (
            <Button
              type="ghost"
              label="Unlink"
              onClick={() => unlinkGoogleMutation()}
            />
          ) : null}
        </div>
      </div>

      <form className={styles.form} onSubmit={formik.handleSubmit}>
        {/* NAME */}
        <div className={styles.row}>
          <div className={styles.formInput}>
            <TypographyPoppins type="label" size="M" className={styles.label}>
              <label htmlFor="first_name">First name</label>
            </TypographyPoppins>
            <TextField
              hideClear
              formNoValidate
              onBlur={() => {
                if (user.first_name !== formik.values.first_name)
                  formik.submitForm();
              }}
              id="first_name"
              name="first_name"
              type="text"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              errorAndSupportingText={formik.errors.first_name}
              clear={(e) => {
                e.preventDefault();
              }}
            />
          </div>
          <div className={styles.formInput}>
            <TypographyPoppins type="label" size="M" className={styles.label}>
              <label htmlFor="last_name">Last name</label>
            </TypographyPoppins>
            <TextField
              hideClear
              formNoValidate
              onBlur={() => {
                if (user.last_name !== formik.values.last_name)
                  formik.submitForm();
              }}
              id="last_name"
              name="last_name"
              type="text"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              errorAndSupportingText={formik.errors.last_name}
              clear={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        </div>
        {/* EMAIL */}
        <div className={styles.row}>
          <div className={styles.formInput}>
            <TypographyPoppins type="label" size="M" className={styles.label}>
              <label htmlFor="email">Email</label>
            </TypographyPoppins>
            <TextField
              hideClear
              formNoValidate
              isDisabled
              id="email"
              name="email"
              type="email"
              infoText="Contact us to change your email"
              placeholder="Use your work email"
              value={formik.values.email}
              onChange={() => {}}
              errorAndSupportingText={formik.errors.email}
              clear={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        </div>
        {/* Password */}
        <div className={styles.row}>
          <Modal
            showModal={showPasswordChangeModal === 'change-password'}
            handleClose={() => {
              setShowPasswordChangeModal('');
            }}
            isInitialFocused={false}
          >
            <ChangePasswordForm
              onSuccess={() => {
                setShowPasswordChangeModal('');
              }}
            />
          </Modal>
          <Modal
            showModal={showPasswordChangeModal === 'set-password'}
            handleClose={() => {
              setShowPasswordChangeModal('');
            }}
            isInitialFocused={false}
          >
            <SetPasswordForm
              onSuccess={() => {
                setShowPasswordChangeModal('');
              }}
            />
          </Modal>
          <div
            className={styles.formInput}
            onClick={() => {
              if (user.valid_password) {
                setShowPasswordChangeModal('change-password');
              } else {
                setShowPasswordChangeModal('set-password');
              }
            }}
          >
            <TypographyPoppins type="label" size="M" className={styles.label}>
              <label htmlFor="password">Password</label>
            </TypographyPoppins>
            <TextField
              hideClear
              formNoValidate
              id="password"
              name="password"
              type="password"
              placeholder={
                user.valid_password ? 'Change password' : 'Set password'
              }
              value=""
              onFocus={() => {
                if (user.valid_password) {
                  setShowPasswordChangeModal('change-password');
                } else {
                  setShowPasswordChangeModal('set-password');
                }
              }}
              onChange={() => {}}
              clear={() => {}}
            />
          </div>
        </div>
        <button type="submit" hidden />
      </form>

      <PersonalizationFields
        user={user}
        updatePersonalizeFields={updatePersonalizeFields}
      />
    </div>
  );
};

const GoogleLink = ({ user }: { user: UserType }) => {
  const { mutate } = useMutation({
    mutationFn: linkGogleAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['my-user'],
      });
      startedSnack({
        label: 'Google account linked',
        close: true,
      });
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        if (
          error.response?.data?.non_field_errors &&
          error.response?.data?.non_field_errors.length !== 0
        ) {
          if (
            error.response.data.non_field_errors[0] ===
            `User's account email and social account email are not matching.`
          ) {
            startedSnack({
              label: 'Use the same email to log in with Google',
            });
          } else {
            startedSnack({
              label: 'An error occurred',
              close: true,
            });
          }
        }
      }
    },
  });

  return (
    <GoogleButton>
      <GoogleButton.Headless
        disabled={user.is_google_linked}
        label={
          user.is_google_linked ? 'Managed with Google' : 'Connect with Google'
        }
        handleAccessToken={async (token) => {
          // If user is not linked to google, then link them
          if (!user.is_google_linked) {
            await mutate({
              access_token: token,
            });
          }
        }}
        className={styles.googleButton}
      />
    </GoogleButton>
  );
};

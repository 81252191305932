import styles from './styles.module.css';

export const CheckboxMark = () => {
  return (
    <svg
      className={styles.container}
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      fill="none"
    >
      <path
        fill="#E2C9EB"
        fillRule="evenodd"
        d="M66.6214 14.0092C66.6214 13.641 66.3229 13.3425 65.9548 13.3425H13.9548C13.5866 13.3425 13.2881 13.641 13.2881 14.0092V72.6759C13.2881 73.0441 13.5866 73.3425 13.9548 73.3425H65.9548C66.3229 73.3425 66.6214 73.044 66.6214 72.6759V14.0092ZM18.2486 70.1391C17.8804 70.1391 17.5819 69.8406 17.5819 69.4724V17.5425C17.5819 17.1743 17.8804 16.8759 18.2486 16.8759H61.6401C62.0083 16.8759 62.3068 17.1743 62.3068 17.5425V69.4724C62.3068 69.8406 62.0083 70.1391 61.6401 70.1391H18.2486Z"
        clipRule="evenodd"
      />
      <rect
        width="53.3333"
        height="60"
        x="13.2878"
        y="13.3428"
        stroke="#43424A"
        strokeWidth=".666667"
        rx=".666667"
      />
      <rect
        width="45"
        height="53.3333"
        x="17.5"
        y="16.676"
        fill="#F3F1EE"
        opacity=".5"
        rx=".666667"
      />
      <rect
        width="38.6841"
        height="53.3333"
        x="23.8152"
        y="16.676"
        fill="#F3F1EE"
        rx=".666667"
      />
      <rect
        width="45"
        height="53.3333"
        x="17.5"
        y="16.676"
        stroke="#43424A"
        strokeWidth=".666667"
        rx=".666667"
      />
      <path
        fill="#DCD5CB"
        fillRule="evenodd"
        d="M48.9804 18.4762H30.9554C30.4794 18.4762 30.1568 17.9918 30.3403 17.5526L33.3312 10.3938C33.4348 10.1457 33.6774 9.98414 33.9463 9.98414H36.8006L37.5407 8.02294C37.6071 7.84701 37.7447 7.7073 37.9196 7.63824L40.0183 6.80957L42.1625 7.64363C42.3317 7.70946 42.4668 7.84149 42.5364 8.0092L43.3568 9.98414H46.2015C46.4766 9.98414 46.7234 10.1531 46.8229 10.4096L49.6019 17.5683C49.7715 18.0053 49.4492 18.4762 48.9804 18.4762ZM38.4585 9.98414C38.1621 9.66148 38.6726 8.93661 39.009 8.5628C39.0442 8.52365 39.085 8.48919 39.1306 8.46274C40.159 7.86551 41.1981 8.88688 41.6893 9.61306C41.7634 9.72263 41.7969 9.85451 41.7788 9.98555C41.6809 10.6952 41.2348 11.7474 40.0183 11.4891C38.8454 11.2401 38.7425 10.8097 38.6512 10.4281C38.6122 10.2653 38.5754 10.1114 38.4585 9.98414Z"
        clipRule="evenodd"
      />
      <path
        stroke="#43424A"
        strokeLinecap="round"
        strokeWidth=".666667"
        d="M33.5023 16.4703H46.6578M40.0183 6.80957C37.7449 6.80957 37.0777 8.39427 36.8819 9.32439C36.806 9.68469 36.5021 9.98414 36.1339 9.98414H33.9463C33.6774 9.98414 33.4348 10.1457 33.3312 10.3938L30.3403 17.5526C30.1568 17.9918 30.4794 18.4762 30.9554 18.4762H48.9804C49.4492 18.4762 49.7715 18.0053 49.6019 17.5683L46.8229 10.4096C46.7234 10.1531 46.4766 9.98414 46.2015 9.98414H43.9986C43.6403 9.98414 43.3412 9.69989 43.257 9.35157C43.0334 8.42551 42.3141 6.80957 40.0183 6.80957Z"
      />
      <path
        stroke="#43424A"
        strokeWidth=".666667"
        d="M41.6666 9.85392C41.6666 10.7744 40.9204 11.5206 39.9999 11.5206C39.0794 11.5206 38.3333 10.7744 38.3333 9.85392C38.3333 8.93345 39.0794 8.18726 39.9999 8.18726C40.9204 8.18726 41.6666 8.93345 41.6666 9.85392Z"
      />
      <path
        stroke="#43424A"
        strokeLinecap="round"
        strokeWidth=".666667"
        d="M32.3955 30.0225H59.0622M32.3958 40H59.0624M32.3958 50.1667H59.0624M32.3958 60.3337H59.0624"
      />
      <rect
        width="6.66667"
        height="6.66667"
        x="22.0574"
        y="26.4998"
        fill="#FFE2B5"
        opacity=".5"
        rx=".666667"
      />
      <rect
        width="6.66667"
        height="6.66667"
        x="22.0574"
        y="36.6667"
        fill="#FFE2B5"
        opacity=".5"
        rx=".666667"
      />
      <rect
        width="6.66667"
        height="6.66667"
        x="21.9597"
        y="46.8335"
        fill="#FFE2B5"
        opacity=".5"
        rx=".666667"
      />
      <rect
        width="6.66667"
        height="6.66667"
        x="21.9597"
        y="36.6667"
        stroke="#43424A"
        strokeWidth=".666667"
        rx=".666667"
      />
      <rect
        width="6.66667"
        height="6.66667"
        x="22.0574"
        y="26.4998"
        stroke="#43424A"
        strokeWidth=".666667"
        rx=".666667"
      />
      <rect
        width="6.66667"
        height="6.66667"
        x="21.9597"
        y="46.8335"
        stroke="#43424A"
        strokeWidth=".666667"
        rx=".666667"
      />
      <rect
        width="6.66667"
        height="6.66667"
        x="21.9597"
        y="57.0002"
        fill="#FFE2B5"
        opacity=".5"
        rx=".666667"
      />
      <rect
        width="6.66667"
        height="6.66667"
        x="21.9597"
        y="57.0002"
        stroke="#43424A"
        strokeWidth=".666667"
        rx=".666667"
      />
      <path
        d="M23.2878 28.6742L25.578 31.5556L29.9545 24.8889"
        stroke="#43424A"
        strokeWidth="0.666667"
        strokeLinecap="round"
      />
      <path
        d="M23.2878 38.5684L25.578 41.4499L29.9545 34.7832"
        stroke="#43424A"
        strokeWidth="0.666667"
        strokeLinecap="round"
      />
      <path
        d="M23.2878 58.9019L25.578 61.7834L29.9545 55.1167"
        stroke="#43424A"
        strokeWidth="0.666667"
        strokeLinecap="round"
        className={styles.tick}
      />
    </svg>
  );
};

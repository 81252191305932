import { useEffect, useState } from 'react';

import axios from 'axios';
// @ts-expect-error
import confetti from 'canvas-confetti';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router';

import {
  Button,
  TextField,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { updatePassword } from '@api/account';
import { useMutation } from '@tanstack/react-query';

import { ResetConfirmFormValues } from '../types';
import {
  resolveResetPasswordErrorMessages,
  validateResetConfirmedPasswordLogin,
} from '../utils';

import styles from './styles.module.css';

const initialValues: ResetConfirmFormValues = {
  password: '',
  confirmPassword: '',
};

export const ResetPasswordForm = () => {
  const { search } = useLocation();
  const [hasChanged, setHasChanged] = useState(false);

  // Parse search for HTML encoded charachters which are not handleded by decodeURI (e.g. send grid converst & to &amp; in html links from emails because click tracking...)
  let parsedSearch = search;
  if (search?.includes('&amp;')) {
    parsedSearch = search.replace(/&amp;/g, '&');
  }

  const params = new URLSearchParams(parsedSearch);

  const token = decodeURIComponent(params.get('token') ?? '');
  const uid = decodeURIComponent(params.get('uid') ?? '');
  const email = decodeURIComponent(params.get('email') ?? '');

  const { mutate } = useMutation({
    mutationFn: updatePassword,
    onSuccess: () => {
      setHasChanged(true);
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        if (
          error.response?.data?.new_password2 &&
          error.response?.data?.new_password2.length !== 0
        ) {
          formik.errors.password = resolveResetPasswordErrorMessages(
            error.response.data.new_password2[0],
          );
        }
      } else {
        formik.errors.password = 'Something went wrong';
      }
    },
  });

  const formik = useFormik({
    initialValues,
    validate: (v) =>
      validateResetConfirmedPasswordLogin(
        v,
        {} as Record<keyof ResetConfirmFormValues, string>,
      ),
    onSubmit: (values) => {
      return mutate({
        uid,
        token,
        new_password1: values.password,
        new_password2: values.confirmPassword,
      });
    },
  });

  return (
    <AnimatePresence mode="popLayout">
      {hasChanged ? (
        <motion.div
          key={1}
          initial={{ transform: 'translateX(120%)' }}
          animate={{ transform: 'translateX(0%)' }}
          transition={{
            duration: 0.3,
            bounce: 0.2,
            type: 'spring',
          }}
        >
          <SuccessChange />
        </motion.div>
      ) : (
        <motion.div
          key={2}
          exit={{
            transform: 'translateX(-120%)',
          }}
          transition={{
            duration: 0.3,
            bounce: 0.2,
            type: 'spring',
          }}
          className={styles.container}
        >
          <TypographyPoppins type="title" size="L" className={styles.title}>
            Reset password for
            <br />
            <span className={styles.userEmail}>{email}</span>
          </TypographyPoppins>
          <div className={styles.mainForm}>
            <form
              noValidate
              onSubmit={(e) => {
                formik.handleSubmit(e);
              }}
              className={styles.form}
            >
              <div className={styles.formInput}>
                <TypographyPoppins
                  type="label"
                  size="M"
                  className={styles.label}
                >
                  <label htmlFor="email">New password</label>
                </TypographyPoppins>
                <TextField
                  autoFocus
                  formNoValidate
                  id="password"
                  name="password"
                  type="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  errorAndSupportingText={formik.errors.password}
                  supportingText={
                    !formik.errors.password
                      ? 'At least 12 characters, including 1 letter and 1 symbol or number'
                      : undefined
                  }
                  clear={(e) => {
                    e.preventDefault();
                    formik.resetForm();
                  }}
                />
              </div>
              <div className={styles.formInput}>
                <TypographyPoppins
                  type="label"
                  size="M"
                  className={styles.label}
                >
                  <label htmlFor="email">Confirm password</label>
                </TypographyPoppins>
                <TextField
                  autoFocus
                  formNoValidate
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  errorAndSupportingText={formik.errors.confirmPassword}
                  clear={(e) => {
                    e.preventDefault();
                    formik.resetForm();
                  }}
                />
              </div>
              <div className={styles.formButtons}>
                <Button
                  type="filled"
                  label="Reset password"
                  // Empty onclick as it's handled by formik
                  onClick={() => {}}
                />
              </div>
            </form>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const colors = [
  '#E2C9EB',
  '#006E2E',
  '#FFB86A',
  '#FFE2B5',
  '#B3D4C1',
  '#53006E',
];

const SuccessChange = () => {
  const history = useHistory();

  useEffect(() => {
    const end = Date.now() + 3000;

    const frame = () => {
      if (Date.now() > end) return;

      confetti({
        particleCount: 4,
        angle: 60,
        spread: 55,
        startVelocity: 60,
        origin: { x: 0, y: 0.5 },
        colors: colors,
      });

      confetti({
        particleCount: 4,
        angle: 120,
        spread: 55,
        startVelocity: 60,
        origin: { x: 1, y: 0.5 },
        colors: colors,
      });

      return requestAnimationFrame(frame);
    };

    const frameNumber = frame();

    return () => {
      if (frameNumber) cancelAnimationFrame(frameNumber);
    };
  }, []);

  return (
    <div className={styles.container}>
      <TypographyPoppins type="title" size="L" className={styles.title}>
        <div>Password reset</div>
      </TypographyPoppins>
      <div className={styles.successRow}>
        <TypographyPoppins type="body" size="M" className={styles.successText}>
          We’ve successfully reset your password
        </TypographyPoppins>
        <Button
          type="filled"
          label="Log in now"
          onClick={() => {
            history.push('/login');
          }}
        />
      </div>
    </div>
  );
};

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import {
  getDoc,
  ResponseDoc,
  UpdateDoc,
  updateDoc,
  updateDocCustomColors,
} from '@api/docs';
import {
  ALL_DOCS_QUERY,
  DOC_QUERY,
  DOCS_OF_BOARD_QUERY,
  DOCS_OF_HUB_QUERY,
} from '@src/shared/constants/query-names';
import { DOC_TABS } from '@src/shared/constants/variables-local-storage';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';
import {
  MutateOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { Tab } from '../../ui/tabs';

export const useUpdateDoc = (
  options?: MutateOptions<ResponseDoc, any, UpdateDoc>,
) => {
  const queryClient = useQueryClient();

  const [, setTabs] = useLocalStorage<Tab[]>(DOC_TABS, []);

  const editDocMutation = useMutation({
    mutationFn: (props: UpdateDoc) => updateDoc({ ...props }),
    onMutate: (variables) => {
      queryClient.cancelQueries({
        queryKey: [DOC_QUERY, { docId: variables.docId }],
      });
      const previousQueryData = queryClient.getQueryData<
        Awaited<ReturnType<typeof getDoc>>
      >([DOC_QUERY, { docId: variables.docId }]);

      if (!previousQueryData) return;

      const newData = { ...previousQueryData };

      if (variables.height) {
        newData.height = variables.height;
      }
      if (variables.width) {
        newData.width = variables.width;
      }
      if (variables.marginTop) {
        newData.margin_top = variables.marginTop;
      }
      if (variables.marginBottom) {
        newData.margin_bottom = variables.marginBottom;
      }
      if (variables.marginLeft) {
        newData.margin_left = variables.marginLeft;
      }
      if (variables.marginRight) {
        newData.margin_right = variables.marginRight;
      }

      queryClient.setQueryData(
        [DOC_QUERY, { docId: variables.docId }],
        newData,
      );

      return {
        previousQueryData,
      };
    },
    onSuccess: (
      data,
      { docId, title, isDocRemovedFromBoard, board },
      context,
    ) => {
      queryClient.invalidateQueries({
        queryKey: [DOC_QUERY, { docId }],
      });

      if (title) {
        setTabs((tabs) =>
          tabs.map((t) => (t.id === docId ? { ...t, label: title } : t)),
        );

        startedSnack({
          label: 'Renamed doc',
          close: true,
        });
      }

      if (board) {
        startedSnack({
          label: 'Added doc to board',
          action: {
            label: 'Undo',
            action: () =>
              editDocMutation.mutate({
                docId,
                board: '',
                isDocRemovedFromBoard: true,
              }),
          },
          close: true,
        });
      }

      if (isDocRemovedFromBoard) {
        startedSnack({
          label: 'Removed doc from board',
          action: {
            label: 'Undo',
            action: () =>
              editDocMutation.mutate({ docId, board: data.previous_board }),
          },
          close: true,
        });
      }

      if (options?.onSuccess)
        options?.onSuccess(data, { docId, title }, context);
    },
    onError: (err, { docId, title, board, isDocRemovedFromBoard }, context) => {
      // Revert optimistic update
      queryClient.setQueryData(
        [DOC_QUERY, { docId }],
        context?.previousQueryData,
      );

      if (title) {
        startedSnack({
          label: "Couldn't rename doc",
          action: {
            label: 'Try again',
            action: () => editDocMutation.mutate({ docId, title }),
          },
          close: true,
        });
      }

      if (board) {
        startedSnack({
          label: "Couldn't add doc to board",
          action: {
            label: 'Try again',
            action: () => editDocMutation.mutate({ docId, board }),
          },
          close: true,
        });
      }

      if (isDocRemovedFromBoard) {
        startedSnack({
          label: "Couldn't remove doc from board",
          action: {
            label: 'Try again',
            action: () => editDocMutation.mutate({ docId, board }),
          },
          close: true,
        });
      }

      if (options?.onError) {
        options?.onError(err, { docId, title }, context);
      }
    },
    onSettled: (_, _err, variables) => {
      queryClient.invalidateQueries({
        queryKey: [ALL_DOCS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [DOC_QUERY, { docId: variables.docId }],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_HUB_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_BOARD_QUERY],
      });
    },
  });

  const updateDocCustomColorsMutation = useMutation({
    mutationFn: updateDocCustomColors,
    onSuccess: (data, { docId }) => {
      queryClient.invalidateQueries({
        queryKey: [DOC_QUERY, { docId }],
      });
    },
  });

  return {
    editDoc: editDocMutation.mutate,
    editDocMutation,
    updateDocCustomColorsMutation,
  };
};

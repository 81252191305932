import React, { ReactNode, useRef, useState } from 'react';

import cn from 'classnames';

import { Button } from '../Buttons';
import { TypographyPoppins } from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

type TooltipAction = {
  title: string;
  action: () => void;
};

type RichParameter = {
  type: 'rich';
  title?: string;
  description: string | ReactNode;
  actions?: TooltipAction[];
  position?:
    | 'top right'
    | 'top left'
    | 'bottom right'
    | 'bottom left'
    | 'bottom';
};

type Props = {
  parameter: RichParameter;
  children: React.ReactNode;
  style?: React.CSSProperties;
  disableWrapperCenter?: boolean;
  className?: string;
  wrapperClassName?: string;
};

export const Tooltip = ({
  parameter,
  children,
  style,
  disableWrapperCenter,
  className,
  wrapperClassName,
  ...rest
}: Props) => {
  const [bounds, setBounds] = useState({ height: 0, width: 0 });
  const ref = useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (ref.current) {
      setBounds(ref.current.getBoundingClientRect());
    }
  }, []);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          ref.current &&
          (bounds.height === 0 || bounds.width === 0)
        ) {
          setBounds(ref.current.getBoundingClientRect());
        }
      });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div
      className={cn(styles.wrapper, wrapperClassName)}
      ref={ref}
      style={
        {
          '--content-height': `${bounds.height}px`,
          '--content-width': `${bounds.width}px`,
        } as React.CSSProperties
      }
    >
      <div
        className={cn(
          styles.tooltipContainer,
          {
            [styles.tooltipRich]: parameter.type === 'rich',
            [styles.tooltipTopRightRich]: parameter.position === 'top right',
            [styles.tooltipTopLeftRich]: parameter.position === 'top left',
            [styles.tooltipBottomRightRich]:
              parameter.position === 'bottom right',
            [styles.tooltipBottomLeftRich]:
              parameter.position === 'bottom left',
            [styles.tooltipBottom]: parameter.position === 'bottom',
          },
          className,
        )}
        style={style}
      >
        <RichTooltip parameter={parameter} />
      </div>
      <div
        className={cn(styles.wrapperCenter, {
          [styles.disableWrapperCenter]: disableWrapperCenter,
        })}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};

type RichTooltipProps = {
  parameter: RichParameter;
};

const RichTooltip = ({ parameter }: RichTooltipProps) => {
  return (
    <>
      <div
        className={cn(styles.richTextContainer, {
          [styles.richTextContainerBottomPadding]: !parameter.actions,
        })}
      >
        {parameter.title ? (
          <TypographyPoppins type="title" size="S">
            <span className={styles.richTitle}>{parameter.title}</span>
          </TypographyPoppins>
        ) : null}
        <TypographyPoppins type="body" size="M">
          <span className={styles.richDescription}>
            {parameter.description}
          </span>
        </TypographyPoppins>
      </div>
      {parameter.actions ? (
        <div className={styles.richButtonContainer}>
          {parameter.actions?.map((action) => {
            return (
              <Button
                key={action.title}
                type="text"
                label={action.title}
                onClick={action.action}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
};

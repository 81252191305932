import { useHistory, useLocation } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { boardsKeys } from '@src/shared/constants/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { removeBoardFromHub } from '../../../../../../api/boards';
import {
  BOARD_QUERY,
  BOARDS_OF_HUB_QUERY,
  BOARDS_TREE_QUERY,
} from '../../../../../../shared/constants';

export const useMoveBoardFromHub = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const moveBoardFromHubMutation = useMutation({
    mutationFn: ({ hub, boards }: { hub: string; boards: string[] }) =>
      removeBoardFromHub({ hub, boards }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_OF_HUB_QUERY, { hub: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: boardsKeys.all,
      });

      startedSnack({
        label: 'Moved board',
        action: {
          label: 'Go to new location',
          action: () => {
            history.push(`/b/${variables.boards[0]}`);
          },
        },
        close: true,
      });
    },

    onError: (err, variables) => {
      startedSnack({
        label: 'Couldn’t move board',
        action: {
          label: 'Try again',
          action: () => {
            moveBoardFromHubMutation.mutate({
              hub: variables.hub,
              boards: variables.boards,
            });
          },
        },
        close: true,
      });
    },
  });

  return { moveBoardFromHubMutation };
};

import { fetchLogin } from '@api/account';
import { REDIRECT_TO_HUB_OR_BOARD_EXISTING_USER } from '@src/shared/constants/variables-local-storage';
import { setCookie } from '@src/shared/utils';
import { MutateOptions, useMutation } from '@tanstack/react-query';

export const useSignIn = (props: {
  options?: MutateOptions<
    Awaited<ReturnType<typeof fetchLogin>>,
    any,
    Parameters<typeof fetchLogin>[0]
  >;
}) => {
  return useMutation({
    mutationFn: fetchLogin,
    ...props.options,
    onSuccess: (response, ...args) => {
      if (props.options?.onSuccess) props.options.onSuccess(response, ...args);

      setCookie('access', response.access);
      setCookie('refresh', response.refresh);

      localStorage.setItem('v_user', JSON.stringify(response));

      const redirect = localStorage.getItem(
        REDIRECT_TO_HUB_OR_BOARD_EXISTING_USER,
      );

      if (redirect) {
        window.location.href = redirect;
        localStorage.removeItem(REDIRECT_TO_HUB_OR_BOARD_EXISTING_USER);
      } else {
        window.location.href = '/home';
      }
    },
  });
};

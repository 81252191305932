import { createEvent, createStore, sample } from 'effector';

import { Swatches } from '@visualist/design-system/src/components/v2';

export const colorMenuToggled = createEvent();
export const colorMenuClosed = createEvent();

export const backgroundSelected = createEvent<string>();
export const backgroundCleared = createEvent();

export const $isOpenColorMenu = createStore(false);

$isOpenColorMenu
  .on(colorMenuToggled, (isOpen) => !isOpen)
  .reset(colorMenuClosed);

export const $colors = createStore<Swatches>({
  title: 'Solid',
  swatches: [
    { name: '#FFFFFF', color: '#FFFFFF' },
    { name: '#FFE2B5', color: '#FFE2B5' },
    { name: '#E2C9EB', color: '#E2C9EB' },
    { name: '#B3D4C1', color: '#B3D4C1' },
    { name: '#FBFBFA', color: '#FBFBFA' },
    { name: '#F3F1EE', color: '#F3F1EE' },
    { name: '#DCD5CB', color: '#DCD5CB' },
  ],
});

export const $background = createStore('');

$background
  .on(backgroundSelected, (_, background) => background)
  .reset(backgroundCleared);

export const $isCustomColor = createStore<boolean | null>(false);

// Determine whether custom colour is selected or not
sample({
  clock: $background,
  source: {
    background: $background,
    colors: $colors,
  },
  fn: ({ colors, background }) => {
    if (colors.swatches && background) {
      const isColorSwatch = colors.swatches.some((swatch) =>
        swatch.color.includes(background),
      );

      return !isColorSwatch;
    } else {
      return null;
    }
  },
  target: $isCustomColor,
});

export const $showPageBackgroundColorSetupModal = createStore(false);
export const closedPageBackgroundColorSetupModal = createEvent();
export const openedPageBackgroundColorSetupModal = createEvent();

$showPageBackgroundColorSetupModal
  .on(openedPageBackgroundColorSetupModal, () => true)
  .on(closedPageBackgroundColorSetupModal, () => false);

// Clear background when page setup modal is closed
sample({
  clock: closedPageBackgroundColorSetupModal,
  source: {
    showPageBackgroundColorSetupModal: $showPageBackgroundColorSetupModal,
  },
  filter: ({ showPageBackgroundColorSetupModal }) =>
    !showPageBackgroundColorSetupModal,
  target: backgroundCleared,
});

export const $showPageSizeSetupModal = createStore(false);
export const closedPageSizeSetupModal = createEvent();
export const openedPageSizeSetupModal = createEvent();

$showPageSizeSetupModal
  .on(openedPageSizeSetupModal, () => true)
  .on(closedPageSizeSetupModal, () => false);

import { useEffect, useMemo } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';

import {
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { getBoards } from '@api/boards';
import { BoardLvl1, BoardLvl2, BoardLvl3, Hub } from '@api/designs';
import { useFileCopies } from '@pages/FileCard/model/queries/use-file-copies';
import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { SearchModal } from '@src/entities/search-modal/ui/modal';
import { searchKeys } from '@src/shared/constants/query-keys';
import { useDebounce } from '@src/shared/hooks/use-debounce';
import { useQuery } from '@tanstack/react-query';

import {
  $boardsToCopyFile,
  $boardsToRemoveFile,
  $selectedBoards,
  boardsCleared,
  boardSelected,
  fileCopiesReceived,
} from './model/copy';
import { useCopyFile } from './model/queries/use-copy-file';
import { useRemoveFile } from './model/queries/use-remove-file';
import {
  $isOpenCopyFileDialog,
  $query,
  copyFileDialogClosed,
  queryEntered,
} from './model/show-copy-dialog';

import styles from './styles.module.css';

type Board = {
  id: string;
  name: string;
  parent?: Hub | BoardLvl1 | BoardLvl2 | BoardLvl3 | null;
};

export const CopyFile = () => {
  const isOpenCopyFileDialog = useUnit($isOpenCopyFileDialog);
  const query = useUnit($query);
  const selectedBoards = useUnit($selectedBoards);
  const boardsToCopyFile = useUnit($boardsToCopyFile);
  const boardsToRemoveFile = useUnit($boardsToRemoveFile);

  const debouncedQuery = useDebounce(query);

  const { fileCopies, fileId } = useFileCopies();
  const { copyFileMutation } = useCopyFile();
  const { removeFileMutation } = useRemoveFile();

  const { data: boards = [] } = useQuery({
    queryKey: [searchKeys, debouncedQuery],
    queryFn: () => getBoards({ name: debouncedQuery, action: 'edit' }),
    enabled: !!debouncedQuery,
  });

  const results = useMemo(() => {
    if (query) return boards;

    return (
      fileCopies?.boards?.map((b) => ({
        id: b.board_id,
        name: b.board_name,
        parent: b.parent,
      })) ?? []
    );
  }, [query, boards, fileCopies]);

  useEffect(() => {
    if (isOpenCopyFileDialog && fileCopies?.boards) {
      fileCopiesReceived(fileCopies.boards);
    }
  }, [isOpenCopyFileDialog, fileCopies]);

  useEffect(() => {
    if (!isOpenCopyFileDialog) {
      if (boardsToCopyFile.length > 0) {
        copyFileMutation.mutate({ blocks: [fileId], boards: boardsToCopyFile });
      }
      if (boardsToRemoveFile.length > 0) {
        removeFileMutation.mutate({
          blocks: boardsToRemoveFile.map((b) => b.blockId),
          fileId,
        });
      }

      boardsCleared();
    }
  }, [isOpenCopyFileDialog]);

  return (
    <SearchModal<Board>
      placeholder="Search for board"
      searchTerm={query}
      updateSearchTerm={queryEntered}
      isOpen={isOpenCopyFileDialog}
      onClose={copyFileDialogClosed}
      results={results}
      icon={
        !query && boards.length === 0 ? (
          <Icon size={80} name="sprite/folder-full-colored" />
        ) : (
          <Icon size={80} name="sprite/folder-empty-colored" />
        )
      }
      iconDescription={
        boards.length === 0 ? 'Tidy files, tidy mind' : undefined
      }
      renderItem={(row) => {
        const isSelected = selectedBoards.includes(row.id);
        const description = isSelected
          ? 'Remove from this board'
          : 'Copy to this board';

        return (
          <SearchModal.EmptyRow
            key={row.id}
            className={styles.result}
            onClick={() => boardSelected(row.id)}
          >
            <div className={styles.row}>
              <div className={styles.item}>
                <Breadcrumbs board={row.parent} className={styles.breadcumbs} />
                <div className={styles.content}>
                  <Icon name="sprite/board" color="var(--color-secondary-40)" />
                  <TypographyPoppins
                    type="body"
                    size="L"
                    className={styles.name}
                  >
                    {row.name}
                  </TypographyPoppins>
                  <div
                    className={cn(styles.checkbox, {
                      [styles.withBreadcrumbs]: row.parent,
                    })}
                  >
                    <TooltipRadix description={description}>
                      <Icon
                        name={
                          isSelected
                            ? 'sprite/checkbox-filled'
                            : 'sprite/checkbox-blank'
                        }
                        color="var(--color-primary-40)"
                      />
                    </TooltipRadix>
                  </div>
                </div>
              </div>
            </div>
          </SearchModal.EmptyRow>
        );
      }}
    >
      <span />
    </SearchModal>
  );
};

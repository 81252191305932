import {
  Button,
  TeamIcon,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

type Props = {
  isNotEnoughSeats: boolean;
  invite: () => void;
};

export const Invite = ({ isNotEnoughSeats, invite }: Props) => {
  return (
    <div className={styles.invite}>
      {isNotEnoughSeats ? (
        <div style={{ display: 'flex' }}>
          <TooltipRadix description="You don't have enough seats">
            <Button
              type="filled"
              label="Invite"
              icon={<TeamIcon />}
              isDisabled={isNotEnoughSeats}
              onClick={() => {}}
            />
          </TooltipRadix>
        </div>
      ) : (
        <Button
          type="filled"
          label="Invite"
          icon={<TeamIcon />}
          onClick={invite}
        />
      )}
    </div>
  );
};

import { createEvent, createStore } from 'effector';

export const archiveDocIdSelected = createEvent<string>();
export const archiveDocIdCleared = createEvent();

export const $archiveDocId = createStore<string | null>(null);

$archiveDocId
  .on(archiveDocIdSelected, (_, id) => id)
  .reset(archiveDocIdCleared);

import cn from 'classnames';

import {
  AssistChip,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Plan } from '@pages/AccountPage/components/types';
import { hubDialogOpened } from '@src/entities/dialogs/hub/model';
import { useBilling } from '@src/shared/queries/useBilling';
import { useMembership } from '@src/shared/queries/useMembershipPermissions';

import styles from './styles.module.css';

interface HeaderProps {
  plan: Plan;
}

export const CreateHub = ({ plan }: HeaderProps) => {
  const { isWithinThreeDays } = useBilling();
  const { checkoutPro, isReady } = useMembership();

  return (
    <div className={styles.header}>
      {(plan === 'free' || plan === 'starter') && isReady ? (
        <TooltipRadix
          type="rich"
          title="Manage projects and clients from hubs"
          description="Create hubs to keep your files organised in one space and sync with your clients."
          actions={[
            {
              action: checkoutPro,
              title: 'Try Pro to create hubs',
            },
          ]}
          width={280}
          side="right"
        >
          <AssistChip
            style="outlined"
            disabled
            leadingIcon
            icon={<Icon name="sprite/plus" />}
            onClick={checkoutPro}
          >
            <TypographyPoppins type="label" size="L">
              Create a hub
            </TypographyPoppins>
          </AssistChip>
        </TooltipRadix>
      ) : (
        <AssistChip
          leadingIcon
          icon={<Icon name="sprite/plus" />}
          style="outlined"
          onClick={() => hubDialogOpened()}
          disabled={isWithinThreeDays}
        >
          <TypographyPoppins
            type="label"
            size="L"
            className={cn({
              [styles.text]: !isWithinThreeDays,
            })}
          >
            Create a hub
          </TypographyPoppins>
        </AssistChip>
      )}
    </div>
  );
};

import { ReactNode } from 'react';

import cn from 'classnames';

import { Icon } from '@visualist/icons';

import { Avatar } from '../Avatar';
import {
  Size,
  TypographyPoppins,
  Variant,
} from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

export type InputChipProps<T extends Variant> = {
  style: 'outlined' | 'filled';
  leadingIcon?: ReactNode;
  trailingIcon?: boolean;
  avatar?: string;
  onClick?: () => void;
  children: ReactNode;
  className?: string;
  classNameLabel?: string;
  type?: T;
  size?: Size[T];
};

export const InputChip = <T extends Variant>({
  style,
  leadingIcon,
  trailingIcon,
  avatar,
  onClick,
  children,
  className,
  classNameLabel,
  type,
  size,
}: InputChipProps<T>) => {
  return (
    <button
      className={cn(styles.chip, className, {
        [styles.outlined]: style === 'outlined',
        [styles.filled]: style === 'filled',
        [styles.withLeadingIcon]: leadingIcon || avatar,
        [styles.withTrailingIcon]: trailingIcon,
      })}
    >
      {leadingIcon && !avatar && (
        <span className={styles.leadingIcon}>{leadingIcon}</span>
      )}

      {avatar && (
        <span className={styles.avatar}>
          <Avatar image={avatar} size={24} />
        </span>
      )}

      <TypographyPoppins
        className={cn(styles.label, classNameLabel, {
          [styles.labelFilled]: style === 'filled',
        })}
        type={type ? type : 'label'}
        //@ts-ignore
        size={size ? size : 'L'}
      >
        {children}
      </TypographyPoppins>

      {trailingIcon && (
        <button
          className={cn(styles.trailingIcon, {
            [styles.trailingIconFilled]: style === 'filled',
          })}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >
          <Icon
            name="sprite/x"
            size={16}
            className={cn(styles.closeIcon, {
              [styles.closeIconFilled]: style === 'filled',
            })}
          />
        </button>
      )}
    </button>
  );
};

import cn from 'classnames';
import { useUnit } from 'effector-react';

import {
  Button,
  FilterChip,
  Modal,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { Member } from '@api/hubs';
import { Permissions } from '@api/users';
import { useChangePermissions } from '@pages/BoardPage/model/queries/useChangePermissions';
import { useConvertBoardIntoTopLevel } from '@pages/BoardPage/model/queries/useConvertBoardIntoTopLevel';
import { useChangePermissionsHub } from '@pages/HubPage/model/queries/useChangePermissionsHub';
import { Breadcrumbs } from '@src/entities/breadcrumbs';
import { Title } from '@src/entities/dialogs/ui/title';
import { useRemoveInvitee } from '@src/entities/share-sheet/board/model/queries/use-remove-from-board';
import { useRemoveInviteeFromHub } from '@src/entities/share-sheet/hub/model/queries/use-remove-from-hub';
import { Invitees } from '@src/entities/share-sheet/ui/invitees';

import { Location } from '../../../search-modal/location';
import { $isBlur } from '../../../search-modal/location/model/blur';
import { useLocation } from '../../../search-modal/location/use-location';
import { DialogContainer } from '../../ui/dialog-container';
import {
  $destinationLocation,
  $originalHubId,
  $originalLocation,
  $originalLocationId,
  $originalLocationLvl,
  $selectedLocation,
  $shouldCloseMoveDialog,
  currentLocationCleared,
  locationChanged,
  originalLocationCleared,
} from './model/move';
import { $moveOutOfHub } from './model/move-out-of-hub';
import { $level } from './model/nesting-limit';
import { useMembers } from './model/queries/use-members';
import { useMoveBoard } from './model/queries/use-move-board';
import { useMoveBoardToHub } from './model/queries/use-move-to-hub';
import {
  $shouldMoveOutOfHub,
  confirmationDialogOpened,
} from './model/show-confirmation-dialog';
import {
  $isShowMoveBoardDialog,
  moveBoardDialogClosed,
} from './model/show-move-dialog';
import {
  $isSearchOpen,
  $query,
  queryEntered,
  searchClosed,
  searchOpened,
} from './model/show-search';

import styles from './styles.module.css';

export const MoveBoardDialog = () => {
  const isShowMoveBoardDialog = useUnit($isShowMoveBoardDialog);
  const isSearchOpen = useUnit($isSearchOpen);
  const query = useUnit($query);
  const originalLocation = useUnit($originalLocation);
  const originalLocationId = useUnit($originalLocationId);
  const originalHubId = useUnit($originalHubId);
  const shouldCloseMoveDialog = useUnit($shouldCloseMoveDialog);
  const shouldMoveOutOfHub = useUnit($shouldMoveOutOfHub);
  const originalLocationLvl = useUnit($originalLocationLvl);
  const destinationLocation = useUnit($destinationLocation);
  const selectedLocation = useUnit($selectedLocation);
  const moveOutOfHub = useUnit($moveOutOfHub);
  const level = useUnit($level);
  const isBlur = useUnit($isBlur);

  const { ref, getLeadingIcon, getTrailingIcon, isShowTooltip } = useLocation({
    location: selectedLocation,
  });

  const { noSeats, destination } = useMembers({
    originalLocationId,
    destinationLocation,
    originalHubId,
  });

  const { changePermissions } = useChangePermissions({});
  const { changePermissionHub } = useChangePermissionsHub();

  const handleChangePermissions = ({
    permissions,
    member,
  }: {
    permissions: Permissions[];
    member: Member;
  }) => {
    if (member.board) {
      changePermissions({
        boardId: member.board,
        member: member.id,
        email: member.email,
        permissions,
      });
    } else if (member.hub) {
      changePermissionHub({
        hubId: member.hub,
        member: member.id,
        email: member.email,
        permissions,
      });
    }
  };

  const { removeInvitee } = useRemoveInvitee({});
  const { removeInviteeFromHub } = useRemoveInviteeFromHub();

  const remove = (member: Member) => {
    if (member.board) {
      removeInvitee({
        boardId: member.board,
        members: [member.id],
        email: member.email,
        hubId: member.hub,
      });
    } else {
      removeInviteeFromHub({
        id: member.hub,
        members: [member.id],
      });
    }
  };

  const clear = () => {
    originalLocationCleared();
    currentLocationCleared();
  };

  const { moveBoardToHubMutation } = useMoveBoardToHub();
  const { moveBoardMutation } = useMoveBoard();
  const { convertBoardMutation } = useConvertBoardIntoTopLevel();

  const move = () => {
    if (shouldMoveOutOfHub) {
      confirmationDialogOpened();
      return;
    }

    if (shouldCloseMoveDialog) {
      moveBoardDialogClosed();
      return;
    }

    if (
      originalLocationId &&
      originalLocationLvl !== 0 &&
      !destinationLocation
    ) {
      convertBoardMutation.mutate({
        boardId: originalLocationId,
      });

      moveBoardDialogClosed();
      return;
    }

    if (originalLocationId && destinationLocation) {
      const { id, type } = destinationLocation;

      if (id) {
        if (type === 'hub') {
          moveBoardToHubMutation.mutate({
            hub: id,
            boards: [originalLocationId],
          });
        } else if (type === 'board') {
          moveBoardMutation.mutate({
            parent: id,
            children: originalLocationId,
          });
        }
      }
      moveBoardDialogClosed();
    }
  };

  return (
    <Modal
      showModal={isShowMoveBoardDialog}
      handleClose={moveBoardDialogClosed}
    >
      <DialogContainer className={styles.container}>
        <Title
          className={styles.title}
          title={
            noSeats ? 'There’s a problem with moving this board' : 'Move board'
          }
          description="Choose where to put this board in. Any sub-boards and files will also be moved."
        />
        <div className={styles.location}>
          <div className={styles.selection}>
            {selectedLocation && (
              <TypographyPoppins className={styles.nest} type="body" size="S">
                Nest board in
              </TypographyPoppins>
            )}
            <Location
              isOpen={isSearchOpen}
              open={searchOpened}
              close={searchClosed}
              query={query}
              queryEntered={queryEntered}
              location={selectedLocation}
              selectLocation={locationChanged}
              level={level}
            >
              {isShowTooltip() ? (
                <TooltipRadix
                  description={
                    <Breadcrumbs
                      board={
                        originalLocation
                          ? originalLocation.parent?.parent
                          : destinationLocation?.parent
                      }
                      className={styles.breadcrumbs}
                    />
                  }
                >
                  <FilterChip
                    ref={ref}
                    className={cn({
                      [styles.filterChip]: selectedLocation !== null,
                    })}
                    classNameLabel={cn({
                      [styles.blur]: isBlur,
                    })}
                    leadingIcon={getLeadingIcon()}
                    trailingIcon={getTrailingIcon()}
                    trailingAction={selectedLocation ? clear : undefined}
                    isSelected={selectedLocation !== null}
                    onClick={() => searchOpened(true)}
                    hideTick
                  >
                    {selectedLocation
                      ? selectedLocation.name
                      : 'Keep in Library'}
                  </FilterChip>
                </TooltipRadix>
              ) : (
                <FilterChip
                  ref={ref}
                  className={cn({
                    [styles.filterChip]: selectedLocation !== null,
                  })}
                  classNameLabel={cn({
                    [styles.blur]: isBlur,
                  })}
                  leadingIcon={getLeadingIcon()}
                  trailingIcon={getTrailingIcon()}
                  trailingAction={selectedLocation ? clear : undefined}
                  isSelected={selectedLocation !== null}
                  hideTick
                >
                  {selectedLocation ? selectedLocation.name : 'Keep in Library'}
                </FilterChip>
              )}
            </Location>
            {noSeats && destination.members.length > 0 ? (
              <>
                <TypographyPoppins
                  className={styles.noSeats}
                  type="body"
                  size="S"
                >
                  {`There aren’t enough seats in the hub. 

Remove persons to complete the move.`}
                </TypographyPoppins>
                <div style={{ maxWidth: '312px' }}>
                  <Invitees
                    //@ts-ignore
                    invitees={destination}
                    remove={remove}
                    currentPermissions={['edit', 'invite']}
                    //@ts-ignore
                    handleChangePermissions={handleChangePermissions}
                  />
                </div>
              </>
            ) : null}
          </div>
          <TypographyPoppins className={styles.tip} type="body" size="S">
            {selectedLocation?.hub || selectedLocation?.type === 'hub'
              ? 'You’ll be able to invite others to this board.'
              : 'If you want to invite others to this board, you’ll need to have it in a hub.'}
          </TypographyPoppins>
        </div>
        <div className={styles.actions}>
          <Button
            type="outlined"
            label="Cancel"
            onClick={() => moveBoardDialogClosed()}
          />
          <Button
            type="filled"
            label="Done"
            onClick={move}
            isDisabled={noSeats}
          />
        </div>
        {moveOutOfHub ? (
          <Button
            type="ghost"
            fontType="label"
            size="L"
            label="Move board out of hub"
            onClick={() => confirmationDialogOpened()}
          />
        ) : null}
      </DialogContainer>
    </Modal>
  );
};

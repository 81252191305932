import { useCallback, useRef } from 'react';

import { useUnit } from 'effector-react';
import { KonvaEventObject } from 'konva/lib/Node';

import { $currentTool } from '@pages/StudioPage/model';
import { getCenter, getDistance } from '@pages/StudioPage/utils';

type TouchState = {
  lastCenter: { x: number; y: number } | null;
  lastDist: number;
  mode: 'pan' | 'pinch' | null;
  previousDelta: { x: number; y: number } | null;
};

export function useTouch({
  updateStage,
}: {
  updateStage: (params: { scale: number; x: number; y: number }) => void;
}) {
  const currentTool = useUnit($currentTool);

  const touchStateRef = useRef<TouchState>({
    lastCenter: null,
    lastDist: 0,
    mode: null,
    previousDelta: null,
  });

  const handleTouchMove = useCallback(
    (e: KonvaEventObject<TouchEvent>) => {
      e.evt.preventDefault();

      if (currentTool !== 'move') return;

      const touch1 = e.evt.touches[0];
      const touch2 = e.evt.touches[1];
      const stage = e.target.getStage();

      if (!stage) return;

      // Get stage container's position relative to the viewport
      const stageContainer = stage.container();
      const stageRect = stageContainer.getBoundingClientRect();

      // Single touch handling
      if (touch1 && !touch2 && touchStateRef.current.mode === 'pan') {
        const adjustedX = touch1.clientX - stageRect.left;
        const adjustedY = touch1.clientY - stageRect.top;

        if (!touchStateRef.current.previousDelta) return;

        const deltaX = touchStateRef.current.previousDelta.x - adjustedX;
        const deltaY = touchStateRef.current.previousDelta.y - adjustedY;

        updateStage({
          scale: stage.scaleX(),
          x: stage.x() - deltaX,
          y: stage.y() - deltaY,
        });

        touchStateRef.current.previousDelta = {
          x: adjustedX,
          y: adjustedY,
        };
        return;
      }

      // Multi-touch handling
      if (touch1 && touch2 && touchStateRef.current.mode === 'pinch') {
        const p1 = {
          x: touch1.clientX - stageRect.left,
          y: touch1.clientY - stageRect.top,
        };
        const p2 = {
          x: touch2.clientX - stageRect.left,
          y: touch2.clientY - stageRect.top,
        };

        if (!touchStateRef.current.lastCenter) {
          touchStateRef.current.lastCenter = getCenter(p1, p2);
          touchStateRef.current.lastDist = getDistance(p1, p2);
          return;
        }

        const newCenter = getCenter(p1, p2);
        const dist = getDistance(p1, p2);

        // Calculate scale change
        const scale = stage.scaleX() * (dist / touchStateRef.current.lastDist);

        // Calculate the point relative to stage
        const centerPoint = {
          x: (newCenter.x - stage.x()) / stage.scaleX(),
          y: (newCenter.y - stage.y()) / stage.scaleY(),
        };

        // Calculate new position to maintain the center point
        const newPos = {
          x: newCenter.x - centerPoint.x * scale,
          y: newCenter.y - centerPoint.y * scale,
        };

        updateStage({
          scale,
          x: newPos.x,
          y: newPos.y,
        });

        touchStateRef.current.lastDist = dist;
        touchStateRef.current.lastCenter = newCenter;
      }
    },
    [currentTool, updateStage],
  );

  const handleTouchEnd = useCallback(() => {
    if (currentTool !== 'move') return;

    setTimeout(() => {
      touchStateRef.current = {
        lastDist: 0,
        lastCenter: null,
        mode: null,
        previousDelta: null,
      };
    }, 100);
  }, [currentTool]);

  const handleTouchStart = useCallback(
    (e: KonvaEventObject<TouchEvent>) => {
      if (currentTool !== 'move') return;

      const stage = e.target.getStage();
      if (!stage) return;

      const stageRect = stage.container().getBoundingClientRect();
      const adjustedX = e.evt.touches[0].clientX - stageRect.left;
      const adjustedY = e.evt.touches[0].clientY - stageRect.top;

      touchStateRef.current = {
        lastDist: 0,
        lastCenter: null,
        mode: e.evt.touches.length === 1 ? 'pan' : 'pinch',
        previousDelta: {
          x: adjustedX,
          y: adjustedY,
        },
      };
    },
    [currentTool],
  );

  return {
    handleTouchMove,
    handleTouchEnd,
    handleTouchStart,
  };
}

import { useCallback, useEffect, useMemo, useState } from 'react';

import { useUnit } from 'effector-react';
import { Stage } from 'konva/lib/Stage';
import { useParams } from 'react-router';

import {
  DesignData,
  DesignJSONV2 as DesignState,
  DesignObject,
  isImageJSON,
  isShapeJSON,
  isTextJSON,
} from '@api/designs';
import {
  Orientation,
  PageSize,
} from '@pages/StudioPage/components/page-setup/page-size';
import { DesignMetadata } from '@pages/StudioPage/lib/design';
import { DesignManager } from '@pages/StudioPage/lib/design-manager';
import { $tempNewSticky } from '@pages/StudioPage/model';
import { StickyType } from '@src/entities/Stickies/types';
import useStickies from '@src/entities/Stickies/useStickies';

/**
 * Hook to connect with the design file class manager. Allows for changes in class state to be reflected in react
 *
 * @param designId
 * @returns
 */

export function useStudioDesign(designId: string) {
  const design = DesignManager.getInstance(designId);
  const tempNewSticky = useUnit($tempNewSticky);

  const { pageNumber } = useParams<{
    pageNumber?: string;
  }>();

  const [designData, setDesignData] = useState<{
    state: DesignState | null;
    metadata: DesignMetadata;
  }>(() => ({
    state: design.getState(),
    metadata: design.getMetadata(),
  }));

  const { stickiesQuery } = useStickies(designId, null);

  const { pageNumberIndex: currentPageIndex, isLoading } = designData.metadata;

  useEffect(() => {
    const unsubscribe = design.subscribe((newState, newMetadata) => {
      setDesignData({
        state: structuredClone(newState),
        metadata: { ...newMetadata },
      });
    });

    const pn = typeof pageNumber === 'undefined' ? 0 : Number(pageNumber);

    if (!designData.state) {
      design.loadDesign({
        pageNumber: pn,
      });
    } else {
      const newState = design.getState();
      const newMetadata = design.getMetadata();

      if (!newState) return;

      setDesignData({
        state: newState,
        metadata: newMetadata,
      });
    }

    return () => {
      unsubscribe();
    };
  }, [design, designId]);

  const stickiesMapped = useMemo(() => {
    const stickies = stickiesQuery.data?.stickies;

    if (!stickies) return new Map<string, StickyType>();

    return new Map(stickies?.map((s) => [s.id, s]));
  }, [stickiesQuery.data]);

  const stickiesToRender: Array<StickyType> = useMemo(() => {
    if (designData.state === null) return [];

    if (designData.state.type === 'infinite') {
      const ids = designData.state.data.metadata.stickyIds ?? [];

      const finalRenderedStickies = ids
        .map((id) => stickiesMapped.get(id))
        .filter(
          (sticky): sticky is StickyType =>
            sticky !== null && sticky !== undefined,
        );

      if (tempNewSticky) finalRenderedStickies.push(tempNewSticky);

      return finalRenderedStickies;
    } else {
      if (
        currentPageIndex === null ||
        currentPageIndex >= designData.state.data.length
      )
        return [];

      const ids =
        designData.state.data[currentPageIndex].metadata.stickyIds ?? [];

      const finalRenderedStickies = ids
        .map((id) => stickiesMapped.get(id))
        .filter(
          (sticky): sticky is StickyType =>
            sticky !== null && sticky !== undefined,
        );

      if (tempNewSticky) finalRenderedStickies.push(tempNewSticky);

      return finalRenderedStickies;
    }
  }, [designData, currentPageIndex, stickiesMapped, tempNewSticky]);

  const objects = useMemo(() => {
    return designData.state === null
      ? []
      : designData.state.type === 'infinite'
      ? designData.state.data.objects
      : currentPageIndex !== null &&
        currentPageIndex < designData.state.data.length
      ? designData.state.data[currentPageIndex].objects
      : [];
  }, [designData, currentPageIndex]);

  const allPageObjects = useMemo(() => {
    return designData.state === null
      ? []
      : designData.state.type === 'infinite'
      ? designData.state.data.objects
      : designData.state.data.flatMap((page) => page.objects);
  }, [designData]);

  const images = useMemo(() => {
    return designData.state === null
      ? []
      : studioObjectsMapper({
          designData: designData.state.data,
          currentPageIdx: currentPageIndex,
          isCorrectObject: isImageJSON,
        });
  }, [designData, currentPageIndex, isImageJSON]);

  const textboxes = useMemo(() => {
    return designData.state === null
      ? []
      : studioObjectsMapper({
          designData: designData.state.data,
          currentPageIdx: currentPageIndex,
          isCorrectObject: isTextJSON,
        });
  }, [designData, currentPageIndex, isTextJSON]);

  const shapes = useMemo(() => {
    return designData.state === null
      ? []
      : studioObjectsMapper({
          designData: designData.state.data,
          currentPageIdx: currentPageIndex,
          isCorrectObject: isShapeJSON,
        });
  }, [designData, currentPageIndex, isShapeJSON]);

  return {
    designData,
    // Returns all objects on infinite or all objects on a page
    objects,
    // Returns all objects on infinite and all objects in all pages
    allPageObjects,
    images,
    textboxes,
    shapes,
    stickies: stickiesToRender,
    isLoading,
    isInPagesMode: useMemo(
      () => designData.state?.type === 'pages',
      [designData],
    ),
    currentPageIndex: currentPageIndex,
    changePage: useCallback(
      (page: number | null) => {
        return design.changePage(page ?? 0);
      },
      [design],
    ),
    addSticky: useCallback(
      (...params: Parameters<typeof design.addSticky>) =>
        design.addSticky(...params),
      [design],
    ),
    addImage: useCallback(
      (...params: Parameters<typeof design.addImage>) =>
        design.addImage(...params),
      [design],
    ),
    addText: useCallback(
      (...params: Parameters<typeof design.addText>) =>
        design.addText(...params),
      [design],
    ),
    addShape: useCallback(
      (...params: Parameters<typeof design.addShape>) =>
        design.addShape(...params),
      [design],
    ),
    updateImage: useCallback(
      (...params: Parameters<typeof design.updateImage>) =>
        design.updateImage(...params),
      [design],
    ),
    updateText: useCallback(
      (...params: Parameters<typeof design.updateText>) =>
        design.updateText(...params),
      [design],
    ),
    updateShape: useCallback(
      (...params: Parameters<typeof design.updateShape>) =>
        design.updateShape(...params),
      [design],
    ),
    updateLayer: useCallback(
      (...params: Parameters<typeof design.updateLayer>) =>
        design.updateLayer(...params),
      [design],
    ),
    updateLayers: useCallback(
      (...params: Parameters<typeof design.updateLayers>) =>
        design.updateLayers(...params),
      [design],
    ),
    applyPassedLayers: useCallback(
      (...params: Parameters<typeof design.applyPassedLayers>) =>
        design.applyPassedLayers(...params),
      [design],
    ),
    deleteObject: useCallback(
      (id: string) => design.deleteObject(id),
      [design],
    ),
    rotateSingleObject: useCallback(
      (id: string, amount: number) => design.rotateSingleObject(id, amount),
      [design],
    ),
    syncStickies: useCallback(
      (stickiesData: Array<StickyType>) => design.syncStickies(stickiesData),
      [design],
    ),
    rotateMultipleObjects: useCallback(
      (
        rotatedNodes: Array<{
          id: string;
          x: number;
          y: number;
          rotation: number;
        }>,
      ) => design.rotateMultipleObjects(rotatedNodes),
      [design],
    ),
    switchStateType: useCallback(
      (stage: Stage | null) => design.switchStateType({ stage }),
      [design],
    ),
    copyObjects: useCallback(
      (objectIds: string[]) => design.copyStudioObject(objectIds),
      [design],
    ),
    cutObjects: useCallback(
      (objectIds: string[]) => design.cutStudioObject(objectIds),
      [design],
    ),
    pasteObjects: useCallback(() => design.pasteStudioObjects(), [design]),
    deletePage: useCallback(
      (pageId: string) => {
        return design.deletePage(pageId);
      },
      [design],
    ),
    addPage: useCallback(
      (index?: number, id?: string) => design.addPage(index, id),
      [design],
    ),
    swapPage: useCallback(
      (
        currentIndex: number,
        swapTo: number,
        currentSelectedFrameId: string,
      ) => {
        return design.swapPage(currentIndex, swapTo, currentSelectedFrameId);
      },
      [design],
    ),
    updateBackgroundColor: useCallback(
      (backgroundColor: string, updateAllPages: boolean, pageId: string) =>
        design.updateBackgroundColor(backgroundColor, updateAllPages, pageId),
      [design],
    ),
    updatePagedSize: useCallback(
      (
        width: number,
        height: number,
        orientation: Orientation,
        size: PageSize,
        updateAllPages: boolean,
        pageId: string,
      ) =>
        design.updatePageSize(
          width,
          height,
          orientation,
          size,
          updateAllPages,
          pageId,
        ),
      [design],
    ),
    saveThumbnail: useCallback(
      (index: number, data: string) => design.saveThumbnail(index, data),
      [design],
    ),
    markPageForCopy: useCallback(
      (pageId: string) => design.markPageForCopy(pageId),
      [design],
    ),
    pastePage: useCallback(
      (index?: number) => design.pastePage(index),
      [design],
    ),
    duplicatePage: useCallback(
      (pageId: string) => {
        return design.duplicatePage(pageId);
      },
      [design],
    ),
    changeToEnd: useCallback(() => {
      return design.changeToEnd();
    }, [design]),
    currentPageMetadata: design.getCurrentPageMetadata,
    undo: useCallback(design.undo, [design]),
    redo: useCallback(design.redo, [design]),
    addToGroup: useCallback(
      (objectIds: Set<string>) => design.addToGroup(objectIds),
      [design],
    ),
    removeFromGroup: useCallback(
      (objectIds: Set<string>) => design.removeFromGroup(objectIds),
      [design],
    ),
    getGroupObjectsByObjectId: design.getGroupObjectsByObjectId,
    isAnyGrouped: design.isAnyGrouped,
    areAllInSameGroup: design.areAllInSameGroup,
  };
}

/**
 * Maps design objects to their specific types (images, text, shapes) based on the design data
 * @param {Object} params - The parameters object
 * @param {DesignData} params.designData - The design data containing objects to filter
 * @param {(obj: DesignObject) => obj is T} params.isCorrectObject - Type guard function to check if object matches desired type
 * @param {number | null} params.currentPageIdx - Current page index for pages mode, null for infinite mode
 * @returns {T[]} Array of filtered design objects matching the specified type (ImageJSON[], TextJSON[], or ShapeJSON[])
 * @template T - Type extending DesignObject (ImageJSON | TextJSON | ShapeJSON)
 */

const studioObjectsMapper = <T extends DesignObject>({
  designData,
  isCorrectObject,
  currentPageIdx,
}: {
  designData: DesignData;
  isCorrectObject: (obj: DesignObject) => obj is T;
  currentPageIdx: number | null;
}) => {
  if (!designData) return [] as T[];

  if (!Array.isArray(designData)) {
    return designData.objects.filter((obj) => isCorrectObject(obj));
  }

  if (currentPageIdx !== null && currentPageIdx < designData.length) {
    return designData[currentPageIdx].objects.filter((obj) =>
      isCorrectObject(obj),
    );
  }

  return [] as T[];
};

import { useHistory, useLocation } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { moveBoard } from '@api/boards';
import {
  BOARD_QUERY,
  BOARDS_OF_HUB_QUERY,
  BOARDS_TREE_QUERY,
} from '@src/shared/constants';
import { boardsKeys } from '@src/shared/constants/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useMoveBoard = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const moveBoardMutation = useMutation({
    mutationFn: ({ parent, children }: { parent: string; children: string }) =>
      moveBoard({ parent, children }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_OF_HUB_QUERY, { hub: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: boardsKeys.all,
      });

      startedSnack({
        label: 'Moved board',
        action: {
          label: 'Go to new location',
          action: () => {
            history.push(`/b/${variables.children}`);
          },
        },
        close: true,
      });
    },
    onError: (err, variables) => {
      startedSnack({
        label: 'Couldn’t move board',
        action: {
          label: 'Try again',
          action: () => {
            moveBoardMutation.mutate({
              parent: variables.parent,
              children: variables.children,
            });
          },
        },
        close: true,
      });
    },
  });

  return {
    moveBoardMutation,
  };
};

import { PropsWithChildren } from 'react';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import { CheckboxMark } from './animated/checkmark';
import { HubIcon } from './animated/hub';
import { PaintBrushIcon } from './animated/paint-brush';
import { PlaneIcon } from './animated/plane';
import { SearchIcon } from './animated/search';
import { WindowIcon } from './animated/window';

import styles from './styles.module.css';

type AnimatedIcon =
  | 'search'
  | 'paint-brush'
  | 'plane'
  | 'checkmark'
  | 'hub'
  | 'window';

export const BrandLoader = ({
  animatedIcon,
  subText = 'Loading...',
}: {
  animatedIcon: AnimatedIcon;
  subText: string;
}) => {
  return (
    <div className={styles.container}>
      <AnimatedIcon animatedIcon={animatedIcon} />
      <TypographyPoppins type="body" size="S" className={styles.text}>
        {subText}
      </TypographyPoppins>
    </div>
  );
};

BrandLoader.CenterContainer = ({ children }: PropsWithChildren) => {
  return <div className={styles.centerContainer}>{children}</div>;
};

const AnimatedIcon = ({ animatedIcon }: { animatedIcon: AnimatedIcon }) => {
  switch (animatedIcon) {
    case 'search':
      return <SearchIcon />;
    case 'paint-brush':
      return <PaintBrushIcon />;
    case 'plane':
      return <PlaneIcon />;
    case 'checkmark':
      return <CheckboxMark />;
    case 'hub':
      return <HubIcon />;
    case 'window':
      return <WindowIcon />;
    default:
      throw new Error('Invalid animated icon type passed');
  }
};

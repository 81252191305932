import cn from 'classnames';
import { useUnit } from 'effector-react';
import { isDesktop } from 'react-device-detect';
import { useParams } from 'react-router';

import {
  AssistChip,
  ColorMenu,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { ThumbnailBrightness } from '@api/hubs';
import { useAddCover } from '@pages/HubPage/model/queries/useAddCover';
import { useInvitees } from '@src/entities/share-sheet/hub/model/queries/use-invitees';
import { hexToRgb, isLightColor } from '@src/shared/constants/colours';

import { useRemoveCover } from '../../../../pages/HubPage/model/queries/useRemoveCover';
import {
  $background,
  $colors,
  $defaultColor,
  $gradients,
  $isCustomColor,
  $isOpenColorMenu,
  backgroundSelected,
  colorMenuClosed,
  colorMenuToggled,
  colorPicked,
} from './model';

import styles from './styles.module.css';

interface SelectCoverProps {
  thumbnail: string;
  thumbnailHex: string;
  customColors: string[];
  updateCustomColors: (customColors: string) => void;
  isArchived: boolean;
}

export const SelectCover = ({
  thumbnail,
  thumbnailHex,
  customColors,
  updateCustomColors,
  isArchived,
}: SelectCoverProps) => {
  const isOpenColorMenu = useUnit($isOpenColorMenu);
  const colors = useUnit($colors);
  const gradients = useUnit($gradients);
  const selectedBackground = useUnit($background);
  const defaultColor = useUnit($defaultColor);
  const isCustomColor = useUnit($isCustomColor);

  const { hub_id } = useParams<{ hub_id: string }>();

  const { isEditor } = useInvitees({ id: hub_id });

  const { addCover } = useAddCover();
  const { removeCover } = useRemoveCover();

  const selectColor = (color: string) => {
    let rgb = null;
    let coverColor: ThumbnailBrightness = 'light';

    if (color.includes('#')) {
      rgb = hexToRgb(color);
      coverColor = isLightColor(rgb) ? 'light' : 'dark';
    }

    colorPicked(color);
    backgroundSelected(color);

    addCover({
      hubId: hub_id,
      thumbnail_hex: color,
      thumbnailBrightness: coverColor,
    });
  };

  const selectFile = ({
    file,
    coverColor,
  }: {
    file: string | FileList;
    coverColor: 'light' | 'dark';
  }) => {
    if (file && coverColor) {
      addCover({
        hubId: hub_id,
        thumbnail: file,
        thumbnailBrightness: coverColor,
      });
      colorPicked(file);
      colorMenuClosed();
    }
  };

  return (
    <div className={styles.cover}>
      {!isEditor() ? null : (!thumbnailHex && !thumbnail) ||
        thumbnailHex === defaultColor ? (
        isArchived ? (
          <AssistChip
            style="elevated"
            className={styles.addCover}
            leadingIcon
            icon={<Icon name="sprite/image" />}
            disabled
          >
            Add cover
          </AssistChip>
        ) : (
          <Popover
            open={isOpenColorMenu}
            onOpenChange={() => colorMenuToggled()}
          >
            <PopoverTrigger asChild>
              <AssistChip
                style="elevated"
                className={styles.addCover}
                leadingIcon
                icon={<Icon name="sprite/image" />}
              >
                Add cover
              </AssistChip>
            </PopoverTrigger>
            <PopoverContent align="end">
              <ColorMenu
                colors={colors}
                customColors={customColors}
                updateCustomColors={updateCustomColors}
                gradients={gradients}
                background={thumbnailHex}
                selectedBackground={selectedBackground}
                isCustomColor={isCustomColor}
                backgroundSelected={selectColor}
                colorMenuClosed={colorMenuClosed}
                selectFile={selectFile}
                isTabs
                isDebounce
                style={{ height: '185px' }}
              />
            </PopoverContent>
          </Popover>
        )
      ) : (
        <div
          className={cn(styles.updateCover, {
            [styles.show]: isOpenColorMenu,
            [styles.touchscreenCover]: !isDesktop,
          })}
        >
          <AssistChip
            style="elevated"
            className={styles.deleteCover}
            leadingIcon
            icon={<Icon name="sprite/bin" />}
            onClick={() => {
              removeCover({
                hubId: hub_id,
                thumbnail_hex: defaultColor,
              });
              backgroundSelected('');
            }}
            disabled={isArchived}
          >
            Remove cover
          </AssistChip>
          {isArchived ? (
            <AssistChip
              style="elevated"
              className={styles.changeCover}
              leadingIcon
              icon={<Icon name="sprite/image" />}
              disabled
            >
              Change cover
            </AssistChip>
          ) : (
            <Popover
              open={isOpenColorMenu}
              onOpenChange={() => colorMenuToggled()}
            >
              <PopoverTrigger asChild>
                <AssistChip
                  style="elevated"
                  className={styles.changeCover}
                  leadingIcon
                  icon={<Icon name="sprite/image" />}
                >
                  Change cover
                </AssistChip>
              </PopoverTrigger>
              <PopoverContent align="end">
                <ColorMenu
                  colors={colors}
                  customColors={customColors}
                  updateCustomColors={updateCustomColors}
                  gradients={gradients}
                  background={thumbnailHex}
                  selectedBackground={selectedBackground}
                  isCustomColor={isCustomColor}
                  backgroundSelected={selectColor}
                  colorMenuClosed={colorMenuClosed}
                  selectFile={selectFile}
                  isTabs
                  isDebounce
                  style={{ height: '185px' }}
                />
              </PopoverContent>
            </Popover>
          )}
        </div>
      )}
    </div>
  );
};

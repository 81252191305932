import { useHistory, useParams } from 'react-router-dom';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { fileCardIdSelected, openedPanel } from '@pages/FileCard/model';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createColorPaletteFromColorTheme } from '../../../../api/blocks';
import {
  ALL_BOARD_IMAGES_QUERY,
  BOARD_IMAGES_QUERY,
  LIBRARY_QUERY,
} from '../../../../shared/constants';

export const useAddColorPalette = () => {
  const history = useHistory();
  const { board_id } = useParams<{ board_id: string }>();
  const queryClient = useQueryClient();

  const { mutate: addColorPalette, ...mutateStates } = useMutation({
    mutationFn: (payload: { id: string; theme: string; board?: string }) => {
      if (board_id) {
        payload.board = board_id;
      }

      return createColorPaletteFromColorTheme(payload);
    },
    onMutate: () => {
      startedSnack({
        label: 'Creating palette...',
        close: true,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [LIBRARY_QUERY],
      });

      if (board_id) {
        queryClient.invalidateQueries({
          queryKey: [BOARD_IMAGES_QUERY],
        });
        queryClient.invalidateQueries({
          queryKey: [ALL_BOARD_IMAGES_QUERY],
        });
      }

      startedSnack({
        label: 'Created new palette',
        action: {
          label: 'Go to file',
          action: () => {
            history.push(`#/f/${data.data.id}`);
            openedPanel('palette');
            // TODO
            fileCardIdSelected(data.data.id);
          },
        },
        close: true,
      });
    },
    onError: (_, payload) => {
      if (board_id) {
        payload.board = board_id;
      }

      startedSnack({
        label: "Couldn't create palette",
        action: {
          label: 'Try again',
          action: () => {
            addColorPalette(payload);
          },
        },
        close: true,
      });
    },
  });

  return { addColorPalette, ...mutateStates };
};

import { createEvent, createStore } from 'effector';
import { hotkey } from 'effector-hotkey';

export const hubRestoringDialogOpened = createEvent();
export const hubRestoringDialogClosed = createEvent();
export const hubRestorationContinued = createEvent();
export const hubRestorationCanceled = createEvent();

export const $isHubRestoringDialog = createStore(false);

$isHubRestoringDialog
  .on(hubRestoringDialogOpened, () => true)
  .reset([hubRestoringDialogClosed, hubRestorationContinued]);

hotkey({
  key: 'Escape',
  filter: $isHubRestoringDialog,
  target: [hubRestoringDialogClosed, hubRestorationCanceled],
});

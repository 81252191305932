import React, { useCallback } from 'react';

import { useUnit } from 'effector-react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Text as TText } from 'konva/lib/shapes/Text';
import { debounce } from 'lodash';
import { isMobile } from 'react-device-detect';
import { flushSync } from 'react-dom';
import { Group, Text, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';

import { useKeyPress } from '@visualist/hooks';

import { TextJSON } from '@api/designs';
import { TEXT, TEXT_GROUP, TRANSFORMER } from '@pages/StudioPage/constants';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import { useSnap } from '@pages/StudioPage/hooks/useSnap';
import {
  $currentTool,
  $dragSelection,
  $isEditingText,
  $selectedObjectIdInGroup,
  $selectedObjectIds,
  changedEditingState,
  selectObjectIdInGroup,
  selectObjectIds,
} from '@pages/StudioPage/model';
import { $isShiftPressed } from '@pages/StudioPage/shift-key-tracking';
import { TERTIARY_40 } from '@src/shared/constants/colours';
import { usePrevious } from '@src/shared/hooks/usePrevious';

import styles from './styles.module.css';

type Props = {
  id: string;
  textJSON: TextJSON;
  designId: string;
  hoveredSelection: string;
  setHoveredSelection: (id: string) => void;
  isInPagesMode: boolean;
};

const PADDING = 4;

const DOUBLE_CLICK_DETECT_MS = 400;

const ERROR_PADDING = 2;

export const Textbox = (props: Props) => {
  // State
  const currentTool = useUnit($currentTool);
  const selectedObjectIds = useUnit($selectedObjectIds);
  const selectedObjectIdInGroup = useUnit($selectedObjectIdInGroup);
  const dragSelection = useUnit($dragSelection);
  const isEditingText = useUnit($isEditingText);
  const isShiftPressed = useUnit($isShiftPressed);

  const {
    x,
    y,
    // opacity,
    rotation,
    metadata: {
      content,
      height,
      width,
      alignment,
      lockWidth,
      bold,
      colour,
      fontFamily,
      fontSize,
      italic,
      underline,
      groupId,
    },
  } = props.textJSON;

  /**
   * Editing state is a temp store that while an edit is taking place that effects size we store it in here
   * Once the edit is committed to the design this value is reset. A wannabe transaction
   */
  const [editingState, setEditingState] = React.useState<{
    content: string;
    height: number;
    width: number;
  } | null>(null);

  const {
    deleteObject,
    updateText,
    getGroupObjectsByObjectId,
    areAllInSameGroup,
  } = useStudioDesign(props.designId);

  // Refs
  const konvaTextRef = React.useRef<TText>(null);
  const transformerRef = React.useRef<Konva.Transformer>(null);
  const hoverTransformerRef = React.useRef<Konva.Transformer>(null);
  const textareaInputRef = React.useRef<HTMLTextAreaElement | null>(null);
  const growingElementRef = React.useRef<HTMLDivElement>(null);
  const dblClickTimer = React.useRef<number | null>(null);

  // Derive state from textJSON or if in process of editing before a commit is made
  const activeWidth = editingState !== null ? editingState.width : width;
  const activeHeight = editingState !== null ? editingState.height : height;
  const activeContent = editingState !== null ? editingState.content : content;

  // Derived state
  const isSelected =
    selectedObjectIds.size === 1 && selectedObjectIds.has(props.id);
  const isInMassSelection =
    selectedObjectIds.has(props.id) && selectedObjectIds.size > 1;
  const isSelectedInGroup = selectedObjectIdInGroup === props.id;
  const canDrag = 'select' === currentTool;
  const isHovered =
    props.id === props.hoveredSelection && currentTool !== 'move';
  const isEditingThisText = isEditingText && (isSelected || isSelectedInGroup);
  const wasSelection = usePrevious(isSelected || isSelectedInGroup);

  // Handlers
  useKeyPress({
    key: 'Escape',
    onKeyDown: () => {
      changedEditingState(false);
      selectObjectIds(new Set());
    },
  });

  const { onEndCheckSnap, onMoveAnchorSnap, onMoveCheckSnap } = useSnap({
    usePageGuides: props.isInPagesMode,
  });

  const enableEditing = () => {
    if (currentTool === 'move') return;

    syncTextboxSizes();
    flushSync(() => changedEditingState(true));

    setTimeout(() => {
      textareaInputRef.current?.focus();
      textareaInputRef.current?.setSelectionRange(0, activeContent.length);
    }, 50);
  };

  const debounceTextCommit = useCallback(
    debounce((text: string, currentWidth: number, currentHeight: number) => {
      updateText({
        id: props.id,
        metadata: {
          content: text,
          width: currentWidth,
          height: currentHeight,
        },
      });
      setEditingState(null);
    }, 300),
    [updateText, props.id, setEditingState, debounce],
  );

  const onEditText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const growingElement = growingElementRef.current;
    if (!growingElement) return;

    const newContent = e.target.value;

    growingElement.textContent = newContent;

    const newHeight = growingElement.clientHeight;
    const newWidth = lockWidth
      ? width
      : growingElement.clientWidth + ERROR_PADDING;

    flushSync(() => {
      setEditingState({
        content: newContent,
        height: newHeight,
        width: newWidth,
      });

      syncTextboxSizes();

      debounceTextCommit(newContent, newWidth, newHeight);
    });
  };

  const dragStarted = () => {
    if (isInMassSelection && !isSelectedInGroup) return;

    selectTextBox();
  };

  const updateDragPosition = (e: KonvaEventObject<DragEvent>) => {
    onMoveCheckSnap({ e, selectedObjectIds, selectedObjectIdInGroup });
  };

  const commitDragPosition = (e: KonvaEventObject<DragEvent>) => {
    onEndCheckSnap(e);

    const newX = e.currentTarget.x();
    const newY = e.currentTarget.y();

    updateText({
      id: props.id,
      x: newX,
      y: newY,
    });
  };

  const onTransform = () => {
    const node = konvaTextRef.current;
    // Do not update state in these because each state udpate would count as an undo redo. The transformer will scale the object on its own
    if (!node || !transformerRef.current) return;

    //Middle anchor is solely for width not scaling so if its being transformed reset scale to 1
    const isMiddleAnchors = transformerRef.current
      .getActiveAnchor()
      ?.includes('middle');

    if (isMiddleAnchors) {
      const newWidth = node.width() * node.scaleX();
      node.width(newWidth);
      if (growingElementRef.current) {
        growingElementRef.current.style.width = `${newWidth}px`;
        node.height(growingElementRef.current.clientHeight);
      }
      node.scaleX(1);
    }
  };

  const commitTransformChange = (e: KonvaEventObject<Event>) => {
    const node = konvaTextRef.current;

    if (!node || !transformerRef.current) return;

    onEndCheckSnap(e);

    //Middle anchor is solely for width not scaling so if its being transformed reset scale to 1
    const shouldScale = transformerRef.current
      .getActiveAnchor()
      ?.includes('middle');

    const newWidth = node.width() * node.scaleX();
    const newHeight = node.height() * node.scaleY();
    const newRotation = node.rotation();

    const growingHeight = growingElementRef.current?.clientHeight ?? newHeight;

    const scale = node.scaleY();

    flushSync(() => {
      node.width(newWidth);
      node.height(newHeight);

      updateText({
        id: props.id,
        x: e.currentTarget.x(),
        y: e.currentTarget.y(),
        rotation: newRotation,
        metadata: {
          width: newWidth,
          height: shouldScale ? growingHeight : newHeight,
          fontSize: Math.round(fontSize * scale),
          lockWidth: true,
        },
      });
    });

    node.scaleX(1);
    node.scaleY(1);

    syncTextboxSizes();
  };

  const syncTextboxSizes = () => {
    const growing = growingElementRef.current;
    const textareaRef = textareaInputRef.current;

    if (!growing || !textareaRef) return;

    textareaRef.style.height = `${growing.clientHeight}px`;
    // Do not change width if it has been resized
    if (!lockWidth)
      textareaRef.style.width = `${growing.clientWidth + ERROR_PADDING}px`;

    // Update Konva text node dimensions to match growing text area
    if (konvaTextRef.current) {
      konvaTextRef.current.height(growing.clientHeight);
      if (!lockWidth)
        konvaTextRef.current.width(growing.clientWidth + ERROR_PADDING);
    }
  };

  React.useEffect(() => {
    if (activeContent === '') {
      enableEditing();
    }
  }, []);

  // Remove text if no content
  React.useEffect(() => {
    if (wasSelection && (!isSelected || !isSelectedInGroup)) {
      // Selection has just been lost, delete if the textbox is empty
      if (content === '') {
        deleteObject(props.id);
      }
    }
  }, [isSelected, isSelectedInGroup]);

  // Set Transformers
  React.useEffect(() => {
    if ((isSelected || isSelectedInGroup) && transformerRef.current) {
      // we need to attach transformer manually
      // @ts-ignore
      transformerRef.current.nodes([konvaTextRef.current]);
      transformerRef.current.moveToTop();
      // @ts-ignore
      transformerRef.current.getLayer().batchDraw();
    }
  }, [isSelected, isSelectedInGroup]);

  React.useEffect(() => {
    if (
      (isHovered ||
        (isEditingText && (isSelected || isSelectedInGroup)) ||
        isInMassSelection) &&
      hoverTransformerRef.current
    ) {
      // we need to attach hover transformer manually
      // @ts-ignore
      hoverTransformerRef.current.nodes([konvaTextRef.current]);
      // @ts-ignore
      hoverTransformerRef.current.getLayer().batchDraw();
    }
  }, [
    isHovered,
    isEditingThisText,
    isSelected,
    isSelectedInGroup,
    isInMassSelection,
  ]);

  // Cleanup timer on unmount
  React.useEffect(() => {
    return () => {
      dblClickTimer.current = null;
    };
  }, []);

  // Selection handlers
  const selectTextBox = () => {
    const currentTime = new Date().getTime();

    if (dblClickTimer.current) {
      if (currentTime - dblClickTimer.current <= DOUBLE_CLICK_DETECT_MS) {
        // Double click detected
        enableEditing();
        // Clear the timer immediately after double click
        dblClickTimer.current = null;
      } else {
        // Reset timer if too much time has passed
        dblClickTimer.current = currentTime;
      }
    } else {
      // First click
      dblClickTimer.current = currentTime;
    }

    const groupObjects = getGroupObjectsByObjectId(props.id);
    const groupObjectsSet = new Set(groupObjects);
    if (isShiftPressed) {
      const newSelectedText = new Set(selectedObjectIds);

      if (newSelectedText.has(props.id)) {
        groupObjectsSet.forEach((objectId) => newSelectedText.delete(objectId));
      } else {
        groupObjectsSet.forEach((objectId) => newSelectedText.add(objectId));
      }

      selectObjectIds(newSelectedText);
      selectObjectIdInGroup(null);
      return;
    }

    if (selectedObjectIds.has(props.id) && isMobile) {
      // Allow double select on mobile
      enableEditing();
      return;
    }

    if (currentTool === 'add-text') {
      selectObjectIds(new Set([props.id]));
      enableEditing();
      return;
    }

    if (!canDrag) return;

    if (
      groupId &&
      areAllInSameGroup(selectedObjectIds) &&
      selectedObjectIds.has(props.id)
    ) {
      selectObjectIdInGroup(props.id);
    }
    selectObjectIds(groupObjectsSet);
  };

  const mouseEntersText = () => props.setHoveredSelection(props.id);

  const mouseLeavesText = () => props.setHoveredSelection('');

  const calculateFontFamily = () => {
    if (bold && italic) {
      return 'bold italic';
    }

    if (bold) {
      return 'bold';
    }

    if (italic) {
      return 'italic';
    }

    return 'normal';
  };

  const textareaStyle = {
    whiteSpace: 'pre-wrap',
    letterSpacing: konvaTextRef.current?.letterSpacing(),
    fontSize: fontSize,
    resize: 'none',
    textAlign: alignment,
    color: colour,
    fontWeight: bold ? 'bold' : 'normal',
    fontStyle: italic ? 'italic' : 'normal',
    textDecoration: underline ? 'underline' : 'none',
    lineHeight: konvaTextRef.current?.lineHeight(),
    fontFamily: fontFamily,
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
  } as React.CSSProperties;

  return (
    <>
      <Group name={TEXT_GROUP}>
        <Text
          name={TEXT}
          padding={PADDING}
          id={props.id}
          ref={konvaTextRef}
          text={activeContent}
          onClick={selectTextBox}
          onTap={selectTextBox}
          onMouseEnter={mouseEntersText}
          onMouseLeave={mouseLeavesText}
          onDragStart={dragStarted}
          onDragEnd={commitDragPosition}
          onDragMove={updateDragPosition}
          onTransform={onTransform}
          onTransformEnd={commitTransformChange}
          x={x}
          y={y}
          // wrap={lockWidth ? 'none' : 'word'}
          rotation={rotation}
          draggable={canDrag}
          fontSize={fontSize}
          width={activeWidth + ERROR_PADDING}
          height={activeHeight}
          align={alignment}
          fill={colour}
          fontFamily={fontFamily}
          fontStyle={calculateFontFamily()}
          textDecoration={underline ? 'underline' : undefined}
          visible={!isEditingThisText}
        />
        <Group x={x} y={y} rotation={rotation}>
          <Html
            divProps={{
              style: {
                visibility: isEditingThisText ? 'visible' : 'hidden',
              },
              className: styles.textAreaContainer,
            }}
          >
            {/* Main editing text component */}
            <textarea
              id={`${props.id}-textarea`}
              name="textarea"
              ref={textareaInputRef}
              defaultValue={activeContent}
              className={styles.textArea}
              style={{
                ...textareaStyle,
                width: lockWidth ? `${width}px` : 'auto',
                height: '100%',
                padding: `${PADDING}px`,
              }}
              onChange={onEditText}
              onClick={(e) => e.stopPropagation()}
              spellCheck={false}
              autoCorrect="off"
              autoCapitalize="off"
              autoComplete="off"
              data-gramm="false"
              data-gramm_editor="false"
              data-enable-grammarly="false"
            />
          </Html>
        </Group>
        {/* Special text size for growing the textarea and measuring it */}
        <GrowingArea
          text={props.textJSON}
          ref={growingElementRef}
          content={activeContent}
          textareaStyle={textareaStyle}
          syncTextboxSizes={syncTextboxSizes}
        />
      </Group>
      {(isSelected && !isEditingThisText) || isSelectedInGroup ? (
        <Transformer
          // @ts-ignore TODO fix type issue
          ref={transformerRef}
          id={`${props.id}-${TRANSFORMER}`}
          name={TRANSFORMER}
          keepRatio={true}
          borderStroke={TERTIARY_40}
          onClick={selectTextBox}
          flipEnabled={false}
          anchorStroke={TERTIARY_40}
          anchorDragBoundFunc={(oldPos, newPos) =>
            onMoveAnchorSnap(
              oldPos,
              newPos,
              konvaTextRef,
              selectedObjectIds,
              selectedObjectIdInGroup,
            )
          }
          anchorCornerRadius={100}
          // When text is selected click events go to transformer because of this property
          shouldOverdrawWholeArea
          enabledAnchors={[
            'top-right',
            'top-left',
            'bottom-left',
            'bottom-right',
            'middle-right',
            'middle-left',
          ]}
        />
      ) : null}
      {(!isSelected && isHovered && !dragSelection) ||
      isEditingThisText ||
      isInMassSelection ? (
        // Transformer for hovering selection
        <Transformer
          // @ts-ignore TODO fix type issue
          ref={hoverTransformerRef}
          flipEnabled={false}
          keepRatio={false}
          enabledAnchors={[]}
          rotateEnabled={false}
          resizeEnabled={false}
          borderStroke={TERTIARY_40}
          anchorStroke={TERTIARY_40}
          name={TRANSFORMER}
        />
      ) : null}
    </>
  );
};

const GrowingArea = React.forwardRef<
  HTMLDivElement,
  {
    text: TextJSON;
    textareaStyle: React.CSSProperties;
    content: string;
    syncTextboxSizes: () => void;
  }
>((props, ref) => {
  const { text, textareaStyle, content } = props;
  const {
    x,
    y,
    rotation,
    metadata: { width, lockWidth },
  } = text;

  // Replace all newline characters with <br /> for proper HTML rendering
  const formattedContent = content.replace(/\n/g, '<br />');

  const htmlContent = content === '' ? 'A' : formattedContent;

  return (
    <Group x={x + width} y={y} rotation={rotation}>
      <Html
        divProps={{
          style: {
            visibility: 'hidden',
            width: lockWidth ? `${width}px` : 'auto',

            minHeight: '1em',
            minWidth: '1ch',
          },
          className: styles.textAreaContainer,
        }}
      >
        <div
          id={`${props.text.id}-growing`}
          ref={(r) => {
            if (typeof ref === 'function') {
              ref(r);
            } else if (ref) {
              ref.current = r;
            }

            props.syncTextboxSizes();
          }}
          style={{
            ...textareaStyle,
            width: '100%',
            minWidth: '1ch',
            height: 'auto',
            minHeight: '1.6em',
            padding: PADDING,
            boxSizing: 'border-box',
            wordBreak: 'break-word',
          }}
          className={styles.textAreaInvisible}
          dangerouslySetInnerHTML={{
            __html: htmlContent + '<br />',
          }}
        />
      </Html>
    </Group>
  );
});

GrowingArea.displayName = 'GrowingArea';

import { useHistory } from 'react-router';

import {
  Avatar as DSAvatar,
  Dropdown,
  Item,
  User,
} from '@visualist/design-system/src/components/v2';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { Icon } from '@visualist/icons';

import { useAppData } from '@src/AppContext';

export const Avatar = () => {
  const history = useHistory();

  const { user, signOut } = useAppData();

  const logout = () => {
    signOut && signOut();
  };

  const goToAccount = () => {
    history.push('/account/profile', {
      from: history.location.pathname,
    });
  };

  const menuItems: Item<Variant>[] = [
    {
      leadingIcon: <User fill="none" />,
      content: 'Account settings',
      onClick: () => goToAccount(),
    },
    {
      leadingIcon: <Icon name="sprite/logout" />,
      content: 'Log out',
      onClick: () => logout(),
    },
  ];

  return (
    <Dropdown>
      <Dropdown.Menu
        trigger={
          <DSAvatar
            image={user.photo?.medium_square_crop}
            initials={`${user.first_name ?? ''} ${user.last_name ?? ''}`}
            email={user.email}
            size={36}
          />
        }
        side="bottom"
        sideOffset={10}
        density="-2"
      >
        {menuItems.map((item, index) => (
          <Dropdown.MenuItem key={index} item={item} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';

interface RotateNodesParams {
  selectionTransformerRef: Konva.Transformer | null;
  stageRef: Stage | null;
  rotateAmount: number;
}

export const rotateMultipleNodes = ({
  selectionTransformerRef,
  stageRef,
  rotateAmount,
}: RotateNodesParams) => {
  if (!selectionTransformerRef || !stageRef) return;
  const transformer = selectionTransformerRef;
  const nodes = transformer.nodes();
  if (nodes.length === 0) return;

  const box = transformer;

  const center = {
    x: box.getAbsolutePosition().x + box.width() / 2,
    y: box.getAbsolutePosition().y + box.height() / 2,
  };

  const rad = (rotateAmount * Math.PI) / 180;

  const rotatedNodes: { id: string; x: number; y: number; rotation: number }[] =
    [];
  nodes.forEach((node) => {
    const nodePos = node.absolutePosition();

    const dx = nodePos.x - center.x;
    const dy = nodePos.y - center.y;

    const newDx = dx * Math.cos(rad) - dy * Math.sin(rad);
    const newDy = dx * Math.sin(rad) + dy * Math.cos(rad);

    const newX = center.x + newDx;
    const newY = center.y + newDy;

    node.absolutePosition({
      x: newX,
      y: newY,
    });

    // Update node rotation
    const newRotation = (node.rotation() + rotateAmount) % 360;
    rotatedNodes.push({
      id: node.id(),
      x: node.x(),
      y: node.y(),
      rotation: newRotation,
    });
  });

  return rotatedNodes;
};

import Konva from 'konva';
import { Stage } from 'konva/lib/Stage';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { AlignmentType } from '@pages/StudioPage/components/main-toolbar/multi-select-toolbar/constants';
import { getSelectedNodes } from '@pages/StudioPage/utils';

export const nodeAlignment = (
  items: string[],
  type: AlignmentType[number],
  selectionTransformer: Konva.Transformer | null,
  stage: Stage | null,
) => {
  if (!selectionTransformer || !stage) {
    return;
  }

  const box = selectionTransformer;
  const nodes = getSelectedNodes(stage, items);
  if (!nodes.length) return;

  const boundingBox = box;
  const stageScale = stage.scaleX();

  // Calculate reference points
  const lockBoxValues = {
    left: boundingBox.absolutePosition().x,
    right: boundingBox.absolutePosition().x + boundingBox.width(),
    centre: boundingBox.absolutePosition().x + boundingBox.width() / 2,
    top: boundingBox.absolutePosition().y,
    bottom: boundingBox.absolutePosition().y + boundingBox.height(),
    middle: boundingBox.absolutePosition().y + boundingBox.height() / 2,
  };

  nodes.forEach((node) => {
    if (!stage) return;

    const nodeBox = node.getClientRect();
    let newX = node.x();
    let newY = node.y();

    switch (type) {
      case 'Left': {
        const offset = lockBoxValues.left - nodeBox.x;
        newX = node.x() + offset / stageScale;
        break;
      }

      case 'Right': {
        const nodeRight = nodeBox.x + nodeBox.width;
        const offset = lockBoxValues.right - nodeRight;
        newX = node.x() + offset / stageScale;
        break;
      }

      case 'Top': {
        const offset = lockBoxValues.top - nodeBox.y;
        newY = node.y() + offset / stageScale;
        break;
      }

      case 'Bottom': {
        const nodeBottom = nodeBox.y + nodeBox.height;
        const offset = lockBoxValues.bottom - nodeBottom;
        newY = node.y() + offset / stageScale;
        break;
      }

      case 'Center': {
        const nodeCenter = nodeBox.x + nodeBox.width / 2;
        const offset = lockBoxValues.centre - nodeCenter;
        newX = node.x() + offset / stageScale;
        break;
      }

      case 'Middle': {
        const nodeMiddle = nodeBox.y + nodeBox.height / 2;
        const offset = lockBoxValues.middle - nodeMiddle;
        newY = node.y() + offset / stageScale;
        break;
      }

      default:
        console.error(
          `Alignment error! Unknown alignment type: ${String(type)}`,
        );
        startedSnack({
          label: 'Oops, something went wrong.',
          close: true,
        });
        return;
    }

    node.position({
      x: newX,
      y: newY,
    });

    node.fire('dragend', {
      type: 'dragend',
      target: node,
      evt: new MouseEvent('dragend'),
    });
  });
};

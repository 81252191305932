import { useLayoutEffect, useRef, useState } from 'react';

import Markdown from 'react-markdown';

import {
  Button,
  StarPointyVaiDiagonal,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import styles from './styles.module.css';

export const VaiPinnedCard = ({
  cardTitle,
  cardContent,
  onCloseClick,
  onSeeMoreClick,
}: {
  cardTitle: string;
  cardContent: string;
  onCloseClick: () => void;
  onSeeMoreClick?: () => void;
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (contentRef.current) {
      const lineHeight = parseFloat(
        getComputedStyle(contentRef.current).lineHeight,
      );
      const maxLinesHeight = lineHeight * 6; // Calculate the height for 6 lines
      setIsOverflowing(contentRef.current.scrollHeight > maxLinesHeight);
    }
  }, [cardContent]);

  return (
    <div className={styles.vaiCardWrapper}>
      <div className={styles.vaiCardHeader}>
        <StarPointyVaiDiagonal fill="none" size={24} />
        <TypographyPoppins type="body" size="L" className={styles.vaiCardTitle}>
          {cardTitle}
        </TypographyPoppins>
        <Icon
          name="sprite/x"
          size={24}
          className={styles.closeIcon}
          onClick={onCloseClick}
        />
      </div>
      <TypographyPoppins type="body" size="M">
        <div ref={contentRef} className={styles.vaiCardContent}>
          <Markdown className={styles.vaiCardContent}>{cardContent}</Markdown>
        </div>
      </TypographyPoppins>
      {isOverflowing && (
        <Button
          label="See more"
          className={styles.seeMoreBtn}
          style={{ color: 'var(--color-wolfe-20)' }}
          onClick={() => onSeeMoreClick && onSeeMoreClick()}
          type="ghost"
        />
      )}
    </div>
  );
};

import { useHistory } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { bulkUndoDeleteBlock } from '@api/blocks';
import {
  ALL_BOARD_IMAGES_QUERY,
  BOARD_IMAGES_QUERY,
  LIBRARY_QUERY,
} from '@src/shared/constants';
import { recycleBin } from '@src/shared/constants/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUndoDeleteBlock = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { mutate: putBlockBack } = useMutation({
    mutationFn: ({ id, location }: { id: string; location?: string }) =>
      bulkUndoDeleteBlock({ id, location }),
    onSuccess: (_, variables) => {
      const location = variables.location
        ? `/b/${variables.location}`
        : '/library';

      queryClient.invalidateQueries({
        queryKey: [LIBRARY_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_IMAGES_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARD_IMAGES_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: recycleBin.all,
      });

      startedSnack({
        label: 'Restored file',
        action: {
          label: 'Go to file',
          action: () => history.push(location),
        },
        close: true,
      });
    },
    onError: (err, variables) => {
      startedSnack({
        label: 'Couldn’t restore',
        action: {
          label: 'Try again',
          action: () => {
            putBlockBack({
              id: variables.id,
              location: variables.location,
            });
          },
        },
        close: true,
      });
    },
  });

  return { putBlockBack };
};

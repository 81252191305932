import { shapeType } from '../Shape';

export const getInitialShapeMetada = (currentShape: shapeType) => {
  let shapeMetadata = {};
  if (currentShape === 'circle') {
    shapeMetadata = { radius: 5 };
  } else if (currentShape === 'hexagon') {
    shapeMetadata = { radius: 20, sides: 6 };
  } else if (currentShape === 'ellipse') {
    shapeMetadata = { radiusX: 50, radiusY: 20 };
  } else if (currentShape === 'star') {
    shapeMetadata = { numPoints: 6, innerRadius: 10, outerRadius: 20 };
  } else if (currentShape === 'wedge') {
    shapeMetadata = { radius: 5, angle: 60 };
  } else if (currentShape === 'ring') {
    shapeMetadata = { innerRadius: 10, outerRadius: 20 };
  } else if (currentShape === 'arc') {
    shapeMetadata = { innerRadius: 10, outerRadius: 20, angle: 60 };
  } else if (currentShape === 'line') {
    shapeMetadata = { points: [0, 0, 1, 0] };
  } else if (currentShape === 'arrow') {
    shapeMetadata = {
      points: [0, 0, 1, 0],
      pointerWidth: 0,
      pointerLength: 0,
    };
  } else {
    shapeMetadata = { width: 5, height: 10 };
  }
  return shapeMetadata;
};

export const calculateShapeMetadata = ({
  x,
  y,
  startX,
  startY,
  currentShape,
}: {
  x: number;
  y: number;
  startX: number;
  startY: number;
  currentShape: shapeType;
}) => {
  const width = Math.abs(x - startX);
  const height = Math.abs(y - startY);
  let newX = startX;
  let newY = startY;
  let shapeMetadata = {};
  if (
    currentShape === 'circle' ||
    currentShape === 'hexagon' ||
    currentShape === 'wedge'
  ) {
    const radius = Math.max(width, height) / 2 || 1;
    newX = startX + (x > startX ? radius : -radius);
    newY = startY + (y > startY ? radius : -radius);
    shapeMetadata = { radius };
  } else if (currentShape === 'ellipse') {
    const radiusX = width / 2 || 1;
    const radiusY = height / 2 || 1;
    newX = startX + (x > startX ? radiusX : -radiusX);
    newY = startY + (y > startY ? radiusY : -radiusY);
    shapeMetadata = { radiusX: radiusX, radiusY: radiusY };
  } else if (
    currentShape === 'star' ||
    currentShape === 'ring' ||
    currentShape === 'arc'
  ) {
    const outerRadius = Math.max(width, height) / 2 || 1;
    const innerRadius = outerRadius * 0.5;
    if (currentShape === 'arc') {
      const deltaX = x - startX;
      const deltaY = y - startY;
      if (deltaX >= 0 && deltaY >= 0) {
        // Bottom-right corner: Keep start position fixed
        newX = startX;
        newY = startY;
      } else if (deltaX < 0 && deltaY < 0) {
        // Top-left corner: Move position to expand correctly
        newX = startX - outerRadius;
        newY = startY - outerRadius;
      } else if (deltaX >= 0 && deltaY < 0) {
        // Top-right corner: Adjust only Y position
        newX = startX;
        newY = startY - outerRadius;
      } else {
        // Bottom-left corner: Adjust only X position
        newX = startX - outerRadius;
        newY = startY;
      }
    } else {
      newX = startX + (x > startX ? outerRadius : -outerRadius);
      newY = startY + (y > startY ? outerRadius : -outerRadius);
    }
    shapeMetadata = { innerRadius: innerRadius, outerRadius: outerRadius };
  } else if (currentShape === 'line') {
    shapeMetadata = { points: [0, 0, x - startX, y - startY] };
  } else if (currentShape === 'arrow') {
    shapeMetadata = {
      points: [0, 0, x - startX, y - startY],
      pointerWidth: 5,
      pointerLength: 5,
    };
  } else {
    newX = Math.min(startX, x) || 1;
    newY = Math.min(startY, y) || 1;
    shapeMetadata = { width, height };
  }
  return { newX, newY, shapeMetadata };
};

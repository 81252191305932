import { NavItemList } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { BoardItem, HubItem } from '@api/bin';
import { useHubs } from '@pages/HubPage/model/queries/useHubs';
import { deletePermanentlyModalOpened } from '@src/entities/delete-modals/model';

import { Board } from '../board';
import { Menu } from '../menu';
import { Person } from '../person';
import { Time } from '../time';

import styles from '../styles.module.css';

type Props = {
  item: HubItem | BoardItem;
};

export const Hub = ({ item }: Props) => {
  const boardChildren = item.child.filter(
    (child): child is BoardItem => child.item_type === 'Board',
  );

  const { putHubBack } = useHubs();

  const restore = () => {
    if (item.item_type === 'Hub') {
      putHubBack(item.hub.id);
    }
  };

  const deletePermanently = () => {
    if (item.item_type === 'Hub') {
      deletePermanentlyModalOpened(
        item.child.length > 0
          ? { file: item.item_type, id: item.hub.id, type: 'group' }
          : { file: item.item_type, id: item.hub.id, type: 'single' },
      );
    }
  };

  return (
    <li className={styles.row}>
      <NavItemList
        key={item.id}
        className={styles.item}
        classNameContent={styles.align}
        indent={1}
        date={<Time date={item.deleted_at} isIndent />}
        user={<Person user={item.deleted_by} isIndent />}
        menu={
          <Menu
            item={item}
            restore={restore}
            deletePermanently={deletePermanently}
          />
        }
        name={item.item_type === 'Hub' && item.hub.name}
        leadingIcon={<Icon name="sprite/hub" />}
        onClick={() => {}}
        isSelected={false}
      >
        {boardChildren.length > 0
          ? boardChildren.map((boardChild) => (
              <Board key={boardChild.id} item={boardChild} indent={2} />
            ))
          : null}
      </NavItemList>
    </li>
  );
};

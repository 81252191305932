import cn from 'classnames';
import { isMobileOnly } from 'react-device-detect';

import {
  CenteredSpinner,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { DocumentHead } from '@components/DocumentHead';
import { useAppData } from '@src/AppContext';
import { SomethingWentWrong } from '@src/entities/global-error/premade/something-went-wrong';
import { useBilling } from '@src/shared/queries/useBilling';
import { useQuery } from '@tanstack/react-query';

import { getHubs } from '../../api/hubs';
import { LibraryActions } from '../../components/LibraryActions';
import { ALL_HUBS_QUERY } from '../../shared/constants/query-names';
import { FileCard } from '..';
import { HomeBanner } from './HomeBanner';
import { Hubs } from './Hubs';
import { useUserActions } from './model/queries/useUserActions';
import { useUserMessages } from './model/queries/useUserMessages';
import { Overview } from './Overview';
import { VaiCard } from './vai-card';
import { WelcomeMessage } from './WelcomeMessage';

import styles from './styles.module.css';

export const HomePage = () => {
  const { user } = useAppData();

  const {
    query: { isLoading, isError, isSuccess, data },
  } = useBilling();

  const { data: hubs = [] } = useQuery({
    queryKey: [ALL_HUBS_QUERY, user.show_archived],
    queryFn: () =>
      getHubs({ ordering: '-created_at', is_archived: user.show_archived }),
  });

  const { items } = useUserActions();
  const { messagesQuery } = useUserMessages();

  // To be replaced with snackbars
  if (isLoading) return <CenteredSpinner />;

  if (isError) return <SomethingWentWrong />;

  if (isSuccess && data) {
    const plan = data.membershipTierInfo.plan;

    return (
      <div className={styles.homePage}>
        <FileCard />
        <div
          className={cn(styles.container, {
            [styles.mobile]: isMobileOnly,
          })}
        >
          <DocumentHead name="Home" />
          <WelcomeMessage />
          <TypographyPoppins type="headline" size="M" className={styles.header}>
            Dashboard
          </TypographyPoppins>
          <ul className={styles.cardsList}>
            <VaiCard />
            <Overview
              actions={items.data?.count}
              messages={messagesQuery.data}
            />
          </ul>
          <div className={styles.bannerContainer}>
            <HomeBanner plan={plan} />
          </div>
          <Hubs hubs={hubs} plan={plan} />
          <LibraryActions />
        </div>
      </div>
    );
  }

  return <SomethingWentWrong />;
};

import { useUnit } from 'effector-react';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { recycleBin } from '@src/shared/constants/query-keys';
import { $entityType } from '@src/shared/utils/get-entity-type';
import { MutateOptions, useMutation } from '@tanstack/react-query';

import { bulkDeleteBlock, permanentDelete } from '../../../../../api/blocks';
import { queryClient } from '../../../../../queryClient';
import {
  ALL_BOARD_IMAGES_QUERY,
  BOARD_IMAGES_QUERY,
  LIBRARY_QUERY,
} from '../../../../../shared/constants/query-names';

export const useDeleteBDC = (options?: MutateOptions<any, any, any>) => {
  const entityType = useUnit($entityType);

  const getSuccessLabel = () => {
    if (entityType === 'image') {
      return 'Moved image to bin';
    } else if (entityType === 'design') {
      return 'Moved design to bin';
    } else {
      return 'Moved palette to bin';
    }
  };

  const getErrorLabel = () => {
    if (entityType === 'image') {
      return "Couldn't move image to bin";
    } else if (entityType === 'design') {
      return "Couldn't delete design";
    } else {
      return "Couldn't move palette to bin";
    }
  };

  const { mutate: removeBlock } = useMutation({
    mutationFn: (blockId: string[] | null) =>
      bulkDeleteBlock({ block_ids: blockId }),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [LIBRARY_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_IMAGES_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARD_IMAGES_QUERY],
      });

      if (variables?.length === 1) {
        startedSnack({
          label: getSuccessLabel(),
          close: true,
        });
      } else {
        startedSnack({
          label: 'Moved files to bin',
          close: true,
        });
      }

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      startedSnack({
        label: getErrorLabel(),
        action: {
          label: 'Try again',
          action: () => removeBlock(variables),
        },
        close: true,
      });
      if (options?.onError) options?.onError(error, variables, context);
    },
  });

  const { mutate: permanentDeleteBlock } = useMutation({
    mutationFn: (id: string) => permanentDelete(id),
    ...(options ?? {}),
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: recycleBin.all,
      });

      startedSnack({
        label: 'Permanently deleted',
        close: true,
      });

      if (options?.onSuccess) options?.onSuccess(data, variables, context);
    },
    onError: (error, variables, context) => {
      startedSnack({
        label: 'Couldn’t permanently delete',
        action: {
          label: 'Try again',
          action: () => permanentDeleteBlock(variables),
        },
        close: true,
      });
      if (options?.onError) options?.onError(error, variables, context);
    },
  });

  return { removeBlock, permanentDeleteBlock };
};

import React, { useEffect } from 'react';

/**
 * Hook to persist state in localStorage
 * @param key - localStorage key
 * @param initialValue - initial state value
 * @returns [storedValue, setValue, reset] - tuple with current value, setter, and reset function
 */

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}":`, error);

      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
      window.dispatchEvent(new Event('localStorageChange'));
    } catch (error) {
      console.warn(`Error setting localStorage key "${key}":`, error);
    }
  };

  const reset = () => {
    setValue(initialValue);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      try {
        const item = window.localStorage.getItem(key);

        setStoredValue(!item ? initialValue : JSON.parse(item));
      } catch (e) {
        console.warn(`Error handling storage change for key "${key}":`, e);
        setStoredValue(initialValue);
      }
    };

    window.addEventListener('localStorageChange', handleStorageChange);

    return () => {
      window.removeEventListener('localStorageChange', handleStorageChange);
    };
  }, [setStoredValue, key]);

  return [storedValue, setValue, reset] as const;
};

import React from 'react';

import { Stage } from 'konva/lib/Stage';

import { useImages } from '@pages/StudioPage/hooks/useImages';
import { asyncLoadImage } from '@pages/StudioPage/utils';
import { ALLOWED_UPLOAD_IMAGE_FORMATS } from '@src/shared/utils/allowed-formats';

type Props = {
  designId: string;
  stage: Stage | null;
};

export const FileUpload = React.forwardRef<HTMLInputElement, Props>(
  ({ designId, stage }, ref) => {
    const { imageUploadMutation } = useImages({ designId });

    const handleFileUpload = async (
      // TODO send backend correct position x and y and average height and width
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      if (!stage) {
        console.error('No stage');
        return;
      }

      const forms: FormData[] = [];
      const center = {
        x: (stage.width() / 2 - stage.x()) / stage.scaleX(),
        y: (stage.height() / 2 - stage.y()) / stage.scaleY(),
      };
      let containsNonImage = false;

      if (!event.target.files) {
        // SEND ERROR
        console.log('Error');
        return;
      }

      // For multiple files, add them horizontally
      let previousWidth = 0;

      for (let i = 0; i < event.target.files.length; i++) {
        if (event.target.files[i].type.includes('image')) {
          const formData = new FormData();
          const file = event.target.files[i];
          if (!file) {
            console.error('No file added');
            return;
          }

          const img = await asyncLoadImage(URL.createObjectURL(file));

          const newWidth = 1000;
          const newHeight = (1000 * img.height) / img.width;

          formData.append('width', img.width.toString() || newWidth.toString());
          formData.append(
            'height',
            img.height.toString() || newHeight.toString(),
          );

          formData.append('file', file);
          formData.append(
            'position_x',
            Math.trunc(center.x + previousWidth).toString(),
          );
          previousWidth += img.width || newWidth;
          formData.append('position_y', Math.trunc(center.y).toString());
          formData.append(
            'id',
            `${Math.random() * 100}-${new Date().toTimeString()}`,
          );
          formData.append('set', designId);
          formData.append('is_hidden_from_library', 'false');
          forms.push(formData);
        } else {
          containsNonImage = true;
        }
      }

      if (containsNonImage) {
        // addNotification({
        //   message: "Couldn't upload. Try adding only PNG or JPG files.",
        //   type: 'warning',
        // });
      }

      if (forms.length > 0) {
        imageUploadMutation.mutate({ forms });
      }
    };

    return (
      <input
        ref={ref}
        hidden
        multiple
        type="file"
        accept={ALLOWED_UPLOAD_IMAGE_FORMATS}
        onChange={handleFileUpload}
      />
    );
  },
);

import cn from 'classnames';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { openedPlanModal, openedTrialSheet } from '@pages/AccountPage/models';
import { useBilling } from '@src/shared/queries/useBilling';

import { Plan } from '../types';

import styles from './styles.module.css';

type Props = {
  credits: number;
  plan: Plan;
};

type Detailed_Plans =
  | 'free_or_starter_with_credits'
  | 'free_or_starter_without_credits'
  | 'pro_without_credits'
  | 'pro_with_1_4_credits'
  | 'pro_with_5_credits';

const PLAN_INFO_COPY: Record<Detailed_Plans, string> = {
  free_or_starter_with_credits: 'You can’t use hubs on your current plan',
  free_or_starter_without_credits: 'You can’t use hubs on your current plan',
  pro_without_credits: 'Make sure you’re saving with hub bundles',
  pro_with_1_4_credits: 'Top up now so you don’t run out',
  pro_with_5_credits: 'Pre-pay with bundles to save up to 33%',
};

export const LargeHubCredit = ({ credits, plan }: Props) => {
  const planCopy = getCopy(plan, credits);

  const { query: data } = useBilling();

  if (!data) {
    return <div className={styles.loader}></div>;
  }

  const showButton = plan === 'free' || plan === 'starter';
  const hasHadTrial = data.data?.membershipTierInfo.trialInfo.has_had_trial;
  const buttonCopy =
    !hasHadTrial && (plan === 'free' || plan === 'starter')
      ? 'Trial Pro and get 100 free credits'
      : 'Upgrade to use your credits';

  const openBasedOnPlan = () => {
    if (plan === 'pro') return;

    if (
      (plan === 'free' || plan === 'starter') &&
      data.data?.membershipTierInfo.trialInfo.has_had_trial
    ) {
      openedPlanModal({
        mode: 'upgrade-downgrade',
        // planModalMode: `${plan}-pro`,
        planToChange: 'pro',
        planModalMode: 'free-pro',
        isPlanModalOpen: true,
      });
      return;
    }

    openedTrialSheet();
  };

  const isPro = plan === 'pro' || plan === 'trial';

  return (
    <div
      className={cn(styles.container, {
        [styles.border]:
          plan === 'free' || plan === 'starter' || (isPro && credits >= 5),
        [styles.darker]: isPro && credits === 0,
        [styles.lighter]: isPro && credits >= 1 && credits <= 4,
      })}
    >
      <TypographyPoppins type="headline" size="L">
        <span className={styles.hubBalanceHeader}>Your credit balance</span>
      </TypographyPoppins>
      <div className={styles.creditContainer}>
        <TypographyPoppins type="display" size="M">
          <span className={styles.creditValue}>{credits}</span>
        </TypographyPoppins>
        <TypographyPoppins type="body" size="M">
          <span className={styles.creditLabel}>
            {credits === 1 ? 'credit' : 'credits'}
          </span>
        </TypographyPoppins>
      </div>
      <TypographyPoppins type="body" size="S">
        <span className={styles.description}>{planCopy}</span>
      </TypographyPoppins>
      {showButton ? (
        <Button onClick={openBasedOnPlan} type="filled" label={buttonCopy} />
      ) : null}
    </div>
  );
};

const getCopy = (plan: Plan, credits: number) => {
  if (plan === 'free' || plan === 'starter') {
    return PLAN_INFO_COPY['free_or_starter_with_credits'];
  } else {
    // Pro plan
    if (credits === 0) {
      return PLAN_INFO_COPY['pro_without_credits'];
    } else if (credits >= 1 && credits <= 4) {
      return PLAN_INFO_COPY['pro_with_1_4_credits'];
    } else {
      return PLAN_INFO_COPY['pro_with_5_credits'];
    }
  }
};

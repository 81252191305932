import { customAlphabet } from 'nanoid';
export const nanoid = customAlphabet(
  '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz',
);

const prefixes = {
  studio_shape_preview: 'studio_shape_preview',
  studio_text_object: 'studio_text_object',
  studio_object: 'studio_object',
  studio_page: 'studio_page',
  studio_group: 'studio_group',
  palette: 'palette',
  color: 'color',
  test: 'test',
} as const;

export function generateId(prefix: keyof typeof prefixes): string {
  return [prefixes[prefix], nanoid(16)].join('_');
}

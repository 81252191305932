import { createEvent, createStore, sample } from 'effector';

import { Permissions } from '@api/users';

export type Email = {
  email: string;
};

export const boardIdSelected = createEvent<string>();
export const boardIdCleared = createEvent();

export const queryEntered = createEvent<string>();
export const queryCleared = createEvent();

export const emailsEntered = createEvent<Email[]>();
export const emailsCleared = createEvent();

export const permissionsSelected = createEvent<Permissions[]>();
export const permissionsCleared = createEvent();

export const $id = createStore<string | null>(null);
export const $query = createStore<string>('');
export const $emails = createStore<Email[]>([]);
export const $permissions = createStore<Permissions[]>([]);

$id.on(boardIdSelected, (_, id) => id).reset(boardIdCleared);

$query.on(queryEntered, (_, query) => query).reset(queryCleared);

$emails.on(emailsEntered, (_, emails) => emails).reset(emailsCleared);

$permissions
  .on(permissionsSelected, (_, permissions) => permissions)
  .reset(permissionsCleared);

sample({
  clock: $emails,
  filter: (emails) => emails.length === 0,
  fn: () => [],
  target: $permissions,
});

import cn from 'classnames';

import {
  SegmentedButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';

import { ResponseDoc } from '@api/docs';

import styles from './styles.module.css';

export const TextStyleButton = (props: {
  textIcon: React.ReactNode;
  textIconStyle: React.CSSProperties;
  isActive: boolean;
  toggle: () => void;
  tooltipText: string;
  doc?: ResponseDoc;
}) => {
  const { textIcon, isActive, textIconStyle, toggle, tooltipText, doc } = props;

  const icon =
    typeof textIcon === 'string' ? (
      <span style={textIconStyle}>{textIcon}</span>
    ) : (
      textIcon
    );

  return (
    <TooltipRadix description={tooltipText} side="bottom">
      <SegmentedButton
        buttonStyle={cn(styles.button, {
          [styles.archived]: doc?.is_archived,
        })}
        isSelected={isActive}
        icon={icon}
        onClick={toggle}
        isDisabled={doc?.is_archived}
      />
    </TooltipRadix>
  );
};

import { useUnit } from 'effector-react';

import { Dialog, Modal } from '@visualist/design-system/src/components/v2';

import {
  $isHubRestoringDialog,
  hubRestorationCanceled,
  hubRestorationContinued,
  hubRestoringDialogClosed,
} from './model';

export const HubRestoringDialog = () => {
  const isHubRestoringDialog = useUnit($isHubRestoringDialog);

  const close = () => {
    hubRestorationCanceled();
    hubRestoringDialogClosed();
  };

  return (
    <Modal handleClose={close} showModal={isHubRestoringDialog}>
      <Dialog
        headline="Note about billing"
        description={`You’ll be billed for this hub starting from today.

Do you want to proceed with restoring this hub and its contents?`}
        action={hubRestorationContinued}
        actionLabel="I'm sure"
        closeAction={close}
      />
    </Modal>
  );
};

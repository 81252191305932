import { useHistory, useLocation } from 'react-router';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { unarchiveHub } from '@api/hubs';
import { updatedHubFilter } from '@pages/action-items/modal';
import {
  ALL_HUBS_QUERY,
  BOARD_QUERY,
  BOARDS_OF_HUB_QUERY,
  BOARDS_TREE_QUERY,
  HUB_QUERY,
} from '@src/shared/constants';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUnarchiveHub = () => {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const history = useHistory();

  const unarchiveHubMutation = useMutation({
    mutationFn: ({ id }: { id: string }) => unarchiveHub({ id }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [HUB_QUERY, { hubId: data.id }],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_HUBS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_OF_HUB_QUERY, { hub: data.id }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });

      updatedHubFilter({
        id: data.id,
        name: data.name,
        isArchived: false,
      });

      startedSnack({
        label: 'Unarchived hub',
        action: {
          label: 'Go to hub',
          action: () => history.push(`/h/${data.id}`),
        },
        close: true,
      });
    },
    onError: (err, variables) => {
      startedSnack({
        label: 'Couldn’t unarchive hub',
        action: {
          action: () => {
            unarchiveHubMutation.mutate({ id: variables.id });
          },
          label: 'Try again',
        },
        close: true,
      });
    },
  });

  return {
    unarchiveHubMutation,
  };
};

import { createEvent, createStore } from 'effector';

export type DesignMetadata = {
  id: string;
  index: number;
  name: string;
};
export type SavedDesigns = Map<string, DesignMetadata>;

export const addedOpenedDesigns = createEvent<DesignMetadata>();
export const removedOpenedDesigns = createEvent<string>();
export const replaceOpenedDesigns = createEvent<Map<string, DesignMetadata>>();

export const $openedDesigns = createStore<Map<string, DesignMetadata>>(
  new Map(),
)
  .on(replaceOpenedDesigns, (_, designs) => designs)

  .on(addedOpenedDesigns, (state, design) => {
    const newState = new Map(state);
    newState.set(design.id, design);
    return newState;
  })
  .on(removedOpenedDesigns, (state, designId) => {
    const newState = new Map(state);
    newState.delete(designId);
    return newState;
  });

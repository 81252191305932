/*
 * The hook is created following the tutorial on
 * https://www.fullstacklabs.co/blog/keyboard-shortcuts-with-react-hooks
 */
import { useCallback, useEffect, useReducer } from 'react';

type Action = {
  type: 'set-key-down' | 'set-key-up' | 'reset-keys' | null;
  key?: any;
  data?: any;
};

const keysReducer = (state: string[], action: Action) => {
  switch (action.type) {
    case 'set-key-down':
      return { ...state, [action.key]: true };
    case 'set-key-up':
      return { ...state, [action.key]: false };
    case 'reset-keys':
      return { ...action.data };
    default:
      return state;
  }
};

const useKeyboardShortcut = (
  shortcutKeys: string[],
  callback: (keys: string) => void,
  shouldEnableInput?: boolean,
) => {
  /*
   * Add some warnings to our custom hook to make sure that all of the
   * parameter conditions are met
   */

  if (!Array.isArray(shortcutKeys))
    throw new Error(
      'The first parameter to `useKeyboardShortcut` must be an ordered array of `KeyboardEvent.key` strings.',
    );
  if (!shortcutKeys.length)
    throw new Error(
      'The first parameter to `useKeyboardShortcut` must contain at least one `KeyboardEvent.key` string.',
    );

  if (callback === null || typeof callback !== 'function')
    throw new Error(
      'The second parameter to `useKeyboardShortcut` must be a function that will be invoked when the keys are pressed.',
    );

  // A state object and a reducer in order to track key presses and releases
  const initalKeyMapping = shortcutKeys.reduce((currentKeys: any, key: any) => {
    currentKeys[key.toLowerCase()] = false;
    return currentKeys;
  }, {});

  const [keys, setKeys] = useReducer(keysReducer, initalKeyMapping);

  // Keydown and Keyup handling functions
  const keydownListener = useCallback(
    (assignedKey: string) => (keydownEvent: KeyboardEvent) => {
      const loweredKey = assignedKey.toLowerCase();
      const target = keydownEvent.target as Element;

      if (target.nodeName === 'INPUT' && !shouldEnableInput) return;
      if (target.nodeName === 'TEXTAREA') return;
      if (keydownEvent.repeat) return;
      if (loweredKey !== keydownEvent.key.toLowerCase()) return;
      if (keys[loweredKey] === undefined) return;

      setKeys({ type: 'set-key-down', key: loweredKey });
      return false;
    },
    [keys],
  );
  const keyupListener = useCallback(
    (assignedKey: string) => (keyupEvent: any) => {
      const raisedKey = assignedKey.toLowerCase();

      if (keyupEvent?.key?.toLowerCase() !== raisedKey) return;
      if (keys[raisedKey] === undefined) return;

      setKeys({ type: 'set-key-up', key: raisedKey });
      return false;
    },
    [keys],
  );

  // Two event listeners, one for keydown events and the other for keyup events
  useEffect(() => {
    if (!Object.values(keys).filter((value) => !value).length) {
      callback(keys);
      setKeys({ type: 'reset-keys', data: initalKeyMapping });
    } else {
      setKeys({ type: null });
    }
  }, [callback, keys, initalKeyMapping]);

  useEffect(() => {
    shortcutKeys.forEach((k: any) =>
      window.addEventListener('keydown', () => {
        keydownListener(k);
      }),
    );
    return () =>
      shortcutKeys.forEach((k: any) =>
        window.removeEventListener('keydown', keydownListener(k)),
      );
    // eslint-disable-next-line
  }, []);

  /*
   * The hook that will fire our callback function for the keyboard shortcut. It will
   * check out keys state object to make sure that all of the keys are currently being held
   * down. Once that criteria is met it will fire the callback function
   */
  useEffect(() => {
    shortcutKeys.forEach((k: any) =>
      window.addEventListener('keyup', keyupListener(k)),
    );
    return () =>
      shortcutKeys.forEach((k: any) =>
        window.removeEventListener('keyup', keyupListener(k)),
      );
    // eslint-disable-next-line
  }, []);
};

export default useKeyboardShortcut;

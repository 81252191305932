import { useEffect, useRef } from 'react';

import { Icon } from '@visualist/icons';

import { Location } from './model';
import { widthUpdated } from './model/blur';

type Props = {
  location: Location | null;
};

export const useLocation = ({ location }: Props) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        widthUpdated(entry.contentRect.width);
      });
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        resizeObserver.unobserve(ref.current);
      }
    };
  }, [location]);

  const getLeadingIcon = () => {
    if (location && location.type === 'hub') {
      return <Icon name="sprite/hub" />;
    } else if (location && location.type === 'board') {
      return <Icon name="sprite/board" />;
    } else {
      return null;
    }
  };

  const getTrailingIcon = () => {
    if (location) {
      return <Icon name="sprite/x" color="var(--color-neutral-variant-30)" />;
    } else {
      return <Icon name="sprite/caret-down" />;
    }
  };

  const isShowTooltip = () => {
    if (!location) return false;
    if (location.type === 'hub') return false;
    if (location.type === 'board' && location.level === 0 && !location.parent) {
      return false;
    }
    return true;
  };

  return {
    ref,
    getLeadingIcon,
    getTrailingIcon,
    isShowTooltip,
  };
};

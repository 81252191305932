import { createEffect, createEvent, createStore, sample } from 'effector';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

export const fontsLoadFx = createEffect(async () => {
  await document.fonts.ready;
});

const snackBarShowFx = createEffect(() => {
  startedSnack({ label: 'Oops, we had trouble loading your fonts' });
});

const fontsLoaded = createEvent();

export const $isFontsLoaded = createStore(false);

$isFontsLoaded.on(fontsLoaded, () => true);

sample({
  clock: fontsLoadFx.done,
  target: fontsLoaded,
});

sample({
  clock: fontsLoadFx.fail,
  target: snackBarShowFx,
});

fontsLoadFx();

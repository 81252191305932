import React from 'react';

import { useUnit } from 'effector-react';
import Konva from 'konva';
import { Group as TGroup } from 'konva/lib/Group';
import { KonvaEventObject } from 'konva/lib/Node';
import { Image as TImage } from 'konva/lib/shapes/Image';
import { flushSync } from 'react-dom';
import { Group, Image as KonvaImage, Rect, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';
import useLoadedImage from 'use-image';

import { ImageJSON } from '@api/designs';
import { IMAGE, IMAGE_GROUP, TRANSFORMER } from '@pages/StudioPage/constants';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import { useSnap } from '@pages/StudioPage/hooks/useSnap';
import { Design } from '@pages/StudioPage/lib/design';
import {
  $currentlyCroppingPhoto,
  $currentTool,
  $dragSelection,
  $selectedObjectIdInGroup,
  $selectedObjectIds,
  endCroppingImage,
  selectObjectIdInGroup,
  selectObjectIds,
  startedCroppingImage,
} from '@pages/StudioPage/model';
import { $isShiftPressed } from '@pages/StudioPage/shift-key-tracking';
import { useAppData } from '@src/AppContext';
import { TERTIARY_40 } from '@src/shared/constants/colours';
import useStickies from '@src/shared/queries/useStickies';

import { CroppingImage } from './cropping-image';

import styles from './styles.module.css';

const LOADING_COLOURS = ['#DCD5CB', '#8A6F51', '#68402E', '#8A6F51', '#DCD5CB'];

type LoadedImageProps = {
  url: string;
  designId: string;
  id: string;
  stageRef: React.RefObject<Konva.Stage>;
  mostUpperRightImage: boolean;
  isInPagesMode: boolean;
  imageJSON: ImageJSON;
  updateImage: (...params: Parameters<Design['updateImage']>) => void;
};

export const LoadedImage = ({
  url,
  imageJSON,
  updateImage,
  designId,
  id,
  stageRef,
  mostUpperRightImage,
  isInPagesMode,
}: LoadedImageProps) => {
  const selectedObjectIds = useUnit($selectedObjectIds);
  const selectedObjectIdInGroup = useUnit($selectedObjectIdInGroup);
  const currentTool = useUnit($currentTool);
  const dragSelection = useUnit($dragSelection);
  const currentlyCroppingPhoto = useUnit($currentlyCroppingPhoto);
  const isCropping = currentlyCroppingPhoto === id;
  const isShiftPressed = useUnit($isShiftPressed);

  // Hooks
  const { user } = useAppData();
  const [image, status] = useLoadedImage(url, 'anonymous');

  const { bulkMutateStickies, stickiesQuery } = useStickies(designId, null);

  // Local State
  const [hasMounted, setHasMounted] = React.useState(false);
  const x = imageJSON.x;
  const y = imageJSON.y;
  const width = imageJSON.metadata.width || imageJSON.metadata.originalWidth;
  const height = imageJSON.metadata.height || imageJSON.metadata.originalHeight;
  const originalWidth = imageJSON.metadata.originalWidth;
  const originalHeight = imageJSON.metadata.originalHeight;
  const rotation = imageJSON.rotation;
  const crop = imageJSON.metadata.crop;
  const groupId = imageJSON.metadata.groupId;
  const [isHovered, setIsHovered] = React.useState(false);
  const [isDragging, setIsDragging] = React.useState(false);
  const lastTapTime = React.useRef(0);

  // Refs
  const groupRef = React.useRef<TGroup | null>(null);
  const imageRef = React.useRef<TImage | null>(null);
  const draggingImageRef = React.useRef<TImage | null>(null);
  const trRef = React.useRef<Konva.Transformer>(null);
  const draggingImageTrRef = React.useRef<Konva.Transformer>(null);
  const hoveredTrRef = React.useRef<Konva.Transformer>(null);
  const positionBeforeDrag = React.useRef({
    x: imageJSON.x,
    y: imageJSON.y,
  });

  const { getGroupObjectsByObjectId, areAllInSameGroup } =
    useStudioDesign(designId);

  const { onMoveCheckSnap, onEndCheckSnap, onMoveAnchorSnap } = useSnap({
    usePageGuides: isInPagesMode,
  });

  // On the fly calculations
  const isSelected = selectedObjectIds.has(id) && selectedObjectIds.size === 1;
  const isInMassSelection =
    selectedObjectIds.has(id) && selectedObjectIds.size > 1;
  const isSelectedInGroup = selectedObjectIdInGroup === id;
  const canDrag = currentTool === 'select' && !isCropping;

  const removeSelectedImageTransformer = () => {
    // @ts-ignore
    trRef.current.nodes([]);
    // @ts-ignore
    trRef.current.getLayer().batchDraw();
  };

  const redrawTransformer = () => {
    // @ts-ignore
    trRef.current.nodes([groupRef.current]);
    // @ts-expect-error
    trRef.current.moveToTop();
    //@ts-ignore
    trRef.current.getLayer().batchDraw();
  };

  React.useEffect(() => {
    // Used to update the transformer when transforming the image
    if ((isSelected || isSelectedInGroup) && trRef.current) {
      // we need to attach transformer manually
      redrawTransformer();
      trRef.current.zIndex(500);
    } else if (trRef.current && !isSelected) removeSelectedImageTransformer();

    return () => {
      if (trRef?.current) removeSelectedImageTransformer();
    };
    // isCropping passed so transformer is applied when the crop mode turns off
  }, [isSelected, isSelectedInGroup, isCropping]);

  const removeHoveredImageTransformer = () => {
    // @ts-ignore
    hoveredTrRef.current.nodes([]);
    // @ts-ignore
    hoveredTrRef.current.getLayer().batchDraw();
  };

  React.useEffect(() => {
    // Used to update the transformer when transforming the image
    if ((isHovered || isInMassSelection) && hoveredTrRef.current) {
      // we need to attach transformer manually
      // @ts-ignore
      hoveredTrRef.current.nodes([imageRef.current]);
      // @ts-ignore
      hoveredTrRef.current.getLayer().batchDraw();
    } else if (!(isHovered || isInMassSelection) && hoveredTrRef.current)
      removeHoveredImageTransformer();

    return () => {
      if (hoveredTrRef.current) removeHoveredImageTransformer();
    };
  }, [isHovered, isInMassSelection]);

  React.useEffect(() => {
    // Adds event listeners to the html div and it has no pointer events
    if (!imageRef.current) return;

    const handlePointerEnter = () => {
      setIsHovered(true);
    };
    const handlePointerLeave = () => {
      setIsHovered(false);
    };

    imageRef.current.addEventListener('pointerenter', handlePointerEnter);
    imageRef.current.addEventListener('pointerleave', handlePointerLeave);

    return () => {
      if (!imageRef.current) return;
      imageRef.current.removeEventListener('pointerenter');
      imageRef.current.removeEventListener('pointerleave');
    };
  }, [hasMounted]);

  const onSelectImage = () => {
    setIsDragging(false);
    const groupObjects = getGroupObjectsByObjectId(id);
    const groupObjectsSet = new Set(groupObjects);
    if (isShiftPressed) {
      const newSelectedImages = new Set(selectedObjectIds);

      if (newSelectedImages.has(id)) {
        groupObjectsSet.forEach((objectId) =>
          newSelectedImages.delete(objectId),
        );
      } else {
        groupObjectsSet.forEach((objectId) => newSelectedImages.add(objectId));
      }

      selectObjectIds(newSelectedImages);
      selectObjectIdInGroup(null);
      return;
    }

    if (!canDrag) return;

    if (
      groupId &&
      areAllInSameGroup(selectedObjectIds) &&
      selectedObjectIds.has(id)
    ) {
      selectObjectIdInGroup(id);
    }
    selectObjectIds(groupObjectsSet);
  };

  const dragStarted = () => {
    if (isInMassSelection && !isSelectedInGroup) return;
    onSelectImage();
    setIsDragging(true);
    positionBeforeDrag.current = {
      x: imageJSON.x,
      y: imageJSON.y,
    };
  };

  const updateDragPosition = (e: KonvaEventObject<DragEvent>) => {
    onEndCheckSnap(e);
    if (!groupRef.current) return;

    if (isSelected || isSelectedInGroup) {
      onSelectImage();
    }

    const moveToX = e.currentTarget.x();
    const moveToY = e.currentTarget.y();

    setIsDragging(false);

    updateImage({ id, x: moveToX, y: moveToY });

    if (!stageRef?.current) return;

    if (!stickiesQuery.data) return;

    // If any attached stickies move those as well
    const attachedStickies = stickiesQuery.data.stickies.filter(
      (sticky) =>
        sticky.attached_to_id === id && sticky.attached_to_type === 'file',
    );

    if (!attachedStickies) return;

    const stage = stageRef.current;
    if (!stage) return;

    const bulkEditStickies = attachedStickies.map((sticky) => {
      // Find the difference moved to apply to the sticky
      const imageMovedXAmount = moveToX - positionBeforeDrag.current.x;
      const imageMovedYAmount = moveToY - positionBeforeDrag.current.y;

      return {
        ...sticky,
        left_pixel: sticky.left_pixel + imageMovedXAmount,
        top_pixel: sticky.top_pixel + imageMovedYAmount,
      };
    });

    bulkMutateStickies.mutate({
      data: bulkEditStickies,
    });
  };

  const updatePositionState = (e: KonvaEventObject<DragEvent>) => {
    if (!groupRef.current) return;

    onMoveCheckSnap({ e, selectedObjectIds, selectedObjectIdInGroup });
  };

  const updateTransformState = () => {
    // Do not update state in these because each state udpate would count as an undo redo. The transformer will scale the object on its own
    if (!imageRef.current) return;
    setIsDragging(false);
  };

  const onTransform = (e: KonvaEventObject<Event>) => {
    if (!imageRef.current) return;

    onEndCheckSnap(e);
    setIsDragging(false);

    updateImage({
      id,
      x: e.currentTarget.x(),
      y: e.currentTarget.y(),
      rotation: e.currentTarget.rotation(),
      metadata: {
        width: e.currentTarget.width() * e.currentTarget.scaleX(),
        height: e.currentTarget.height() * e.currentTarget.scaleY(),
      },
    });

    e.currentTarget.scaleX(1);
    e.currentTarget.scaleY(1);
  };

  const loadingColour = React.useMemo(
    () => LOADING_COLOURS[getRandomIntInclusive(0, LOADING_COLOURS.length)],
    [],
  );

  if (status === 'loading')
    return (
      <Rect
        x={x}
        y={y}
        height={height}
        width={width}
        fill={loadingColour}
        rotation={rotation}
      />
    );

  const showOnboardingDot =
    user.meta?.onboarding?.demoMode &&
    user.meta?.onboarding?.workCreatively === 'moodboards-that-pop' &&
    mostUpperRightImage &&
    !selectedObjectIds.has(id) &&
    currentTool !== 'move';

  const stageScaleX = stageRef.current?.scaleX() ?? 0;

  const handleDoubleClick = () => {
    if (!isSelected) return;
    startedCroppingImage(id);
  };

  /**
   * The cropData returned is the crop based on the images originalHeight and originalWidth
   * @param cropData
   */
  const handleCropComplete = async (cropData: {
    // Crop data is the coorindates of the crop with respect to the original height and width of the image
    originalCrop: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    // Clip rect are the coordinates in the canvas layer
    newPosition: {
      x: number;
      y: number;
      height: number;
      width: number;
    };
  }) => {
    const newX = cropData.newPosition.x;
    const newY = cropData.newPosition.y;
    const newWidth = cropData.newPosition.width;
    const newHeight = cropData.newPosition.height;

    const roundedCropData = {
      x: Math.max(0, Math.min(originalWidth, cropData.originalCrop.x)),
      y: Math.max(0, Math.min(originalHeight, cropData.originalCrop.y)),
      width: Math.max(0, Math.min(originalWidth, cropData.originalCrop.width)),
      height: Math.max(
        0,
        Math.min(originalHeight, cropData.originalCrop.height),
      ),
    };

    flushSync(() => {
      // The following newPosition is the correct crop rects in the stage space matching the image
      // the x and y, height and width are the original image size properties for the crop
      updateImage({
        id,
        x: newX,
        y: newY,
        metadata: {
          ...imageJSON.metadata,
          width: newWidth,
          height: newHeight,
          crop: roundedCropData,
        },
      });

      endCroppingImage();
    });
  };

  const handleCropCancel = () => {
    endCroppingImage();
  };

  if (isCropping) {
    return (
      <CroppingImage
        stageRef={stageRef}
        image={image}
        rotation={rotation}
        canvasPosition={{
          x,
          y,
          width,
          height,
        }}
        originalWidth={originalWidth}
        originalHeight={originalHeight}
        initialCrop={crop}
        onCropComplete={handleCropComplete}
        onCropCancel={handleCropCancel}
      />
    );
  }

  return (
    <>
      <Group
        ref={groupRef}
        x={x}
        name={IMAGE_GROUP}
        y={y}
        height={height}
        width={width}
        id={id}
        draggable={canDrag}
        onDragEnd={updateDragPosition}
        onDragMove={updatePositionState}
        onDragStart={dragStarted}
        rotation={rotation}
        onClick={onSelectImage}
        onTap={onSelectImage}
        onTransform={updateTransformState}
        onTransformEnd={onTransform}
        onDblClick={handleDoubleClick}
        onTouchStart={() => {
          // Handle double tap for touch devices
          const now = Date.now();
          if (now - lastTapTime.current < 300) {
            handleDoubleClick();
          }
          lastTapTime.current = now;
        }}
      >
        {showOnboardingDot ? (
          <Html
            divProps={{
              style: {
                transform: 'unset',
                zIndex: 'unset',
              },
            }}
          >
            <div
              className={styles.demoIndicator}
              style={{
                position: 'absolute',
                top:
                  (imageRef.current?.getAbsolutePosition().y ?? 0) -
                  12 +
                  (imageRef.current?.getHeight() * stageScaleX) / 2,
                left:
                  (imageRef.current?.getAbsolutePosition().x ?? 0) -
                  12 +
                  (imageRef.current?.getWidth() * stageScaleX) / 2,
              }}
            ></div>
          </Html>
        ) : null}
        <KonvaImage
          visible={isDragging}
          ref={draggingImageRef}
          draggable={false}
          height={height}
          width={width}
          image={image}
          crop={crop !== null ? { ...crop } : undefined}
          name="KonvaImage-Drag"
        />
        {isDragging ? (
          // Transformer for dragging image
          <Transformer
            ref={draggingImageTrRef}
            borderStroke={TERTIARY_40}
            anchorStroke={TERTIARY_40}
            enabledAnchors={[]}
            flipEnabled={false}
            resizeEnabled={false}
            rotateEnabled={false}
            name={TRANSFORMER}
          />
        ) : null}
        <KonvaImage
          x={0}
          y={0}
          crop={crop !== null ? { ...crop } : undefined}
          visible={!isDragging}
          draggable={false}
          flipEnabled={false}
          id={id}
          ref={(ref) => {
            imageRef.current = ref;
            setHasMounted(true);
          }}
          height={height}
          width={width}
          image={image}
          name={IMAGE}
        />
      </Group>
      {(isSelected && !isCropping && !isInMassSelection) ||
      isSelectedInGroup ? (
        // Transformer for rotating and resizing
        <Transformer
          rotationSnaps={[0, 90, 180, 270]}
          ref={trRef}
          borderStroke={TERTIARY_40}
          anchorStroke={TERTIARY_40}
          anchorCornerRadius={100}
          rotateAnchorOffset={58}
          visible={!isDragging}
          name={TRANSFORMER}
          anchorDragBoundFunc={(
            oldPos: { x: number; y: number },
            newPos: { x: number; y: number },
          ) =>
            onMoveAnchorSnap(
              oldPos,
              newPos,
              groupRef,
              selectedObjectIds,
              selectedObjectIdInGroup,
            )
          }
        />
      ) : null}
      {/* Transformer for hovering selection */}
      {(!isSelected && isHovered && !dragSelection && currentTool !== 'move') ||
      isInMassSelection ? (
        <Transformer
          rotateEnabled={false}
          ref={hoveredTrRef}
          keepRatio={false}
          enabledAnchors={[]}
          resizeEnabled={false}
          borderStroke={TERTIARY_40}
          anchorStroke={TERTIARY_40}
          name={TRANSFORMER}
        />
      ) : null}
    </>
  );
};

function getRandomIntInclusive(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); // The maximum is inclusive and the minimum is inclusive
}

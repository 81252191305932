import { isMobileOnly } from 'react-device-detect';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { OptionKey, useAppData } from '@src/AppContext';

import styles from './styles.module.css';

interface WelcomeNameConfirmationScreenProps {
  onConfirm: () => void;
  selectedOption: OptionKey;
}

export const WelcomeNameConfirmationScreen = ({
  onConfirm,
  selectedOption,
}: WelcomeNameConfirmationScreenProps) => {
  const { user } = useAppData();

  const onConfirmClick = () => {
    onConfirm();
  };

  const buttonLabels = {
    createHubs: 'Explore client hubs',
    workCreatively: 'Check out my creative toolkit',
    manageBusinessAdmin: 'Get down to business',
  };

  const messages = {
    createHubs:
      'Spend a few minutes to learn how to use hubs to manage clients and projects seamlessly.',
    workCreatively:
      'Spend a few minutes to see how our moodboarding and color features can give you a creative edge.',
    manageBusinessAdmin:
      'Spend a few minutes to check out how to craft flexible and visual documents that make you stand out.',
  };

  return (
    <div className={styles.container}>
      {isMobileOnly ? (
        <>
          <TypographyPoppins
            type="headline"
            size="L"
            className={styles.headline}
          >
            {user.first_name ? `Hey ${user.first_name},` : 'Hey,'}
          </TypographyPoppins>
          <TypographyPoppins
            type="body"
            size="M"
            className={styles.welcomeBody}
          >
            Looks like you’re on mobile.
            <br />
            <br />
            Feel free to explore the demo content, but get a better experience
            on desktop with tips to guide you through the app.
          </TypographyPoppins>
        </>
      ) : (
        <>
          <TypographyPoppins
            type="headline"
            size="L"
            className={styles.headline}
          >
            {user.first_name ? `Hey ${user.first_name},` : 'Hey,'}
          </TypographyPoppins>
          <TypographyPoppins
            type="body"
            size="M"
            className={styles.welcomeBody}
          >
            You’re now entering demo mode.
            <br />
            <br />
            {messages[selectedOption]}
          </TypographyPoppins>
        </>
      )}
      <Button
        type="filled"
        label={isMobileOnly ? 'Got it' : buttonLabels[selectedOption]}
        onClick={onConfirmClick}
        className={styles.welcomeButton}
      />
    </div>
  );
};

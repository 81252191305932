import { PropsWithChildren, useState } from 'react';

import styles from './styles.module.css';

export const AuthPageContainer = (props: PropsWithChildren) => {
  const { children } = props;

  const [chosenShapes] = useState<string>(
    shapes[~~(Math.random() * shapes.length)],
  );
  const colour = colours[~~(Math.random() * colours.length)];

  return (
    <>
      <div className={styles.container}>{children}</div>
      <div className={styles.shapeContainer}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 600 600"
          fill="none"
          className={styles.svg}
        >
          <path d={chosenShapes} fill={colour} />
        </svg>
      </div>
    </>
  );
};

const shapes = [
  'M600 0L581.266 289.544L432.239 342.168L494.599 357.738V454.756C494.599 534.958 429.585 599.976 349.384 599.981L0 600L9.80298 326.351L159.418 342.168L106.291 273.629V145.225L159.418 55.0804L263.372 0H600Z',
  'M0 5.28299e-07L44.1805 0L194.869 101.729L242.533 142.171L290.196 101.73L491.855 0.0010245L600 0.00102579L548.559 153.065C511.297 263.94 501.093 382.119 518.799 497.741L532.122 584.738L438.403 589.83H377.794L299.999 561.825L222.206 589.828L175.807 600H0L44.1805 493.613L0 5.28299e-07Z',
  'M545.372 599.999H54.6277L0 495.912L54.6277 177.118H122.249C166.807 177.118 208.41 189.77 243.66 211.678L228.8 152.857L255.619 55.8573L366.305 0.0369094H402.109C403.257 0.0123448 404.407 0 405.56 0H545.372L600 452.316L545.372 599.999Z',
  'M128.656 17.1632L553.891 0L600 167.686L454.653 413.182V600L2.87322e-06 544.486L0 411.428C-1.94641e-06 321.29 52.5255 243.412 128.656 206.697L128.656 17.1632Z',
  'M513.161 17.2663V83.0968L486.338 144.611L479.308 153.891L600 149.215V318.35L558.332 422.958L490.276 499.961L195.832 545L94.4431 491.243L36.1102 414.241L0 331.425V172.46L113.443 168.065L98.4366 151.086L67.4863 73.3847V0L513.161 17.2663Z',
  'M223.585 600H257.252C446.547 600 600 446.547 600 257.252L532.344 234.904L505.296 55.5612L172.375 0L0 337.506L57.8341 514.733H180.49L223.585 600Z',
  'M535.118 24.6905V135.139L501.081 218.879L442.428 257.973L537.582 310.14L600 424.029L585.903 566.729L119.46 600L0 587.371L0.00179311 471.883L62.4196 357.995L157.574 305.827L74.3453 248.71L50.7944 16.5093L255.255 0L535.118 24.6905Z',
  'M572.747 620H61.3573L0 550.492L70.8333 205.957L140.174 196.713V106.033L200.963 42.0759L301.94 0L406.246 42.0759L467.689 106.033V196.713L534.804 224.813L600 550.492L572.747 620Z',
] as const;

const colours = ['#016F2F', '#FF9D09', '#8B39A8'] as const;

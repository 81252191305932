import { forwardRef, useState } from 'react';

import * as RadixSlider from '@radix-ui/react-slider';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import styles from './styles.module.css';

type Props = {
  defaultValue?: number[];
  value?: number[];
  maxValue?: number;
  minValue?: number;
  step?: number;
  onChange?: (value: number[]) => void;
  disabled?: boolean;
};

export const Slider = forwardRef<HTMLSpanElement, Props>(
  (
    {
      defaultValue = [10],
      value,
      maxValue = 100,
      minValue = 0,
      step = 1,
      onChange,
      disabled = false,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const sliderValue = value || defaultValue;

    const handleValueChange = (newValue: number[]) => {
      if (onChange) {
        onChange(newValue);
      }
    };

    return (
      <div className={styles.SliderContainer}>
        <RadixTooltip.Provider>
          <RadixSlider.Root
            className={styles.Root}
            defaultValue={defaultValue}
            value={value}
            min={minValue}
            max={maxValue}
            step={step}
            ref={ref}
            onValueChange={handleValueChange}
            disabled={disabled}
          >
            <RadixSlider.Track className={styles.Track}>
              <RadixSlider.Range className={styles.Range} />
            </RadixSlider.Track>

            {sliderValue.map((val, index) => (
              <RadixTooltip.Root key={index} open={isOpen}>
                <RadixTooltip.Trigger asChild>
                  <RadixSlider.Thumb
                    className={styles.Thumb}
                    aria-label="slider"
                    onMouseEnter={() => setIsOpen(true)}
                    onMouseLeave={() => setIsOpen(false)}
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                  />
                </RadixTooltip.Trigger>
                <RadixTooltip.Portal>
                  <div className={styles.wrapper}>
                    <RadixTooltip.Content
                      className={styles.Tooltip}
                      side="top"
                      align="center"
                      sideOffset={8}
                      data-value={val}
                    />
                  </div>
                </RadixTooltip.Portal>
              </RadixTooltip.Root>
            ))}
          </RadixSlider.Root>
        </RadixTooltip.Provider>
      </div>
    );
  },
);

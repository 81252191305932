import { useUnit } from 'effector-react';

import { Dialog, Modal } from '@visualist/design-system/src/components/v2';

import { $moveOutOfHub } from './model/move-out-of-hub';
import { useMoveBoardFromHub } from './model/queries/use-move-from-hub';
import {
  $isShowConfirmationDialog,
  confirmationDialogClosed,
} from './model/show-confirmation-dialog';
import { moveBoardDialogClosed } from './model/show-move-dialog';

export const ConfirmationDialog = () => {
  const isShowConfirmationDialog = useUnit($isShowConfirmationDialog);
  const moveOutOfHub = useUnit($moveOutOfHub);

  const { moveBoardFromHubMutation } = useMoveBoardFromHub();

  const move = () => {
    if (moveOutOfHub) {
      moveBoardFromHubMutation.mutate({
        hub: moveOutOfHub.hubId,
        boards: moveOutOfHub.boardId,
      });
    }
    confirmationDialogClosed();
    moveBoardDialogClosed();
  };

  return (
    <Modal
      handleClose={confirmationDialogClosed}
      showModal={isShowConfirmationDialog}
    >
      <Dialog
        headline="Move out of hub?"
        description={`Any nested boards and files will also be moved out of this hub and into your Library.

Those currently invited to this board will lose access.`}
        actionLabel="Confirm"
        action={move}
        closeAction={confirmationDialogClosed}
      />
    </Modal>
  );
};

import { useState } from 'react';

import cn from 'classnames';
import { Stage } from 'konva/lib/Stage';
import { useHistory } from 'react-router';

import { Icon } from '@visualist/icons';

import { DesignObject } from '@api/designs';
import { SwitchPagesOrInfiniteDialog } from '@src/entities/dialogs/studio/switch-pages-infinity';

import { ContextMenu, ContextMenuGroups } from './context-menu';

import styles from './styles.module.css';

interface DropZoneProps {
  index: number;
  designId: string;
  isActive: boolean;
  allPageObjects: Array<DesignObject>;
  stage: Stage | null;
  switchStateType: (stage: Stage | null) => void;
  onClick: (index: number) => void;
  onContextMenu: (index: number, e: React.MouseEvent) => void;
  handleAddPage: (index: number) => void;
  handlePaste: (index: number) => void;
  handleUpdatePageSize: (index: number) => void;
  handleUpdatePageBackground: (index: number) => void;
  className?: string;
}

export const DropZone: React.FC<DropZoneProps> = ({
  index,
  designId,
  stage,
  isActive,
  allPageObjects,
  switchStateType,
  onClick,
  onContextMenu,
  handleAddPage,
  handlePaste,
  handleUpdatePageSize,
  handleUpdatePageBackground,
  className,
}) => {
  const [isOpenContextMenu, setOpenContextMenu] = useState(false);

  const [
    isOpenSwitchPagesOrInfinityDialog,
    setOpenSwitchPagesOrInfinityDialog,
  ] = useState(false);
  const history = useHistory();

  const switchToInfinte = () => {
    setOpenSwitchPagesOrInfinityDialog(false);
    switchStateType(stage);
    history.push(`/studio/${designId}`);
  };

  const handleContextMenu = (e: React.MouseEvent, index: number) => {
    onContextMenu(index, e);
    e.preventDefault();
    setOpenContextMenu(true);
  };

  const menuItems: ContextMenuGroups = {
    pageOptions: [
      {
        key: 'change-background',
        content: 'Change background',
        onClick: () => handleUpdatePageBackground(index),
        leadingIcon: <Icon name="sprite/color-drop" />,
      },
      {
        key: 'page-size',
        content: 'Page size',
        leadingIcon: <Icon name="sprite/page-landscape" />,
        onClick: () => handleUpdatePageSize(index),
      },
      {
        key: 'switch-to-infinity',
        content: 'Switch to infinity canvas',
        leadingIcon: <Icon name="sprite/infinite-canvas" />,
        onClick: () => {
          if (allPageObjects.length > 0) {
            setOpenSwitchPagesOrInfinityDialog(true);
          } else {
            switchToInfinte();
          }
        },
      },
    ],
    editOptions: [
      {
        key: 'new-page',
        content: 'New page',
        leadingIcon: <Icon name="sprite/plus" />,
        onClick: () => handleAddPage(index),
      },
      {
        key: 'cut',
        content: 'Cut',
        leadingIcon: <Icon name="sprite/cut" />,
        onClick: () => {},
        disabled: true,
      },
      {
        key: 'copy',
        content: 'Copy',
        leadingIcon: <Icon name="sprite/copy-studio" />,
        onClick: () => {},
        disabled: true,
      },
      {
        key: 'paste',
        content: 'Paste',
        leadingIcon: <Icon name="sprite/paste" />,
        onClick: () => handlePaste(index + 1),
      },
      {
        key: 'duplicate',
        content: 'Duplicate',
        leadingIcon: <Icon name="sprite/duplicate" />,
        onClick: () => {},
        disabled: true,
      },
    ],
    dangerOptions: [
      {
        key: 'delete',
        content: 'Delete',
        leadingIcon: <Icon color="var(--color-error)" name="sprite/bin" />,
        onClick: () => {},
        disabled: true,
      },
    ],
  };
  return (
    <>
      <button
        className={cn(styles.dropZone, className)}
        onClick={() => onClick(index)}
        onContextMenu={(e) => {
          handleContextMenu(e, index);
        }}
      >
        {isActive && (
          <Icon
            style={{ height: '100%' }}
            name="sprite/drop-indicator"
            className={styles.dropIndicator}
          />
        )}
        <div className={styles.contextMenuContainerDropZone}>
          <ContextMenu
            isOpen={isOpenContextMenu}
            onOpenChange={setOpenContextMenu}
            menuItems={menuItems}
            side="top"
            sideOffset={58}
            align="start"
          />
        </div>
      </button>
      <SwitchPagesOrInfiniteDialog
        headline="Switch to infinity canvas?"
        description="Your pages will be removed, and all objects will be placed on an infinity canvas."
        isOpenSwitchPagesOrInfinityDialog={isOpenSwitchPagesOrInfinityDialog}
        setOpenSwitchPagesOrInfinityDialog={setOpenSwitchPagesOrInfinityDialog}
        switchPagesOrInfinity={switchToInfinte}
      />
    </>
  );
};

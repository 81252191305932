import React from 'react';

import { useUnit } from 'effector-react';
import {
  animate,
  AnimationPlaybackControls,
  motion,
  useMotionValue,
  useTransform,
} from 'framer-motion';

import {
  VaiColored,
  VaiStyleGradientIcon,
} from '@visualist/design-system/src/components/v2';

import { doNotAskAgainTaggingModal } from '@api/vai';
import { VaiButton, VaiButtonText } from '@src/entities/vai/ui/button';
import {
  Card,
  CardButtons,
  CardContent,
  CardHeader,
  CardHeaderText,
  CardText,
} from '@src/entities/vai/ui/card';
import { VaiPopup } from '@src/entities/vai/ui/popup';
import { useLocalStorage } from '@src/shared/hooks/useLocalStorage';
import { useMutation } from '@tanstack/react-query';

import { openedVaiModalTab } from '../../../../widgets/vai-main/model';
import { $showVaiTagPopup, closedVaiTagPopup } from './model';

import styles from './styles.module.css';

export const VaiTagPopup = () => {
  const [showVaiTagPopup] = useUnit([$showVaiTagPopup]);
  const [, setDoNotShowVaiTagPopup] = useLocalStorage(
    'do_not_show_vai_tag_popup',
    false,
  );

  const text = `What’s “elegant” and what’s “minimalist”?\nHelp Vai to learn your style.`;
  const textLength = useMotionValue(0);

  React.useEffect(() => {
    let controls: AnimationPlaybackControls;
    if (showVaiTagPopup) {
      const timeout = setTimeout(() => {
        controls = animate(textLength, text.length, {
          duration: 4,
        });
      }, 500);
      return () => {
        controls.stop();
        clearTimeout(timeout);
      };
    }
  }, [showVaiTagPopup]);

  const roundedTextLength = useTransform(textLength, (latest) =>
    Math.round(latest),
  );
  const displayedText = useTransform(roundedTextLength, (latest) =>
    text.slice(0, latest),
  );

  const { mutateAsync: doNotAskAgainMutation } = useMutation({
    mutationFn: doNotAskAgainTaggingModal,
    onSuccess: () => closedVaiTagPopup(),
  });

  return (
    <VaiPopup showPopup={showVaiTagPopup} top={76} right={20}>
      <Card>
        <CardHeader onClose={() => closedVaiTagPopup()}>
          <VaiColored />
          <CardHeaderText>Teach Vai your style</CardHeaderText>
        </CardHeader>
        <CardContent>
          <CardText>
            <motion.span className={styles.text}>
              {displayedText.get()}
            </motion.span>
          </CardText>
        </CardContent>
        <CardButtons
          onClick={async () => {
            setDoNotShowVaiTagPopup(true);
            await doNotAskAgainMutation('tagging_prompt');
          }}
        >
          <VaiButton variant="secondary">
            <VaiButtonText>Don't ask me again</VaiButtonText>
          </VaiButton>
          <VaiButton onClick={() => openedVaiModalTab('aesthetiq')}>
            <VaiStyleGradientIcon />
            <VaiButtonText>Go to Vai</VaiButtonText>
          </VaiButton>
        </CardButtons>
      </Card>
    </VaiPopup>
  );
};

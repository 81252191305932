import { getInvitees } from '@api/boards';
import { Permissions, Role } from '@api/users';
import { useAppData } from '@src/AppContext';
import { MEMBERS_INVITATION_QUERY } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

type Props = {
  id: string | null;
};

export const useInvitees = ({ id }: Props) => {
  const { user } = useAppData();

  const inviteesQuery = useQuery({
    queryKey: [MEMBERS_INVITATION_QUERY, id],
    queryFn: () => {
      if (id) {
        return getInvitees(id);
      }
    },
    enabled: Boolean(id),
    refetchInterval: 180000,
  });

  const currentPermissions = inviteesQuery.data?.members.filter(
    (u) => u.user && u.user.id === user.id,
  )[0]?.permissions;

  const roles: Record<Role, Permissions[]> = {
    Editor: ['invite', 'edit'],
    Commenter: ['invite', 'comment'],
    Viewer: ['invite'],
  };

  const getRole = (permissions?: Permissions[]): Role | null => {
    if (!permissions) return null;

    const sortedPermissions = [...permissions].sort();

    for (const [role, perms] of Object.entries(roles)) {
      const userPermissions = [...perms].sort();

      if (
        sortedPermissions.length === userPermissions.length &&
        sortedPermissions.every(
          (perm, index) => perm === userPermissions[index],
        )
      ) {
        return role as Role;
      }
    }

    return null;
  };

  const isEditor = () => {
    if (!currentPermissions) return true;

    const editor = new Set<Permissions>(['edit', 'invite']);

    return (
      currentPermissions?.length === 2 &&
      currentPermissions.every((p) => editor.has(p))
    );
  };

  return {
    inviteesQuery,
    currentPermissions,
    role: getRole(currentPermissions),
    isEditor,
  };
};

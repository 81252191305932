import { type PropsWithChildren } from 'react';

import { useFeatureFlagEnabled } from 'posthog-js/react';

import { GlobalError } from '@src/entities/global-error';

import styles from './styles.module.css';

export function MaintenanceMode({ children }: PropsWithChildren) {
  const flagEnabled = useFeatureFlagEnabled('maintenance-mode');

  if (flagEnabled) {
    return (
      <div className={styles.container}>
        <GlobalError
          text={`
						Visualist is taking a quick breather for
						maintenance.

						We’ll be back soon, refreshed and
						ready to dazzle. Check back again
						later.
					`}
          icon={AnimatedGear}
        />
      </div>
    );
  }

  return <>{children}</>;
}

const AnimatedGear = (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.3774 25.315C53.382 22.7086 64.3527 24.1567 68.5389 34.301"
      stroke="#43424A"
      strokeWidth="0.666667"
      className={styles.animateUp}
      data-delay="true"
    />
    <path
      d="M55.1186 19.299C60.8094 18.6838 65.8539 20.6409 68.5394 25.7012"
      stroke="#43424A"
      strokeWidth="0.666667"
      className={styles.animateUp}
    />
    <path
      d="M25.599 46.2508C19.9003 46.7892 14.8827 44.7643 12.2657 39.6684"
      stroke="#43424A"
      strokeWidth="0.666667"
      className={styles.animateDown}
      data-delay="true"
    />
    <path
      d="M20.4419 51.4936C19.037 51.6532 13.1161 49.841 12.2638 47.5463"
      stroke="#43424A"
      strokeWidth="0.666667"
      className={styles.animateDown}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.3907 67.9041L44.9084 69.9227L47.7671 63.336L52.6109 63.336L55.2256 69.1857L60.5123 66.975L57.7568 60.9834L60.5123 58.0677L67.1852 60.7079L69.8446 54.1146L63.3055 51.6074L63.2943 47.4362L69.5478 45.1232L66.9204 38.2579L60.4482 41.022L57.4903 38.2579L60.0958 32.4671L55.7382 30.5126L53.2626 36.2751L47.5678 36.0877L44.8444 30.032L40.0382 32.1467L42.2491 37.7859L38.4682 41.2463L33.7679 39.2726L31.504 45.3096L35.6868 46.9914L35.6868 53.2656L30.232 55.7668L32.4314 60.9846L37.8217 58.0092L42.6976 61.8164L40.3907 67.9041ZM54.3284 50.0895C54.3171 45.8758 51.1968 44.8298 49.3301 44.8348C46.4156 44.8427 44.1951 47.1416 44.2035 50.2818C44.2123 53.5362 47.5363 54.901 49.5223 54.8956C51.7954 54.8895 54.3363 52.9939 54.3284 50.0895Z"
      fill="#DCD5CB"
      className={styles.spinningGear}
    />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.6878 67.2468L45.0951 69.9636L47.4508 63.6435L51.4833 63.6167L54.032 69.9042L60.553 67.1014L57.8956 60.8567L60.4925 58.0132L67.0507 60.7958L69.732 54.2562L63.0671 51.8004L63.0396 47.6701L69.6713 45.1257L66.9018 38.405L60.3798 41.0572L57.7468 38.4659L60.3227 32.4705L53.7661 29.9051L51.2991 35.8998L47.2665 35.9266L44.8292 29.9645L38.4576 32.6158L40.9625 38.5775L38.5146 41.2026L32.2434 38.6354L29.6722 45.3916L35.3565 47.8541L35.3839 51.9844L29.7329 54.5221L32.3923 61.0263L37.714 58.1646L41.1114 60.9683L38.6878 67.2468ZM54.323 50.3041C54.2952 46.1316 51.2277 44.8219 49.3278 44.8346C46.3616 44.8543 44.2225 47.2617 44.2432 50.3711C44.2646 53.5936 47.374 55.0057 49.3953 54.9923C51.7088 54.9769 54.3421 53.1799 54.323 50.3041Z"
      fill="#DCD5CB"
      className={styles.spinningGear}
    />
    <path
      d="M45.0951 69.9636L38.6878 67.2468L41.1114 60.9683L37.714 58.1646L32.3923 61.0263L29.7329 54.5221L35.3839 51.9844L35.3565 47.8541L29.6722 45.3916L32.2434 38.6354L38.5146 41.2026L40.9625 38.5775L38.4576 32.6158L44.8292 29.9645L47.2665 35.9266L51.2991 35.8998L53.7661 29.9051L60.3227 32.4705L57.7468 38.4659L60.3798 41.0572L66.9018 38.405L69.6713 45.1257L63.0396 47.6701L63.0671 51.8004L69.732 54.2562L67.0507 60.7958L60.4925 58.0132L57.8956 60.8567L60.553 67.1014L54.032 69.9042L51.4833 63.6167L47.4508 63.6435L45.0951 69.9636Z"
      stroke="#43424A"
      strokeWidth="0.666667"
      className={styles.spinningGear}
    />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.6417 40.4918L27.8592 40.6369L27.7761 35.579L30.585 34.4576L34.0843 38.1516L37.8719 34.411L34.3084 30.7765L35.3444 28.0805L40.6853 28.2305L40.7696 22.9301L35.4434 23.0377L34.2948 20.1607L38.2304 16.5703L34.4584 12.6341L30.6289 16.2699L28.0814 15.1802L28.2409 10.2887L22.9604 10.2901L22.8768 15.1513L20.0679 16.2727L16.7354 12.7755L13.0107 16.3695L16.3903 19.8479L15.3987 22.3506L10.317 22.2727L10.3689 27.6942L15.0121 27.8595L16.1607 30.7365L12.9081 34.054L16.544 37.8691L19.478 34.4153L22.6173 35.4443L22.6417 40.4918ZM28.9276 24.3839C27.7673 21.4776 25.2668 21.4018 23.9435 21.9301C21.8774 22.755 21.0418 25.0212 21.9065 27.1871C22.8027 29.4317 25.3604 29.5676 26.7684 29.0055C28.3798 28.3621 29.7274 26.3871 28.9276 24.3839Z"
      fill="#DCD5CB"
      className={styles.spinningGear}
      data-reverse="true"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1966 40.579L27.9314 40.5896L27.6971 35.7932L31.6526 34.4451L35.0497 37.8055L38.0769 34.6847L34.3899 30.9352L35.3927 28.3562L40.9472 28.3719L40.8767 23.0812L35.8774 23.0384L34.7174 20.1329L38.3124 16.6585L34.6587 12.6214L30.5544 16.3366L28.3588 15.2469L28.3445 10.1777L25.0131 10.1683L25.0266 14.9669L20.2453 16.3074L16.5429 12.8917L14.6254 14.9374L17.6351 18.2351L15.5692 22.2536L12.4458 22.4382L12.4586 26.9808L16.5826 26.9925L17.9842 30.1824L14.6828 33.8869L17.6881 37.0654L20.2177 33.9028L24.1811 35.0659L24.1966 40.579ZM28.7997 24.4328C27.6278 21.4977 24.947 21.5658 23.719 22.0561C21.8018 22.8216 21.0146 24.8203 21.8879 27.0075C22.793 29.2744 25.4099 29.7152 26.7164 29.1936C28.2118 28.5966 29.6074 26.4557 28.7997 24.4328Z"
      fill="#DCD5CB"
      className={styles.spinningGear}
      data-reverse="true"
    />
    <path
      d="M27.8592 40.6369L22.6417 40.4918L22.6173 35.4443L19.478 34.4153L16.544 37.8691L12.9081 34.054L16.1607 30.7365L15.0121 27.8595L10.3689 27.6942L10.317 22.2727L15.3987 22.3506L16.3903 19.8479L13.0107 16.3695L16.7354 12.7755L20.0679 16.2727L22.8768 15.1513L22.9604 10.2901L28.2409 10.2887L28.0814 15.1802L30.6289 16.2699L34.4584 12.6341L38.2304 16.5703L34.2948 20.1607L35.4434 23.0377L40.7696 22.9301L40.6853 28.2305L35.3444 28.0805L34.3084 30.7765L37.8719 34.411L34.0843 38.1516L30.585 34.4576L27.7761 35.579L27.8592 40.6369Z"
      stroke="#43424A"
      strokeWidth="0.666667"
      className={styles.spinningGear}
      data-reverse="true"
    />
  </svg>
);

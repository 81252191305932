import { MouseEvent, MouseEventHandler, ReactNode } from 'react';

import cn from 'classnames';

type Density = '0' | '-2';

import {
  Size,
  TypographyPoppins,
  Variant,
} from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

export type MenuItemProps<T extends Variant> = {
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  density?: Density;
  supportingText?: string;
  isDisabled?: boolean;
  children: ReactNode;
  className?: string;
  onClickContent?: MouseEventHandler<HTMLLIElement>;
  onClickLeadingIcon?: MouseEventHandler<HTMLSpanElement>;
  isBlur?: boolean;
  onMouseEnter?: MouseEventHandler<HTMLLIElement>;
  classNameLeadingIcon?: string;
  classNameContent?: string;
  classNameLabel?: string;
  classNameTrailingIcon?: string;
  type?: T;
  size?: Size[T];
};

export const MenuItem = <T extends Variant>({
  leadingIcon,
  trailingIcon,
  density,
  supportingText,
  isDisabled,
  children,
  className,
  onClickContent,
  onClickLeadingIcon,
  isBlur,
  onMouseEnter,
  classNameLeadingIcon,
  classNameContent,
  classNameLabel,
  classNameTrailingIcon,
  type,
  size,
}: MenuItemProps<T>) => {
  const handleOnClickContent = (e: MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    if (onClickContent && !isDisabled) onClickContent(e);
  };

  const handleOnClickLeadingIcon = (e: MouseEvent<HTMLSpanElement>) => {
    e.stopPropagation();
    if (onClickLeadingIcon && !isDisabled) onClickLeadingIcon(e);
  };

  return (
    <li
      className={cn(styles.item, className, {
        [styles.disabled]: isDisabled,
      })}
      onClick={handleOnClickContent}
      onMouseEnter={onMouseEnter}
    >
      {leadingIcon && (
        <span
          className={cn(styles.leadingIcon, classNameLeadingIcon, {
            [styles.density]: density === '-2',
          })}
          onClick={handleOnClickLeadingIcon}
        >
          {leadingIcon}
        </span>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '14px',
          width: '100%',
        }}
        className={cn({
          [styles.leftColumn]: isBlur,
        })}
      >
        <div
          className={cn(styles.content, classNameContent, {
            [styles.withSupportingText]: supportingText,
          })}
        >
          <TypographyPoppins
            className={cn(styles.text, classNameLabel)}
            type={type ? type : 'body'}
            //@ts-ignore
            size={size ? size : 'L'}
          >
            {children}
          </TypographyPoppins>

          {supportingText && (
            <TypographyPoppins
              className={styles.supportingText}
              type="body"
              size="M"
            >
              {supportingText}
            </TypographyPoppins>
          )}
        </div>
      </div>
      {isBlur && <div className={styles.blur} />}
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        className={cn({
          [styles.rightColumn]: isBlur,
        })}
      >
        {trailingIcon && (
          <span
            className={cn(styles.trailingIcon, classNameTrailingIcon, {
              [styles.density]: density === '-2',
            })}
          >
            {trailingIcon}
          </span>
        )}
      </div>
    </li>
  );
};

import Icon, { IconProps } from './Icon';

const MagicEraserIcon = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 24 24">
      <defs>
        <linearGradient
          id="magic-eraser-gradient-1"
          x1="14.464"
          x2="15.57"
          y1="3.186"
          y2="20.428"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#630682" />
          <stop offset=".5" stopColor="#8B39A8" />
          <stop offset="1" stopColor="#FAD7FF" />
        </linearGradient>
        <linearGradient
          id="magic-eraser-gradient-2"
          x1="19.75"
          x2="10.778"
          y1="9"
          y2="13.304"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#630682" />
          <stop offset=".5" stopColor="#8B39A8" />
          <stop offset="1" stopColor="#FAD7FF" />
        </linearGradient>
      </defs>
      <mask
        id="magic-eraser-mask"
        width="18"
        height="18"
        x="3"
        y="3"
        maskUnits="userSpaceOnUse"
        style={{ maskType: 'alpha' }}
      >
        <path
          fill="#D9D9D9"
          d="m16 16-2 5H3V3h18v10h-3l-1.5-1-1-4.5-4 6.5H11l2.072 2.486L15 14.5l1 1.5Z"
        />
      </mask>
      <g mask="url(#magic-eraser-mask)">
        <path
          stroke="url(#magic-eraser-gradient-1)"
          strokeWidth="1.5"
          fill="none"
          d="m6.686 10.964 7.07-7.07a1 1 0 0 1 1.415 0l4.95 4.95a1 1 0 0 1 0 1.413l-7.071 7.071m-6.364-6.364-2.829 2.829a1 1 0 0 0 0 1.414l4.95 4.95a1 1 0 0 0 1.414 0l2.829-2.829m-6.364-6.364 6.364 6.364"
        />
      </g>
      <path
        stroke="url(#magic-eraser-gradient-2)"
        strokeLinecap="square"
        strokeWidth="1.2"
        fill="none"
        d="m15.25 13.09-2.25.82 2.25.817L16.375 18l1.125-3.273 2.25-.818-2.25-.818L16.375 9l-1.125 4.09Z"
      />
    </Icon>
  );
};

export default MagicEraserIcon;

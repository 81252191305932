import { combine, createEvent, createStore, sample } from 'effector';

import { Board } from '@api/boards';

import { Location } from '../../../../search-modal/location/model';

export const locationSelected = createEvent<Board>();
export const locationChanged = createEvent<Location>();
export const originalLocationCleared = createEvent();
export const currentLocationCleared = createEvent();
export const initialLocationIdCleared = createEvent();

export const $originalLocation = createStore<Board | null>(null);
export const $originalLocationId = createStore<string | null>(null);
export const $originalLocationLvl = createStore<number | null>(null);
export const $originalHubId = createStore<string | null>(null);
export const $originalParentId = createStore<string | null>(null);
export const $destinationLocation = createStore<Location | null>(null);
export const $selectedLocation = createStore<Location | null>(null);

sample({
  clock: locationSelected,
  target: $originalLocation,
});

sample({
  clock: locationSelected,
  fn: (location) => location.id,
  target: $originalLocationId,
});

sample({
  clock: locationSelected,
  fn: (location) => location.level,
  target: $originalLocationLvl,
});

sample({
  clock: locationSelected,
  fn: (location) => {
    if (location.hub) {
      return location.hub.id;
    } else return null;
  },
  target: $originalHubId,
});

sample({
  clock: locationSelected,
  fn: (location) => {
    if (location.parent) {
      return location.parent.id;
    } else return null;
  },
  target: $originalParentId,
});

sample({
  clock: locationChanged,
  target: [$destinationLocation, originalLocationCleared],
});

sample({
  source: {
    originalLocation: $originalLocation,
    destinationLocation: $destinationLocation,
  },
  fn: ({ originalLocation, destinationLocation }): Location | null => {
    if (!originalLocation && !destinationLocation) return null;

    const selectedLocation = {
      id: originalLocation?.parent?.id ?? destinationLocation?.id,
      name: originalLocation?.parent?.name ?? destinationLocation?.name,
      type:
        originalLocation?.parent &&
        'type' in originalLocation.parent &&
        originalLocation.parent.type === 'hub'
          ? 'hub'
          : destinationLocation?.type ?? 'board',
      //@ts-ignore
      level: originalLocation?.parent?.level ?? destinationLocation?.level,
      parent: originalLocation?.parent?.parent ?? destinationLocation?.parent,
      hub: originalLocation?.hub ?? destinationLocation?.hub,
    };

    return selectedLocation.id ? selectedLocation : null;
  },
  target: $selectedLocation,
});

sample({
  clock: originalLocationCleared,
  fn: () => null,
  target: $originalLocation,
});

sample({
  clock: initialLocationIdCleared,
  fn: () => null,
  target: [
    $originalLocationId,
    $originalLocationLvl,
    $originalHubId,
    $originalParentId,
  ],
});

sample({
  clock: currentLocationCleared,
  fn: () => null,
  target: [$destinationLocation, $selectedLocation],
});

export const $shouldCloseMoveDialog = combine(
  $destinationLocation,
  $originalLocationId,
  $originalHubId,
  $originalLocationLvl,
  $originalParentId,
  $selectedLocation,
  (
    destinationLocation,
    originalLocationId,
    originalHubId,
    originalLocationLvl,
    originalParentId,
    selectedLocation,
  ) => {
    const conditionA =
      destinationLocation && destinationLocation.id === originalLocationId;
    const conditionB =
      destinationLocation &&
      destinationLocation.id === originalHubId &&
      originalLocationLvl === 0;
    const conditionC = originalParentId === selectedLocation?.id;
    const conditionD =
      originalLocationId &&
      originalLocationLvl === 0 &&
      !destinationLocation?.id;

    return Boolean(conditionA || conditionB || conditionC || conditionD);
  },
);

import { isMobile } from 'react-device-detect';

import {
  IconButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { copyFile } from '@src/shared/utils';

import styles from './styles.module.css';

export const Copy = ({ block, getFileType }: any) => {
  const handleCopy = () => {
    if (block.file.full_size) {
      copyFile(block.file.full_size);

      startedSnack({
        label: `Copied ${getFileType()}`,
        close: true,
      });
    } else {
      startedSnack({
        label: `Couldn't copy ${getFileType()}`,
        action: {
          label: 'Try again',
          action: () => {
            handleCopy();
          },
        },
        close: true,
      });
    }
  };

  return (
    <div className={styles.copy}>
      <TooltipRadix
        description="Copy to clipboard"
        side={isMobile ? 'bottom' : 'right'}
      >
        <IconButton
          type="unfilled"
          className={styles.iconButton}
          icon={<Icon name="sprite/copy" className={styles.icon} />}
          onClick={handleCopy}
          isDisabled={block?.is_archived}
        />
      </TooltipRadix>
    </div>
  );
};

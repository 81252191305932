import { useEffect, useRef, useState } from 'react';

import {
  FilterChip,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useOnClickOutside } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { Permissions as PermissionsType } from '@api/users';

import { Permissions } from '../permissions';

import styles from './styles.module.css';

type GuestSeatProps = {
  permissions: PermissionsType[];
  permissionsSelected: (permissions: PermissionsType[]) => void;
  currentPermissions?: PermissionsType[];
  type: 'doc' | 'board' | 'hub';
};

export const GuestSeat = ({
  type,
  permissions,
  permissionsSelected,
  currentPermissions,
}: GuestSeatProps) => {
  const [isShowMenu, setShowMenu] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentPermissions) {
      permissionsSelected(currentPermissions);
    }
  }, [currentPermissions]);

  useOnClickOutside(ref, () => {
    if (isShowMenu) {
      setShowMenu(false);
    }
  });

  const getLabel = (permissions: PermissionsType[]) => {
    if (permissions.includes('edit')) {
      return 'Editor';
    } else if (permissions.includes('comment')) {
      return 'Commenter';
    } else {
      return 'Viewer';
    }
  };

  return (
    <div className={styles.guestSeat}>
      <TypographyPoppins className={styles.description} type="body" size="S">
        Send invitation to join as
      </TypographyPoppins>
      <div ref={ref} className={styles.permissions}>
        <Popover open={isShowMenu}>
          <PopoverTrigger className={styles.popover}>
            <FilterChip
              className={styles.permission}
              leadingIcon={
                isShowMenu && (
                  <Icon
                    name="sprite/tick"
                    size={16}
                    color="var(--color-neutral-variant-30)"
                  />
                )
              }
              trailingIcon={<Icon name="sprite/caret-down" size={16} />}
              type="label"
              size="S"
              isSelected={isShowMenu}
              onClick={() => setShowMenu(!isShowMenu)}
            >
              {getLabel(permissions)}
            </FilterChip>
          </PopoverTrigger>
          <PopoverContent side="top" align="end">
            <Permissions
              permissions={permissions}
              permissionsSelected={permissionsSelected}
              closeMenu={() => setShowMenu(false)}
              currentPermissions={currentPermissions}
              type={type}
            />
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

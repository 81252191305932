import { getInviteesHub } from '@api/hubs';
import { Permissions } from '@api/users';
import { useAppData } from '@src/AppContext';
import { MEMBERS_INVITATION_HUB_QUERY } from '@src/shared/constants';
import { useQuery } from '@tanstack/react-query';

type Props = {
  id: string | null;
};

export const useInvitees = ({ id }: Props) => {
  const { user } = useAppData();

  const inviteesQuery = useQuery({
    queryKey: [MEMBERS_INVITATION_HUB_QUERY, id],
    queryFn: () => getInviteesHub(id),
    enabled: Boolean(id),
    refetchInterval: 180000,
  });

  const currentPermissions = inviteesQuery.data?.members.filter(
    (u) => u.user && u.user.id === user.id,
  )[0].permissions;

  const isEditor = () => {
    const editor = new Set<Permissions>(['edit', 'invite']);

    return (
      currentPermissions?.length === 2 &&
      currentPermissions.every((p) => editor.has(p))
    );
  };

  return {
    inviteesQuery,
    currentPermissions,
    isEditor,
  };
};

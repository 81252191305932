import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { Plan } from '@pages/AccountPage/components/types';
import { binDeletionDialogOpened } from '@src/entities/delete-modals/model';
import { useBilling } from '@src/shared/queries/useBilling';

import styles from '../styles.module.css';

type Props = {
  isDisabled?: boolean;
};

export const Header = ({ isDisabled }: Props) => {
  const {
    query: { data },
  } = useBilling();

  return (
    <div className={styles.header}>
      <TypographyPoppins type="headline" size="L">
        Bin
      </TypographyPoppins>
      <div className={styles.bodyText}>
        <TypographyPoppins
          type="body"
          size="M"
        >{`Items are permanently deleted after ${getPeriod(
          data?.membershipTierInfo.plan,
        )}`}</TypographyPoppins>
        <Button
          type="filled"
          label="Empty bin"
          onClick={() => binDeletionDialogOpened()}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

const getPeriod = (plan?: Plan) => {
  if (plan === 'free') return '7 days';
  if (plan === 'starter') return '30 days';
  if (plan === 'pro') return '6 months';
};

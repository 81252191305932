import { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useHistory, useLocation } from 'react-router';

import {
  Button,
  TextField,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { capitalise } from '@pages/AccountPage/utils';

import { GoogleButton } from '../buttons';
import { useCheckRegistrationCode } from '../model/queries/useCheckRegistrationCode';
import { LoginFormValues, SignupFormWithPasswordValues } from '../types';
import {
  resolveEmailErrorMessages,
  resolvePasswordErrorMessages,
  validateEmail,
  validateSignupPasswordLogin,
} from '../utils';

import styles from './styles.module.css';

export const SignupForm = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [emailSuccess, setEmailSuccess] = useState('');
  const urlSearchParams = new URLSearchParams(search);
  const email = urlSearchParams.get('email') || '';
  const plan = urlSearchParams.get('plan');
  const promo = urlSearchParams.get('promo');

  const formik = useFormik({
    initialValues: { email },
    validate: (v) =>
      validateEmail(v, {} as Record<keyof LoginFormValues, string>),
    onSubmit: (values) => {
      setEmailSuccess(values.email);
    },
  });

  return (
    <AnimatePresence mode="popLayout">
      {emailSuccess ? (
        <motion.div
          key={1}
          initial={{ transform: 'translateX(120%)' }}
          animate={{ transform: 'translateX(0%)' }}
          transition={{
            duration: 0.3,
            bounce: 0.2,
            type: 'spring',
          }}
        >
          <EmailSignupForm email={emailSuccess} plan={plan} promo={promo} />
        </motion.div>
      ) : (
        <motion.div
          key={2}
          exit={{
            transform: 'translateX(-120%)',
          }}
          transition={{
            duration: 0.3,
            bounce: 0.2,
            type: 'spring',
          }}
          className={styles.container}
        >
          <TypographyPoppins type="title" size="L" className={styles.title}>
            <motion.div
              transition={{
                duration: 0.3,
                bounce: 0.2,
                type: 'spring',
              }}
              layoutId="signup-title"
            >
              Sign up for Visualist
            </motion.div>
          </TypographyPoppins>
          <div className={styles.mainForm}>
            <div className={styles.buttons}>
              <GoogleButton>
                <GoogleButton.Login label="Sign up with Google" />
              </GoogleButton>
              {/* <AppleButton /> */}
            </div>
            <TypographyPoppins type="body" size="L" className={styles.or}>
              or
            </TypographyPoppins>
            <form
              noValidate
              onSubmit={(e) => {
                formik.handleSubmit(e);
              }}
              className={styles.form}
            >
              <div className={styles.formInput}>
                <TypographyPoppins
                  type="label"
                  size="M"
                  className={styles.label}
                >
                  <label htmlFor="email">Email</label>
                </TypographyPoppins>
                <TextField
                  autoFocus
                  formNoValidate
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Use your work email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  errorAndSupportingText={formik.errors.email}
                  clear={(e) => {
                    e.preventDefault();
                    formik.resetForm();
                  }}
                />
              </div>
              <div className={styles.formButtons}>
                <Button
                  type="filled"
                  label="Sign up with email"
                  isDisabled={formik.isSubmitting}
                  // Empty onclick as it's handled by formik
                  onClick={() => {}}
                />
                {plan ? (
                  <TypographyPoppins
                    type="body"
                    size="S"
                    className={styles.planSelection}
                  >
                    You have selected the{' '}
                    <span className={styles.planLabel}>
                      {cleanupPlanName(plan) === 'Hobby'
                        ? 'Starter'
                        : cleanupPlanName(plan)}
                    </span>{' '}
                    plan
                  </TypographyPoppins>
                ) : null}
              </div>
            </form>
          </div>
          <div>
            <div className={styles.footer}>
              <TypographyPoppins type="body" size="M">
                Already have an account?
              </TypographyPoppins>
              <Button
                type="ghost"
                label="Log in"
                onClick={() => {
                  history.push('/login');
                }}
              />
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const cleanupPlanName = (s: string) => {
  return capitalise(s.split('_')[0]);
};

const EmailSignupForm = ({
  email,
  plan,
  promo = '',
}: {
  email: string;
  plan: string | null;
  promo: string | null;
}) => {
  const [showPromoInput, setShowPromoInput] = useState(promo !== null);
  const [errorCount, setErrorCount] = useState(0);
  const password1Ref = useRef<HTMLInputElement | null>(null);

  let redirectPath = `/home?first=true`;

  const formik = useFormik<SignupFormWithPasswordValues>({
    initialValues:
      plan && plan !== null
        ? {
            email,
            password: '',
            confirmPassword: '',
            promo_code: promo ?? '',
            lookup_key: plan,
            hasPlan: true,
          }
        : {
            email,
            password: '',
            confirmPassword: '',
            promo_code: promo ?? '',
            hasPlan: false,
          },
    validateOnChange: false,
    validate: (v) =>
      validateSignupPasswordLogin(
        v,
        {} as Record<keyof SignupFormWithPasswordValues, string>,
      ),
    onSubmit: (values) => {
      if (values.promo_code) {
        redirectPath = `${redirectPath}&usedPromo=true`;
      }
      registerUser({
        email: values.email,
        password1: values.password,
        password2: values.confirmPassword,
        first_name: '',
        last_name: '',
        signup_source: '',
        // Backend expects lookup_key not plan
        promo_code: values.promo_code,
        lookup_key: values.hasPlan ? values.lookup_key : undefined,
        redirect_url: `${window.location.origin}${redirectPath}`,
      });
    },
  });

  const { registerUser } = useCheckRegistrationCode({
    onError: (error) => {
      if (errorCount > 4) {
        startedSnack({
          label: 'Too many login attempts, Please try again later',
          close: true,
        });
      }

      if (error.response?.data?.password1?.length > 0) {
        setErrorCount((e) => e + 1);
      }

      if (axios.isAxiosError(error)) {
        if (
          error.response?.data?.password1 &&
          error.response?.data?.password1.length !== 0
        ) {
          formik.errors.password = resolvePasswordErrorMessages(
            error.response.data.password1[0],
          );
        }

        if (
          error.response?.data?.non_field_errors &&
          error.response?.data?.non_field_errors.length !== 0
        ) {
          formik.errors.password = resolvePasswordErrorMessages(
            error.response.data.non_field_errors[0],
          );
        }

        if (
          error.response?.data?.email &&
          error.response?.data?.email.length !== 0
        ) {
          formik.errors.email = resolveEmailErrorMessages(
            error.response.data.email[0],
          );
        }

        if (
          error.response?.data.detail ===
          'No PromotionsModel matches the given query.'
        ) {
          formik.errors.promo_code = 'That didn’t work. Please check your code';
          return;
        }
      }
    },
  });

  useEffect(() => {
    // Focus password element after animation is complete otherwise is janks
    const timeout = setTimeout(() => {
      password1Ref.current?.focus();
    }, 300);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={styles.confirmContainer}>
      <TypographyPoppins type="title" size="L" className={styles.title}>
        <motion.div
          transition={{
            duration: 0.3,
            bounce: 0.2,
            type: 'spring',
          }}
          layoutId="signup-title"
        >
          Sign up for Visualist
        </motion.div>
      </TypographyPoppins>
      <form
        noValidate
        onSubmit={(e) => {
          formik.handleSubmit(e);
        }}
        className={styles.form}
      >
        <div className={styles.formInput}>
          <TypographyPoppins type="label" size="M" className={styles.label}>
            <label htmlFor="email">Email</label>
          </TypographyPoppins>
          <TextField
            formNoValidate
            id="email2"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            errorAndSupportingText={formik.errors.email}
            clear={(e) => {
              e.preventDefault();
              formik.setFieldValue('email', '');
            }}
          />
        </div>
        <div className={styles.formInput}>
          <TypographyPoppins type="label" size="M" className={styles.label}>
            <label htmlFor="password">Password</label>
          </TypographyPoppins>
          <TextField
            ref={password1Ref}
            formNoValidate
            id="password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={(e) => {
              formik.setFieldValue('password', e.target.value).then(() => {
                formik.validateForm();
              });
            }}
            errorAndSupportingText={formik.errors.password}
            supportingText={
              !formik.errors.password
                ? 'At least 12 characters, including 1 letter and 1 symbol or number'
                : undefined
            }
            clear={(e) => {
              e.preventDefault();
            }}
          />
        </div>
        <div className={styles.formInput}>
          <TypographyPoppins type="label" size="M" className={styles.label}>
            <label htmlFor="password">Confirm password</label>
          </TypographyPoppins>
          <TextField
            formNoValidate
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            value={formik.values.confirmPassword}
            onChange={(e) => {
              formik
                .setFieldValue('confirmPassword', e.target.value)
                .then(() => {
                  formik.validateForm();
                });
            }}
            errorAndSupportingText={formik.errors.confirmPassword}
            clear={(e) => {
              e.preventDefault();
            }}
          />
        </div>
        <AnimatePresence mode="wait">
          <motion.div
            // layout="position"
            initial={{ width: '100%', height: 'auto' }}
            animate={{ width: '100%', height: 'auto' }}
            transition={{
              layout: {
                duration: 0.2,
                type: 'tween',
              },
            }}
            style={{ width: '100%' }}
          >
            {showPromoInput ? (
              <div className={styles.formInput}>
                <TypographyPoppins
                  type="label"
                  size="M"
                  className={styles.label}
                >
                  <label htmlFor="promo_code">Offer code</label>
                </TypographyPoppins>
                <TextField
                  formNoValidate
                  id="promo_code"
                  name="promo_code"
                  value={formik.values.promo_code}
                  onChange={(e) => {
                    formik.setFieldValue('promo_code', e.target.value);
                  }}
                  errorAndSupportingText={formik.errors.promo_code}
                  clear={(e) => {
                    e.preventDefault();
                    formik.setFieldValue('promo_code', '');
                  }}
                />
              </div>
            ) : (
              <div className={styles.promoButtonContainer}>
                <Button
                  label="Add an offer code"
                  type="text"
                  onClick={() => setShowPromoInput(true)}
                />
              </div>
            )}
          </motion.div>
        </AnimatePresence>
        <div className={styles.formButtons}>
          <Button type="filled" label="Create account" onClick={() => {}} />
        </div>
      </form>
    </div>
  );
};

import { useEffect, useState } from 'react';

type UseLoadingDelayParams = {
  duration?: number;
  enableLoadingDelay?: boolean;
};

export function useLoadingDelay({
  duration = 1000,
  enableLoadingDelay = true,
}: UseLoadingDelayParams) {
  const [isLoading, setIsLoading] = useState(enableLoadingDelay);

  useEffect(() => {
    // Don't run this if there is no loading needed
    if (!isLoading) return;

    const timer = setTimeout(() => {
      setIsLoading(false);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, isLoading]);

  return isLoading;
}

import { Icon, IconProps } from './Icon';

export const ChatLeft = ({ stroke = 'black', ...props }: IconProps) => {
  return (
    <Icon {...props}>
      <path
        d="M7.56375 14.7557L4.90694 19.5H4.8076L4.99954 15.0214L5.02189 14.5H4.5H2.5V4.5H17C17.2761 4.5 17.5 4.72386 17.5 5V14.5H8H7.70694L7.56375 14.7557Z"
        stroke={stroke}
      />
      <mask
        id="mask0_15943_578"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="8"
        y="7"
        width="14"
        height="13"
      >
        <path d="M18 15V7H22V20H8.5V15H18Z" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_15943_578)">
        <path
          d="M20 17H19.5V17.5V19.5H19.2071L16.8536 17.1464L16.7071 17H16.5H9.5V8.5H21C21.2761 8.5 21.5 8.72386 21.5 9V17H20Z"
          stroke={stroke}
        />
      </g>
    </Icon>
  );
};

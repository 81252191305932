import React, { ComponentProps, ReactNode, useEffect } from 'react';

import cn from 'classnames';
import { AnimatePresence, motion, PanInfo, Variants } from 'framer-motion';

import { useKeyPress, useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { Button, ButtonType } from '../Buttons';
import { IconButton } from '../IconButtons';
import TypographyItalic from '../Styles/Typography/TypographyItalic';
import { TypographyPoppins } from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

type Props = {
  children: React.ReactNode;
  handleBackdropClick: () => void;
  showSheet: boolean;
  type?: 'large' | 'normal';
  customWidth?: number;
  className?: string;
  backdropVisible?: boolean;
  closeSideSheet: () => void;
  displayMobileVariant?: boolean;
};

/**
 * When using make sure the SideSheet component is always mounted, otherwise the exit animation will not run. If need to conditionaly render the sheet, create a parent component that renders <SideSheet .../> and a child component that conditionaly renders based on the data.
 */

export const SideSheet = ({
  children,
  handleBackdropClick,
  showSheet,
  type = 'normal',
  className,
  backdropVisible = true,
  customWidth,
  closeSideSheet,
  displayMobileVariant = true,
  ...rest
}: Props & ComponentProps<typeof motion.section>) => {
  const [mounted, setMounted] = React.useState(false);
  const { isMobile } = useWindowSize();
  const onBackdropClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (event.target === event.currentTarget && handleBackdropClick) {
      handleBackdropClick();
    }
  };

  useKeyPress({ key: 'Escape', onKeyDown: handleBackdropClick });

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, [mounted]);

  const mobileVariant: Variants = {
    show: {
      y: 0,
    },
    hidden: {
      y: 600,
    },
    exit: {
      y: 600,
      transition: {
        type: 'tween',
        ease: [0.3, 0, 0.8, 0.15],
        duration: 0.2,
      },
    },
  };

  const variant: Variants = {
    show: {
      right: 0,
    },
    hidden: {
      right: -400,
    },
    exit: {
      right: -400,
      transition: {
        type: 'tween',
        ease: [0.3, 0, 0.8, 0.15],
        duration: 0.2,
      },
    },
  };

  const dragHandler = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo,
  ) => {
    if (info.offset.y > 250 || info.velocity.y > 1000) {
      handleBackdropClick();
    }
  };

  const onClick = () => {
    closeSideSheet();
  };

  return (
    <AnimatePresence>
      {showSheet && mounted && (
        <div className={cn(styles.parentContainer)}>
          <div className={styles.container}>
            <motion.section
              layout
              key={'modal'}
              className={cn(styles.sideSheetContainer, className, {
                [styles.sideSheetContainerLarge]:
                  type === 'large' && !customWidth,
                [styles.sideSheetCustomWidth]: customWidth,
              })}
              style={
                {
                  '--side-sheet-width': customWidth
                    ? `${customWidth}px`
                    : undefined,
                } as React.CSSProperties
              }
              initial="hidden"
              animate={showSheet ? 'show' : 'hidden'}
              exit="exit"
              drag={isMobile ? 'y' : undefined}
              dragConstraints={{ top: 0, bottom: 200 }}
              dragElastic={0.2}
              dragSnapToOrigin
              variants={
                isMobile && displayMobileVariant ? mobileVariant : variant
              }
              onDrag={dragHandler}
              transition={{
                type: 'tween',
                ease: [0.05, 0.7, 0.1, 1],
                duration: 0.4,
              }}
              {...rest}
            >
              <motion.div role="button" className={styles.bodyHandle}>
                <div onClick={onClick} />
              </motion.div>
              {children}
            </motion.section>
            <motion.div
              key="backdrop"
              initial={{ opacity: 0 }}
              animate={{
                opacity: backdropVisible ? 1 : 0,
                transition: {
                  duration: 0.4,
                },
              }}
              exit={{
                opacity: 0,
              }}
              className={styles.backdrop}
              onClick={onBackdropClick}
            />
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

type ChildProps = {
  children: React.ReactNode;
  className?: string;
};

SideSheet.Heading = ({
  children,
  closeSideSheet,
  className,
  backAction,
}: ChildProps & {
  closeSideSheet?: () => void;
  backAction?: () => void;
}) => {
  return (
    <div className={cn(styles.headingContainer, className)}>
      {backAction ? (
        <IconButton
          type="unfilled"
          icon={<Icon name="sprite/chevron-left" />}
          onClick={backAction}
        />
      ) : null}
      <TypographyPoppins type="title" size="L">
        <span className={styles.headingLabel}>{children}</span>
      </TypographyPoppins>
      {closeSideSheet ? (
        <IconButton
          type="unfilled"
          icon={<Icon name="sprite/x" />}
          onClick={closeSideSheet}
        />
      ) : null}
    </div>
  );
};

SideSheet.SubHeading = ({ children }: ChildProps) => {
  return (
    <div className={styles.subHeadingContainer}>
      <TypographyPoppins type="title" size="L">
        <span>{children}</span>
      </TypographyPoppins>
    </div>
  );
};

SideSheet.SubSubHeading = ({ children }: ChildProps) => {
  return (
    <div className={styles.subSubHeadingContainer}>
      <TypographyPoppins type="body" size="M">
        <span>{children}</span>
      </TypographyPoppins>
    </div>
  );
};

SideSheet.Body = ({ children, className }: ChildProps) => {
  return <div className={cn(styles.bodyContainer, className)}>{children}</div>;
};

SideSheet.ContentHeading = ({ children }: ChildProps) => {
  return (
    <div className={styles.contentHeading}>
      <TypographyPoppins type="title" size="S">
        <span>{children}</span>
      </TypographyPoppins>
    </div>
  );
};

SideSheet.ContentContainer = ({ children, className }: ChildProps) => {
  return (
    <div className={cn(styles.contentContainer, className)}>{children}</div>
  );
};

SideSheet.Content = ({ children }: ChildProps) => {
  return (
    <div className={styles.content}>
      <TypographyPoppins type="body" size="M">
        <span>{children}</span>
      </TypographyPoppins>
    </div>
  );
};

SideSheet.InfoText = ({ children }: ChildProps) => {
  return (
    <div className={styles.infoText}>
      <TypographyItalic formattingBody="small italic" type="body">
        <span>{children}</span>
      </TypographyItalic>
    </div>
  );
};

SideSheet.BottomControls = ({
  closeSideSheet,
  mainClick,
  primaryLabel,
  hideCancel,
  mainType = 'filled',
  isDisabled,
  icon,
  className,
}: {
  closeSideSheet?: () => void;
  mainClick: () => void;
  primaryLabel: string;
  hideCancel?: boolean;
  mainType?: ButtonType;
  isDisabled?: boolean;
  icon?: ReactNode;
  className?: string;
}) => {
  const { isMobile } = useWindowSize();

  return (
    <div className={cn(styles.controls, className)}>
      {isMobile && !hideCancel ? (
        <Button
          label="Cancel"
          onClick={() => {
            if (closeSideSheet) {
              closeSideSheet();
            }
          }}
          type="outlined"
          stopPropagation
        />
      ) : null}
      <Button
        label={primaryLabel}
        icon={icon}
        onClick={mainClick}
        type={mainType}
        stopPropagation
        isDisabled={isDisabled}
      />
    </div>
  );
};

import { useRef, useState } from 'react';

import { useUnit } from 'effector-react';
import { Stage } from 'konva/lib/Stage';

import {
  Divider,
  Dropdown,
  SegmentedButton,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import {
  $currentTool,
  $showLibrary,
  addShape,
  changedTool,
  toggledLibrary,
} from '@pages/StudioPage/model';

import { FileUpload } from '../FileUpload';
import { shapeType } from '../Shape';

import styles from './styles.module.css';

export const AddToStudio = ({
  designId,
  stage,
}: {
  designId: string;
  stage: Stage | null;
}) => {
  const showLibrary = useUnit($showLibrary);
  const currentTool = useUnit($currentTool);

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const uploadRef = useRef<HTMLInputElement>(null);

  const uploadFromDevice = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  };

  return (
    <div className={styles.toolbarSection}>
      <TooltipRadix description="Add from Library">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/library" />}
          onClick={() => {
            changedTool('select');
            toggledLibrary();
          }}
          isSelected={showLibrary}
        />
      </TooltipRadix>
      <TooltipRadix description="Add textbox">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/textbox" />}
          onClick={() => changedTool('add-text')}
          isSelected={currentTool === 'add-text' && !showLibrary}
        />
      </TooltipRadix>
      <Dropdown open={isOpenDropdown} onOpenChange={setIsOpenDropdown}>
        <Dropdown.Menu
          trigger={
            <TooltipRadix description="Add shapes">
              <SegmentedButton
                buttonStyle={styles.button}
                icon={<Icon name="sprite/elements" />}
                onClick={() => {}}
                isSelected={currentTool === 'add-shape' && !showLibrary}
              />
            </TooltipRadix>
          }
          side="top"
          density="-2"
          align="start"
        >
          <Dropdown.MenuItem
            item={{
              isHideHoverEffect: true,
              content: <ShapesMenu setOpenDropdown={setIsOpenDropdown} />,
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
      <TooltipRadix description="Upload">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<Icon name="sprite/upload" />}
          onClick={() => uploadFromDevice()}
        />
      </TooltipRadix>
      <FileUpload stage={stage} ref={uploadRef} designId={designId} />
    </div>
  );
};

const ShapesMenu = ({
  setOpenDropdown,
}: {
  setOpenDropdown: (isOpenDropdown: boolean) => void;
}) => {
  const createShape = (shape: shapeType) => {
    setOpenDropdown(false);
    changedTool('add-shape');
    addShape(shape);
  };

  return (
    <>
      <TypographyPoppins type="label" size="S" className={styles.label}>
        Shapes
      </TypographyPoppins>
      <ul className={styles.shapes}>
        <li>
          <Icon
            name="sprite/studio-rectangle"
            className={styles.shapesIcon}
            onClick={() => createShape('rectangle')}
          />
        </li>
      </ul>
      <ul className={styles.shapes}>
        <li>
          <Icon
            name="sprite/studio-hexagon"
            className={styles.shapesIcon}
            onClick={() => createShape('hexagon')}
          />
        </li>
        <li>
          <Icon
            name="sprite/studio-six-pointed-star"
            className={styles.shapesIcon}
            onClick={() => createShape('star')}
          />
        </li>
      </ul>
      <ul className={styles.shapes}>
        <li>
          <Icon
            name="sprite/studio-circle"
            className={styles.shapesIcon}
            onClick={() => createShape('circle')}
          />
        </li>
        <li>
          <Icon
            name="sprite/studio-ellipse"
            className={styles.shapesIcon}
            onClick={() => createShape('ellipse')}
          />
        </li>
        <li>
          <Icon
            name="sprite/studio-slice-triangle"
            className={styles.shapesIcon}
            onClick={() => createShape('wedge')}
          />
        </li>
        <li>
          <Icon
            name="sprite/studio-double-circle"
            className={styles.shapesIcon}
            onClick={() => createShape('ring')}
          />
        </li>
        <li>
          <Icon
            name="sprite/studio-arc"
            className={styles.shapesIcon}
            onClick={() => createShape('arc')}
          />
        </li>
      </ul>
      <Divider type="long-line" className={styles.dividerShapesMenu} />
      <TypographyPoppins type="label" size="S" className={styles.label}>
        Line
      </TypographyPoppins>
      <ul className={styles.shapes}>
        <li>
          <Icon
            name="sprite/studio-line"
            className={styles.shapesIcon}
            onClick={() => createShape('line')}
          />
        </li>
        <li>
          <Icon
            name="sprite/studio-arrow"
            className={styles.shapesIcon}
            onClick={() => createShape('arrow')}
          />
        </li>
      </ul>
    </>
  );
};

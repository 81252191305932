import { createEvent, createStore } from 'effector';
import { hotkey } from 'effector-hotkey';

export const copyFileDialogOpened = createEvent();
export const copyFileDialogClosed = createEvent();

export const queryEntered = createEvent<string>();
export const queryCleared = createEvent();

export const $isOpenCopyFileDialog = createStore(false);
export const $query = createStore('');

$isOpenCopyFileDialog
  .on(copyFileDialogOpened, () => true)
  .reset(copyFileDialogClosed);

$query
  .on(queryEntered, (_, query) => query)
  .reset([queryCleared, copyFileDialogClosed]);

hotkey({
  key: 'Escape',
  filter: $isOpenCopyFileDialog && $query.map((query) => query.length === 0),
  target: copyFileDialogClosed,
});

hotkey({
  key: 'Escape',
  filter: $isOpenCopyFileDialog && $query.map((query) => query.length > 0),
  target: queryCleared,
});

import { ImageJSON } from '@api/designs';

import { centralLayout } from '../components/Shuffler/layouts/central';
import { clusterLayout } from '../components/Shuffler/layouts/cluster';

// NOTE: Layout methods are given copies and mutated directly.

type Animations =
  | 'cluster'
  | 'central'
  | 'left-hero'
  | 'right-hero'
  | 'top-hero'
  | 'bottom-hero';

export const chooseHeroImage = async (
  images: ImageJSON[],
  transparentImages: Set<string>,
) => {
  // Remove transparent images from the list of images
  const blockImages = images.filter(
    (image) => !transparentImages.has(image.id),
  );

  // Choose a random image from the list of images
  let heroImage = blockImages[Math.floor(Math.random() * blockImages.length)];

  // If no hero image due to transparency, choose a random image from the list of images
  if (!heroImage) {
    heroImage = images[Math.floor(Math.random() * images.length)];
  }

  return heroImage;
};

export const performLayoutCalculation = async ({
  heroImage,
  images,
  center,
  transparentImages,
  paletteImages,
}: {
  heroImage: ImageJSON;
  images: ImageJSON[];
  center: {
    x: number;
    y: number;
  };
  transparentImages: Set<string>;
  paletteImages: Set<string>;
}) => {
  const animation = decideAnimation(images);

  switch (animation) {
    case 'cluster':
      return clusterLayout({
        heroImage,
        images,
        center,
        transparentImages,
        paletteImages,
      });
    case 'central':
    case 'left-hero':
    case 'right-hero':
    case 'top-hero':
    case 'bottom-hero':
    default:
      return centralLayout({
        heroImage,
        images,
        center,
        transparentImages,
        paletteImages,
      });
    // return randomLayout({
    //   heroImage,
    //   images,
    //   center,
    //   transparentImages,
    //   paletteImages,
    // });
  }
};

const decideAnimation = (images: ImageJSON[]): Animations => {
  if (images.length < 9) return 'cluster';

  return 'cluster';
};

import { useState } from 'react';

import { UserPersonalisationType } from '@visualist/design-system';
import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';
import { Icon } from '@visualist/icons';

import { saveProfile } from '@api/account';
import { useAppData } from '@src/AppContext';
import { PersonalizationFields } from '@src/widgets/profile/components/personalization-fields';
import { getFormCompletionPercentage } from '@src/widgets/profile/util';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import styles from './styles.module.css';

export const Personalise = () => {
  const queryClient = useQueryClient();

  const { user } = useAppData();

  const [showEmpty, setShowEmpty] = useState(
    () => getFormCompletionPercentage(user) === 0,
  );

  const { mutate } = useMutation({
    mutationFn: saveProfile,
    onSuccess: (response, variables) => {
      queryClient.setQueryData(['my-user'], response);
      localStorage.setItem('v_user', JSON.stringify(response));
      if (variables.first_name || variables.last_name) {
        startedSnack({
          label: 'Profile updated',
          close: true,
        });
      }
    },
    onError: () => {
      startedSnack({
        label: 'An error occurred',
        close: true,
      });
    },
  });

  const updatePersonalizeFields = (user: UserPersonalisationType) => {
    mutate({
      active_since: user.active_since,
      location: user.location,
      creative_field: user.creative_field,
      creative_field_other: user.creative_field_other,
      team_size: user.team_size,
      average_fee: user.average_fee,
      project_number: user.project_number,
      use_case: user.use_case,
      acquisition_channel: user.acquisition_channel,
      acquisition_channel_other: user.acquisition_channel_other,
    });
  };

  if (showEmpty) {
    return (
      <div className={styles.container} data-is-empty={showEmpty}>
        <div className={styles.center}>
          <div>
            <Icon
              className={styles.martini}
              name="sprite/martini-colored"
              size={80}
            />
            <TypographyPoppins type="body" size="S" className={styles.text}>
              Let Vai get to know you better to provide better assistance
            </TypographyPoppins>
          </div>
          <Button
            type="outlined"
            onClick={() => setShowEmpty(false)}
            label="Improve my experience"
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <PersonalizationFields
        user={user}
        updatePersonalizeFields={updatePersonalizeFields}
        description="Vai can provide better suggestions with this information"
      />
    </div>
  );
};

import { BlockChoiceMapping, getAllBlocksOfBoard } from '@api/boards';
import { Block } from '@api/services';
import { useAppData } from '@src/AppContext';
import { ALL_BOARD_IMAGES_QUERY } from '@src/shared/constants/query-names';
import { useInfiniteQuery } from '@tanstack/react-query';

type Props = {
  boardId: string;
  typeOfBlock?: keyof typeof BlockChoiceMapping;
};

export const useBoardBlocks = ({ boardId, typeOfBlock }: Props) => {
  const { user } = useAppData();

  const query = useInfiniteQuery({
    queryKey: [
      ALL_BOARD_IMAGES_QUERY,
      { boardId, type: typeOfBlock },
      user.show_archived,
    ],
    queryFn: ({ pageParam }) =>
      getAllBlocksOfBoard({
        boardId,
        typeOfBlock,
        offset: parseInt(pageParam),
        is_archived: user.show_archived,
      }),
    initialPageParam: '0',
    getNextPageParam: (lastGroup) =>
      lastGroup.next && new URL(lastGroup.next).searchParams.get('offset'),
    refetchInterval: 180000,
  });

  if (!query.data)
    return {
      blocks: [],
      ...query,
    };

  const blocks = query.data.pages.reduce(
    (acc, item) => [...acc, ...item.results],
    [] as Block[],
  );

  return { blocks, ...query };
};

import { Design } from './design';

export class DesignManager {
  private static readonly instances = new Map<string, Design>();

  static getInstance(designId: string) {
    if (!this.instances.has(designId)) {
      this.instances.set(designId, new Design(designId));
    }

    return this.instances.get(designId)!;
  }

  static removeInstance(designId: string) {
    this.instances.delete(designId);
  }
}

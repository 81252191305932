import React from 'react';

import { useUnit } from 'effector-react';
import Konva from 'konva';
import type { Stage } from 'konva/lib/Stage';
import {
  Group,
  Image as KonvaImage,
  Layer,
  Stage as KonvaStage,
  Transformer,
} from 'react-konva';
import { Html } from 'react-konva-utils';
import useLoadedImage from 'use-image';

import {
  AssistChip,
  Check,
  SegmentedButton,
  ThinArrowIcon,
  TooltipRadix,
  TypographyPoppins,
  VaiStarIcon,
  VaiVerticalIcon,
} from '@visualist/design-system/src/components/v2';
import { useKeyPress } from '@visualist/hooks';

import { ImageJSON } from '@api/designs';
import { PageLoader } from '@components/PageLoader';
import { MAIN_OBJECTS_LAYER } from '@pages/StudioPage/constants';
import {
  $showShuffler,
  closedShuffler,
  closedVaiTidyupModal,
} from '@pages/StudioPage/model';
import { isLayerNode } from '@pages/StudioPage/utils';
import { VaiModal, VaiModalProvider } from '@src/entities/vai/ui/modal';
import { TERTIARY_40 } from '@src/shared/constants/colours';

import { useShuffler } from './useShuffler';

import styles from './styles.module.css';

type Props = {
  stageRef: React.MutableRefObject<Stage | null>;
  designId: string;
};

export const Shuffler = ({ stageRef, designId }: Props) => {
  const [showShuffler] = useUnit([$showShuffler]);

  const {
    images,
    updateImage,
    applyPassedLayers,
    selectHero,
    centerPoint,
    currentHeroImage,
    currentMapKey,
    setCurrentMapKey,
    shuffledMap,
    handleShuffle,
    hasLoadedTransparentImages,
  } = useShuffler({ designId });

  const applyLayout = async () => {
    if (!currentMapKey) {
      console.error('No layout selected');
      return;
    }

    const currentLayoutData = shuffledMap.get(currentMapKey);

    if (!currentLayoutData) {
      console.error('No layout data found');
      return;
    }

    if (!stageRef.current) {
      console.error('No stage');
      return;
    }

    closedShuffler();

    // Wait for the modal to close
    await new Promise((resolve) => setTimeout(resolve, 400));

    const mainObjectLayer = stageRef.current.find(`.${MAIN_OBJECTS_LAYER}`);

    if (!mainObjectLayer || mainObjectLayer.length !== 1) {
      console.error('Incorrect main object layer for exporting images');
    }

    const layer = mainObjectLayer[0];

    if (!isLayerNode(layer)) {
      console.error('Main object layer type incorrect');
      return;
    }

    // const layerObjectsMap = new Map<string, ImageJSON>(
    //   currentLayoutData.images.map((image) => [image.id, image]),
    // );

    // let movePromises: Promise<void>[] = [];

    // layer.children.forEach((g) => {
    //   const image = layerObjectsMap.get(g.id());

    //   if (!image) return;

    //   movePromises.push(
    //     new Promise((res, rej) => {
    //       if (!g) rej(new Error('No Group node'));

    //       g.to({
    //         x: image.x,
    //         y: image.y,
    //         rotation: image.rotation,
    //         width: image.metadata.width,
    //         height: image.metadata.height,
    //         duration: 0.5,
    //         onFinish: res,
    //       });
    //     }),
    //   );
    // });

    // await Promise.allSettled(movePromises);

    currentLayoutData.images.forEach((img) => {
      updateImage({
        ...img,
      });
    });

    applyPassedLayers({
      layeredObjects: currentLayoutData.images.map((img) => img.id),
    });
  };

  useKeyPress({
    key: 'Enter',
    onKeyDown: () => {
      if (!showShuffler) return;
      applyLayout();
    },
  });

  return (
    <VaiModalProvider
      showModal={showShuffler}
      closeModal={() => closedShuffler()}
    >
      <VaiModal>
        <div className={styles.container}>
          <Canvas
            // designId={designId}
            images={images}
            selectHero={selectHero}
            centerPoint={centerPoint}
            currentHeroImage={currentHeroImage}
            currentMapKey={currentMapKey}
            handleShuffle={handleShuffle}
            shuffledMap={shuffledMap}
            hasLoadedTransparentImages={hasLoadedTransparentImages}
          />
          <div className={styles.toolbarContainer}>
            <Toolbar handleShuffle={handleShuffle} />
          </div>
          <div className={styles.layout}>
            <LayoutControls
              numberOfKeys={shuffledMap.size}
              currentMapKey={currentMapKey}
              setCurrentMapKey={setCurrentMapKey}
              applyLayout={applyLayout}
            />
          </div>
        </div>
      </VaiModal>
    </VaiModalProvider>
  );
};

const Toolbar = ({ handleShuffle }: { handleShuffle: () => void }) => {
  return (
    <>
      <TypographyPoppins type="body" size="M" className={styles.footerText}>
        Tidy up your designs with Vai. <br /> To see more layouts, press
        Spacebar or
      </TypographyPoppins>
      <AssistChip
        leadingIcon
        icon={<VaiVerticalIcon color="black" />}
        onClick={handleShuffle}
        style="elevated"
        className={styles.tidyButton}
      >
        <div className={styles.sparkles}>
          <VaiStarIcon className={styles.sparkle} />
        </div>
        Tidy up
      </AssistChip>
    </>
  );
};

const LayoutControls = (props: {
  currentMapKey: number | undefined;
  setCurrentMapKey: React.Dispatch<React.SetStateAction<number | undefined>>;
  numberOfKeys: number;
  applyLayout: () => Promise<void>;
}) => {
  const { currentMapKey, setCurrentMapKey, numberOfKeys, applyLayout } = props;

  const incrementMapKey = () => {
    if (currentMapKey === undefined) return setCurrentMapKey(0);
    if (currentMapKey >= numberOfKeys) return;
    setCurrentMapKey((k = 0) => k + 1);
  };

  const decrementMapKey = () => {
    if (currentMapKey === undefined) return;

    if (currentMapKey <= 0) return;

    setCurrentMapKey(currentMapKey - 1);
  };

  useKeyPress({
    key: 'ArrowRight',
    onKeyDown: incrementMapKey,
  });

  useKeyPress({
    key: 'ArrowLeft',
    onKeyDown: decrementMapKey,
  });

  return (
    <div className={styles.toolbar}>
      <TooltipRadix description="Previous layout">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<ThinArrowIcon direction="left" height={24} width={24} />}
          isDisabled={currentMapKey === 0}
          onClick={decrementMapKey}
        />
      </TooltipRadix>
      <TooltipRadix description="Next layout">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<ThinArrowIcon direction="right" height={24} width={24} />}
          isDisabled={
            currentMapKey === undefined || currentMapKey >= numberOfKeys
          }
          onClick={incrementMapKey}
        />
      </TooltipRadix>
      <div className={styles.divider} />
      <SegmentedButton
        buttonStyle={styles.button}
        icon={
          // applyLayoutMutation.isPending ? (
          //   <Spinner colour={TERTIARY_40} />
          // ) : (
          // )
          <Check height={14} width={14} />
        }
        label="Apply this layout"
        isDisabled={numberOfKeys === 0}
        onClick={async () => {
          await applyLayout();
          closedVaiTidyupModal();
        }}
      />
    </div>
  );
};

const STAGE_SCALE = 0.07;

const Canvas = (props: {
  images: ImageJSON[];
  handleShuffle: () => void;
  hasLoadedTransparentImages: boolean;
  centerPoint: { x: number; y: number };
  selectHero: (id: string) => void;
  currentHeroImage: string;
  currentMapKey: number | undefined;
  shuffledMap: Map<number, { images: ImageJSON[] }>;
}) => {
  const {
    images,
    handleShuffle,
    hasLoadedTransparentImages,
    centerPoint,
    selectHero,
    currentHeroImage,
    currentMapKey,
    shuffledMap,
  } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const stageRef = React.useRef<Stage | null>(null);
  const [dimensions, setDimensions] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    if (!ref.current) return;
    const { width, height } = ref.current.getBoundingClientRect();
    setDimensions({
      width: Math.min(width ?? 0, 1200),
      height: Math.min(height ?? 0, 1200),
    });

    window.addEventListener('resize', () => {
      setDimensions({
        width: Math.min(ref.current?.clientWidth ?? 0, 1200),
        height: Math.min(ref.current?.clientHeight ?? 0, 1200),
      });
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, [hasLoadedTransparentImages]);

  useKeyPress({
    key: ' ',
    onKeyDown: handleShuffle,
  });

  if (!hasLoadedTransparentImages)
    return (
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <PageLoader isVai />
      </div>
    );

  return (
    <div className={styles.canvas} ref={ref}>
      <KonvaStage
        ref={stageRef}
        scale={{
          x: STAGE_SCALE,
          y: STAGE_SCALE,
        }}
        x={-centerPoint.x * STAGE_SCALE + dimensions?.width / 2}
        y={-centerPoint.y * STAGE_SCALE + dimensions?.height / 2}
        height={dimensions.height}
        width={dimensions.width}
      >
        <Layer>
          {images ? (
            <Images
              selectHero={selectHero}
              heroImage={currentHeroImage}
              images={
                currentMapKey
                  ? shuffledMap.get(currentMapKey)?.images ?? images
                  : images
              }
            />
          ) : null}
        </Layer>
      </KonvaStage>
    </div>
  );
};

const Images = ({
  images,
  heroImage,
  selectHero,
}: {
  images: ImageJSON[];
  heroImage: string;
  selectHero: (id: string) => void;
}) => {
  return (
    <>
      {images.map((image) => (
        <Image
          selectHero={selectHero}
          key={image.id}
          id={image.id}
          x={image.x}
          y={image.y}
          height={image.metadata.height}
          width={image.metadata.width}
          rotation={image.rotation}
          url={image.metadata.file.full_size}
          isHero={heroImage === image.id}
        />
      ))}
    </>
  );
};

const Image = ({
  id,
  height,
  width,
  rotation,
  url,
  x,
  y,
  isHero,
  selectHero,
}: {
  id: string;
  x: number;
  y: number;
  height: number;
  width: number;
  rotation: number;
  url: string;
  isHero: boolean;
  selectHero: (id: string) => void;
}) => {
  const [image] = useLoadedImage(url, 'anonymous');

  const [isHovered, setIsHovered] = React.useState(false);
  const hoveredTrRef = React.useRef<Konva.Transformer>(null);
  const imageRef = React.useRef<Konva.Image>(null);

  const removeHoveredImageTransformer = () => {
    // @ts-ignore
    hoveredTrRef.current.nodes([]);
    // @ts-ignore
    hoveredTrRef.current.getLayer().batchDraw();
  };

  React.useEffect(() => {
    // Used to update the transformer when transforming the image
    if (isHovered && hoveredTrRef.current) {
      // we need to attach transformer manually
      // @ts-ignore
      hoveredTrRef.current.nodes([imageRef.current]);
      // @ts-ignore
      hoveredTrRef.current.getLayer().batchDraw();
    } else if (!isHovered && hoveredTrRef.current)
      removeHoveredImageTransformer();

    return () => {
      if (hoveredTrRef.current) removeHoveredImageTransformer();
    };
  }, [isHovered]);

  return (
    <>
      <KonvaImage
        ref={imageRef}
        id={id}
        x={x}
        y={y}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        stroke={isHero ? TERTIARY_40 : undefined}
        strokeWidth={isHero ? 20 : undefined}
        height={height}
        width={width}
        rotation={rotation}
        image={image}
        onClick={() => selectHero(id)}
      />
      {isHovered ? (
        <Transformer
          rotateEnabled={false}
          // @ts-ignore TODO fix type issue
          ref={hoveredTrRef}
          keepRatio={false}
          enabledAnchors={[]}
          resizeEnabled={false}
          borderStroke={TERTIARY_40}
          anchorStroke={TERTIARY_40}
        />
      ) : null}
      <Group x={x} y={y} width={width}>
        <Html
          groupProps={{
            x: width / 2,
            y: -320,
          }} // additional properties to the group wrapper, useful for some position offset
        >
          {/* isHovered && !isHero */}
          {isHovered && !isHero ? (
            // @ts-ignore Some query client global issue
            <div className={styles.tooltip}>
              <TypographyPoppins type="body" size="M">
                Select this as hero
              </TypographyPoppins>
            </div>
          ) : null}
        </Html>
      </Group>
    </>
  );
};

type Block = {
  x: number;
  y: number;
  width: number;
  height: number;
};

/**
 * @name CalculateCenter
 * @description Calculates center of a given set of blocks. In this case a block has a height, width, x and y.
 * @param blocks
 * @returns center {x: number, y: number}
 */

export const calculateCenter = ({ blocks }: { blocks: Block[] }) => {
  const xValues = blocks.map((b) => b.x);
  const yValues = blocks.map((b) => b.y);

  const xLowerFence = calculateLowerFence(xValues, 0.2, 0.8);
  const xUpperFence = calculateUpperFence(xValues, 0.2, 0.8);

  const yLowerFence = calculateLowerFence(yValues, 0.2, 0.8);
  const yUpperFence = calculateUpperFence(yValues, 0.2, 0.8);

  const filteredPoints = blocks.filter((b) => {
    const point = {
      x: b.x,
      y: b.y,
    };

    return (
      point.x >= xLowerFence &&
      point.x <= xUpperFence &&
      point.y >= yLowerFence &&
      point.y <= yUpperFence
    );
  });

  const center = {
    x: 0,
    y: 0,
  };

  filteredPoints.forEach((b) => {
    center.x += b.x + b.width / 2;
    center.y += b.y + b.height / 2;
  });
  center.x /= filteredPoints.length;
  center.y /= filteredPoints.length;

  return center;
};

// Function to calculate the lower fence
export const calculateLowerFence = (
  values: number[],
  lower: number,
  upper: number,
) => {
  const q1 = percentile(values, lower);
  const q3 = percentile(values, upper);
  const iqr = q3 - q1;
  return q1 - 1.5 * iqr;
};

// Function to calculate the upper fence
export const calculateUpperFence = (
  values: number[],
  lower: number,
  upper: number,
) => {
  const q1 = percentile(values, lower);
  const q3 = percentile(values, upper);
  const iqr = q3 - q1;
  return q3 + 1.5 * iqr;
};

// Function to calculate the percentile
const percentile = (arr: number[], p: number) => {
  arr.sort((a, b) => a - b);
  const index = Math.floor(p * (arr.length - 1));
  return arr[index];
};

import { createEvent, createStore, sample } from 'effector';

import { locationSelected } from './move';

export const cleared = createEvent();

export const $moveOutOfHub = createStore<{
  hubId: string;
  boardId: string[];
} | null>(null);

sample({
  clock: locationSelected,
  fn: (location) => {
    if (location.hub && location.parent) {
      return {
        hubId: location.hub.id,
        boardId: [location.id],
      };
    } else {
      return null;
    }
  },
  target: $moveOutOfHub,
});

sample({
  clock: cleared,
  fn: () => null,
  target: $moveOutOfHub,
});

import { useLocation } from 'react-router';

import { saveProfile } from '@api/account';
import {
  ALL_BOARD_IMAGES_QUERY,
  ALL_DOCS_QUERY,
  ALL_HUBS_QUERY,
  BOARD_QUERY,
  BOARDS_TREE_QUERY,
  DOCS_OF_BOARD_QUERY,
  GET_BLOCKS_QUERY,
  GET_BLOCKS_QUERY_DOCS_SIDEBAR,
  HUB_QUERY,
  IMAGE_QUERY,
  LIBRARY_QUERY,
} from '@src/shared/constants';
import { boardsKeys } from '@src/shared/constants/query-keys';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useShowArchived = () => {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const showArchivedMutation = useMutation({
    mutationFn: saveProfile,
    onSuccess: (response) => {
      queryClient.setQueryData(['my-user'], response);
      localStorage.setItem('v_user', JSON.stringify(response));

      queryClient.invalidateQueries({
        queryKey: ['my-user'],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_HUBS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARDS_TREE_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [HUB_QUERY, { hubId: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: [BOARD_QUERY, { board_id: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: [
          ALL_BOARD_IMAGES_QUERY,
          { board_id: pathname.split('/')[2] },
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [DOCS_OF_BOARD_QUERY, { board: pathname.split('/')[2] }],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_DOCS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [LIBRARY_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [GET_BLOCKS_QUERY_DOCS_SIDEBAR],
      });
      queryClient.invalidateQueries({
        queryKey: [GET_BLOCKS_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: boardsKeys.noHub(),
      });
      queryClient.invalidateQueries({
        queryKey: [IMAGE_QUERY, { id: pathname.split('/')[2] }],
      });
    },

    onError: () => {},
  });

  return {
    showArchivedMutation,
  };
};

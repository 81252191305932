import { useState } from 'react';

import { Stage } from 'konva/lib/Stage';
import { flushSync } from 'react-dom';

import {
  Dropdown,
  Item,
  SegmentedButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { Icon } from '@visualist/icons';

import {
  downloadStudioImages,
  downloadStudioPDF,
} from '@pages/StudioPage/export-studio-pdf';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import { useDesign } from '@pages/StudioPage/hooks/useDesign';

import { usePageNavigation } from '../film-strip/usePageNavigation';

import styles from './styles.module.css';

export const PageSetup = ({
  designId,
  stage,
  hideAddNewPage,
}: {
  designId: string;
  stage: Stage | null;
  hideAddNewPage: boolean;
}) => {
  const {
    designData,
    currentPageIndex: currentPage,
    addPage,
  } = useStudioDesign(designId);
  const { designQuery } = useDesign({ designId });

  const { navigateToPage } = usePageNavigation();

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const name = designQuery.data?.name || 'Untitled';

  const addNewPage = () => {
    if (currentPage === null) return;
    // Current page is index
    flushSync(() => {
      addPage(currentPage + 1);
    });
    navigateToPage(currentPage + 1);
  };

  const downloadThumbnailJPG = async () => {
    if (!stage) return;
    await downloadStudioImages(stage, designData, name, 'jpg');
  };

  const downloadThumbnailPNG = async () => {
    if (!stage) return;

    await downloadStudioImages(stage, designData, name, 'png');
  };

  const downloadPDF = async () => {
    if (!stage) return;
    await downloadStudioPDF(stage, designData, name);
  };

  const menuItems: Item<Variant>[] = [
    {
      content: 'PNG',
      classNameContent: styles.padding,
      onClick: downloadThumbnailPNG,
    },
    {
      content: 'JPG',
      classNameContent: styles.padding,
      onClick: downloadThumbnailJPG,
    },
    {
      content: 'PDF',
      classNameContent: styles.padding,
      onClick: downloadPDF,
    },
  ];

  return (
    <div className={styles.toolbarSection}>
      {hideAddNewPage ? null : (
        <TooltipRadix description="New page">
          <SegmentedButton
            buttonStyle={styles.button}
            icon={<Icon name="sprite/page-landscape-add" />}
            onClick={addNewPage}
            isSelected={false}
          />
        </TooltipRadix>
      )}
      <Dropdown open={isOpenDropdown} onOpenChange={setIsOpenDropdown}>
        <Dropdown.Menu
          trigger={
            <TooltipRadix description="Download">
              <SegmentedButton
                start={hideAddNewPage}
                buttonStyle={styles.button}
                icon={<Icon name="sprite/download" />}
                onClick={() => {}}
                isSelected={false}
              />
            </TooltipRadix>
          }
          side="bottom"
          // sideOffset={-8}
          align="start"
          density="-2"
        >
          {menuItems.map((item, index) => (
            <Dropdown.MenuItem key={index} item={item} />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

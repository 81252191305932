import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  InputChip,
  TextField,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';

import { UserInvitationHub } from '@api/hubs';
import { ResponseSuggestion } from '@api/users';
import { checkValidateEmail, KeyCodes } from '@src/shared/utils';

import styles from './styles.module.css';

type InivteField = {
  isNotEnoughSeats: boolean;
  suggestions: ResponseSuggestion[];
  invitation: string;
  setInvitation: (s: string) => void;
  users: UserInvitationHub[];
  setUsers: Dispatch<UserInvitationHub[]>;
  clear: () => void;
  isHub?: boolean;
  type?: 'hub' | 'doc';
};

export const InviteField = ({
  invitation,
  setInvitation,
  users,
  setUsers,
  suggestions,
  isNotEnoughSeats,
  clear,
  isHub,
  type = 'hub',
}: InivteField) => {
  const [error, setError] = useState('');
  const [supportingText, setSupportingText] = useState('');

  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (users && users.length > 0 && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [users]);

  useEffect(() => {
    if (invitation.length >= 0) {
      setError('');
    }
  }, [invitation]);

  useEffect(() => {
    if (
      users &&
      suggestions &&
      invitation.trim().length > 0 &&
      users.length === 0 &&
      suggestions.length === 0
    ) {
      setSupportingText('No contacts found. Enter email to invite');
    } else {
      setSupportingText('');
    }
  }, [suggestions, invitation, users]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInvitation(e.target.value.replace(/[,]/g, ''));
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (
      (e.key === KeyCodes.COMMA ||
        e.key === KeyCodes.ENTER ||
        e.key === KeyCodes.SPACE) &&
      !e.shiftKey &&
      checkValidateEmail(invitation) &&
      invitation.trim().length !== 0
    ) {
      e.preventDefault();
      setUsers([...users, { email: invitation }]);
      setInvitation('');
    }

    if (
      (e.key === KeyCodes.COMMA ||
        e.key === KeyCodes.ENTER ||
        e.key === KeyCodes.SPACE) &&
      !e.shiftKey &&
      invitation.trim().length > 0 &&
      !checkValidateEmail(invitation)
    ) {
      e.preventDefault();
      setError('Invalid email');
    }
  };

  const removeChip = (index: number) =>
    setUsers(users.filter((_, idx) => idx !== index));

  const inputChips =
    users && users.length > 0
      ? users.map(({ email }, index) => (
          <li key={email} style={{ marginRight: '4px', marginTop: '4px' }}>
            <InputChip
              style="outlined"
              trailingIcon
              onClick={() => removeChip(index)}
            >
              {email}
            </InputChip>
          </li>
        ))
      : null;

  return (
    <div className={styles.inviteField}>
      {isNotEnoughSeats && (isHub || type === 'hub') && (
        <TooltipRadix description="You don't have enough seats">
          <TextField
            ref={textFieldRef}
            placeholder="Enter name or email"
            value={invitation}
            onChange={onChange}
            onKeyDown={onKeyDown}
            errorAndSupportingText={error}
            error={isNotEnoughSeats}
            clear={clear}
            inputChips={inputChips}
          />
        </TooltipRadix>
      )}

      {type === 'doc' && !isHub && (
        <TooltipRadix description="This doc is not in a hub. Add it to a hub to invite others.">
          <TextField
            ref={textFieldRef}
            placeholder="Enter name or email"
            value={invitation}
            onChange={onChange}
            onKeyDown={onKeyDown}
            clear={clear}
            isDisabled={!isHub}
          />
        </TooltipRadix>
      )}

      {!isNotEnoughSeats && (isHub || type === 'hub') && (
        <TextField
          ref={textFieldRef}
          placeholder="Enter name or email"
          value={invitation}
          onChange={onChange}
          onKeyDown={onKeyDown}
          supportingText={supportingText}
          errorAndSupportingText={error}
          clear={clear}
          inputChips={inputChips}
        />
      )}
    </div>
  );
};

import type { Stage } from 'konva/lib/Stage';
import { Layer } from 'react-konva';

import { STICKY_LAYER } from '@pages/StudioPage/constants';

import { Stickies } from '../../Stickies';

export const StickiesLayer = ({
  designId,
  hasCentered,
  stageRef,
}: {
  designId: string;
  hasCentered: boolean;
  stageRef: React.MutableRefObject<Stage | null>;
}) => {
  if (!designId || !hasCentered) return null;

  return (
    <Layer name={STICKY_LAYER}>
      <Stickies stageRef={stageRef} designId={designId} />
    </Layer>
  );
};

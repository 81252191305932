import React, { useEffect, useRef, useState } from 'react';

import cn from 'classnames';
import { Stage } from 'konva/lib/Stage';
import {
  DragDropContext,
  Draggable,
  DragStart,
  DragUpdate,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';

import {
  SegmentedButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { DesignObject, Page } from '@api/designs';

import { useStudioShortcut } from '../../hooks/useStudioShortcut';
import { PageBackgroundColorSetupWrapper } from '../page-setup/background-color';
import {
  openedPageBackgroundColorSetupModal,
  openedPageSizeSetupModal,
} from '../page-setup/model';
import { PageSizeSetupWrapper } from '../page-setup/page-size';
import { DropZone } from './film-strip-drop-zone';
import { Frame } from './frame';
import { usePageNavigation } from './usePageNavigation';

import styles from './styles.module.css';

type FilmStripProps = {
  designId: string;
  pages: Array<Page>;
  allPageObjects: Array<DesignObject>;
  switchStateType: (stage: Stage | null) => void;
  stageRef: React.MutableRefObject<Stage | null>;
  deletePage: (pageId: string) => number;
  addPage: (index?: number, id?: string) => void;
  swapPage: (
    currentIndex: number,
    swapTo: number,
    currentSelectedFrameId: string,
  ) => number | undefined;
  pastePage: (index?: number) => void;
  markPageForCopy: (pageId: string) => void;
  duplicatePage: (pageId: string) => number;
  currentPageMetadata: () => Page['metadata'] | null;
};

export const FilmStrip = ({
  designId,
  pages,
  allPageObjects,
  switchStateType,
  stageRef,
  deletePage,
  addPage,
  swapPage,
  pastePage,
  markPageForCopy,
  duplicatePage,
  currentPageMetadata,
}: FilmStripProps) => {
  const [isFilmStripVisible, setIsFilmStripVisible] = useState(true);
  const [insertLineIndex, setInsertLineIndex] = useState<number>(-1);
  const filmStripRef = useRef<HTMLDivElement>(null);
  const filmStripButtonRef = useRef<HTMLDivElement>(null);
  const { navigateToPage, currentPageIndex, navigateToEnd, selectedId } =
    usePageNavigation();
  const [clickedPageId, setClickedPageId] = useState<string>('');

  // Handles
  const handleAddPage = (index?: number, id?: string) => {
    if (index !== undefined && id !== undefined) {
      addPage(index, id);
      navigateToPage(index);
    } else if (index !== undefined) {
      addPage(index);
      navigateToPage(index);
    } else {
      addPage();
      navigateToEnd();
    }
  };

  const handleDeletePage = (pageId: string) => {
    const pageIndexToReturnTo = deletePage(pageId);
    navigateToPage(pageIndexToReturnTo - 1);
  };

  const handleCopyPage = (pageId: string) => {
    if (pageId) {
      markPageForCopy(pageId);
    }
  };

  const handleCutPage = (pageId: string) => {
    if (pageId) {
      markPageForCopy(pageId);
      navigateToPage(currentPageIndex - 1);
      deletePage(pageId);
    }
  };

  const handlePaste = (index?: number) => {
    if (insertLineIndex !== -1) {
      pastePage(insertLineIndex);
      navigateToPage(insertLineIndex);
    } else if (index !== undefined && index !== null) {
      pastePage(index);
      navigateToPage(index);
    } else {
      pastePage(currentPageIndex + 1);
      navigateToPage(currentPageIndex + 1);
    }
  };

  const handleUpdatePageSize = (pageId: string) => {
    setClickedPageId(pageId);
    openedPageSizeSetupModal();
  };

  const handleUpdatePageBackground = (pageId: string) => {
    setClickedPageId(pageId);
    openedPageBackgroundColorSetupModal();
  };

  const handleUpdatePageSizeDropZone = (index: number) => {
    setClickedPageId(pages[index === 0 ? 0 : index - 1].metadata.id);
    openedPageSizeSetupModal();
  };

  const handleUpdatePageBackgroundDropZone = (index: number) => {
    setClickedPageId(pages[index === 0 ? 0 : index - 1].metadata.id);
    openedPageBackgroundColorSetupModal();
  };

  const handleDuplicatePage = (pageId: string) => {
    const page = duplicatePage(pageId);
    navigateToPage(page);
  };

  const handleDropZoneClick = (index: number): void => {
    setInsertLineIndex(insertLineIndex === index ? -1 : index);
  };

  const handleDropZoneContextMenu = (
    index: number,
    e: React.MouseEvent,
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    setInsertLineIndex(index);
  };

  const handleDragStart = (event: DragStart) => {
    setInsertLineIndex(event.source.index);
  };

  const handleDragUpdate = (event: DragUpdate) => {
    if (!event.destination) {
      setInsertLineIndex(-1);
      return;
    }

    if (event.destination.index === event.source.index) {
      setInsertLineIndex(event.destination.index);
    } else if (event.destination.index > event.source.index - 1) {
      setInsertLineIndex(event.destination.index + 1);
    } else {
      setInsertLineIndex(event.destination.index);
    }
  };

  const onDragEnd = (result: DropResult): void => {
    const { source, destination } = result;

    if (!destination || destination.index === source.index) {
      setInsertLineIndex(-1);
      return;
    }

    const metadata = currentPageMetadata();
    if (!metadata) {
      setInsertLineIndex(-1);
      return;
    }

    const frameId = metadata.id;
    const page = swapPage(source.index, destination.index, frameId);

    if (page) {
      navigateToPage(page + 1);
    } else {
      navigateToPage(1);
    }

    setInsertLineIndex(-1);
  };

  // ShortCuts
  useStudioShortcut('Enter', () => {
    if (isFocused()) {
      handleAddPage();
    }
  });

  useStudioShortcut('Backspace', () => {
    if (isFocused()) {
      const metadata = currentPageMetadata();
      if (metadata === null) return;
      handleDeletePage(metadata.id);
    }
  });
  useStudioShortcut('Delete', () => {
    if (isFocused()) {
      const metadata = currentPageMetadata();
      if (metadata === null) return;
      handleDeletePage(metadata.id);
    }
  });
  useStudioShortcut('ArrowLeft', () => {
    if (isFocused()) {
      const newPage =
        currentPageIndex === 0 ? pages.length : currentPageIndex - 1;
      navigateToPage(newPage);
    }
  });
  useStudioShortcut('ArrowRight', () => {
    if (isFocused()) {
      const newPage =
        currentPageIndex >= pages.length - 1 ? 0 : currentPageIndex + 1;
      navigateToPage(newPage);
    }
  });
  useStudioShortcut('Command+c', () => {
    if (isFocused()) {
      const metadata = currentPageMetadata();
      if (metadata === null) return;
      handleCopyPage(metadata.id);
    }
  });

  useStudioShortcut('Control+c', () => {
    if (isFocused()) {
      const metadata = currentPageMetadata();
      if (metadata === null) return;
      handleCopyPage(metadata.id);
    }
  });

  useStudioShortcut('Command+x', () => {
    if (isFocused()) {
      const metadata = currentPageMetadata();
      if (metadata === null) return;
      handleCutPage(metadata.id);
    }
  });

  useStudioShortcut('Control+x', () => {
    if (isFocused()) {
      const metadata = currentPageMetadata();
      if (metadata === null) return;
      handleCutPage(metadata.id);
    }
  });

  useStudioShortcut('Command+v', () => {
    if (isFocused()) {
      handlePaste();
    }
  });

  useStudioShortcut('Control+v', () => {
    if (isFocused()) {
      handlePaste();
    }
  });

  const isFocused = () => {
    return (
      (filmStripRef.current &&
        (filmStripRef.current === document.activeElement ||
          filmStripRef.current.contains(document.activeElement))) ||
      (filmStripButtonRef.current &&
        (filmStripButtonRef.current === document.activeElement ||
          filmStripButtonRef.current.contains(document.activeElement)))
    );
  };

  useEffect(() => {
    if (filmStripRef.current) {
      filmStripRef.current.addEventListener('mousedown', () => {
        filmStripRef.current?.focus();
      });
    }
  }, []);

  useEffect(() => {
    const handleGlobalClick = (e: MouseEvent): void => {
      const target = e.target as HTMLElement;
      const isDropZone = target.closest(`.${styles.dropZone}`);
      if (!isDropZone) {
        setInsertLineIndex(-1);
      }
    };

    document.addEventListener('click', handleGlobalClick);
    document.addEventListener('contextmenu', handleGlobalClick);

    return () => {
      document.removeEventListener('click', handleGlobalClick);
      document.removeEventListener('contextmenu', handleGlobalClick);
    };
  }, []);

  return (
    <>
      <div className={styles.toggleButtonBox} ref={filmStripButtonRef}>
        <TooltipRadix
          description={isFilmStripVisible ? 'Hide filmstrip' : 'Show filmstrip'}
          side="top"
        >
          <SegmentedButton
            icon={<Icon name="sprite/film-strip" />}
            buttonStyle={styles.toggleButton}
            onClick={() => setIsFilmStripVisible((prev) => !prev)}
          />
        </TooltipRadix>
      </div>
      <div
        id="filmStrip"
        tabIndex={0}
        ref={filmStripRef}
        className={`${styles.container} ${
          isFilmStripVisible ? styles.filmStripVisible : ''
        }`}
      >
        <div className={styles.slidesContainer}>
          <DropZone
            key={'dropzone-0'}
            index={0}
            designId={designId}
            className={styles.leftMostIndicator}
            isActive={insertLineIndex === 0}
            allPageObjects={allPageObjects}
            stage={stageRef.current}
            switchStateType={switchStateType}
            onClick={handleDropZoneClick}
            onContextMenu={handleDropZoneContextMenu}
            handleAddPage={handleAddPage}
            handlePaste={() => handlePaste(0)}
            handleUpdatePageSize={handleUpdatePageSizeDropZone}
            handleUpdatePageBackground={handleUpdatePageBackgroundDropZone}
          />
          <DragDropContext
            onDragEnd={onDragEnd}
            onDragUpdate={handleDragUpdate}
            onDragStart={handleDragStart}
          >
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`${styles.droppable} ${
                    snapshot.isDraggingOver ? styles.draggingOver : ''
                  }`}
                >
                  {pages.map((page, index) => (
                    <React.Fragment key={page.metadata.id}>
                      <Draggable
                        key={page.metadata.id}
                        draggableId={page.metadata.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            id={`draggable-${page.metadata.id}`}
                            className={cn(
                              snapshot.isDragging
                                ? styles.isDragging
                                : undefined,
                            )}
                            data-index={index}
                          >
                            <div
                              className={styles.draggableContainer}
                              id={`draggable-item-${index}`}
                            >
                              <Frame
                                frameId={page.metadata.id}
                                image={page.metadata.thumbnail}
                                pageMetaData={page}
                                className={`${styles.slide} ${
                                  selectedId() === page.metadata.id
                                    ? styles.selected
                                    : ''
                                }`}
                                handleAddPage={handleAddPage}
                                handleCopyPage={handleCopyPage}
                                handleCutPage={handleCutPage}
                                handleDeletePage={handleDeletePage}
                                handleDuplicatePage={handleDuplicatePage}
                                handlePastePage={handlePaste}
                                handleUpdatePageBackground={
                                  handleUpdatePageBackground
                                }
                                handleUpdatePageSize={handleUpdatePageSize}
                                index={index}
                                onClick={() => {
                                  navigateToPage(index);
                                }}
                              />
                              <DropZone
                                key={`dropzone-${index + 1}`}
                                index={index + 1}
                                designId={designId}
                                stage={stageRef.current}
                                isActive={insertLineIndex === index + 1}
                                allPageObjects={allPageObjects}
                                switchStateType={switchStateType}
                                onClick={handleDropZoneClick}
                                onContextMenu={handleDropZoneContextMenu}
                                handleAddPage={handleAddPage}
                                handlePaste={handlePaste}
                                handleUpdatePageSize={
                                  handleUpdatePageSizeDropZone
                                }
                                handleUpdatePageBackground={
                                  handleUpdatePageBackgroundDropZone
                                }
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    </React.Fragment>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <div className={styles.trailingContainer}>
          <button
            className={styles.addButton}
            style={
              {
                '--button-width': `${pages[pages.length - 1].metadata.width}`,
                '--button-height': `${pages[pages.length - 1].metadata.height}`,
              } as React.CSSProperties
            }
            onClick={() => {
              handleAddPage();
            }}
          >
            <Icon name="sprite/plus" />
          </button>
        </div>
      </div>
      <PageBackgroundColorSetupWrapper
        designId={designId}
        pageId={clickedPageId}
      />
      <PageSizeSetupWrapper designId={designId} pageId={clickedPageId} />
    </>
  );
};

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { useMembership } from '@src/shared/queries/useMembershipPermissions';

import styles from './styles.module.css';

const MAX_NUMBER_DOCS = 10;

interface BannerProps {
  numberDocs: number;
}

export const Banner = ({ numberDocs }: BannerProps) => {
  const { checkoutPro, isReady } = useMembership();

  if (!isReady) return null;

  return (
    <div className={styles.banner}>
      <TypographyPoppins type="body" size="M" className={styles.text}>
        {numberDocs >= MAX_NUMBER_DOCS
          ? 'You’ve hit the limit of 10 docs as a Starter user.'
          : 'You’re nearly out. Starter users can create up to 10 docs.'}
      </TypographyPoppins>
      <Button
        type="text"
        label="Try Pro to create unlimited docs"
        onClick={checkoutPro}
      />
    </div>
  );
};

type Props = {
  label: string;
  buttonText: string;
  onClick: () => void;
};

export const GenericBanner = ({ label, buttonText, onClick }: Props) => {
  return (
    <div className={styles.banner}>
      <TypographyPoppins type="body" size="M" className={styles.text}>
        {label}
      </TypographyPoppins>
      <Button type="text" label={buttonText} onClick={onClick} />
    </div>
  );
};

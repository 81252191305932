import { ChangeEvent, useState } from 'react';

import axios from 'axios';

import {
  Button,
  Modal,
  TextField,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { applyPromoCode } from '@api/billing';
import { useMutation } from '@tanstack/react-query';

import styles from './styles.module.css';

interface PromocodeScreenProps {
  closePromoScreen: () => void;
}

export const PromocodeScreen = ({ closePromoScreen }: PromocodeScreenProps) => {
  const [promoCode, setPromoCode] = useState('');
  const [error, setError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(true);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value);
  };

  const clear = () => {
    setPromoCode('');
  };

  const { mutate: applyPromoCodeMutation } = useMutation({
    mutationFn: (promoCode: string) => applyPromoCode(promoCode),
    onSuccess: () => {
      setError('');
      startedSnack({
        label: 'We’ve applied your code',
      });
      closeModal();
      closePromoScreen();
    },
    onError: (error) => {
      if (axios.isAxiosError(error)) {
        if (error.response?.data.state.status === 'promo_no_longer_active') {
          setError('Sorry, the code has expired');
        } else if (
          error.response?.data.state.status === 'promo_already_redeemed'
        ) {
          setError('This promo has already been redeemed');
        } else {
          setError('That didn’t work. Please check your code');
        }
      } else {
        setError('Something went wrong');
      }
    },
  });

  const applyPromocodeSubmit = () => {
    applyPromoCodeMutation(promoCode);
  };

  const onClosePromoClick = () => {
    closeModal();
    closePromoScreen();
  };

  return (
    <Modal
      showModal={isModalOpen}
      handleClose={closeModal}
      className={styles.modal}
      preventBackdropClick={true}
    >
      <div className={styles.container}>
        <TypographyPoppins
          type="headline"
          size="S"
          className={styles.promoTitle}
        >
          Have a code?
        </TypographyPoppins>
        <TextField
          placeholder="Offer code"
          value={promoCode}
          onChange={onChange}
          errorAndSupportingText={error}
          clear={clear}
        />
        <TypographyPoppins type="body" size="M" className={styles.promoBody}>
          If you’ve got a promo or referral code, you can enter it now.
          <br />
          <br />
          Don’t worry, you can come back to this later.
        </TypographyPoppins>
        <div className={styles.btnWrapper}>
          <Button
            label="Do it later"
            type="outlined"
            onClick={onClosePromoClick}
            className={styles.doItLaterBtn}
          />
          <Button
            label="Apply code"
            type="filled"
            isDisabled={!(promoCode.length > 0)}
            onClick={applyPromocodeSubmit}
            className={styles.applyPromoBtn}
          />
        </div>
      </div>
    </Modal>
  );
};

import { useUnit } from 'effector-react';

import { HandIcon, MoveIcon } from '@visualist/design-system';
import {
  SegmentedButton,
  TooltipRadix,
} from '@visualist/design-system/src/components/v2';

import {
  $currentTool,
  $showLibrary,
  changedTool,
} from '@pages/StudioPage/model';

import styles from './styles.module.css';

export const Tools = () => {
  const showLibrary = useUnit($showLibrary);
  const currentTool = useUnit($currentTool);

  return (
    <div className={styles.toolbarSection}>
      <TooltipRadix description="Select">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<MoveIcon height={24} width={24} />}
          onClick={() => changedTool('select')}
          isSelected={currentTool === 'select' && !showLibrary}
        />
      </TooltipRadix>
      <TooltipRadix description="Pan">
        <SegmentedButton
          buttonStyle={styles.button}
          icon={<HandIcon height={24} width={24} />}
          onClick={() => changedTool('move')}
          isSelected={currentTool === 'move' && !showLibrary}
        />
      </TooltipRadix>
    </div>
  );
};

import { BoardShareSheet } from './board';
import { DocShareSheet } from './doc';
import { HubShareSheet } from './hub';

export const ShareSheets = () => {
  return (
    <>
      <HubShareSheet />
      <BoardShareSheet />
      <DocShareSheet />
    </>
  );
};

import {
  Divider,
  EmptyState,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { Board } from '@api/boards';
import { boardDialogOpened } from '@src/entities/dialogs/board/create/model/create';
import { Thumbnail } from '@src/entities/thumbnails/board';

import styles from './styles.module.css';

type Props = {
  boards?: Board[];
  isArchived: boolean;
};

export const Boards = ({ boards, isArchived }: Props) => {
  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <TypographyPoppins type="title" size="M" className={styles.title}>
          Boards
        </TypographyPoppins>
      </div>
      <Divider className={styles.divider} type="long-line" />
      {boards && boards.length > 0 ? (
        <ul className={styles.boards}>
          {boards
            .filter((board) => board.level === 0)
            .map((board, idx) => {
              return (
                <li key={board.id} style={{ listStyleType: 'none' }}>
                  <Thumbnail
                    board={board}
                    onboardingId={
                      idx === 1 ? 'onboarding-board-demo' : undefined
                    }
                    parent={board.parent?.id}
                  />
                </li>
              );
            })}
        </ul>
      ) : (
        <EmptyState
          icon={<Icon name="sprite/sticky-note-soft-colored" size={80} />}
          content="Want to stay organized?"
          label="Create a board"
          action={() => boardDialogOpened()}
          isActionDisabled={isArchived}
        />
      )}
    </div>
  );
};

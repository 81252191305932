/**
 * Centralized configuration for the Magic Studio mask tool
 *
 * All settings related to the mask tool should be defined here
 * to make it easier to update values in one place.
 */

export const MASK_CONFIG = {
  // Brush settings
  DEFAULT_BRUSH_SIZE: 25,
  MAX_BRUSH_SIZE: 60,

  // Colors
  FILL_COLOR: '#FAD7FF',
  //FILL_COLOR: ctx.createLinearGradient(0, 0, 280, 0),
  ERASE_COLOR: 'rgba(0, 0, 0, 1)',

  // Opacity
  FILL_OPACITY: 1,
  BRUSH_OPACITY: 0.7,
  CANVAS_OPACITY: 1.0,

  // Canvas
  BLEND_MODE: 'normal',

  // Mask detection
  OPACITY_THRESHOLD: 10, // Even very faint marks will be part of the mask

  // Visual properties
  BRUSH_BORDER_COLOR: 'rgba(139, 69, 19, 1)',
  ADD_BRUSH_GRADIENT:
    'linear-gradient(90deg, #FFF7FB 0%, #FAD7FF 36%, #FFFBFF 100%)',
  ERASE_BRUSH_BG: 'rgba(198, 49, 49, 0.3)',
};

export const generateConfigCssVariables = {
  '--brush-border-color': MASK_CONFIG.BRUSH_BORDER_COLOR,
  '--add-brush-gradient': MASK_CONFIG.ADD_BRUSH_GRADIENT,
  '--erase-brush-bg': MASK_CONFIG.ERASE_BRUSH_BG,
  '--brush-opacity': MASK_CONFIG.BRUSH_OPACITY.toString(),
  '--canvas-blend-mode': MASK_CONFIG.BLEND_MODE,
  '--canvas-opacity': MASK_CONFIG.CANVAS_OPACITY.toString(),
};

/**
 * Utility function to convert canvas data to a binary mask
 * This can be updated if the brush color or properties change
 */
export const canvasDataToMask = (imageData: ImageData): void => {
  const data = imageData.data;
  const OPACITY_THRESHOLD = MASK_CONFIG.OPACITY_THRESHOLD;

  for (let i = 0; i < data.length; i += 4) {
    // Alpha channel is at index i+3
    if (data[i + 3] > OPACITY_THRESHOLD) {
      // Set to white (255, 255, 255) with full opacity
      data[i] = 255; // R
      data[i + 1] = 255; // G
      data[i + 2] = 255; // B
      data[i + 3] = 255; // Alpha
    } else {
      // Set to black (0, 0, 0) with full opacity
      data[i] = 0;
      data[i + 1] = 0;
      data[i + 2] = 0;
      data[i + 3] = 255;
    }
  }
};

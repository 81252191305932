import { createEvent, createStore, sample } from 'effector';

export const fileCopiesReceived =
  createEvent<{ board_id: string; block_id: string }[]>();

export const boardSelected = createEvent<string>();
export const boardsCleared = createEvent();

export const $selectedBoards = createStore<string[]>([]);
export const $boardsToCopyFile = createStore<string[]>([]);
export const $boardsToRemoveFile = createStore<
  { boardId: string; blockId: string }[]
>([]);
export const $boardsWithFileCopies = createStore<
  { board_id: string; block_id: string }[]
>([]);

sample({
  clock: fileCopiesReceived,
  fn: (boards) => boards.map(({ board_id }) => board_id),
  target: $selectedBoards,
});

sample({
  clock: fileCopiesReceived,
  target: $boardsWithFileCopies,
});

sample({
  clock: boardSelected,
  source: $selectedBoards,
  fn: (boards, id) =>
    boards.includes(id)
      ? boards.filter((boardId) => boardId !== id)
      : [...boards, id],
  target: $selectedBoards,
});

const syncBoards = sample({
  clock: boardSelected,
  source: {
    selectedBoards: $selectedBoards,
    copiedList: $boardsWithFileCopies,
    addList: $boardsToCopyFile,
    rmList: $boardsToRemoveFile,
  },
  fn: ({ selectedBoards, copiedList, addList, rmList }, boardId) => {
    const isSelected = selectedBoards.includes(boardId);
    const isCopied = copiedList.some((b) => b.board_id === boardId);
    const blockId = copiedList.find((b) => b.board_id === boardId)?.block_id;

    const isInAdd = addList.includes(boardId);
    const newToAdd =
      isSelected && !isCopied && !isInAdd
        ? [...addList, boardId]
        : !isSelected
        ? addList.filter((id) => id !== boardId)
        : addList;

    const isInRm = rmList.some((b) => b.boardId === boardId);
    const newToRemove =
      !isSelected && blockId && !isInRm
        ? [...rmList, { boardId, blockId }]
        : rmList.filter((b) => b.boardId !== boardId);

    return { newToAdd, newToRemove };
  },
});

sample({
  clock: syncBoards,
  fn: ({ newToAdd }) => newToAdd,
  target: $boardsToCopyFile,
});

sample({
  clock: syncBoards,
  fn: ({ newToRemove }) => newToRemove,
  target: $boardsToRemoveFile,
});

sample({
  clock: boardsCleared,
  fn: () => [],
  target: [$boardsToCopyFile, $boardsToRemoveFile],
});

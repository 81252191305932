import { useEffect, useRef, useState } from 'react';

import { useUnit } from 'effector-react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Stage } from 'konva/lib/Stage';
import {
  Arc,
  Arrow,
  Circle,
  Ellipse,
  Group,
  Line,
  Rect,
  RegularPolygon,
  Ring,
  Star,
  Transformer,
  Wedge,
} from 'react-konva';

import { ShapeJSON } from '@api/designs';
import { SHAPE, SHAPE_GROUP, TRANSFORMER } from '@pages/StudioPage/constants';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import { useSnap } from '@pages/StudioPage/hooks/useSnap';
import { DesignType } from '@pages/StudioPage/lib/design';
import {
  $currentTool,
  $dragSelection,
  $selectedObjectIdInGroup,
  $selectedObjectIds,
  changedTool,
  selectObjectIdInGroup,
  selectObjectIds,
} from '@pages/StudioPage/model';
import { $isShiftPressed } from '@pages/StudioPage/shift-key-tracking';
import { isArrowNode, isLineNode } from '@pages/StudioPage/utils';
import { TERTIARY_40 } from '@src/shared/constants/colours';

export type shapeType =
  | 'rectangle'
  | 'circle'
  | 'line'
  | 'arrow'
  | 'hexagon'
  | 'star'
  | 'ellipse'
  | 'ring'
  | 'wedge'
  | 'arc';

type ShapeProps = {
  id: string;
  shape: ShapeJSON;
  designId: string;
  stage: Stage | null;
  isClick: boolean;
  isInPagesMode: boolean;
  updateShape: (...updates: Parameters<DesignType['updateShape']>) => void;
};

export const Shape = (props: ShapeProps) => {
  const shapeRef = useRef(null);
  const transformerRef = useRef<Konva.Transformer>(null);
  const hoverTransformerRef = useRef<Konva.Transformer>(null);
  const selectedObjectIds = useUnit($selectedObjectIds);
  const selectedObjectIdInGroup = useUnit($selectedObjectIdInGroup);
  const currentTool = useUnit($currentTool);
  const dragSelection = useUnit($dragSelection);
  const isShiftPressed = useUnit($isShiftPressed);

  const { updateShape } = props;
  const {
    id,
    x,
    y,
    opacity,
    rotation,
    metadata: {
      fill,
      stroke,
      height,
      width,
      radius,
      type,
      strokeWidth,
      angle,
      innerRadius,
      numPoints,
      outerRadius,
      radiusX,
      radiusY,
      sides,
      points,
      pointerWidth,
      pointerLength,
      groupId,
    },
  } = props.shape;

  const [isHovered, setIsHovered] = useState(false);
  const isSelected = selectedObjectIds.has(id) && selectedObjectIds.size === 1;
  const isInMassSelection =
    selectedObjectIds.has(id) && selectedObjectIds.size > 1;
  const isSelectedInGroup = selectedObjectIdInGroup === id;
  const [isDragging, setIsDragging] = useState(false);
  const canDrag = currentTool === 'select';
  const positionBeforeDrag = useRef({ x, y });
  const { onMoveCheckSnap, onEndCheckSnap, onMoveAnchorSnap } = useSnap({
    usePageGuides: props.isInPagesMode,
  });
  const isSelectedLine =
    selectedObjectIds.has(id) &&
    selectedObjectIds.size === 1 &&
    (type === 'line' || type === 'arrow');
  const isSelectedLineInGroup =
    selectedObjectIdInGroup === id && (type === 'line' || type === 'arrow');

  const { getGroupObjectsByObjectId, areAllInSameGroup } = useStudioDesign(
    props.designId,
  );

  const removeSelectedShapeTransformer = () => {
    if (transformerRef?.current) {
      // @ts-ignore
      transformerRef.current.nodes([]);
      // @ts-ignore
      transformerRef.current.getLayer().batchDraw();
    }
  };

  const redrawTransformer = () => {
    if (transformerRef?.current) {
      // @ts-ignore
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.moveToTop();
      // @ts-ignore
      transformerRef.current.getLayer().batchDraw();
    }
  };

  useEffect(() => {
    if ((isSelected || isSelectedInGroup) && transformerRef.current) {
      redrawTransformer();
    } else if (transformerRef.current && !isSelected)
      removeSelectedShapeTransformer();

    return () => {
      if (transformerRef?.current) removeSelectedShapeTransformer();
    };
  }, [isSelected, isSelectedInGroup, currentTool]);

  const removeHoveredShapeTransformer = () => {
    // @ts-ignore
    hoverTransformerRef.current.nodes([]);
    // @ts-ignore
    hoverTransformerRef.current.getLayer().batchDraw();
  };

  useEffect(() => {
    // Used to update the transformer when transforming
    if ((isHovered || isInMassSelection) && hoverTransformerRef.current) {
      // we need to attach transformer manually
      // @ts-ignore
      hoverTransformerRef.current.nodes([shapeRef.current]);
      // @ts-ignore
      hoverTransformerRef.current.getLayer().batchDraw();
    } else if (!(isHovered || isInMassSelection) && hoverTransformerRef.current)
      removeHoveredShapeTransformer();

    return () => {
      if (hoverTransformerRef.current) removeHoveredShapeTransformer();
    };
  }, [isHovered, isInMassSelection]);

  const updateTransformState = () => {
    // Do not update state in these because each state udpate would count as an undo redo. The transformer will scale the object on its own
    if (!shapeRef.current) return;

    const s = props.stage;
    if ((type === 'line' || type === 'arrow') && points && s) {
      const lineNode = s.findOne(`#${id}`);
      if (!lineNode || !isLineNode(lineNode) || !isArrowNode(lineNode)) {
        console.warn(`Node with ID ${id} is not a Line or Arrow.`);
        return;
      }

      // Get new scale factors
      const scaleX = lineNode.scaleX();
      const scaleY = lineNode.scaleY();

      // Reset scale (we don't want scale to affect the stroke width)
      lineNode.scaleX(1);
      lineNode.scaleY(1);

      // Modify the points array to extend/shrink the line
      const newPoints = [...points];
      newPoints[2] = points[0] + (points[2] - points[0]) * scaleX;
      newPoints[3] = points[1] + (points[3] - points[1]) * scaleY;

      lineNode.points(newPoints);
    }

    setIsDragging(false);
  };

  const onTransform = (e: KonvaEventObject<Event>) => {
    if (!shapeRef.current) return;

    onEndCheckSnap(e);
    setIsDragging(false);

    const newWidth = e.currentTarget.width() * e.currentTarget.scaleX();
    const newHeight = e.currentTarget.height() * e.currentTarget.scaleY();
    const newRotation = e.currentTarget.rotation();

    const updatedShape = calculateShapeMetadata(
      newWidth,
      newHeight,
      newRotation,
      e.currentTarget.x(),
      e.currentTarget.y(),
    );

    updateShape({
      ...updatedShape,
    });

    e.currentTarget.scaleX(1);
    e.currentTarget.scaleY(1);
  };

  const calculateShapeMetadata = (
    newWidth: number,
    newHeight: number,
    rotation: number,
    currentX: number,
    currentY: number,
  ) => {
    let newX = currentX;
    let newY = currentY;
    let shapeMetadata = {};
    if (type === 'circle' || type === 'hexagon' || type === 'wedge') {
      const radius = Math.max(newWidth, newHeight) / 2 || 1;
      shapeMetadata = { radius: radius };
    } else if (type === 'ellipse') {
      const radiusX = newWidth / 2 || 1;
      const radiusY = newHeight / 2 || 1;
      shapeMetadata = {
        radiusX,
        radiusY,
      };
    } else if (type === 'star' || type === 'ring' || type === 'arc') {
      const outerRadius = Math.max(newWidth, newHeight) / 2 || 1;
      const innerRadius = outerRadius * 0.5;
      shapeMetadata = {
        innerRadius: innerRadius,
        outerRadius: outerRadius,
      };
    } else if (type === 'rectangle') {
      shapeMetadata = { width: newWidth, height: newHeight };
    } else if (type === 'line' || type === 'arrow') {
      shapeMetadata = {};
      const s = props.stage;
      if (s) {
        const lineNode = s.findOne(`#${id}`);
        if (lineNode && (isLineNode(lineNode) || isArrowNode(lineNode))) {
          shapeMetadata = { points: lineNode.points() };
          newX = lineNode.x();
          newY = lineNode.y();
        }
      }
    } else {
      shapeMetadata = {};
    }

    const updatedShape = {
      id,
      x: newX,
      y: newY,
      rotation,
      scale: {
        x: newX,
        y: newY,
      },
      metadata: {
        type,
        fill: fill,
        stroke: stroke,
        strokeWidth: strokeWidth,
        sides: sides,
        numPoints: numPoints,
        angle: angle,
        points: points,
        pointerLength: pointerLength,
        pointerWidth: pointerWidth,
        ...shapeMetadata,
      },
    };
    return updatedShape;
  };

  const dragStarted = () => {
    if (isInMassSelection && !isSelectedInGroup) return;
    onSelectShape();
    setIsDragging(true);
    positionBeforeDrag.current = {
      x,
      y,
    };
  };

  const updatePositionState = (e: KonvaEventObject<DragEvent>) => {
    // Drag is happening
    if (!shapeRef.current) return;

    onMoveCheckSnap({ e, selectedObjectIds, selectedObjectIdInGroup });
  };

  const updateDragPosition = (e: KonvaEventObject<DragEvent>) => {
    // Drag has ended
    onEndCheckSnap(e);
    if (!shapeRef.current) return;
    if (isSelected || isSelectedInGroup) {
      onSelectShape();
    }

    setIsDragging(false);

    const newX = e.currentTarget.x();
    const newY = e.currentTarget.y();

    const updatedShape = calculateShapePosition(newX, newY);

    updateShape(updatedShape);
  };

  const calculateShapePosition = (newX: number, newY: number) => {
    let shapeMetadata = {};
    if (type === 'circle' || type === 'hexagon' || type === 'wedge') {
      shapeMetadata = { radius };
    } else if (type === 'ellipse') {
      shapeMetadata = { radiusX, radiusY };
    } else if (type === 'star' || type === 'ring' || type === 'arc') {
      shapeMetadata = { innerRadius, outerRadius };
    } else if (type === 'rectangle') {
      shapeMetadata = { width, height };
    } else {
      shapeMetadata = {};
    }

    const updatedShape = {
      id,
      x: newX,
      y: newY,
      rotation: rotation,
      scale: {
        x: newX,
        y: newY,
      },
      metadata: {
        type,
        fill,
        stroke,
        strokeWidth,
        sides,
        numPoints,
        angle,
        points,
        ...shapeMetadata,
      },
    };

    return updatedShape;
  };

  const mouseEntersShape = () => {
    if (currentTool !== 'move') setIsHovered(true);
  };

  const mouseLeavesShape = () => {
    setIsHovered(false);
  };

  const onSelectShape = () => {
    if (
      (currentTool === 'select' ||
        (currentTool === 'add-shape' && props.isClick)) &&
      !isDragging
    ) {
      const groupObjects = getGroupObjectsByObjectId(id);
      const groupObjectsSet = new Set(groupObjects);
      if (isShiftPressed) {
        const newSelectedImages = new Set(selectedObjectIds);

        if (newSelectedImages.has(id)) {
          groupObjectsSet.forEach((objectId) =>
            newSelectedImages.delete(objectId),
          );
        } else {
          groupObjectsSet.forEach((objectId) =>
            newSelectedImages.add(objectId),
          );
        }

        selectObjectIds(newSelectedImages);
        selectObjectIdInGroup(null);
        return;
      }

      changedTool('select');
      if (!canDrag) return;

      if (
        groupId &&
        areAllInSameGroup(selectedObjectIds) &&
        selectedObjectIds.has(id)
      ) {
        selectObjectIdInGroup(id);
      }
      selectObjectIds(groupObjectsSet);
    }
  };

  const updateLinePosition = (
    e: KonvaEventObject<DragEvent>,
    xIndex: number,
    yIndex: number,
  ) => {
    const s = props.stage;
    if (points && s) {
      const newPoints = [...points];
      newPoints[xIndex] = e.target.x() - x;
      newPoints[yIndex] = e.target.y() - y;
      const lineNode = s.findOne(`#${id}`);
      if (lineNode && (isLineNode(lineNode) || isArrowNode(lineNode))) {
        lineNode?.points(newPoints);
      }
      onMoveCheckSnap({ e, selectedObjectIds, selectedObjectIdInGroup });
    }
  };

  const updateLinePositionCommit = (e: KonvaEventObject<DragEvent>) => {
    const s = props.stage;
    if (s) {
      const lineNode = s.findOne(`#${id}`);
      if (lineNode && (isLineNode(lineNode) || isArrowNode(lineNode))) {
        const newMetadataPoints = [
          0,
          0,
          lineNode.points()[2] - lineNode.points()[0],
          lineNode.points()[3] - lineNode.points()[1],
        ];
        updateShape({
          ...props.shape,
          x: lineNode.x() + lineNode.points()[0],
          y: lineNode.y() + lineNode.points()[1],
          metadata: { ...props.shape.metadata, points: newMetadataPoints },
        });
      }
    }
    onEndCheckSnap(e);
  };

  return (
    <>
      <Group name={SHAPE_GROUP}>
        {type === 'rectangle' && (
          <Rect
            ref={shapeRef}
            {...props}
            draggable={canDrag}
            onClick={onSelectShape}
            onTap={onSelectShape}
            onMouseEnter={mouseEntersShape}
            onMouseLeave={mouseLeavesShape}
            onTransform={updateTransformState}
            onTransformEnd={onTransform}
            onDragEnd={updateDragPosition}
            onDragMove={updatePositionState}
            onDragStart={dragStarted}
            rotation={rotation}
            opacity={opacity}
            x={x}
            y={y}
            height={height}
            width={width}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            name={SHAPE}
          />
        )}
        {type === 'circle' && (
          <Circle
            ref={shapeRef}
            {...props}
            draggable={canDrag}
            onClick={onSelectShape}
            onTap={onSelectShape}
            onMouseEnter={mouseEntersShape}
            onMouseLeave={mouseLeavesShape}
            onTransform={updateTransformState}
            onTransformEnd={onTransform}
            onDragEnd={updateDragPosition}
            onDragMove={updatePositionState}
            onDragStart={dragStarted}
            rotation={rotation}
            opacity={opacity}
            x={x}
            y={y}
            radius={radius}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            name={SHAPE}
          />
        )}
        {type === 'hexagon' && radius && sides && (
          <RegularPolygon
            ref={shapeRef}
            {...props}
            draggable={canDrag}
            onClick={onSelectShape}
            onTap={onSelectShape}
            onMouseEnter={mouseEntersShape}
            onMouseLeave={mouseLeavesShape}
            onTransform={updateTransformState}
            onTransformEnd={onTransform}
            onDragEnd={updateDragPosition}
            onDragMove={updatePositionState}
            onDragStart={dragStarted}
            rotation={rotation}
            x={x}
            y={y}
            radius={radius}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            sides={sides}
            name={SHAPE}
          />
        )}
        {type === 'ellipse' && radiusX && radiusY && (
          <Ellipse
            ref={shapeRef}
            {...props}
            draggable={canDrag}
            onClick={onSelectShape}
            onTap={onSelectShape}
            onMouseEnter={mouseEntersShape}
            onMouseLeave={mouseLeavesShape}
            onTransform={updateTransformState}
            onTransformEnd={onTransform}
            onDragEnd={updateDragPosition}
            onDragMove={updatePositionState}
            onDragStart={dragStarted}
            rotation={rotation}
            x={x}
            y={y}
            radiusX={radiusX}
            radiusY={radiusY}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            name={SHAPE}
          />
        )}
        {type === 'star' && innerRadius && outerRadius && numPoints && (
          <Star
            ref={shapeRef}
            {...props}
            draggable={canDrag}
            onClick={onSelectShape}
            onTap={onSelectShape}
            onMouseEnter={mouseEntersShape}
            onMouseLeave={mouseLeavesShape}
            onTransform={updateTransformState}
            onTransformEnd={onTransform}
            onDragEnd={updateDragPosition}
            onDragMove={updatePositionState}
            onDragStart={dragStarted}
            rotation={rotation}
            x={x}
            y={y}
            numPoints={numPoints}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            name={SHAPE}
          />
        )}
        {type === 'wedge' && radius && angle && (
          <Wedge
            ref={shapeRef}
            {...props}
            draggable={canDrag}
            onClick={onSelectShape}
            onTap={onSelectShape}
            onMouseEnter={mouseEntersShape}
            onMouseLeave={mouseLeavesShape}
            onTransform={updateTransformState}
            onTransformEnd={onTransform}
            onDragEnd={updateDragPosition}
            onDragMove={updatePositionState}
            onDragStart={dragStarted}
            rotation={rotation}
            x={x}
            y={y}
            radius={radius}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            angle={angle}
            name={SHAPE}
          />
        )}
        {type === 'ring' && innerRadius && outerRadius && (
          <Ring
            ref={shapeRef}
            {...props}
            draggable={canDrag}
            onClick={onSelectShape}
            onTap={onSelectShape}
            onMouseEnter={mouseEntersShape}
            onMouseLeave={mouseLeavesShape}
            onTransform={updateTransformState}
            onTransformEnd={onTransform}
            onDragEnd={updateDragPosition}
            onDragMove={updatePositionState}
            onDragStart={dragStarted}
            rotation={rotation}
            x={x}
            y={y}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            name={SHAPE}
          />
        )}
        {type === 'arc' && innerRadius && outerRadius && angle && (
          <Arc
            ref={shapeRef}
            {...props}
            draggable={canDrag}
            onClick={onSelectShape}
            onTap={onSelectShape}
            onMouseEnter={mouseEntersShape}
            onMouseLeave={mouseLeavesShape}
            onTransform={updateTransformState}
            onTransformEnd={onTransform}
            onDragEnd={updateDragPosition}
            onDragMove={updatePositionState}
            onDragStart={dragStarted}
            rotation={rotation}
            x={x}
            y={y}
            angle={angle}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill={fill}
            stroke={stroke}
            strokeWidth={strokeWidth}
            name={SHAPE}
          />
        )}
        {type === 'line' && points && (
          <>
            <Line
              ref={shapeRef}
              {...props}
              draggable={canDrag}
              onClick={onSelectShape}
              onTap={onSelectShape}
              onMouseEnter={mouseEntersShape}
              onMouseLeave={mouseLeavesShape}
              onTransform={updateTransformState}
              onTransformEnd={onTransform}
              onDragEnd={updateDragPosition}
              onDragMove={updatePositionState}
              onDragStart={dragStarted}
              rotation={rotation}
              x={x}
              y={y}
              points={points}
              stroke={stroke}
              strokeWidth={strokeWidth}
              name={SHAPE}
            />
            {(isSelectedLine || isSelectedInGroup) && !isDragging && (
              <>
                <Circle
                  id={props.id}
                  name="lineTransformerCircle"
                  x={x + points[0]}
                  y={y + points[1]}
                  radius={7}
                  fill="#FFFFFF"
                  strokeWidth={2}
                  stroke={TERTIARY_40}
                  draggable
                  onDragMove={(e) => {
                    updateLinePosition(e, 0, 1);
                  }}
                  onDragEnd={updateLinePositionCommit}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
                <Circle
                  id={props.id}
                  name="lineTransformerCircle"
                  x={x + points[2]}
                  y={y + points[3]}
                  radius={7}
                  fill="#FFFFFF"
                  strokeWidth={2}
                  stroke={TERTIARY_40}
                  draggable
                  onDragMove={(e) => {
                    updateLinePosition(e, 2, 3);
                  }}
                  onDragEnd={updateLinePositionCommit}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
              </>
            )}
          </>
        )}
        {type === 'arrow' && points && (
          <>
            <Arrow
              ref={shapeRef}
              {...props}
              draggable={canDrag}
              onClick={onSelectShape}
              onTap={onSelectShape}
              onMouseEnter={mouseEntersShape}
              onMouseLeave={mouseLeavesShape}
              onTransform={updateTransformState}
              onTransformEnd={onTransform}
              onDragEnd={updateDragPosition}
              onDragMove={updatePositionState}
              onDragStart={dragStarted}
              rotation={rotation}
              x={x}
              y={y}
              points={points}
              stroke={stroke}
              strokeWidth={strokeWidth}
              pointerLength={pointerLength}
              pointerWidth={pointerWidth}
              name={SHAPE}
            />
            {(isSelectedLine || isSelectedInGroup) && !isDragging && (
              <>
                <Circle
                  id={props.id}
                  name="lineTransformerCircle"
                  x={x + points[0]}
                  y={y + points[1]}
                  radius={7}
                  fill="#FFFFFF"
                  strokeWidth={2}
                  stroke={TERTIARY_40}
                  draggable
                  onDragMove={(e) => {
                    updateLinePosition(e, 0, 1);
                  }}
                  onDragEnd={updateLinePositionCommit}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
                <Circle
                  id={props.id}
                  name="lineTransformerCircle"
                  x={x + points[2]}
                  y={y + points[3]}
                  radius={7}
                  fill="#FFFFFF"
                  strokeWidth={2}
                  stroke={TERTIARY_40}
                  draggable
                  onDragMove={(e) => {
                    updateLinePosition(e, 2, 3);
                  }}
                  onDragEnd={updateLinePositionCommit}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                />
              </>
            )}
          </>
        )}
      </Group>
      {(isSelected && !isInMassSelection && !isSelectedLine) ||
      (isSelectedInGroup && !isSelectedLineInGroup) ? (
        // Transformer for rotating and resizing
        <Transformer
          rotationSnaps={[0, 90, 180, 270]}
          ref={transformerRef}
          borderStroke={TERTIARY_40}
          anchorStroke={TERTIARY_40}
          anchorCornerRadius={100}
          rotateAnchorOffset={58}
          visible={!isDragging}
          name={TRANSFORMER}
          enabledAnchors={
            type === 'circle' ||
            type === 'hexagon' ||
            type === 'star' ||
            type === 'wedge' ||
            type === 'ring' ||
            type === 'arc'
              ? ['top-left', 'top-right', 'bottom-left', 'bottom-right'] // Only corner anchors for circle, hexagon
              : [
                  'top-left',
                  'top-right',
                  'bottom-left',
                  'bottom-right',
                  'middle-left',
                  'middle-right',
                  'top-center',
                  'bottom-center',
                ] // All anchors for other shapes
          }
          anchorDragBoundFunc={(oldPos, newPos) =>
            onMoveAnchorSnap(
              oldPos,
              newPos,
              shapeRef,
              selectedObjectIds,
              selectedObjectIdInGroup,
            )
          }
        />
      ) : null}
      {(!isSelected && isHovered && !dragSelection) || isInMassSelection ? (
        // Transformer for hovering selection
        <Transformer
          rotateEnabled={false}
          ref={hoverTransformerRef}
          keepRatio={false}
          enabledAnchors={[]}
          resizeEnabled={false}
          borderStroke={TERTIARY_40}
          anchorStroke={TERTIARY_40}
          name={TRANSFORMER}
        />
      ) : null}
    </>
  );
};

import { ActionFilter } from '@pages/action-items/modal';

export const ALL_HUBS_QUERY = 'all_hubs';
export const HUB_QUERY = 'hub';
export const HUBS_SUGGESTIONS_QUERY = 'hubs_suggestions';
export const ALL_BOOKMARKS_QUERY = 'all_bookmarks';
export const NOTES_QUERY = 'notes';
export const BOARD_IMAGES_QUERY = 'board_images';
export const ALL_BOARD_IMAGES_QUERY = 'all_board_images';
export const IMAGE_QUERY = 'image';
export const BOOKMARKS_OF_HUB_QUERY = 'bookmarks_of_hub';
export const BOARDS_TREE_QUERY = 'boards_tree';
export const BOARD_QUERY = 'board';
export const ALL_BOARDS_QUERY = 'all_boards';
export const BOARDS_SUGGESTIONS_QUERY = 'boards_suggestions';
export const BOARDS_OF_HUB_QUERY = 'boards_of_hub';
export const NOTES_REPLIES_QUERY = 'notes_comments';
export const LIBRARY_QUERY = 'library';
export const MEMBERS_INVITATION_QUERY = 'members_invitation';
export const MEMBERS_INVITATION_HUB_QUERY = 'members_invitation_hub';
export const MEMBERS_INVITATION_DOC_QUERY = 'members_invitation_doc';
export const MEMBERS_SUGGESTIONS_QUERY = 'members_suggestions';
export const MEMBERS_SUGGESTIONS_HUB_QUERY = 'members_suggestions_hub';
export const MEMBERS_SUGGESTIONS_DOC_QUERY = 'members_suggestions_doc';
export const ALL_DOCS_QUERY = 'all_docs';
export const DOCS_OF_HUB_QUERY = 'docs_of_hub';
export const DOC_QUERY = 'doc';
export const GET_BLOCKS_QUERY_DOCS_SIDEBAR = 'get_blocks_docs_sidebar';
export const TAGS_SUGGESTIONS_QUERY = 'tags_suggestions';
export const BILLING_INFO_QUERY = 'billing_info';
export const MEMBERSHIP_PRODUCT_INFO_QUERY = 'membership_product_info';
export const HUB_CREDIT_QUERY = 'hub_credit_query';
export const HUB_PRODUCTS_QUERY = 'hub_products_query';
export const MANAGE_BILLING_QUERY = 'manage_billing_query';
export const ALL_FILE_COPIES = 'all_file_copies';
export const REFUNDED_HUB_CREDITS_QUERY = 'refunded_hub_credits';
export const DOCS_OF_BOARD_QUERY = 'docs_of_board_query';
export const COLOURS_OF_BLOCK_QUERY = 'colours_of_block_query';

// New Studio
export const GET_BLOCKS_QUERY = 'get_blocks_studio';
export const IMAGE_PREVIEW_MUTATION = 'image_preview_mutation';

// Tag Modal
export const BLOCKS_TAGS_QUERY = 'blocks_tags';

// TODO move to query-keys
export const actions = {
  all: ['actions'] as const,
  filtered: (filters: ActionFilter) => [...actions.all, filters],
  hub: (id: string) => [...actions.all, id] as const,
  action: (id: string) => [...actions.all, id] as const,
};

import cn from 'classnames';

import {
  Button,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import { archiveBoardIdSelected } from '@pages/BoardPage/model/archive';
import { archiveHubIdSelected } from '@pages/HubPage/model/archive';
import { unarchiveDialogOpened } from '@src/entities/dialogs/unarchive/model';
import { archiveDocIdSelected } from '@src/entities/doc/archive';
import {
  entityTypeSelected,
  InputType,
} from '@src/shared/utils/get-entity-type';

import styles from './styles.module.css';

type Props = {
  entity: InputType;
  id: string;
  className?: string;
};

export const ArchiveBanner = ({ entity, id, className }: Props) => {
  const unarchive = () => {
    if (entity === 'Hub' && id) {
      archiveHubIdSelected(id);
    } else if (entity === 'Board' && id) {
      archiveBoardIdSelected(id);
    } else if (entity === 'Doc' && id) {
      archiveDocIdSelected(id);
    }

    entityTypeSelected(entity);
    unarchiveDialogOpened();
  };

  return (
    <div className={cn(styles.banner, className)}>
      <TypographyPoppins type="body" size="M" className={styles.text}>
        This page is archived.
      </TypographyPoppins>
      <Button type="ghost" label="Unarchive now" onClick={unarchive} />
    </div>
  );
};

import { ResponseInvitee as ResponseBoardInvitee } from '@api/boards';
import { ResponseInvitee as ResponseDocInvitee } from '@api/docs';
import { Member } from '@api/docs';
import { ResponseInvitee } from '@api/hubs';
import { Member as MemberHub } from '@api/hubs';
import { Permissions } from '@api/users';

import { InvitedUser } from '../user/invited-user';
import { Owner } from '../user/owner';

import styles from './styles.module.css';

type InviteesProps = {
  invitees: ResponseInvitee | ResponseBoardInvitee | ResponseDocInvitee;
  remove: (member: any) => void;
  type?: 'doc';
  handleChangePermissions?: ({
    permissions,
    member,
  }: {
    permissions: Permissions[];
    member: Member | MemberHub;
  }) => void;
  currentPermissions?: Permissions[];
  error?: any;
};

export const Invitees = ({
  invitees,
  remove,
  type,
  currentPermissions,
  handleChangePermissions,
  error,
}: InviteesProps) => {
  const isLastMember = invitees.members.length === 1;
  const editors = invitees.members.filter((member) =>
    member.permissions.includes('edit'),
  );
  const lastEditorId = editors.length === 1 ? editors[0].id : null;

  return (
    <>
      <ul className={styles.guestList}>
        {invitees?.members?.map((invitee: any) => {
          if (invitee.is_owner) {
            return (
              <Owner
                key={invitee.id}
                user={invitee}
                permissions={invitee.permissions}
                removeMember={remove}
                type={type}
                currentPermissions={currentPermissions}
                handleChangePermissions={handleChangePermissions}
                error={error}
                isLastMember={isLastMember}
                lastEditorId={lastEditorId}
              />
            );
          } else {
            return (
              <InvitedUser
                key={invitee.id}
                user={invitee}
                permissions={invitee.permissions}
                removeMember={remove}
                type={type}
                currentPermissions={currentPermissions}
                handleChangePermissions={handleChangePermissions}
                error={error}
                isLastMember={isLastMember}
                lastEditorId={lastEditorId}
              />
            );
          }
        })}
      </ul>
    </>
  );
};

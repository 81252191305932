import { Dispatch, MouseEvent, ReactNode, useState } from 'react';

import cn from 'classnames';

import * as Tooltip from '@radix-ui/react-tooltip';

import { Button } from '../Buttons';
import { TypographyPoppins } from '../Styles/Typography/TypographyPoppins';

import styles from './styles.module.css';

type Action = {
  title: string;
  action: () => void;
};

type Props = {
  children: ReactNode;
  side?: 'bottom' | 'left' | 'right' | 'top';
  title?: ReactNode | string;
  description: ReactNode | string;
  sideOffset?: number;
  delayDuration?: number;
  classNameTrigger?: string;
  type?: 'rich';
  width?: number;
  actions?: Action[];
  descriptionSize?: 'S' | 'M' | 'L';
};

let globalSetOpen: Dispatch<boolean>;

export const TooltipRadix = ({
  children,
  side = 'top',
  title,
  description,
  sideOffset = 8,
  delayDuration = 100,
  classNameTrigger,
  type,
  width,
  actions,
  descriptionSize,
}: Props) => {
  const [open, onOpenChange] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);

  const onTouchStart = () => {
    if (timerId) clearTimeout(timerId);
    if (globalSetOpen) globalSetOpen(false);
    const id = setTimeout(() => {
      onOpenChange(true);
      globalSetOpen = onOpenChange;
    }, 500);
    setTimerId(id);
  };

  const onTouchEnd = () => {
    if (timerId) clearTimeout(timerId);
    setTimerId(null);
  };

  const onContextMenu = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  };

  return (
    <Tooltip.Provider delayDuration={delayDuration}>
      <Tooltip.Root open={open} onOpenChange={onOpenChange}>
        <Tooltip.Trigger
          asChild
          className={classNameTrigger}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          onContextMenu={onContextMenu}
        >
          <span style={{ display: 'flex' }}>{children}</span>
        </Tooltip.Trigger>
        <Tooltip.Portal>
          <div className={styles.wrapper}>
            <Tooltip.Content
              style={{
                width,
              }}
              className={cn(styles.tooltip, {
                [styles.rich]: type === 'rich',
              })}
              side={side}
              sideOffset={sideOffset}
            >
              {type === 'rich' && title && (
                <TypographyPoppins type="title" size="S">
                  {title}
                </TypographyPoppins>
              )}
              <TypographyPoppins
                type="body"
                size={
                  descriptionSize
                    ? descriptionSize
                    : type === 'rich'
                    ? 'M'
                    : 'S'
                }
              >
                {description}
              </TypographyPoppins>
              {Array.isArray(actions) && (
                <div className={styles.action}>
                  {actions.map((action) => {
                    return (
                      <Button
                        key={action.title}
                        type="text"
                        label={action.title}
                        onClick={action.action}
                      />
                    );
                  })}
                </div>
              )}
            </Tooltip.Content>
          </div>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};

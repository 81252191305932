import axios from 'axios';
import qs from 'query-string';

import { User } from '@src/AppContext';
import { getRoute } from '@src/routes';
import { REDIRECT_TO_HUB_OR_BOARD_EXISTING_USER } from '@src/shared/constants/variables-local-storage';
import { deleteCookie, getCookie, setCookie } from '@src/shared/utils';
import { showingDemoWarning } from '@src/widgets/onboarding/model';

import { BoardLvl1, BoardLvl2, BoardLvl3 } from './designs';
import { Permissions } from './users';

export type BlockType = 'Image' | 'Set' | 'Colour palette';
export type BlockSource =
  | 'user'
  | 'block'
  | 'palette'
  | 'upload'
  | 'hand'
  | 'set';

type Theme =
  | 'scientific'
  | 'dark'
  | 'light'
  | 'colourful'
  | 'cool'
  | 'warm'
  | 'neutral'
  | 'monochromatic';

export interface Colour {
  hex_color: string;
  id: string;
  main_color: boolean;
  order: number;
  rgb_color: string;
  theme: Theme;
}

export interface File {
  full_size: string;
  small_square_crop: string;
  thumbnail_100: string;
  thumbnail_130: string;
  thumbnail_160: string;
  thumbnail_330: string;
  thumbnail_400: string;
  thumbnail_640: string;
}
export interface Tag {
  id: string;
  name: string;
  tag_source: string;
  count: number | undefined;
}

export type ColorSwatch = {
  hex_color: string;
  hsl_color: string;
  hsv_color: string;
  id: string;
  order: number;
  pixel?: { pixel_x: number | null; pixel_y: number | null };
  rgb_color: string;
  theme: string;
};

export type ColorTheme = {
  block?: string;
  swatches: ColorSwatch[];
  theme: string;
  type?: 'custom_palette';
  theme_create?: boolean;
};

export type ColorCodes = {
  rgb: boolean;
  hex: boolean;
  hsl: boolean;
  hsv: boolean;
};

export interface RawBlockResponse {
  block_source: BlockSource;
  block_type: BlockType;
  board_list: string[];
  boards: Array<BoardLvl1 | BoardLvl2 | BoardLvl3>;
  created_at: string;
  custom_palettes: ColorTheme[];
  default_palettes: ColorTheme[];
  file: File;
  height: number;
  id: string;
  is_removed: boolean;
  name: string | null;
  project_list: unknown[];
  source_data: {
    image_url?: string;
    page_domain?: string;
    page_url?: string;
    saved_date?: string;
    source_id?: string;
  };
  tags: Tag[];
  use_count: number;
  width: number;
  display_color_codes: ColorCodes;
  is_archived: boolean;
  permissions: Permissions[];
}

export interface BlockResponse extends RawBlockResponse {
  custom_palettes: Array<{
    theme: string;
    swatches: ColorSwatch[];
    type: 'custom_palette';
  }>;
}

const api = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

const apiRefresh = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

api.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => {
    // @ts-ignore
    return qs.stringify(params, { arrayFormat: 'repeat' });
  };

  const token = getCookie('access');

  if (!token) return config;

  return {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    },
    withCredentials: true,
  };
});

/**
 * Interceptor for API calls for demo mode
 */

api.interceptors.request.use((config) => {
  if (config.baseURL && /\/demo/.test(config.baseURL) && config.url) {
    // Allow any user request
    if (/\/user\//.test(config.url)) {
      return config;
    }

    // In demo mode throw for any request that is a post for a hub
    if (config.method === 'post' && /\/hubs\/$/.test(config.url)) {
      showingDemoWarning();
      throw Error('This feature is not available in demo mode.');
    }
  }

  return config;
});

// Response interceptor for API calls
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const HTTP_UNAUTHORIZED = 401;
    // @ts-ignore
    if (
      error.response.status === HTTP_UNAUTHORIZED &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = getCookie('refresh');
      try {
        const accessToken = await fetchRefreshToken(refreshToken);
        setCookie('access', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        return api(originalRequest);
      } catch (e) {
        deleteCookie('access');
        deleteCookie('refresh');
        localStorage.removeItem('v_user');

        const pathname = window.location.pathname;
        const authRoutes = [
          getRoute('login'),
          getRoute('signup'),
          getRoute('forgotPassword'),
          getRoute('resetPassword'),
        ].map((route) => route.split(':')[0]); // Get base path before any params

        const isAuthRoute = authRoutes.some((route) =>
          route.includes(pathname),
        );

        if (!isAuthRoute) {
          const currentUrl = window.location.href;
          const isHubLink = currentUrl.includes('/h/');
          const isBoardLink = currentUrl.includes('/b/');

          // The description of the purpose of the logic for saving to local storage, see the file variables-local-storage.ts
          if (isHubLink || isBoardLink) {
            localStorage.setItem(
              REDIRECT_TO_HUB_OR_BOARD_EXISTING_USER,
              currentUrl,
            );
          }
          window.location.href = '/login';
        }
      }
    }
    return Promise.reject(error);
  },
);

export { api };

interface GetImagesParams {
  payload: Record<string, unknown>;
  otherParams?: Record<string, unknown>;
}

export interface GetBlocksParams {
  block_source?: string;
  block_type?: number;
  boards__contains?: string[];
  boards__overlap?: string[];
  created_at_gte?: string;
  created_at_lte?: string;
  created_at?: string;
  h_range?: string[];
  hex_colors?: string;
  is_removed?: string;
  limit?: number;
  offset?: number;
  ordering?: string;
  projects__contains?: string[];
  projects__overlap?: string[];
  rgb_color?: string | string[];
  tags__contains?: string[];
  tags__overlap?: string[];
  tags?: string;
  is_archived?: boolean;
}

export type GenericBlockResponse = {
  // TODO sort out what could be instead of null here
  count: number;
  next: string | null;
  previous: string | null;
};

export type ImageTypes = {
  thumbnail_640: string;
  full_size: string;
  thumbnail_160: string;
  small_square_crop: string;
  thumbnail_400: string;
  thumbnail_330: string;
  thumbnail_130: string;
  thumbnail_100: string;
};

export type Studio = {
  position_x: number;
  position_y: number;
  position_omega: number;
  position_width: number | null;
  position_height: number | null;
  position_lock: boolean;
  position: Position;
  crop: Crop;
  layer: number;
};

export type Crop = {
  top: number | null;
  left: number | null;
  right: number | null;
  bottom: number | null;
};

type Position = {
  position_x: number;
  position_y: number;
  position_lock: boolean;
  position_omega: number;
  position_width: number | null;
  position_height: number | null;
};

export type Block = {
  id: string;
  name: string;
  file: ImageTypes;
  created_at: Date;
  block_type: BlockType;
  custom_palettes: [
    {
      theme: string;
      swatches: ColorSwatch[];
    },
  ];
  height: number;
  width: number;
  studio: Studio;
  is_removed: boolean;
  copies_number: number;
  boards: Array<BoardLvl1 | BoardLvl2 | BoardLvl3> | null;
  is_archived: boolean;
};

export type BlocksResponse = GenericBlockResponse & {
  results: Array<Block>;
};

export const getBlocks = async (
  params?: GetBlocksParams,
): Promise<BlocksResponse> => {
  const { data } = await api.get('/blocks/', {
    params,
  });

  return data;
};

export const getBlock = async ({
  id,
  is_archived,
}: {
  id: string | null;
  is_archived?: boolean;
}): Promise<RawBlockResponse> => {
  if (!id) {
    throw new Error('Block id not found');
  }

  const { data } = await api.get(`/blocks/image/${id}/`, {
    params: { is_archived },
  });
  return data;
};

export const fetchCreateNewTag = (data: Record<string, unknown>) =>
  api.post('/image_tag_api/', data);

export const fetchDeleteImage = (data: GetImagesParams) => {
  api.delete(`/image_api/${data.payload}/`, {});
};

export const getComments = async (
  payload: any, // TODO type
) => {
  if (!payload.image && !payload.board) {
    throw new Error('Image or Board id not found');
  }
  const { data } = await api.get('/comments/', {
    params: payload,
  });
  return data;
};

export const fetchDowloadImage = (data: GetImagesParams) =>
  api
    .get('/download_zip_file/', {
      params: { image_ids: data.payload },
      responseType: 'arraybuffer',
    })
    .then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: 'application/x-zip-compressed' }),
      );
      const link = document.createElement('a');
      link.href = url;
      const fileName = 'album';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });

export const postRemoveImageFromBoard = async (
  payload: Record<string, unknown>,
) => {
  await api.post(`/boards/remove_blocks/`, payload);
};

export const postComment = async (payload: Record<string, unknown>) => {
  const { data } = await api.post('/comments/', payload);
  return data;
};

export const updateComment = async (
  commentId: string,
  payload: Record<string, unknown>,
) => {
  const { data } = await api.put(`/comments/${commentId}/`, payload);
  return data;
};

export const deleteComment = async (commentId: Record<string, unknown>) => {
  await api.delete(`/comments/${commentId}/`);
};

export const getUser = async (): Promise<User> => {
  const { data } = await api.get('/auth/user/');
  return data;
};

export const createBoard = async (payload: any) => {
  const { data } = await api.post(`/boards/`, payload);
  return data;
};

export const fetchRefreshToken = async (refresh: any) => {
  if (!refresh) {
    throw new Error('Refresh is empty');
  }
  try {
    const response = await apiRefresh.post('/auth/token/refresh/', { refresh });
    const { data } = response;
    return data.access;
  } catch (e) {
    throw new Error('Unauthorised');
  }
};

export const startInstagramImport = async (accountId: number, payload: any) => {
  const { data } = await api.post(
    `/imports/instagram/${accountId}/collections/start_import/`,
    payload,
  );
  return data;
};

export const startPinterestImport = async (accountId: number, payload: any) => {
  const { data } = await api.post(
    `/imports/pinterest/${accountId}/boards/start_import/`,
    payload,
  );
  return data;
};

export const pinterestConnect = async (payload: any) => {
  const { data } = await api.post('/imports/pinterest/', payload);
  return data;
};

export const getTags = async (params: any): Promise<Array<Tag>> => {
  const config = params !== 'all-tags' ? { params } : {};
  const { data } = await api.get('/tags/', config);
  return data;
};

export const getColors = async () => {
  const { data } = await api.get('/colors/');
  return data;
};

export const mergeProjects = async (data: any) => {
  // TODO
  console.log(data);
};

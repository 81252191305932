import { ReactNode } from 'react';

import cn from 'classnames';
import { useHistory } from 'react-router';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { BoardLvl1, BoardLvl2, BoardLvl3, Hub } from '@api/designs';

import { AnyIconName } from '../../../../packages/icons/src/Icon';

import styles from './styles.module.css';

type RenderBreadcrumbsProps = {
  board?: Hub | BoardLvl1 | BoardLvl2 | BoardLvl3 | null;
  isShowHub?: boolean;
  isClickable?: boolean;
};

type ItemProps = {
  name: string;
  icon: AnyIconName;
  isClickable?: boolean;
  onClick: () => void;
};

type Props = {
  board?: Hub | BoardLvl1 | BoardLvl2 | BoardLvl3 | null;
  isShowHub?: boolean;
  isClickable?: boolean;
  className?: string;
};

export const Breadcrumbs = ({
  board,
  isShowHub = true,
  isClickable,
  className,
}: Props) => {
  if (!board) return null;

  return (
    <div className={cn(styles.breadcrumbs, className)}>
      {renderBreadcrumbs({ board, isShowHub, isClickable })}
    </div>
  );
};

const Item = ({ name, icon, isClickable, onClick }: ItemProps) => (
  <div
    className={cn(styles.filterChip, { [styles.clickable]: isClickable })}
    onClick={isClickable ? onClick : undefined}
  >
    <div style={{ display: 'flex' }}>
      <Icon name={icon} size={12} />
    </div>
    <TypographyPoppins type="label" size="S" className={styles.name}>
      {name}
    </TypographyPoppins>
  </div>
);

const renderBreadcrumbs = ({
  board,
  isClickable,
  isShowHub,
}: RenderBreadcrumbsProps) => {
  const history = useHistory();
  const breadcrumbs: ReactNode[] = [];

  const pushBreadcrumb = ({
    name,
    icon,
    path,
  }: {
    name: string;
    icon: AnyIconName;
    path: string;
  }) => {
    breadcrumbs.push(
      <Item
        key={name}
        name={name}
        icon={icon}
        isClickable={isClickable}
        onClick={() => history.push(path)}
      />,
    );
  };

  let current = board;

  while (current) {
    if ('type' in current && current.type === 'hub' && isShowHub) {
      pushBreadcrumb({
        name: current.name,
        icon: 'sprite/hub',
        path: `/h/${current.id}`,
      });
    } else {
      pushBreadcrumb({
        name: current.name,
        icon: 'sprite/board',
        path: `/b/${current.id}`,
      });
    }

    if ('parent' in current) {
      current = current.parent;
    } else {
      break;
    }
  }

  return breadcrumbs.reverse().map((breadcrumb, index) => (
    <div className={styles.breadcrumb} key={index}>
      {index > 0 && (
        <div style={{ display: 'flex' }}>
          <Icon name="sprite/dot" size={12} />
        </div>
      )}
      {breadcrumb}
    </div>
  ));
};

import { AxiosResponse } from 'axios';
import { useUnit } from 'effector-react';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { updateDesignThumbnail } from '@api/designs';
import {
  deleteImage,
  File,
  getImages,
  ImageGenericBlockResponse,
  ImageResponse,
  removeBackground,
  updateImage as updateImageRequest,
  UpdateImageDataWithoutDesignId,
  uploadExistingImages,
  uploadImage,
} from '@pages/StudioPage/api';
import {
  $draggableImages,
  $selectedImages,
  $singleSelectedImage,
  singleImageUnselected,
} from '@pages/StudioPage/components/Library/model';
import {
  addedOptimisticImage,
  openedVaiTidyupModal,
  removedOptimisticImage,
  selectObjectIds,
} from '@pages/StudioPage/model';
import { studioDesignKeys } from '@src/shared/constants/query-keys';
import { IMAGE_PREVIEW_MUTATION } from '@src/shared/constants/query-names';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { useStudioDesign } from '../use-studio-design';

type Props = {
  designId: string;
};

const FIVE_MINS_IN_MS = 1000 * 60 * 5;

export const useImages = ({ designId }: Props) => {
  const queryClient = useQueryClient();
  const { addImage, images, deleteObject, updateImage } =
    useStudioDesign(designId);
  const singleSelectedImage = useUnit($singleSelectedImage);
  const selectedImages = useUnit($selectedImages);
  const draggableImages = useUnit($draggableImages);

  const imageQuery = useQuery({
    queryKey: studioDesignKeys.images(designId),
    queryFn: () => getImages(designId),
    refetchOnReconnect: false,
    retry: false,
    staleTime: FIVE_MINS_IN_MS,
  });

  const previewMutation = useMutation({
    mutationFn: ({ file }: { file: string }) =>
      updateDesignThumbnail(designId, file),
    mutationKey: [IMAGE_PREVIEW_MUTATION],
  });

  const imageUpdateMutation = useMutation({
    mutationFn: (
      props: UpdateImageDataWithoutDesignId & {
        shouldInvalidate?: boolean;
      },
    ) =>
      updateImageRequest({
        designId,
        ...props,
      }),
    onMutate: async (variables) => {
      await queryClient.cancelQueries({
        queryKey: studioDesignKeys.images(designId),
      });

      const queryData = queryClient.getQueryData<
        AxiosResponse<ImageGenericBlockResponse>
      >(studioDesignKeys.images(designId));

      if (!queryData?.data.results) return;

      const imageToUpdate = getImageFromCache(
        queryData?.data.results,
        variables.imageId,
      );

      if (!imageToUpdate) return;

      const { image, idx } = imageToUpdate;

      const updatedImage: typeof image = {
        ...image,
        studio: {
          ...image.studio,
          position_height: variables.positionHeight,
          position_width: variables.positionWidth,
          position_x: variables.positionX,
          position_y: variables.positionY,
          position_omega: variables.positionOmega,
          position_lock: variables.positionLock,
          position: {
            ...image.studio.position,
            position_height: variables.positionHeight,
            position_width: variables.positionWidth,
            position_x: variables.positionX,
            position_y: variables.positionY,
            position_omega: variables.positionOmega,
            position_lock: variables.positionLock,
          },
        },
      };

      const newResults = [...queryData.data.results];

      newResults[idx] = updatedImage;

      const newQueryData = {
        ...queryData,
        data: {
          ...queryData.data,
          results: newResults,
        },
      };

      queryClient.setQueryData(studioDesignKeys.images(designId), newQueryData);
    },
    onSuccess: (_, variables) => {
      if (variables.shouldInvalidate) {
        queryClient.invalidateQueries({
          queryKey: studioDesignKeys.images(designId),
        });
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });
    },
  });

  const imageUploadMutation = useMutation({
    mutationFn: async ({ forms }: { forms: FormData[] }) => {
      const promises = [];
      for (const element of forms) {
        promises.push(uploadImage(element));
      }

      return await Promise.allSettled(promises);
    },
    onMutate: async (variables) => {
      queryClient.cancelQueries({
        queryKey: studioDesignKeys.images(designId),
      });

      // Notify user
      startedSnack({
        label: 'Adding...',
        close: true,
      });

      // Optimistically add an image to the canvas while image uploads
      for (const form of variables.forms) {
        try {
          const file = form.get('file');

          if (!(file instanceof Blob)) {
            console.error('File is not a Blob');
            continue;
          }

          const { posX, posY, width, height, id } = getImageData(form);

          const newWidth = width;
          const newHeight = height;

          const imgURL = URL.createObjectURL(file);

          addedOptimisticImage({
            id,
            imageURL: imgURL,
            position: {
              x: posX,
              y: posY,
            },
            height: newHeight,
            width: newWidth,
          });
        } catch (e) {
          console.error(e);
          continue;
        }
      }
    },
    onSuccess: async (data, variables) => {
      // We Assume the form order and the response order is the same. Promise.allSettled should guarantee this, as long as we always use form.variables as our source of truth

      // Update position after uploading image
      // TODO improve in the future to not have to do this i.e. send in the first request
      for (const [index, form] of variables.forms.entries()) {
        const { height, width, id } = getImageData(form);

        const newWidth = width;
        const newHeight = height;

        if (data[index].status !== 'fulfilled') {
          // TODO Error out
          removedOptimisticImage({ id });
          const rejectedError = data[index] as PromiseRejectedResult;
          throw Error(rejectedError.reason);
        }

        const imageResponse = data[index] as PromiseFulfilledResult<
          AxiosResponse<ImageResponse>
        >;

        try {
          const uploadedImageData = imageResponse.value.data;

          const file = form.get('file');

          if (!(file instanceof Blob)) {
            console.error('File is not a Blob');
            continue;
          }

          removedOptimisticImage({ id });

          // Add image to the images query so it shows up on cavnas, this gets replaced by the server data on refetch
          const imagesQuery:
            | AxiosResponse<ImageGenericBlockResponse>
            | undefined = queryClient.getQueryData(
            studioDesignKeys.images(designId),
          );

          if (!imagesQuery) {
            console.error('No image data in query cache');
            continue;
          }

          // Get original image dimensions
          const img = new Image();
          img.src = URL.createObjectURL(file);
          const { originalHeight, originalWidth } = await new Promise<{
            originalHeight: number;
            originalWidth: number;
          }>((resolve) => {
            img.onload = () => {
              const height = img.height;
              const width = img.width;
              URL.revokeObjectURL(img.src);
              resolve({
                originalHeight: height,
                originalWidth: width,
              });
            };
          });

          addImage({
            file: uploadedImageData.file,
            x: uploadedImageData.position_x,
            y: uploadedImageData.position_y,
            height: newHeight,
            width: newWidth,
            imageType: 'Image',
            id: uploadedImageData.id,
            originalWidth,
            originalHeight,
          });

          if (images.length === 4) {
            // Open tiny Vai popup modal
            openedVaiTidyupModal();
          }
        } catch (e) {
          removedOptimisticImage({ id: id });

          queryClient.invalidateQueries({
            queryKey: studioDesignKeys.images(designId),
          });

          // Retry Snack bar
          startedSnack({
            label: "Couldn't upload image",
            action: {
              label: 'Try again',
              action: () => {
                imageUploadMutation.mutate({ forms: [form] });
              },
            },
            close: true,
          });
          console.error(e);
          continue;
        } finally {
          queryClient.invalidateQueries({
            queryKey: studioDesignKeys.images(designId),
          });
        }
      }
    },
    onError: (e) => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });
      console.error(e);
    },
  });

  const existingImageUploadMutation = useMutation({
    mutationFn: async ({ id, files }: { id: string; files: File[] }) => {
      return await uploadExistingImages({ id, files });
    },
    onMutate: (variables) => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });

      // Notify user
      startedSnack({
        label: 'Adding...',
        close: true,
      });

      // Optimistically add an image to the canvas while image uploads
      try {
        if (selectedImages && !draggableImages && !singleSelectedImage) {
          addedOptimisticImage({
            id: variables.files[variables.files.length - 1].file,
            imageURL: selectedImages[selectedImages.length - 1].ref,
            position: {
              x: variables.files[variables.files.length - 1].position
                .position_x,
              y: variables.files[variables.files.length - 1].position
                .position_y,
            },
            height:
              variables.files[variables.files.length - 1].position
                .position_height,
            width:
              variables.files[variables.files.length - 1].position
                .position_width,
          });
        } else if (draggableImages) {
          addedOptimisticImage({
            id: variables.files[0].file,
            imageURL: draggableImages[0].ref,
            position: {
              x: variables.files[0].position.position_x,
              y: variables.files[0].position.position_y,
            },
            height: variables.files[0].position.position_height,
            width: variables.files[0].position.position_width,
          });
        } else if (singleSelectedImage) {
          addedOptimisticImage({
            id: variables.files[0].file,
            imageURL: singleSelectedImage[0].ref,
            position: {
              x: variables.files[0].position.position_x,
              y: variables.files[0].position.position_y,
            },
            height: variables.files[0].position.position_height,
            width: variables.files[0].position.position_width,
          });
          singleImageUnselected();
        }
      } catch (e) {
        console.error(e);
      }
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });

      removedOptimisticImage({
        id: variables.files[variables.files.length - 1].file,
      });

      data.data.forEach((image) => {
        addImage({
          id: image.id,
          x: image.studio.position_x,
          y: image.studio.position_y,
          width: image.studio.position_width,
          height: image.studio.position_height,
          file: image.file,
          imageType: 'Image',
          originalWidth: image.width,
          originalHeight: image.height,
        });
      });
    },
    onError: (e) => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });
      console.error(e);
    },
  });

  const imageBackgroundRemove = useMutation({
    mutationFn: ({ blockId }: { blockId: string; imageId: string }) =>
      removeBackground(blockId),
    onMutate: () => {
      startedSnack({
        label: 'Magic in progress...',
        close: true,
      });
    },
    onSuccess: ({ data }, variables) => {
      updateImage({
        id: variables.imageId,
        metadata: {
          file: data.file,
          blockId: data.id,
          imageType: data.block_type,
        },
      });
      // Attempt to optimistically replace the block with the new removed bg
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });
    },
  });

  const imageDeleteMutation = useMutation({
    mutationFn: (ids: string[]) => {
      return deleteImage(designId, ids);
    },
    onMutate: (variables) => {
      variables.forEach((imageIdToDelete) => {
        deleteObject(imageIdToDelete);
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: studioDesignKeys.images(designId),
      });
      selectObjectIds(new Set());
    },
  });

  return {
    imageQuery,
    imageUploadMutation,
    existingImageUploadMutation,
    imageDeleteMutation,
    imageBackgroundRemove,
    imageUpdateMutation,
    previewMutation,
  };
};

const getImageData = (form: FormData) => {
  const posXString = form.get('position_x');
  const posYString = form.get('position_y');
  const widthString = form.get('width');
  const heightString = form.get('height');
  const id = form.get('id');

  if (
    !posXString ||
    !posYString ||
    typeof posXString !== 'string' ||
    typeof posYString !== 'string' ||
    !widthString ||
    !heightString ||
    typeof widthString !== 'string' ||
    typeof heightString !== 'string' ||
    !id ||
    typeof id !== 'string'
  ) {
    throw Error('Form data missing');
  }

  const posX = parseInt(posXString);
  const posY = parseInt(posYString);
  const width = parseInt(widthString);
  const height = parseInt(heightString);

  return {
    posX,
    posY,
    width,
    height,
    id,
  };
};

const getImageFromCache = (
  imageData: ImageGenericBlockResponse['results'] | undefined,
  imageId: string,
) => {
  if (!imageData) return undefined;

  const imageToUpdateIndex = imageData.findIndex(
    (image) => image.id === imageId,
  );

  if (typeof imageToUpdateIndex === 'undefined') return;

  const imageToUpdate = imageData[imageToUpdateIndex];

  return { image: imageToUpdate, idx: imageToUpdateIndex };
};

import { useState } from 'react';

import cn from 'classnames';

import {
  Button,
  Dropdown,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import styles from './styles.module.css';

export const PriorityMapping = {
  3: 'High',
  2: 'Medium',
  1: 'Low',
  0: 'None',
} as const;

export const priorityArray = [3, 2, 1, 0] as const;

export type PriorityType = keyof typeof PriorityMapping;
export const priorityColors = {
  High: 'var(--color-error-40)',
  Medium: 'var(--color-picardy-80)',
  Low: 'var(--color-light-blue)',
  None: 'var(--color-neutral-variant-90)',
};

export const ActionItemPriority = ({
  priority,
  updatePriority,
  displayLabel = false,
  displaySetPriorityBtn = false,
  className = '',
  isArchived,
}: {
  priority: PriorityType | number;
  updatePriority: (p: PriorityType) => void;
  displayLabel?: boolean;
  displaySetPriorityBtn?: boolean;
  className?: string;
  isArchived?: boolean;
}) => {
  if (typeof priority !== 'number' || ![0, 1, 2, 3].includes(priority))
    return null;

  const priorityLabel = PriorityMapping[priority as PriorityType];
  const [hoverPriority, setHoverPriority] = useState(false);

  return (
    <div className={cn(styles.actionItemPriority, className)}>
      {isArchived ? (
        priorityLabel !== 'None' ? (
          <>
            <Icon
              name="sprite/flag-filled"
              color={priorityColors[priorityLabel]}
              size={24}
              style={{ marginRight: '12px' }}
            />
            {displayLabel && (
              <TypographyPoppins
                type="body"
                size="M"
                style={{ color: 'var(--color-secondary-40)' }}
              >
                {priorityLabel}
              </TypographyPoppins>
            )}
          </>
        ) : (
          <>
            {displaySetPriorityBtn ? (
              <Button
                type="text"
                style={{ color: 'var(--color-secondary-80)' }}
                label="Set priority"
                onClick={() => {}}
                isDisabled
              />
            ) : (
              <div
                className={styles.noPriority}
                onMouseEnter={() => {
                  setHoverPriority(true);
                }}
                onMouseLeave={() => {
                  setHoverPriority(false);
                }}
              >
                <Icon
                  name="sprite/flag-filled"
                  color="var(--color-neutral-variant-90)"
                  size={24}
                  className={cn({
                    [styles.noPriorityFlagShow]: hoverPriority,
                    [styles.noPriorityFlagHide]: !hoverPriority,
                  })}
                />
              </div>
            )}
          </>
        )
      ) : (
        <Dropdown>
          <Dropdown.Menu
            side="bottom"
            density="-2"
            trigger={
              priorityLabel !== 'None' ? (
                <>
                  <Icon
                    name="sprite/flag-filled"
                    color={priorityColors[priorityLabel]}
                    size={24}
                    style={{ marginRight: '12px' }}
                  />
                  {displayLabel && (
                    <TypographyPoppins
                      type="body"
                      size="M"
                      style={{ color: 'var(--color-secondary-40)' }}
                    >
                      {priorityLabel}
                    </TypographyPoppins>
                  )}
                </>
              ) : (
                <>
                  {displaySetPriorityBtn ? (
                    <Button
                      type="text"
                      style={{ color: 'var(--color-secondary-80)' }}
                      label="Set priority"
                      onClick={() => {}}
                    />
                  ) : (
                    <div
                      className={styles.noPriority}
                      onMouseEnter={() => {
                        setHoverPriority(true);
                      }}
                      onMouseLeave={() => {
                        setHoverPriority(false);
                      }}
                    >
                      <Icon
                        name="sprite/flag-filled"
                        color="var(--color-neutral-variant-90)"
                        size={24}
                        className={cn({
                          [styles.noPriorityFlagShow]: hoverPriority,
                          [styles.noPriorityFlagHide]: !hoverPriority,
                        })}
                      />
                    </div>
                  )}
                </>
              )
            }
          >
            <Dropdown.MenuItem
              item={{
                content: 'High',
                leadingIcon: (
                  <Icon
                    name="sprite/flag-filled"
                    color="var(--color-error-40)"
                  />
                ),
                onClick: () => updatePriority(3),
              }}
            />
            <Dropdown.MenuItem
              item={{
                content: 'Medium',
                leadingIcon: (
                  <Icon
                    name="sprite/flag-filled"
                    color="var(--color-picardy-80)"
                  />
                ),
                onClick: () => updatePriority(2),
              }}
            />
            <Dropdown.MenuItem
              item={{
                content: 'Low',
                leadingIcon: (
                  <Icon
                    name="sprite/flag-filled"
                    color="var(--color-light-blue)"
                  />
                ),
                onClick: () => updatePriority(1),
              }}
            />
            <Dropdown.MenuItem
              item={{
                content: 'None',
                leadingIcon: (
                  <Icon
                    name="sprite/flag-filled"
                    color="var(--color-neutral-variant-90)"
                  />
                ),
                onClick: () => updatePriority(0),
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

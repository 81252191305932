import { useAppData } from '@src/AppContext';
import { useQuery } from '@tanstack/react-query';

import {
  BlockResponse,
  getBlock,
  RawBlockResponse,
} from '../../../../api/services';
import { IMAGE_QUERY } from '../../../../shared/constants';

export const useBlock = (id: string | null) => {
  const { user } = useAppData();

  return useQuery({
    queryKey: [IMAGE_QUERY, { id }, user.show_archived],
    queryFn: () => getBlock({ id, is_archived: user.show_archived }),
    select: transformData,
    enabled: !!id,
    refetchInterval: 180000,
  });
};

const transformData = (data: RawBlockResponse) => {
  const transformedData = {
    ...data,
    custom_palettes: data.custom_palettes.map((palette) => ({
      ...palette,
      type: 'custom_palette',
    })),
  } satisfies BlockResponse;

  return transformedData;
};

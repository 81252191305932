import { ReactNode } from 'react';

import cn from 'classnames';

import {
  Button,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';

import styles from './styles.module.css';

type Props = {
  className?: string;
  icon: ReactNode;
  content: string;
  label: string;
  action: () => void;
  isActionDisabled?: boolean;
  secondaryLabel?: string;
  secondaryAction?: () => void;
  tooltip?: string;
};

export const EmptyState = ({
  className,
  icon,
  content,
  label,
  action,
  isActionDisabled,
  secondaryLabel,
  secondaryAction,
  tooltip,
}: Props) => (
  <div className={cn(styles.emptyState, className)}>
    {icon}
    <TypographyPoppins className={styles.content} type="body" size="S">
      {content}
    </TypographyPoppins>

    {tooltip ? (
      <TooltipRadix description={tooltip}>
        <Button
          type="outlined"
          label={label}
          onClick={action}
          isDisabled={isActionDisabled}
        />
      </TooltipRadix>
    ) : (
      <Button
        type="outlined"
        label={label}
        onClick={action}
        isDisabled={isActionDisabled}
      />
    )}

    {secondaryAction && secondaryLabel && (
      <Button
        type="ghost"
        fontType="label"
        size="S"
        label={secondaryLabel}
        onClick={secondaryAction}
        isDisabled={isActionDisabled}
      />
    )}
  </div>
);

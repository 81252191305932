import { SyntheticEvent } from 'react';

import cn from 'classnames';

import {
  Design,
  IconButton,
  TooltipRadix,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { useWindowSize } from '@visualist/hooks';
import { Icon } from '@visualist/icons';

import { SelectedImageDetails } from '@components/LayoutGrid';
import { SMALL } from '@src/shared/constants/breakpoints';

import { viewThumbnailsSheetOpened } from '../sheet/view-thumbnails/model';

import styles from './styles.module.css';

type Props = {
  items: SelectedImageDetails[];
  isSideSheet?: boolean;
  download: (e: SyntheticEvent) => void;
  remove?: () => void;
  shuffleList?: () => void;
  closeAction?: () => void;
  createDesign: () => void;
};

export const SelectionToolbar = ({
  items,
  isSideSheet,
  remove,
  shuffleList,
  download,
  closeAction,
  createDesign,
}: Props) => {
  const { width } = useWindowSize();

  const isDesignFile = items.some(
    (item) => item.imageInfo.block_type === 'Set',
  );

  return (
    <div
      className={cn(styles.toolbar, {
        [styles.sideSheet]: isSideSheet,
      })}
    >
      <ul className={styles.options}>
        {!isDesignFile && (
          <li className={styles.option}>
            <TooltipRadix
              description="Create design with selection"
              side="bottom"
            >
              <IconButton
                type="unfilled"
                icon={<Design fill="none" />}
                onClick={() => createDesign()}
              />
            </TooltipRadix>
          </li>
        )}
        <li className={styles.option}>
          <TooltipRadix description="Download all" side="bottom">
            <IconButton
              type="unfilled"
              icon={<Icon name="sprite/download" />}
              onClick={(e) => download(e)}
              isDisabled={true}
            />
          </TooltipRadix>
        </li>
        <li className={styles.option}>
          <TooltipRadix description="Copy all to board" side="bottom">
            <IconButton
              type="unfilled"
              icon={<Icon name="sprite/board" />}
              isDisabled={true}
              onClick={() => {}}
            />
          </TooltipRadix>
        </li>
        {isSideSheet && (
          <li className={styles.option}>
            <TooltipRadix description="Shuffle" side="bottom">
              <IconButton
                type="unfilled"
                icon={<Icon name="sprite/shuffle" />}
                onClick={() => {
                  if (shuffleList) {
                    shuffleList();
                  }
                }}
              />
            </TooltipRadix>
          </li>
        )}
        {!isSideSheet && (
          <li className={styles.option}>
            <TooltipRadix description="View thumbnails" side="bottom">
              <IconButton
                type="unfilled"
                icon={<Icon name="sprite/arrow-expand" />}
                onClick={() => viewThumbnailsSheetOpened()}
              />
            </TooltipRadix>
          </li>
        )}
        {!isSideSheet && (
          <li className={styles.option}>
            <IconButton
              type="unfilled"
              icon={<Icon name="sprite/bin" className={styles.bin} />}
              onClick={() => {
                if (remove) {
                  remove();
                }
              }}
            />
          </li>
        )}
      </ul>
      {isSideSheet && width <= SMALL && (
        <div
          className={cn(styles.numberSelected, {
            [styles.sideSheetNumberSelected]: isSideSheet,
          })}
        >
          <TypographyPoppins type="label" size="L" className={styles.label}>
            {items.length} selected
          </TypographyPoppins>
        </div>
      )}
      {!isSideSheet && (
        <div className={styles.numberSelected}>
          <TypographyPoppins type="label" size="L" className={styles.label}>
            {items.length} selected
          </TypographyPoppins>
          <IconButton
            type="unfilled"
            icon={<Icon name="sprite/x" />}
            onClick={() => {
              if (closeAction) {
                closeAction();
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

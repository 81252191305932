import { ComponentProps, useEffect, useReducer, useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';

import {
  Button,
  ColorMenu,
  Dropdown,
  FilterChip,
  Modal,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TypographyPoppins,
} from '@visualist/design-system/src/components/v2';
import { Icon } from '@visualist/icons';

import { ResponseDoc } from '@api/docs';
import { hexToRgb, isLightColor } from '@src/shared/constants/colours';

import { $showPageSetupModal, closedPageSetupModal } from '../../model';
import { useGetDoc } from '../../queries/useGetDoc';
import { useUpdateDoc } from '../../queries/useUpdateDoc';
import {
  $background,
  $colors,
  $gradients,
  $isCustomColor,
  $isOpenColorMenu,
  backgroundSelected,
  colorMenuClosed,
  colorMenuToggled,
} from './model';

import styles from './styles.module.css';

const reducer = (state: PageSetupState, action: Action) => {
  switch (action.type) {
    case 'update-page-size': {
      let width = state.width;
      let height = state.height;

      if (action.payload.pageSize === 'letter') {
        width = 8.5;
        height = 11;
      } else if (action.payload.pageSize === 'a4') {
        width = 8.3;
        height = 11.7;
      }

      return {
        ...state,
        width,
        height,
      };
    }
    case 'update-margins': {
      return {
        ...state,
        marginTop: action.payload.top ?? state.marginTop,
        marginBottom: action.payload.bottom ?? state.marginBottom,
        marginLeft: action.payload.left ?? state.marginLeft,
        marginRight: action.payload.right ?? state.marginRight,
      };
    }
    case 'update-orientation': {
      const currentOrientation =
        state.width > state.height ? 'landscape' : 'portrait';
      if (currentOrientation === action.payload.orientation) {
        return state;
      }

      return {
        ...state,
        width: state.height,
        height: state.width,
      };
    }
    default:
      return state;
  }
};

const initialState = {
  width: 8.5,
  height: 11,
  marginTop: 1,
  marginBottom: 1,
  marginLeft: 1,
  marginRight: 1,
};

type PageSetupState = typeof initialState;

type Orientation = 'portrait' | 'landscape';

type PageSize = 'a4' | 'letter';

type Action =
  | {
      type: 'reset';
      payload: PageSetupState;
    }
  | {
      type: 'update-page-size';
      payload: {
        pageSize: PageSize;
      };
    }
  | {
      type: 'update-margins';
      payload: {
        top?: number;
        bottom?: number;
        left?: number;
        right?: number;
      };
    }
  | {
      type: 'update-orientation';
      payload: {
        orientation: Orientation;
      };
    };

export const PageSetupWrapper = ({ docId }: { docId: string }) => {
  const [showPageSetupModal] = useUnit([$showPageSetupModal]);
  const { data: doc } = useGetDoc({ doc_id: docId });
  const { updateDocCustomColorsMutation } = useUpdateDoc();
  const [customColors, setCustomColors] = useState<string[]>([]);

  useEffect(() => {
    if (doc?.custom_colors) {
      setCustomColors(doc?.custom_colors);
    }
  }, [doc?.custom_colors]);

  if (!doc) return null;

  const updateCustomColors = (customColor: string) => {
    if (!customColors.includes(customColor)) {
      setCustomColors((prevColors) => {
        const updatedColors = [...prevColors, customColor];
        updateDocCustomColorsMutation.mutate({
          docId,
          custom_colors: updatedColors,
        });
        return updatedColors;
      });
    }
  };

  return (
    <PageSetup
      key={showPageSetupModal.toString()}
      doc={doc}
      showPageSetup={showPageSetupModal}
      customColors={customColors}
      updateCustomColors={updateCustomColors}
    />
  );
};

const PageSetup = ({
  doc,
  showPageSetup,
  customColors,
  updateCustomColors,
}: {
  doc: ResponseDoc;
  showPageSetup: boolean;
  customColors: string[];
  updateCustomColors: (customColors: string) => void;
}) => {
  const [state, dispatch] = useReducer(reducer, {
    height: doc.height,
    width: doc.width,
    marginTop: doc.margin_top,
    marginRight: doc.margin_right,
    marginLeft: doc.margin_left,
    marginBottom: doc.margin_bottom,
  });

  const background = useUnit($background);

  const { editDoc } = useUpdateDoc({});

  useEffect(() => {
    if (!background) {
      backgroundSelected(doc.background_color);
    }
  }, [background]);

  const saveChanges = () => {
    editDoc({ docId: doc.id, background_color: background, ...state });
    closedPageSetupModal();
  };

  const updatePageSize = (pageSize: PageSize) => {
    dispatch({
      type: 'update-page-size',
      payload: {
        pageSize,
      },
    });
  };

  const updateMargins = (m: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  }) => {
    dispatch({
      type: 'update-margins',
      payload: m,
    });
  };

  const changeOrientation = (o: Orientation) => {
    dispatch({
      type: 'update-orientation',
      payload: {
        orientation: o,
      },
    });
  };

  return (
    <Modal handleClose={() => closedPageSetupModal()} showModal={showPageSetup}>
      <div className={styles.container}>
        <TypographyPoppins type="headline" size="S" className={styles.title}>
          Page setup
        </TypographyPoppins>
        <section className={styles.pageSetupContainer}>
          <div className={styles.twoColumns}>
            <PageSize
              width={state.width}
              height={state.height}
              updatePageSize={updatePageSize}
            />
            <Margins
              top={state.marginTop}
              left={state.marginLeft}
              bottom={state.marginBottom}
              right={state.marginRight}
              updateMargins={updateMargins}
            />
          </div>
          <div style={{ margin: '20px 0 24px 0' }}>
            <Orientation
              changeOrientation={changeOrientation}
              orientation={
                state.width > state.height ? 'landscape' : 'portrait'
              }
            />
          </div>
          <Background
            background={doc.background_color}
            customColors={customColors}
            updateCustomColors={updateCustomColors}
          />
        </section>
        <section className={styles.footer}>
          <div className={styles.row}>
            <Button
              label="Cancel"
              type="outlined"
              onClick={() => closedPageSetupModal()}
            />
            <Button label="Save" type="filled" onClick={saveChanges} />
          </div>
        </section>
      </div>
    </Modal>
  );
};

const getPageSizeLabel = (width: number, height: number) => {
  if ((width === 8.5 && height === 11) || (height === 8.5 && width === 11)) {
    return 'Letter';
  } else if (
    (width === 8.3 && height === 11.7) ||
    (height === 8.3 && width === 11.7)
  ) {
    return 'A4';
  }

  return 'Custom';
};

const PageSize = ({
  width,
  height,
  updatePageSize,
}: {
  width: number;
  height: number;
  updatePageSize: (s: PageSize) => void;
}) => {
  return (
    <fieldset className={styles.fieldset}>
      <div className={styles.row}>
        <label htmlFor="size">
          <TypographyPoppins type="body" size="M" className={styles.labels}>
            Page size
          </TypographyPoppins>
        </label>
        <Dropdown>
          <Dropdown.Menu
            trigger={
              <FilterChip
                className={styles.inputButton}
                trailingIcon={<Icon name="sprite/caret-down" />}
                type="body"
                size="S"
              >
                {getPageSizeLabel(width, height)}
              </FilterChip>
            }
            side="bottom"
            align="start"
            sideOffset={4}
            collisionPadding={8}
            density="-2"
          >
            <Dropdown.MenuItem
              item={{
                content: 'Letter',
                onClick: () => updatePageSize('letter'),
                trailingIcon:
                  getPageSizeLabel(width, height) === 'Letter' ? (
                    <Icon name="sprite/tick" />
                  ) : null,
              }}
            />
            <Dropdown.MenuItem
              item={{
                content: 'A4',
                onClick: () => updatePageSize('a4'),
                trailingIcon:
                  getPageSizeLabel(width, height) === 'A4' ? (
                    <Icon name="sprite/tick" />
                  ) : null,
              }}
            />
            <Dropdown.MenuItem
              item={{
                content: 'Custom',
                isDisabled: true,
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div
        className={styles.row}
        style={{
          marginTop: '12px',
        }}
      >
        <label htmlFor="width">
          <TypographyPoppins type="body" size="S" className={styles.text}>
            Width
          </TypographyPoppins>
        </label>
        <Input
          disabled
          value={width}
          type="number"
          name="width"
          id="width"
          min="0"
          max="100"
        />
      </div>
      <div
        className={styles.row}
        style={{
          marginTop: '8px',
        }}
      >
        <label htmlFor="height">
          <TypographyPoppins type="body" size="S" className={styles.text}>
            Height
          </TypographyPoppins>
        </label>
        <Input
          disabled
          value={height}
          type="number"
          name="height"
          id="height"
          min="0"
          max="100"
        />
      </div>
    </fieldset>
  );
};

const Margins = (props: {
  top: number;
  left: number;
  bottom: number;
  right: number;
  updateMargins: (m: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  }) => void;
}) => {
  const { top, left, bottom, right, updateMargins } = props;
  return (
    <div className={styles.marginsContainer}>
      <TypographyPoppins
        type="body"
        size="M"
        className={styles.labels}
        style={{ padding: '6px 0' }}
      >
        Margins (inches)
      </TypographyPoppins>
      <div
        className={styles.row}
        style={{
          marginTop: '12px',
        }}
      >
        <label htmlFor="top" className={styles.marginLabel}>
          <TypographyPoppins type="body" size="S" className={styles.text}>
            Top
          </TypographyPoppins>
        </label>
        <Input
          value={top}
          onChange={(e) => updateMargins({ top: parseInt(e.target.value) })}
          disabled
          type="number"
          name="top"
          id="top"
          min="0"
          max="100"
        />
      </div>
      <div
        className={styles.row}
        style={{
          marginTop: '8px',
        }}
      >
        <label htmlFor="bottom">
          <TypographyPoppins type="body" size="S" className={styles.text}>
            Bottom
          </TypographyPoppins>
        </label>
        <Input
          value={bottom}
          onChange={(e) => updateMargins({ bottom: parseInt(e.target.value) })}
          disabled
          type="number"
          name="bottom"
          id="bottom"
          min="0"
          max="100"
        />
      </div>
      <div
        className={styles.row}
        style={{
          marginTop: '8px',
        }}
      >
        <label htmlFor="left">
          <TypographyPoppins type="body" size="S" className={styles.text}>
            Left
          </TypographyPoppins>
        </label>
        <Input
          value={left}
          onChange={(e) => updateMargins({ left: parseInt(e.target.value) })}
          disabled
          type="number"
          name="left"
          id="left"
          min="0"
          max="100"
        />
      </div>
      <div
        className={styles.row}
        style={{
          marginTop: '8px',
        }}
      >
        <label htmlFor="right">
          <TypographyPoppins type="body" size="S" className={styles.text}>
            Right
          </TypographyPoppins>
        </label>
        <Input
          value={right}
          onChange={(e) => updateMargins({ right: parseInt(e.target.value) })}
          disabled
          type="number"
          name="right"
          id="right"
          min="0"
          max="100"
        />
      </div>
    </div>
  );
};

const Orientation = (props: {
  orientation: Orientation;
  changeOrientation: (orientation: Orientation) => void;
}) => {
  const { orientation, changeOrientation } = props;

  return (
    <div className={styles.orientationContainer}>
      <TypographyPoppins type="body" size="M" className={styles.labels}>
        Orientation
      </TypographyPoppins>
      <div className={styles.orientationRow}>
        <label htmlFor="portrait" className={styles.radioRow}>
          <input
            checked={orientation === 'portrait'}
            onChange={() => changeOrientation('portrait')}
            type="radio"
            name="orientation"
            id="portrait"
            value="portrait"
            className={styles.radio}
          />
          <TypographyPoppins type="body" size="S" className={styles.text}>
            Portrait
          </TypographyPoppins>
        </label>
        <label htmlFor="landscape" className={styles.radioRow}>
          <input
            checked={orientation === 'landscape'}
            onChange={() => changeOrientation('landscape')}
            type="radio"
            name="orientation"
            id="landscape"
            value="landscape"
            className={styles.radio}
          />
          <TypographyPoppins type="body" size="S" className={styles.text}>
            Landscape
          </TypographyPoppins>
        </label>
      </div>
    </div>
  );
};

const Background = ({
  background,
  customColors,
  updateCustomColors,
}: {
  background: string;
  customColors: string[];
  updateCustomColors: (customColors: string) => void;
}) => {
  const isOpenColorMenu = useUnit($isOpenColorMenu);
  const colors = useUnit($colors);
  const gradients = useUnit($gradients);
  const selectedBackground = useUnit($background);
  const isCustomColor = useUnit($isCustomColor);

  const getLightColor = () => {
    if (selectedBackground && selectedBackground.includes('#')) {
      return isLightColor(hexToRgb(selectedBackground));
    } else if (!selectedBackground && background.includes('#')) {
      return isLightColor(hexToRgb(background));
    }
  };

  const isGradient =
    (selectedBackground && !selectedBackground.includes('#')) ||
    !background.includes('#');

  return (
    <div className={styles.colorSelector}>
      <TypographyPoppins type="body" size="M" className={styles.labels}>
        Background color
      </TypographyPoppins>
      <Popover open={isOpenColorMenu} onOpenChange={() => colorMenuToggled()}>
        <PopoverTrigger asChild>
          <FilterChip
            trailingIcon={<Icon name="sprite/caret-down" />}
            type="label"
            size="S"
          >
            <div
              style={{
                background: selectedBackground
                  ? selectedBackground
                  : background,
              }}
              className={cn(styles.swatch, {
                [styles.light]: !isGradient && getLightColor(),
                [styles.dark]: !isGradient && !getLightColor(),
              })}
            />
          </FilterChip>
        </PopoverTrigger>
        <PopoverContent align="start">
          <ColorMenu
            colors={colors}
            customColors={customColors}
            updateCustomColors={updateCustomColors}
            gradients={gradients}
            background={background}
            selectedBackground={selectedBackground}
            isCustomColor={isCustomColor}
            backgroundSelected={backgroundSelected}
            colorMenuClosed={colorMenuClosed}
            isDebounce
          />
        </PopoverContent>
      </Popover>
    </div>
  );
};

const Input = ({
  ...rest
}: Exclude<ComponentProps<'input'>, 'className'> & {}) => {
  return <input {...rest} className={styles.input} />;
};

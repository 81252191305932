import { createEvent, createStore, sample } from 'effector';

import { BlockType } from '@api/index';

export type InputType = 'Hub' | 'Board' | 'Doc' | BlockType;
export type OutputType =
  | 'hub'
  | 'board'
  | 'doc'
  | 'image'
  | 'design'
  | 'palette';

const typeMap: Record<InputType, OutputType> = {
  Hub: 'hub',
  Board: 'board',
  Doc: 'doc',
  Image: 'image',
  Set: 'design',
  'Colour palette': 'palette',
} as const;

export const entityTypeSelected = createEvent<InputType>();
export const entityTypeCleared = createEvent();

export const $entityType = createStore<OutputType | null>(null);

$entityType.reset(entityTypeCleared);

sample({
  clock: entityTypeSelected,
  fn: (type) => typeMap[type],
  target: $entityType,
});

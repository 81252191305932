import { useUnit } from 'effector-react';

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { STUDIO_TABS } from '@src/shared/constants/variables-local-storage';

import {
  $openedDesigns,
  DesignMetadata,
  replaceOpenedDesigns,
  SavedDesigns,
} from './model';

export const useOpenedDesigns = () => {
  // Hooks for saving and loading designs to local storage
  const openedDesigns = useUnit($openedDesigns);

  const saveAllOpenedDesigns = (designs: SavedDesigns) => {
    localStorage.setItem(
      STUDIO_TABS,
      JSON.stringify(Object.fromEntries(designs.entries())),
    );
    replaceOpenedDesigns(designs);
  };

  const saveDesign = ({
    designId,
    name,
  }: {
    designId: string;
    name: string;
  }) => {
    if (!openedDesigns) loadOpenedDesigns();
    const newDesigns = new Map(openedDesigns);
    const designs = Array.from(newDesigns.values());
    let index = 0;
    designs.forEach((d) => {
      index = Math.max(index, d.index);
    });
    newDesigns.set(designId, { id: designId, name, index: ++index });
    saveAllOpenedDesigns(newDesigns);
  };

  const removeDesign = async (designId: string) => {
    try {
      await loadOpenedDesigns();
      const newDesigns = new Map(openedDesigns);
      newDesigns.delete(designId);
      saveAllOpenedDesigns(newDesigns);
    } catch (error) {
      startedSnack({
        label: "Couldn't delete design",
        action: {
          label: 'Try again',
          action: () => {
            removeDesign(designId);
          },
        },
        close: true,
      });
    }
  };

  const loadOpenedDesigns = (): Promise<SavedDesigns> => {
    return new Promise((resolve, reject) => {
      if (openedDesigns && openedDesigns.size > 0) {
        resolve(openedDesigns);
      } else {
        try {
          const designs: SavedDesigns = JSON.parse(
            localStorage.getItem(STUDIO_TABS) || '{}',
          );

          if (!designs) {
            replaceOpenedDesigns(new Map());
          } else {
            replaceOpenedDesigns(new Map(Object.entries(designs)));
          }

          resolve(new Map(Object.entries(designs)));
        } catch (e) {
          replaceOpenedDesigns(new Map());
          reject(new Map());
          console.error(e);
        }
      }
    });
  };

  const reorderOpenedDesigns = async (newValues: Array<string>) => {
    if (!openedDesigns) await loadOpenedDesigns();

    const newMapUsingIndex = new Map<string, DesignMetadata>();

    const reorederedDesigns = new Map<string, DesignMetadata>();

    openedDesigns?.forEach((value) => {
      newMapUsingIndex.set(value.index.toString(), { ...value });
    });

    newValues.forEach((orderIndex, index) => {
      const design = newMapUsingIndex.get(orderIndex);
      if (design) {
        design.index = index;
        reorederedDesigns.set(design.id, design);
      }
    });

    saveAllOpenedDesigns(reorederedDesigns);
  };

  const updateDesignName = async (designId: string, name: string) => {
    if (!openedDesigns) await loadOpenedDesigns();

    const newDesignMap = new Map(openedDesigns);
    const design = newDesignMap.get(designId);

    if (!design) return;

    design.name = name;
    saveAllOpenedDesigns(newDesignMap);
  };

  return {
    openedDesigns,
    saveDesign,
    removeDesign,
    loadOpenedDesigns,
    reorderOpenedDesigns,
    updateDesignName,
  };
};

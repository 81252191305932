// Some konva component do not take css and need colours defined in js
export const GRETNA_40 = '#006E2E';
export const TERTIARY_40 = '#675F30';

// These coefficients are based on the standard formula for calculating relative brightness used in the sRGB colour space
// https://stackoverflow.com/questions/687261/converting-rgb-to-grayscale-intensity/689547#689547
export const RED_Y = 0.2126;
export const GREEN_Y = 0.7152;
export const BLUE_Y = 0.0722;

export const hexToRgb = (hex: string | null) => {
  if (!hex) return null;

  if (hex.includes('#')) {
    hex = hex.replace(/^#/, '');
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`.replace(/,\s+/g, ',');
};

export const parseRGBString = (str: string | null) => {
  if (!str) return null;

  const [r, g, b] = str.split(',').map((value) => value.trim());

  return { r, g, b };
};

export const isLightColor = (rgb: string | null) => {
  if (!rgb) return null;

  const rgbColors = rgb.split(',');
  const r = parseInt(rgbColors[0]);
  const g = parseInt(rgbColors[1]);
  const b = parseInt(rgbColors[2]);
  const brightness = RED_Y * r + GREEN_Y * g + BLUE_Y * b;

  const isLight = brightness > 128;

  return isLight;
};

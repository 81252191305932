import { useEffect, useState } from 'react';

import { useUnit } from 'effector-react';
import Konva from 'konva';

import { ImageJSON } from '@api/designs';
import {
  DEFAULT_FONTFAMILY,
  DEFAULT_FONTSIZE,
  DEFAULT_TEXT_HEIGHT,
  DEFAULT_TEXT_WIDTH,
} from '@pages/StudioPage/constants';
import { useStudioDesign } from '@pages/StudioPage/hooks/use-studio-design';
import {
  $currentTool,
  $stageState,
  changedEditingState,
  changedTool,
  selectObjectIds,
} from '@pages/StudioPage/model';
import { generateId } from '@src/shared/utils/id';

import { LoadedImage } from '../Images';
import { OptimisticImages } from '../OptimisticImages';
import { Shape } from '../Shape';
import { Textbox } from '../Textbox';
import { PreviewShape } from './preview-shape';

export const Renderer = ({
  designId,
  stageRef,
}: {
  designId: string;
  stageRef: React.RefObject<Konva.Stage>;
}) => {
  const { objects, images, isInPagesMode, updateImage, addText, updateShape } =
    useStudioDesign(designId);

  const [currentTool, stageState] = useUnit([$currentTool, $stageState]);

  const [chosenImageForOnboarding, setChosenImageForOnboarding] =
    useState<ImageJSON | null>(null);
  const [hoveredSelection, setHoveredSelection] = useState('');

  useEffect(() => {
    if (chosenImageForOnboarding) return;

    // Chose top left most image
    let image = images[0];

    objects
      .filter((v) => v.type === 'image')
      .forEach((img) => {
        if (img.x < image.x && img.y < image.y) {
          image = img;
        }
      });

    setChosenImageForOnboarding(image);
  }, []);

  const addTextComponent = (e: MouseEvent) => {
    if (currentTool !== 'add-text' || !stageRef.current) return;
    const s = stageRef.current;

    // Get x and y of click
    const pointerX = e.offsetX;
    const pointerY = e.offsetY;

    const x = (pointerX - s.x()) / s.scaleX();
    const y = (pointerY - s.y()) / s.scaleY();

    const id = generateId('studio_text_object');

    changedEditingState(true);
    selectObjectIds(new Set([id]));

    addText({
      id,
      x,
      y,
      opacity: 1,
      rotation: 0,
      scale: {
        x: 1,
        y: 1,
      },
      type: 'text',
      metadata: {
        content: '',
        height: DEFAULT_TEXT_HEIGHT,
        width: DEFAULT_TEXT_WIDTH,
        alignment: 'left',
        bold: false,
        italic: false,
        underline: false,
        fontFamily: DEFAULT_FONTFAMILY,
        fontSize: DEFAULT_FONTSIZE,
        colour: '#000',
      },
    });

    changedTool('select');
  };

  useEffect(() => {
    if (!stageRef.current) return;

    stageRef.current.container().addEventListener('click', addTextComponent);

    return () => {
      if (!stageRef.current) return;
      // Remove event listener
      stageRef.current
        .container()
        .removeEventListener('click', addTextComponent);
    };
  }, [currentTool, stageState]);

  return (
    <>
      {objects.map((o) => {
        switch (o.type) {
          case 'image':
            return (
              <LoadedImage
                id={o.id}
                imageJSON={o}
                designId={designId}
                key={o.id}
                // NOTE: IF YOU INSTEAD TO CHANGE THE FULL_SIZE TO ANY OTHER FOR WHATEVER REASON THE CROP CALCULATIONS NEED TO BE ADJUSTED. THEY ASSUME FULL RESOLUTION
                url={o.metadata.file.full_size}
                stageRef={stageRef}
                updateImage={updateImage}
                mostUpperRightImage={chosenImageForOnboarding?.id === o.id}
                isInPagesMode={isInPagesMode}
              />
            );
          case 'text':
            return (
              <Textbox
                id={o.id}
                key={o.id}
                textJSON={o}
                designId={designId}
                hoveredSelection={hoveredSelection}
                setHoveredSelection={setHoveredSelection}
                isInPagesMode={isInPagesMode}
              />
            );
          case 'shape':
            return (
              <Shape
                key={o.id}
                id={o.id}
                updateShape={updateShape}
                shape={o}
                designId={designId}
                stage={stageRef.current}
                isClick={true}
                isInPagesMode={isInPagesMode}
              />
            );
        }
      })}
      <OptimisticImages />
      <PreviewShape designId={designId} stageRef={stageRef} />
    </>
  );
};

import { startedSnack } from '@visualist/design-system/src/components/v2/SnackBar/model';

import { archiveFile } from '@api/blocks';
import { ALL_BOARD_IMAGES_QUERY, LIBRARY_QUERY } from '@src/shared/constants';
import { OutputType } from '@src/shared/utils/get-entity-type';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useArchiveFile = () => {
  const queryClient = useQueryClient();

  const archiveFileMutation = useMutation({
    mutationFn: ({ ids }: { ids: string[] | null; entityType?: OutputType }) =>
      archiveFile({ ids }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: [LIBRARY_QUERY],
      });
      queryClient.invalidateQueries({
        queryKey: [ALL_BOARD_IMAGES_QUERY],
      });

      startedSnack({ label: `Archived ${variables.entityType}`, close: true });
    },
    onError: (err, variables) => {
      startedSnack({
        label: `Couldn’t archive ${variables.entityType}`,
        action: {
          action: () => {
            archiveFileMutation.mutate({ ids: variables.ids });
          },
          label: 'Try again',
        },
        close: true,
      });
    },
  });

  return {
    archiveFileMutation,
  };
};

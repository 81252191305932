import cn from 'classnames';

import { TypographyPoppins } from '@visualist/design-system/src/components/v2';

import { formatDate } from '@src/shared/utils/data';

import styles from '../styles.module.css';

type Props = {
  date: string;
  isIndent: boolean;
};

export const Time = ({ date, isIndent }: Props) => {
  return (
    <TypographyPoppins
      type="label"
      size="S"
      className={cn(styles.time, {
        [styles.timeIndent]: isIndent,
      })}
    >
      {formatDate(date)}
    </TypographyPoppins>
  );
};

import { useUnit } from 'effector-react';

import { $selectedSearchedColours } from '../../model';
import { ColourResults } from '../color-results';
import { ColourSelector } from '../colour-selector';

import styles from './styles.module.css';

export const ColourBottom = () => {
  const [selectedSearchedColours] = useUnit([$selectedSearchedColours]);

  return (
    <div className={styles.container}>
      {selectedSearchedColours.length > 0 ? (
        <ColourResults key={selectedSearchedColours.join('-')} />
      ) : (
        <ColourSelector />
      )}
    </div>
  );
};

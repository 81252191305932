import { createEvent, createStore, sample } from 'effector';

import { emailsCleared, permissionsCleared, queryCleared } from './inviting';

export const hubShareSheetOpened = createEvent();
export const hubShareSheetClosed = createEvent();

export const idSelected = createEvent<string>();
export const idCleared = createEvent();

export const $isHubShareSheetOpen = createStore(false);
export const $id = createStore<string | null>(null);

$isHubShareSheetOpen
  .on(hubShareSheetOpened, () => true)
  .reset(hubShareSheetClosed);

$id.on(idSelected, (_, id) => id).reset(idCleared);

sample({
  clock: hubShareSheetClosed,
  target: [idCleared, queryCleared, emailsCleared, permissionsCleared],
});

export type AlignmentType = typeof AlignmentType;
export type DistributionType = typeof DistributionType;

export const AlignmentType = [
  'Top',
  'Middle',
  'Bottom',
  'Left',
  'Center',
  'Right',
] as const;

export const DistributionType = ['HORIZONTAL', 'VERTICAL'] as const;

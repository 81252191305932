import { Icon, IconProps } from './Icon';

export const NoColor = (props: IconProps) => {
  return (
    <Icon {...props}>
      <g clipPath="url(#a)">
        <rect width="24" height="24" rx="12" fill="#fff" />
      </g>
      <path stroke="#BA1A1A" d="M21.354 4.354 4.383 21.324" />
      <defs>
        <clipPath id="a">
          <rect width="24" height="24" rx="12" fill="#fff" />
        </clipPath>
      </defs>
    </Icon>
  );
};

import { useState } from 'react';

import { isEditor } from '@visualist/design-system';
import {
  Dropdown,
  IconButton,
  Item,
} from '@visualist/design-system/src/components/v2';
import { Variant } from '@visualist/design-system/src/components/v2/Styles/Typography/TypographyPoppins';
import { Icon } from '@visualist/icons';

import { BlockItem, BoardItem, DocItem, HubItem } from '@api/bin';

import styles from '../styles.module.css';

type Props = {
  item: HubItem | BoardItem | DocItem | BlockItem;
  restore: () => void;
  deletePermanently: () => void;
};

export const Menu = ({ item, restore, deletePermanently }: Props) => {
  const [open, onOpenChange] = useState(false);

  const canRestore = () => {
    if (!item) return false;

    switch (item.item_type) {
      case 'Hub':
        return true;
      case 'Board':
        return !item.board.hub?.is_removed;
      case 'Doc':
        return !item.doc.board?.is_removed;
      case 'Block':
        return !item.block.boards[0]?.is_removed;
      default:
        return false;
    }
  };

  const menuItems: Item<Variant>[] = [
    ...(canRestore()
      ? [
          {
            leadingIcon: <Icon name="sprite/arrow-undo" />,
            content: 'Restore',
            onClick: restore,
          },
        ]
      : []),
    {
      leadingIcon: <Icon name="sprite/broom" className={styles.delete} />,
      content: 'Delete permanently',
      classNameContent: styles.delete,
      onClick: deletePermanently,
    },
  ];

  return isEditor(item?.permissions) ? (
    <Dropdown open={open} onOpenChange={onOpenChange}>
      <Dropdown.Menu
        asChild
        trigger={
          <IconButton
            className={styles.menu}
            type="unfilled"
            icon={<Icon size={12} name="sprite/3-dot-menu" />}
            onClick={() => onOpenChange(!open)}
            isSelected={open}
          />
        }
        side="bottom"
        align="end"
        density="-2"
      >
        {menuItems.map((item, index) => (
          <Dropdown.MenuItem key={index} item={item} />
        ))}
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};

import { useState } from 'react';

import cn from 'classnames';
import { useUnit } from 'effector-react';
import { useHistory, useParams } from 'react-router-dom';

import { DocumentHead } from '@components/DocumentHead';
import { ErrorBoundary } from '@sentry/react';
import { SomethingWentWrong } from '@src/entities/global-error/premade/something-went-wrong';
import { ActionItemDetails } from '@src/entities/sheet/action-item-details';

import { $actionFilters } from './modal';
import { ActionItemsHeader } from './ui/action-items-header';
import { ActionItems } from './ui/action-items-list';

import styles from './styles.module.css';

export const ActionItemsPage = () => {
  const history = useHistory();
  const [filters] = useUnit([$actionFilters]);
  const { actionId } = useParams<{
    actionId?: string;
  }>();
  const [showActionItemDetails, setShowActionItemDetails] = useState<boolean>(
    actionId ? true : false,
  );
  const openActionItemDetails = () => {
    setShowActionItemDetails(true);
  };

  return (
    <div className={styles.container}>
      <DocumentHead name="Action items" />
      <div className={styles.actionItemsContainer}>
        <ErrorBoundary fallback={<SomethingWentWrong />}>
          <div
            className={cn(styles.actionItemListContainer, {
              [styles.actionItemSheetOpen]: showActionItemDetails,
            })}
          >
            <ActionItemsHeader createActionItem={openActionItemDetails} />
            <ActionItems
              key={JSON.stringify(filters)}
              openDetails={openActionItemDetails}
            />
          </div>
          <ActionItemDetails
            isOpen={showActionItemDetails}
            closeAction={() => {
              history.push('/action-items');
              setShowActionItemDetails(false);
            }}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};

import { combine, createEvent, createStore, sample } from 'effector';
import { previous } from 'patronum';

import { Swatches } from '@visualist/design-system/src/components/v2';

// colorPicked - an event for passing any type of data (not just hex-color) to change the background of the hub's header.
// Then, the value in $previousColor is compared with $defaultColor. If they match, it means the event 'Added cover' occurred, otherwise, it's 'Changed cover'
export const colorPicked = createEvent<any>();

export const $defaultColor = createStore('#FFF8F6');

const $currentColor = createStore('');
export const $previousColor = previous($currentColor);

$currentColor.on(colorPicked, (_, color) => color);

export const currentHeaderNameSetted = createEvent<string>();

export const $currentHeaderName = createStore('');

$currentHeaderName.on(currentHeaderNameSetted, (_, name) => name);

export const headerNameChanged = createEvent<string>();
export const headerNameReset = createEvent();

export const $headerName = createStore('');

$headerName.on(headerNameChanged, (_, name) => name);

sample({
  clock: headerNameReset,
  source: $currentHeaderName,
  target: $headerName,
});

export const thumbnailSetted = createEvent<any>();
export const thumbnailHexSetted = createEvent<any>();

const $thumbnail = createStore(false);
$thumbnail.on(thumbnailSetted, (_, background) => background);

const $thumbnailHex = createStore(false);
$thumbnailHex.on(thumbnailHexSetted, (_, background) => background);

export const $isShowSnackbar = combine(
  $thumbnail,
  $thumbnailHex,
  (thumbnail, thumbnailHex) => {
    return !thumbnail && !thumbnailHex;
  },
);

export const colorMenuToggled = createEvent();
export const colorMenuClosed = createEvent();

export const backgroundSelected = createEvent<string | { full_size: string }>();
export const backgroundCleared = createEvent();

export const $isOpenColorMenu = createStore(false);

$isOpenColorMenu
  .on(colorMenuToggled, (isOpen) => !isOpen)
  .reset(colorMenuClosed);

export const $colors = createStore<Swatches>({
  title: 'Solid',
  swatches: [
    { name: '#FFFFFF', color: '#FFFFFF' },
    { name: '#FFE2B5', color: '#FFE2B5' },
    { name: '#E2C9EB', color: '#E2C9EB' },
    { name: '#B3D4C1', color: '#B3D4C1' },
    { name: '#FBFBFA', color: '#FBFBFA' },
    { name: '#F3F1EE', color: '#F3F1EE' },
    { name: '#DCD5CB', color: '#DCD5CB' },
  ],
});

export const $gradients = createStore<Swatches>({
  title: 'Gradients',
  swatches: [
    { name: 'Sunrise', color: 'var(--sunrise)' },
    { name: 'Morning mist', color: 'var(--morning-mist)' },
    { name: 'Cotton candy', color: 'var(--cotton-candy)' },
    { name: 'Moonlight', color: 'var(--moonlight)' },
    { name: 'Mint', color: 'var(--mint)' },
    { name: 'Meadow', color: 'var(--meadow)' },
  ],
});

export const $background = createStore<string | { full_size: string }>('');

$background
  .on(backgroundSelected, (_, background) => background)
  .reset(backgroundCleared);

export const $isCustomColor = createStore<boolean | null>(false);

sample({
  clock: $background,
  source: {
    background: $background,
    colors: $colors,
    gradients: $gradients,
  },
  fn: ({ colors, background, gradients }) => {
    if (
      colors.swatches &&
      gradients.swatches &&
      typeof background === 'string'
    ) {
      const isColorSwatch = colors.swatches.some((swatch) =>
        swatch.color.includes(background),
      );

      const isGradientSwatch = gradients.swatches.some((swatch) =>
        swatch.color.includes(background),
      );

      return !isColorSwatch && !isGradientSwatch;
    } else {
      return null;
    }
  },
  target: $isCustomColor,
});
